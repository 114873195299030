<template>
    <div>
        <div class="widget-block">
            <div class="chart-container m-0"
                 v-loading="loading"
                 element-loading-background="rgba(19, 19, 19, .7)">
                <div>
                    <div class="header mb-3">
                        <div class="title">
                            {{ $t('dashboard.Research_leaders') }}
                            <div class="viewAll" @click="viewAll('Science')">
                                {{ $t('dashboard.viewAll') }}
                            </div>
                        </div>
                    </div>
                    <div class="cloud py-3" v-show="!noResult">
                        <vue-word-cloud
                            style="height: 130px; width: 100%;"
                            :words="words.Science"
                            :spacing="2"
                            :font-size-ratio="ratio"
                            color="#C46370"
                            font-family="Roboto"
                        >
                            <template slot-scope="{text, weight, word}">
                                <el-tooltip effect="dark" placement="top"
                                            :content="text + ': ' +  $utils.toFinHuman(weight,1).number + ' articles'"
                                            :open-delay="600">
                                    <div class="keyword" @click="(e) => handleWordClick(e, word, 'Science')">
                                        {{ text }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </vue-word-cloud>
                    </div>
                </div>
            </div>
        </div>
        <div class="widget-block">
            <div class="chart-container m-0"
                 v-loading="loading"
                 element-loading-background="rgba(19, 19, 19, .7)">
                <div>
                    <div class="header mb-3">
                        <div class="title">
                            {{ $t('dashboard.Patent_leaders') }}
                            <div class="viewAll" @click="viewAll('Patents')">
                                {{ $t('dashboard.viewAll') }}
                            </div>
                        </div>
                    </div>
                    <div class="cloud py-3" v-show="!noResult">
                        <vue-word-cloud
                            style="height: 130px; width: 100%;"
                            :words="words.Patents"
                            :spacing="2"
                            :font-size-ratio="ratio"
                            color="#C69245"
                            font-family="Roboto"
                        >
                            <template slot-scope="{text, weight, word}">
                                <el-tooltip effect="dark" placement="top"
                                            :content="text + ': ' +  $utils.toFinHuman(weight,1).number + ' patents'"
                                            :open-delay="600">
                                    <div class="keyword" @click="(e) => handleWordClick(e, word, 'Patents')">
                                        {{ text }}
                                    </div>
                                </el-tooltip>
                            </template>

                        </vue-word-cloud>
                    </div>
                </div>
            </div>
        </div>
        <div class="noResult" v-show="noResult">
            No Result
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "SciencePatentCountries",
    props: {
        widgetData: Object,
        chartOptions: Object,
        request: Object,
        params: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            curYear: new Date().getFullYear(),
            words: {
                Patents: [],
                Science: []
            },
            patentWords: [],
            scienceWords: [],
            ratio: 4,
            colors: [
                '#C46370', // Red2
                '#61B768', // Green2
                '#8599D0', // BlueBright
                '#C69245', // Orange2
                '#BABABA',  // White2
            ],
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        noResult() {
            return !this.widgetData || (this.widgetData && (!this.widgetData.Patents || !this.widgetData.Science))
        }
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        widgetData(newVal) {
            if (newVal) {
                this.refresh();
            }
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let _data = _.cloneDeep(self.widgetData),
                _vals = _.map(_data, it => parseInt(it)),
                rotation = self.params.rotation ? self.params.rotation : {};


            //---------- Countries ----------

            _.each(_data, (ser, name) => {
                self.words[name] = _.map(ser, (it, country) => {
                    return {
                        text: country,
                        weight: parseInt(it),
                        // rotationUnit: 'deg',
                        // rotation: rotation.rule === 'stringLength' ?
                        //     (country.length < rotation.value && Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0')
                        //     : Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0',
                    }
                });
            });
            self.ratio = (_.max(_vals) / _.min(_vals) > 18) ? 3 : 4;

            self.loading = false;
            // console.log('-- series Sci&Pats Countries: ', self.words);

        },
        handleWordClick(e, keyword, docType) {
            // console.log('keyword:', keyword);
            let self = this;
            let years = [];
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            let facets = {
                requests: [
                    {
                        type: yearType,
                        values: years
                    },
                    {
                        type: 'COUNTRIES',
                        values: [keyword.text]
                    },
                ]
            }
            // console.log('Facets', facets, self.request.filter);
            if (e.button === 0) {
                self.viewAll(docType, facets);
            }
        },
        viewAll(docType = 'Science', facets = null) {
            let self = this;
            let years = [];
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            facets = facets ? facets : {
                requests: [
                    {
                        type: yearType,
                        values: years
                    },
                ]
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
            }
            self.$utils.openSearch(params);

        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
.cloud {

}
</style>