import _ from 'lodash';
import globalStates from '../state'
import view360 from './scenarios/view360'
import searchView360 from './scenarios/searchView360'
import clusterMap from './scenarios/clusterMap'

const scenario = {
    mainSearch: {
        widget: {
            view360: searchView360,
        }
    },
    dashboard: {
        widget: {
            view360: view360,
            techRating: {},
            techHeatmap: {},
            techSignals: {}
        }
    },
    clusterMap: {
        widget: {
            map: clusterMap,
        }
    }
};
let hlClasses = ['angle-bottom', 'angle-right', 'angle-left', 'angle-none'];


export default {
    state: {
        QTStatus: {
            show: false,
            totalSteps: 0,
            step: {
                section: 'dashboard',
                widget: 'view360',
                number: 0,
                content: {
                    type: ''
                }
            }
        }
    },
    mutations: {
        QTChangeStep(state, stepNum) {
            let _step = state.QTStatus.step;

            // -- Clear classes from prev step { --
            let oldNote = document.querySelector('.tour-Note');
            if (_step.content.actions) {
                (_step.content.actions || []).forEach(act => {
                    if (act.type === 'addClass') {
                        act.targets.forEach(tar => {
                            let _el = document.querySelectorAll(tar.selector);
                            [].forEach.call(_el, it => {
                                it.classList.remove(tar.class);
                            })
                        })
                    }
                })
            }
            if (_step.content.anglePlacement) {
                oldNote.classList.remove('angle-' + _step.content.anglePlacement);
            }
            // -- } Clear classes from prev step  --

            state.QTStatus.step.number = stepNum;
            state.QTStatus.step.content = scenario[_step.section].widget[_step.widget].step.filter(stp => {
                return !stp.availableForRole || globalStates.allowedActions.contains(stp.availableForRole)
            })[stepNum];

            // console.log('____step__', _step);
            // console.log('allowedActions____', globalStates.allowedActions);
            let Note = document.querySelector('.tour-Note');
            if (_step.content.actions) {
                (_step.content.actions || []).forEach(act => {
                    if (act.type === 'addClass') {
                        // *** Classes { ***
                        act.targets.forEach(tar => {
                            hlClasses.push(tar.class);
                            let _el = document.querySelectorAll(tar.selector);
                            [].forEach.call(_el, it => {
                                it.classList.add(tar.class);
                            })
                        })
                        // *** } Classes  ***
                    } else if (act.type === 'scrollTop') {
                        act.targets.forEach(tar => {
                            let _value = tar.value,
                                _el = document.querySelector(tar.targetElement);
                            if (_el) {
                                let _coord = _el.getBoundingClientRect();
                                _value = tar.value + _coord.top
                            }
                            document.querySelector(tar.selector).scrollTo({
                                top: _value,
                                behavior: "smooth"
                            });
                        })
                    }
                })
                Note.classList.add('hidden');
                setTimeout(() => {
                    let _noteCoords = _step.content.noteCoords,
                        _anglePlace = _step.content.anglePlacement;
                    let _targetElement = _noteCoords && _noteCoords.targetElement || '.tourHighlight',
                        _el = document.querySelector(_targetElement);
                    if (_noteCoords) {
                        let _coord = _el ? _el.getBoundingClientRect()
                            : {
                                left: 0,
                                width: 0,
                                top: 0,
                                height: 0
                            }

                        _.each(_noteCoords, (val, key) => {
                            let num = parseInt(val);
                            if (num || num === 0) {
                                let _shift = _coord[key];
                                if (_noteCoords.targetCoords) {
                                    if (key === 'left') {
                                        _shift = _coord.left + _coord.width / (_anglePlace === 'top' ? 2 : 1);
                                    } else if (key === 'right') {
                                        _shift = _coord.left - 410;
                                    } else if (key === 'top') {
                                        _shift = _coord[key] + _coord.height / (_anglePlace === 'left' ? 2 : 1);
                                    }
                                }
                                if (key === 'width') {
                                    Note.style.width = num + 'px';
                                } else {
                                    let _key = ['right', 'left'].includes(key) ? 'left' : 'top';
                                    Note.style[_key] = num + _shift + 'px';
                                    Note.style.removeProperty('width');
                                }
                            } else {
                                Note.style[key] = val;
                            }
                        });
                    }
                    if (_anglePlace) {
                        Note.classList.add('angle-' + _step.content.anglePlacement);
                    }
                    Note.classList.remove('hidden');
                }, _step.content.timeOut || 200);
            }

        },
        QTStart(state) {
            let _step = state.QTStatus.step,
                curScStep = scenario[_step.section].widget[_step.widget];
            state.QTStatus.totalSteps = curScStep.step.filter(stp => {
                return !stp.availableForRole || globalStates.allowedActions.contains(stp.availableForRole)
            }).length - 2;
            state.QTStatus.step.number = 0;
            state.QTStatus.step.content = curScStep.step[0];
            state.QTStatus.show = true;
        },
        QTChangeSection(state, params) {
            if (params.widget) {
                state.QTStatus.step.widget = params.widget;
            }
            if (params.section) {
                state.QTStatus.step.section = params.section;
            }
        },
        QTSkip(state) {
            state.QTStatus.step.number = 0;
            state.QTStatus.show = false;
            _.uniq(hlClasses).forEach(cls => {
                [].forEach.call(document.querySelectorAll("." + cls), it => {
                    it.classList.remove(cls);
                })
            });
        },

    },
    actions: {},
    getters: {
        QTStatus(state) {
            return _.merge(
                {show: state.QTStatus.show},
                {totalSteps: state.QTStatus.totalSteps},
                state.QTStatus.step
            );
        }
    }
};
