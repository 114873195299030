import _ from "lodash";

export default class AllowedActions {
    constructor(actions) {
        actions = actions || [];
        if(actions instanceof AllowedActions) {
            actions = actions.list;
        }
        const map = Object.freeze(_.reduce(actions, (r, v) => {r[v] = true; return r;}, {}));
        Object.defineProperty(this, 'map', {value: map});
        _.each(actions, v => Object.defineProperty(this, v, {value: true, enumerable: true}));
    }
    get list() {
        return Object.keys(this.map);
    }
    contains(action) {
        return !!this.map[action];
    }
    containsAny(actions) {
        if(!actions) {
            return false;
        }
        if(_.isString(actions)) {
            actions = Array.from(arguments);
        }
        if(_.isArray(actions)) {
            if(actions.length === 0){
                return false;
            }
            for (const action of actions) {
                if(this.map[action]) {
                    return true;
                }
            }
        }
        return false;
    }
    containsAll(actions) {
        if(!actions) {
            return false;
        }
        if(_.isString(actions)) {
            actions = Array.from(arguments);
        }
        if(_.isArray(actions)) {
            if(actions.length === 0){
                return false;
            }
            for (const action of actions) {
                if(!this.map[action]) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }
}
