<template>
    <div class="favorites-btn"
         :class="{active: doc.isInFavorite}"
         @click="toggleFavorites">
        <i class="fas fa-star"></i>
        <span class="addTo"> Add to </span>
        <span class="in"> In </span>
        Favorites
    </div>
</template>

<script>
export default {
    name: "FavoritesBtn",
    props: {
        doc: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
        toggleFavorites() {
            let item = this.doc;
            let query = [_.pick(item, ['id', 'type'])];
            let methodName = item.isInFavorite ? 'favoritesRemove' : 'favoritesAdd';
            this.$services.documents[methodName](query).prepare().then(resp => {
                if (resp.data) {
                    item.isInFavorite = !item.isInFavorite;
                    this.$emit('change', _.merge({},{isInFavorite:methodName === "favoritesAdd"}, query[0]))
                }
            });
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.favorites-btn {
    display: inline-flex;
    height: 25px;
    align-items: center;
    padding: 0 12px;
    font-variant: all-small-caps;
    font-size: 14px;
    border: 1px solid $iq-color-orange;
    color: $iq-color-orange;
    background: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;

    i {
        margin-right: 5px;
    }

    .addTo {
        display: inline-block;
        margin-right: 4px;
    }

    .in {
        display: none;
        margin-right: 4px;
    }

    &.active {
        color: #fff;
        background: $iq-color-orange;

        .addTo {
            display: none;
        }

        .in {
            display: inline-block;
        }
    }
}

</style>