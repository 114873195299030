titl
<template>
    <div class="fst-SearchListCard fst-CompanyCard" @contextmenu="handleContextMenu">
        <div class="left-block">
            <div class="companyCard-header">
                <div class="logo-title">
                    <div class="logo" v-if="logoUrl">
                        <img :src="logoUrl" :alt="'Company logo'" on_error="this.src='noLogo.png'"/>
                    </div>
                    <div class="title-attr">
                        <div class="title" v-html="doc.title"></div>
                        <div class="attrs">
                            <div class="attrs-item" v-if="founded" v-html=" $t('label.founded') + ': ' + founded"></div>
                            <div class="attrs-item" v-if="doc.url">
                                <el-link type="primary" :href="doc.url.href" :underline="false" target="_blank"
                                         v-html="doc.url.origin"></el-link>
                            </div>
                            <div class="attrs-item" v-if="doc.urlCrunchbase">
                                <el-link type="primary" :href="doc.urlCrunchbase" :underline="false" target="_blank">
                                    Crunchbase
                                </el-link>
                            </div>
                        </div>
                        <div v-if="owner && owner.title" class="owner">
                            <div class="owner-logo" v-if="owner.icon && owner.icon.url">
                                <img :src="owner.icon.url" alt="Investors logo">
                            </div>
                            Acquired by
                            <a href="javascript:" @click="handleOpenCompanyCard(owner)">
                                {{ owner.title }}
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tags" v-if="doc.keywords">
                <el-tag v-for="(item,key) in doc.keywords" :key="key" type="green" v-html="item"></el-tag>
            </div>
            <UserTags
                v-model="doc.userTags"
                :fullUserTagList="fullUserTagList"
                :doc="doc"
                @updateUserTagList="getUserTagList"
                @userTagAction="userTagAction"
                class="mb-2">
            </UserTags>

            <div class="docLang">
                <el-link :underline="false"
                         v-for="lang in languages" :key="lang.name"
                         @click="handleTranslateItem(lang.name)"
                         :class="{active: curLang === lang.name}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                         fill="none" style="position: relative;top: 5px; margin: -5px -4px 0 0;">
                        <rect width="20" height="20" fill="white"></rect>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.99358 2.99986C8.54587 2.99986 8.99358 3.44758 8.99358 3.99986V4.70257H10.7807C10.7944 4.70228 10.8081 4.70228 10.8218 4.70257H12.3269C12.8792 4.70257 13.3269 5.15028 13.3269 5.70257C13.3269 6.25485 12.8792 6.70257 12.3269 6.70257H11.5715C11.3942 7.2829 11.1626 7.87554 10.8992 8.42696C10.5212 9.21867 10.0426 9.99981 9.50681 10.5411C9.49852 10.5495 9.49011 10.5579 9.48159 10.5664C9.98959 11.0649 10.5269 11.548 11.0479 11.9289L12.5915 7.71029C12.736 7.3153 13.1123 7.05297 13.5329 7.05392C13.9535 7.05487 14.3285 7.31889 14.4713 7.71452L16.6202 13.6705L17.4241 15.6184C17.6348 16.1289 17.3918 16.7135 16.8812 16.9242C16.3707 17.1349 15.7861 16.8919 15.5754 16.3813L15.0181 15.0311H11.9972L11.3972 16.401C11.1757 16.9069 10.5859 17.1374 10.0801 16.9159C9.57416 16.6943 9.34367 16.1046 9.56524 15.5987L10.3273 13.8588C9.44283 13.2896 8.59741 12.5108 7.92665 11.8415C7.75135 11.971 7.57421 12.0992 7.39996 12.2233C6.59587 12.7958 5.76548 13.3424 5.28655 13.621C4.80917 13.8987 4.19704 13.7368 3.91933 13.2595C3.64162 12.7821 3.80348 12.17 4.28086 11.8922C4.68465 11.6573 5.45919 11.1499 6.23999 10.594C6.33483 10.5265 6.42856 10.4591 6.52064 10.3922C5.97063 9.79109 5.43925 9.14158 5.1081 8.53017C4.84507 8.04454 5.02553 7.43764 5.51116 7.17461C5.99679 6.91158 6.60369 7.09203 6.86672 7.57766C7.1194 8.04418 7.56729 8.58074 8.08367 9.13589C8.08422 9.13535 8.08476 9.1348 8.0853 9.13426C8.39776 8.81855 8.76175 8.26185 9.09446 7.56512C9.22851 7.28441 9.35171 6.99312 9.46021 6.70257H3.49976C2.94747 6.70257 2.49976 6.25485 2.49976 5.70257C2.49976 5.15028 2.94747 4.70257 3.49976 4.70257H6.99358V3.99986C6.99358 3.44758 7.4413 2.99986 7.99358 2.99986ZM12.7743 13.0311H14.2633L13.524 10.9821L12.7743 13.0311Z"></path>
                    </svg>
                    {{ $t('label.translateToRu') }}
                </el-link>
            </div>
            <div class="description" v-html="translation.description" v-if="translation.show"></div>
            <div class="description" v-html="description" v-else></div>


            <el-tabs v-model="activeTab" class="round-table">
                <el-tab-pane name="news" v-if="news.total">
                    <span slot="label">{{$t('label.news')}}</span>

                    <div class="subFilter">
                        <div class="totalSum">{{$t('label.totalNews')}}: <span>{{ news.total }}</span></div>

                    </div>
                    <div class="table-like iqTab companyNews sortTab">
                        <div class="tbody">
                            <div>
                                <div :class="['sortBy', news.sortDir]"
                                     @click="handleSortBy">
                                    <div class="flex-center">
                                        <div>{{$t('label.date')}}</div>
                                        <span></span>
                                    </div>
                                </div>
                                <div>{{$t('label.source')}}</div>
                                <div>{{$t('label.title')}}</div>
                            </div>
                        </div>
                        <div class="tbody" ref="tbody">
                            <div v-for="(item, key) in news.list"
                                 :key="key">
                                <div>{{ item.dateFormatted }}</div>
                                <div>{{ item.hostName }}</div>
                                <div>
                                    <a :href="item.url" target="_blank">{{ item.title }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>

                <el-tab-pane v-for="item in investmentsToShow" :key="item.type" v-loading="invLoading"
                             :name="item.type" >
                    <span slot="label">{{ $t(item.name) }} <el-tag type="primary">{{ item.result.total }}</el-tag></span>
                    <div class="subFilter">
                        <div class="totalSum">{{ $t('label.totalSum') }}: <span>$ {{ item.totalSum }}</span></div>
                    </div>
                    <Investment v-model="item.result"
                                ref="investment"
                                :showSubtypeFilter="true"
                                @sortBy="sortBy => investmentSort(sortBy,item)">
                    </Investment>
                </el-tab-pane>

            </el-tabs>

        </div>
        <div class="right-block">
            <div class="attr" v-if="doc.investmentTotal && doc.investmentTotal > 0">
                <div class="attr-title">{{$t('label.investmentTotal')}}</div>
                <div class="attr-value">
                    $ {{ investmentFormat }}
                </div>
            </div>
            <div class="attr" v-if="doc.countries && doc.countries.length">
                <div class="attr-title">{{$t('label.hqCountry')}}</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.countries" :key="i">
                        <flag :iso="item.iso" :squared="false" v-if="item.iso" class="mr-2"></flag>
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
            <template v-if="doc.facetValues">
                <div class="attr" v-if="doc.facetValues.trend">
                    <div class="attr-title">{{$t('label.trends')}}</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.trend.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
                <div class="attr" v-if="doc.facetValues.industry">
                    <div class="attr-title">{{$t('label.industries')}}</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.industry.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
            </template>
            <div class="attr" v-if="doc.companyAddress">
                <div class="attr-title">{{ $t('label.companyAddress') }}</div>
                <div class="attr-value" v-html="doc.companyAddress">
                </div>
            </div>
            <div class="attr" v-if="doc.companySize">
                <div class="attr-title">{{ $t('label.numberOfEmployees') }}</div>
                <div class="attr-value" v-html="doc.companySize">
                </div>
            </div>
            <div class="attr" v-if="doc.companyStatus">
                <div class="attr-title">{{ $t('label.companyStatus') }}</div>
                <div class="attr-value text-all-caps" v-html="doc.companyStatus">
                </div>
            </div>
            <div class="attr" v-if="doc.companyRank">
                <div class="attr-title">{{ $t('label.companyRank') }} (Crunchbase)</div>
                <div class="attr-value" v-html="rankFormat">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import uuid from 'uuid';
import Investment from './Investment';
import i18n from '@/commons/i18n';
import UserTags from '@/commons/components/UserTags.vue';
import _ from "lodash";


export default {
    name: "CompanyCard",
    components: {Investment, UserTags},
    data() {
        return {
            doc: JSON.parse(window.sessionStorage.getItem('companyCard')) || {},
            investmentFormat: '',
            rankFormat: '',
            activeTab: 'news',
            logoUrl: '',
            owner: {},
            founded: '',
            description: '',
            news: {
                total: 0,
                sortDir: 'DESC',
                list: []
            },
            curLang: '',
            currentTab: {},
            investments: [
                {
                    name: 'investments.funding',
                    type: 'funding',
                    result: {
                        total: 0,
                        list: [],
                        fullList: []
                    },
                },
                {
                    name: 'investments.investments',
                    type: 'venture',
                    result: {
                        total: 0,
                        list: [],
                        fullList: []
                    },
                },
                {
                    name: 'investments.acquisitions',
                    type: 'ma',
                    result: {
                        total: 0,
                        list: [],
                        fullList: []
                    },
                },
                {
                    name: 'IPO',
                    type: 'ipo',
                    result: {
                        total: 0,
                        list: [],
                        fullList: []
                    },
                },

            ],
            invLoading: true,
            promises: [],
            translation: {
                show: false
            },
            languages: [
                {name: 'ru', label: 'Translate to RU'},
                // {name: 'en', label: 'EN'},
                // {name: 'de', label: 'DE'},
                // {name: 'fr', label: 'FR'}
            ],
            fullUserTagList: [
                {id: 1, color: 1, name: 'Favorites'},
            ],
            investmentSortBy: {
                field: 'date',
                dir: 'DESC'
            },
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        investmentsToShow(){
            return this.investments.filter(it => it.result.total > 0);
        },
    },
    mounted() {
        let self = this;
        services.system.info().then(function (resp) {
            if (resp.data.userSettings) {
                self.userSettings = resp.data.userSettings;
                if (self.userSettings.locale) {
                    i18n.locale = self.userSettings.locale;
                    self.$store.commit('locale', self.userSettings.locale);
                }
            }
            self.getUserTagList();
            self.refresh();
        });
        window.addEventListener('storage', () => {
            if (window.localStorage.getItem('changeUserTags')) {
                self.getUserTagList();
            }
        });
        self.$services.userEvents.publish({
            type: 'openCard',
            data: {docIds: [{type: this.doc.type, id: this.doc.id}]}
        });
    },
    methods: {
        refresh() {
            let _doc = _.cloneDeep(this.doc),
                self = this,
                id = (_doc && _doc.id) ? _doc.id : this.$route.query.id || "111",
                type = "Companies",
                filter = {
                    ids: [id],
                    fullness: 'FULL',
                    count: 1,
                    types: [type]
                };
            this.$services.documents.search(filter).prepare().then(inc => {
                let docFromDb = inc.data.list[0],
                    alias = {
                        ma: 'M&A',
                        'm&a': 'M&A',
                        'M&A': 'M&A',
                        ipo: 'IPO',
                        venture: 'Venture',
                        'post_ipo_debt': 'Post IPO Debt'
                    };
                if (!Object.keys(_doc).length) {
                    _doc = inc.data.list[0];
                    // console.log('Inc _doc', _doc);
                }
                console.log('__doc', _doc);
                self.doc = Object.assign(_doc, {
                    url: _doc.url ? new URL(_doc.url) : '',
                    countriesFormat: (_doc.countries || []).join('<i class="divider"></i>'),
                    investmentFormat: this.$utils.toFin(_doc.investmentTotal),
                    rankFormat: this.$utils.toFin(_doc.companyRank),
                    userTags: docFromDb.tags
                });
                for (let prop in this.doc) {
                    let value = this.doc[prop];
                    if (_.isArray(value) && !value.length) {
                        delete this.doc[prop];
                    }
                }
                self.investmentFormat = this.$utils.toFin(this.doc.investmentTotal);
                self.rankFormat = this.$utils.toFin(this.doc.companyRank);
                self.founded = _.compact([this.doc.countriesFormat, this.doc.year]).join(', ');
                self.logoUrl = (this.doc.icon && this.doc.icon.url && !this.doc.icon.url.includes('placeholder_')) ? this.doc.icon.url.replace('f_jpg', 'f_auto,b_white,q_auto:eco') : 'noLogo2.png';
                self.description = this.description = (_doc.description && _doc.description.full) ? _doc.description.full
                    : typeof (_doc.description) === 'string' ? _doc.description : '';

                let highlights = _.merge({full: {}, fragments: {}}, _doc.highlights),
                    keywordsToHighlights = {};

                for (let prop in highlights.full) {
                    if (!prop || prop === 'id' || prop === 'type') {
                        continue;
                    }
                    if (prop.startsWith('systemKeywords')) {
                        keywordsToHighlights[_.get(_doc, prop)] = highlights.full[prop];
                        continue;
                    }
                    _.set(_doc, prop, highlights.full[prop]);
                }

                _doc.keywords = (_doc.systemKeywords || []).map(kw => keywordsToHighlights[kw] || kw);

                self.doc.countries = _doc.countries.map(it => {
                    return {
                        name: it,
                        iso: self.$utils.countryToISO((it || '').toLowerCase())
                    }
                })


                /************* News ************/
                if (self.doc.companyNews && self.doc.companyNews.length) {
                    self.news.list = self.doc.companyNews.map(it => {
                        it.dateFormatted = moment(it.date).format('DD.MM.YYYY');
                        return it;
                    });
                    self.news.total = self.doc.companyNews.length;
                }

                console.log('this.news', this.news);

                /*********** Investments ***********/

                let investorsFilter = {
                    count: 1200,
                    offset: 0,
                    investment: {
                        investorIds: [this.doc.id]
                    },
                    order: [{
                        field: 'date',
                        type: 'DESC'
                    }, {
                        field: 'name',
                        type: 'ASC'
                    }]
                }

                this.promises.push(this.$services.documents.investment(investorsFilter).then(resp => {
                    let result = resp.data;
                    _.each(self.investments, it => {
                        let _ini = 0,
                            _investor = self.investments.find(inv => inv.type === it.type),
                            _list = _.orderBy(result.list.filter(item => item.type === it.type), 'date', 'desc');
                        if (_list && _list.length) {
                            it.totalSum = this.$utils.toFin(_list.reduce((_sum, n) => _sum + parseInt(n.sum || 0), _ini));
                            if (result.total && _list.length) {
                                _investor.result = {
                                    list: _list,
                                    fullList: _.cloneDeep(_list),
                                    total: _list.length
                                }
                            }
                        }
                    });
                    console.log('self.investments', self.investments);
                    // console.log('inv resp', resp.data);
                }));

                let investeeFilter = {
                    count: 200,
                    offset: 0,
                    type: 'venture',
                    ids: [this.doc.id],
                    order: [{
                        field: 'date',
                        type: 'DESC'
                    }, {
                        field: 'name',
                        type: 'ASC'
                    }]
                }
                this.promises.push(this.$services.documents.investment(investeeFilter).then(resp => {
                    let result = resp.data,
                        _investee = self.investments.find(inv => inv.type === 'funding'),
                        _ini = 0;
                    result.list = _.orderBy(result.list, 'date', 'desc');
                    _investee.totalSum = this.$utils.toFin(result.list.reduce((_sum, n) => _sum + parseInt(n.sum || 0), _ini));

                    // console.log('invtee resp', resp.data);

                    if (result.total) {
                        _investee.result = {
                            list: result.list,
                            fullList: _.cloneDeep(result.list),
                            total: result.list.length
                        }
                        let _owner = (result.list.find(it => {
                            return (it.subtype || '').toLowerCase() === "m&a" && (it.type || '').toLowerCase() === "ma";
                        }) || {}).investors;
                        self.owner = _owner && _owner.length ? _owner[0] : {};
                        // console.log('owner', self.owner);
                    }
                }));

                Promise.all(this.promises).then(() => {
                    self.invLoading = false;
                    self.investments = _.cloneDeep(self.investments);
                    if (!this.news.total && self.investments) {
                        this.activeTab = (self.investments.find(it => {
                            return it.result.total > 0;
                        }) || {}).type;
                    }
                    // console.log('---------- Investments', self.investments, self.subtypes);
                })
                console.log('Doc fin:::', self.doc);
            })
        },
        getUserTagList() {
            this.$services.tags.list({
                count: 500,
                offset: 0,
                userIds: [this.userInfo.id]
            }).prepare().then(resp => {
                if (resp && resp.data) {
                    this.fullUserTagList = resp.data.list;
                }
            });
        },
        saveUserTag(item) {
            if (item) {
                let query = {
                    id: item.id ? item.id : null,
                    name: item.name,
                    color: item.color
                };
                this.$services.tags.save(query).prepare().then(resp => {
                    this.getUserTagList();
                    parent.localStorage.setItem('changeUserTags', JSON.stringify(item));
                    this.$emit('updateUserTagList');
                });
            }
        },
        deleteUserTag(tagId) {
            if (tagId) {
                this.$services.tags.delete([tagId]).prepare().then(resp => {
                    this.fullUserTagList = this.fullUserTagList.filter(it => it.id !== tagId);
                    this.getUserTagList();
                    parent.localStorage.setItem('changeUserTags', 'deleted tag ' + tagId);
                    this.$emit('updateUserTagList');
                });
            }
        },
        userTagAction(data) {
            parent.localStorage.setItem('userTagAction', JSON.stringify(data));
        },
        investmentSort(sortBy, item) {
            item.result.list = _.orderBy(item.result.list.map(it => {
                it['investment.date'] = it.date;
                it.sum = it.sum ? parseInt(_.clone(it).sum) : 0;
                return it;
            }), sortBy.field, sortBy.dir.toLowerCase());
            this.investments = _.cloneDeep(this.investments);
        },
        handleToggleFavorite(data) {
            parent.localStorage.setItem('changeFav', JSON.stringify(data));
        },
        handleSortBy() {
            this.news.sortDir = this.news.sortDir === 'DESC' ? 'ASC' : 'DESC';
            this.news.list = _.orderBy(this.news.list, 'date', this.news.sortDir.toLowerCase());
        },
        handleOpenCompanyCard(item) {
            if (item.id) {
                window.sessionStorage.removeItem('companyCard');
                window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
            }
        },

        handleTranslateItem(lang) {
            let item = this,
                toTranslate = '';
            if (this.curLang === lang) {
                this.curLang = null;
                item.translation.show = false;
            } else if (item.translation && item.translation.title) {
                this.curLang = lang;
                item.translation.show = true;
            } else {
                this.curLang = lang;
                item.lang = lang;
                item.translation = {
                    title: '<div class="text-info">Translation in progress...</div>',
                    description: '<div class="text-center text-info my-3"><i class="fas fa-2x fa-spinner fa-pulse"></i></div>',
                    show: true
                };

                toTranslate = item.description;

                this.$utils.translateText({
                    text: toTranslate.substring(0, 4999),
                    tl: lang
                }).then(function (response) {
                    if (response && response.data && response.data.description) {
                        item.translation = _.merge({}, item.translation, {
                            description: response.data.description,
                            show: true
                        });
                    }
                });
            }
        },
        uuid() {
            return uuid();
        },
        sortFunc: (a, b) => {
            let $a = a.indexOf('<em>') !== -1 ? 0 : 1;
            let $b = b.indexOf('<em>') !== -1 ? 0 : 1;
            return $a === $b ? 0 : $a > $b ? 1 : -1;
        },
        handleContextMenu(event) {
            this.$utils.wikiTranslateContext(this, event);
        },
        handleFilterSubType(item) {
            let self = this,
                _tab = self.investments.find(inv => inv.type === self.activeTab);
            _tab.result.list = _tab.result.fullList.filter(it => {
                return !item || it.subtype === item;
            });
            self.investments = _.cloneDeep(self.investments);
        },
        handleTabClick(item) {
            console.log('item', item);
            let self = this,
                _tab = self.investments.find(inv => inv.type === item.name);
            if (_tab) {
                self.subtypes = _.cloneDeep(_tab.subtypes);
                self.subtypes.splice(0, 0, {value: '', label: 'All'});
                self.subtype = '';
                self.currentTab = _tab;
            }
        },

    }
}
</script>

<style lang="scss">
.fst-SearchListCard {
    &.fst-CompanyCard {

        div.iqTab.companyNews {
            .tbody {
                > div {
                    > div {
                        &:first-child {
                            flex: 0;
                            flex-basis: 90px;
                        }

                        &:nth-child(2) {
                            flex: 0;
                            flex-basis: 20%;
                        }

                    }
                }

                &:last-child {
                    > div {
                        > div {
                            &:last-child {
                                font-weight: 500;
                            }

                        }
                    }
                }
            }
        }

        div.right-block {
            flex-basis: 450px;
        }

        .owner {
            display: flex;
            align-items: center;

            .owner-logo {
                width: 50px;
                height: 50px;
                padding: 5px;
                border-radius: 10px;
                box-sizing: border-box;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                border: 1px solid $iq-border-color-base;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            a {
                margin-left: 4px;
                text-decoration: none;
            }
        }

        .Funding {
            .investee {
                .tags {
                    display: none;
                }
            }
        }
    }

    .subFilter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-left: 10px;
        font-size: 14px;
        font-weight: 400;
        margin: -5px 0 0;
        font-variant: all-small-caps;
        color: $iq-color-text-primary;
        min-height: 35px;
        box-sizing: border-box;

        .totalSum {
            span {
                font-weight: 700;
                color: #000;
            }
        }

        .subtypes {
            display: flex;
            align-items: center;
        }
    }

    .fst-Investment {
        height: auto;
        border: none;
        padding: 0;

        div.iqTab .tbody {
            max-height: none;
        }
    }

    .Funding {
        .fst-Investment {
            .iqTab {
                .tbody > div > div:first-child {
                    //display: none;
                }
            }
        }
    }
}
</style>


<style lang="scss">
//--- moved to commons.scss
</style>