const GROUP_LIST_COUNT = 20;

class Groups {
    constructor (list = []) {
        this.groupList = list;
        this.listCount = GROUP_LIST_COUNT;
        this.listOffset = 0;
    }

    get count () {
        return this.listCount;
    }

    get offset () {
        return this.listOffset;
    }

    set offset (value) {
        if (value >= this.total) {
            value = this.count * (Math.floor(this.total / this.count) - 1);
        }
        this.listOffset = value;
    }

    get list () {
        return this.fullList.slice(this.listOffset, this.listOffset + this.listCount);
    }

    get fullList () {
        let result = [...this.groupList];
        result.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }

    get total () {
        return this.groupList.length;
    }

    fill (list) {
        this.groupList = list;
        this.offset = this.offset;
    }

    clear () {
        this.groupList = [];
        this.listOffset = 0;
    }
}

export {
    GROUP_LIST_COUNT,
    Groups,
};
