<template>
    <div class="widget-block">
        <div class="header">
            <div class="title">
                <span @click="handleWidgetModeChange('science')"
                      :class="['mr-1',{'dashboard-red': widgetMode==='science'}]">{{ $t('dashboard.Science') }}</span>
                {{ $t('dashboard.and') }}
                <span @click="handleWidgetModeChange('patents')"
                      :class="['mx-1',{'dashboard-orange': widgetMode==='patents'}]">{{ $t('dashboard.Patents') }}</span>
                {{ $t('dashboard.dynamics') }}
                <div class="viewAll noClick">{{ $t('dashboard.viewAll') }}
                    <span @click="viewAll('Science')">{{ $t('dashboard.Science') }}</span>
                    {{ $t('dashboard.or') }}
                    <span @click="viewAll('Patents')">{{ $t('dashboard.Patents') }}</span>
                </div>
            </div>
            <div class="widgetMode">
                <span
                    @click="handleWidgetModeChange('chart')"
                    :class="{active: widgetMode === 'chart'}">
                    <el-tooltip effect="dark" placement="top" content="Graph" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
                <el-tooltip effect="dark" placement="top" content="Science papers" :open-delay="600">
                    <span @click="handleWidgetModeChange('science')"
                          :class="['science',{active: widgetMode === 'science'}]">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                  fill="currentColor"/>
                        </svg>
                        {{ $t('dashboard.s') }}
                    </span>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top" content="Patents" :open-delay="600">
                    <span @click="handleWidgetModeChange('patents')"
                          :class="['patents',{active: widgetMode === 'patents'}]">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                      fill="currentColor"/>
                            </svg>
                        {{ $t('dashboard.p') }}
                    </span>
                </el-tooltip>
            </div>

        </div>
        <div class="chart-container"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <apexchart
                v-show="chartSeries.length && widgetMode === 'chart'"
                ref="chart"
                :options="chartOptions"
                :series="chartSeries"
                @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div :class="['newsCards', widgetMode]" v-show="chartSeries.length && widgetMode === 'science'">
                <div @click="handleOpenCompanyCard(item, 'science')" class="cardItem" v-for="item in science"
                     :key="item.id">
                    <div class="title" v-html="item.title"></div>
                    <div class="tags">
                        <div v-for="(tag, tk) in item.keywords" :key="tk" v-html="tag"></div>
                    </div>
                    <div class="attrs">
                        <span>{{ item.dateFormatted }}</span>
                        <div class="attrs-item nowrap" v-html="item.countriesFormat"></div>
                    </div>
                </div>
            </div>
            <div :class="['newsCards', widgetMode]" v-show="chartSeries.length && widgetMode === 'patents'">
                <div @click="handleOpenCompanyCard(item, 'patents')" class="cardItem" v-for="item in patents"
                     :key="item.id">
                    <div class="title" v-html="item.title"></div>
                    <div class="tags">
                        <div v-for="(tag, tk) in item.keywords" :key="tk" v-html="tag"></div>
                    </div>
                    <div class="attrs">
                        <span>{{ item.dateFormatted }}</span>
                        <div class="attrs-item" v-html="item.companiesFormat"></div>
                    </div>
                </div>
            </div>

            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "SciencePatentArticles",
    props: {
        request: Object,
        chartOptions: Object,
    },
    data() {
        return {
            widgetMode: 'chart',
            loading: true,
            curYear: new Date().getFullYear(),
            chartSeries: [],
            news: [],
            patents: [],
            science: [],
            reQ: {
                chart: {},
                science: {},
                patents: {},
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        request() {
            this.refresh();
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this,
                docTypes = ['Science', 'Patents'],
                promises = [];

            let query = _.cloneDeep(self.request)

            delete query.widgets;
            query.widgets = [
                'sciencePatentsDynamics',
            ];

            let textQuery = {
                count: 10,
                offset: 0,
                duplicate: false,
                explain: false,
                facets: {calculate: false},
                profile: false,
                query: self.request.filter.query,
                nativeQuery: self.request.filter.nativeQuery,
                semantic: false,
                spam: false,
                subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                suggestQuery: false,
                types: ["Science"],
            }

            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                    self.reQ.science.cancel();
                    self.reQ.patents.cancel();
                } catch (e) {
                    console.error(e);
                }
            }
            self.loading = true;

            self.reQ.chart = self.$services.analytics.fullView(query);
            self.reQ.science = self.$services.documents.search(textQuery);
            textQuery.types = ["Patents"];
            self.reQ.patents = self.$services.documents.search(textQuery);

            promises.push(self.reQ.chart);
            promises.push(self.reQ.science);
            promises.push(self.reQ.patents);

            Promise.all(promises).then(resp => {
                let _data = resp[0].data.yearDynamics,
                    _cagr = resp[0].data.sciencePatentsCagr,
                    _science = resp[1].data.list,
                    _patents = resp[2].data.list;

                //---------- Sci & Pats Articles ----------
                self.chartSeries.forEach(it => {
                    it.data = [];
                })
                let _colors = [
                    '#C46370', // Red2
                    '#C69245', // Orange2
                    '#8599D0', // BlueBright
                    '#61B768', // Green2
                    '#BABABA'  // White2
                ];
                // delete _data.News;

                self.chartSeries = docTypes.map(name => {
                    let it = _data[name],
                        _out = {},
                        cagr = self.$utils.roundX((_cagr[name] || 0) * 100, 1);
                    _out.name = self.$t('dashboard.' + name) + (cagr ? ` (CAGR: ${cagr}%)` : '');
                    _out.nameRaw = name;
                    _out.data = [];
                    return _out;
                });
                let _years = _.flatten(_.map(_data, (it) => {
                        return Object.keys(it);
                    })),
                    ii = 0;
                docTypes.forEach(name => {
                    let line = _data[name];
                    if (line) {
                        for (let year = _.min(_years); year <= _.max(_years); year++) {
                            self.chartSeries[ii].data.push({
                                x: year,
                                y: line[year] ? line[year] : null
                            });
                        }
                        ii++;
                    }
                });

                self.science = self.createDocuments(_science);
                self.patents = self.createDocuments(_patents);


                let options = {
                    colors: _colors,
                    chart: {
                        type: 'line',
                        height: '380px',
                    },
                    markers: {
                        size: 5,
                        hover: {
                            size: 7
                        }
                    },
                    tooltip: {
                        enabled: true,
                        intersect: true,
                        shared: false,
                        onDatasetHover: {
                            highlightDataSeries: true,
                        },
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'left',
                    },
                    stroke: {
                        width: 4,
                        curve: 'straight'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            align: 'right',
                            style: {
                                fontSize: '14px'
                            },
                            formatter: function (val, index) {
                                return self.$utils.toFinHuman(val, 0).number;
                            }
                        },
                    },
                }
                self.$refs.chart.updateOptions(options)

                self.loading = false;
                // console.log('-- series Sci&Pats Articles: ', self.chartSeries);
            }).catch(e => {
                console.log(e);
            });
        },
        dataPointSelectionHandler(e, chartContext, config) {
            let self = this;
            let _series = self.chartSeries[config.seriesIndex],
                _year = _series.data[config.dataPointIndex].x || '';
            if (e.button === 0) {
                self.viewAll(_series.nameRaw, _year.toString());
            }

        },
        createDocuments(item) {
            return item.map(item => {
                let highlights = _.merge({full: {}, fragments: {}}, item.highlights),
                    keywordsToHighlights = {};
                for (let prop in highlights.full) {
                    if (!prop || prop === 'id' || prop === 'type') {
                        continue;
                    }
                    if (prop.startsWith('systemKeywords')) {
                        keywordsToHighlights[_.get(item, prop)] = highlights.full[prop];
                        continue;
                    }
                    _.set(item, prop, highlights.full[prop]);
                }

                // keywords
                item.keywords = (item.systemKeywords || []).map(kw => keywordsToHighlights[kw] || kw);
                item.title = item.title || _.truncate(item.description, {'length': 100});
                item.dateFormatted = moment(item.date).format('ll');

                let countries = (item.countries || []).filter(it => it && it !== 'null');
                let companies = item.companies || [];
                item.countriesFormat = countries.join('<i class="divider"></i>');

                item.companiesFormat = (count => {
                    return count ? (count > 1 ? `${companies[0]} (+${count - 1})` : companies[0]) : '';
                })(companies.length)

                return item;
            })
        },
        viewAll(docType = 'Science', year = null) {
            let self = this;
            let years = [];
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            let facets = {
                requests: [
                    {
                        type: yearType,
                        values: year ? [year] : years
                    },
                ]
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: self.request.filter.spam,
                duplicate: false,
            }
            self.$utils.openSearch(params);
        },
        handleOpenCompanyCard(item, docType) {
            if (item.id) {
                if (docType === 'patents') {
                    window.sessionStorage.setItem('patentCard', JSON.stringify(item));
                    window.open(this.$router.resolve({name: 'patentCard'}).href + '?id=' + item.id, '_blank');
                } else {
                    window.sessionStorage.setItem('card', JSON.stringify(item));
                    window.open(this.$router.resolve({name: 'card'}).href + '?id=' + item.id + '&type=' + item.type, '_blank');
                }
            }
        },
        handleWidgetModeChange(mode) {
            this.widgetMode = mode;
            if (mode === 'chart') {
                this.reload();
            }

        },
        reload() {
            this.$nextTick(() => {
                this.$refs.chart.updateOptions({})
            });
        },

    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
</style>