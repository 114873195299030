<template>
    <div class="fst-UserEvents">
        <div class="admin-content mw-950">
            <div class="">
                <UserEventReportForm ref="form"/>
                <el-button class="mt-3" type="primary" @click="handleDownload"><i class="fas fa-download mr-1"></i> Download
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import UserEventReportForm from './actions/UserEventReportForm'

export default {
    name: `UserEvents`,
    components: {UserEventReportForm},
    methods: {
        handleDownload() {
            this.$refs.form.download();
        }
    }
}
</script>

<style scoped>

</style>