<template>
<div class="fst-Logging" v-loading="loading">
    <el-input placeholder="Please input" v-model="form.predicate" @change="refresh(0)" class="input-with-select">
        <el-select v-model="form.field" slot="prepend" placeholder="Select">
            <el-option label="Logger and Message" value="predicate" />
            <el-option label="threadName" value="threadName" />
            <el-option label="callerFilename" value="callerFilename" />
            <el-option label="callerClass" value="callerClass" />
            <el-option label="callerMethod" value="callerMethod" />
        </el-select>
        <el-button slot="append" @click="refresh(0)" icon="el-icon-search"></el-button>
    </el-input>
    <el-table :data="page.list" :row-class-name="rowClassName">
        <el-table-column type="expand">
            <template slot-scope="scope">
                <p><b>Logger</b>: {{ scope.row.loggerName }}</p>
                <p><b>Thread</b>: {{ scope.row.threadName }}</p>
                <p><b>Caller</b>: {{ formatCaller(scope.row, scope.column) }}</p>
                <p><b>File name</b>: {{ scope.row.callerFilename }}</p>
            </template>
        </el-table-column>
        <el-table-column label="date" :formatter="formatDate" width="90px"/>
        <el-table-column label="level" prop="levelString" width="60px"/>
        <el-table-column label="message" prop="formattedMessage" />
    </el-table>
    <Pager :pagedList="page" :pageSize="pageCount" @offset-change="refresh"/>
</div>
</template>

<script>
import _ from 'lodash';
import Dates from '@/commons/utils/dates';
import Pager from '@/commons/components/Pager';

export default {
    name: 'Logging',
    components: {Pager},
    props: {
        incEvent: Object
    },
    data() {
        return {
            form: {
                predicate: '',
                field: 'predicate'
            },
            loading: false,
            page: {
                list: [],
                total: 0,
                offset: 0
            },
            pageCount: 30
        };
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) {
                this[newVal.incEvent]({});
            }
        }
    },
    mounted() {
        this.refresh(0);
    },
    methods: {
        refresh(offset = 0) {
            this.loading = true;
            const filter = {offset, count: this.pageCount, order: [{field:'date', type: 'DESC'}]};
            if(this.form.predicate) {
                filter[this.form.field] = {value: this.form.predicate, caseInsensitive: true, type: 'LIKE'};
            }
            this.$services.logging.list(filter).prepare().then(resp => {
                this.page = resp.data;
                setTimeout(()=> {
                    this.loading = false;
                }, 500)
            });
        },
        refresh_logging(){
            this.refresh();
        },
        rowClassName({row}) {
            switch (row.levelString) {
                case 'WARN': return 'fst-log-row-warning';
                case 'ERROR': return 'fst-log-row-danger';
                case 'DEBUG':
                case 'TRACE':
                    return 'fst-log-row-info';
            }
            return '';
        },
        formatCaller(row, col) {
            return `${row.callerClass}#${row.callerMethod}:${row.callerLine}`;
        },
        formatDate(row, col) {
            return Dates.toMoment(row.date).format('YYYY-MM-DD HH:mm:ss');
        }
    }
}
</script>

<style lang="scss">
@function light_bg($bg_color) {
    @return lighten($bg_color, 38%);
}
.fst-Logging {
    background: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    border: 1px solid #D7E1FC;
    .el-table {
        .fst-log-row-primary {
            background-color: light_bg($iq-color-primary);
        }

        .fst-log-row-success {
            background-color: light_bg($iq-color-success);
        }

        .fst-log-row-warning {
            background-color: light_bg($iq-color-warning);
        }

        .fst-log-row-danger {
            background-color: light_bg($iq-color-danger);
        }

        .fst-log-row-info {
            background-color: light_bg($iq-color-info);
        }
    }
}
</style>
<style lang="scss" scoped>
    .el-table{
        max-height: calc(100vh - 210px);
        overflow-y: auto;
    }
</style>