<template>
    <div class="widget-block" :class="`SW_${name}`">
        <div class="header">
            <div class="title">
                {{title}}
                <el-tooltip class="hint" v-if="params.hint"
                            effect="light"
                            :open-delay="500">
                    <div slot="content" v-html="$t(params.hint)"></div>
                    <i class="far fa-question-circle ml-2 cursor-help"></i>
                </el-tooltip>
                <div class="viewAll" @click="viewAll(params.docType)"
                     v-if="this.$store.getters.swList.mode === 'all'"
                >{{ $t('dashboard.viewAll') }}
                </div>
            </div>
            <div class="widgetMode">
                <span @click="handleWidgetModeChange('chart')"
                      :class="{active: widgetMode === 'chart'}">
                    <el-tooltip effect="light" placement="top" content="Graph" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                  fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
                <span @click="handleWidgetModeChange('news')"
                      :class="{active: widgetMode === 'news'}">
                    <el-tooltip effect="light" placement="top" :content="params.docType || 'News'" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                  fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
                <export-to-image :filename="title" selector=".chart-container"></export-to-image>
            </div>

        </div>
        <div class="chart-container"
             v-loading="loading">
            <apexchart
                    v-show="chartSeries.length && widgetMode !== 'news'"
                    ref="chart"
                    :options="chartOptions"
                    :series="chartSeries"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="newsCards news" v-show="chartSeries.length && widgetMode === 'news'">
                <a :href="item.innerUrl" target="_blank" class="cardItem" v-for="item in news" :key="item.id">
                    <div class="title" v-html="item.title"></div>
                    <div class="tags">
                        <div v-for="(tag, tk) in item.keywords" :key="tk" v-html="tag"></div>
                    </div>
                    <div class="attrs">
                        <span>{{ item.dateFormatted }}</span>
                        <a :href="item.url" target="_blank">{{ item.hostingName || item.id }}</a>
                    </div>
                </a>
            </div>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWSciencePatentArticles",
        components: {ExportToImage},
        props: {
            title: String,
            name: String,
            filter: Object,
            years: Object,
            chartOptions: Object,
            params: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                curYear: new Date().getFullYear(),
                chartSeries: [],
                widgetMode: 'chart',
                news: [],
                result: {},
                loading: true,
                reQ: {
                    chart: {}
                },
                chartType: {
                    "GROWTH": {
                        dataKey: 'counts',
                        chartFunc: 'createChart',
                    },
                    "PATENT_DYNAMICS": {
                        dataKey: 'years',
                        chartFunc: 'createMultiChart',
                        series: [
                            {
                                name: 'patents',
                                title: 'label.patents',
                                facet: 'YEAR',
                            },
                            {
                                name: 'applications',
                                title: 'label.applications',
                                facet: 'APPLICATION_YEAR',
                            },
                            {
                                name: 'families',
                                title: 'label.families',
                                facet: 'APPLICATION_YEAR',
                                facetRequest: {
                                    type: "PATENT_FAMILY_HEAD",
                                    value: true
                                }
                            },
                        ]
                    },
                }
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            this.params.type = this.params.type || "GROWTH";
            if (this.params.series) {
                this.chartType[this.params.type].series = this.params.series
            }
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let widgetFilter = _.cloneDeep(self.filter);
                if (self.params.docType) {
                    widgetFilter.types = [self.params.docType]
                }
                let query = {
                    "filter": widgetFilter,
                    "widgets": {
                        "dynamics": {
                            "type": self.params.type,
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        }
                    }
                };
                let textQuery = _.cloneDeep(widgetFilter);
                textQuery.types = [self.params.docType] || ['News'];

                _.forEach(self.reQ, req => {
                    if (req.then) {
                        try {
                            req.cancel();
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });

                self.loading = true;
                if (self.widgetMode === 'chart') {
                    self.reQ.chart = self.$services.analytics.widgets(query);
                    self.reQ.chart.then(resp => {
                        // console.log('Resp dynamics:', resp.data);
                        let _wD = resp.data.widgets.dynamics,
                            _cT = self.chartType[self.params.type];


                        //---------- Sci & Pats Articles ----------
                        let _colors = self.params.colors || [
                            'hsl(224, 65%, 65%)', // BlueBright
                            'hsl(36, 63%, 52%)', // Orange2
                            'hsl(0, 55%, 55%)', // Red2
                            '#5d6d63',  // Feldgrau
                            'hsl(125, 67%, 55%)', // Green2
                        ];

                        self[_cT.chartFunc](_wD, _cT.dataKey);

                        let options = {
                            colors: _colors,
                            chart: {
                                type: 'line',
                                height: '380px',
                                toolbar: {
                                    export: {
                                        svg: {
                                            filename: this.title
                                        },
                                        png: {
                                            filename: this.title
                                        }
                                    }
                                },
                            },
                            markers: {
                                size: 5,
                                hover: {
                                    size: 7
                                }
                            },
                            tooltip: {
                                enabled: true,
                                intersect: true,
                                shared: false,
                                onDatasetHover: {
                                    highlightDataSeries: true,
                                },
                            },
                            legend: {
                                show: true,
                                showForSingleSeries: true,
                                position: 'bottom',
                                horizontalAlign: 'left',
                            },
                            stroke: {
                                width: 4,
                                curve: 'straight'
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: {
                                labels: {
                                    show: true,
                                    align: 'right',
                                    style: {
                                        fontSize: '14px'
                                    },
                                    formatter: function (val, index) {
                                        return self.$utils.toFinHuman(val, 0).number;
                                    }
                                },
                            },
                        }
                        self.$refs.chart.updateOptions(options)

                        // console.log('-- series Dynamics: ', self.chartSeries);
                        self.loading = false;
                    }).catch(e => {
                        console.log(e);
                    });
                } else {
                    self.reQ.documents = self.$services.documents.search(textQuery);
                    self.reQ.documents.then(resp => {
                        self.result.news = resp.data.list;
                        self.news = self.result.news.map(item => {
                            let highlights = _.merge({full: {}, fragments: {}}, item.highlights),
                                keywordsToHighlights = {};
                            for (let prop in highlights.full) {
                                if (!prop || prop === 'id' || prop === 'type') {
                                    continue;
                                }
                                if (prop.startsWith('systemKeywords') || prop.startsWith('systemKeywords')) {
                                    keywordsToHighlights[_.get(item, prop)] = highlights.full[prop];
                                    continue;
                                }
                                _.set(item, prop, highlights.full[prop]);
                            }

                            // keywords
                            item.innerUrl = this.$router.resolve({name: 'card'}).href + '?id=' + item.id + '&type=Patents';
                            item.keywords = _.uniq([].concat(item.systemKeywords || []).concat(item.systemKeywords || [])).map(kw => keywordsToHighlights[kw] || kw);
                            item.title = item.title || _.truncate(item.description, {'length': 100});
                            item.dateFormatted = moment(item.date).format('ll');
                            return item;
                        });
                        self.loading = false;

                    })
                }


            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this;
                let _series = self.chartSeries[config.seriesIndex],
                    _year = _series.data[config.dataPointIndex].x || '';
                if (e.button === 0) {
                    self.viewAll(self.params.docType, _year.toString(), _series.id);
                }

            },
            createChart(data, dKey) {
                let self = this,
                    _data = data[dKey],
                    _aagr = self.$utils.roundX(data.growth * 100, 2, 2),
                    _from = self.years.min,
                    _to = self.years.max;
                self.chartSeries = [{
                    name: self.$t(self.params.docType) + (_aagr ? ` (AAGR: ${_aagr}%)` : ''),
                    type: self.params.chartType || 'line',
                    data: []
                }]

                // self.labels = [];
                self.chartSeries[0].data = [];
                for (let year = _from; year <= _to; year++) {
                    let _item = _data[year];
                    self.chartSeries[0].data.push({
                        x: year,
                        y: _item || 0
                    });
                    // self.labels.push(_.pickBy(_item, (it, i) => i !== 'total'));
                }
            },
            createMultiChart(data, dKey) {
                let self = this,
                    _cT = self.chartType[self.params.type],
                    _aagr = self.$utils.roundX(data.patentGrowth * 100, 2, 2),
                    _data = data[dKey],
                    _from = self.years.min,
                    _to = self.years.max;
                (_cT.series || []).forEach((ser, ind) => {
                    self.chartSeries[ind] = {
                        name: self.$t(ser.title) + (ser.name === 'patents' ? (_aagr ? ` (AAGR: ${_aagr}%)` : '') : ''),
                        id: ser.name,
                        type: self.params.chartType || 'line',
                        data: []
                    };
                    for (let year = _from; year <= _to; year++) {
                        let _item = _data.find(yr => yr.year == year);
                        self.chartSeries[ind].data.push({
                            x: year,
                            y: _item[ser.name] || 0
                        });
                    }

                })
            },

            createDocuments(item) {
                return item.map(item => {
                    let highlights = _.merge({full: {}, fragments: {}}, item.highlights),
                        keywordsToHighlights = {};
                    for (let prop in highlights.full) {
                        if (!prop || prop === 'id' || prop === 'type') {
                            continue;
                        }
                        if (prop.startsWith('systemKeywords')) {
                            keywordsToHighlights[_.get(item, prop)] = highlights.full[prop];
                            continue;
                        }
                        _.set(item, prop, highlights.full[prop]);
                    }

                    // keywords
                    item.keywords = (item.systemKeywords || []).map(kw => keywordsToHighlights[kw] || kw);
                    item.title = item.title || _.truncate(item.description, {'length': 100});
                    item.dateFormatted = moment(item.date).format('ll');

                    let countries = (item.countries || []).filter(it => it && it !== 'null');
                    let companies = item.companies || [];
                    item.countriesFormat = countries.join('<i class="divider"></i>');

                    item.companiesFormat = (count => {
                        return count ? (count > 1 ? `${companies[0]} (+${count - 1})` : companies[0]) : '';
                    })(companies.length)

                    return item;
                })
            },
            viewAll(docType = 'Science', year = null, seriesId = null) {
                let self = this;
                let _cT = self.chartType[self.params.type];
                let _curSeries = (_cT.series || []).find(it => it.name === seriesId) || {};
                let yearType = docType === 'Investment' ? 'INVT_YEAR'
                    : seriesId ? (_curSeries || {}).facet || 'YEAR'
                        : 'YEAR'

                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: year ? [year] : self.years.years
                        },
                    ]
                }
                if (_curSeries.facetRequest) {
                    facets.requests.push(_curSeries.facetRequest);
                }

                // console.log('Facets', facets, self.filter);
                let params = {
                    query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: self.filter.spam,
                    duplicate: false,
                }
                self.$utils.openSearch(params);
            },
            handleOpenCompanyCard(item, docType) {
                if (item.id) {
                    if (docType === 'patents') {
                        window.sessionStorage.setItem('patentCard', JSON.stringify(item));
                        window.open(this.$router.resolve({name: 'patentCard'}).href + '?id=' + item.id, '_blank');
                    } else {
                        window.sessionStorage.setItem('card', JSON.stringify(item));
                        window.open(this.$router.resolve({name: 'card'}).href + '?id=' + item.id + '&type=' + item.type, '_blank');
                    }
                }
            },
            handleWidgetModeChange(mode) {
                let self = this;
                self.widgetMode = mode;
                self.refresh();
            },

            reload() {
                this.$nextTick(() => {
                    this.$refs.chart.updateOptions({})
                });
            },

        }
    }
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
</style>