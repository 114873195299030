<template>
    <div class="el-collapse-item" :class="{'is-active': isActive, 'is-disabled': disabled }"
         @mouseenter="$emit('mouseenter', $event)"
         @mouseleave="$emit('mouseleave', $event)"
    >
        <div role="tab"
            :aria-expanded="isActive"
            :aria-controls="`el-collapse-content-${id}`"
            :aria-describedby="`el-collapse-content-${id}`"
        >
            <div class="el-collapse-item__header"
                @click="handleHeaderClick"
                role="button"
                :id="`el-collapse-head-${id}`"
                :tabindex="disabled ? undefined : 0"
                @keyup.space.enter.stop="handleEnterClick"
                :class="{'focusing': focusing, 'is-active': isActive}"
                @focus="handleFocus"
                @blur="focusing = false"
            >
                <div>
                    <slot name="selectAll" v-if="selectAllCheckBox">
                        <el-checkbox v-model="selectAllStatus" @change="handleSelectAll"></el-checkbox>
                    </slot>
                    <slot name="title">{{ title }}</slot>
                </div>
                <i class="el-collapse-item__arrow el-icon-arrow-right" :class="{'is-active': isActive}"></i>
            </div>
        </div>
        <el-collapse-transition>
            <div class="el-collapse-item__wrap"
                v-show="isActive"
                role="tabpanel"
                :aria-hidden="!isActive"
                :aria-labelledby="`el-collapse-head-${id}`"
                :id="`el-collapse-content-${id}`"
            >
                <div class="el-collapse-item__content">
                    <slot></slot>
                </div>
            </div>
        </el-collapse-transition>
    </div>
</template>
<script>
    import ElCollapseTransition from 'element-ui/src/transitions/collapse-transition';
    import Emitter from 'element-ui/src/mixins/emitter';
    import {generateId} from 'element-ui/src/utils/util';

    export default {
        name: 'FstCollapseItem',
        componentName: 'FstCollapseItem',
        mixins: [Emitter],
        components: {ElCollapseTransition},
        data() {
            return {
                contentWrapStyle: {
                    height: 'auto',
                    display: 'block'
                },
                contentHeight: 0,
                focusing: false,
                isClick: false,
                id: generateId(),
                checkAll: true
            };
        },
        inject: ['collapse'],
        props: {
            title: String,
            selectAllCheckBox: Boolean,
            selectAllStatus: {
                type: Boolean,
                default: true,
            },
            name: {
                type: [String, Number],
                default() {
                    return this._uid;
                }
            },
            disabled: Boolean
        },
        computed: {
            isActive() {
                return this.collapse.activeNames.indexOf(this.name) > -1;
            }
        },
        methods: {
            handleFocus() {
                setTimeout(() => {
                    if (!this.isClick) {
                        this.focusing = true;
                    } else {
                        this.isClick = false;
                    }
                }, 50);
            },
            handleHeaderClick(event) {
                let classNames = event.target.className.split(' ');
                let isToggle = classNames.includes('el-collapse-item__arrow') || classNames.includes('title');

                if (this.disabled || !isToggle) return;

                this.dispatch('FstCollapse', 'item-click', this);
                this.focusing = false;
                this.isClick = true;
            },
            handleSelectAll(val){
                this.$emit('checkItAll', val);
            },
            handleEnterClick() {
                //this.dispatch('FstCollapse', 'item-click', this);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .el-collapse-item__header{
        .el-checkbox{
            padding-right: 10px;
        }
    }
</style>