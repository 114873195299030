import _ from 'lodash';


export default {
    state: {
        filter:{
            clusterFilter: {
                reportId: '',
                clusterIds: [],
                cluster: {},
            }
        }
    },
    actions: {
    },
    mutations: {
        updFilter(state, filter) {
            state.filter = filter;
        },
        clearClusterFilter(state, filter) {
            state.filter.clusterFilter = {
                reportId: '',
                clusterIds: [],
                cluster: {},
            }
        }
    },
    getters: {
        filter(state) {
            return state.filter;
        },
    }
}