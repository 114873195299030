import _ from 'lodash';
import {services, addMappings} from '@/commons/services';
import moment from 'moment';
import { MessageBox } from 'element-ui';
import Const from './../commons/const';
import i18n from "../commons/i18n";
import AllowedActions from "@/models/AllowedActions";


export const types = Object.freeze({
    MODE : 'mode',
    LOCALE : 'locale',
    ROLES : 'roles',
    COMPONENTS : 'components',
    USER : 'userInfo',
    ALLOWED_ACTIONS : 'allowedActions',
    MAPPINGS : 'Service.Mappings',
    SERVER_TIME : 'serverTime',
    NOTIONS: 'notions',
    CUSTOMFACETS: 'customFacets',
    SHOWCUSTOMFACETS : 'showCustomFacets',
    LASTYEARS : 'lastYears',
    INIT : 'init'
});

export const mutations = {
    contextUrl(state, contextUrl) {
        contextUrl = (contextUrl || '').trim();
        contextUrl === '/' && (contextUrl = '');
        state.contextUrl = contextUrl;
        state.apiUrl = contextUrl + Const.apiUrl;
    },
    [types.MODE] (state, mode) {
        state.mode = mode;
    },
    [types.CUSTOMFACETS] (state, facets) {
        state.customFacets = facets;
    },
    [types.LASTYEARS] (state, years) {
        state.lastYears = years;
    },
    [types.SHOWCUSTOMFACETS] (state, show) {
        state.showCustomFacets = show;
        window.localStorage.setItem('showCustomFacets', show);
    },

    [types.LOCALE] (state, locale) {
        if(locale !== 'ru' && locale !== 'en') {
            locale = state.locale || Const.locale;
        }
        state.locale = locale;
        moment.locale(locale);
    },

    [types.SERVER_TIME] (state, time) {
        if(time && (time = moment(time, Const.format.moment.datetime))) {
            state.serverTimeOffset = time.diff(moment()) - 100 /* минус время на транспорт, значение придумал*/;
        }
    },

    [types.USER] (state, user) {
        state.userInfo = Object.freeze(_.defaults(user.userInfo || {}, state.userInfo));
    },

    [types.ALLOWED_ACTIONS] (state, allowedActions) {
        state.allowedActions = new AllowedActions(allowedActions);
    },

    [types.ROLES] (state, roles) {
        state.roles = roles || [];
    },

    [types.LOADING] (state, params) {
        state.loading[params.type] = params.state;
    },

    [types.COMPONENTS] (state, components) {
        state.components = components || [];
    },

    [types.MAPPINGS] (state, mappings) {
        addMappings(mappings);
    },

    [types.NOTIONS] (state, notions){
        state.notions = notions || [];
    },

    notionsPromise(state, notionsPromise){
        notionsPromise && (state.notionsPromise = notionsPromise);
    },

    project(state, project) {
        project && (state.project = project);
    },

    [types.INIT] (state, payload) {
        state.initialized = true;
    },
    updateState(state, params) {
        if (Array.isArray(params)) {
            params.forEach(par => {
                _.set(state, par.path, par.data);
            })
        } else {
            if (params.path) {
                _.set(state, params.path, params.data);
            } else {
                state[params.var] = params.data;
            }
        }
    },
};

export const actions = {
    [types.INIT] (context, payload) {
        return new Promise((resolve, reject) => {
            function logout() {
                window.open('logout', '_self');
            }

            function authDialog() {
                MessageBox.alert(i18n.t('auth.errorMessage'), i18n.t('auth.errorTitle'), {
                    type: 'error',
                    dangerouslyUseHTMLString: true
                }).then(logout);
            }

            function loadSystemInfo() {
                services.system.info().then(function (resp) {
                    if (resp.status === 200) {
                        const info = resp.data;
                        context.commit(types.LOCALE, info.locale);
                        context.commit(types.SERVER_TIME, info.time);
                        context.commit(types.ROLES, info.roles);
                        context.commit(types.COMPONENTS, info.components);
                        context.commit(types.USER, {
                            userInfo: info.user
                        });
                        context.commit(types.ALLOWED_ACTIONS, info.allowedActions);
                        context.commit(types.MAPPINGS, info.mappings);
                        context.commit('project', info.project);
                        if(!context.state.initialized) {
                            context.commit(types.INIT);
                        }
                    } else {
                        logout();
                    }
                    resolve(resp);
                }).catch(e => {
                    if(e.response.status === 403) {
                        //authDialog();
                        window.open('login', '_self');
                    } else {
                        logout();
                    }
                    reject(e);
                    return e;
                });
            }

            if(payload.mode) {
                context.commit(types.MODE, payload.mode);
            }

            if(payload.contextPath) {
                context.commit('contextUrl', payload.contextPath);
            }

            loadSystemInfo();

        });
    },
    [types.NOTIONS] (context) {
        return context.state.notionsPromise ? context.state.notionsPromise : context.dispatch('updateNotions');
    },
    updateNotions(context) {
        const promise = services.notions.list({offset:0, count:6000}).then(resp => {
            const list = resp.data.list || [];
            return Object.freeze({
                list: list,
                map: Object.freeze(_.keyBy(list, 'id'))
            });
        });
        context.commit('notionsPromise', promise);
        promise.then(notions => context.commit(types.NOTIONS, notions));
        return promise;
    }
};

export default {mutations, actions, types};
