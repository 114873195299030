<template>
    <div class="widget-block">
        <div class="header mb-4">
            <div class="title">
                {{ title }}
                <div class="viewAll"
                     v-if="this.$store.getters.swList.mode === 'all'"
                     @click="viewAll()">{{ $t('dashboard.viewAll') }}</div>

            </div>
            <export-to-image :filename="title" selector=".topDeals"></export-to-image>
        </div>
        <div class="topDeals pt-2" v-show="list.length"
             v-loading="loading">
            <div v-for="(item, key) in list" :key="key">
                <div>&ndash;</div>
                <div class="item">
                    <el-popover
                        placement="top-start"
                        width="400"
                        popper-class="investDesc"
                        :open-delay="1050"
                        :close-delay="300"
                        trigger="hover">
                        <div>
                            <div class="desc" v-html="item.investee.description"></div>
                            <div class="tags"
                                 v-show="item.investee.systemKeywords && item.investee.systemKeywords.length">
                                <div v-for="(tag, tk) in item.investee.systemKeywords" :key="tk">{{ tag }}</div>
                            </div>
                        </div>
                        <div slot="reference" class="company"
                             @click="handleOpenCompanyCard(item.investee)"
                             v-html="item.investee.title">
                        </div>
                    </el-popover>
                    <span>{{ $t('dashboard.acquiredBy') }}</span>
                    <el-popover
                        placement="top-start"
                        width="400"
                        popper-class="investDesc"
                        :open-delay="1050"
                        :close-delay="300"
                        trigger="hover">
                        <div>
                            <div class="desc" v-html="item.investor.description"></div>
                            <div class="tags"
                                 v-show="item.investor.systemKeywords && item.investor.systemKeywords.length">
                                <div v-for="(tag, tk) in item.investor.systemKeywords" :key="tk">{{ tag }}</div>
                            </div>
                        </div>
                        <div slot="reference" class="company"
                             @click="handleOpenCompanyCard(item.investor)"
                             v-html="item.investor.title">
                        </div>
                    </el-popover>
                    <template v-if="item.sum">
                        <span>{{ $t('dashboard.for') }}</span>
                        <span v-html="item.sum" class="sum"></span>
                    </template>
                    <div class="date-news">
                        <span class="date">{{ $t('dashboard.in') }} {{ item.date }}</span>
                        <span class="news" v-if="item.news">
                            <el-popover
                                v-if="item.news && item.news.length"
                                placement="top-start"
                                width="400"
                                popper-class="investNews"
                                :open-delay="500"
                                :close-delay="500"
                                trigger="hover">
                                    <div>
                                        <a :href="news.url" target="_blank" class="newsItem" v-for="(news, nk) in item.news"
                                           :key="nk">
                                            <div class="date-hosting">
                                                <span class="date">{{ news.dateFormatted }}</span>
                                                <span class="hosting" v-if="news.hostName"> - {{ news.hostName }}</span>
                                            </div>
                                            <div class="title">{{ news.title }}</div>
                                        </a>
                                    </div>
                                    <div slot="reference">
                                        <i class="fas fa-share"></i>
                                    </div>
                            </el-popover>
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <div class="noResult" v-show="list.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";
import ExportToImage from "../../elements/ExportToImage";


export default {
    name: "SWTopDeals",
    components: {ExportToImage},
    props: {
        filter: Object,
        years: Object,
        title: String,
        field: String,
        params: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false,
            reQ: {
                chart: {},
            },
            list: [],
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        filter() {
            this.refresh();
        },
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let ii = 0;


            let query = {
                "filter": self.filter,
                "widgets": {
                    "chart": self.params,
                }
            };

            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                } catch (e) {
                    console.error(e);
                }
            }

            self.loading = true;
            self.reQ.chart = self.$services.analytics.widgets(query);

            self.reQ.chart.then(resp => {
                let _data = resp.data.widgets.chart.searchResponse.list;

                // -------------- Top Deals ---------------
                self.list = _data.map(it => {
                    let out = {};
                    out.date = moment(it.date).format('MMM YYYY');
                    out.investor = it.investors[0];
                    out.investee = it.investee;
                    if (it.news && it.news.length) {
                        out.news = _.orderBy(it.news.map(n => {
                            n.dateFormatted = moment(n.date).format('ll');
                            return n;
                        }), 'date', 'desc');
                    }
                    out.sum = it.sum ? '$ ' + self.$utils.toFinHuman(it.sum).number : null;
                    //Nuance Communications acquired by Microsoft for $19.7B in april 2021
                    return out;
                });
                self.loading = false;

            }).catch(e => {
                console.log(e);
            });
        },
        handleOpenCompanyCard(item) {
            if (item.id) {
                window.sessionStorage.removeItem('companyCard');
                window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
            } else {
                console.log('--- No company Id. Investor - person? --- ');
            }
        },
        viewAll() {
            // console.log('keyword:', keyword);
            let self = this;
            let docType = 'Investment';
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            let facets = {
                requests: [{
                    type: yearType,
                    values: self.years.years
                }, {
                    type: 'INVT_TYPE',
                    values: ['ma']
                }]
            },
            params = {
                query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                isNativeQuery: !!self.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
                investmentSortBy: {
                    field: 'sum',
                    dir: 'DESC'
                }
            }
            // console.log('Facets', facets, self.filter);
            self.$utils.openSearch(params);

        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
</style>