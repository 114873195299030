<template>
    <li :class="{'dropdown-submenu': !!children.length, 'dropdown-divider': type === 'divider', 'dropdown-item': type === 'item'}" role="menu" aria-labelledby="dropdownMenu"
        @mousedown.stop.prevent="$emit('clicked')">
        <a>
            <i class="icon" :class="iconClass"></i>
            <span class="name">{{ text }}</span>
            <IqpContextSubmenu v-if="children.length" :items="children" @clickItem="submenuSelect"/>
        </a>
    </li>
</template>

<script>
    export default {
        name: 'IqpContextMenuItem',
        components: { IqpContextSubmenu: () => import('./Submenu') },
        props: {
            type: {
                type: String,
                default: 'item'
            },
            text: String,
            iconClass: String,
            children: {
                type: Array,
                default(){return [];}
            }
        },
        methods: {
            submenuSelect(item) {
                this.$emit('clickItem', item);
            }
        }
    }
</script>

<style scoped>
    .icon {
        width: 1.1rem;
        margin-right: 5px;
        font-size: 14px;

    }
    .name {
        font-size: 15px;
    }
</style>
