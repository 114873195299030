<template>
    <div class="fst-MoreLikeThis">
        <el-drawer
            :title="$t('label.morLikeThis')"
            :close-on-press-escape="false"
            :visible.sync="showMoreLikeThis"
            :with-header="false"
            :wrapperClosable="false"
            size="45%"
            direction="ltr">
            <div class="drawer-container">
                <div class="header">
                    <div class="title">
                        <i class="far fa-copy mr-1 iq-text-blue"></i> {{ $t('label.morLikeThis') }}
                    </div>
                    <div>
                        <i class="far fa-times close" @click="closeMe"></i>
                    </div>

                </div>
                <div class="body">
                    <div class="tab-content">
                        <SearchList v-model="activeTab.result"
                                    v-if="activeTab.result.total > 0"
                                    :filter="activeTab.filter"
                                    :noLikeThis="true"
                                    :type="item.type"
                                    :fullUserTagList="fullUserTagList"
                                    @updateUserTagList="getUserTagList"
                                    @infinite="state => search(false, state)">
                        </SearchList>
                        <template v-if="activeTab.result.total === 0">
                            <div class="noResult">
                                <i class="fas fa-spinner fa-pulse fa-3x iq-text-primary" v-if="!noResult"></i>
                                <img src="noResult.svg" v-if="noResult">
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import SearchList from './SearchList.vue';
import _ from "lodash";
import uuid from "uuid";


export default {
    name: "MoreLikeThis",
    components: {SearchList},
    props: {
        showMoreLikeThis: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object
        },
        userInfo: {
            type: Object
        },
    },
    data() {
        return {
            predicate: '',
            defaultActiveTab: 'News',
            searchFilter: {},
            noResult: false,
            orderBy: 'relevance',
            orderByItems: [
                {label: 'Standard Relevance', value: 'relevance'},
                {label: 'Semantic Relevance', value: 'semantic'},
                {label: 'Document date', value: 'date'}
            ],
            fullUserTagList: [
                {id: 1, color: 1, name: 'Favorites'},
                {id: 2, color: 2, name: 'Veni, vidi, vici'},
            ],
            assignedTagsList: [],
            activeTab: {
                result: {
                    total: 0
                },
                filter: {}
            }
        };
    },
    computed: {},
    watch: {
        item(newVal) {
            this.refresh();
        }
    },
    mounted() {
        this.getUserTagList();
        this.refresh();
        // console.log('uI:', this.userInfo);

    },
    methods: {
        refresh() {
            if (this.item && this.item.id) {
                let filter = {
                    fullness: 'FULL',
                    offset: 0,
                    count: 40,
                    docId: {
                        id: this.item.id,
                        type: this.item.type || 'News'
                    }
                }

                console.log('search Filter:', filter);
                this.activeTab.filter = filter;
                this.$services.documents.similar(filter).then(resp => {
                    this.activeTab.result = resp.data;
                    if (resp.data.total === 0) {
                        this.noResult = true;
                    }
                    // console.log('resp',resp);
                }, error => {

                });
            }
        },
        getUserTagList() {
            this.$services.tags.list({
                count: 500,
                offset: 0,
                userIds: [this.userInfo.id]
            }).then(resp => {
                if (resp && resp.data) {
                    this.fullUserTagList = resp.data.list;
                    // this.refreshAssignTagsList();
                }
            });
        },
        closeMe() {
            this.activeTab.result = {
                total: 0,
                offset: 0,
                list: []
            };
            this.predicate = '';
            this.$emit('closeMoreLikeThis');
        },
        handleSelectTab(key) {
            this.defaultActiveTab = key;
            this.prevActiveTab = this.defaultActiveTab;
            let activeTab = this.activeTab;
            this.search(null, null, null);
        },
    }
};
</script>

<style lang="scss">
@import "../../assets/variables.scss";

.fst-MoreLikeThis {
    .drawer-container {
        .body {
            max-height: none;
            overflow-y: hidden;

            > .tab-content {
                max-height: calc(100vh - 245px);
            }
        }
    }

    .tab-nav {
        .el-menu {
            background: transparent;
            //min-width: 500px;
        }

        .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
            background: transparent;
        }
    }

    .tab-content {
        width: 100%;
    }

    .predicate-block {
        display: flex;
        margin-bottom: 0;


        .predicate {
            textarea {
                height: 70px;
                border-radius: 5px 0 0 5px;
            }
        }

        .el-button {
            border-radius: 0 5px 5px 0;
            border: none;
            background: $iq-color-blue;
            color: #ffffff;
            min-height: 50px;
            width: 70px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            line-height: 1;
        }
    }

    .fst-SearchList {
        .list-item-container {
            width: auto;
            padding-top: 20px;
        }
    }

    .fst-SearchList {
    }
    .fst-SearchListItem {
        max-height: calc(100vh - 110px);
    }
}
</style>

<style lang="scss" scoped>
</style>
