<template>
    <el-form class="fst-UserEventReportForm" label-position="top" v-loading="loadingDownload">
        <el-form-item :label="$t('types')">
            <el-select v-model="model.types" multiple
                       :disabled="model.stats">
                <el-option v-for="type in allEventTypes" :key="type" :value="type" :label="type"/>
            </el-select>
        </el-form-item>
        <el-form-item :label="$t('date')">
            <el-date-picker
                v-model="model.date"
                type="datetimerange"
                :disabled="model.stats"
                :range-separator="$t('dateTo')"
                :start-placeholder="$t('dateMin')"
                :default-time="['00:00:00', '23:59:59']"
                :end-placeholder="$t('dateMax')" clearable/>
        </el-form-item>
        <el-form-item label="Company">
            <el-select v-model="model.company" placeholder="Choose company">
                <el-option key="comp-0" label="All" value=""></el-option>
                <el-option v-for="item in companies"
                           :key="`comp-${item.id}`"
                           :label="item.name"
                           :value="item.name"
                ></el-option>
            </el-select>

        </el-form-item>
        <el-form-item :label="$t('username')">
            <el-input v-model="model.username" clearable style="width: 400px;"/>
        </el-form-item>
        <el-form-item class="mt-3">
            <el-checkbox v-model="model.stats" :label="$t('stats')"></el-checkbox>
        </el-form-item>
    </el-form>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import {jsonApiPreProcessing} from '@/commons/services';

export default {
    name: 'UserEventReportForm',
    data() {
        return {
            companies: [],
            allEventTypes: [],
            loadingDownload: false,
            companyName: {},
            model: {
                types: [],
                date: [],
                company: '',
                username: '',
                stats: false
            }
        };
    },
    computed: {
        filter() {
            const f = {}, {types, date, username, company} = this.model;
            if (types && types.length) {
                f.types = types;
            }
            if (date && date.length && (date[0] || date[1])) {
                f.date = {min: date[0], minBoundType: 'CLOSED', max: date[1], maxBoundType: 'CLOSED'};
            }
            if (username && username.length) {
                f.usernames = [username];
            }
            if (company) {
                f.companyNames = [company];
            }
            return jsonApiPreProcessing(f);
        }
    },
    created() {
        this.allEventTypes = [
            'search', 'openOriginal', 'openCard', 'openFulltext', 'loadResultset', 'addFavorites', 'deleteFavorites',
            'openFavorites', 'hints', 'reportExcel', 'authorization', 'fullView', 'heatMap', 'trendRank', 'countryRank',
            'keywordGrowth', 'passResetRequest', 'passResetConfirm', 'translate', 'aiChat'
        ].sort();
        this.getCompanies();
    },
    methods: {
        download() {
            let self = this;
            this.loadingDownload = true;
            axios({
                url: this.$store.state.apiUrl + '/userEvents/reportExcel',
                method: 'POST',
                data: JSON.stringify({
                    "filter": _.extend({order: [{field: 'date', type: 'ASC'}]}, self.filter),
                    "needStats": self.model.stats
                }),
                responseType: 'blob',
                headers: {'Content-Type': 'application/json;charset=UTF-8'}
            }).then((resp) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(resp.data);
                link.setAttribute('download', 'report.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
                this.loadingDownload = false;
            });
        },
        getCompanies() {
            let _query = {
                "fullness": "FULL",
                "deleted": false,
                "order": [
                    {
                        "field": "name",
                        "type": "ASC"
                    }
                ],
                "offset": 0,
                "count": 8888
            };
            this.$services.companies.list(_query).then(resp => {
                this.companies = resp.data.list;
                this.companies.forEach(it => {
                    this.companyName[it.id] = it.name;
                })

            });
        }

    },
    i18n: {
        messages: {
            ru: {
                types: 'Типы событий',
                date: 'Дата',
                dateMin: 'Дата начала',
                dateMax: 'Дата окончания',
                dateTo: 'по',
                username: 'Login',
                stats: 'Статистика',
            }, en: {
                types: 'Event types',
                date: 'Date',
                dateMin: 'Start date',
                dateMax: 'End date',
                dateTo: 'to',
                username: 'Login',
                stats: 'Statistics',
            }
        }
    }
}
</script>

<style lang="scss">
.fst-UserEventReportForm {

}
</style>