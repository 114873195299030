<template>
    <div class="widget-block"
         v-loading="loading"
         element-loading-background="rgba(19, 19, 19, .7)">
        <div class="header mb-3">
            <div class="title">
                {{ title }}
                <div class="viewAll" @click="(e) => handleWordClick(e)">{{ $t('dashboard.viewAll') }}</div>
            </div>
        </div>
        <div class="cloud" v-show="words.length"
        >
            <vue-word-cloud
                    style="height: 440px; width: 100%;"
                    :words="words"
                    :spacing="spacing"
                    :font-size-ratio="ratio"
                    @update:progress="handleCloudUpdate"
                    color="#61B768"
                    font-family="Roboto">
                <template slot-scope="{text, weight, word}">
                    <el-tooltip effect="dark" placement="top"
                                :content="text + ': ' + $utils.toFinHuman(weight,1).number + ' ' + (params.unit || 'documents')"
                                :open-delay="600">
                        <div class="keyword" @click="(e) => handleWordClick(e,word)">
                            {{ text }}
                        </div>
                    </el-tooltip>
                </template>

            </vue-word-cloud>
        </div>
        <div class="noResult" v-show="words.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";


    export default {
        name: "Keywords",
        props: {
            request: Object,
            title: String,
            widget: String,
            field: String,
            params: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                loading: true,
                words: [],
                spacing: 1,
                reQ: {
                    chart: {}
                },
                ratio: 4,
                colors: [
                    'hsl(224, 44%, 67%)', // BlueBright
                    'hsl(36, 53%, 52%)', // Orange2
                    'hsl(352, 55%, 55%)', // Red2
                    '#BABABA',  // White2
                    'hsl(125, 37%, 50%)', // Green2
                ]
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            request() {
                this.refresh();
            },
        },
        mounted() {
            // this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let query = _.cloneDeep(self.request),
                    rotation = self.params.rotation ? self.params.rotation : {},
                    ii = 0;
                delete query.widgets;
                query.widgets = [
                    self.widget
                ];
                if (self.params.spacing) {
                    self.spacing = self.params.spacing;
                }
                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }

                self.loading = true;

                self.reQ.chart = self.$services.analytics.fullView(query);
                // console.log('~~ Keywords widget query:', query);
                self.reQ.chart.then(resp => {
                    let _data = resp.data[self.field],
                        _vals = _.map(_data, it => parseInt(it));

                    // console.log(`-- aKw ${self.field} Data: `, _data);

                    self.ratio = (_.max(_vals) / _.min(_vals) > 14) ? 3 : 4;
                    // self.loading = false;

                    // -------------- Keywords ---------------

                    self.words = _.map(_data, (it, value) => {
                        let out = {
                            // text: value,
                            text: value,
                            weight: parseInt(it),
                            rotationUnit: 'deg',
                            // rotation: Math.ceil(90 - Math.random() * 150),
                            rotation: rotation.rule === 'stringLength' ?
                                (value.length < rotation.value && Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0')
                                : Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0',
                            color: self.colors[ii]
                        };
                        ii++;
                        if (ii > self.colors.length) {
                            ii = 0;
                        }
                        return out;
                    });
                    // console.log(self.words);

                }).catch(e => {
                    console.log(e);
                });


            },
            handleWordClick(e, keyword) {
                // console.log('keyword:', keyword);
                let self = this;
                let years = [],
                    docType = "Science";
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

                if (e.button === 0) {
                    for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                        years.push(year.toString());
                    }

                    let facets = {
                        requests: [
                            {
                                type: yearType,
                                values: years
                            },
                        ]
                    };
                    if (keyword) {
                        facets.requests.push({
                            type: 'SYSTEM_KEYWORDS',
                            values: [keyword.text]
                        })
                    }
                    // console.log('Facets', facets, self.request.filter);
                    let params = {
                        query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                        isNativeQuery: !!self.request.filter.nativeQuery,
                        docType: docType,
                        facets: facets,
                        spam: false,
                        duplicate: 'false',
                    }
                    self.$utils.openSearch(params);
                }
            },
            handleCloudUpdate(str) {
                if (!str) {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style lang="scss">
</style>

<style scoped lang="scss">

</style>