<template>
    <section class="techWeakSignalsChart nice-scroll">

        <div class="graph" v-for="(graph,key) in chartData" :key="key">
            <div class="items">
                <div class="item"
                     v-for="(item, idx) in graph.items" :key="idx"
                     :style="`width: ${item.radius}px; height: ${item.radius}px;`"
                     :title="`${item.year}
${$t('label.documents')}: ${item.docCount}
${$t('dashboard.weakSignalsTab.significance')}: ${graph.significance}
${$t('dashboard.weakSignalsTab.growthLastYearNoBr')}: ${graph.growthLastYear} ${graph.iconLastYear ? ' ↑' : ''}
${$t('dashboard.weakSignalsTab.growth')}: ${graph.icon > 0 ? '↑ ' : ''}${graph.growth}`">
<!--
`${item.year}
${$t('label.documents')}: ${item.docCount}
${$t('dashboard.weakSignalsTab.significance')}: ${graph.significance}
${$t('dashboard.weakSignalsTab.growthLastYearNoBr')}: ${graph.growthLastYear} ${graph.iconLastYear ? ' ↑' : ''}
${$t('dashboard.weakSignalsTab.growth')}: ${graph.icon > 0 ? '↑ ' : ''}${graph.growth}`
-->
                    <div class="tooltip" v-if="false">
                        <div class="year">{{item.year}}</div>
                        <div>{{$t('label.documents')}}: {{item.docCount}}</div>
                        <div class="stats">{{$t('dashboard.weakSignalsTab.significance')}}: {{graph.significance}}</div>
                        <div>{{$t('dashboard.weakSignalsTab.growthLastYearNoBr')}}: {{graph.growthLastYear}}
                            {{graph.iconLastYear ? ' ↑' : ''}}
                        </div>
                        <div>{{ $t('dashboard.weakSignalsTab.growth') }}: {{graph.icon > 0 ? '↑ ' : ''}}{{graph.growth}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="sign" v-show="showSign" :title="$t('dashboard.weakSignalsTab.significance') + ': ' + graph.sign">
                <span :style="`width: ${graph.signPrc}%;`"></span>
            </div>

            <div class="title" @click="mouseOver(graph)">
                {{ graph.keyword }}
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "WeakSignalsChart",
        props: {
            wsData: {
                type: Object,
                default: () => ({
                    list: [],
                    max: 0,
                })
            },
            maxRadius: {
                type: Number,
                default: 0
            },
            showSign: {
                type: Boolean,
                default: true
            }
            // sortBy: {
            //     type: Object,
            //     default: () => ({})
            // },
        },
        computed: {
            chartData() {
                let self = this;
                return this.wsData.list.slice(0, 600).map(graph => {
                    graph.items = {}
                    graph.sign = self.$utils.roundX(parseFloat(graph.significance), 2, 1);
                    graph.signPrc = parseFloat(graph.significance) * 100;

                    _.forEach(graph.countsRaw.slice(0, 5), (item, year) => {
                        graph.items[year] = {}
                        let _it = graph.items[year];
                        _it.year = item.year;
                        _it.value = item.value;
                        _it.docCount = item.formattedValue;
                        _it.radius = item.value * this.maxRadius / this.wsData.max;
                        _it.radius = _it.radius < this.maxRadius / 12 ? this.maxRadius / 12 : _it.radius;
                    })
                    // console.log('graph ::: ', graph)
                    return graph;
                })
            }
        },
        methods:{
            mouseOver(item){
                this.$emit('mouseOver', item);
            },
            mouseOut(){
                this.$emit('mouseOut')
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "../../../assets/variables.scss";

    $main-bg: #191919;
    $color0: #296080;
    $color1: #437594;
    $color2: #5d8ba9;
    $color3: #75a1be;
    $color4: #8eb8d3;
    $color5: #a7cfe9;
    $color6: #c1e7ff;

    $color-1: #004c6d;
    $color-2: #3d708f;
    $color-3: #6996b3;
    $color-4: #94bed9;
    $color-5: #c1e7ff;

    .techWeakSignalsChart {
        background: $main-bg;
        display: flex;
        /*gap: 35px 50px;*/
        flex-wrap: wrap;
        padding: 20px;
        margin-right: -25px;
        max-height: calc(100vh - 230px);
        overflow-y: auto;
        justify-content: space-evenly;
        align-items: center;

        .graph {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            padding: 0 25px 35px;
            width: 20%;

            .title {
                text-align: center;
                color: #aaa;
                font-size: .9rem;
                font-variant: all-small-caps;
                max-width: 140px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: pointer;
                transition: all .25s ease;
                &:hover{
                    color: #fff;
                }
            }

            .sign {
                background: #2E3134;
                border-radius: 12px;
                height: 8px;
                display: flex;
                justify-content: flex-start;
                width: 60%;
                margin: 7px 0 4px;

                > span {
                    display: inline-block;
                    background: #9BB185;
                    border-radius: 12px;
                    height: 100%;
                }
            }

            .items {
                display: flex;
                align-items: center;
                flex-direction: row;


                .item {
                    flex-shrink: 0;
                    border-radius: 1000px;
                    margin-right: -4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(101, 131, 153, .6);
                    border: 1px solid #90A2B0;
                    transition: all .35s .15s ease;
                    position: relative;

                    .tooltip {
                        opacity: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        transition: all .35s .55s ease;
                        background: #2C4D63;
                        border-radius: 4px;
                        border: 1px solid #2C4D63;
                        color: #ffffffcc;
                        position: absolute;
                        z-index: 300;
                        top: 100%;
                        left: 50%;
                        width: 260px;
                        margin-left: -130px;
                        height: auto;
                        padding: 7px 10px;
                        font-size: 0.75rem;
                        white-space: nowrap;
                        .year{
                            font-weight: 500;
                        }
                        .stats{
                            border-top: 1px solid #90a2b0;
                            padding-top: 8px;
                            margin-top: 3px;
                        }
                        &::after {
                            content: " ";
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: transparent transparent #2C4D63 transparent;
                        }
                    }

                    &:first-child {
                        background: #1B3A4E;
                    }

                    &:last-child {
                        margin-right: 0;
                        background: rgba(144, 162, 176, .85)

                    }

                    &:hover {
                        .tooltip {
                            opacity: 1;
                        }
                    }

                }
            }

            &:hover {
                .items {
                    .item {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    @media (max-width: 1700px) {
        .techWeakSignalsChart {
            .graph{
                width: 25%;
            }
        }

    }

</style>