<template>
    <div class="fst-UserNotification">
        <div class="iq-notify">
            <article class="type-info"
                     v-if="!['expired', 'closed'].includes(notification.status) && notification.title"
                     :class="notification.status"
            >
                <div class="header">
                    <div class="icon">
                        <img src="teq-icon-clock.svg">
                    </div>
                    <div class="timer" @click="notification.status=''">
                        {{ notification.timeLeft }}
                    </div>
                    <div class="control">
                        <img src="teq-icon-topMinus.svg" @click="handleMinimizeIt(notification)">
                    </div>
                </div>
                <div class="body">
                    <div class="title" v-html="notification.titleFormatted"></div>
                    <div class="text" v-html="notification.description" v-if="!notification.hideDesc"></div>
                </div>
            </article>
        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex';
import {USER_ROLES} from '@/models/Users';
import Utils from '@/commons/utils'


export default {
    name: "UserNotification",
    props: {},
    data() {
        return {
            superUsers: ['watson', 'chas', 'polyakova'],
            userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
            notification: _.extend({
                expirationDate: Utils.dates.serverMoment().add(-5, 'minutes').format(Utils.dates.FORMAT_DATETIME),
                timerId: null,
                // title: 'Teqviser will start updating in <span class="big">{dur}</span> minutes',
                title: '',
                titleFormatted: '',
                description: '',
                status: '',
                creationDate: null
                // description: '<p>Please, save all your work.</p>' +
                //     '<p>System will not be available for 5-10 minutes.</p>'

            }, this.alert)
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        ...mapState({
            alert: state => state.systemAlert.alert
        })
    },
    mounted() {
        this.$store.dispatch('systemAlert/waitAlert');
        this.update(this.alert);
    },
    beforeDestroy() {
        clearTimeout(this.timerId);
    },
    watch: {
        alert(alert) {
            this.update(alert);
        }
    },
    methods: {
        update(alert) {
            alert && (this.notification = _.extend({}, this.notification, alert));
            if (alert && alert.title) {
                this.notification.status = "";
            } else {
                this.notification.status = "expired";
            }
            this.refresh();
        },
        refresh() {
            let it = this.notification;
            if (it) {
                const curr = Utils.dates.serverMoment(), exp = Utils.dates.toMoment(it.expirationDate),
                    timeLeft = exp.diff(curr),
                    dur = moment.duration(timeLeft),
                    timeVal = Math.floor(dur.asMinutes()) + ':' + (dur.seconds() < 10 ? '0' : '') + dur.seconds();
                it.titleFormatted = (it.title || '').replace(/{dur}|{duration}/g, timeVal);
                it.timeLeft = timeVal;
                if (timeLeft < 0) {
                    if (it.expirationText) {
                        it.titleFormatted = it.expirationText;
                        it.hideDesc = true;
                        it.timeLeft = it.expirationText;
                    } else {
                        it.status = 'expired'
                    }
                } else if(it.title){
                    it.hideDesc = false;
                    clearTimeout(this.timerId);
                    this.timerId = setTimeout(() => {
                        this.refresh()
                    }, 1000);
                }
            }
        },
        handleMinimizeIt(item) {
            item.status = 'minimized';
        },
        handleCloseIt(item) {
            item.status = 'closed';
        },
    }
}
</script>

<style lang="scss">
.fst-UserNotification {
    .iq-notify {
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 3000;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        article {
            background: $iq-color-blue;
            padding: 25px;
            color: #D7E1FC;
            border-radius: 5px;
            margin-bottom: 20px;
            width: 390px;
            box-sizing: border-box;

            &:last-child {
                margin-bottom: 0;
            }

            .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                align-items: flex-start;

                .icon {

                }

                .timer {
                    display: none;
                }

                .control {
                    display: flex;
                    align-items: flex-start;
                    cursor: pointer;

                    i {
                        font-size: 28px;
                        color: $iq-color-text-action;
                        margin-top: -10px;
                        padding: 3px;
                        cursor: pointer;

                        &.fa-minus {
                            margin-top: -18px;
                        }
                    }
                }
            }

            .body {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;

                p {
                    margin: 0 0 5px 0;
                }

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 15px;
                    color: #fff;
                }

                .big {
                    font-size: 28px;
                }
            }

            &.minimized {
                width: auto;
                padding: 20px;

                .header {
                    margin-bottom: 0;
                    align-items: center;

                    .icon img {
                        height: 20px;
                    }

                    .timer {
                        margin-left: 10px;
                        display: flex;
                        cursor: pointer;
                    }

                    .control {
                        display: none;
                    }
                }

                .body {
                    display: none;
                }
            }
        }
    }
}
</style>
