export default {
    step: [
        {
            type: 'modal',
            buttons: {
                first: {
                    name: 'quickTour.skip',
                    action: 'skip'
                },
                second: {
                    name: 'quickTour.start',
                    action: 'next'
                }
            },
            title: `Добро пожаловать в модуль кластерного анализа`,
            text: `<div class="text-center">
                        <img src="teq-logo-icon1.svg" style="width: 36px;">
                    </div>
                    <div class="text-center">
                        <p>Пройдите небольшой тур, это облегчит начало работы.</p>
                    </div>`
        }, {
            type: 'step',
            title: 'Кликните по кластеру',
            text: 'справа откроется карточка выбранного кластера.<br> ' +
                'Кластер - это набор близких по смыслу документов, сформированный нейросетью.<br>' +
                'Каждая точка на карте является документом.',
            noDarkness: true,
            noteCoords: {
                top: -60,
                left: 15,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.svg-plot-area g.label:first-child',
                targetCoords: true,
            },
            anglePlacement: 'left',
            actions: [{
                type: 'function',
                function: 'handleCheckCluster',
                arg: 'clustersItem',
                argVal: '0',
                timeOut: 0
            }],
        }, {
            type: 'step',
            title: 'Ключевые слова',
            text: 'характеризующие данный кластер',
            noDarkness: false,
            noteCoords: {
                top: -200,
                right: -20,
                targetElement: '.chart-container>.control.right .keywords',
                targetCoords: true,
            },
            anglePlacement: 'right',
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.chart-container>.control.right',
                            class: 'tourStepCardKeywords'
                        }
                    ]
                }
            ],
        }, {
            type: 'step',
            title: 'Ссылка на документы',
            text: 'открывает список документов кластера',
            noDarkness: false,
            noteCoords: {
                top: -58,
                right: -25,
                targetElement: '.chart-container>.control.right .numDocs .el-link',
                targetCoords: true,
            },
            anglePlacement: 'right',
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.chart-container>.control.right',
                            class: 'tourStepShowDocs'
                        }
                    ]
                }
            ],
        }, {
            type: 'step',
            title: 'Диаграмма распределения',
            text: 'документов по годам',
            noDarkness: false,
            noteCoords: {
                top: -140,
                right: -25,
                targetElement: '.chart-container>.control.right .yearCounts',
                targetCoords: true,
            },
            anglePlacement: 'right',
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.chart-container>.control.right',
                            class: 'tourStepDocsByYears'
                        }
                    ]
                }
            ],
        }, {
            type: 'step',
            title: 'Для совокупного анализа нескольких кластеров',
            text: 'выберите их на карте с нажатой клавишей "CTRL"',
            noDarkness: true,
            noteCoords: {
                top: -40,
                left: 25,
                targetElement: '.chart-container>.control .colorSchemeBlock',
                targetCoords: true,
            },
            anglePlacement: 'none',
            timeOut: 100,

            actions: [{
                type: 'function',
                function: 'handleToggleAll',
            }, {
                type: 'function',
                function: 'handleCheckCluster',
                arg: 'clustersItem',
                argVal: '0',
                timeOut: 100
            }, {
                type: 'function',
                function: 'handleCheckCluster',
                arg: 'clustersItem',
                argVal: '1',
                isCtrl: true,
                timeOut: 600
            }, {
                type: 'function',
                function: 'handleCheckCluster',
                arg: 'clustersItem',
                argVal: '2',
                isCtrl: true,
                timeOut: 1400
            }],
        }, {
            type: 'step',
            title: 'Сбросить выделение',
            text: 'можно кликнув по свободному пространсту карты или выбрать чекбокс All',
            noDarkness: true,
            noteCoords: {
                top: -63,
                left: 15,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.chart-container>.control .selectAll',
                targetCoords: true,
            },
            anglePlacement: 'left',
            timeOut: 0,

            actions: [{
                type: 'function',
                function: 'handleToggleAll',
            }],
        }, {
            type: 'step',
            title: 'Поиск по кластерам',
            text: 'позволяет найти документы во всех кластерах',
            noDarkness: true,
            noteCoords: {
                top: 15,
                left: 55,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.fst-Header .predicate .el-input-group__prepend',
                targetCoords: true,
            },
            anglePlacement: 'top',
            timeOut: 0,

            actions: [{
                type: 'predicateSearch',
            }],
        }, {
            type: 'step',
            title: 'С помощью инструмента "Лассо"',
            text: 'можно выделить произвольную область на карте',
            noDarkness: true,
            noteCoords: {
                top: -141,
                left: -250,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.areaSelectionControl button:last-child',
                targetCoords: true,
            },
            anglePlacement: 'bottom',
            timeOut: 0,

            actions: [{
                type: 'predicateSearch',
                query: '',
            }, {
                type: 'lasso',
                values: [
                    {x: 943, y: 378.984375},
                    {x: 962, y: 440.984375},
                    {x: 1065, y: 479.984375},
                    {x: 1165, y: 413.984375},
                    {x: 1177, y: 321.984375},
                    {x: 1049, y: 272.984375},
                    {x: 1000, y: 289.984375},
                ],
                timeout: 300
            }],
        }, {
            type: 'step',
            title: 'Раскраска карты',
            text: 'по динамике роста количества документов в кластерах',
            noDarkness: true,
            noteCoords: {
                top: -65,
                left: 15,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.chart-container>.control .colorSchemeBlock',
                targetCoords: true,
            },
            anglePlacement: 'left',
            timeOut: 0,

            actions: [{
                type: 'function',
                function: 'resetPolySelection',
            }, {
                type: 'function',
                function: 'togglePolySelection',
                argVal: false,
            }, {
                type: 'changeColoring',
                value: 'byGrowthLastYear',
                timeout: 1000
            }, {
                type: 'addClass',
                targets: [
                    {
                        selector: '.colorSchemeBlock',
                        class: 'tourStepChangeColoring'
                    }
                ]
            }],
        }, {
            type: 'step',
            title: 'Вернуться к режиму поиска',
            text: 'можно кликнув по логотипу системы',
            noDarkness: true,
            noteCoords: {
                top: -50,
                left: 20,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.fst-Header svg',
                targetCoords: true,
            },
            anglePlacement: 'left',
            timeOut: 0,
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.fst-Header',
                            class: 'tourStepLogoBackToSearch'
                        }
                    ]
                }
            ],
        }, {
            type: 'modal',
            buttons: {
                first: {
                    name: 'quickTour.again',
                    action: 'restart'
                },
                second: {
                    name: 'quickTour.finish',
                    action: 'skip'
                }
            },
            title: 'Спасибо, что прошли этот тур с нами!',
            text: `<div class="text-center">
                        <img src="teq-logo-icon1.svg" style="width: 36px;">
                    </div>
                    <div class="text-center">
                        <p>Хорошего дня!
                            <img src="smile3.png" style="width: 23px;position: relative;top: 6px;left: 3px;"><!--&#128526;--></p>
                    </div>`
        }
    ]
}