<template>
    <div class="fst-AddValue">
        <div class="d-flex align-items-center">
            <el-input
                class="addGroupInput"
                :disabled="savingGroup"
                v-show="showAddGroupInput"
                v-model="newName"
            ></el-input>
            <el-button type="primary" v-show="!showAddGroupInput" @click="showAddGroupInput=true">

                + source group
            </el-button>
            <el-button type="primary" v-show="showAddGroupInput" @click="handleSaveNewGroupButton"><i
                class="fas" :class="{'fa-save': readyToSaveGroup, 'fa-check': savingGroup}"></i>
            </el-button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AddValue',
    components: {},
    data() {
        return {
            savingGroup: false,
            assignGroupValue: {},
            newName: '',
            readyToSaveGroup: true,
            showAddGroupInput: false,
            showAssignGroup: false,
        };
    },
    created() {
    },
    computed: {},
    watch: {},
    methods: {
        handleSaveNewGroupButton() {
            this.savingGroup = true;
            this.readyToSaveGroup = false;
            let query = {
                name: this.newName
            }
            this.$services.hostingGroups.save(query).prepare().then((resp) => {
                setTimeout(() => {
                    this.showAddGroupInput = false;
                    this.newName = '';
                    this.savingGroup = false;
                    this.readyToSaveGroup = true;
                }, 1800);
                this.$emit('fireEvent', 'getGroupList');
            })
        }

    },
    destroyed() {

    }
}
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>

</style>
