<template>
    <div class="fst-AdminPatterns">
        <div class="admin-content mb-3  mw-950">
            <el-input
                placeholder="Search pattern"
                v-model="searchPatternQuery"
                @input="handleSearchPattern"
                clearable
            >
                <template slot="prepend"><i class="el-icon-search"/></template>
            </el-input>
        </div>
        <div class="admin-content py-2 mw-950">
            <div class="table-like iqTab sortTab">
                <div class="thead">
                    <div>
                        <div class="sortBy" :class="sortBy.name.dir" @click="handleSortBy('name')">
                            <div>
                                Pattern
                                <span></span>
                            </div>
                        </div>
                        <div class="sortBy" :class="sortBy.docType.dir" @click="handleSortBy('docType')">
                            <div>
                                Document type
                                <span></span>
                            </div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div class="tbody">
                    <div v-for="(item, key) in patternListVisible" :key="key">
                        <div>{{ item.name }}</div>
                        <div>
                            <span>{{ item.docType }}</span>
                        </div>
                        <div class="control">
                            <div>
                                <el-button type="text" @click="handleEditPattern(item)"><i class="fas fa-pencil"></i>
                                </el-button>
                                <el-button type="text" class="text-danger" @click="handleDeletePattern(item)"><i
                                    class="fas fa-trash-alt"></i></el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EditPattern
            v-if="show.editPattern"
            @updateList="getPatternList()"
            :close="() => {this.show.editPattern = false}"
            :pattern="patternToEdit"
        ></EditPattern>

    </div>
</template>

<script>

import _ from 'lodash';
import EditPattern from './actions/EditPattern';

export default {
    name: 'AdminPatterns',
    components: {EditPattern},
    props: {
        incEvent: Object
    },
    data() {
        return {
            show: {
                editPattern: false
            },
            patternToEdit: {},
            searchPatternQuery: '',
            sortBy: {
                docType: {
                    dir: ''
                },
                name: {
                    dir: 'ASC'
                }

            },
            patternList: []
        };
    },
    created() {
        this.getPatternList();
        // this.getGroupList();
    },
    computed: {
        patternListVisible() {
            return _.filter(this.patternList, p => p && p.show);
        }
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        }
    },
    methods: {
        getPatternList(sortBy) {
            let query = {
                fullness: 'FULL',
                count: 1881,
                offset: 0,
                order: [{
                    field: (sortBy && sortBy.field) ? sortBy.field : 'name',
                    type: (sortBy && sortBy.dir) ? sortBy.dir : 'ASC',
                }]
            }
            this.$services.patterns.list(query).prepare().then((resp) => {
                this.patternList = resp.data.list.map(it => {
                    let _out = it;
                    _out.show = true
                    return _out
                })
                this.show.editPattern = false;
            })
        },
        addPattern() {
            console.log('New pat');
            this.patternToEdit = {docType: 'News'};
            this.show.editPattern = true;
        },
        handleEditPattern(item) {
            this.patternToEdit = item;
            this.show.editPattern = true;
        },
        handleSearchPattern(query) {
            this.patternList = this.patternList.map(it => {
                let _out = it,
                    _input = query ? query.toLowerCase() : this.searchPatternQuery.toLowerCase();
                _out.show = it.name.toLowerCase().includes(_input) || (it.docType && it.docType.toLowerCase().includes(_input));
                return _out
            });
        },
        handleDeletePattern(item) {
            let _query = {
                ids: [item.id],
                count: 1
            }
            this.$confirm('This will delete this pattern. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$services.patterns.delete(_query).prepare().then((resp) => {
                    this.$message({
                        type: 'success',
                        message: 'Delete completed'
                    });
                    console.log(resp);
                    this.getPatternList();
                });
            }).catch(() => {
                // console.log('Del id noooooooooooo', id);
            });
        },
        handleSortBy(item) {
            let _curSortDir = _.cloneDeep(this.sortBy[item].dir);
            _.each(this.sortBy, it => {
                it.dir = '';
            });
            if (_curSortDir) {
                this.sortBy[item].dir = (_curSortDir === 'ASC') ? 'DESC' : 'ASC';
            } else {
                this.sortBy[item].dir = 'ASC';
            }
            this.getPatternList({field: item, dir: this.sortBy[item].dir});
        }

    },
    destroyed() {

    }
}
</script>

<style lang="scss">

.fst-AdminPatterns {
    .table.iqTab {
        td {
            vertical-align: middle;

            &.control {
                width: 100px;

                .el-button {
                    padding: 2px 7px;
                }
            }
        }
    }

}
</style>
<style lang="scss" scoped>
.subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
}

.is-link {
    cursor: pointer;
}
</style>
