<template>
    <div class="admin-content mb-3">
        <h3 class="mt-0">{{$t('admin.wordStat.title')}}</h3>
        <div class="fst-Wordstat">
            <div>
                <el-row class="top-block" type="flex" :gutter="20">
                    <el-col :span="15">
                        <el-input class="mr-2" v-model="predicateValue" clearable
                                  :placeholder="$t('placeholder.Enter_keyword')"
                                  @keydown.enter.native.prevent="handleSearchKeyword">
                            <i slot="prepend" class="el-icon-search"></i>
                        </el-input>
                    </el-col>
                    <el-col :span="9" class="d-flex align-items-center justify-content-between">
                        <el-select v-model="curType" placeholder="Select" class="w-100" @change="handleSearchKeyword">
                            <el-option
                                    v-for="item in docTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                        <div class="ml-3 d-flex">
                            <el-button type="primary" @click="handleSearchKeyword">{{$t('search')}}</el-button>
                        </div>
                    </el-col>
                </el-row>
                <template v-if="result.search.list.length">
                    <h4 v-if="predicateValue">
                        {{$t('admin.wordStat.semanticProximityFor')}} "{{ predicateValue }}" {{$t('inSource')}} {{$t('searchMode.source.' + curType) }}
                    </h4>
                    <div class="results">
                        <div class="table-like iqTab">
                            <div class="thead">
                                <div>
                                    <div>
                                        <div>
                                            {{$t('admin.wordStat.wordPhrase')}}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{$t('admin.wordStat.score')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tbody">
                                <div v-for="(item, key) in result.search.list" :key="key">
                                    <div>
                                    <span class="link" @click="handleClickKeyword(item)"
                                          @contextmenu="handleContextMenu($event, item.keyword)">
                                        {{ item.keyword }}
                                    </span>
                                    </div>
                                    <div>
                                        {{ item.score }}
                                        <el-tooltip class="item" effect="dark" placement="top"
                                                    content="Search in Teqviser"
                                                    :open-delay="1500">
                                            <el-link type="primary" @click="handleOpenSearch(item.keyword)"
                                                     :underline="false">
                                                <i class="fas fa-search"></i>
                                                <i class="fas fa-arrow-alt-right ml-1"></i>
                                            </el-link>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </div>
            <div class="desc">
                <div :class="['collapsable',{collapsed: collapsed}]" @click="collapsed = !collapsed">
                    <template v-if="false">
                        <div class="chart-block mb-3" v-show="false">
                            <apexchart
                                    ref="wordChart"
                                    :options="lineChartOptions"
                                    :series="lineSeries">
                            </apexchart>
                        </div>
                        <div class="chart-block mb-3" v-show="result.list.length > 0">
                            <apexchart
                                    ref="wordColumns"
                                    :options="columnsChartOptions"
                                    :series="lineSeries"
                                    @dataPointSelection="dataPointSelectionHandler">
                            </apexchart>
                        </div>
                        <div class="chart-block mb-3" v-show="result.list.length > 0">
                            <apexchart
                                    ref="wordArea"
                                    :options="areaChartOptions"
                                    :series="lineSeries">
                            </apexchart>
                        </div>
                    </template>
                    <div v-html="$t('admin.wordStat.description')"></div>
                </div>
                <template v-if="result.keywords.list.length">

                    <h4 v-if="predicateValue">
                        {{$t('admin.wordStat.morphologyFor')}}  "{{ predicateValue }}" {{$t('inSource')}} {{ $t('searchMode.source.' + curType) }}
                    </h4>
                    <div class="results">
                        <div class="table-like iqTab">
                            <div class="thead">
                                <div>
                                    <div>
                                        <div>
                                            {{$t('admin.wordStat.wordPhrase')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tbody">
                                <div v-for="(item, key) in result.keywords.list" :key="key">
                                    <div>
                                    <span class="link" @click="handleClickKeyword(item)"
                                          @contextmenu="handleContextMenu($event, item)">
                                        {{ item }}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import NotionsEditor from './NotionsEditor.vue';

    import {USER_ROLES} from '@/models/Users';


    export default {
        name: "Wordstat",
        props: {},
        data() {
            return {
                predicateValue: '',
                curType: 'News',
                collapsed: false,
                docTypes: [
                    {
                        label: this.$t('searchMode.source.News'),
                        value: 'News'
                    },
                    {
                        label: this.$t('searchMode.source.Science'),
                        value: 'Science'
                    }],
                pageSize: 200,
                result: {
                    search: {
                        list: [],
                        total: 0,
                        offset: 0
                    },
                    keywords: {
                        list: [],
                        total: 0,
                        offset: 0
                    },
                },
                promises: [],
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                lineSeries: [{
                    name: 'Word stat',
                    data: [],
                    color: "#F2EBFF" // series color
                }],
                lineChartOptions: {
                    chart: {
                        height: 250,
                        type: 'line',
                        foreColor: '#C0C5CB',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    stroke: {
                        width: 7,
                        curve: 'smooth'
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            shadeIntensity: 1,
                            type: 'horizontal',
                            gradientToColors: ['#271F4D'], // start from
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100]
                        },
                    },
                    xaxis: {
                        color: '#C0C5CB',
                        type: 'category',
                        lines: {
                            show: false,
                        }
                    },
                },
                columnsSeries: [{
                    name: 'Word Cols',
                    data: [],
                    color: "" // series color
                }],

                columnsChartOptions: {
                    chart: {
                        height: 250,
                        type: 'bar',
                        foreColor: '#C0C5CB',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                        events: {
                            click: function (event, chartContext, config) {
                                // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex].x)
                            }
                        }
                    },
                    stroke: {
                        width: 0,
                        curve: 'smooth'
                    },
                    tooltip: {
                        enabled: true,
                        // theme: 'light'
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            shadeIntensity: 1,
                            type: 'vertical',
                            gradientToColors: ['#FFF'],
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100, 100, 100]
                        },
                    },
                    grid: {
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        color: '#C0C5CB',
                        type: 'category',
                        lines: {
                            show: false,
                        }
                    },
                },
                areaSeries: [{
                    name: 'Word Cols',
                    data: [],
                    color: "" // series color
                }],

                areaChartOptions: {
                    chart: {
                        height: 250,
                        type: 'area',
                        foreColor: '#C0C5CB',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                    },
                    stroke: {
                        width: 3,
                        curve: 'smooth',
                        colors: ['#FBFBFA']
                    },
                    tooltip: {
                        enabled: false,
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            shadeIntensity: 1,
                            type: 'vertical',
                            gradientToColors: ['#2C065C'],
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 75, 100]
                        },
                    },
                    grid: {
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        color: '#C0C5CB',
                        type: 'category',
                        lines: {
                            show: false,
                        }
                    },
                },

            }
        },
        computed: {
            total() {
                return `1 - ${this.result.list.length} / ${this.result.total} `;
            }
        },
        mounted() {
            // this.refresh()
        },
        methods: {
            refresh(query) {
                let _query = {
                        word: query,
                        type: this.curType.toUpperCase() || 'NEWS'
                    },
                    self = this;

                self.promises.push(
                    this.$services.wordStat.search(_query).prepare({target: this.$el}).then(resp => {

                        // console.log(_query, resp);
                        this.result.search.list = _.orderBy(resp.data.map(it => {
                            it.score = it.score.toFixed(5)
                            return it;
                        }), 'score').reverse();
                        self.lineSeries.data = resp.data.map(it => {
                            return {
                                x: it.keyword,
                                y: it.score
                            }
                        }).slice(0, 15).reverse();
                        this.collapsed = true;

                    })
                );
                self.promises.push(
                    this.$services.wordStat.morpho(_query).prepare({target: this.$el}).then(resp => {

                        // console.log(_query, resp);
                        this.result.keywords.list = _.orderBy(resp.data, 'score', 'desc');
                        self.lineSeries.data = resp.data.map(it => {
                            return {
                                x: it.keyword,
                                y: it.score
                            }
                        }).slice(0, 15).reverse();
                    })
                );
                return Promise.all(self.promises);
                /*
                // console.log('data', JSON.stringify(self.series.data));
                    self.$refs.wordChart.updateSeries([{
                        data: self.lineSeries.data,
                        name: 'Word Line Chart',
                        color: '#F2EBFF'
                    }]);
                    self.$refs.wordColumns.updateSeries([{
                        data: self.lineSeries.data,
                        color: '#320364'
                    }]);
                    self.$refs.wordArea.updateSeries([{
                        data: self.lineSeries.data,
                        color: '#FFF'
                    }]);
                    */

            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this,
                    keyIndex = config.selectedDataPoints[0][0],
                    keyword = self.lineSeries.data[keyIndex].x;
                console.log('KW--', keyword);
                self.handleOpenSearch(keyword);

            },
            handleClickKeyword(item) {
                this.predicateValue = item.keyword ? item.keyword : item;
                this.refresh(this.predicateValue);
            },
            handleOpenSearch(keyword) {
                window.localStorage.setItem('wordStatQuery', JSON.stringify({
                    query: keyword,
                    docType: this.curType,
                    from: 'wordStat'
                }));
                window.open(document.location.origin + this.$store.state.contextUrl + '/', '_blank');
            },
            handleContextMenu(event, value) {
                this.$utils.wikiTranslateContext(this, event, value);
            },
            handleSearchKeyword() {
                this.refresh(this.predicateValue);
            },
            handleInfinite(state) {
                let total = this.result.total;
                if (total > this.pageSize && total > this.result.list.length) {
                    this.refresh({offset: this.result.offset + this.pageSize}).then(data => {
                        if (data.list.length) {
                            state.loaded();
                        } else {
                            state.complete();
                        }
                    }, () => {
                        state.complete();
                    });
                } else {
                    state.complete();
                }
            }
        }
    }
</script>

<style lang="scss">
    .fst-Wordstat {
        display: flex;
        flex-direction: row;
        //justify-content: space-between;

        > div {
            flex: 1;
        }

        .desc {
            //padding: 15px 20px;
            border-radius: 5px;
            //background: $iq-color-blue-lighter;
            margin-left: 40px;
            font-weight: 400;
            font-size: 15px;
            box-sizing: border-box;
            line-height: 1.4;

            p {
                margin: 0 0 7px 0;

                &.small {
                    padding-top: 7px;
                    border-top: 1px solid $iq-color-grey-light;
                    font-size: 13px;
                }
            }
        }

        .top-block {
            align-items: center;
            padding-bottom: 15px;
        }

        .results {
            //max-height: 60vh;
            overflow-y: auto;
            //width: 690px;
        }

        .markIt {
            padding: 0;
            background: transparent;
            font-weight: 700;
            color: $iq-text-color-highlight;
        }

        .w-690 {
            max-width: 690px;
        }

        .apexcharts-tooltip.apexcharts-theme-light {
            .apexcharts-tooltip-title {
                background: $iq-dark-theme-bg;
                border: $iq-dark-theme-light;
                color: #fff;
                padding: 5px 10px;
                font-variant: all-small-caps;
                font-size: 14px !important;

            }

            .apexcharts-tooltip-text {
                background: $iq-dark-theme-text;
            }
        }

        .collapsable {
            cursor: pointer;
            position: relative;

            &.collapsed {
                cursor: row-resize;
                max-height: 42px;
                overflow-y: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    top: 5px;
                    display: block;
                    background: linear-gradient(0deg, #fff, transparent);
                    height: 35px;
                    width: 100%;
                }
            }
        }
    }
</style>

<style scoped lang="scss">
    .admin-content {
        max-height: calc(100vh - 135px);

        .link {
            transition: all .25s ease;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                color: $iq-color-blue;
            }
        }

        div.iqTab .tbody {
            max-height: calc(100vh - 348px);

            > div > div {
                &:last-child {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        div.iqTab .thead > div > div,
        div.iqTab .tbody > div > div {
            flex: 0;
            //box-sizing: border-box;
            &:last-child {
                flex-basis: 20%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &:first-child {
                flex-basis: 80%;
            }
        }
    }

    .chart-block {
        background: $iq-dark-theme-bg;
        //color: $iq-dark-theme-text;
        padding: 15px 20px;
    }
</style>