<template>
    <div class="fst-SearchFacets"
         v-loading="startLoading && tourStep !== '0'">
        <div class="facets-header">
            <div class="left-tabs">
                <div @dblclick="active=[]"
                     :class="{active: !isWidgetMode}"
                     v-if="widgets.mode !== 'all'"
                     @click="handleToggleFilterCharts('filter')"
                     v-html="$t('label.filterBy')"></div>
                <div v-html="$t('label.chartCategory')"
                     @click="handleToggleFilterCharts('charts')"
                     :class="{active: widgets.isActive}"
                     v-if="isDev && widgetTabs.includes(docType) && widgets.isVisible"></div>
            </div>
            <el-tooltip v-if="userSettings && userSettings.profileVisible && !isWidgetMode"
                        class="item" effect="dark" placement="top" :open-delay="500"
                        :content="`User search profile is ${profileIsActive ? 'activated' : 'deactivated'}`">
                <el-tag type="primary" @click="handleToggleSearchProfile"
                        :class="['searchProfile', {'active': profileIsActive}]">
                    <i :class="['fas mr-1', profileIsActive ? 'fa-sliders-v-square' : 'fa-ban']"></i>
                    {{ userSettings.defaultProfile.name }}
                </el-tag>
            </el-tooltip>
        </div>
        <template v-if="!isWidgetMode">
            <div class="facets-selected" v-if="selection.list.length || advSearchTags.length || isShowClusterFilter">
                <div>
                    <el-tooltip class="item" effect="dark" placement="top" :open-delay="500" v-if="isShowClusterFilter">
                        <div slot="content">
                            <div class="text-uppercase"><b>{{clusterFilterTag.tooltip}}</b></div>
                            <ul class="dashed capitalize">
                                <li v-for="(kw,key) in clusterFilterTag.keywords" :key="key">{{kw}}</li>
                            </ul>
                        </div>
                        <el-tag type="primary" size="small"
                                plain closable
                                @close="handleRemoveClusterFilter()">{{ clusterFilterTag.name }}
                        </el-tag>
                    </el-tooltip>
                    <el-tag v-for="(item,key) in selection.list" :key="key" type="green" size="small" plain closable
                            @close="handleCloseSelectionFaceItem(item)">{{ item.tagName }}
                    </el-tag>
                    <el-tag v-for="(item) in advSearchTags" :key="item.field" type="green" size="small" plain closable
                            @close="handleCloseSelectionAdvSearch(item.field)">{{ item.title }}: {{ item.value }}
                    </el-tag>
                </div>
                <el-link icon="far fa-times" :underline="false"
                         v-if="selection.list.length || advSearchTags.length"
                         @click="clear()">{{$t('clear')}}
                </el-link>
            </div>

            <div class="facets-content" :class="{'tour-show-step': tourStep == '4'}">
                <fst-collapse v-model="active" @collapseItemAction="handleFacetTypeToggle">
                    <fst-collapse-item v-for="facet in facets" :key="`${facet.type}--${facet.facetId || 'NotCustom'}`"
                                       v-show="!facet.hidden"
                                       :name="facet.title"
                                       v-loading="!facet.collapsed && facet.loading"
                                       :class="`facets-${facet.type}-${facet.facetId}`"
                                       @mouseenter="handleToggleSearch(facet.search, !facet.collapsed)"
                                       @mouseleave="facet.search.visible = facet.search.query || facet.search.focus"
                    >
                        <template slot="title">
                            <div @mouseenter="handleToggleSearch(facet.search, !facet.collapsed)">
                                <el-checkbox v-model="facet.selectedAll"
                                             @change="status=> handleCheckEmAll(facet,status)"
                                             v-if="facet.type === 'CUSTOM' && !facet.noSelectAll && !facet.collapsed"></el-checkbox>
                                <el-tooltip class="item" effect="dark" placement="top"
                                            :disabled="!facet.tooltip" :open-delay="700">
                                    <div slot="content"
                                         v-html="(locale === 'ru' ? facet.tooltipRu : facet.tooltip) || facet.title"></div>
                                    <span :class="['title', facet.hasSelected ? facet.color : '', facet.isSearch && facet.search.visible ? 'isSearch' : '']">
                                {{ facet.title }}
                            </span>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" placement="top"
                                            :open-delay="500">
                                    <div slot="content" v-html="facet.operatorAnd ? $t('placeholder.joinByOR')
                                : $t('placeholder.joinByAND')"></div>
                                    <span :class="['operatorAnd',{active: facet.operatorAnd}]"
                                          v-if="facet.operators"
                                          @click="handleToggleActiveOperatorAnd(facet)"
                                    >&</span>
                                </el-tooltip>
                            </div>
                            <div @mouseenter="handleToggleSearch(facet.search, true)">
                                <el-input v-show="(facet.search || {}).visible && facet.isSearch"
                                          v-model="facet.search.query"
                                          :class="facet.facetId"
                                          size="mini" prefix-icon="far fa-search" clearable
                                          @focus="facet.search.focus = true"
                                          @blur="searchBlur(facet.search)"
                                          @input="itemsFilter(facet)"
                                          @keydown.enter.native.prevent="itemsFilter(facet)">
                                </el-input>
                                <el-link class="clear" v-show="facet.hasSelected" icon="far fa-times" :underline="false"
                                         title="Clear" @click="clear(facet)"></el-link>
                            </div>
                        </template>
                        <el-popover v-for="(item, ind) in facet.itemsShow"
                                    :key="`facetPopover-${facet.type}-${item.name}-${ind}`"
                                    ref="facetPopover"
                                    v-model="item.popover"
                                    popper-class="button-popover"
                                    trigger="manual"
                                    placement="right"
                        >
                            <div>
                                <el-button @click="handleClickApply(item)" type="primary">Apply</el-button>
                            </div>
                            <el-checkbox slot="reference" v-model="item.selected"
                                         :class="[{'highlighted': (tour.highlight.Checkbox && ind === 0)}]"
                                         @change="handleSelectFacetItem(item)">
                                <flag :iso="item.iso" :squared="false" v-if="item.iso" class="mr-1"></flag>
                                <span :class="['name', {'m-ellipsis': facet.ellipsis}]"
                                      v-html="item.name"></span>
                                <span class="dots-div"></span>
                                <!--div>facetPopover-{{facet.type}}-{{item.name}}-{{ind}}</div-->
                                <el-tag type="primary" effect="plain" size="mini"
                                        :class="[{'highlighted': (tour.highlight.Count && ind === 0)}]"
                                >{{ item.docCount }}
                                </el-tag>
                                <div class="bar" :style="`width:${item.barWidth}`"></div>
                            </el-checkbox>

                        </el-popover>
                        <template v-if="facet.isSearch && facet.search.query">
                            <el-link v-if="facet.search.items.length > facet.itemsShow.length" type="primary"
                                     :underline="false" @click="itemsShowMore(facet)">More...
                            </el-link>
                        </template>
                        <template v-else>
                            <el-link v-if="facet.items.length > facet.itemsShow.length" type="primary"
                                     :underline="false"
                                     @click="itemsShowMore(facet)">More...
                            </el-link>
                        </template>
                    </fst-collapse-item>
                    <div class="toggleCustomFacets" v-if="isDev || isAdmin"
                         @click="toggleCustomFacets">
                        <i :class="[ showCustomFacets ? 'fas fa-check-square' : 'far fa-square']"></i>
                        <div>Show custom facets</div>
                    </div>
                    <fst-collapse-item v-for="item in advSearchParams" :key="item.field" :name="item.field"
                                       class="advSearchBlock">
                        <template slot="title">
                            <span class="title">{{ item.title }}</span>
                        </template>
                        <!--                    <el-input v-model="advSearchData[item.field]"-->
                        <el-input v-model="item.value"
                                  clearable
                                  :placeholder="item.placeholder"
                                  @change="handleAdvSearch">
                            <template slot="append"><i class="fas fa-search"></i></template>

                        </el-input>
                    </fst-collapse-item>
                </fst-collapse>
                <div class="darkness"></div>
                <div class="tour-step-note">
                    <div class="title" v-html="tour.curStep.title"></div>
                    <div class="text" v-html="tour.curStep.text"></div>
                    <div class="control fixed">
                        <div class="wrap">
                            <el-button type="default" @click="handleNextStep('skip')">
                                {{ $t('quickTour.skip') }}
                            </el-button>
                            <div class="breadcrumbs">
                                <div v-for="(crumb, ind) in tour.steps" :key="ind" @click="handleNextStep(ind)">
                                    <i :class="['fa-circle', (tour.curStep.index === ind) ? 'fas' : 'far']"></i>
                                </div>
                            </div>
                            <div class="d-flex">
                                <el-button type="default" @click="handleNextStep('back')">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                         viewBox="0 0 17.818 12.526">
                                        <path id="left-arrow"
                                              d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                              fill="currentColor"></path>
                                    </svg>
                                    {{ $t('quickTour.back') }}
                                </el-button>
                                <el-button type="primary" @click="handleNextStep()">
                                    {{ $t('quickTour.next') }}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                         viewBox="0 0 17.818 12.526">
                                        <path id="left-arrow"
                                              d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                              fill="currentColor"></path>
                                    </svg>
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div :class="['sw-menu' , `wm-${widgetMode}`]">
                <div class="item" v-for="(item, ind) in widgets.list[docType]"
                     :class="[
                         {active: activeMenuIndex.link.includes(ind)},
                         {marked: activeMenuIndex.marker.includes(ind)},
                     ]"
                     @click="$store.commit('toggleSwMenu', ind)"
                     :key="ind">
                    <span v-html="$t(item.title)"></span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import utils from '@/commons/utils';
    import FstCollapse from '@/commons/components/FstCollapse.vue';
    import FstCollapseItem from '@/commons/components/FstCollapseItem.vue';
    import {mapState} from "vuex";
    import _ from "lodash";

    export default {
        name: "SearchFacets",
        components: {FstCollapse, FstCollapseItem},
        props: {
            value: {
                type: Array,
                default: () => ([])
            },
            filter: {
                type: Object,
                default: () => ({
                    requests: []
                })
            },
            userSettings: {
                type: Object,
                default: () => ({})
            },
            selectedItems: {
                type: Array
            },
            from: {
                type: String
            },
            tourStep: {
                type: String
            }
        },
        data() {
            return {
                facets: [],
                requestFacets: {},
                numberAtFirst: 5,
                numberAtMore: 10,
                profileIsActive: false,
                profileIsVisible: false,
                reQ: {},
                widgetTabs: [],
                active: [],
                loading: true,
                startLoading: true,
                customFacets: [],
                customIds: [],
                filterChartMode: 'filter',
                customName: {},
                tab: {},
                advSearchParams: [],
                selection: {
                    list: [],
                    requests: []
                },
                advSearchData: {
                    ids: '',
                    journalNameQuery: '',
                    authorsQuery: ''
                },
                tour: {
                    curStep: {
                        title: 'Фильтруйте',
                        text: 'документы по их атрибутам',
                        index: 0
                    },
                    steps: [{
                        title: 'Фильтруйте',
                        text: 'документы по их атрибутам',
                        actions: [{
                            type: 'highlight',
                            target: 'Checkbox'
                        }],
                    }, {
                        title: 'Оценивайте статистику',
                        text: 'по количеству документов в разрезе атрибутов',
                        actions: [{
                            type: 'highlight',
                            target: 'Checkbox'
                        }, {
                            type: 'highlight',
                            target: 'Count'
                        }],
                    }],
                    highlight: {
                        Checkbox: true,
                        Count: false,
                    }
                },
                advSearchTags: [],
                facetParam: [
                    {
                        id: 'News',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year',
                                tooltip: 'Year of publication. Shared facet.',
                                tooltipRu: 'Год публикации',
                                isCommon: true,
                                order: 10,
                            }, {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Keywords',
                                isSearch: true,
                                tooltip: 'Keywords in frequency order. Keywords are assigned <br>' +
                                    'to an article by its authors or by artificial intelligence.',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 12,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to news by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to news by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COMPANIES',
                                title: 'Company',
                                isSearch: true,
                                nameToStartCase: true,
                                operators: true,
                                tooltip: 'Companies, mentioned in the news',
                                tooltipRu: 'Компания, упомянутая в новости',
                                order: 40,
                            }, {
                                type: 'COMPANIES_SIGNIFICANT',
                                title: 'companies_significant',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                nameToStartCase: true,
                                operators: true,
                                tooltip: 'Specific companies, mentioned in the news',
                                tooltipRu: 'Компании, упомянутые в новостях, в порядке убывания их специфичности.<br><Br>' +
                                    'Специфичная компания часто упоминается в новостях, соответствующих заданному<br>' +
                                    'запросу, и редко упоминается во всех других новостях. Компания с самой высокой<br>' +
                                    'специфичностью не обязательно является самой частотной.',
                                order: 42,
                            }, {
                                type: 'HOSTING_GROUP',
                                title: 'Groups of Web sites',
                                isSearch: true,
                                tooltip: 'Groups of websites where news were published',
                                tooltipRu: 'Веб-сайты объединяются в группы по их тематике. Новости считаются <br>' +
                                    'опубликованными в группе веб-сайтов, если они опубликованы на одном из <br>' +
                                    'источников, входящих в неё.',
                                order: 50,
                            }, {
                                type: 'HOSTING_NAME',
                                title: 'Web site',
                                isSearch: true,
                                tooltip: 'Web site where news were published',
                                tooltipRu: 'Веб-сайты - источники новостей',
                                order: 60,
                            }, {
                                type: 'SYSTEM_KEYWORDS_SIGNIFICANT',
                                title: 'Significant keywords',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Keywords ordered by their significance from high to low. <br><br>' +
                                    'Significance is the ability of the keyword to reflect the meaning <br>' +
                                    'of the search result. Keyword with the highest significance may not be <br>' +
                                    'the most frequent word, but it carries the most amount of information <br>' +
                                    'about what differs the search result from other documents in the database.',
                                tooltipRu: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br><br>" +
                                    "Специфичность - это способность ключевого слова отражать основной смысл<br>" +
                                    "заданного запроса. Ключевое слово с самой высокой специфичностью не <br>" +
                                    "обязательно является самым частотным словом. Вместо этого оно несет<br>" +
                                    "максимальное количество информации о том, что отличает документы, <br>" +
                                    "полученные по запросу, от всех других документов в базе.",

                                order: 70,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Query keywords',
                                tooltipRu: 'Ключевые слова запроса',
                                color: 'blue',
                                order: 90,
                            },
                        ],
                        advSearchParams: [
                            {
                                title: 'URL',
                                field: 'urls',
                                placeholder: 'Type in the URL',
                                value: ''
                            }
                        ],
                    },                     {
                        id: 'RuNews',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year',
                                tooltip: 'Year of publication. Shared facet.',
                                tooltipRu: 'Год публикации',
                                isCommon: true,
                                order: 10,
                            },  {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to news by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to news by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COMPANIES',
                                title: 'Company',
                                isSearch: true,
                                nameToStartCase: true,
                                operators: true,
                                tooltip: 'Companies, mentioned in the news',
                                tooltipRu: 'Компания, упомянутая в новости',
                                order: 40,
                            }, {
                                type: 'HOSTING_NAME',
                                title: 'Web site',
                                isSearch: true,
                                tooltip: 'Web site where news were published',
                                tooltipRu: 'Веб-сайты - источники новостей',
                                order: 60,
                            },
                        ],
                        advSearchParams: [
                            {
                                title: 'URL',
                                field: 'urls',
                                placeholder: 'Type in the URL',
                                value: ''
                            }
                        ],
                    }, {
                        id: 'Science',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year',
                                tooltip: 'Year of publication. Shared facet.',
                                tooltipRu: 'Год публикации',
                                isCommon: true,
                                order: 1
                            }, {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Keywords',
                                // dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                tooltip: 'Keywords in frequency order. Keywords are assigned <br>' +
                                    'to an article by its authors or by artificial intelligence.',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 12,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COUNTRIES',
                                title: 'Country', nameToStartCase: true, isSearch: true,
                                tooltip: 'Location of organizations affiliated with<br> ' +
                                    'authors of scientific paper/conference proceedings',
                                tooltipRu: 'Страна организации, с которой аффилирован<br>' +
                                    'автор научной публикации',
                                order: 40,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment',
                                nameToStartCase: true, isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 45,
                            }, {
                                type: 'COMPANIES',
                                title: 'Company',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                nameToStartCase: true,
                                isSearch: true,
                                operators: true,
                                tooltip: 'Organizations affiliated with authors of<br>' +
                                    ' an article/conference proceedings',
                                tooltipRu: 'Организация, с которой аффилирован автор научной публикации',
                                order: 50,
                            },
                            // {
                            //     type: 'AUTHOR_KEYWORDS',
                            //     title: 'Keywords',
                            //     isSearch: true,
                            //     tooltip: 'Keywords assigned to an article by its authors',
                            //     order: 70,
                            // },
                            {
                                type: 'SOURCE_TYPE',
                                title: 'publicationType', isSearch: true,
                                tooltip: 'Type of science publication',
                                tooltipRu: 'Тип научной публикации',
                                order: 60,
                            },
                            {
                                type: 'SOURCE_SUBTYPE',
                                title: 'publicationSubtype', isSearch: true,
                                tooltip: 'Subtype of science publication',
                                tooltipRu: 'Подтип научной публикации',
                                order: 70,
                            },
                            {
                                type: 'ASJC_MAIN',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                title: 'Subject area', isSearch: true,
                                tooltip: 'Subject area of an article/conference proceedings<br>' +
                                    ' according to All Science Journal Classification (ASJC).<br>' +
                                    'ASJC high-level class is assigned to an article based of<br>' +
                                    ' the journal in which it was published. Each journal and,<br>' +
                                    ' hence, each article may belong to more than one subject area.',
                                tooltipRu: 'Широкая предметная область журнала, в котором опубликована<br>' +
                                    'научная статья, согласно классификатору All Science Journal<br>' +
                                    'Classification (ASJC). Журнал может одновременно относиться <br>' +
                                    'к нескольким предметным областям.',
                                order: 90,
                            }, {
                                type: 'ASJC',
                                title: 'Subject',
                                collapsed: true,
                                dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                tooltip: 'Subject of an article/conference proceedings according to<br>' +
                                    ' All Science Journal Classification (ASJC). ASJC class is<br>' +
                                    ' assigned to an article based of the journal in which it was<br>' +
                                    ' published. Each journal and, hence, each article may belong to<br>' +
                                    ' more than one subject. Subject is a detalization of Subject area.',
                                tooltipRu: "Конкретизированная тематика журнала, в котором опубликована<br>" +
                                    "научная статья, согласно классификатору All Science Journal Classification <br>" +
                                    "(ASJC). Журнал может одновременно относиться к нескольким тематикам.",
                                order: 110
                            }, {
                                type: 'SYSTEM_KEYWORDS_SIGNIFICANT',
                                title: 'Significant keywords',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Keywords ordered by their significance from high to low. <br><br>' +
                                    'Significance is the ability of the keyword to reflect the meaning <br>' +
                                    'of the search result. Keyword with the highest significance may not be <br>' +
                                    'the most frequent word, but it carries the most amount of information <br>' +
                                    'about what differs the search result from other documents in the database.',
                                tooltipRu: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br><br>" +
                                    "Специфичность - это способность ключевого слова отражать основной смысл<br>" +
                                    "заданного запроса. Ключевое слово с самой высокой специфичностью не <br>" +
                                    "обязательно является самым частотным словом. Вместо этого оно несет<br>" +
                                    "максимальное количество информации о том, что отличает документы, <br>" +
                                    "полученные по запросу, от всех других документов в базе.",
                                order: 100,
                            }, {
                                type: 'JOURNAL_RATE',
                                title: 'Journal quality',
                                collapsed: true,
                                tooltip: 'Journal quality range based on Scopus CiteScore',
                                tooltipRu: "Оценка качества журнала базируется на Scopus CiteScore - рейтинге <br>" +
                                    "журнала, ежегодно высчитываемом экспертами Elsevier Scopus на основе <br>" +
                                    "цитирования статей журнала.",
                                order: 120,
                            }, {
                                type: 'GRANT_COMPANIES',
                                title: 'Funded',
                                isSearch: true,
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                tooltip: 'Organization which gave grant for research based<br>' +
                                    ' on which scientific paper was written',
                                tooltipRu: "Организация, выдавшая грант на исследования и написание научной статьи",
                                order: 130,
                            }, {
                                type: 'AUTHORS',
                                title: 'Authors',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                nameToStartCase: true,
                                tooltip: 'Scientific paper\'s author',
                                tooltipRu: 'Автор научной статьи',
                                order: 140,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                isSearch: true,
                                collapsed: true,
                                tooltip: 'Query keywords',
                                tooltipRu: 'Ключевые слова запроса',
                                color: 'blue',
                                order: 150,
                            },
                        ],
                        advSearchParams: [
                            {
                                title: 'Author name',
                                field: 'authorsQuery',
                                placeholder: 'Enter Authors name',
                                value: ''

                            },
                            {
                                title: 'Journal name',
                                field: 'journalNameQuery',
                                placeholder: 'Enter Title of Journal',
                                value: ''
                            },
                            {
                                title: 'ID',
                                field: 'ids',
                                placeholder: 'Type in ID of the document',
                                value: ''

                            }
                        ]
                    }, {
                        id: 'ScienceCrossref',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year',
                                tooltip: 'Year of publication. Shared facet.',
                                tooltipRu: 'Год публикации',
                                isCommon: true,
                                order: 1
                            },
                            {
                                type: 'HAS_DESCRIPTION',
                                isBoolean: true,
                                title: 'Publications',
                                // dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: false,
                                names: ['withAbstract', 'all'],
                                tooltip: '',
                                value: true,
                                tooltipRu: '',
                                order: 5,
                            },
                            {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Keywords',
                                // dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                tooltip: 'Keywords in frequency order. Keywords are assigned <br>' +
                                    'to an article by its authors or by artificial intelligence.',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 12,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COMPANIES',
                                title: 'Company',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                nameToStartCase: true,
                                isSearch: true,
                                operators: true,
                                tooltip: 'Organizations affiliated with authors of<br>' +
                                    ' an article/conference proceedings',
                                tooltipRu: 'Организация, с которой аффилирован автор научной публикации',
                                order: 40,
                            },
                            {
                                type: 'COUNTRIES',
                                title: 'Country', nameToStartCase: true, isSearch: true,
                                tooltip: 'Location of organizations affiliated with<br> ' +
                                    'authors of scientific paper/conference proceedings',
                                tooltipRu: 'Страна организации, с которой аффилирован<br>' +
                                    'автор научной публикации',
                                order: 50,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment', nameToStartCase: true, isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 55,
                            },
                            {
                                type: 'CITED_BY_SCIENCE',
                                title: 'citedByScienceCount',
                                isSearch: true,
                                tooltip: 'Citation count',
                                order: 60,
                            },
                            {
                                type: 'SUBJECT',
                                title: 'Subject',
                                isSearch: true,
                                tooltip: 'Keywords assigned to an article by its authors',
                                order: 70,
                            },
                            {
                                type: 'SOURCE_TYPE',
                                title: 'Type', isSearch: true,
                                tooltip: 'Type of science publication',
                                tooltipRu: 'Тип научной публикации',
                                order: 60,
                            },
                            {
                                type: 'SOURCE_SUBTYPE',
                                title: 'Subtype', isSearch: true,
                                tooltip: 'Subtype of science publication',
                                tooltipRu: 'Подтип научной публикации',
                                order: 70,
                            },{
                                type: 'SYSTEM_KEYWORDS_SIGNIFICANT',
                                title: 'Significant keywords',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Keywords ordered by their significance from high to low. <br><br>' +
                                    'Significance is the ability of the keyword to reflect the meaning <br>' +
                                    'of the search result. Keyword with the highest significance may not be <br>' +
                                    'the most frequent word, but it carries the most amount of information <br>' +
                                    'about what differs the search result from other documents in the database.',
                                tooltipRu: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br><br>" +
                                    "Специфичность - это способность ключевого слова отражать основной смысл<br>" +
                                    "заданного запроса. Ключевое слово с самой высокой специфичностью не <br>" +
                                    "обязательно является самым частотным словом. Вместо этого оно несет<br>" +
                                    "максимальное количество информации о том, что отличает документы, <br>" +
                                    "полученные по запросу, от всех других документов в базе.",
                                order: 100,
                            },{
                                type: 'AUTHORS',
                                title: 'Authors',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                nameToStartCase: true,
                                tooltip: 'Scientific paper\'s author',
                                tooltipRu: 'Автор научной статьи',
                                order: 140,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                isSearch: true,
                                collapsed: true,
                                tooltip: 'Query keywords',
                                tooltipRu: 'Ключевые слова запроса',
                                color: 'blue',
                                order: 150,
                            },
                        ],
                        advSearchParams: [
                            {
                                title: 'Author name',
                                field: 'authorsQuery',
                                placeholder: 'Enter Authors name',
                                value: ''

                            },
                            {
                                title: 'Journal name',
                                field: 'journalNameQuery',
                                placeholder: 'Enter Title of Journal',
                                value: ''
                            },
                            {
                                title: 'ID',
                                field: 'ids',
                                placeholder: 'Type in ID of the document',
                                value: ''

                            }
                        ]
                    }, {
                        id: 'ScienceOpen',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year',
                                tooltip: 'Year of publication. Shared facet.',
                                tooltipRu: 'Год публикации',
                                isCommon: true,
                                order: 1
                            }, {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Keywords',
                                // availableForAction: ['DEV', 'ADMIN'],
                                isSearch: true,
                                tooltip: 'Keywords in frequency order. Keywords are assigned <br>' +
                                    'to an article by its authors or by artificial intelligence.',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 12,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COUNTRIES',
                                title: 'Country', nameToStartCase: true, isSearch: true,
                                tooltip: 'Location of organizations affiliated with<br> authors of scientific paper/conference proceedings',
                                tooltipRu: 'Страна организации, с которой аффилирован автор научной публикации',
                                order: 40,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment', nameToStartCase: true, isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 45,
                            }, {
                                type: 'COMPANIES',
                                title: 'Company',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                nameToStartCase: true,
                                isSearch: true,
                                operators: true,
                                tooltip: 'Organizations affiliated with authors of<br>' +
                                    ' an article/conference proceedings',
                                tooltipRu: 'Организация, с которой аффилирован автор научной публикации',
                                order: 50,
                            }, {
                                type: 'GRANT_COMPANIES',
                                title: 'Funded',
                                hide: true,
                                dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: false,
                                tooltip: 'Organization which gave grant for research based<br>' +
                                    ' on which scientific paper was written',
                                tooltipRu: "Организация, выдавшая грант на исследования и написание научной статьи",
                                order: 100,
                            }, {
                                type: 'SYSTEM_KEYWORDS_SIGNIFICANT',
                                title: 'Significant keywords',
                                collapsed: true,
                                dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                tooltip: 'Keywords ordered by their significance from high to low. <br><br>' +
                                    'Significance is the ability of the keyword to reflect the meaning <br>' +
                                    'of the search result. Keyword with the highest significance may not be <br>' +
                                    'the most frequent word, but it carries the most amount of information <br>' +
                                    'about what differs the search result from other documents in the database.',
                                tooltipRu: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br><br>" +
                                    "Специфичность - это способность ключевого слова отражать основной смысл<br>" +
                                    "заданного запроса. Ключевое слово с самой высокой специфичностью не <br>" +
                                    "обязательно является самым частотным словом. Вместо этого оно несет<br>" +
                                    "максимальное количество информации о том, что отличает документы, <br>" +
                                    "полученные по запросу, от всех других документов в базе.",
                                order: 105,
                            }, {
                                type: 'AUTHORS',
                                title: 'Authors',
                                collapsed: true,
                                isSearch: true,
                                nameToStartCase: true,
                                tooltip: 'Scientific paper\'s author',
                                tooltipRu: 'Автор научной статьи',
                                order: 110,
                            }, {
                                type: 'HOSTING_NAME',
                                title: 'Web site',
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Web sites of publishers and agregators',
                                tooltipRu: 'Веб-сайты - источники научных публикаций',
                                order: 120,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                isSearch: true,
                                collapsed: true,
                                tooltip: 'Query keywords',
                                tooltipRu: 'Ключевые слова запроса',
                                color: 'blue',
                                order: 130,
                            }
                        ],
                        advSearchParams: [
                            {
                                title: 'Author name',
                                field: 'authorsQuery',
                                placeholder: 'Enter Authors name',
                                value: ''

                            },
                            {
                                title: 'Journal name',
                                field: 'journalNameQuery',
                                placeholder: 'Enter Title of Journal',
                                value: ''
                            }
                        ]
                    }, {
                        id: 'Patents',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Year_of_patent',
                                isCommon: true,
                                order: 1
                            }, {
                                type: 'APPLICATION_YEAR',
                                title: 'Year_of_application',
                                tooltip: '',
                                tooltipRu: '',
                                order: 5
                            }, {
                                type: 'PATENT_FAMILY_HEAD',
                                title: 'patent_family',
                                isBoolean: true,
                                names: ['patent_family_head_only', 'all'],
                                tooltip: '',
                                tooltipRu: '',
                                value: false,
                                order: 8
                            },
                            {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Keywords',
                                // dontLoadOnEmptyQuery: true, hidden: false,
                                // availableForAction: ['DEV', 'ADMIN'],
                                isSearch: true,
                                tooltip: 'Keywords in frequency order. Keywords are assigned <br>' +
                                    'to an article by its authors or by artificial intelligence.',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 12,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COUNTRIES',
                                title: 'country_patent_office',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Patent office that issued a patent ',
                                tooltipRu: "Патентный офис, который выдал патент или зарегистрировал заявку на патент",
                                order: 40,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 43,
                            }, {
                                type: 'HEAD_COUNTRY',
                                title: 'Head_country',
                                isCountry: true,
                                isSearch: true,
                                tooltip: 'First priority country',
                                tooltipRu: 'Страна первого приоритета',
                                order: 45
                            }, {
                                type: 'COMPANIES',
                                title: 'Applicant',
                                nameToStartCase: true,
                                // dontLoadOnEmptyQuery: true, hidden: false,
                                isSearch: true,
                                operators: true,
                                // tooltip: 'Patent\'s applicants. Can be companies or individuals.',
                                // tooltipRu: "Компания-заявитель изобретения",
                                order: 50,
                            },{
                                type: 'SYSTEM_KEYWORDS_SIGNIFICANT',
                                title: 'Significant keywords',
                                dontLoadOnEmptyQuery: true, hidden: false,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Keywords ordered by their significance from high to low. <br><br>' +
                                    'Significance is the ability of the keyword to reflect the meaning <br>' +
                                    'of the search result. Keyword with the highest significance may not be <br>' +
                                    'the most frequent word, but it carries the most amount of information <br>' +
                                    'about what differs the search result from other documents in the database.',
                                tooltipRu: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br><br>" +
                                    "Специфичность - это способность ключевого слова отражать основной смысл<br>" +
                                    "заданного запроса. Ключевое слово с самой высокой специфичностью не <br>" +
                                    "обязательно является самым частотным словом. Вместо этого оно несет<br>" +
                                    "максимальное количество информации о том, что отличает документы, <br>" +
                                    "полученные по запросу, от всех других документов в базе.",
                                order: 60,
                            },  {
                                type: 'CPC_MAIN',
                                title: 'patent_class',
                                collapsed: true,
                                isSearch: true,
                                ellipsis: true,
                                tooltip: 'Patent class characterizes the subject described in<br>' +
                                    ' patent/application. Each patent/application may be assigned<br>' +
                                    ' to one or more patent classes according to Cooperative Patent<br>' +
                                    ' Classification (CPC). CPC system is maintained by European Patent<br>' +
                                    ' Office (EPO) and  United States Patent and Trademark Office (USPTO).',
                                tooltipRu: "Патентный класс характеризует предметную область изобретения,<br>" +
                                    "описанного в патенте. Одному патенту может быть присвоено несколько <br>" +
                                    "патентных классов согласно классификатору Cooperative Patent Classification<br>" +
                                    "(CPC). Этот классификатор доддерживается Европейским патентным офисом (EPO) <br>" +
                                    "и Ведомством по патентам и товарным знакам США (USPTO).",
                                order: 80,
                            }, {
                                type: 'CPC_EXT',
                                title: 'patent_class_ext',
                                collapsed: true,
                                isSearch: true,
                                ellipsis: true,
                                tooltip: 'Patent class characterizes the subject described in<br>' +
                                    ' patent/application. Each patent/application may be assigned<br>' +
                                    ' to one or more patent classes according to Cooperative Patent<br>' +
                                    ' Classification (CPC). CPC system is maintained by European Patent<br>' +
                                    ' Office (EPO) and  United States Patent and Trademark Office (USPTO).',
                                tooltipRu: "Патентный класс характеризует предметную область изобретения,<br>" +
                                    "описанного в патенте. Одному патенту может быть присвоено несколько <br>" +
                                    "патентных классов согласно классификатору Cooperative Patent Classification<br>" +
                                    "(CPC). Этот классификатор доддерживается Европейским патентным офисом (EPO) <br>" +
                                    "и Ведомством по патентам и товарным знакам США (USPTO).",
                                order: 90,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                isSearch: true,
                                collapsed: true,
                                tooltip: '',
                                tooltipRu: '',
                                color: 'blue',
                                order: 120,
                            },
                        ],
                        advSearchParams: [
                            {
                                title: 'ID',
                                field: 'ids',
                                placeholder: 'Type in ID of the document',
                                value: ''
                            }
                        ]

                    }, {
                        id: 'Companies',
                        items: [
                            {
                                type: 'YEAR',
                                title: 'Founded',
                                isSearch: true,
                                isCommon: true,
                                tooltip: 'Year of company\'s establishment. Shared facet.',
                                tooltipRu: 'Год основания компании',
                                order: 10
                            }, {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Category',
                                isSearch: true,
                                operators: true,
                                tooltip: 'Categories, which describe company\'s line of work,<br>' +
                                    ' assigned to a company by Crunchbase',
                                tooltipRu: "Категория характеризует сферу, в которой работает компания",
                                order: 15,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }, {
                                type: 'COUNTRIES',
                                title: 'Country',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Country of company\'s headquarters',
                                tooltipRu: "Страна головного офиса компании",
                                order: 40,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 45,
                            }, {
                                type: 'INVESTMENT_TOTAL',
                                title: 'Total investments',
                                tooltip: 'Total amount of investment into the company<br>' +
                                    ' (excluding funding rounds: debt financing, post ipo debt)',
                                tooltipRu: "Суммарный объем инвестиций в компанию <br>" +
                                    "(исключая debt financing, post ipo debt)",
                                order: 60,
                            }, {
                                type: 'COMPANY_SIZE',
                                title: 'Company size',
                                tooltip: 'Approximate number of employees',
                                tooltipRu: "Приблизительное кол-во сотрудников компании",
                                order: 70,
                            }, {
                                type: 'COMPANY_RANK',
                                title: 'Company rank',
                                tooltip: 'Rank according to Crunchbase',
                                tooltipRu: "Ранг, приписываемый компании сервисом Crunchbase",
                                order: 80,
                            }, {
                                type: 'COMPANY_STATUS',
                                title: 'Status',
                                tooltip: 'Company\'s status',
                                tooltipRu: "Статус компании",
                                order: 90,
                            }, {
                                type: 'QUERY_ELEMENTS',
                                title: 'Query keywords',
                                rawName: true,
                                collapsed: true,
                                isSearch: true,
                                tooltip: 'Query keywords',
                                tooltipRu: 'Ключевые слова запроса',
                                color: 'blue',
                                order: 100,
                            }
                        ],
                        advSearchParams: []
                    }, {
                        id: 'MarketSize',
                        items: [
                            {
                                type: 'MS_YEAR',
                                title: 'Year',
                                tooltip: 'Year of report',
                                tooltipRu: 'Год отчета',
                                isCommon: true,
                                altName: 'YEAR',
                                prefix: 'MS',
                                order: 1
                            }, {
                                type: 'MS_COMPANIES',
                                title: 'Company',
                                isSearch: true,
                                nameToStartCase: true,
                                operators: true,
                                // tooltip: 'Companies, mentioned in the news',
                                order: 40,
                            }, {
                                type: 'MS_HOSTING_GROUP',
                                title: 'Groups of Web sites',
                                isSearch: true,
                                // tooltip: 'Groups of websites where news were published',
                                order: 50,
                            }, {
                                type: 'MS_HOSTING_NAME',
                                title: 'Web site',
                                isSearch: true,
                                // tooltip: 'Web site where news were published',
                                order: 60,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }
                        ],
                        advSearchParams: []
                    }, {
                        id: 'Investment',
                        items: [
                            {
                                type: 'INVT_YEAR',
                                title: 'Year',
                                tooltip: 'Year',
                                tooltipRu: 'Год инвестиций',
                                isCommon: true,
                                altName: 'YEAR',
                                prefix: 'INVT',
                                order: 10
                            }, {
                                type: 'SYSTEM_KEYWORDS',
                                title: 'Category',
                                isSearch: true,
                                operators: true,
                                ellipsis: true,
                                tooltip: 'Categories, which describe company\'s line of work,<br>' +
                                    ' assigned to a company by Crunchbase',
                                tooltipRu: 'Ключевые слова в порядке убывания частоты встречания<br>' +
                                    'на документах. Ключевые слова приписываются статье их <br>' +
                                    'авторами или искусственным интеллектом',
                                order: 15,
                            }, {
                                type: 'COUNTRIES',
                                title: 'Country',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Country of company\'s headquarters',
                                tooltipRu: "Страна головного офиса компании",
                                order: 40,
                            }, {
                                type: 'COUNTRIES_ALIGNMENT',
                                // availableForAction: ['DEV'],
                                title: 'country_alignment',
                                nameToStartCase: true,
                                isSearch: true,
                                tooltip: 'Friendly / Unfriendly country to the Russian Federation',
                                tooltipRu: "Отношение к РФ - дружественная/недружественная страна",
                                order: 45,
                            }, {
                                type: 'INVT_TYPE',
                                title: 'Type',
                                collapsed: false,
                                isSearch: true,
                                ellipsis: true,
                                nameToStartCase: false,
                                tooltip: 'Investment type',
                                tooltipRu: 'Тип инвестиций',
                                order: 50,
                            }, {
                                type: 'INVT_SUBTYPE',
                                title: 'Subtype',
                                collapsed: false,
                                isSearch: true,
                                ellipsis: true,
                                nameToStartCase: true,
                                tooltip: 'Investment subtype',
                                tooltipRu: 'Подтип инвестиций',
                                order: 60,
                            }, {
                                type: 'INVT_INVESTOR',
                                title: 'Investor',
                                collapsed: false,
                                isSearch: true,
                                ellipsis: true,
                                nameToStartCase: true,
                                tooltip: 'Investor',
                                tooltipRu: 'Инвестор',
                                order: 80,
                            }, {
                                type: 'INVT_INVESTEE',
                                title: 'Funded company',
                                collapsed: true,
                                isSearch: true,
                                ellipsis: true,
                                sortByValue: true,
                                nameToStartCase: true,
                                tooltip: 'Investee',
                                tooltipRu: 'Инвестируемая компания',
                                order: 90,
                            }, {
                                type: 'COMPANY_SIZE',
                                title: 'Company size',
                                collapsed: true,
                                isSearch: true,
                                dontSort: true,
                                ellipsis: true,
                                tooltip: 'Company size',
                                tooltipRu: 'Размер компании',
                                order: 100,
                            }, {
                                type: 'COMPANY_RANK',
                                title: 'Company rank',
                                collapsed: true,
                                isSearch: true,
                                ellipsis: true,
                                tooltip: 'Company rank',
                                tooltipRu: 'Ранг компании',
                                order: 110,
                            }, {
                                type: 'CUSTOM',
                                facetId: 'trend',
                                title: 'Trend',
                                isSearch: true,
                                noSelectAll: true,
                                tooltip: 'Technological trends assigned to a document by Teqviser. Shared facet.',
                                tooltipRu: 'Технологический тренд, присвоенный документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 20,
                                color: 'green'
                            }, {
                                type: 'CUSTOM',
                                facetId: 'industry',
                                title: 'Industry',
                                isSearch: true,
                                tooltip: 'Industries assigned to document by Teqviser. Shared facet.',
                                tooltipRu: 'Индустрия, присвоенная документу сервисом TeqViser.',
                                isCommon: true,
                                operators: true,
                                order: 30,
                                color: 'orange'
                            }
                        ],
                        advSearchParams: []
                        // }].map(docType => {
                    }].map(docType => {
                    docType.items = docType.items.map(it => {
                        let _title = (it.title || it.facetId || it.type).toLowerCase().replace(/\s/g, '_'),
                            _tooltipLoc = this.$t('facets.tooltips.' + _title);
                        it.id = it.type + (it.facetId || '');
                        it.title = this.$t('facets.' + _title);
                        // it.tooltip = it.tooltip || it.title;
                        it.tooltipRu = it.tooltipRu || it.tooltip || it.title;
                        // it.tooltip = _tooltipLoc.includes('facets.tooltips.') ?  it.tooltip : _tooltipLoc;
                        return it;
                    })
                    return docType
                })
            }
        },
        watch: {
            value(newVal) {

            },
            userSettings(newVal) {
                this.profileIsActive = newVal.profileActive;
            },
            locale() {
                // this.refresh();
            }
            // tourStep(newVal) {
            //     console.log('New Step!!', newVal);
            // }
        },
        mounted() {
            // this.refresh();
            let self = this;
            this.toggleSearch = _.debounce(this.toggleShowSearch, 909);
            services.system.info().then(function (resp) {
                _.each((resp.data.facets || []), it => {
                    self.customFacets.push(it);
                    self.customIds.push(it.id);
                    self.customName[it.id] = it.name;
                    self.$store.commit('toggleShowSwMenu', self.widgetMode === 'all')
                });
                // --- Check boolean params from filter ---
                let booleans = (self.filter.facets.requests || []).filter(it => it.value) || [];
                booleans.forEach(fac => {
                    ((self.facetParam.find(it => it.id === self.docType) || {}).items
                        .find(it => it.type === fac.type) || {}).value = true;
                });
            });
        },
        computed: {
            globalFilter() {
                return this.$store.getters.filter;
            },
            userRoles() {
                return this.$store.state.userInfo.roles;
            },
            isDev() {
                return this.userRoles.indexOf('DEV') >= 0;
            },
            isAdmin() {
                return this.isDev || this.userRoles.indexOf('ADMIN') >= 0;
            },
            widgets() {
                return this.$store.getters.swList;
            },
            widgetMode() {
                return this.widgets.mode;
            },
            isSearchProfile() {
                return this.from === 'searchProfile'
            },
            isWidgetMode() {
                return this.widgets.isVisible && this.widgets.isActive && !this.isSearchProfile
            },
            docType() {
                return this.widgetMode === 'all' ? 'All' : (this.filter.types || [])[0];
            },
            locale() {
                return this.$store.state.locale
            },
            showCustomFacets() {
                return this.$store.state.showCustomFacets
            },
            booleanFacet() {
                return this.facetParam.find(id => id.id === this.docType).items.find(type => type.isBoolean) || {};
            },
            facetHasDescription() {
                return this.facetParam.find(id => id.id === 'ScienceCrossref').items.find(type => type.type === 'HAS_DESCRIPTION') || {};
            },
            clusterFilterTag() {
                let self = this,
                    _cF = self.globalFilter.clusterFilter,
                    _ids = (_cF.clusterIds || []).join(', '),
                    out = {};
                out.name = _cF ? _cF.source.name
                    : 'Cluster #' + _ids;
                out.keywords = _cF ? _cF.cluster.keywords.slice(0, 10) : '';
                out.tooltip = _cF ? _cF.cluster.altName || _cF.cluster.name : 'Cluster' + _ids
                // out.tooltip = _cF ? _cF.source.name + ": Cluster #" + _id : 'Cluster'
                return out;
            },
            isShowClusterFilter() {
                return this.globalFilter
                    && this.globalFilter.clusterFilter.reportId
                    && this.globalFilter.docType === this.filter.types[0];
            },
            activeMenuIndex() {
                let _ind = this.$store.getters.activeMenuIndex;
                if (this.widgetMode === 'all' && window.innerWidth > 1770) {
                    let _ind2 = _ind + 1 - 2 * (_ind % 2),
                        _out = _ind % 2 ? [_ind2] : [_ind];
                    return {link: [_ind, _ind2], marker: [_ind, _ind2]}
                } else {
                    return {link: [_ind], marker: [_ind]}
                }

            },
            advSearchName() {
                let _out = {};
                this.advSearchParams.forEach(it => {
                    _out[it.field] = it.title
                })
                return _out;
            },
        },
        methods: {
            refresh(params = {}) {
                let self = this,
                    requestFacets = {},
                    operators = {},
                    alias = {
                        'm&a': 'M&A',
                        'M&A': 'M&A',
                        ipo: 'IPO',
                        venture: 'Venture',
                        friendly: self.$t('facets.values.friendly'),
                        unfriendly: self.$t('facets.values.unfriendly'),
                    };
                self.widgetTabs = self.widgets.list ? _.map(self.widgets.list, (it, i) => i) : [];
                self.profileIsActive = self.userSettings.profileActive;
                // console.log('selected From::::::', self.selectedItems);
                let _selectedItems = self.selectedItems ? self.selectedItems : self.filter.facets.requests;
                // self.requestFacets = _.keyBy(_.get(self.filter, 'facets.requests') || [], 'type');
                _.each(_selectedItems || [], it => {
                    it.id = it.type + (it.facetId || '');
                    requestFacets[it.id] = (requestFacets[it.id] || []).concat(it.values);
                    operators[it.id] = it.operator;
                })
                self.selection.list = [];
                self.facets = self.facets.map(f => {
                    f.id = f.type + (f.facetId || '');
                    f.isCommon = f.type === 'CUSTOM' || f.isCommon;
                    let selectedItems = requestFacets[f.id] || [];
                    let _thisFacet = f;
                    let _docCounts = [];
                    let isBoolean = f.isBoolean;
                    let _booleanSum = 0;
                    let items = f.items ? f.items.filter(it => {
                        return (!isBoolean || it.value)
                    }).map(item => {
                        let value = item.value || null;
                        let _name = item.name || item.value,
                            tooltip = _name;
                        let selected = _.isObject(value) ? selectedItems.some(item => _.isEqual(item, value))
                            : isBoolean ? f.value
                                : selectedItems.includes((value ? (value || '').toLowerCase() : value)) || selectedItems.includes(value);
                        let name = _.isString(_name) && !f.rawName ? (alias[_name] ? alias[_name]
                            : (f.nameToStartCase ? _.startCase(_name)
                                : _.upperFirst(_name.replace(/_/g, ' '))))
                            : _name;
                        if (f.type.includes('CPC_')) {
                            name = self.$utils.cpcNameFormat(name, 'full');
                        } else if (isBoolean) {
                            name = self.$t('facets.' + (item.value ? f.names[0] : f.names[1]))
                        }
                        // item.docCount = item.docCountRaw = f.isBoolean && !item.value ? _booleanSum : item.docCountRaw || item.docCount;
                        let it = _.merge({}, item, {
                            name: name,
                            title: tooltip,
                            // tagName: `${f.title} / ${name}`,
                            tagName: `${name}`,
                            value: value,
                            valueOrd: value ? value : '0',
                            selected: selected,
                            iso: (f.type === 'COUNTRIES' || f.isCountry) ? self.$utils.countryToISO((item.value || '').toLowerCase()) : '',
                            popover: false,
                            docCountRaw: item.docCountRaw || item.docCount,
                            docCount: utils.numbers.format(item.docCount),
                            parent: _.pick(f, ['title', 'type', 'facetId', 'isBoolean'])
                        });
                        if (selected) {
                            self.selection.list.push(it);
                        }
                        self.selection.list = _.orderBy(self.selection.list, 'valueOrd', 'asc');
                        return it;
                    }) : [];
                    f.selectedAll = (items || []).length && items.every(it => it.selected);
                    let itemsCount = this.isSearchProfile ? 12
                        : params.facetToAdd && params.facetToAdd.id === f.id ? self.numberAtFirst
                            : self.numberAtFirst;
                    let facet = Object.assign({}, f, {
                        title: f.title || f.type,
                        items: items,
                        loading: false,
                        itemsShow: items,
                        itemsCount: itemsCount,
                        hasSelected: items.some(it => it.selected),
                        operatorAnd: operators[f.id] && operators[f.id] === 'AND',
                        search: {
                            query: '',
                            items: [],
                            visible: false
                        }
                    });
                    if (facet.sortByValue) {
                        facet.items = _.orderBy(facet.items, 'docCountRaw', 'desc');
                    } else if (facet.type === 'YEAR') {
                        facet.items = _.orderBy(facet.items, 'valueOrd', 'desc');
                    }
                    let _selectedNum = facet.items.filter(it => it.selected).length || 0;
                    let _facet = self.sortFacets(facet, 'items', 'itemsShow').slice(0, itemsCount + _selectedNum);
                    let _max = _.max(_.map(_facet, 'docCountRaw'));
                    // console.log('facet ------ ', facet, _max);
                    facet.itemsShow = _facet.map(it => {
                        it.barWidth = Math.ceil(it.docCountRaw / _max * 100) + "%";
                        return it;
                    })
                    return facet;
                });

                self.loading = !(self.facets && self.facets.length);
                self.updateAdvTags();
            },
            getFacets() {
                let self = this;
                let facetFilter = _.cloneDeep(self.filter);
                delete facetFilter.facets.calculate;
                facetFilter.suggestQuery = false;
                let _facetVals = {},
                    _facetVal = {},
                    _operator = {};

                self.changeTab(facetFilter.types[0]);

                if (facetFilter.facets.requests) {
                    facetFilter.facets.requests.forEach(it => {
                        let _id = it.type + (it.facetId || '');
                        _facetVals[_id] = it.values;
                        _facetVal[_id] = it.value;
                        _operator[_id] = it.operator;
                        (self.tab.items.find(t => t.id === _id) || {}).collapsed = false;
                    })
                }
                let emptyQuery = !(self.filter.query || (self.filter.facets.requests || []).length || (self.filter.nativeQuery && self.filter.nativeQuery.value));
                if (emptyQuery) {
                    self.tab.items.forEach(it => {
                        if (it.dontLoadOnEmptyQuery) {
                            it.hidden = true;
                        }
                    })
                } else {
                    self.tab.items.forEach(it => {
                        if (it.dontLoadOnEmptyQuery) {
                            it.hidden = false;
                        }
                    })

                }

                facetFilter.facets.requests = self.tab.items.filter(it => !it.collapsed).map(it => {
                    let _id = it.type + (it.facetId || '');
                    return {
                        type: it.type,
                        calculate: !(it.collapsed || (it.dontLoadOnEmptyQuery && emptyQuery)),
                        // calculate: !it.collapsed,
                        facetId: it.facetId || null,
                        values: _facetVals[_id] || [],
                        value: _facetVal[_id] || null,
                        operator: _operator[_id] || null
                    }
                });
                if (self.showCustomFacets) {

                    self.customFacets.forEach(it => {
                        let _facets = _.cloneDeep(self.filter.facets),
                            _id = 'CUSTOM' + it.id;
                        let _values = _facets.requests ? (_facets.requests.find(f => f.facetId && f.facetId === it.id) || {}).values || [] : [];
                        // console.log('=:: customFac ::= ', self.customFacets, _values);
                        // console.log('=:: filter ::= ', self.filter.facets.requests);
                        facetFilter.facets.requests.push({
                            type: "CUSTOM",
                            facetId: it.id,
                            isCommon: true,
                            calculate: (_values || []).length > 0 || this.isSearchProfile,
                            operator: _operator[_id] || null,
                            values: _values
                        })
                    });
                }
                facetFilter.count = 0;
                self.allFacetsLoading(true);

                // console.log('// Facet facetFilter:', facetFilter);
                let serviceType = self.tab.id === 'Investment' ? 'investment' : self.tab.id === 'MarketSize' ? 'marketSize' : 'search';

                if (self.reQ.then) {
                    try {
                        self.reQ.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }
                // self.reQ = this.$services.analytics.fullView(query);
                // self.reQ.then(resp => {


                self.reQ = this.$services.documents[serviceType](facetFilter);
                self.reQ.then(resp => {
                    let result = resp.data
                    result.facets = result.facets.map(it => {
                        if (it.facetId) {
                            it.name = (self.customFacets[it.facetId] || {}).name;
                            it.items = it.items.filter(f => f.name !== 'Undefined');
                        }
                        return it;
                    });
                    // self.activeTab.result.facets = result.facets;
                    self.allFacetsLoading(false);

                    self.facets = _.map(self.tab.items, f => {
                        let facet = Object.assign(f, result.facets.find(it => (it.type + (it.facetId || '')) === f.id));
                        facet.calculate = !facet.collapsed;
                        facet.name = facet.type = f.type;
                        if (!facet.hide) {
                            return facet;
                        }
                    }).filter(Boolean);

                    let _customs = (result.facets || []).filter(it => it.type === 'CUSTOM' && !self.tab.customIds.includes(it.facetId)),
                        _selected = _.map((self.filter.facets.requests || []), 'facetId');

                    _customs.forEach((it, i) => {
                        this.facets.push({
                            type: 'CUSTOM',
                            facetId: it.facetId,
                            calculate: true,
                            order: 1000 + i,
                            name: it.facetId,
                            collapsed: !_selected.includes(it.facetId) && !this.isSearchProfile,
                            title: self.customName[it.facetId] || _.upperFirst(it.facetId),
                            color: 'blue',
                            items: it.items,
                            operators: true,
                            isSearch: true,
                        })
                    });
                    self.facets = _.orderBy(self.facets, 'order');
                    // console.log('filter', _selected);
                    // self.active = _.map(self.facets.filter(it => !it.collapsed), 'type').concat(_.map(self.advSearchParams, 'field'));
                    self.active = _.map(self.facets.filter(it => !it.collapsed), 'title').concat(_.map(self.advSearchParams, 'field'));

                    self.startLoading = false;
                    self.refresh();
                    // console.log('Facets JSON: ', JSON.stringify(result.facets));

                }, err => {
                    if (err.response && err.response.data.message) {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message
                        });
                    }
                });


            },
            getFacetsProfile() {
                let self = this;
                services.system.info().then(function (resp) {
                    _.each((resp.data.facets || []), it => {
                        self.customFacets.push(it);
                        self.customIds.push(it.id);
                        self.customName[it.id] = it.name;
                    });
                    self.getFacets();
                });

            },
            allFacetsLoading(status = true) {
                let self = this,
                    _facets = self.facets.filter(it => !it.collapsed);
                // _facets.forEach(it => it.loading = status);
                // console.log('Open facets: ', _facets);
                self.startLoading = status;
            },
            updateAdvTags() {
                let _out = [];
                _.each(this.advSearchParams, (it) => {
                    if (it.value) _out.push({
                        title: it.title,
                        field: it.field,
                        value: it.value
                    })
                })
                this.advSearchTags = _out;
            },
            searchBlur(el) {
                el.visible = el.query;
                el.focus = false;
            },
            getSelection() {
                let requests = [];
                //let list = [];

                this.facets.forEach(facet => {
                    let selected = [];
                    let isHasDescription = facet.type === 'HAS_DESCRIPTION';
                    facet.itemsShow.forEach(item => {
                        if (item.selected) {
                            selected.push(item);
                        }
                    });

                    if (selected.length) {
                        /*selected.forEach(item => {
                            list.push(item);
                        });*/

                        let values = facet.isBoolean ? selected[0].value : _.map(selected, 'value');
                        if ((values && values.length) || facet.isBoolean) {
                            let _req = {
                                type: facet.type,
                                values: values,
                                isCommon: !!facet.isCommon,
                            }
                            if (facet.isBoolean) {
                                _req.value = values;
                            }
                            // if (isHasDescription) {
                            //     _req.value = this.facetHasDescription.value;
                            // _req.value = selected[0].value;
                            //
                            // }
                            if (facet.facetId) {
                                _req.facetId = facet.facetId;
                            }
                            if (facet.operatorAnd) {
                                _req.operator = "AND";
                            }

                            requests.push(_req);
                        }
                    }
                    facet.selectedAll = (facet.items || []).length && facet.items.every(it => it.selected);
                });
                return Object.assign(this.selection, {requests});
            },
            itemsFilter(facet) {
                let query = facet.search.query,
                    self = this,
                    selectedItems = _.map(facet.itemsShow.filter(it => it.selected), 'value') || [],
                    selectedNum = selectedItems.length;

                // console.log('facets------', this.facets);
                let _facet = [];
                if (query.length > 1) {
                    facet.search.items = _.cloneDeep(facet.items).filter(item => {
                        let regExp = new RegExp(`${query}`, 'ig');
                        let queryFound = regExp.test(item.name);
                        if (queryFound) {
                            item.name = item.name.replace(regExp, '<em>$&</em>');
                        }
                        item.hidden = item.selected && !queryFound;
                        return queryFound || selectedItems.includes(item.value);
                    });
                    _facet = facet.search.items.slice(0, facet.itemsCount + selectedNum);
                    // facet.itemsShow = self.sortFacets(facet, 'items', 'itemsShow').slice(0, facet.itemsCount + _selectedNum);
                } else {
                    facet.search.items = [];
                    // facet.itemsShow = self.sortFacets(facet, 'items', 'itemsShow').slice(0, facet.itemsCount + _selectedNum);
                    _facet = facet.items.filter(it => selectedItems.includes(it.value))
                        .concat(facet.items.filter(it => !selectedItems.includes(it.value)).concat().slice(0, facet.itemsCount))
                        .slice(0, facet.itemsCount + selectedNum);
                }
                let _max = _.max(_.map(_facet, 'docCountRaw'));
                // console.log('facet ------ ', facet, _max);
                facet.itemsShow = _facet.map(it => {
                    it.barWidth = Math.ceil(it.docCountRaw / _max * 100) + "%";
                    return it;
                })

                // facet.itemsShow = selectedItems.concat(facet.itemsShow);
                clearTimeout(this.hidePopoverTimer);
                this.hidePopoverTimer = setTimeout(() => {
                    this.$refs.facetPopover.forEach(popover => {
                        popover.doClose();
                    });
                }, 500);
            },
            itemsShowMore(facet) {
                let start = facet.itemsShow.length;
                let end = start + this.numberAtMore;
                let items = facet.isSearch && facet.search.query ? facet.search.items : facet.items;
                // console.log('facet ------ ', facet, _max);
                let _facetitemsShow = facet.itemsShow.concat(items.slice(start, end));
                let _max = _.max(_.map(_facetitemsShow, 'docCountRaw'));
                facet.itemsShow = _facetitemsShow.map(it => {
                    it.barWidth = Math.ceil(it.docCountRaw / _max * 100) + "%";
                    return it;
                })
            },
            handleLoading(status = false) {
                this.loading = status;
            },
            handleCheckEmAll(facet, status) {
                let self = this;
                facet.hasSelected = status;
                facet.items.forEach(it => {
                    it.selected = status;
                });
                let _facets = facet.isSearch && facet.search.query ? facet.search.items : facet.items;
                let _max = _.max(_.map(_facets, 'docCountRaw'));
                facet.itemsShow = _facets.map(it => {
                    it.barWidth = Math.ceil(it.docCountRaw / _max * 100) + "%";
                    return it;
                })

                self.$nextTick(() => {
                    self.getSelection();
                    self.$emit('selection-change', self.selection);
                    if (!this.isSearchProfile) {
                        facet.items[0].popover = true;
                    }
                })
            },
            handleRemoveClusterFilter() {
                let self = this;
                self.$store.commit('clearClusterFilter');
                self.$emit('apply');

            },
            handleToggleSearch(item,state){
                this.toggleSearch(item,state);
            },
            toggleShowSearch(item,state){
                item.visible = state;
            },
            clear(facet) {
                // console.log('list', this.selection.list);
                let self = this;
                if (facet) {
                    facet.itemsShow.forEach(item => {
                        item.selected = false;
                    });
                    if (this.isSearchProfile) {
                        self.selection.list = self.selection.list.filter(it => {
                            return it.parent.type + (it.parent.facetId || '') !== facet.type + (facet.facetId || '');
                        })
                    }
                    if (facet.isBoolean) {
                        self.booleanFacet.value = false;
                    }
                } else {
                    self.facets.forEach(f => {
                        f.itemsShow.forEach(item => {
                            item.selected = false;
                        });
                    });
                    self.booleanFacet.value = false;
                    _.each(self.advSearchParams, it => {
                        it.value = null;
                    });
                    self.selection.list = [];
                }
                self.updateAdvTags();
                self.getSelection();
                self.$emit('selection-change', self.selection);
                self.$emit('advancedSearch', self.advSearchParams);
                self.$emit('apply');
            },
            sortFacets(facet, _in = 'itemsShow', _out = 'itemsShow') {
                (facet[_in].find(it => it.title === 'Undefined') || {}).isUndef = true;

                let selected = _.filter(facet[_in], {selected: true});
                let bulk = _.filter(facet[_in], f => (!f.selected && !f.isUndef));
                let undef = _.filter(facet[_in], {selected: false, isUndef: true});

                facet[_out] = _.flatten([
                    selected,
                    facet.type === "YEAR" ? _.orderBy(bulk.filter(it => {
                        return parseInt(it.value) <= new Date().getFullYear()
                    }), ['valueOrd'], ['desc']) : bulk,
                    undef
                ]);

                return facet[_out];
            },
            changeTab(newVal) {
                let self = this,
                    _tab = self.facetParam.find(it => it.id === newVal);
                if (_tab) {
                    let _items = (_tab || {}).items.filter(it => {
                        return (!it.facetId || self.customIds.includes(it.facetId)) && (!it.availableForAction || it.availableForAction.some(role => self.userRoles.includes(role)))
                    });
                    self.tab = {
                        id: newVal,
                        items: _items,
                        customIds: _.map(_items.filter(it => it.facetId), 'facetId'),
                        advSearchParams: (_tab || {}).advSearchParams
                    };
                    self.advSearchParams = (_tab || {}).advSearchParams || [];
                }
            },
            handleFacetTypeToggle(item) {
                let self = this,
                    filter = _.cloneDeep(self.filter),
                    facetToAdd = self.facets.find(it => it.title === item.name);

                if (facetToAdd) {
                    if (item.status) {
                        facetToAdd.loading = true;
                        facetToAdd.collapsed = false;
                        delete filter.facets.calculate;
                        filter.facets.requests = filter.facets.requests ? filter.facets.requests.map(it => {
                            it.calculate = true;
                            return it;
                        }) : [];
                        let _typeSelected = _.map(filter.facets.requests, 'type'),
                            _facetIdSelected = _.map(filter.facets.requests, 'facetId');
                        if (!_typeSelected.includes(facetToAdd.type) || !_facetIdSelected.includes(facetToAdd.facetId)) {
                            filter.facets.requests.push({
                                type: facetToAdd.type,
                                calculate: true,
                                facetId: facetToAdd.facetId ? facetToAdd.facetId : null
                            });
                        }
                        // filter.facets.requests = [{
                        //     type: item.name,
                        //     calculate: true
                        // }];
                        filter.count = 0;
                        let searchType = filter.types.includes('Investment') ? 'investment' : filter.types.includes('MarketSize') ? 'marketSize' : 'search'
                        this.$services.documents[searchType](filter).then(resp => {
                            const result = resp.data;
                            facetToAdd.calculate = true;
                            facetToAdd.loading = false;
                            if (facetToAdd && result.facets) {
                                if (facetToAdd.facetId) {
                                    facetToAdd.items = result.facets.find(it => it.facetId === facetToAdd.facetId).items.filter(f => f.name !== 'Undefined');
                                } else {
                                    facetToAdd.items = result.facets.find(it => it.type === facetToAdd.type).items;
                                }
                                this.refresh({facetToAdd: facetToAdd});
                                setTimeout(() => {
                                    facetToAdd.search.visible = true;
                                    // console.log('toAdd', facetToAdd);
                                }, 50);
                            }
                        }, err => {
                            if (err.response && err.response.data.message) {
                                this.$message({
                                    type: 'error',
                                    message: err.response.data.message
                                });
                            }
                            self.loading = false;

                        });
                        // }
                    } else {
                        facetToAdd.calculate = false;
                        facetToAdd.collapsed = true;
                    }
                    // this.$emit('facetTypeToggle', item);
                }
            },
            handleToggleSearchProfile() {
                this.profileIsActive = !this.profileIsActive;
                let _query = {"profileActive": this.profileIsActive};
                this.$services.userSettings.save(_query).then(resp => {
                    this.$emit('searchProfileChanged', this.profileIsActive);
                })

            },
            handleToggleFilterCharts(state) {
                this.$store.commit('toggleShowSwMenu', state === 'charts');
            },
            handleToggleActiveOperatorAnd(facet) {
                let self = this;
                facet.operatorAnd = !facet.operatorAnd;
                self.getSelection();
                self.$emit('selection-change', self.selection);
                if (facet.hasSelected) {
                    facet.itemsShow.forEach(it => {
                        it.popover = false;
                    });
                    self.$emit('apply');
                }
                self.handleToggleSearch(facet.search, true)
            },
            handleSelectFacetItem(item) {
                let self = this;

                if (item.parent.isBoolean) {
                    self.booleanFacet.value = item.selected;
                }
                if (this.isSearchProfile) {
                    self.selection.list = [];
                    let _facetType = (self.facets || []).find(it => it.id === (item.parent.type + (item.parent.facetId || '')));
                    (_facetType.items || []).find(it => it.title === item.title).selected = item.selected;
                    // console.log('selected', item.selected, item.name, _facetType);
                    // console.log('item---', (_facetType.items || []).find(it => it.title === item.title));
                }
                // console.log('---',(self.facets.find(it => it.id === (item.parent.type + (item.parent.facetId || ''))) || {}).items.find(it => it.value === item.value));
                (self.facets.find(it => it.id === (item.parent.type + (item.parent.facetId || '')))
                    || {}).items.find(it => it.value === item.value).selected = item.selected;
                self.facets.forEach(facet => {
                    if (!this.isSearchProfile) {
                        facet.itemsShow.forEach(it => {
                            it.popover = item === it;
                        });
                    } else {
                        facet.itemsShow = self.sortFacets(facet, 'itemsShow', 'itemsShow');
                        self.selection.list = [...self.selection.list, ...facet.itemsShow.filter(it => it.selected)];
                    }
                    facet.hasSelected = facet.itemsShow.some(it => it.selected);
                });
                clearTimeout(self.applyPopoverTimer);
                self.applyPopoverTimer = setTimeout(() => {
                    item.popover = false;
                }, 4004);
                self.getSelection();
                self.$emit('selection-change', self.selection);
            },
            handleCloseSelectionFaceItem(item) {
                item.selected = false;
                this.selection.list.splice(this.selection.list.indexOf(item), 1);
                if (item.parent.isBoolean) {
                    this.booleanFacet.value = false;
                }
                this.getSelection();
                this.$emit('selection-change', this.selection);
                this.$emit('apply');
            },
            handleAdvSearch() {
                this.updateAdvTags();
                // console.log(this.advSearchParams)
                this.$emit('advancedSearch', this.advSearchParams);
                this.$emit('apply');

            },
            handleCloseSelectionAdvSearch(field) {
                console.log(this.advSearchParams.find(it => it.field === field));
                this.advSearchParams.find(it => it.field === field).value = '';
                this.handleAdvSearch();
            },
            handleClickApply(item) {
                item.popover = false;
                this.$emit('apply');
            },
            toggleCustomFacets() {
                let self = this;
                // self.showCustomFacets = !self.showCustomFacets;
                self.$store.commit('showCustomFacets', !self.showCustomFacets);
                if (self.showCustomFacets) {
                    self.getFacets();
                } else {
                    self.facets = self.facets.filter(it => (it.type !== 'CUSTOM' || it.facetId === 'TREND'))
                }
            },
            handleTourStep(step) {
                this.$emit('tourStep', step);
            },
            handleNextStep(step = '') {
                let self = this;
                if (step === 'skip') {
                    self.$emit('tourStep');
                } else {
                    let rez = self.$utils.tourNextStep({
                        step: step,
                        tour: self.tour
                    });
                    if (rez === 'nextTourBlock') {
                        self.handleTourStep('5');
                    } else if (rez === 'previousTourBlock') {
                        self.handleTourStep('3');
                    }
                }
            },
            resetNonCommonFacets() {
                let self = this;
                self.facets.forEach(facet => {
                    if (!facet.isCommon) {
                        facet.hasSelected = false;
                        facet.items = facet.items.map(it => {
                            it.selected = false;
                            return it;
                        })
                    }
                });
            },
            resetTour() {
                let _tour = this.tour;
                _tour.curStep.index = 0;
                _tour.curStep.title = _tour.steps[0].title;
                _tour.curStep.text = _tour.steps[0].text;
            },

        }
    }
</script>

<style lang="scss">
    .fst-SearchFacets {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: $iq-color-white;
        border-right: 1px solid $iq-color-blue-light;

        .facets-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 14px 20px 0 10px;
            font-weight: bold;
            //border-bottom: 1px solid $iq-color-blue-light;
            font-size: 15px;
            white-space: nowrap;
            border-bottom: 1px solid $iq-border-color-base;
            position: relative;

            .el-tag {
                font-size: 15px;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-link {
                i {
                    margin-right: 5px;
                }
            }
        }

        .left-tabs {
            position: relative;
            display: flex;
            bottom: -1px;

            > div {
                border: 1px solid $iq-border-color-base;
                padding: 9px 15px;
                background: $iq-color-blue-lighter;
                color: $iq-tag-color-12;
                cursor: pointer;
                //color: fade-out($iq-color-text-primary, .2);
                //                background: $iq-border-color-lighter;

                & + div {
                    border-left: none;
                }

                &.active {
                    border-bottom-color: transparent;
                    background: #fff;
                    color: #333;
                }
            }
        }

        .sw-menu {
            padding: 15px 20px;
            overflow-y: auto;
            overflow-x: hidden;

            .item {
                cursor: pointer;
                color: $iq-tag-color-12;
                display: flex;
                align-items: center;
                padding: 0 7px;
                font-size: 15px;
                font-weight: 600;
                height: 41px;
                border-bottom: $border;
                border-left: 2px solid transparent;

                &:hover {
                    color: $iq-color-primary;
                    transition: all .1s ease;
                }

                &.active {
                    color: $iq-color-primary;
                    //background: fade_out($iq-color-primary, 0.9);
                }

                &.marked {
                    border-left-color: $iq-color-primary;
                }

            }
        }

        .facets-selected {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            max-height: 100px;
            overflow-y: auto;
            padding: 10px 20px 5px;
            border-bottom: 1px solid $iq-color-blue-light;

            .el-tag {
                margin: 0 5px 5px 0;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                padding-right: 22px;

                i {
                    position: absolute;
                    right: 4px;
                    top: 3px;
                    background: #D5EDD7;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        font-size: 13px;
                        line-height: 1;
                    }

                    &:hover {
                        background: #21962B;
                    }
                }

                &.el-tag--primary {
                    i {
                        background: transparent;
                        color: $iq-color-primary;

                        &:hover {
                            background: $iq-color-primary;
                            color: #fff;
                        }
                    }
                }
            }

            .el-link {
                margin: 3px 0 0 0;
            }
        }

        .facets-content {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;

            > .el-collapse {
                min-height: 100px;
            }
        }

        .el-collapse-item__content {
            width: 100%;

            .el-checkbox {
                display: block;
                width: 100%;

                .el-checkbox__label {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    position: relative;

                    > span {
                        z-index: 200;
                    }

                    //justify-content: space-between;
                    .name {
                        margin-right: 5px;
                        white-space: nowrap;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .dots-div {
                        flex: 1;
                        border-bottom: 2px dotted rgba(213, 228, 246, 0);
                        position: relative;
                        bottom: 5px;
                    }

                    .el-tag {
                        //height: 21px;
                        //border-radius: 5px;
                        padding: 0 10px;
                        border: none;
                        background: transparent;
                        color: $iq-color-text-regular;
                        margin: 0 20px 0 5px;
                        font-family: system-ui;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    .bar {
                        position: absolute;
                        content: ' ';
                        background: $iq-color-grey-ultra-light;
                        height: 24px;
                        width: 50%;
                        bottom: -2px;
                        left: -20px;
                    }

                }
            }

        }

        .el-collapse {
            border: none;
            padding: 5px 20px;

            &-item {
                position: relative;

                &.facets-CPC_MAIN {
                    .el-checkbox {
                        &__label {
                            .name {
                                max-height: 38px;
                            }
                        }
                    }
                }

                &__header {
                    height: 40px;
                    line-height: 40px;
                    position: relative;
                    padding-left: 25px;
                    font-size: 15px;
                    font-weight: bold;
                    color: $iq-color-grey-light;
                    border: none;
                    display: flex;
                    justify-content: space-between;

                    > div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: calc(100% + 10px);
                        line-height: 1;
                        margin-right: -8px;

                        > div {
                            display: flex;
                            align-items: center;

                            .title.isSearch {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 120px;
                            }
                        }
                    }

                    .el-input {
                        margin-left: 7px;
                        flex: 1;
                        max-width: 125px;
                        min-width: 100px;
                    }

                    .clear {
                        margin-left: 10px;
                    }

                    .green {
                        color: darken($iq-color-trend, 5%);
                    }

                    .orange {
                        color: $iq-color-industry;
                    }

                    .blue {
                        color: $iq-color-blue;
                    }
                }

                &__arrow {
                    position: absolute;
                    left: 0;
                }

                &__wrap {
                    border: none;
                    overflow: visible;
                }

                &__content {
                    padding: 0 0 0 25px;
                    display: inline-block;

                    .el-checkbox {
                        margin: 0 0 9px 0;
                        display: flex;
                        align-items: start;
                        white-space: normal;
                        outline: none !important;

                        &__input {
                            padding-top: 2px;
                        }

                        .name {
                            margin-right: 5px;
                        }

                        .el-tag {
                            height: 17px;
                            line-height: 17px;
                            border-radius: 10px;
                        }

                        em {
                            font-style: normal;
                            font-weight: bold;
                        }
                    }

                    > div, .el-link, .el-checkbox {
                        float: left;
                        clear: both;
                    }
                }
            }
        }

        .advSearchBlock {
            .el-collapse-item__content {
                .el-input {
                    max-width: calc(100% - 22px);
                }
            }

            .el-input-group__append {
                //background: $iq-color-blue;
                //color: #fff;
                padding: 0 12px;
            }
        }

        .operatorAnd {
            display: inline-block;
            padding: 2px 3px 1px;
            color: $iq-color-blue-light;
            border: 1px solid $iq-border-color-base;
            line-height: 1;
            border-radius: 4px;
            margin-left: 5px;
            font-weight: 500;

            &.active {
                color: #fff;
                border: 1px solid $iq-color-blue;
                background: $iq-color-blue;
            }
        }

        .toggleCustomFacets {
            color: $iq-color-grey-light;
            font-weight: bold;
            font-size: 15px;
            display: flex;
            margin: 10px 0 10px 2px;
            cursor: pointer;

            i {
                margin-right: 10px;
            }
        }
    }

    .el-tooltip__popper {
        line-height: 1.5;
    }

    .el-popover {
        &.button-popover {
            min-width: auto;
            padding: 0;

            .el-button {
                outline: none !important;
            }

            &[x-placement^="right"] {
                .popper__arrow {
                    &:after {
                        border-right-color: $iq-color-primary;
                    }
                }
            }
        }
    }

    .el-tag.el-tag--primary.searchProfile {
        cursor: pointer;
        opacity: .33;
        filter: saturate(0%);

        &.active {
            opacity: 1;
            filter: saturate(100%);
        }
    }
</style>