<template>
    <div class="fst-Companies">
        <div class="admin-content mw-950">
            <el-row type="flex" class="action-panel" :gutter="20">
                <el-col :span="12">
                    <el-button type="primary" @click="editCompany()"><i class="far fa-plus mr-1"></i> Add company
                    </el-button>
                    <edit-company
                        v-if="show.editCompany"
                        :close="closeEditor"
                        :item="companyToEdit"
                    />
                </el-col>
            </el-row>
            <el-table
                :data="companies"
                height="74.5vh"
                stripe border
                class="companies-table"
                empty-text="No companies"
            >
                <el-table-column type="index" label="№" width="50"/>
                <el-table-column label="Name">
                    <template slot-scope="scope">
                        <div :class="[{disabled: scope.row.deleted}, 'link-like']"
                                 @click="editCompany(scope.row)">
                            <i class="far fa-trash-alt mr-1" v-if="scope.row.deleted"></i>
                            {{ scope.row.name }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="Roles">
                    <template slot-scope="scope">
                        <el-tag
                            v-for="tag in scope.row.companyRoles"
                            :key="tag.role"
                            type="primary">
                            {{ tag.name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column width="70" align="right">
                    <template slot-scope="scope">
                        <div class="text-center">
                        <el-button
                            size="mini"
                            plain
                            type="text"
                            icon="el-icon-edit"
                            @click="editCompany(scope.row)"
                        />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import EditCompany from './CompanyEditor';

export default {
    name: 'Companies',
    components: {
        EditCompany,
    },
    data() {
        return {
            show: {
                editCompany: false,
            },
            companies: [],
            companyName: {},
            companyToEdit: {},
            companyRoles: {}
        };
    },
    created() {
        this.mapRoles();
    },
    mounted() {
        this.getCompanies();
    },
    computed: {},
    methods: {
        getCompanies() {
            let _query = {
                "fullness": "FULL",
                "deleted": false,
                "order": [
                    {
                        "field": "name",
                        "type": "ASC"
                    }
                ],
                "offset": 0,
                "count": 8888
            };
            this.$services.companies.list(_query).prepare().then(resp => {
                this.companies = resp.data.list.map(it => {
                    let out = it;
                    out.companyRoles = _.orderBy((it.roles || []).map(rl => {
                        return {
                            role: rl,
                            name: this.companyRoles[rl]
                        }
                    }), 'name');
                    return out;
                });
                this.companies.forEach(it => {
                    this.companyName[it.id] = it.name;
                });
            });
        },
        editCompany(item) {
            this.companyToEdit = item;
            this.show.editCompany = true;
        },
        closeEditor() {
            this.show.editCompany = false;
            this.getCompanies();
        },
        mapRoles() {
            this.$store.state.roles.forEach(it => {
                this.companyRoles[it.role] = it.name;
            })
        },
    },
    destroyed() {
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.fst-Companies {
    /deep/
    .companies-table {
        width: 100%;
        font-size: 14px;

        thead {
            color: $iq-color-text-primary;
            font-weight: bold;
        }
        td{
            div.disabled{
                opacity: .5;
            }
        }
    }

    .admin-content {
        max-height: none;

        .el-button {
            height: 24px;
            padding: 0 7px;
        }
    }

    .action-panel {
        margin-bottom: 10px;
    }

    .el-tag {
        margin: 0 5px 5px 0;
    }

    .group-pagination {
        margin: 10px -9px 0 0;
    }
}
</style>
