<template>
    <div>
        <el-collapse v-if="stackTrace">
            <el-collapse-item :title="$t('message', {msg: messageTitle})" name="message">
                <pre>{{ message }}</pre>
            </el-collapse-item>
            <el-collapse-item :title="$t('stackTrace', {msg: stackTraceTitle})" name="stackTrace">
                <pre>{{ stackTrace }}</pre>
            </el-collapse-item>
        </el-collapse>
        <pre v-else>{{ message }}</pre>
    </div>
</template>

<script>
    function cutTitle(msg) {
        msg = (msg || '').trim();
        if(!msg.length) {
            return msg;
        }
        const ing = msg.indexOf('\n');
        if(ing > 0 && ing < 60) {
            msg = msg.substring(0, ing) + '...';
        }
        if(msg.length > 60) {
            msg = msg.substring(0, 60) + '...';
        }
        return msg;
    }
    export default {
        name: 'ErrorMessageView',
        props: {
            error: {
                type: Object
            }
        },
        data() {
            return {
                type: '',
                message: '',
                stackTrace: '',
            };
        },
        computed: {
            messageTitle() {
                return cutTitle(this.message);
            },
            stackTraceTitle() {
                return cutTitle(this.stackTrace);
            }
        },
        mounted() {
            this.update();
        },
        methods: {
            update() {
                const e = this.error || {};
                this.type = e.type || '';
                this.message = e.message || '';
                this.stackTrace = e.stackTrace || '';
            }
        },
        i18n: {
            messages: {ru: {
                    message: 'Сообщение: {msg}',
                    stackTrace: 'Подробно: {msg}',
            }, en: {
                    message: 'Message: {msg}',
                    stackTrace: 'In detail: {msg}',
            }}
        }
    }
</script>

<style scoped>
    pre {
        white-space: pre-wrap;
    }
</style>
