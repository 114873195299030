<template>
    <div class="fst-360view view360"
         v-loading="!showWidgets"
         element-loading-background="rgba(19, 19, 19, .7)">
        <div class="wrapper">
            <div class="filter-block">
                <div class="d-flex align-items-center">
                    <div class="periodSelector">
                        {{$t('label.period')}} {{$t('label.from')}}
                        <el-select v-model="filter.dateFrom"
                                   style="width: 63px;"
                                   popper-class="darkSelect"
                                   @change="apply">
                            <el-option v-for="year in years"
                                       :key="`from-${year.value}`"
                                       :label="year.value"
                                       :value="year.value"
                            >{{ year.value }}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <span class="dashboardExport" @click="handleExport360">
                    <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                    <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                              fill="currentColor"/>
                    </svg>
                    {{ $t('label.exportToExcel') }}
                </span>

            </div>
            <div class="widgets">
                <Keywords
                        :request="request"
                        :title="$t('dashboard.Keywords')"
                        widget="keywords"
                        field="scienceKeywords"
                        :params="{
                        rotation: {
                            rule: 'stringLength',
                            value: 10,
                            rng: 0.65
                        }
                    }">
                </Keywords>
                <TopCompanies
                        :request="request"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        :title="$t('dashboard.Most_active_companies')"
                        widget="companies"
                        tab="Companies"
                        field="companies">
                </TopCompanies>
                <News
                        :chart-options="chartOptions"
                        :request="request">
                </News>
                <SciencePatentArticles
                        :chart-options="chartOptions"
                        :loading="loading"
                        :widgetData="chartData"
                        :request="request">
                </SciencePatentArticles>
                <InvestmentVentures
                        :chart-options="chartOptions"
                        :title="$t('dashboard.Venture_investments_dynamics')"
                        :loading="loading"
                        :request="request"
                        :widget-data="chartData"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        data-type="venture"
                        :chart-series="chartSeries.VC">
                </InvestmentVentures>
                <InvestmentVentures
                        :chart-options="chartOptions"
                        :title="$t('dashboard.MA_dynamics')"
                        :loading="loading"
                        :request="request"
                        :widgetData="chartData"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        data-type="ma"
                        :chart-series="chartSeries.MA">
                </InvestmentVentures>
                <TopCompanies
                        :request="request"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        :title="$t('dashboard.Top_venture_investors')"
                        widget="topVentureInvestors"
                        field="topVentureInvestors"
                        tab="Investment">
                </TopCompanies>
                <TopDeals
                        :request="request"
                        :title="$t('dashboard.Top_MA_deals')"
                        widget="topMADeals"
                        field="topMADeals"
                        :params="{}">
                </TopDeals>
                <VentureStages
                        :chart-options="chartOptions"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        :request="request">
                </VentureStages>
                <Markets
                        :chart-options="chartOptions"
                        :request="request">
                </Markets>
                <SciencePatentCountries
                        :chart-options="chartOptions"
                        :request="request"
                        :loading="loading"
                        :widgetData="chartData.topCountries"
                        :params="{
                        rotation: {}
                    }">
                </SciencePatentCountries>
                <TopCompanies
                        :request="request"
                        :ventureSubtypeFacets="ventureSubtypeFacets"
                        :title="$t('dashboard.Top_funded_companies')"
                        widget="topFundedCompanies"
                        field="topFundedCompanies"
                        tab="Investment">
                </TopCompanies>

                <RatedList
                        :request="request"
                        :title="$t('dashboard.Top_research_institutions')"
                        widget="researchInstitutions"
                        tab="Science"
                        field="scienceCompanies"
                        :params="{
                        bgColor: 'hsl(352, 55%, 55%)', // Red2
                        color: 'hsl(352, 55%, 75%)', // Red2
                        units: 'dashboard.Total_publications'
                    }">
                </RatedList>
                <RatedList
                        :request="request"
                        :title="$t('dashboard.Top_patent_applicants')"
                        widget="patentApplicants"
                        tab="Patents"
                        field="patentCompanies"
                        :params="{
                        bgColor: 'hsl(36, 53%, 52%)', // Orange2
                        color: 'hsl(36, 50%, 72%)', // Orange2
                        units: 'dashboard.Total_patents'
                    }">
                </RatedList>
                <CompanyCountries
                        :base-chart-options="chartOptions"
                        :request="request">
                </CompanyCountries>

            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import InvestmentVentures from "@/components/dashboard/widgets/InvestmentVentures";
    import VentureStages from "@/components/dashboard/widgets/VentureStages";
    import CompanyCountries from "@/components/dashboard/widgets/CompanyCountries";
    import Markets from "@/components/dashboard/widgets/Markets";
    import SciencePatentArticles from "@/components/dashboard/widgets/SciencePatentArticles";
    import SciencePatentCountries from "@/components/dashboard/widgets/SciencePatentCountries";
    import Keywords from "@/components/dashboard/widgets/Keywords";
    import TopCompanies from "@/components/dashboard/widgets/TopCompanies";
    import News from "@/components/dashboard/widgets/News";
    import RatedList from "@/components/dashboard/widgets/RatedList";
    import TopDeals from "@/components/dashboard/widgets/TopDeals";
    import axios from 'axios';


    export default {
        name: "View360",
        components: {
            TopDeals,
            RatedList,
            News,
            TopCompanies,
            SciencePatentCountries,
            SciencePatentArticles,
            CompanyCountries,
            VentureStages,
            InvestmentVentures,
            Markets,
            Keywords
        },
        props: {
            filterQuery: String,
            incEvent: Object,
            isNativeQuery: Boolean,
            queryFields: Object,
        },
        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                predicateValue: '',
                loading: false,
                exportLoading: false,
                filter: {
                    dateFrom: new Date().getFullYear() - 5,
                    query: '',
                },
                request: {},
                reQ: {},
                showWidgets: true,
                years: [],
                minYear: 2015,
                curYear: new Date().getFullYear(),
                chartData: {},
                ventureSubtypeFacets: [],
                chartSeries: {
                    VC: [{
                        type: 'column',
                        name: 'Sum of investments',
                        title: 'Sum of investments',
                        data: [],
                        color: '#8599D0',
                        format: 'financial'
                    }, {
                        type: 'line',
                        name: 'Deal count',
                        title: 'Deal count',
                        data: [],
                        color: '#FFF',
                        yaxis: 'opposite'
                    }],
                    MA: [{
                        type: 'column',
                        name: 'Sum of investments',
                        title: 'Sum of investments',
                        data: [],
                        color: '#C69245',
                        format: 'financial'
                    }, {
                        type: 'line',
                        name: 'Deal count',
                        title: 'Deal count',
                        data: [],
                        color: '#FFF',
                        yaxis: 'opposite'
                    }],
                    markets: [],
                    companySize: [{
                        name: 'Number of companies',
                        data: [],
                        color: '#61B768',
                    }],
                    SciencePatentArticles: []
                },
                chartOptions: {
                    chart: {
                        foreColor: '#6A6C73',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                    stroke: {
                        width: [0, 3],
                        curve: 'smooth'
                    },
                    fill: {
                        opacity: 0.95,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '25%',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function (val, opts) {
                            return val + '$'
                        },
                    },
                    legend: {
                        show: false,
                        position: 'bottom',
                        horizontalAlign: 'left',
                        fontSize: '14px',
                        labels: {
                            colors: '#B2B2B2',
                            useSeriesColors: true
                        },
                        itemMargin: {
                            horizontal: 15,
                            vertical: 5
                        },
                    },
                    grid: {
                        borderColor: '#46484C',
                        // strokeDashArray: 2,
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                            }
                        },
                    },
                    yaxis: [{
                        labels: {
                            style: {
                                colors: '#6A6C73',
                                fontSize: '14px'
                            },
                        },
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#6A6C73',
                        },
                        crosshairs: {
                            show: false,
                        }
                    }],
                    xaxis: {
                        color: '#6A6C73',
                        type: 'category',
                        labels: {
                            style: {
                                fontSize: '14px'
                            },
                        },
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            offsetY: 1
                        },

                    },
                },
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
            refresh() {
                let self = this;
                let query = {
                    "filter": {
                        "query": self.filterQuery,
                        "queryFields": self.queryFields.value,
                        "spam": false,
                        "duplicate": false,
                        "subfilters": [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    },
                    "years": {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "CLOSED",
                        "min": self.filter.dateFrom,
                        "max": self.curYear
                    },
                    "widgets": [
                        'vc',
                        'ma',
                        'sciencePatentsCountries', // --- WordClouds Sci + Pats
                    ]
                };
                if (this.isNativeQuery) { // --- if native Query is ON ---
                    query.filter.nativeQuery = {
                        value: self.filterQuery
                    };
                    delete query.filter.query;
                }
                self.request = query;
                // console.log('360 view Filter: ', query);
                _.each(self.chartSeries, wid => {
                    wid.forEach(it => {
                        it.data = [];
                    })
                })
                self.loading = true;

                if (self.reQ.then) {
                    try {
                        self.reQ.cancel();
                        // console.log('>>> canceled: ', self.reQ);
                    } catch (e) {
                        console.error(e);
                    }
                }
                self.reQ = this.$services.analytics.fullView(query);
                self.reQ.then(resp => {
                    // console.log('----- Data: ', resp.data);
                    self.chartData = resp.data;
                    self.loading = false;
                    self.showWidgets = true;
                }).catch(e => {
                    console.log(e);
                });

            },
            apply() {
                this.$emit('apply');
            },
            initData() {
                let self = this,
                    _years = [],
                    _filter = {
                        count: 0,
                        offset: 0
                    };
                for (let year = this.minYear; year <= this.curYear; year++) {
                    this.years.push({value: year, disabled: false});
                    _years.push(year.toString());
                }
                _filter.facets = {
                    calculate: false,
                    requests: [{
                        calculate: true,
                        type: "INVT_SUBTYPE"
                    }, {
                        type: 'INVT_TYPE',
                        values: ['venture']
                    }, {
                        type: 'INVT_YEAR',
                        values: _years
                    }]
                }
                // if(self.reQ){
                //     self.reQ.cancel();
                // }
                self.$services.documents.investment(_filter).then(resp => {
                    self.ventureSubtypeFacets = _.map((resp.data.facets[0] || {}).items, 'value');
                    self.refresh();
                });
            },
            handleExport360() {
                if (!this.exportLoading) {
                    let self = this,
                        _query = _.cloneDeep(self.request),
                        fileName = 'Teqviser_View360.xlsx';

                    delete _query.widgets;
                    self.exportLoading = true;

                    axios({
                        url: this.$store.state.apiUrl + '/analytics/fullViewExport',
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then((resp) => {
                        // console.log('Checked query:', query, this.filter);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.exportLoading = false;
                    })
                }
            },
        }
    }
</script>

<style lang="scss">
    .fst-360view {
        .widgets {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-height: calc(100vh - 165px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 20px;
            margin-right: -20px;

            > div {
                width: calc(50% - 10px);
//                height: 490px;
            }
        }

        .widget-block {
            background: $iq-dark-theme-bg-light-1;
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;
            padding: 20px;
            box-sizing: border-box;
            position: relative;
            margin-bottom: 20px;

            .header {
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-size: 20px;
                    font-weight: 600;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    position: relative;
                }

                .viewAll {
                    font-variant: all-small-caps;
                    font-size: 14px;
                    font-weight: 400;
                    color: $iq-color-grey-medium;
                    margin-left: 20px;
                    cursor: pointer;
                    transition: all .15s ease;

                    &.noClick {
                        cursor: default;
                    }

                    span {
                        color: $iq-dark-theme-light;
                        cursor: pointer;
                        transition: all .15s ease;

                        &:hover {
                            color: $iq-dark-theme-primary;
                        }
                    }

                    &:hover:not(.noClick) {
                        color: $iq-dark-theme-primary;
                    }
                }
            }

        }

        .chart-container {
            margin: 0 -20px;
            display: flex;
            justify-content: space-between;

            > div {
                flex: 1;
            }

            &.double {
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                > div {
                    flex: 0;
                    //flex-basis: 45%;
                    + div {
                        //margin-top: 30px;
                    }
                }
            }

            .subtitle {
                margin: 0 0 10px 18px;
                color: #b2b2b2;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .apexcharts-legend.position-bottom {
            margin-top: 15px;
        }

        .apexcharts-legend-series {
            display: flex;
            align-items: center;

            .apexcharts-legend-marker {
                margin-right: 7px;
            }
        }

        .apexcharts-legend-text {
            //font-size: 14px;
            //max-width: 152px;
            //overflow: hidden;
            //display: inline-block;
            //white-space: nowrap;
            //text-overflow: ellipsis;
            //top: 3px;
            //&:hover{
            //    max-width: 600px;
            //}
        }

        .noResult {
            min-height: 320px;
            font-size: 18px;
            font-weight: 500;
            color: $iq-dark-theme-light;
        }

        .markIt {
            background: none;
            color: #fff;
        }

        .axisLabels {
            display: flex;
            justify-content: space-between;
        }

        .cloud {
            text-transform: capitalize;
            display: flex;
            justify-content: center;
            padding: 0 20px;

            .keyword {
                text-transform: capitalize;
                cursor: pointer;
            }

        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 -15px -15px;

            > * {
                flex-basis: calc(50% - 20px);
                margin: 0 0 15px 15px;
            }

            .card {
                border-radius: 5px;
                cursor: pointer;
                background: $iq-dark-theme-bg-light-2;
                padding: 15px;
                display: flex;
                color: $iq-dark-theme-light;
                font-size: 14px;

                .logo {
                    flex-basis: 55px;
                    flex-shrink: 0;
                    height: 55px;
                    border-radius: 5px;
                    background: $iq-dark-theme-primary;
                    //border: 1px solid $iq-dark-theme-color-border;
                    margin-right: 15px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;

                    img {
                        max-width: 100%;
                        height: auto;
                        border-radius: 5px;
                    }
                }

                .text {
                }

                .title {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 1.11;
                    margin-bottom: 4px;
                }

                .link,
                .investmentTotal {
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 4px;
                    font-size: 13px;
                    font-variant: all-small-caps;
                }

                .investmentTotal {
                    font-weight: 500;
                    color: $iq-color-dashboard-green;
                    font-size: 13px;
                }

                a {
                    color: $iq-dark-theme-link;
                    text-decoration: none;
                    transition: all .15s ease;

                    &:hover {
                        color: hsl(224, 100%, 65%);
                    }
                }
            }
        }

        .newsCards {
            padding: 20px;
            max-height: 390px;
            overflow-y: auto;

            .cardsSubtitle {
                font-size: 16px;
                margin: -23px 0 10px;
                font-weight: 600;
            }

            .cardItem {
                cursor: pointer;
                display: block;
                border-radius: 5px;
                background: $iq-dark-theme-bg-light-2;
                padding: 15px 20px;
                color: $iq-dark-theme-light;
                font-size: 14px;
                text-decoration: none;

                em {
                    font-style: normal;
                    //color: #fff;
                }

                & + .cardItem {
                    margin-top: 10px;
                }

                .title {
                    color: $iq-dark-theme-light;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 12px;
                    line-height: 1.15;
                    max-height: 40px;
                    overflow: hidden;
                    transition: all .2s ease;
                }

                &:hover {
                    .title {
                        color: $iq-dark-theme-primary;
                    }
                }

                .tags {
                    display: flex;
                    margin: 0 -3px 12px -3px;
                    flex-wrap: wrap;
                    max-height: 24px;
                    overflow: hidden;
                    //transition: all .35s ease-in .15s;
                    //cursor: pointer;

                    &:hover {
                        //max-height: 180px;
                        //transition: all .66s ease-in .55s;
                    }

                    > div {
                        margin: 0 3px 5px;
                        background: $iq-dark-theme-color-border;
                        border: none;
                        color: $iq-dark-theme-light;
                        font-variant: all-small-caps;
                        font-size: 12px;
                        font-weight: 500;
                        border-radius: 4px;
                        padding: 4px 9px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }


                .attrs {

                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-variant: all-small-caps;

                    a {
                        color: $iq-dark-theme-link;
                        text-decoration: none;
                        transition: all .15s ease;

                        &:hover {
                            color: hsl(224, 100%, 65%);
                        }
                    }

                    > * {
                        display: inline-block;

                        & + * {
                            display: inline-flex;
                            align-items: center;

                            &::before {
                                content: ' ';
                                display: inline-block;
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background: #7D8BB2;
                                margin: 0 9px 0 7px;
                            }
                        }
                    }
                }
            }

            &.news {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-dashboard-blue;
                        }
                    }
                }
            }

            &.science {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-dashboard-red;
                        }
                    }
                }
            }

            &.patents {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-dashboard-orange;
                        }
                    }
                }
            }

        }

        .widgetMode {
            > span {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                color: $iq-color-grey-medium;

                &.patents {
                    margin-left: 8px;

                    &.active {
                        color: $iq-color-dashboard-orange;
                    }

                    svg {
                        margin-right: 3px;
                    }
                }

                &.science {
                    &.active {
                        color: $iq-color-dashboard-red;
                    }

                    svg {
                        margin-right: 3px;
                    }
                }

                & + span {
                    margin-left: 11px;
                }

                &.active {
                    color: $iq-dark-theme-primary;
                }
            }
        }

        .ratedList {
            .listItem {
                .title {
                    font-variant: all-small-caps;
                }

                //.value, .title {
                //    color: $iq-dark-theme-light;
                //}

                .text {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                }

                .bar {
                    margin-top: 4px;
                    height: 8px;
                    opacity: .75;
                }

                & + .listItem {
                    margin-top: 15px;
                }
            }

        }

        .topDeals {
            color: $iq-dark-theme-light;
            max-height: 385px;
            overflow-y: auto;
            padding-right: 10px;

            > div {
                display: flex;
                gap: 10px;

                & + div {
                    margin-top: 18px;
                }

            }

            .item {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .date-news {
                    display: flex;
                    gap: 5px;
                    white-space: nowrap;
                }

                .date {
                    //text-transform: lowercase;
                }

                .sum {
                    font-weight: 500;
                    color: $iq-color-dashboard-green;
                }

                .company {
                    font-weight: 500;
                    color: #ddd;
                    cursor: pointer;
                    transition: all .15s ease;

                    &:hover {
                        color: #eee;
                    }
                }

                .news {
                    i {
                        padding: 0 3px;
                        font-size: 12px;
                        position: relative;
                        top: -2px;
                        //color: $iq-dark-theme-link;

                        &:hover {
                            color: $iq-dark-theme-primary;
                        }
                    }
                }

            }

        }

    }

    @media only screen and (max-width: 1400px) {
        .fst-360view {
            .widget-block {
                flex-basis: 100%;
            }

        }
    }
</style>

<style scoped lang="scss">
</style>