<template>
    <el-pagination :class="{invisible : currentTotal <= 0 && !showAlways}"
                   @current-change="onPageChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   :total="currentTotal"
                   :small="small"
                   background
                   :layout="currentLayout" v-slot:default>
        <span class="el-pagination__total">{{ $t('total') }}: {{ currentRealTotal }}</span>
    </el-pagination>
</template>

<script>
    export default {
        name: "IqPagination",
        props: {
            layout: {
                default: 'total, prev, pager, next, jumper'
            },
            pageSize: {
                type: Number,
                default: 10
            },
            offset: {
                type: Number,
                default: 0
            },
            total: {
                type: Number,
                default: 0
            },
            limit: {
                type: Number,
                default: 0
            },
            pagedList: Object,
            showAlways: Boolean,
            small: Boolean,
        },
        data() {
            let offset = Math.ceil(Math.max(this.offset || 0, 0) / this.pageSize),
                currentRealTotal = this.total || this.pagedList.total || 0;
            return {
                currentPage: offset / this.pageSize + 1,
                currentOffset: offset * this.pageSize,
                currentRealTotal: currentRealTotal,
                currentTotal: Math.min(currentRealTotal, this.limit || Infinity),
                currentLimit: this.limit
            };
        },
        computed: {
            currentLayout() {
                return this.layout.replace('total', 'slot');
            }
        },
        watch: {
            pagedList(pList) {
                pList || (pList = {});
                const offset = Math.ceil(Math.max(pList.offset || 0, 0) / this.pageSize) * this.pageSize;
                this.currentOffset = offset;
                this.currentPage = offset / this.pageSize + 1;
                this.currentRealTotal = pList.total || 0;
                this.currentTotal = Math.min(pList.total || 0, pList.limit || Infinity);
                this.currentLimit = pList.limit || 0;
            }
        },
        methods: {
            onPageChange(page) {
                const offset = Math.max(0, page - 1) * this.pageSize;
                if(this.currentLimit > 0 && this.currentLimit <= offset) {
                    this.$refs.pager.handleCurrentChange(this.currentPage = this.currentOffset / this.pageSize + 1);
                    return;
                }
                if(offset === this.currentOffset) {
                    return;
                }
                this.$emit('offset-change', offset)
            }
        }
    }
</script>

<style scoped>
    .invisible {
        visibility: hidden;
    }
</style>
