<template>
    <div class="fst-UserFeedback">
        <div class="admin-content">
            <div class="action-panel">
                <b class="mr-2">Show: </b>
                <div class="mr-2">
                    <el-select v-model="filter.isProcessed" @change="refresh()">
                        <el-option v-for="(item, key) in processedOpt"
                                   :key="key"
                                   :label="item.label"
                                   :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div>
                    <el-date-picker
                        v-model="datesFromTo"
                        type="daterange"
                        format="dd.MM.yyyy"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                        :picker-options="pickerOptions"
                        @change="refresh()">
                    </el-date-picker>
                </div>
                <div class="ml-auto">
                    <el-tag effect="plain" size="medium">{{ messages.length }}/{{ total }}</el-tag>
                </div>
            </div>
        </div>
        <div class="messages">
            <div class="message" v-for="item in messages" :key="item.id" :class="{processed: item.processed}">
                <div class="left">
                    <div class="date">{{ item.dateFormatted }}</div>
                    <div class="creator">
                        <el-tooltip effect="dark" :content="item.creator.username" placement="left" :open-delay="606">
                            <div class="name">{{ item.creator.fullName }}</div>
                        </el-tooltip>
                        <div class="company" v-if="item.creator.company"><i class="far fa-building mr-1"></i>
                            {{ item.creator.company.name }}
                        </div>
                        <div class="email" v-if="item.creator.email">
                            <a :href="`mailto:${item.creator.email}`">{{ item.creator.email }}</a>
                        </div>
                    </div>
                </div>
                <div class="main">{{ item.text }}</div>
                <div class="right">
                    <el-checkbox v-model="item.processed" @change="handleProcessMessage(item)"> Processed</el-checkbox>
                    <div>
                        <i class="fas fa-trash-alt iq-text-danger pointer py-2 mr-1"
                           @click="handleDeleteMessage(item)"></i>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <el-button type="primary" class="text-center px-5" @click="refresh(count)" v-if="total > offset+count">
                    Show more messages...
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import EditCompany from './CompanyEditor';

export default {
    name: 'Companies',
    components: {
        EditCompany,
    },
    data() {
        return {
            count: 20,
            offset: 0,
            total: 0,
            filter: {
                isProcessed: 'false',
                dateFrom: null,
                dateTo: null
            },
            processedOpt: [
                {label: 'All', value: 'null'},
                {label: 'Not processed', value: 'false'},
                {label: 'Processed', value: 'true'},
            ],
            show: {},
            companies: [],
            companyName: {},
            users: {},
            messages: [],
            datesFromTo: null,
            pickerOptions: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        };
    },
    created() {
        // this.mapNames();
        this.refresh();

    },
    mounted() {
    },
    computed: {},
    methods: {
        refresh(count = 0) {
            if (count) {
                this.offset += count;
            } else {
                this.offset = 0;
            }

            let _query = {
                offset: this.offset,
                count: this.count,
                order: [{field: 'dateCreate', type: 'DESC'}]
            };
            if (this.filter.isProcessed !== 'null') {
                _query.processed = this.filter.isProcessed === 'true';
            }
            console.log('fromTo', this.datesFromTo);
            if (this.datesFromTo) {
                _query.dateCreate = {};
                _query.dateCreate.min = this.datesFromTo[0] + 'T00:00:00.000+0300';
                _query.dateCreate.max = this.datesFromTo[1] + 'T23:59:59.999+0300'
            }

            this.$services.userFeedback.list(_query).then(resp => {
                this.messages = (count ? [...this.messages, ...resp.data.list] : resp.data.list).map(it => {
                    let _out = it;
                    _out.dateFormatted = moment(it.dateCreate).format('DD MMM YYYY HH:mm')
                    return _out;
                })
                this.total = resp.data.total;
            })
        },
        handleProcessMessage(item) {
            let _query = {
                id: item.id,
                processed: item.processed
            }
            this.$services.userFeedback.save(_query).then(resp => {
                this.refresh();
            });
        },
        handleDeleteMessage(item) {
            this.$confirm('Delete this message?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$services.userFeedback.delete({ids: [item.id]}).then((resp) => {
                    this.$message({
                        type: 'danger',
                        message: 'Delete completed'
                    });
                    this.refresh();
                });
            }).catch(() => {
            });
        },
        mapNames() {
            let promises = [],
                _query = {
                    "fullness": "FULL",
                    offset: 0,
                    count: 8888
                };
            promises.push(this.$services.companies.list(_query));
            promises.push(this.$services.security.users(_query));

            Promise.all(promises).then(resp => {
                this.companies = resp[0].data.list;
                this.companies.forEach(it => {
                    this.companyName[it.id] = it.name;
                })

                resp[1].data.list.forEach(it => {
                    this.users[it.id] = it;
                })
                this.refresh();
            });

        },
    },
    destroyed() {
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.fst-UserFeedback {
    .action-panel {
        display: flex;
        align-items: center;
    }

    .admin-content {
        max-width: 950px;
        box-sizing: border-box;


    }

    .messages {
        max-width: 950px;
        max-height: calc(100vh - 190px);
        overflow-y: auto;
        margin: 15px 0 0;

        .message {
            display: flex;
            justify-content: space-between;
            border: 1px solid $iq-border-color-base;
            border-radius: 5px;
            background: #fff;
            padding: 15px 20px;
            margin: 15px 0;
            box-sizing: border-box;

            &:first-child {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.processed {
                //color: $iq-color-is-viewed;
                > * {
                    opacity: 0.4;
                }
            }

            .left {
                flex-basis: 140px;
                //border-right: 1px solid $iq-border-color-base;
                padding-right: 20px;
                line-height: 1.4;

                .date {
                    font-size: .8rem;
                }

                .creator {
                    margin: 5px 0 0;

                    > div {
                        margin: 3px 0;
                    }

                    .name {
                        font-weight: 500;
                    }

                    .company {
                        font-size: .8rem;
                        font-weight: 500;
                        color: $iq-color-grey-blue;
                    }

                    .email {
                        font-size: .8rem;
                        margin: 3px 0;
                    }
                }
            }

            .main {
                flex: 1;
                padding: 10px 15px;
                border-radius: 5px;
                border: 1px solid $iq-border-color-base;
                line-height: 1.5;
                font-size: .85rem;
                box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
                white-space: pre-line;
            }

            .right {
                flex-basis: 100px;
                padding-left: 20px;
                //border-left: 1px solid $iq-border-color-base;

            }
        }
    }
}
</style>
