<template>
    <span ref="container" v-html="html"></span>
</template>

<script>
import 'mathjax/es5/tex-svg';
const MathJax = window.MathJax;

function span(str, start, end, pos) {
    if(!str || !start || !end || str.length < pos) {
        return null;
    }
    const si = str.indexOf(start, pos);
    const ei = si < 0 ? -1 : str.indexOf(end, si + start.length);
    return ei < 0 ? null : {text: str.substr(si + start.length, ei - si - start.length), inner: [si + start.length, ei], outer: [si, ei + end.length]};
}
function spans(str, start, end) {
    if(!str || !start || !end) {
        return [];
    }
    let pos = 0, last = null;
    const spans = [];
    while (true) {
        const s = span(str, start, end, pos);
        if(!s) {
            break;
        }
        if(pos !== s.outer[0]) {
            spans.push({text: str.substr(pos, s.outer[0] - pos), inner:[pos, s.outer[0]], outer:[pos, s.outer[0]], match: false});
        }
        if(s.text.length) {
            s.match = true;
            spans.push(s);
        }
        pos = s.outer[1] + 1;
    }
    if(pos < str.length) {
        spans.push({text: str.substr(pos, str.length - pos), inner:[pos, str.length], outer:[pos, str.length]});
    }
    return spans;
}

export default {
    name: 'MathJax',
    props: {
        text: String,
        inlineMath: {
            type: Array,
            default() {return [['$', '$']];}
        },
        displayMath: {
            type: Array,
            default() {return [['$$', '$$']];}
        }
    },
    data() {
        return {html: ''};
    },
    watch: {
        text() {
            this.refresh();
        }
    },
    mounted() {
        if(!this.$refs.container) {
            return;
        }
        this.containerMetrics = MathJax.getMetricsFor(this.$refs.container, true);
        this.refresh()
    },
    methods: {
        refresh() {
            const options = this.containerMetrics || {};
            let result = [this.text || ''];
            for (const params of [{display: 'block', maths: this.displayMath}, {display: 'inline', maths: this.inlineMath}]) {
                for (const p of params.maths) {
                    const start = p[0], end = p[1], newResult = [];
                    for (const el of result) {
                        if(typeof el !== 'string') {
                            newResult.push(el);
                            continue;
                        }
                        for (const s of spans(el, start, end)) {
                            let r = s.text;
                            if(s.match) {
                                r = MathJax.tex2svg(r, options);
                                r.style.display = params.display;
                            }
                            newResult.push(r);
                        }
                    }
                    result = newResult;
                }
            }
            this.html = result.map(t => {return t instanceof Element ? t.outerHTML : t;}).join(' ');
        }
    }
}
</script>

<style scoped>

</style>
