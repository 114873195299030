<template>
    <div class="widget-block"
         v-loading="loading"
         element-loading-background="rgba(19, 19, 19, .7)">
        <div class="header mb-4">
            <div class="title">
                {{ title }}
                <div class="viewAll" @click="(e) => handleWordClick(e)">{{ $t('dashboard.viewAll') }}</div>
            </div>
            <div v-if="params.units" class="units" :style="{ color: params.color }">{{ $t( params.units) }}</div>
        </div>
        <div class="ratedList" v-show="words.length" :style="{ color: params.color }">
            <div v-for="(item, key) in words" :key="key" class="listItem">
                <div class="text" @click="(e) => handleWordClick(e,item.title)">
                    <div class="title">{{ item.title }}</div>
                    <div class="value">{{ item.value }}</div>
                </div>
                <div class="bar" :style="{ background: params.bgColor, width: item.width }"></div>
            </div>
        </div>
        <div class="noResult" v-show="words.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "RatedList",
    props: {
        request: Object,
        title: String,
        widget: String,
        tab: String,
        field: String,
        params: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            loading: false,
            words: [],
            spacing: 1,
            reQ: {
                chart: {},
            },
            colors: [
                'hsl(224, 44%, 67%)', // BlueBright
                'hsl(36, 53%, 52%)', // Orange2
                'hsl(352, 55%, 55%)', // Red2
                '#BABABA',  // White2
                'hsl(125, 37%, 50%)', // Green2
            ]
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        request() {
            this.refresh();
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let query = _.cloneDeep(self.request),
                ii = 0;

            delete query.widgets;
            query.widgets = [
                self.widget
            ];

            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                } catch (e) {
                    console.error(e);
                }
            }

            self.loading = true;

            self.reQ.chart = self.$services.analytics.fullView(query);

            self.reQ.chart.then(resp => {
                let _data = resp.data[self.field];

                // console.log(`-- aKw ${self.field} Data: `, _data);


                // -------------- Rated List ---------------
                let max = _.max(_.map(_data, it => parseInt(it)));
                self.words = _.map(_data, (it, value) => {
                    return {
                        title: value,
                        value: self.$utils.toFin(it),
                        width: Math.ceil(parseInt(it) / max * 100) + '%'
                    };
                });
                self.loading = false;

            }).catch(e => {
                console.log(e);
            });


        },
        handleWordClick(e,keyword=null) {
            // console.log('keyword:', keyword);
            let self = this;
            let years = [],
                docType = self.tab;
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            let facets = {
                requests: [{
                    type: yearType,
                    values: years
                }]
            };
            if (keyword) {
                facets.requests.push({
                    type: 'COMPANIES',
                    values: [keyword]
                })
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
            }
            if(e.button === 0) {
                self.$utils.openSearch(params);
            }

        },
        handleCloudUpdate(e) {
            // console.log('e',e);
            this.loading = false;

        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
.units {
    //color: $iq-dark-theme-light;
}

</style>