<template>
    <div class="fst-JsonView" :class="{'fst-previewEnabled': previewEnabled}">
        <el-button-group style="position: absolute; right: 20px;">
            <el-button v-if="!raw" size="mini" @click="formatter.openAtDepth(1)">collapse</el-button>
            <el-button v-if="!raw" size="mini" @click="formatter.openAtDepth(100)">expand</el-button>
            <el-button v-if="!raw" v-for="(f, i) in currentFragments" :key="i" size="mini" @click="fragment = f; refresh();">{{ f.name || f.path || i }}</el-button>
            <el-button v-if="!raw && !!fragment" size="mini" @click="fragment = null; refresh();">root</el-button>
            <el-button v-if="raw || !fragment" size="mini" @click="toggleRaw">raw</el-button>
        </el-button-group>
        <div v-show="!raw" ref="container" :json-formatter-string-white-space="stringsWhiteSpace"></div>
        <pre v-show="raw" v-text="rawValue" style="margin: 0;"></pre>
    </div>
</template>

<script>
import JSONFormatter from 'json-formatter-js'
import {jsonApiPreProcessing} from '@/commons/services.js'

export default {
    name: "JsonViewer",
    props: {
        value: {
            type: [String, Object, Function],
            required: true
        },
        open: {type: Number, default: 1},
        previewEnabled: {type: Boolean, default: true},
        hoverPreviewEnabled: {type: Boolean, default: false},
        hoverPreviewArrayCount: {type: Number, default: 20},
        hoverPreviewFieldCount: {type: Number, default: 10},
        theme: {type: String, default: ''},
        animateOpen: {type: Boolean, default: false},
        animateClose: {type: Boolean, default: false},
        useToJSON: {type: Boolean, default: true},
        rawIndent: {type: Number, default: 2},
        stringsWhiteSpace: {
            type: String,
            validate(val) { //
                return ['normal', 'pre-line', 'nowrap'].indexOf(val) >= 0;
            },
            default() {
                return 'normal';
            }
        },
        fragments: {
            type: Array,
            default(){return [];}
        }
    },
    data() {
        return {
            raw: false,
            rawValue: null,
            currentFragments: [],
            fragment: null
        };
    },
    mounted() {
        this.refresh();
    },
    beforeDestroy() {
        if(this.formatterElement && this.$refs.container) {
            this.$refs.container.removeChild(this.formatterElement);
        }
        delete this.formatterElement;
    },
    methods: {
        refresh() {
            if(this.formatterElement && this.$refs.container) {
                this.$refs.container.removeChild(this.formatterElement);
            }
            const json = typeof this.value === 'function' ? this.value() : this.value;
            const view = fragment => fragment.view ? fragment.view(json) : _.get(json, fragment.path);
            this.currentFragments = _.filter(this.fragments || [], view);
            const fragment = this.fragment && view(this.fragment) || json;
            const open = this.fragment && this.fragment.open || this.open;
            const formatter = new JSONFormatter(fragment, open, {
                hoverPreviewEnabled: this.previewEnabled || this.hoverPreviewEnabled,
                hoverPreviewArrayCount: this.hoverPreviewArrayCount,
                hoverPreviewFieldCount: this.hoverPreviewFieldCount,
                theme: this.theme,
                animateOpen: this.animateOpen,
                animateClose: this.animateClose,
                useToJSON: this.useToJSON
            });
            const formatterElement = formatter.render();
            this.$refs.container.appendChild(formatterElement);
            this.formatterElement = formatterElement;
            this.formatter = formatter;
        },
        toggleRaw() {
            this.raw = !this.raw;
            if(!this.raw || !!this.rawValue) {
                return;
            }
            let json = typeof this.value === 'function' ? this.value() : this.value;
            if(typeof json === 'string') {
                if(this.useToJSON) {
                    json = JSON.parse(json);
                } else {
                    this.rawValue = json;
                    return;
                }
            }
            this.rawValue = JSON.stringify(jsonApiPreProcessing(json), null, this.rawIndent);
        }
    }
}
</script>

<style lang="scss">
@import "assets/imports";
.fst-JsonView {
    &.fst-previewEnabled {
        .json-formatter-preview-text {
            opacity: unset;
            transition: unset;
            color: $iq-color-text-secondary;
        }
    }

    [json-formatter-string-white-space="normal"] {
        .json-formatter-row .json-formatter-string, .json-formatter-row .json-formatter-stringifiable {
            white-space: normal;
        }
    }
    [json-formatter-string-white-space="nowrap"] {
        .json-formatter-row .json-formatter-string, .json-formatter-row .json-formatter-stringifiable {
            white-space: nowrap;
        }
    }
    [json-formatter-string-white-space="pre-line"] {
        .json-formatter-row .json-formatter-string, .json-formatter-row .json-formatter-stringifiable {
            white-space: pre-line;
        }
    }

    .json-formatter-row > span {
        display: flex;
        > .json-formatter-key {
        }
        > .json-formatter-string {
            max-height: 4em;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        > .json-formatter-string:hover {
            max-height: 12em;
            overflow: auto;
            text-overflow: unset;
        }
    }

    .json-formatter-preview-text {
        height: 1em;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .json-formatter-row .json-formatter-toggler-link {
        white-space: nowrap;

    }
    .json-formatter-row {
        &:not(.json-formatter-open) .json-formatter-constructor-name {
            display: none;
        }
    }
}
</style>
