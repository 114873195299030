import _ from 'lodash';


export default {
    state: {
        widgets: {
            current: 0,
            activeMenuIndex: 0,
            isActive: false,
            isVisible: false,
            mode: 'documents',
            list: {
                News: [
                    {
                        ind: 0,
                        component: 'SWNews',
                        group: 'A',
                        name: 'News_dynamics',
                        title: 'dashboard.News_dynamics',
                        params: {
                            "type": "GROWTH",
                            "field": "companies",
                            "limit": 10
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'frequent_companies',
                        title: 'dashboard.Frequent_companies',
                        params: {
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(35, 55%, 57%)', // orange
                            color: 'hsl(35, 55%, 45%)',
                            units: 'dashboard.Total_companies',
                            significant: false,
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'specific_companies',
                        title: 'dashboard.Specific_companies',
                        params: {
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(125, 35%, 50%)', // green
                            color: 'hsl(125, 35%, 48%)',
                            units: 'dashboard.Total_companies',
                            significant: true,
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'Keywords',
                        title: 'label.keywords',
                        params: {
                            "type": "TERM_GROWTH",
                            "field": "systemKeywords",
                            "significant": false,
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWHeatmap',
                        group: 'B',
                        class: 'full',
                        name: 'Heatmap',
                        title: 'dashboard.heatmap',
                        params: {}
                    },
                    {
                        ind: 5,
                        component: 'SWWeakSignals',
                        group: 'C',
                        class: 'full',
                        name: 'WeakSignals',
                        title: 'dashboard.weakSignals',
                        params: {}
                    },

                ],
                Science: [
                    {
                        ind: 0,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'articleDynamics',
                        title: 'dashboard.articleDynamics',
                        chartOptions: true,
                        params: {
                            "docType": 'Science',
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'keywords',
                        title: 'label.keywords',
                        params: {
                            "type": "TERM_GROWTH",
                            "significant": false,
                            "field": "systemKeywords",
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'subjectArea',
                        title: 'dashboard.Subject_area',
                        params: {
                            bgColor: '#d45087',
                            color: '#b43067',
                            units: 'dashboard.Total_publications',
                            field: 'asjc.codeMain',
                            chartType: 'donut',
                            limit: 10,
                            facet: 'ASJC_MAIN'
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_leaders',
                        title: 'dashboard.Top_research_leaders',
                        params: {
                            type: 'TERM_GROWTH',
                            field: 'countries',
                            numYears: 6,
                            limit: 10,
                            total: 50,
                            colors: [
                                '#004c6d',
                                '#346888',
                                '#5886a5',
                                '#7aa6c2',
                                '#9dc6e0',
                                '#c1e7ff',

                                '#003f5c',
                                '#444e86',
                                '#955196',
                                '#dd5182',
                                '#ff6e54',
                                '#ffa600',
                            ],
                            color: '#004c6d',
                            units: 'dashboard.Total_publications',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWDiagram',
                        group: 'A',
                        name: 'type',
                        title: 'facets.publicationtype',
                        params: {
                            field: 'sourceType',
                            chartType: 'donut',
                            limit: 10,
                            facet: 'SOURCE_TYPE'
                        }
                    },
                    {
                        ind: 5,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_institutions',
                        title: 'dashboard.Top_research_institutions',
                        params: {
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(125, 35%, 50%)', // green
                            color: 'hsl(125, 35%, 48%)',
                            units: 'dashboard.Total_publications',
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 6,
                        component: 'SWHeatmap',
                        group: 'B',
                        class: 'full',
                        name: 'Heatmap',
                        title: 'dashboard.heatmap',
                        params: {}
                    },
                    {
                        ind: 7,
                        component: 'SWWeakSignals',
                        group: 'C',
                        class: 'full',
                        name: 'WeakSignals',
                        title: 'dashboard.weakSignals',
                        params: {}
                    },

                ],
                ScienceCrossref: [
                    {
                        ind: 0,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'articleDynamics',
                        title: 'dashboard.articleDynamics',
                        chartOptions: true,
                        params: {
                            "docType": 'ScienceCrossref',
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'keywords',
                        title: 'label.keywords',
                        params: {
                            "type": "TERM_GROWTH",
                            "significant": false,
                            "field": "systemKeywords",
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'subjectArea',
                        title: 'dashboard.Subject_area',
                        params: {
                            field: 'subject',
                            limit: 10,
                            bgColor: '#58508d',
                            color: '#58508d',
                            units: 'dashboard.Total_publications',
                            facet: 'SUBJECT'
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_leaders',
                        title: 'dashboard.Top_research_leaders',
                        params: {
                            type: 'TERM_GROWTH',
                            field: 'countries',
                            numYears: 6,
                            limit: 10,
                            total: 50,
                            colors: [
                                '#004c6d',
                                '#346888',
                                '#5886a5',
                                '#7aa6c2',
                                '#9dc6e0',
                                '#c1e7ff',

                                '#003f5c',
                                '#444e86',
                                '#955196',
                                '#dd5182',
                                '#ff6e54',
                                '#ffa600',
                            ],
                            color: '#004c6d',
                            units: 'dashboard.Total_publications',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_institutions',
                        title: 'dashboard.Top_research_institutions',
                        params: {
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(125, 35%, 50%)', // green
                            color: 'hsl(125, 35%, 48%)',
                            units: 'dashboard.Total_publications',
                            facet: 'COMPANIES'
                        },
                    },
                    {
                        ind: 5,
                        component: 'SWHeatmap',
                        group: 'B',
                        class: 'full',
                        name: 'Heatmap',
                        title: 'dashboard.heatmap',
                        params: {}
                    },
                    {
                        ind: 6,
                        component: 'SWWeakSignals',
                        group: 'C',
                        class: 'full',
                        name: 'WeakSignals',
                        title: 'dashboard.weakSignals',
                        params: {}
                    },
                ],
                Patents: [
                    {
                        ind: 0,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'patentDynamics',
                        title: 'dashboard.patentDynamics',
                        params: {
                            "docType": 'Patents',
                            "type": "PATENT_DYNAMICS",
                            // "chartType" : 'bar',
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'inventionDynamics',
                        title: 'dashboard.inventionDynamics',
                        params: {
                            "docType": 'Patents',
                            "type": "PATENT_DYNAMICS",
                            "hint": 'hints.Invention_dynamics',
                            "colors": ['hsl(0, 55%, 55%)'],
                            "series": [{
                                name: 'families',
                                title: 'label.inventions',
                                facet: 'APPLICATION_YEAR',
                                facetRequest: {
                                    type: "PATENT_FAMILY_HEAD",
                                    value: true
                                }
                            },]
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'keywords',
                        title: 'label.keywords',
                        params: {
                            "type": "TERM_GROWTH",
                            "significant": false,
                            "field": "systemKeywords",
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.Patent_leaders',
                        params: {
                            field: 'countries',
                            limit: 10,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_patents',
                            isCountry: true,
                            facet: 'COUNTRIES',
                            hint: 'hints.Patent_leaders'
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.First_country_leaders',
                        params: {
                            field: 'headCountry',
                            limit: 10,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_patents',
                            isCountry: true,
                            facet: 'HEAD_COUNTRY',
                            hint: 'hints.First_country_leaders',
                        }
                    },
                    {
                        ind: 5,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.Top_inventors_by_family',
                        params: {
                            field: 'headCountry',
                            facetRequest: {
                                type: "PATENT_FAMILY_HEAD",
                                value: true
                            },
                            limit: 10,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_patents',
                            isCountry: true,
                            facet: 'HEAD_COUNTRY',
                            hint: 'hints.Top_inventors_by_family'
                        }
                    },
                    {
                        ind: 6,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'Top_patent_applicants',
                        title: 'dashboard.Top_patent_applicants',
                        params: {
                            "docType": 'Patents',
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(35, 55%, 57%)', // orange
                            color: 'hsl(35, 55%, 45%)',
                            units: 'dashboard.Total_patents',
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 7,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'Patent_class',
                        title: 'facets.patent_class',
                        params: {
                            "docType": 'Patents',
                            field: 'cpcs.codeMain',
                            limit: 10,
                            bgColor: 'hsl(125, 35%, 50%)', // green
                            color: 'hsl(125, 35%, 48%)',
                            units: 'dashboard.Total_patents',
                            facet: 'CPC_MAIN'
                        }
                    },
                    {
                        ind: 8,
                        component: 'SWHeatmap',
                        group: 'A',
                        class: 'full',
                        name: 'Heatmap',
                        title: 'dashboard.patentFirstCountries',
                        params: {
                            noControl: true,
                            namesToIso: true,
                            cols: 'COUNTRIES',
                            colsTitle: 'label.patentCountry',
                            maxCols: 20,
                            rows: 'HEAD_COUNTRY',
                            rowsTitle: 'label.headCountry',
                            maxRows: 20,
                            valType: 'normAll',
                            orderBy: 'COUNTRIES',
                            dataLabels: {
                                enabled: true,
                                style: {
                                    colors: ['#555'],
                                    fontSize: '11px',
                                },
                                formatter: function (value, opts) {
                                    // if( !opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].absolute){
                                        // console.log('sssss', opts.w.config.series[opts.seriesIndex].data);
                                    // }
                                    return opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].absolute ||  ''
                                }
                            }
                        }
                    },
                    {
                        ind: 9,
                        component: 'SWHeatmap',
                        group: 'B',
                        class: 'full',
                        name: 'Heatmap',
                        title: 'dashboard.heatmap',
                        params: {}
                    },
                    {
                        ind: 10,
                        component: 'SWWeakSignals',
                        group: 'C',
                        class: 'full',
                        name: 'WeakSignals',
                        title: 'dashboard.weakSignals',
                        params: {}
                    },

                ],
                Companies: [
                    {
                        ind: 0,
                        component: 'SWCompanyCountries',
                        group: 'A',
                        name: 'Company_foundation',
                        title: 'dashboard.Company_foundation',
                        params: {
                            "type": "GROWTH",
                            "field": "countries",
                            "count": 5,
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'topFundedCompanies',
                        title: 'dashboard.Top_funded_companies',
                        params: {
                            "type": "INVT_TOP_VENTURE",
                            "role": "INVESTEE",
                            "limit": 8,
                            "isInvestee": true,
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'Category',
                        title: 'facets.category',
                        params: {
                            "type": "TERM_COUNT",
                            "field": "systemKeywords",
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.Countries',
                        params: {
                            field: 'countries',
                            limit: 10,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_companies',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },

                ],
                MarketSize: [
                    {
                        ind: 0,
                        component: 'SWMarkets',
                        group: 'A',
                        name: 'Markets_evaluation',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Markets_evaluation',
                        params: {
                            "docType": 'MarketSize',

                        }
                    },

                ],
                Investment: [
                    {
                        ind: 0,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'mostActiveCompanies',
                        title: 'dashboard.Most_active_companies',
                        params: {
                            "type": "RELATED_SEARCH",
                            "searchType": "ACTIVE_COMPANIES",
                            "limit": 8
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWInvestmentVentures',
                        group: 'A',
                        name: 'Venture_investments_dynamics',
                        title: 'dashboard.Venture_investments_dynamics',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE"
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWInvestmentVentures',
                        group: 'A',
                        name: 'MA_dynamics',
                        title: 'dashboard.MA_dynamics',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "MA"
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'topVentureInvestors',
                        title: 'dashboard.Top_venture_investors',
                        params: {
                            "type": "INVT_TOP_VENTURE",
                            "role": "INVESTOR",
                            "limit": 8,
                            "isInvestor": true,
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWTopDeals',
                        group: 'A',
                        name: 'Top_MA_deals',
                        title: 'dashboard.Top_MA_deals',
                        params: {
                            "type": "RELATED_SEARCH",
                            "searchType": "TOP_MA_DEALS",
                            "limit": 10
                        }
                    },
                    {
                        ind: 5,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'topFundedCompanies',
                        title: 'dashboard.Top_funded_companies',
                        params: {
                            "type": "INVT_TOP_VENTURE",
                            "role": "INVESTEE",
                            "limit": 8,
                            "isInvestee": true,
                        }
                    },
                    {
                        ind: 6,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'category',
                        title: 'facets.category',
                        params: {
                            field: 'systemKeywords',
                            limit: 10,
                            bgColor: 'hsl(125, 35%, 50%)', // green
                            color: 'hsl(125, 35%, 48%)',
                            units: 'dashboard.Total_companies',
                            facet: 'SYSTEM_KEYWORDS'
                        }
                    },
                    {
                        ind: 7,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'companySize',
                        title: 'facets.company_size',
                        params: {
                            field: 'companySize',
                            limit: 10,
                            bgColor: 'hsl(35, 55%, 57%)', // orange
                            color: 'hsl(35, 55%, 45%)',
                            units: 'dashboard.Total_companies',
                            facet: 'COMPANY_SIZE'
                        }
                    },
                    {
                        ind: 8,
                        component: 'SWVentureStages',
                        group: 'A',
                        name: 'Venture_stages',
                        title: 'dashboard.Venture_stages',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE_SEED"
                        }
                    },
                    {
                        ind: 9,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.Countries',
                        params: {
                            field: 'countries',
                            limit: 10,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_companies',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                ],
                All: [
                    {
                        ind: 0,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'keywords',
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        title: 'label.keywords',
                        params: {
                            "type": "TERM_GROWTH",
                            "significant": false,
                            "field": "systemKeywords",
                            "docType": 'Science',
                            "limit": 30,
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 1,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'mostActiveCompanies',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Most_active_companies',
                        params: {
                            name: 'mostActiveCompanies',
                            "type": "RELATED_SEARCH",
                            "searchType": "ACTIVE_COMPANIES",
                            "limit": 8,
                            docType: "Companies",
                        }
                    },
                    {
                        ind: 2,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'articleDynamics',
                        title: 'dashboard.articleDynamics',
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        chartOptions: true,
                        params: {
                            "docType": 'Science',
                        }
                    },
                    {
                        ind: 3,
                        component: 'SWGrowth',
                        group: 'A',
                        name: 'patentDynamics',
                        title: 'dashboard.patentDynamics',
                        chartOptions: true,
                        params: {
                            "colors": ["#C69245"],
                            "docType": 'Patents',
                        }
                    },
                    {
                        ind: 4,
                        component: 'SWNews',
                        group: 'A',
                        name: 'News_dynamics',
                        title: 'dashboard.News_dynamics',
                        params: {
                            docType: 'News',
                            "type": "GROWTH",
                            "field": "companies",
                            "limit": 10
                        }
                    },
                    {
                        ind: 5,
                        component: 'SWVentureStages',
                        group: 'A',
                        name: 'Venture_stages',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Venture_stages',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE_SEED",
                            "docType": 'Investment'
                        }
                    },
                    {
                        ind: 6,
                        component: 'SWMarkets',
                        group: 'A',
                        name: 'Markets_evaluation',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Markets_evaluation',
                        params: {
                            "docType": 'MarketSize'
                        }
                    },
                    {
                        ind: 7,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'topVentureInvestors',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Top_venture_investors',
                        params: {
                            name: 'topVentureInvestors',
                            "type": "INVT_TOP_VENTURE",
                            "role": "INVESTOR",
                            "limit": 8,
                            "isInvestor": true,
                            docType: "Investment",

                        }
                    },
                    {
                        ind: 8,
                        component: 'SWInvestmentVentures',
                        group: 'A',
                        name: 'Venture_investments_dynamics',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Venture_investments_dynamics',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "VENTURE"
                        }
                    },
                    {
                        ind: 9,
                        component: 'SWInvestmentVentures',
                        group: 'A',
                        name: 'MA_dynamics',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.MA_dynamics',
                        params: {
                            "type": "INVT_GROWTH",
                            "filter": "MA"
                        }
                    },
                    {
                        ind: 10,
                        component: 'SWTopDeals',
                        group: 'A',
                        name: 'Top_MA_deals',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Top_MA_deals',
                        params: {
                            "type": "RELATED_SEARCH",
                            "searchType": "TOP_MA_DEALS",
                            "limit": 12
                        }
                    },
                    {
                        ind: 11,
                        component: 'SWTopCompanies',
                        group: 'A',
                        name: 'topFundedCompanies',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Top_funded_companies',
                        params: {
                            name: 'topFundedCompanies',
                            "type": "INVT_TOP_VENTURE",
                            "role": "INVESTEE",
                            "limit": 8,
                            "isInvestee": true,
                            docType: "Investment",

                        }
                    },
                    {
                        ind: 12,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_leaders',
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        title: 'dashboard.Top_research_leaders',
                        params: {
                            "docType": 'Science',
                            type: 'TERM_GROWTH',
                            field: 'countries',
                            numYears: 6,
                            limit: 10,
                            total: 50,
                            colors: [
                                '#004c6d',
                                '#346888',
                                '#5886a5',
                                '#7aa6c2',
                                '#9dc6e0',
                                '#c1e7ff',
                            ],
                            color: '#004c6d',
                            units: 'dashboard.Total_publications',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                    {
                        ind: 13,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        title: 'dashboard.Patent_leaders',
                        params: {
                            "docType": 'Patents',
                            type: 'TERM_GROWTH',
                            field: 'countries',
                            numYears: 6,
                            limit: 10,
                            total: 50,
                            colors: [
                                '#004c6d',
                                '#346888',
                                '#5886a5',
                                '#7aa6c2',
                                '#9dc6e0',
                                '#c1e7ff',
                            ],
                            color: '#004c6d',
                            units: 'dashboard.Total_patents',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                    {
                        ind: 14,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'research_institutions',
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        title: 'dashboard.Top_research_institutions',
                        params: {
                            "docType": 'Science',
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(224,72%,65%)', // blue
                            color: 'hsl(224,58%,50%)',
                            units: 'dashboard.Total_publications',
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 15,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'patent_applicants',
                        title: 'dashboard.Top_patent_applicants',
                        params: {
                            "docType": 'Patents',
                            field: 'companies',
                            limit: 10,
                            bgColor: 'hsl(35, 55%, 57%)', // orange
                            color: 'hsl(35, 55%, 45%)',
                            units: 'dashboard.Total_patents',
                            facet: 'COMPANIES'
                        }
                    },
                    {
                        ind: 16,
                        component: 'SWKeywords',
                        group: 'A',
                        name: 'Category',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'facets.companyCategory',
                        params: {
                            "type": "TERM_COUNT",
                            "field": "systemKeywords",
                            "limit": 30,
                            "docType": 'Companies',
                            "style": 'height: 425px; width: 100%;',
                            rotation: {
                                rule: 'stringLength',
                                value: 10,
                                rng: 0.70
                            }
                        }
                    },
                    {
                        ind: 17,
                        component: 'SWRatedList',
                        group: 'A',
                        name: 'countries',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'label.hqCountry',
                        params: {
                            docType: 'Companies',
                            field: 'countries',
                            limit: 12,
                            total: 50,
                            bgColor: 'hsl(224,78%,62%)', // blue
                            color: 'hsl(224,78%,57%)',
                            units: 'dashboard.Total_companies',
                            isCountry: true,
                            facet: 'COUNTRIES'
                        }
                    },
                    {
                        ind: 18,
                        component: 'SWCompanyCountries',
                        group: 'A',
                        name: 'Company_foundation',
                        availableForRole: 'COMPANIES_SEARCH',
                        title: 'dashboard.Company_foundation',
                        params: {
                            "count": 5,
                            "type": "GROWTH",
                            "field": "countries",
                            "docType": 'Companies',
                            "widgetClass": 'full',
                        }
                    },
                ],
            }
        }
    },
    actions: {},
    mutations: {
        toggleSwMenu(state, current) {
            state.widgets.current = current;
            state.widgets.activeMenuIndex = current;
        },
        toggleShowSwMenu(state, status) {
            state.widgets.isActive = status;
        },
        toggleShowSwMenuBtn(state, status) {
            state.widgets.isVisible = status;
        },
        updateWidgetList(state, allowedActions) {
            if (allowedActions) {
                state.widgets.list.All = state.widgets.list.All.filter(wid => {
                    return !wid.availableForRole || allowedActions.contains(wid.availableForRole)
                });
            }
        },
        activeMenuIndex(state, current) {
            state.widgets.activeMenuIndex = current;
        },
        toggleWidgetParam(state, param) {
            let obj = Object.entries(param)[0];
            state.widgets[obj[0]] = obj[1];
        },
    },
    getters: {
        swList(state) {
            return state.widgets;
        },
        activeMenuIndex(state) {
            return state.widgets.activeMenuIndex;
        }
    }
}