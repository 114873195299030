<template>
    <div class="fst-UserGenerateAuthToken">
        <el-form ref="form" :model="model" :show-message="false" label-width="240px" label-position="left">
            <el-form-item v-html="$t('admin.users.generateAuthToken.msg.generate', {name: user.fullName})"/>
        </el-form>
    </div>
</template>

<script>
    import CopyToClipboard from '@/commons/components/CopyToClipboard';

    export default {
        name: "UserGenerateAuthToken",
        props: {
            user: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                model: {
                    username: this.user.username
                }
            }
        },
        methods: {
            save() {
                return new Promise((resolve, reject) => {
                    this.$refs.form.validate((valid, form) => {
                        if (valid) {
                            let model = Object.assign({}, this.model);
                            this.$services.security.generateAuthToken (model).prepare({target: this.$el}).then(resp => {
                                resolve();

                                this.$modal.dialog(CopyToClipboard, {
                                    title: this.$t('admin.users.generateAuthToken.token'),
                                    size: 'sm',
                                    params: {
                                        text: resp.data.token,
                                        beforeText: this.$t('admin.users.generateAuthToken.msg.headerName', {name: resp.data.headerName}),
                                        successMessage: this.$t('admin.users.generateAuthToken.msg.successMessage')
                                    }
                                });
                            });
                        }
                    });
                });
            },
        }
    }
</script>

<style lang="scss">
    .fst-UserGenerateAuthToken {}
</style>
