<template>
    <div class="fst-ValidateQuery">
        <div class="gotErrorMarks">
            <el-popover popper-class="gotErrorsMsg" v-if="show"
                        placement="top-end"
                        :width="popoverWidth"
                        trigger="hover"
                        :open-delay="404"
                        :close-delay="808"
                        content="this is content, this is content, this is content">
                <i slot="reference" class="fas fa-times-square pointer"></i>
                <div class="validationResult">
                    <div class="error">
                        <!--div class="query">{{ err.query }}</div-->
                        <div class="message" v-for="(item, key) in validationResult" :key="key" v-if="item.numErrs > 0">
                            <div class="query" v-html="`Line ${item.line}:  ${item.query}`"
                                 v-if="item.isMultiline"></div>
                            <div class="query" v-html="`${item.query}`" v-else></div>
                            <ul>
                                <li class="text" v-for="(msg,kk) in item.messages" :key="kk">{{ msg }}</li>
                            </ul>
                            <!--div class="position">line: {{ msg.line }}, position: {{ msg.column }}</div-->
                        </div>
                    </div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "ValidateQuery",
    props: {
        value: {
            type: String
        },
        popoverWidth: {
            type: String,
            default: '380'
        }
    },
    data() {
        return {
            show: false,
            validationResult: [],
            superUsers: ['watson', 'chas', 'polyakova', 'admin', 'kzn'],
            // superUsers: ['watson'],
        }
    },
    watch: {
        value(newVals, oldVals) {
            if (this.superUsers.includes(this.userInfo.username)) {
                this.$store.dispatch('notions').then(() => {
                    this.validateQuery();
                });
            }
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            notionsDict: state => state.notions,
        })
    },
    mounted() {
    },
    methods: {
        validateQuery() {
            if (this.value) {
                let _query = [_.trim(this.value)];
                this.$services.documents.validateQueries(_query).then(resp => {
                    this.show = resp.data.length > 0;
                    let _result = {};
                    if (this.show) {
                        let _resp = resp.data[0],
                            fullRows = _resp && _resp.query ? _resp.query.split("\n") : [],
                            rezult = this.validationResult;
                        fullRows.forEach((it, i) => {
                            rezult[i] = {
                                queryArr: it.split(''),
                                query: '',
                                numErrs: 0,
                                messages: []
                            }
                        });

                        // this.validationResult = _resp.errors.map(it => {
                        //     let _row = _resp.query.split("\n"),
                        //         _query = _row[it.line - 1].split('');
                        //     _query.splice(it.column, 0, '<span class="sntxError"> </span>');
                        //     it.query = this.toNotionNames(_query.join(''));
                        //     it.isMultiline = _row.length > 1;
                        //     return it;
                        // });
                        (_resp.errors || []).forEach((it, i) => {
                            let _rez = rezult[it.line - 1];
                            _rez.queryArr.splice((it.column + _rez.numErrs), 0, '<span class="sntxError"> </span>');
                            _rez.messages.push(it.message)
                            _rez.numErrs++;
                        });
                        rezult = rezult.map((rez, i) => {
                            rez.query = this.toNotionNames(rez.queryArr.join(''));
                            rez.isMultiline = rezult.length > 1;
                            rez.line = i + 1;
                            return rez;
                        })

                        // console.log('Fullllllll', rezult);
                        this.validationResult = _.cloneDeep(rezult);
                        _result.status = false;
                        _result.errors = this.validationResult;
                    } else {
                        _result.status = true;
                    }
                    this.$emit('validationComplete', _result)


                })
            }
        },
        toNotionNames(text) {
            let regex = /<\d*>/g,
                _query = '',
                tags = text.match(regex),
                words = text.split(regex),
                notions = tags ? tags.map(it => {
                    // return '<span class="notion">' + it.replace(/[<>]/g, "") + '</span>';
                    return '<span class="notion">' + this.notionName(it.replace(/[<>]/g, "")) + '</span>';
                }) : '';
            (words || []).forEach((it, i) => {
                _query += it + (notions[i] || '');
            });
            return _query;

        },
        notionName(id) {
            return (this.notionsDict.map[id] || {}).name || 'Undefined'
        },

    }
}
</script>

<style lang="scss">

.fst-UserTags {

}

.fst-ValidateQuery {
    .gotErrorMarks {
        i {
            color: $iq-color-danger;
            display: block;
            padding: 1px;
            margin-bottom: 5px;
            cursor: pointer;
            position: absolute;
            background: #fff;
            font-size: 16px;
            top: 16px;
            left: -8px;
            z-index: 200;
        }
    }
}

.gotErrorsMsg.el-popover--plain {
    padding: 0;

    .validationResult {
        //width: calc(100% - 155px);
        box-sizing: border-box;
        padding: 7px 15px;
        background: #fff;
        border: 1px solid $iq-color-danger;
        border-left-width: 9px;
        //border-top-width: 0;
        color: $iq-color-text-primary;
        font-size: 13px;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.21);
        max-height: 300px;
        overflow-y: auto;

        p {
            margin: 5px 0;
        }

        .error {
            .sntxError {
                font-weight: 600;
                background: $iq-color-danger;
                display: inline-block;
                padding: 0 2px;
                width: 4px;
                height: 13px;
                margin: 0 2px;
                position: relative;
                top: 2px;
            }

            .query {
                margin: 3px 0;
                font-weight: 500;
                word-break: normal;
                text-align: left;
            }

            .notion {
                padding: 1px 5px;
                border-radius: 3px;
                margin: 0 3px;
                display: inline-block;
                position: relative;
                background: #fff;
                border: 1px solid $iq-color-danger;
            }

            .message {
                margin: 12px 5px;
                border-bottom: 1px solid $iq-border-color-base;

                &:last-of-type {
                    border: none;
                }

                ul {
                    padding: 0 0 0 20px;
                    margin: 0 0 20px 0;

                    li {
                        margin: 3px 0;
                        list-style: square url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
                    }
                }
            }

            .position {
                margin: 1px 0;
            }
        }
    }
}


</style>