import {services} from '@/commons/services';

let lastErrorCount = 0, lastTimeoutId;

function longPollAlert(context) {
    clearTimeout(lastTimeoutId);
    const alert = context.state.alert;
    let promise = services.systemAlert.waitAlert({
        timeout: 30000,
        creationDate: alert && alert.creationDate ? {minBoundType:'OPEN', min: alert.creationDate} : null
    }).then(resp => {
        lastErrorCount = 0;
        const alert = resp.data ? Object.freeze(resp.data) : null;
        context.commit('alert', alert);
        return alert;
    }, error => {
        lastErrorCount++;
        console.error('waitAlert error', error);
    });
    promise.finally(() => {
        if(lastErrorCount > 1) {
            lastTimeoutId = setTimeout(() => longPollAlert(context), Math.min(10, lastErrorCount) * 6000)
        } else {
            longPollAlert(context);
        }
    });
    return context.state.alertPromise = promise;
}
function pollAlert(context) {
    clearTimeout(lastTimeoutId);
    const alert = context.state.alert;
    let promise = services.systemAlert.getAlert().then(resp => {
        lastErrorCount = 0;
        const alert = resp.data ? Object.freeze(resp.data) : null;
        context.commit('alert', alert);
        return alert;
    }, error => {
        lastErrorCount++;
        console.error('getAlert error', error);
    });
    promise.finally(() => {
        lastTimeoutId = setTimeout(() => pollAlert(context), lastErrorCount > 1 ? 60000 : 30000)
    });
    return context.state.alertPromise = promise;
}

export default {
    namespaced: true,
    state: {
        alert: null,
        alertPromise: null
    },
    mutations: {
        alert(state, alert) {
            state.alert = alert;
        }
    },
    actions: {
        waitAlert(context) {
            if(context.state.alertPromise) {
                return context.state.alertPromise;
            }
            return pollAlert(context);
        }
    }
};
