<template>
    <ul class="dropdown-menu">
        <IqpContextMenuItem v-for="(item, index) in items" :key="index"
                  :text="item.text || item.name || item.label || item.title"
                  :iconClass="item.iconClass || item.icon"
                  :children="item.children || item.submenu || item.submenus"
                  @clicked="handleClick(item)"
                  @clickItem="(it) => handleClick(it)"
        />
    </ul>
</template>

<script>
    import IqpContextMenuItem from './MenuItem'
    export default {
        name: 'IqpContextSubmenu',
        components: {IqpContextMenuItem},
        props: {
            items: {
                type: Array,
                default() {return [];}
            }
        },
        methods: {
            handleClick(item) {
                this.$emit('clickItem', item);
            }
        }
    }
</script>

