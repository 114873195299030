<template>
    <div class="widget-block"
         v-loading="loading">
        <div class="header mb-3">
            <div class="title">
                {{ title }}
                <div class="viewAll"
                     v-if="this.$store.getters.swList.mode === 'all'"
                     @click="(e) => handleWordClick(e)">{{ $t('dashboard.viewAll') }}
                </div>

            </div>
            <div class="flex-center">
                <el-select v-model="significant" @change="refresh()" class="significantSelect">
                    <el-option :label="$t('facets.frequent')" key="1"
                               :value="false"></el-option>
                    <el-option :label="$t('facets.significant')" key="2"
                               :value="true"></el-option>
                </el-select>
                <export-to-image :filename="title" selector=".cloud"></export-to-image>
            </div>
        </div>
        <div class="cloud" v-show="words.length">
            <vue-word-cloud
                    :style="params.style || 'height: 350px; width: 100%;'"
                    :words="words"
                    :spacing="spacing"
                    :font-size-ratio="ratio"
                    @update:progress="handleCloudUpdate"
                    color="#61B768"
                    font-family="Roboto">
                <template slot-scope="{text, weight, word}">
                    <el-tooltip effect="light" placement="top"
                                :content="wordParam[`${text}-${weight}`].tooltip"
                                :open-delay="600">
                        <div class="keyword" @click="(e) => handleWordClick(e,word)">
                            {{ text }}
                        </div>
                    </el-tooltip>
                </template>

            </vue-word-cloud>
        </div>
        <div class="noResult" v-show="words.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import htmlToImage from "html-to-image";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWKeywords",
        components: {ExportToImage},
        props: {
            filter: Object,
            years: Object,
            title: String,
            params: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                loading: true,
                words: [],
                wordParam: {},
                spacing: 1,
                significant: false,
                ratio: 4,
                reQ: {
                    chart: {}
                },
                colors: [
                    // '#EF1D26',
                    'hsl(357,80%,65%)',
                    'hsl(155,95%,23%)',
                    // '#008A4E',
                    // '#03A762',
                    // 'hsl(0, 55%, 55%)', // Red2
                    // 'hsl(125, 67%, 55%)', // Green2
                    'hsl(224, 65%, 65%)', // BlueBright
                    'hsl(36, 63%, 52%)', // Orange2
                    '#5d6d63',  // Feldgrau
                ]
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },

        },
        mounted() {
            this.significant = !!this.params.significant;
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let rotation = self.params.rotation ? self.params.rotation : {},
                    ii = 0;

                if (self.params.spacing) {
                    self.spacing = self.params.spacing;
                }
                let widgetFilter = _.cloneDeep(self.filter);
                if (self.params.docType) {
                    widgetFilter.types = [self.params.docType]
                }

                let yearType = self.params.docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
                if (self.$store.getters.swList.mode === 'all') {
                    widgetFilter.facets.requests = [{
                        type: yearType,
                        values: self.years.years
                    }]
                }

                self.significant = self.filter.query ? self.significant : false
                let query = {
                    "filter": widgetFilter,
                    "widgets": {
                        "keywords": {
                            type: self.params.type,
                            field: self.params.field,
                            significant: self.significant,
                            limit: self.params.limit,
                        }
                    }
                };
                if (self.params.type === "TERM_GROWTH") {
                    query.widgets.keywords.years = {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "CLOSED",
                        "min": self.years.min,
                        "max": self.years.max
                    }

                }
                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }

                self.loading = true;
                self.reQ.chart = self.$services.analytics.widgets(query);

                self.reQ.chart.then(resp => {
                    // console.log('REZ:', resp.data);
                    let _data = resp.data.widgets.keywords.terms,
                        _vals = _.map(_data, it => parseInt(it.docCount));

                    // console.log(`-- aKw ${self.field} Data: `, _data);

                    self.ratio = (_.max(_vals) / _.min(_vals) > 14) ? 3 : 4;

                    // -------------- Keywords ---------------
                    self.words = _.map(_data, it => {
                        let weight = parseInt(it.docCount),
                            out = {
                                // text: value,
                                text: it.value,
                                weight: weight,
                                rotationUnit: 'deg',
                                // rotation: Math.ceil(90 - Math.random() * 150),
                                rotation: rotation.rule === 'stringLength' ?
                                    (it.value.length < rotation.value && Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0')
                                    : Math.random() > (rotation.rng ? (1 - rotation.rng) : 0.71) ? '-90' : '0',
                                color: self.params.type === "TERM_COUNT" ? self.colors[ii]
                                    : (typeof it.growthOneYear === 'undefined') || it.growthOneYear >= 0 ? self.colors[1]
                                        : self.colors[0]

                            };
                        ii++;
                        if (ii > self.colors.length) {
                            ii = 0;
                        }
                        self.wordParam[`${it.value}-${it.docCount}`] = {
                            tooltip: _.capitalize(it.value) + ': '
                                + self.$utils.toFinHuman(weight, 1).number
                                + ' ' + (self.params.unit || 'documents')
                                + (it.growthOneYear ? " (" + (it.growthOneYear > 0 ? "+" : '') + self.$utils.roundX(it.growthOneYear * 100, 1) + '%' + (it.growthOneYear > 0 ? " ↑)" : ' ↓)') : '')
                        }
                        return out;
                    });
                    // console.log('Words:::',self.words);
                    if (!self.words.length) {
                        this.loading = false;
                    }

                })

            },
            handleWordClick(e, keyword) {
                // console.log('keyword:', keyword);
                let self = this,
                    filter = _.cloneDeep(self.filter);
                let docType = self.params.docType || filter.types[0],
                    yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR',
                    facets = {};

                if (e.button === 0) {
                    facets.requests = filter.facets.requests || [];

                    if (keyword) {
                        facets.requests.push({
                            type: self.params.facet || 'SYSTEM_KEYWORDS',
                            values: [keyword.text]
                        })
                        facets.requests.push({
                                type: yearType,
                                values: self.years.years
                            },
                        )
                    }
                    // console.log('Facets', facets, self.request.filter);
                    let params = {
                        query: filter.nativeQuery ? filter.nativeQuery.value : filter.query,
                        isNativeQuery: !!filter.nativeQuery,
                        docType: docType,
                        facets: facets,
                        spam: false,
                        duplicate: 'false',
                    }
                    self.$utils.openSearch(params);
                }
            },
            handleCloudUpdate(str) {
                if (!str) {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .cloud {
        padding: 0 15px;
    }
</style>