<template>
    <div class="fst-SearchListCard" @contextmenu="handleContextMenu">
        <!--  Устаревшая неиспользуемая карточка, cм. SearchListCard.vue и CompanyCard.vue  -->
        <div class="left-block">
            <div class="logo-lang">
                <div class="title" v-html="translation.title" v-if="translation.show"></div>
                <div class="title" v-html="doc.title" v-else></div>
            </div>
            <div class="attrs">
                <div class="attrs-item">{{ doc.date }}</div>
                <div class="attrs-item" v-if="doc.url">
                    <el-link type="primary" :href="doc.url" :underline="false" target="_blank">
                        {{ doc.id }}
                        <i class="fas fa-share-square"></i>
                    </el-link>
                </div>
                <div class="attrs-item" v-else>{{ doc.id }}</div>

            </div>
            <div class="tags" v-if="doc.systemKeywords">
                <el-tag v-for="(item,key) in doc.systemKeywords" :key="key" type="green" v-html="item"></el-tag>
            </div>
            <UserTags
                    v-model="doc.userTags"
                    :fullUserTagList="fullUserTagList"
                    :doc="doc"
                    @updateUserTagList="getUserTagList"
                    @userTagAction="userTagAction"
                    class="mb-2">
            </UserTags>

            <div class="docLang">
                <el-link :underline="false"
                         v-for="lang in languages" :key="lang.name"
                         @click="handleTranslateItem(lang.name)"
                         :class="{active: curLang === lang.name}">

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                         fill="none" style="position: relative;top: 5px; margin: -5px -4px 0 0;">
                        <rect width="20" height="20" fill="white"></rect>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7.99358 2.99986C8.54587 2.99986 8.99358 3.44758 8.99358 3.99986V4.70257H10.7807C10.7944 4.70228 10.8081 4.70228 10.8218 4.70257H12.3269C12.8792 4.70257 13.3269 5.15028 13.3269 5.70257C13.3269 6.25485 12.8792 6.70257 12.3269 6.70257H11.5715C11.3942 7.2829 11.1626 7.87554 10.8992 8.42696C10.5212 9.21867 10.0426 9.99981 9.50681 10.5411C9.49852 10.5495 9.49011 10.5579 9.48159 10.5664C9.98959 11.0649 10.5269 11.548 11.0479 11.9289L12.5915 7.71029C12.736 7.3153 13.1123 7.05297 13.5329 7.05392C13.9535 7.05487 14.3285 7.31889 14.4713 7.71452L16.6202 13.6705L17.4241 15.6184C17.6348 16.1289 17.3918 16.7135 16.8812 16.9242C16.3707 17.1349 15.7861 16.8919 15.5754 16.3813L15.0181 15.0311H11.9972L11.3972 16.401C11.1757 16.9069 10.5859 17.1374 10.0801 16.9159C9.57416 16.6943 9.34367 16.1046 9.56524 15.5987L10.3273 13.8588C9.44283 13.2896 8.59741 12.5108 7.92665 11.8415C7.75135 11.971 7.57421 12.0992 7.39996 12.2233C6.59587 12.7958 5.76548 13.3424 5.28655 13.621C4.80917 13.8987 4.19704 13.7368 3.91933 13.2595C3.64162 12.7821 3.80348 12.17 4.28086 11.8922C4.68465 11.6573 5.45919 11.1499 6.23999 10.594C6.33483 10.5265 6.42856 10.4591 6.52064 10.3922C5.97063 9.79109 5.43925 9.14158 5.1081 8.53017C4.84507 8.04454 5.02553 7.43764 5.51116 7.17461C5.99679 6.91158 6.60369 7.09203 6.86672 7.57766C7.1194 8.04418 7.56729 8.58074 8.08367 9.13589C8.08422 9.13535 8.08476 9.1348 8.0853 9.13426C8.39776 8.81855 8.76175 8.26185 9.09446 7.56512C9.22851 7.28441 9.35171 6.99312 9.46021 6.70257H3.49976C2.94747 6.70257 2.49976 6.25485 2.49976 5.70257C2.49976 5.15028 2.94747 4.70257 3.49976 4.70257H6.99358V3.99986C6.99358 3.44758 7.4413 2.99986 7.99358 2.99986ZM12.7743 13.0311H14.2633L13.524 10.9821L12.7743 13.0311Z"></path>
                    </svg>
                    {{ lang.label }}
                </el-link>
            </div>

            <div class="description" v-html="translation.description" v-if="translation.show"></div>
            <div class="description" v-html="description" v-else-if="description"></div>
        </div>
        <div class="right-block">
            <div class="attr" v-if="doc.countries">
                <div class="attr-title">Patent office</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.countries" :key="i" v-html="item"></div>
                </div>
            </div>
            <div class="attr" v-if="doc.companies">
                <div class="attr-title">Applicants</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.companies" :key="i" v-html="item"></div>
                </div>
            </div>
            <template v-if="doc.facetValues">
                <div class="attr" v-if="doc.facetValues.trend">
                    <div class="attr-title">Trends</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.trend.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
                <div class="attr" v-if="doc.facetValues.industry">
                    <div class="attr-title">Industries</div>
                    <div class="attr-value capitalize">
                        <div v-for="(item, i) in doc.facetValues.industry.items" :key="i" v-html="item.name"></div>
                    </div>
                </div>
            </template>
            <div class="attr cpcs" v-if="doc.cpcs && doc.cpcs.length > 0">
                <div class="attr-title">CPC</div>
                <div class="attr-value">
                    <div v-for="(item, i) in doc.cpcs" :key="i">
                        <el-popover
                                placement="bottom"
                                popper-class="cpc-block"
                                :visible-arrow="false"
                                :title="item.code"
                                width="500"
                                trigger="click">
                            <div class="path">
                                <div v-for="(des, d) in item.desc" :key="d" v-html="des"></div>
                            </div>
                            <el-button type="default" slot="reference" :class="{highlighted: item.highlighted }">
                                {{ item.code }}
                            </el-button>
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import uuid from 'uuid';
    import FavoritesBtn from '@/commons/components/FavoritesBtn.vue';
    import i18n from '@/commons/i18n';
    import UserTags from '@/commons/components/UserTags.vue';
    import _ from "lodash";


    export default {
        name: "PatentCard",
        components: {UserTags},
        data() {
            return {
                doc: JSON.parse(window.sessionStorage.getItem('patentCard') || '{}'),
                logoUrl: '',
                description: '',
                curLang: '',
                translation: {
                    show: false
                },
                languages: [
                    {name: 'ru', label: 'Translate to RU'},
                    // {name: 'en', label: 'EN'},
                    // {name: 'de', label: 'DE'},
                    // {name: 'fr', label: 'FR'}
                ],
                fullUserTagList: [
                    {id: 1, color: 1, name: 'Favorites'},
                ]

            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        mounted() {
            let self = this;
            services.system.info().then(function (resp) {
                if (resp.data.userSettings) {
                    self.userSettings = resp.data.userSettings;
                    if (self.userSettings.locale) {
                        i18n.locale = self.userSettings.locale;
                        self.$store.commit('locale', self.userSettings.locale);
                    }
                }
                this.getUserTagList();
                this.refresh();
            });
            window.addEventListener('storage', () => {
                if (window.localStorage.getItem('changeUserTags')) {
                    this.getUserTagList();
                }
            });
            this.$services.userEvents.publish({
                type: 'openCard',
                data: {docIds: [{type: this.doc.type, id: this.doc.id}]}
            });
        },
        methods: {
            refresh() {

                // --- Устаревшая неиспользуемая карточка, cм. SearchListCard.vue и CompanyCard.vue ---
                
                let _doc = _.cloneDeep(this.doc),
                    id = (_doc && _doc.id) ? _doc.id : this.$route.query.id || "111",
                    type = "Patents",
                    filter = {
                        ids: [id],
                        fullness: 'FULL',
                        count: 1,
                        types: [type]
                    },
                    _highlightedIdx = [],
                    _highlightedCpcs = [];
                this.$services.documents.search(filter).prepare().then(inc => {
                    let docFromDb = inc.data.list[0];
                    if (!Object.keys(_doc).length) {
                        _doc = docFromDb;
                        // console.log('Inc:', _doc);
                    }
                    this.doc = Object.assign(_doc, {
                        url: _doc.url ? _doc.url : '',
                        userTags: docFromDb.tags,
                        date: moment(docFromDb.date).format('LL'),
                        countriesFormat: _doc.countries.join('<i class="divider"></i>')
                    });
                    if (_doc.highlights && _doc.highlights.full) {
                        _.each(_doc.highlights.full, (it, i) => {
                            if (i.includes('cpcs')) {
                                _highlightedCpcs.push(it.replace(/<em>|<\/em>/g, ''));
                                _highlightedIdx.push(parseInt(i.replace(/cpcs\[|\]name/g, '')));
                            }
                        });
                    }
                    this.doc.cpcs = this.doc.cpcs ? this.doc.cpcs.map((it, i) => {
                        let _it = it;
                        _it.desc = it.name ? it.name.toLowerCase().split('->') : [];
                        _it.highlighted = _highlightedCpcs.includes(it.codeExt) || _highlightedIdx.includes(i);
                        return _it;
                    }) : [];
                    for (let prop in this.doc) {
                        let value = this.doc[prop];
                        if (_.isArray(value) && !value.length) {
                            delete this.doc[prop];
                        }
                    }
                    this.description = (_doc.description && _doc.description.full) ? _doc.description.full
                        : typeof (_doc.description) === 'string' ? _doc.description : '';

                    console.log('this.doc', this.doc, this.$route.query);
                })
            },
            getUserTagList() {
                this.$services.tags.list({
                    count: 500,
                    offset: 0,
                    userIds: [this.userInfo.id]
                }).prepare().then(resp => {
                    if (resp && resp.data) {
                        this.fullUserTagList = resp.data.list;
                    }
                });
            },
            saveUserTag(item) {
                if (item) {
                    let query = {
                        id: item.id ? item.id : null,
                        name: item.name,
                        color: item.color
                    };
                    this.$services.tags.save(query).prepare().then(resp => {
                        this.getUserTagList();
                        parent.localStorage.setItem('changeUserTags', JSON.stringify(item));
                        this.$emit('updateUserTagList');
                    });
                }
            },
            deleteUserTag(tagId) {
                if (tagId) {
                    this.$services.tags.delete([tagId]).prepare().then(resp => {
                        this.fullUserTagList = this.fullUserTagList.filter(it => it.id !== tagId);
                        this.getUserTagList();
                        parent.localStorage.setItem('changeUserTags', 'deleted tag ' + tagId);
                        this.$emit('updateUserTagList');
                    });
                }
            },
            userTagAction(data) {
                parent.localStorage.setItem('userTagAction', JSON.stringify(data));
            },
            handleToggleFavorite(data) {
                parent.localStorage.setItem('changeFav', JSON.stringify(data));
            },
            handleTranslateItem(lang) {
                let item = this,
                    toTranslate = '',
                    delimiter = ' &&& ';
                if (this.curLang === lang) {
                    this.curLang = null;
                    item.translation.show = false;
                } else if (item.translation && item.translation.title) {
                    this.curLang = lang;
                    item.translation.show = true;
                } else {
                    this.curLang = lang;
                    item.lang = lang;
                    item.translation = {
                        title: '<div class="text-info">Translation in progress...</div>',
                        description: '<div class="text-center text-info my-3"><i class="fas fa-2x fa-spinner fa-pulse"></i></div>',
                        show: true
                    };

                    toTranslate = item.doc.title.replace(/&&&/g, '& & &') + delimiter + (item.description ? item.description.replace(/&&&/g, '& & &') : '');

                    this.$utils.translateText({
                        text: toTranslate.substring(0, 4999),
                        tl: lang
                    }).then(function (response) {
                        if (response && response.data && response.data.message) {
                            let _resp = (response.data.message.content || '').split(delimiter);
                            item.translation = _.merge({}, item.translation, {
                                title: _resp[0] || item.title,
                                description: _resp[1],
                                show: true
                            });
                        }
                    });
                }
            },
            uuid() {
                return uuid();
            },
            sortFunc: (a, b) => {
                let $a = a.indexOf('<em>') !== -1 ? 0 : 1;
                let $b = b.indexOf('<em>') !== -1 ? 0 : 1;
                return $a === $b ? 0 : $a > $b ? 1 : -1;
            },
            toFin: (num) => { // форматирование сумм в виде XXX XXX XXX
                return num ? parseFloat(num).toFixed().toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
            },
            handleContextMenu(event) {
                this.$utils.wikiTranslateContext(this, event);
            },


        }
    }
</script>

<style lang="scss">
    //--- moved to commons.scss
    // --- Устаревшая неиспользуемая карточка, cм. SearchListCard.vue и CompanyCard.vue ---

    .cpc-block {
        .el-popover__title {
            font-weight: 500;
            //background: $iq-color-grey-light;
            background: $iq-color-primary;
            color: #fff;
            padding: 10px 12px;
            margin: -12px -12px 10px;
            line-height: 1;
        }

        .path {
            word-break: normal;

            > div:first-letter {
                text-transform: capitalize;
            }

            em {
                font-style: normal;
                font-weight: 900;
                color: $iq-color-text-primary;
            }

            > div {
                &:after {
                    display: block;
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 900;
                    content: "\f063";
                    margin: 5px 3px;
                }

                &:last-child {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
</style>