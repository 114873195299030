<template>
    <el-dialog
        :title="$t('admin.groups.editGroup.title')"
        :visible="true"
        custom-class="dialog-custom text-left"
        width="730px"
        @close="close"
    >
        <div class="edit-group-form">
            <el-form label-width="130px" :model="form">
                <el-form-item :label="$t('admin.groups.editGroup.name')">
                    <el-input v-model="form.name" autocomplete="group-name"/>
                </el-form-item>
                <el-form-item :label="$t('admin.groups.editGroup.roles')">
                    <el-select v-model="form.roles" multiple autocomplete="group-roles" class="edit-group-form__roles">
                        <el-option
                                v-for="item in $store.state.roles"
                                :key="item.role"
                                :label="item.name"
                                :value="item.role"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('admin.groups.editGroup.users')">
                    <el-transfer
                            :titles="['Пользователи системы', 'Пользователи группы']"
                            filterable
                            :filter-method="filteredUsers"
                            filter-placeholder="Найти по имени/логину"
                            v-model="form.users"
                            :data="userList"
                    />
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="default" type="text" @click="close">{{ $t('cancel') }}</el-button>
            <el-button size="default" type="primary" @click="save">{{ $t('save') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {Users} from "../../models/Users";

    export default {
        name: 'EditGroup',
        props: [
            'id',
            'close',
            'loadGroups',
        ],
        data () {
            return {
                UsersModel: new Users(),
                form: {
                    id: this.id,
                    name: '',
                    roles: [],
                    users: [],
                },
            };
        },
        created () {
            this.selectGroup = () => {
                return this.$services.security.groups({
                    count: 1,
                    ids: [this.id],
                }).prepare();
            };
            this.selectUsers = (offset = 0, count = 1000000) => {
                return this.$services.security.users({
                    offset: offset,
                    count: count,
                    order: [{field: 'username', type: 'ASC'}],
                    states: ['ENABLED'],
                }).prepare();
            };
            this.selectGroupUsers = (offset = 0, count = 1000000) => {
                return this.$services.security.users({
                    offset: offset,
                    count: count,
                    states: ['ENABLED'],
                    groupIds: [this.id],
                }).prepare();
            };
            if (this.id) {
                Promise.all([this.selectGroup(), this.selectUsers(), this.selectGroupUsers()]).then(values => {
                    this.form = {...this.form, ...values[0].data[0]};
                    this.UsersModel.fill(values[1].data.list);
                    this.form.users = values[2].data.list.map(user => user.id);
                }, error => {
                    console.warn(error);
                });
            } else {
                Promise.all([this.selectUsers()]).then(values => {
                    this.UsersModel.fill(values[0].data.list);
                }, error => {
                    console.warn(error);
                });
            }
        },
        computed: {
            userList () {
                return this.UsersModel.fullList.map(user => {
                    return {
                        label: user.fullName,
                        key: user.id,
                        login: user.username,
                    };
                });
            },
        },
        methods: {
            filteredUsers (query, item) {
                return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1 || item.login.toLowerCase().indexOf(query.toLowerCase()) > -1;
            },
            save () {
                let {users, ... group} = this.form;
                if (!this.id) {
                    delete group.id;
                }
                let data = {group};
                if (users.length) {
                    data.userBindings = {set: users};
                }
                this.$services.security.saveGroup(data).prepare().then(() => {
                    this.loadGroups();
                    this.$message({
                        message: this.$t('admin.groups.editGroup.success'),
                        type: 'success'
                    });
                    this.close();
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .edit-group-form {
        padding: 20px;

        &__roles {
            width: 100%;
        }
    }
    /deep/
    .el-transfer {
        display: flex;
        align-items: center;
        .el-transfer-panel {
            flex: 1;
            width: auto;

            .el-checkbox__label {
                font-size: 14px !important;
            }
            &__item {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(100% - 15px);
            }
        }
        .el-transfer__buttons {
            display: flex;
            align-items: flex-start;
            padding: 0 20px;
            flex-direction: column;
        }
        .el-button + .el-button {
            margin-left: 0
        }

    }
    /deep/
    .el-transfer__buttons {
        padding: 0 10px;
    }
</style>
