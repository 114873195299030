<template>
    <div class="fst-SearchListItem">
        <div v-for="(item, key) in list" :key="key" class="list-item-container">
            <div :ref="`list-item-${item.id}`"
                 :class="['list-item', {isRemove: item.isRemove}]"
                 @click="handleFavCheck($event,item)">
                <div class="logo" v-if="item.urlLogo && type !== 'Favorites'">
                    <el-link :underline="false" @click="handleClickTitle(item)">
                        <img :src="item.urlLogo" alt="Company Logo">
                    </el-link>
                </div>
                <div class="sl-content">
                    <div class="title-block" v-if="item.title">
                        <div class="title">
                            <el-link :underline="false" @click="handleClickTitle(item)">
                                <span v-html="item.title"></span>
                            </el-link>
                        </div>
                        <el-link :underline="false" class="moreLikeLink"
                                 v-if="value.from !== 'docItem'"
                                 @click="handleMoreLikeClick(item)">
                            {{$t('label.morLikeThis')}}

                        </el-link>
                    </div>
                    <div class="description" v-if="item.description.fragments || item.description.full"
                         @click="toggleFullText(item)" :class="{full: item.description.showFull}">
                        <template v-if="item.description.fragments">
                            <div v-if="!item.description.showFull" v-html="item.description.fragments"></div>
                            <div v-else v-html="item.description.full"></div>
                            <el-link type="primary" :underline="false" >
                                <span>{{ item.description.showFull ? 'Hide full text' : 'Show full text' }}</span>
                            </el-link>
                        </template>
                        <template v-else>
                            <span v-html="item.description.full"></span>
                        </template>
                    </div>
                    <!-- News -->
                    <template v-if="item.type === 'News'">
                        <CollapseTags v-model="item.trends" type="green"></CollapseTags>
                        <div class="attrs-wrap">
                            <div class="attrs">
                                <div class="attrs-item"><span>{{ item.date }}</span></div>
                                <div class="attrs-item" v-html="item.hostingName"></div>
                            </div>
                            <div class="favs">
                                <el-link :class="['favorites', {active: item.isInFavorite}]" icon="fas fa-star"
                                         :underline="false"
                                         @click="toggleFavorites(item)">Favorites
                                </el-link>
                            </div>
                        </div>
                    </template>
                    <!-- Science -->
                    <template v-else-if="item.type === 'Science'">
                        <CollapseTags v-model="item.authorKeywords" type="green"></CollapseTags>
                        <div class="attrs-wrap">
                            <div class="attrs d-flex">
                                <div class="attrs-item nowrap"><span>{{ item.date }}</span></div>
                                <div class="attrs-item nowrap" v-html="item.countriesFormat"></div>
                                <div class="attrs-item ellipsis" :title="item.journalName"
                                     v-html="item.journalName"></div>
                            </div>
                            <div class="favs">
                                <el-link :class="['favorites', {active: item.isInFavorite}]" icon="fas fa-star"
                                         :underline="false"
                                         @click="toggleFavorites(item)">Favorites
                                </el-link>
                            </div>
                        </div>
                    </template>
                    <!-- Patents -->
                    <template v-else-if="item.type === 'Patents'">
                        <CollapseTags v-model="item.trends" type="green"></CollapseTags>
                        <div class="attrs-wrap">
                            <div class="attrs">
                                <div class="attrs-item"><span>{{ item.date }}</span></div>
                                <div class="attrs-item" v-html="item.companiesFormat"></div>
                                <div class="attrs-item">
                                    <IqLink type="primary" :href="item.url" :underline="false" target="_blank"
                                            @click.stop.prevent="handleClickTitle(item)">{{ item.id }}
                                    </IqLink>
                                </div>
                            </div>
                            <div class="favs">
                                <el-link :class="['favorites', {active: item.isInFavorite}]" icon="fas fa-star"
                                         :underline="false"
                                         @click="toggleFavorites(item)">Favorites
                                </el-link>
                            </div>
                        </div>
                    </template>
                    <!-- Companies -->
                    <template v-else-if="item.type === 'Companies'">
                        <CollapseTags v-model="item.authorKeywords" type="green"></CollapseTags>
                        <div class="attrs-wrap">
                            <div class="attrs">
                                <div class="attrs-item" v-if="item.founded" @click="toggleIconSize()">
                                    <span>Founded: </span>
                                    <span v-html="item.founded"></span>
                                    <!--                            <span v-if="item.countries" v-html="item.countriesFormat"></span>-->
                                    <!--                            <span v-if="item.year"><span v-if="item.countries">, </span>{{item.year}}</span>-->
                                </div>
                                <div class="attrs-item" v-if="item.url">
                                    <IqLink type="primary" :href="item.url" :underline="false" target="_blank"
                                            v-html="item.url"></IqLink>
                                </div>
                            </div>
                            <div class="favs">
                                <el-link :class="['favorites', {active: item.isInFavorite}]" icon="fas fa-star"
                                         :underline="false"
                                         @click="toggleFavorites(item)">Favorites
                                </el-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ElLink from "element-ui/lib/link";
import CollapseTags from '@/commons/components/CollapseTags.vue';
import _ from "lodash";

const IqLink = {
    name: 'IqLink',
    mixins: [ElLink],
    methods: {}
};

export default {
    name: "SearchListItem",
    components: {IqLink, CollapseTags},
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            list: this.value.list
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.list = this.list.map(item => {
                let highlights = _.merge({full: {}, fragments: {}}, item.highlights);
                for (let prop in highlights.full) {
                    if (!['id', 'type'].includes(prop)) {
                        _.set(item, prop, highlights.full[prop]);
                    }
                }

                let desc = {
                    showFull: false,
                    // full: item.description.replace(/([.\r\n|.\n]){3,}/g, "\n\n" ) || '',
                    full: item && item.description ? item.description.replace(/[\r\n]\s*/g, '\n') : '',
                    fragments: (() => {
                        let truncateDesc = _.truncate(_.clone(item.description), {
                            'length': 150,
                            'omission': '',
                            'separator': ' '
                        });
                        let frags = highlights.fragments.description || [truncateDesc];
                        let str = '';
                        frags.forEach((item, index) => {
                            if (item.length) {
                                str += `${index > 0 ? '...' : ''}${item}...<br>`;
                            }
                        });
                        return str;
                    })(),
                };
                let title = item.title || _.truncate(item.description, {'length': 100});
                let sortFunc = (a, b) => {
                    let $a = a.indexOf('<em>') !== -1 ? 0 : 1;
                    let $b = b.indexOf('<em>') !== -1 ? 0 : 1;
                    return $a === $b ? 0 : $a > $b ? 1 : -1;
                };
                let countries = (item.countries || []).filter(it => it && it !== 'null').sort(sortFunc);
                let companies = (item.companies || []).sort(sortFunc),
                    countriesFormat = countries.join('<i class="divider"></i>'),
                    urlLogo = (item.type == "Companies") ? ((item.icon && item.icon.url && !item.icon.url.includes('placeholder_')) ? item.icon.url : 'noLogo2.png') : null,
                    founded = _.compact([countriesFormat, item.year]).join(', ');

                return Object.assign({}, item, {
                    title: title.replace(/\s/g, '') ? title : '',
                    description: desc,
                    date: moment(item.date).format('LL'),
                    authorKeywords: (item.authorKeywords || []).sort(sortFunc),
                    trends: (item.trends || []).sort(sortFunc),
                    urlLogo: urlLogo,
                    // urlLogo: (item.urlLogo && item.type == "Companies") ? item.urlLogo : item.type == "Companies" ? 'https://res-2.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco/v1411584838/z5utpyzqil1rznbizd65.png' : null,
                    countries: countries,
                    countriesFormat: countriesFormat,
                    checked: false,
                    founded: founded,
                    companies: companies,
                    companiesFormat: (count => {
                        return count ? (count > 1 ? `${companies[0]} (+${count - 1})` : companies[0]) : '';
                    })(companies.length),
                    isInFavorite: !!item.isInFavorite
                });
            });
            // console.log('this.list', this.list);
        },
        toggleFullText(item) {
            item.description.showFull = !item.description.showFull;
            if (item.description.showFull) {
                this.$services.userEvents.publish({
                    type: 'openFulltext',
                    data: {docIds: [{type: item.type, id: item.id}]}
                });
            }
        },
        handleFavCheck(e, item) {
            if (this.exportMenuVisible && !['BUTTON', 'A', 'SPAN', 'I'].includes(e.target.tagName)) item.checked = !item.checked;
            this.handleSelectDoc();
        },
        handleSelectDoc() {
            if(this.value.from && this.value.from === 'docItem') return;
            let _count = this.list.filter(it => it.checked).length;
            this.exportCheckedCount = _count;
            this.exportCheckedDisabled = _count < 1;
        },
        handleClickTitle(item) {
            switch (item.type) {
                case 'Companies':
                    window.sessionStorage.setItem('companyCard', JSON.stringify(item));
                    window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
                    break
                case 'Patents':
                    window.sessionStorage.setItem('patentCard', JSON.stringify(item));
                    window.open(this.$router.resolve({name: 'patentCard'}).href + '?id=' + item.id, '_blank');
                    break
                case 'News':
                    window.open(document.location.origin + this.$store.state.contextUrl + '/redirectToOriginal?type=' + item.type + '&id=' + encodeURIComponent(item.id), '_blank');
                    break;
                case 'Science':
                    window.sessionStorage.setItem('card', JSON.stringify(item));
                    window.open(this.$router.resolve({name: 'card'}).href + '?id=' + item.id + '&type=' + item.type, 'Card');
                    break;
            }
        },
        toggleFavorites(item) {
            let query = [_.pick(item, ['id', 'type'])];
            let methodName = item.isInFavorite ? 'favoritesRemove' : 'favoritesAdd';
            let target = this.$refs[`list-item-${item.id}`][0];

            this.$services.documents[methodName](query).prepare({target: target}).then(resp => {
                if (resp.data) {
                    item.isInFavorite = !item.isInFavorite;

                    if (this.type === 'Favorites' && !item.isInFavorite) {
                        item.isRemove = true;
                        setTimeout(() => {
                            let index = this.list.findIndex(it => it === item);
                            this.list.splice(index, 1);
                        }, 1000)
                    }
                    this.$emit('toggleFavorites', item);
                }
            });
        },
        handleMoreLikeClick(item) {
            this.$emit('moreLikeThis', item)
        },
    }
};
</script>

<style lang="scss">
@import "../../assets/variables.scss";
.fst-SearchListItem {
    .list-item-container {
        display: flex;
        align-items: flex-start;
        padding-top: 20px;
        //width: calc(100% - 393px);
        //max-width: calc(100vw - 670px);
        //min-width: 600px;

        > .el-checkbox {
            margin: 2px 15px 0 0;

            .el-checkbox__inner {
                width: 22px;
                height: 22px;

                &:after {
                    border-width: 2px;
                    height: 10px;
                    left: 7px;
                    top: 2px;
                    width: 5px;
                }
            }
        }

        // --- .list-item see @ commons.scss

    }
}
</style>

<style lang="scss" scoped>
</style>
