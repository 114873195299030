<template>
    <div class="fst-Sources">
        <div class="admin-content mb-3 mw-950">
            <div class="d-flex">
                <el-input
                    placeholder="Search by source name or group"
                    v-model="searchSourceQuery"
                    @input="handleSearchSource"
                    class="w-auto flex-1 mr-3"
                    clearable>
                    <template slot="prepend"><i class="el-icon-search"/></template>
                </el-input>

                <el-button type="primary" @click="showEditGroup=true" size="mini">
                    <i class="far fa-edit mr-1"></i> Edit source groups
                </el-button>
            </div>
            <el-row type="flex" :gutter="10">
                <el-col :span="24">
                </el-col>
            </el-row>
        </div>
        <div class="admin-content py-2 mw-950">
            <div class="table-like iqTab sortTab">
                <div class="thead">
                    <div>
                        <div class="sortBy" :class="sortBy.name.dir" @click="handleSortBy('name')">
                            <div>
                                Source (Web site)
                                <span></span>
                            </div>
                        </div>
                        <div>
                            <div>
                                Source groups
                                <span></span>
                            </div>
                        </div>
                        <div class="sortBy" :class="sortBy.docCount.dir" @click="handleSortBy('docCount')">
                            <div>
                                Documents
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tbody">
                    <div v-for="(item, key) in sourceListVisible" :key="key">
                        <div class="name">
                            <text-highlight
                                :queries="searchSourceQuery"
                                :highlightClass="'markIt'">
                                {{ item.name }}
                            </text-highlight>
                        </div>
                        <div>
                            <div class="source-group"
                                 v-for="(group, grkey) in item.groups"
                                 :key="grkey"
                            >
                                <div>
                                    <text-highlight
                                        :queries="searchSourceQuery"
                                        :highlightClass="'markIt'">
                                        {{ group.name }}
                                    </text-highlight>
                                </div>
                                <div class="close">
                                    ...
                                    <i class="far fa-times"
                                       @click="handleUnAssignGroup({groupId: group.id, sourceName: item.name})"></i>
                                </div>
                            </div>
                            <div class="source-group is-link" @click="handleAssignGroup({sourceName: item.name})">
                                <i class="far fa-plus"></i>
                            </div>
                        </div>
                        <div class="docs">
                            <span>{{ item.docCount }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="Assign group"
            custom-class="dialog-custom"
            :visible.sync="showAssignGroup"
            width="25%">
            <div class="groupsToEdit">
                <div class="subtitle"> To source "<span class="text-uppercase">{{ sourceNameToAssign }}</span>"</div>
                <el-select v-model="assignGroupValue" filterable placeholder="Select group" class="w-100">
                    <el-option
                        v-for="item in groupList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button type="text" @click="showAssignGroup = false">Cancel</el-button>
                <el-button type="primary" @click="assignGroup">Assign</el-button>
            </div>
        </el-dialog>
        <el-dialog
            custom-class="dialog-custom"
            title="Edit groups"
            :visible.sync="showEditGroup"
            width="30%">
            <div class="groupsToEdit">
                <el-row class="header">
                    <el-col :span="14">Group name</el-col>
                    <el-col :span="1"></el-col>
                    <el-col :span="8">Exclude from News</el-col>
                    <el-col :span="3"></el-col>
                </el-row>
                <el-row type="flex" v-for="(item,key) in groupList" :key="key" :gutter="10" class="mb-2">
                    <el-col :span="14">
                        <el-input v-model="item.name" @change="handleSaveGroup(item)"></el-input>
                    </el-col>
                    <el-col :span="1">
                        <i class="fas fa-spinner fa-pulse iq-text-primary mt-2" v-if="item.saving"></i>
                    </el-col>
                    <el-col :span="8" class="text-center">
                        <el-checkbox v-model="item.excludeFromSearch" @change="handleSaveExclude(item)"
                                     class="mt-2"></el-checkbox>
                    </el-col>
                    <el-col :span="3" class="text-right">
                        <el-button type="danger" plain @click="handleDeleteGroup({id: item.id})">
                            <i class="fas fa-trash-alt"></i>
                        </el-button>
                    </el-col>

                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="text" @click="handleCloseEditGroup">Close</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'Sources',
    components: {},
    props: {
        incEvent: Object
    },
    data() {
        return {
            showEditGroup: false,
            assignGroupValue: {},
            sourceNameToAssign: '',
            showAssignGroup: false,
            searchSourceQuery: '',
            sourceList: [],
            groupList: [],
            sortBy: {
                docCount: {
                    dir: ''
                },
                name: {
                    dir: 'ASC'
                }

            }
        };
    },
    created() {
        this.getHostingList();
        this.getGroupList();
    },
    computed: {
        sourceListVisible() {
            return _.filter(this.sourceList, s => s && s.show);
        }
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        }
    },
    methods: {
        getHostingList(sortBy) {
            let query = {
                fullness: 'FULL',
                count: 1881,
                offset: 0,
                order: [{
                    field: (sortBy && sortBy.field) ? sortBy.field : 'name',
                    type: (sortBy && sortBy.dir) ? sortBy.dir : 'ASC',
                }]
            }
            this.$services.hostingGroups.listHostings(query).prepare().then((resp) => {
                this.sourceList = resp.data.list.map(it => {
                    let _out = it,
                        _input = this.searchSourceQuery.toLowerCase();
                    _out.show = !_input || (it.name || '').toLowerCase().includes(_input) || !!((it.groups || []).find(group => (group.name || '').toLowerCase().includes(_input)));
                    // _out.show = true
                    return _out
                })

            })
        },
        getGroupList() {
            let query = {
                fullness: 'FULL',
                count: 1881,
                offset: 0,
                order: [{
                    field: 'name',
                    type: 'ASC'
                }]
            }
            this.$services.hostingGroups.list(query).prepare().then((resp) => {
                this.groupList = resp.data.list.map(it => {
                    let _out = it;
                    _out.saving = false;
                    return _out;
                });
            })
        },
        assignGroup() {
            let query = {
                hosting: this.sourceNameToAssign,
                group: {
                    id: this.assignGroupValue
                }
            }
            // console.log(query);
            this.$services.hostingGroups.assign(query).prepare().then((resp) => {
                this.showAssignGroup = false;
                this.getHostingList();
            });
        },
        handleSearchSource() {
            let self = this;
            self.sourceList = self.sourceList.map(it => {
                let _out = it,
                    _input = self.searchSourceQuery.toLowerCase();
                _out.show = (it.name || '').toLowerCase().includes(_input) || !!((it.groups || []).find(group => (group.name || '').toLowerCase().includes(_input)));
                return _out
            });
        },
        handleAssignGroup(params) {
            this.assignGroupValue = '';
            this.sourceNameToAssign = params.sourceName;
            this.showAssignGroup = true;
        },
        handleUnAssignGroup(params) {
            let query = {
                hosting: params.sourceName,
                group: {
                    id: params.groupId
                }
            };
            this.$services.hostingGroups.unassign(query).prepare().then((resp) => {
                this.sourceList = this.sourceList.map(it => {
                    let _out = it;
                    _out.groups = (it.name === params.sourceName) ? it.groups.filter(group => group.id !== params.groupId) : it.groups
                    return _out
                })
            })
        },
        handleSaveExclude(item) {
            item.saving = true;
            let query = {
                excludeFromSearch: item.excludeFromSearch,
                id: item.id
            }
            this.$services.hostingGroups.save(query).then((resp) => {
                setTimeout(() => {
                    item.saving = false
                }, 404)
            });

        },
        handleSaveGroup(item) {
            item.saving = true;
            let query = {
                name: item.name,
                id: item.id
            }
            this.$services.hostingGroups.save(query).then(() => {
                setTimeout(() => {
                    item.saving = false
                    this.getGroupList();
                }, 404)
            });

        },
        handleDeleteGroup(params) {
            let query = {
                ids: [params.id]
            }
            this.$confirm('Delete this group?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$services.hostingGroups.delete(query).prepare().then(() => {
                    this.getGroupList();
                });
            }).catch(() => {
            });
        },
        handleCloseEditGroup() {
            this.getHostingList();
            this.showEditGroup = false;
        },
        handleSortBy(item) {
            let _curSortDir = _.cloneDeep(this.sortBy[item].dir);
            _.each(this.sortBy, it => {
                it.dir = '';
            });
            if (_curSortDir) {
                this.sortBy[item].dir = (_curSortDir === 'ASC') ? 'DESC' : 'ASC';
            } else {
                this.sortBy[item].dir = 'ASC';
            }
            this.getHostingList({field: item, dir: this.sortBy[item].dir});
        }

    },
    destroyed() {

    }
}
</script>

<style lang="scss">
@import "../../assets/variables.scss";

.fst-Sources {
    .groupsToEdit {
        padding: 20px;

        .header {
            font-weight: 700;
            margin-bottom: 10px;
        }

        .el-button {
            height: 32px;
            padding: 0 10px;
        }

        .el-button + .el-button {
            margin-left: 5px;
        }

    }

    .addGroupInput {
        transition: all .5s ease;
        max-width: 0;
        overflow: hidden;

        &.active {
            max-width: 1000px;
        }
    }

    .markIt {
        background: none;
        color: #000;
        font-weight: 600;
    }
    .tbody{
        max-height: calc(100vh - 250px);
    }
}
</style>
<style lang="scss" scoped>
.subtitle {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
}

.is-link {
    cursor: pointer;
}
</style>
