import Vue from 'vue';
import Router from 'vue-router';
import Admin from './views/Admin.vue';
import Settings from './views/Settings.vue';
import ClusterMap from './views/ClusterMap.vue';
import Clusters from './views/Clusters.vue';
import Dashboard from './views/Dashboard.vue';
import SearchProfile from './views/SearchProfile.vue';
import Home from './views/Home.vue';
import Card from './components/search/SearchListCard.vue';
import CompanyCard from './components/search/CompanyCard.vue';
import PatentCard from './components/search/PatentCard.vue';

Vue.use(Router);

export default new Router({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes: [{
        path: '/',
        name: 'home',
        component: Home
    }, {
        path: '/admin',
        name: 'admin',
        component: Admin,
    }, {
        path: '/settings',
        name: 'settings',
        component: Settings,
    }, {
        path: '/searchProfile',
        name: 'searchProfile',
        component: SearchProfile,
    }, {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
    }, {
        path: '/clusterMap',
        name: 'clusterMap',
        component: ClusterMap,
    }, {
        path: '/clusters',
        name: 'clusters',
        component: Clusters,
    }, {
        path: '/card',
        name: 'card',
        component: Card
    }, {
        path: '/companyCard',
        name: 'companyCard',
        component: CompanyCard
    }, {
        path: '/patentCard',
        name: 'patentCard',
        component: PatentCard
    }, {
        path: '/tests',
        name: 'tests',
        component() {
            return import('./components/tests/Tests')
        }
    }]
})
