<template>
    <div class="widget-block">
        <div class="header">
            <div class="title">
                {{ title }}
                <div class="viewAll" @click="viewAll()">{{ $t('dashboard.viewAll') }}
                    <template v-if="field === 'topFundedCompanies'">{{ $t('dashboard.funding') }}</template>
                </div>
            </div>
        </div>
        <div class="cards" v-show="companies.length"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <el-popover
                v-for="(item, key) in companies"
                :key="key"
                placement="top-start"
                width="400"
                popper-class="investDesc dark"
                :open-delay="1250"
                :close-delay="300"
                trigger="hover">
                <div>
                    <div class="desc" v-html="item.description"></div>
                    <div class="tags"
                         v-show="item.systemKeywords && item.systemKeywords.length">
                        <div v-for="(tag, tk) in item.systemKeywords" :key="tk">{{ tag }}</div>
                    </div>
                </div>
                <div slot="reference">
                    <div class="card" @click="handleCardClick(item)">
                        <div class="logo">
                            <img :src="item.urlLogo" alt="Company Logo" onerror="this.src='noLogo3.png'">
                        </div>
                        <div class="text">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="investmentTotal" v-if="item.investmentTotal">Investment total:
                                ${{ item.investmentTotal }}
                            </div>
                            <div class="investmentTotal" v-else-if="item.funded">Funded:
                                ${{ item.funded }}
                            </div>
                            <div class="link" v-else><a :href="item.url" target="_blank">{{ item.urlFormatted }}</a>
                            </div>
                            <div class="attr">{{ item.attr }}</div>
                        </div>
                    </div>
                </div>
            </el-popover>

        </div>
        <div class="noResult" v-show="companies.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "TopCompanies",
    props: {
        request: Object,
        title: String,
        widget: String,
        field: String,
        tab: String,
        ventureSubtypeFacets: Array,
    },
    data() {
        return {
            loading: false,
            companies: [],
            reQ: {
                chart: {},
            },
            facets: [],
            excludeStages: [
                'undisclosed',
                'grant',
                'initial_coin_offering',
                'non_equity_assistance',
                'debt_financing',
                'post_ipo_equity',
                'post_ipo_debt',
                'post_ipo_secondary',
                'secondary_market'
            ],

        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        isInvestment() {
            return this.tab === 'Investment';
        }
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        request() {
            this.refresh();
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let query = _.cloneDeep(self.request);

            delete query.widgets;
            query.widgets = [
                self.widget
            ];

            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                } catch (e) {
                    console.error(e);
                }
            }

            self.loading = true;

            self.reQ.chart = self.$services.analytics.fullView(query);

            self.reQ.chart.then(resp => {
                let _resp = resp.data[self.field] || {};
                let _data = _resp.list ? _resp.list : _resp;

                // console.log(`-- ${self.field} Cards Data: `, _data);


                // -------------- CompCards ---------------

                self.companies = _data.slice(0, 8).map(company => {
                    let it = company.investor ? company.investor
                        : company.investee ? company.investee
                            : company;
                    let countriesFormat = (it.countries || []).join('<i class="divider"></i>'),
                        founded = _.compact([countriesFormat, it.year]).join(', ');
                    it.urlLogo = (it.icon && it.icon.url && !it.icon.url.includes('placeholder_')) ? it.icon.url.replace('f_jpg', 'f_auto,b_white,q_auto:eco') : 'noLogo3.png';
                    it.urlFormatted = it.url ? it.url.split('/')[2].replace('www.', '') : '';
                    it.investmentTotal = company.investor ? self.$utils.toFinHuman(company.investmentTotal, 1).number : null;
                    it.funded = company.investee ? self.$utils.toFinHuman(company.investmentTotal, 1).number : null;
                    it.attr = founded;
                    return it;
                });

                self.$nextTick(() => {
                    self.loading = false;
                })
            }).catch(e => {
                console.log(e);
            });
        },
        viewAll() {
            let self = this;
            let years = [],
                docType = self.tab;
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            let facets = self.tab === 'Investment' ? {
                requests: [
                    {
                        type: yearType,
                        values: years
                    },
                ]
            } : null;
            if (['topVentureInvestors','topFundedCompanies'].includes(self.field)) {
                let subtypes = self.ventureSubtypeFacets.filter(it => !self.excludeStages.includes(it));
                facets.requests.push({
                    type: 'INVT_TYPE',
                    values: ['venture'],
                }, {
                    type: "INVT_SUBTYPE",
                    values: subtypes
                })
            }

            let orderBy = null,
                investmentSortBy = null;

            if (self.tab === 'Companies') {
                orderBy = 'companyRank';
            } else if (self.field === 'topFundedCompanies') {
                investmentSortBy = {
                    field: 'sum',
                    dir: 'DESC'
                }
            }

            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
                orderBy: orderBy,
                investmentSortBy: investmentSortBy
            }
            self.$utils.openSearch(params);

        },
        handleCardClick(item) {
            let self = this;
            if (self.isInvestment) {
                self.handleSearch(item)
            } else {
                self.handleOpenCompanyCard(item)
            }
        },
        handleSearch(item) {
            let self = this;
            let years = [],
                docType = 'Investment';
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }

            let facets = {
                requests: [
                    {
                        type: yearType,
                        values: years
                    },
                ]
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
                investor: item,
            }
            self.$utils.openSearch(params);
        },
        handleOpenCompanyCard(item) {
            if (item.id) {
                window.sessionStorage.removeItem('companyCard');
                window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
            } else {
                console.log('--- No company Id. Investor - person? --- ');
            }
        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">

</style>