<template>
    <div class="fst-QuickTour">

        <div class="tour-Note" v-show="step.content.type === 'step'">
            <div class="title" v-html="step.content.title"></div>
            <div class="text" v-html="step.content.text"></div>
            <div class="control fixed">
                <div class="wrap">
                    <el-button type="default" @click="handleClickButton('skip')">
                        {{ $t('quickTour.skip') }}
                    </el-button>
                    <div class="breadcrumbs">
                        <div v-for="ind in step.totalSteps" :key="ind" @click="handleClickButton('stepNumber',ind)">
                            <i :class="['fa-circle', (step.number === ind) ? 'fas' : 'far']"></i>
                        </div>
                    </div>
                    <div class="d-flex">
                        <el-button type="default" @click="handleClickButton('back')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                 viewBox="0 0 17.818 12.526">
                                <path id="left-arrow"
                                      d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                      fill="currentColor"></path>
                            </svg>
                            {{ $t('quickTour.back') }}
                        </el-button>
                        <el-button type="primary" @click="handleClickButton('next')">
                            {{ $t('quickTour.next') }}
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.818" height="12.526"
                                 viewBox="0 0 17.818 12.526">
                                <path id="left-arrow"
                                      d="M5.812.188a.632.632,0,0,1,.9.889L2.158,5.63H17.18a.632.632,0,0,1,.638.629.64.64,0,0,1-.638.638H2.158L6.71,11.44a.645.645,0,0,1,0,.9.629.629,0,0,1-.9,0L.182,6.707a.633.633,0,0,1,0-.889Z"
                                      fill="currentColor"></path>
                            </svg>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
                v-if="step.content.type === 'modal'"
                :title="step.content.title"
                :close-on-click-modal="false"
                :visible.sync="step.show"
                :modalAppendToBody="true"
                width="650px"
                :custom-class="'dialog-custom text-left quickTour'"
        >
            <div class="modalContent">
                <div v-html="step.content.text"></div>
                <div class="control" v-if="step.content.buttons">
                    <el-button type="default" @click="handleClickButton(step.content.buttons.first.action)">
                        {{ $t(step.content.buttons.first.name) }}
                    </el-button>
                    <el-button type="primary" @click="handleClickButton(step.content.buttons.second.action)">
                        {{ $t(step.content.buttons.second.name) }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <div class="darkness-qt" v-show="!step.content.noDarkness && step.widget !== 'view360'"></div>

    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "QuickTour",
        computed: {
            ...mapGetters({
                step: 'QTStatus'
            }),
        },
        data() {
            return {}
        },
        mounted() {
            // console.log('>step:::', this.step);
        },
        methods: {
            handleClickButton(action, num) {
                console.log('>step:::', this.step);

                if (action === 'skip') {
                    this.$store.commit('QTSkip');
                } else if (action === 'next') {
                    let _num = this.step.number + 1;
                    this.$store.commit('QTChangeStep', _num);
                } else if (action === 'back') {
                    let _num = this.step.number - 1;
                    this.$store.commit('QTChangeStep', _num);
                } else if (action === 'stepNumber') {
                    this.$store.commit('QTChangeStep', num);
                } else if (action === 'restart') {
                    this.$store.commit('QTStart');
                }
            },
        }

    }
</script>

<style lang="scss">
    #app {
        .fst-Dashboard,
        .fst-Clusters {
            .fst-QuickTour {
                .el-button--primary {
                    background: $iq-color-blue;
                    color: #fff;

                    &:hover {
                        background: lighten($iq-color-blue, 7%);
                        border-color: $iq-color-blue;
                    }
                }
            }
        }

        .tour-Note {
            display: none;
            padding: 20px;
            border-radius: 15px;
            background: #fff;
            font-size: 15px;
            width: 420px;
            position: absolute;
            transition: all .1s ease;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
            left: 337px;
            top: 70px;
            z-index: 7000;
            text-align: center;

            &.hidden {
                /*opacity: 0;*/
            }

            &:before {
                content: " ";
                position: absolute;
                width: 15px;
                height: 15px;
                background: #fff;
                display: inline-block;
                top: -7px;
                transform: rotate(45deg);
                z-index: 30;
            }

            &:after {
                content: ' ';
                width: 0;
                height: 0;
                display: inline-block;
                position: absolute;
                border-bottom: 11px solid #d9d9d9;
                border-top: 11px solid transparent;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
                left: calc(50% - 4px);
                top: -22px;
            }

            &.angle-bottom {
                &:before {
                    top: auto;
                    bottom: -7px;
                }

                &:after {
                    left: calc(50% - 4px);
                    top: auto;
                    border-top: 11px solid #d9d9d9;
                    border-bottom: 11px solid transparent;
                    border-left: 11px solid transparent;
                    border-right: 11px solid transparent;
                    bottom: -22px;
                }
            }

            &.angle-left {
                &:before {
                    top: calc(50% - 6px);
                    left: -7px;
                }

                &:after {
                    border-bottom: 11px solid transparent;
                    border-right: 12px solid #d9d9d9;
                    top: calc(50% - 9px);
                    right: auto;
                    left: -23px;
                }
            }

            &.angle-right {
                &:before {
                    top: calc(50% - 6px);
                    left: auto;
                    right: -7px;
                }

                &:after {
                    border-bottom: 11px solid transparent;
                    border-left: 12px solid #b3b3b3;
                    top: calc(50% - 9px);
                    left: auto;
                    right: -23px;
                }
            }

            &.angle-none {
                &:before, &:after {
                    content: none;
                }
            }

            .title {
                font-size: 20px;
                font-weight: 500;
            }

            .text {
                line-height: 1.5;
                margin-top: 10px;

                &:empty {
                    display: none;
                }
            }

            .breadcrumbs {
                display: flex;
                gap: 4px;
                font-size: 9px;
                color: $iq-color-blue;
                justify-content: center;
                margin: 0 30px;

                > div {
                    cursor: pointer;
                    padding: 2px;

                }
            }

            .el-button {
                min-height: 45px;
                font-size: 15px;
                font-variant: all-small-caps;
                color: $iq-color-grey-blue;
                padding: 0 30px;
                display: inline-flex;
                align-items: center;

                > span {
                    display: flex;
                    align-items: center;
                    line-height: 1;

                    svg {
                        margin-right: 10px;
                    }
                }

                &:hover {
                    color: $iq-color-blue;
                    background: #ffffff;
                }

                &.el-button--primary {
                    color: #fff;

                    > span {
                        svg {
                            margin-left: 10px;
                            margin-right: 0;
                            transform: rotate(180deg);
                        }
                    }

                    &:hover {
                        background: $iq-color-blue;
                        color: $iq-color-blue-medium;
                    }
                }
            }

            .control {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                margin-top: 30px;

                &.fixed {
                    position: fixed;
                    border-radius: 15px;
                    bottom: 30px;
                    width: 800px;
                    background: #fff;
                    left: calc(50% - 400px);
                    padding: 15px 30px;
                    box-sizing: border-box;
                    box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.3);
                }

                .wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 50px;
                    width: 850px;
                }
            }

            & + .el-dialog__wrapper {
                z-index: 6000000 !important;
            }
        }

        .quickTourStarted {
            .darkness-qt,
            .darkness-uni,
            .tour-Note {
                display: block;
            }

            &.fst-ClusterMap {
                .darkness-uni {
                    display: none;
                }
            }

            .darkness-qt {
                z-index: 5005;
            }


            //------------------

            .fst-PredicateEditor .predicate-block.tourHighlight {
                position: absolute;
                display: block;
                width: 100%;
                border-radius: 5px;
                z-index: 5000;
            }

            //------------------

            .view360 {
                .periodSelector.tourHighlight {
                    position: absolute;
                    padding: 5px 10px;
                    border-radius: 5px;
                    background: $iq-dark-theme-bg-light-1;
                    z-index: 5000;
                }
            }

            //------------------

            .view360 .widgets.tourHighlight {
                &:before {
                    /*content: ' ';*/
                    /*display: block;*/
                    /*width: 200px;*/
                    /*height: 400px;*/
                }

                > div {
                    height: 492px;
                }

                &.tourSubStep0 {
                    > div {
                        &:nth-child(1) {
                            top: 72px;
                            position: absolute;
                            z-index: 5000;
                            width: calc(50% - 42px);
                        }
                    }
                }

                &.tourSubStep1 {
                    > div {
                        &:nth-child(1) {
                            top: 72px;
                            position: absolute;
                            z-index: 5000;
                            width: calc(50% - 42px);

                            .cloud {
                                > div > div > div:first-child > div {
                                    padding: 1px 10px;
                                    background: $iq-color-blue;
                                    border-radius: 5px;

                                    .keyword {
                                        color: #fff;
                                        font-weight: 600;
                                        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
                                    }
                                }
                            }
                        }
                    }
                }

                &.tourSubStep2 {
                    > div {
                        &:nth-child(2) {
                            top: 72px;
                            position: absolute;
                            z-index: 5000;
                            width: calc(50% - 42px);
                            right: 29px;

                            .cards {
                                > *:nth-child(3) {
                                    .card {
                                        transition: all .3s ease-out;
                                        background: $iq-color-blue;
                                        color: #fff;

                                        a {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.tourSubStep3 {
                    > div {
                        &.SW_patentDynamics {
                            top: 72px;
                            position: absolute;
                            z-index: 5000;
                            width: calc(50% - 42px);

                            .apexcharts-series:nth-child(2) .apexcharts-series-markers:nth-child(3) circle {
                                r: 15;
                                stroke-width: 4;
                                transition: all .2s ease-in;
                                fill: $iq-color-blue;
                            }
                        }
                    }
                }
            }

            //------------------

            .view360 .widget-block {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    .header .viewAll.tourHighlight {
                        position: absolute;
                        z-index: 5000;
                        right: -78px;
                        padding: 3px 10px;
                        border-radius: 4px;
                        background: #000;
                        color: #fff;
                        font-weight: 500;
                    }
                }

                &:nth-child(4) {
                    .header .viewAll.tourHighlight {
                        right: -215px;
                    }
                }
            }

            //------------------
            .view360 {
                .widgets.tourStepGraphDoc {
                    .widget-block {
                        .widgetMode {
                            position: absolute;
                            z-index: 5000;
                            right: 13px;
                            padding: 3px 10px;
                            border-radius: 4px;
                            background: #000;
                            color: #fff;
                            font-weight: 500;
                        }
                    }
                }
            }

            //------------------

            .view360.fst-Analytics {
                .periodSelector.tourHighlight {
                    position: static;
                    background: #fff;
                }

                //------------------

                .widgets.tourHighlight {
                    &:before {
                        /*content: ' ';*/
                        /*display: block;*/
                        /*width: 200px;*/
                        /*height: 400px;*/
                    }

                    > div {
                        height: 492px;
                    }

                    &.tourSubStep0 {
                        > div {
                            &:nth-child(1) {
                                top: 103px;
                                position: absolute;
                                z-index: 5000;
                                width: calc(50vw - 220px);
                            }
                        }
                    }

                    &.tourSubStep1 {
                        > div {
                            &:nth-child(1) {
                                top: 103px;
                                position: absolute;
                                z-index: 5000;
                                width: calc(50vw - 220px);

                                .cloud {
                                    > div > div > div:first-child > div {
                                        padding: 1px 10px;
                                        background: $iq-color-blue;
                                        border-radius: 5px;

                                        .keyword {
                                            color: #fff;
                                            font-weight: 600;
                                            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.tourSubStep2 {
                        > div {
                            &:nth-child(2) {
                                top: 103px;
                                position: absolute;
                                z-index: 5000;
                                width: calc(50vw - 220px);
                                right: 29px;

                                .cards {
                                    > *:nth-child(3) {
                                        .card {
                                            transition: all .3s ease-out;
                                            background: $iq-color-blue;
                                            color: #fff;

                                            a {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.tourSubStep3 {
                        > div {
                            &.SW_patentDynamics {
                                top: 103px;
                                position: absolute;
                                z-index: 5000;
                                width: calc(50vw - 220px);

                                .apexcharts-series:nth-child(1) .apexcharts-series-markers:nth-child(3) circle {
                                    r: 12;
                                    stroke-width: 4;
                                    transition: all .2s ease-in;
                                    fill: $iq-color-blue;
                                    stroke: #c6954b;
                                }
                            }
                        }
                    }
                }

                //------------------

                .widget-block {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        .header .viewAll.tourHighlight {
                            position: absolute;
                            z-index: 5000;
                            right: -78px;
                            padding: 3px 10px;
                            border-radius: 4px;
                            background: #FFF;
                            color: $iq-color-primary;
                            font-weight: 500;
                        }
                    }

                    &:nth-child(4) {
                        .header .viewAll.tourHighlight {
                            right: -215px;
                        }
                    }
                }

                //------------------

                .widgets.tourStepGraphDoc {
                    .widget-block {
                        .widgetMode {
                            position: relative;
                            z-index: 5000;
                            right: 13px;
                            padding: 3px 10px;
                            border-radius: 4px;
                            background: #fff;
                            font-weight: 500;
                        }
                    }
                }

                //------------------
            }

            //------------------
            &.fst-ClusterMap {
                .chart-container > .control.right {
                    &.tourStepCardKeywords {
                        z-index: 6000;

                        .keywords {
                            padding: 5px 10px;
                            margin: -7px -13px;
                            border-radius: 4px;
                            border: 3px solid $iq-color-primary;
                        }
                    }

                    &.tourStepShowDocs {
                        z-index: 6000;

                        .el-link {
                            padding: 2px 7px;
                            margin: -4px -10px;
                            border: 3px solid $iq-color-primary;
                            border-radius: 3px;

                            .el-link--inner {
                                color: #fff !important;
                            }
                        }
                    }

                    &.tourStepDocsByYears {
                        z-index: 6000;

                        .yearCounts {
                            padding: 10px 20px;
                            margin: 1px -7px;
                            border: 3px solid $iq-color-primary;
                            /*color: #fff;*/
                            border-radius: 5px;
                        }
                    }
                }
                .fst-Header {
                    &.tourStepLogoBackToSearch {
                        svg{
                            background: $iq-color-primary;
                            padding: 0 10px;
                            margin: 0 -10px;
                            border-radius: 5px;
                            color: #fff !important;
                        }
                    }
                }
                .colorSchemeBlock{
                    &.tourStepChangeColoring{
                        .el-input__inner{
                            background: $iq-color-primary;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
</style>