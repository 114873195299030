import _ from "lodash";
import moment from "moment";
import Const from './../const/index.js';
import store from '@/store';

export const FORMAT_DATE = Const.format.moment.date;
export const FORMAT_DATETIME = Const.format.moment.datetime;

const dateFormats = [FORMAT_DATETIME, FORMAT_DATE, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DDTHH:mm', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm'];

export function serverMoment() {
    return moment().add(store.state.serverTimeOffset, 'milliseconds');
}

export function toMoment(val) {
    if(!val) {
        return;
    }
    if(moment.isMoment(val) || val instanceof Date) {
        return moment(val)
    }
    if(isFinite(val)) {
        return moment(Number(val));
    }
    if(_.isString(val)) {
        if(!(val = val.trim()).length) {
            return;
        }
        for (let format of dateFormats) {
            let m = moment(val, format, true);
            if(m.isValid()) {
                return m;
            }
        }
        for (let format of dateFormats) {
            let m = moment(val, format, false);
            if(m.isValid()) {
                return m;
            }
        }
    }
}

export function toDate(val) {
    if(!val) {
        return;
    }
    if(val instanceof Date) {
        return val;
    } else if(moment.isMoment(val)) {
        return val.toDate();
    }

    const m = toMoment(val);
    if(m) {
        return m.toDate();
    }
}

export function toDatetimeString(val) {
    val = toMoment(val);
    return val && val.format(FORMAT_DATETIME);
}

export function toDateString(val, format) {
    val = toMoment(val);
    return val && val.format(format || FORMAT_DATE);
}

export default Object.freeze({
    toMoment, toDate, toDatetimeString, toDateString, format: toDateString, serverMoment, FORMAT_DATE, FORMAT_DATETIME
});
