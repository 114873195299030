<template>
    <div class="widget-block Diagram"
         v-loading="loading">
        <div class="header mb-4">
            <div class="title">
                {{ title }}
            </div>
            <export-to-image :filename="title" selector=".chart-container"></export-to-image>
        </div>
        <div class="chart-container"
             v-loading="loading">
            <apexchart
                    v-show="chartSeries.length"
                    ref="chart"
                    :options="chartOptions"
                    :series="chartSeries"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWDiagram",
        components: {ExportToImage},
        props: {
            title: String,
            filter: Object,
            years: Object,
            params: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                loading: false,
                showRows: 0,
                chartSeries: [],
                fullData: [],
                docType: 'Patents',
                chartOptions: {
                    chart: {
                        type: this.params.chartType || 'pie',
                        foreColor: '#6A6C73',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        zoom: {
                            enabled: false,
                        },
                    },
                    colors: [
                        'hsl(224, 44%, 67%)', // BlueBright
                        '#5d6d63',  // FieldGrey
                        'hsl(36, 53%, 52%)', // Orange2
                        'hsl(352, 55%, 55%)', // Red2
                        'hsl(125, 37%, 50%)', // Green2
                    ],
                    tooltip: {
                        enabled: true,
                    },
                    fill: {
                        opacity: 0.95,
                    },
                    labels: [],
                    legend: {
                        show: true,
                        position: 'right',
                        horizontalAlign: 'center',
                        fontSize: this.params.upperCase ? '11px' : '14px',
                        labels: {
                            colors: '#46484C',
                            useSeriesColors: true
                        },
                        itemMargin: {
                            horizontal: 15,
                            vertical: 5
                        },
                    },
                },
                reQ: {
                    chart: {},
                },
                colors: [
                    'hsl(224, 44%, 67%)', // BlueBright
                    'hsl(36, 53%, 52%)', // Orange2
                    'hsl(352, 55%, 55%)', // Red2
                    'hsl(125, 37%, 50%)', // Green2
                    '#5d6d63',  // FieldGrey
                ]
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                self.widgetFilter = _.cloneDeep(self.filter);
                if(self.params.docType){
                    self.widgetFilter.types = [self.params.docType]
                }

                let query = {
                    "filter": self.widgetFilter,
                    "widgets": {
                        "chart": {
                            "type": self.params.type || "TERM_COUNT",
                            "field": self.params.field,
                            "limit": self.params.total || self.params.limit,
                        },
                    }
                };
                if (self.params.type === "TERM_GROWTH") {
                    query.widgets.keywords.years = {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "CLOSED",
                        "min": self.years.min,
                        "max": self.years.max
                    }

                }

                if (typeof (self.params.significant) !== 'undefined') {
                    query.widgets.chart.significant = self.params.significant;
                }
                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }
                for (let i = 0; i < 30; i++) {
                    self.colors.push(self.$utils.getColor(i));
                }

                self.loading = true;
                self.reQ.chart = self.$services.analytics.widgets(query);

                self.reQ.chart.then(resp => {

                    let _data = self.fullData = resp.data.widgets.chart.terms;

                    self.chartSeries = _.map(_data, it => {
                        return parseInt(it.docCount)
                    });
                    let labels = _.map(_data, it => {
                            return self.params.upperCase ? _.upperCase(it.name || it.value)
                                : _.upperFirst(it.name || it.value)
                        }),
                        options = {
                            chart: {
                                type: self.params.chartType || 'pie',
                                width: self.params.width || '850px',
                                height: self.params.height || '360px',
                            },
                            labels: labels,
                            dataLabels: {
                                enabled: true,
                                style: {
                                    fontSize: '16px',
                                },
                                formatter: function (val, opts) {
                                    return self.$utils.roundX(val) + "%"
                                },
                            },
                            plotOptions: {
                                pie: {
                                    // startAngle: -90,
                                    // endAngle: 90,
                                    // offsetY: 10
                                    customScale: 1
                                },
                            },
                            colors: self.colors,
                            tooltip:{
                                y: {
                                    formatter: function (val, opts) {
                                        return self.$utils.toFin(val)
                                    },
                                },
                            },
                            grid: {
                                // padding: {
                                //     bottom: -180
                                // }
                            },
                            legend: {
                                formatter: function (seriesName, opts) {
                                    return [seriesName, ": ", self.$utils.toFin(opts.w.globals.series[opts.seriesIndex])]
                                }
                            }
                        }
                    self.$refs.chart.updateOptions(options)

                    self.loading = false;
                });
            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this,
                    filter = _.cloneDeep(self.widgetFilter);
                let series = self.fullData[config.dataPointIndex],
                    docType = filter.types[0],
                    facets = {};

                facets.requests = filter.facets.requests || [];

                if (series.value) {
                    facets.requests.push({
                        type: self.params.facet,
                        values: [series.value]
                    })
                    let params = {
                        query: filter.nativeQuery ? filter.nativeQuery.value : filter.query,
                        isNativeQuery: !!filter.nativeQuery,
                        docType: docType,
                        facets: facets,
                        spam: false,
                        duplicate: 'false',
                    }

                    if (e && e.button === 0) {
                        self.$utils.openSearch(params);
                    }
                }
            },
        }
    }
</script>

<style lang="scss">
    .fst-Analytics{
        .Diagram{
            .apexcharts-legend-series {
                display: flex;
                align-items: flex-start;
                width: 290px;

                .apexcharts-legend-marker {
                    margin-right: 7px;
                    flex-shrink: 0;
                    top: 2px !important;
                }
            }

        }
    }
</style>

<style scoped lang="scss">

</style>