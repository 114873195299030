__webpack_public_path__ = window.iqmen.initData.staticPath;

import Vue from 'vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import router from './router'
import store from './store'
import _ from 'lodash'; window._ = _;
import moment from 'moment'; window.moment = moment;
import services from './commons/services'
import i18n from './commons/i18n'
import ElementUi from 'element-ui'
import ElementUiLocale from 'element-ui/lib/locale/lang/ru-RU'
import Modal from './commons/components/Modal';
import Pager from './commons/components/Pager';
import ContextMenu from './commons/components/ContextMenu';
import InfiniteLoading from 'vue-infinite-loading';
import VueClipboard from 'vue-clipboard2';
import TextHighlight from 'vue-text-highlight';
import VueApexCharts from 'vue-apexcharts';
import VueWordCloud from 'vuewordcloud';
import VueTour from 'vue-tour';
import LoadScript from 'vue-plugin-load-script';
import FlagIcon from 'vue-flag-icon';
// import CountryFlag from 'vue-country-flag'


// import VueMathjax from 'vue-mathjax';

import App from './App.vue'

import './assets/commons.scss'
import './assets/element-ui.scss';
import './commons/fonts/FontAwesome/scss/fontawesome.scss';
import './commons/fonts/FontAwesome/scss/solid.scss';
import './commons/fonts/FontAwesome/scss/regular.scss';
import './commons/fonts/FontAwesome/scss/light.scss';
import './commons/fonts/FontAwesome/scss/duotone.scss';
import './commons/fonts/FontAwesome/scss/brands.scss';

Vue.config.devtools = true;
Vue.config.productionTip = false;
require('vue-tour/dist/vue-tour.css')

Vue.use(Vuex);
Vue.use(Router);
Vue.use(services);
Vue.use(FlagIcon);
// Vue.use(CountryFlag)

Vue.use(LoadScript);
Vue.use(ContextMenu);
Vue.use(InfiniteLoading, {});
Vue.use(VueClipboard);
Vue.use(VueTour);

Vue.use(Modal, {dynamic: true, injectModalsContainer: true});
Vue.use(ElementUi, { locale: ElementUiLocale, size: 'small' });

Vue.component('iq-pagination', Pager);
Vue.component('text-highlight', TextHighlight);
Vue.component('apexchart', VueApexCharts);
Vue.component('vue-word-cloud', VueWordCloud)

store.dispatch('init', window.iqmen.initData).then(() => {
  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});


