<template>
    <div class="widget-block widget-News">
        <div class="header">
            <div class="title">
                {{ $t('dashboard.News_dynamics') }}
                <div class="viewAll" @click="viewAll()">{{ $t('dashboard.viewAll') }}</div>
            </div>
            <div class="companyType" v-show="widgetMode === 'chart'">
                <label>{{$t('companies')}}: </label>
                <el-select v-model="companyType"
                           popper-class="darkSelect"
                           @change="handleCompanyTypeChange">
                    <el-option :label="$t('facets.significant')" value="significant"></el-option>
                    <el-option :label="$t('facets.frequent')" value="frequent"></el-option>
                </el-select>
            </div>
            <div class="widgetMode">
                <span @click="handleWidgetModeChange('chart')"
                        :class="{active: widgetMode === 'chart'}">
                    <el-tooltip effect="dark" placement="top" content="Graph" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                  fill="currentColor"/>
                        </svg>
                    </el-tooltip>

                </span>
                <span @click="handleWidgetModeChange('news')"
                        :class="{active: widgetMode === 'news'}">
                    <el-tooltip effect="dark" placement="top" content="News" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                  fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
            </div>
        </div>
        <div class="chart-container"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <apexchart
                    v-show="chartSeries.length && widgetMode !== 'news'"
                    ref="chart"
                    :options="chartOptions"
                    :series="chartSeries"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="newsCards news" v-show="chartSeries.length && widgetMode === 'news'">
                <a :href="item.url" target="_blank" class="cardItem" v-for="item in news" :key="item.id">
                    <div class="title" v-html="item.title"></div>
                    <div class="tags">
                        <div v-for="(tag, tk) in item.keywords" :key="tk" v-html="tag"></div>
                    </div>
                    <div class="attrs">
                        <span>{{ item.dateFormatted }}</span>
                        <a :href="item.url" target="_blank">{{ item.hostingName }}</a>
                    </div>
                </a>
            </div>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";


    export default {
        name: "News",
        props: {
            request: Object,
            chartOptions: Object
        },
        data() {
            return {
                loading: false,
                curYear: new Date().getFullYear(),
                cagr: '',
                chartSeries: [],
                news: [],
                labels: [],
                reQ: {
                    chart: {},
                    documents: {}
                },
                result: {},
                widgetMode: 'chart',
                companyType: 'significant',
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            request() {
                this.refresh();
            },
        },
        mounted() {
            // this.refresh();
        },
        methods: {
            refresh() {
                let self = this,
                    promises = [];
                let query = _.cloneDeep(self.request)

                delete query.widgets;
                query.widgets = [
                    'news',
                ];

                let textQuery = {
                    count: 10,
                    offset: 0,
                    duplicate: false,
                    explain: false,
                    facets: {calculate: false},
                    profile: false,
                    query: self.request.filter.query,
                    nativeQuery: self.request.filter.nativeQuery,
                    queryFields: self.request.filter.queryFields,
                    semantic: false,
                    spam: false,
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    suggestQuery: false,
                    types: ["News"],
                }

                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                        self.reQ.documents.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }
                self.loading = true;

                self.reQ.chart = self.$services.analytics.fullView(query);
                self.reQ.documents = self.$services.documents.search(textQuery);

                promises.push(self.reQ.chart);
                promises.push(self.reQ.documents);

                Promise.all(promises).then(resp => {
                    self.result.chart = resp[0].data.newsYearCompanies;
                    self.result.news = resp[1].data.list;

                    self.cagr = self.$utils.roundX(resp[0].data.newsCagr * 100, 1);
                    self.news = self.result.news.map(item => {
                        let highlights = _.merge({full: {}, fragments: {}}, item.highlights),
                            keywordsToHighlights = {};
                        for (let prop in highlights.full) {
                            if (!prop || prop === 'id' || prop === 'type') {
                                continue;
                            }
                            if (prop.startsWith('systemKeywords') || prop.startsWith('systemKeywords')) {
                                keywordsToHighlights[_.get(item, prop)] = highlights.full[prop];
                                continue;
                            }
                            _.set(item, prop, highlights.full[prop]);
                        }

                        // keywords
                        item.keywords = _.uniq([].concat(item.systemKeywords || []).concat(item.systemKeywords || [])).map(kw => keywordsToHighlights[kw] || kw);
                        item.title = item.title || _.truncate(item.description, {'length': 100});
                        item.dateFormatted = moment(item.date).format('ll');
                        return item;
                    });


                    //---------- Sci & Pats Articles ----------
                    let _colors = [
                        '#8599D0', // BlueBright
                        '#C69245', // Orange2
                        '#C46370', // Red2
                        '#61B768', // Green2
                        '#BABABA'  // White2
                    ];

                    self.chartSeries = [{
                        name: self.$t('News') + (self.cagr ? ` (CAGR: ${self.cagr}%)` : ''),
                        data: []
                    }]

                    self.createChart(self.result.chart[self.companyType]);

                    let options = {
                        colors: _colors,
                        chart: {
                            type: 'line',
                            height: '380px',
                        },
                        markers: {
                            size: 5,
                            hover: {
                                size: 7
                            }
                        },
                        tooltip: {
                            enabled: true,
                            intersect: true,
                            shared: false,
                            y: {
                                title: {
                                    // formatter: function (val, opts) {
                                    //     return 'News:';
                                    // }
                                },
                            },
                            onDatasetHover: {
                                highlightDataSeries: true,
                            },
                        },
                        legend: {
                            show: true,
                            showForSingleSeries: true,
                            position: 'bottom',
                            horizontalAlign: 'left',
                        },
                        stroke: {
                            width: 4,
                            curve: 'smooth'
                        },
                        dataLabels: {
                            enabled: true,
                            offsetY: -10,
                            offsetX: 10,
                            borderWidth: 0,
                            textAnchor: 'start',
                            style: {
                                fontSize: '12px',
                                fontWeight: 'normal',
                                colors: ['#333']
                            },
                            background: {
                                enabled: true,
                                borderWidth: 10,
                                borderColor: '#333',
                                foreColor: '#ccc',
                                padding: 3,
                                opacity: 0.7,

                            },
                            formatter: function (val, index) {
                                let out = [],
                                    _arr = self.labels[index.dataPointIndex];
                                _.each(_arr, (it, i) => {
                                    let name = _.upperFirst(i);
                                    out.push((name.length > 14 ? name.substr(0, 14) + '...' : name));
                                })
                                return out;
                            }
                        },
                        yaxis: {
                            min: 0,
                            tickAmount: 6,
                            forceNiceScale: true,
                            labels: {
                                show: true,
                                align: 'right',
                                style: {
                                    fontSize: '14px'
                                },
                                formatter: function (val, index) {
                                    return self.$utils.toFinHuman(val, 0).number;
                                }
                            },
                        },
                    }
                    if (self.$refs && self.$refs.chart) {
                        self.$refs.chart.updateOptions(options)
                    }

                    self.loading = false;
                    // console.log('-- series News Articles: ', self.chartSeries, labels);
                }, err => {
                    if (err.response && err.response.data.message) {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message
                        });
                    }
                    self.loading = false;
                }).catch(e => {
                    console.log(e);
                });
            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this;
                let _series = self.chartSeries[config.seriesIndex],
                    _year = _series.data[config.dataPointIndex].x || '';
                if (e.button === 0) {
                    self.viewAll(_year.toString());
                }

            },
            createChart(_data) {
                let self = this,
                    _years = _.flatten(_.map(_data, (it, i) => {
                        return i;
                    })),
                    _from = _.min(_years),
                    _to = parseInt(_.max(_years)) + 1;
                self.labels = [];
                self.chartSeries[0].data = [];
                for (let year = _from; year <= _to; year++) {
                    let _item = _data[year];
                    self.chartSeries[0].data.push({
                        x: year,
                        y: _item ? _item.total : null
                    });
                    self.labels.push(_.pickBy(_item, (it, i) => i !== 'total'));
                }

            },
            handleWidgetModeChange(mode) {
                let self = this;
                self.widgetMode = mode;
                if (mode !== 'news') {
                    self.reload();
                }
            },
            handleCompanyTypeChange(type) {
                let self = this;
                self.companyType = type;
                self.createChart(self.result.chart[self.companyType])
                self.reload();
            },
            reload() {
                this.$nextTick(() => {
                    this.$refs.chart.updateOptions({})
                });
            },

            viewAll(year = null) {
                let self = this;
                let years = [],
                    docType = "News";
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

                for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                    years.push(year.toString());
                }

                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: year ? [year] : years
                        },
                    ]
                }
                // console.log('Facets', facets, self.request.filter);
                let params = {
                    query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                    isNativeQuery: !!self.request.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                self.$utils.openSearch(params);

            },
        }
    }
</script>

<style lang="scss">
    .widget-News {
        .companyType {
            .el-input__inner {
                max-width: 130px;
            }

            label {
                margin-right: 4px;
            }
        }
    }

</style>

<style scoped lang="scss">
</style>