<template>
    <div class="fst-SearchProfile wrapper">
        <Header></Header>
        <div class="content"
             v-loading="loading"
             element-loading-text="Loading user profile...">
            <div class="control-block">
                <h3 class="mr-5">{{$t('label.userMenu.searchProfile')}}</h3>
                <label class="mr-2">{{$t('name')}}: </label>
                <el-input class="mr-5" v-model="profileName" placeholder="Profile name"></el-input>
                <label class="mr-2">{{$t('active')}}: </label>
                <el-switch
                        v-model="profileActive"
                        @change="saveProfile()">
                </el-switch>
                <label class="ml-5 mr-2">{{$t('visible')}}: </label>
                <el-switch
                        v-model="profileVisible"
                        @change="saveProfile()">
                </el-switch>
                <el-button class="ml-5 px-4 text-uppercase" type="primary" @click="saveProfile()">
                    <i class="fas fa-save mr-1"></i> {{$t('save')}}
                </el-button>
            </div>
            <el-radio-group v-model="docType" @change="handleChangeDocType">
                <el-radio-button v-for="(item, key) in tabs" :key="key" :label="item.id">{{ item.title }}
                </el-radio-button>
            </el-radio-group>
            <PredicateEditor v-model="filterQuery"
                             @predicate="changePredicate"
                             :showApplyButton="false"
                             :hideFullSize="true"
                             :savedQueriesList="[]"
                             :placeholder="$t('placeholder.Type_query')"
                             :isNativeQuery="isNativeQuery"
                             ref="predicate">
                <template v-slot:right>
                    <div class="control-group">
                        <div class="nativeSearch"
                             v-if="showNativeQuery">
                            <el-switch
                                    v-model="isNativeQuery"
                                    @change="handleSwitchNativeQuery"
                                    :width="35"
                                    active-color="#3064F2"
                                    inactive-color="#7D8BB2"
                                    :title="isNativeQuery ? 'Advanced search ON' :'Advanced search OFF'">
                            </el-switch>
                        </div>
                    </div>
                </template>
            </PredicateEditor>

            <div class="facets-block">

                <SearchFacets v-model="tabToShow.result.facets"
                              ref="searchFacets"
                              from="searchProfile"
                              :filter="tabToShow.filter"
                              :selected-items="tabToShow.selected"
                              @selection-change="handleSelect">
                </SearchFacets>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Header from '../components/Header';
    import SearchFacets from '../components/search/SearchFacets.vue';
    import PredicateEditor from '../components/search/PredicateEditor';
    import uuid from "uuid";
    import axios from "axios";

    export default {
        name: 'SearchProfile',
        components: {Header, SearchFacets, PredicateEditor},
        data() {
            const user = this.$store.state.userInfo;
            return {
                tabToShow: {
                    facets: {},
                    result: {
                        facets: []
                    },
                    filter: {},
                    selected: []
                },
                counter: {
                    News: 0,
                    Science: 0,
                    ScienceOpen: 0,
                    ScienceCrossref: 0,
                    Patents: 0,
                    Companies: 0,
                    MarketSize: 0,
                    Investment: 0
                },
                loading: true,
                profileName: 'User search profile',
                filterQuery: '',
                isNativeQuery: false,
                profileActive: false,
                profileVisible: false,
                docType: "News",
                tabs: [
                    {
                        id: "News",
                        title: "News"
                    },
                    {
                        id: "Science",
                        title: "Science Pro",
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                    },
                    {
                        id: "ScienceOpen",
                        title: "Science Open",
                        availableForRole: 'OPEN_SCIENCE_SEARCH',
                    },
                    {
                        id: "ScienceCrossref",
                        title: "Science Crossref",
                        availableForRole: 'CROSSREF_SCIENCE_SEARCH',
                    },
                    {
                        id: "Patents",
                        title: "Patents"
                    },
                    {
                        id: "Companies",
                        title: "Companies",
                        availableForRole: "COMPANIES_SEARCH",
                    },
                    {
                        id: "MarketSize",
                        title: "Market size"
                    },
                    {
                        id: "Investment",
                        title: "Investments",
                        availableForRole: "COMPANIES_SEARCH",
                    },
                ].map(tab => {
                    tab.title = this.$t('searchMode.source.' + tab.id);
                    return tab;
                }),
                filters: [],
                facetFilters: {
                    News: {
                        facets: [],
                        query: {
                            query: '',
                        },

                    },
                    Science: {
                        facets: [],
                        availableForRole: 'PRIVATE_SCIENCE_SEARCH',
                        query: {
                            query: '',
                        },
                    },
                    ScienceOpen: {
                        facets: [],
                        availableForRole: 'OPEN_SCIENCE_SEARCH',
                        query: {
                            query: '',
                        },
                    },
                    ScienceCrossref: {
                        facets: [],
                        availableForRole: 'CROSSREF_SCIENCE_SEARCH',
                        query: {
                            query: '',
                        },
                    },
                    Patents: {
                        facets: [],
                        query: {
                            query: '',
                        },
                    },
                    Companies: {
                        facets: [],
                        availableForRole: "COMPANIES_SEARCH",
                        query: {
                            query: '',
                        },
                    },
                    MarketSize: {
                        facets: [],
                        query: {
                            query: '',
                        },
                    },
                    Investment: {
                        facets: [],
                        availableForRole: "COMPANIES_SEARCH",
                        query: {
                            query: '',
                        },
                        isNativeQuery: false
                    },

                },
            };
        },
        mounted() {
            // console.log('userInfo', this.userInfo);
            let self = this;
            self.tabToShow.filter = {
                types: [self.docType],
                count: 0,
                offset: 0,
                facets: {
                    requests: []
                }
            }
            self.$nextTick(() => {
                self.$store.commit('toggleShowSwMenu', false);
                self.$store.commit('toggleShowSwMenuBtn', false);
                self.refresh();
            })
            self.tabs = self.tabs.filter(tab=> {
                return !tab.availableForRole || this.userInfo.roles.includes(tab.availableForRole);
            })
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
            showNativeQuery(){
                return (this.userInfo.roles || []).includes('ADVANCED_SEARCH');
            }
        },
        methods: {
            refresh() {
                // console.log(this.docType);
                let self = this;
                self.tabs.forEach(it => {
                    self.facetFilters[it.id] = {
                        facets: [],
                        query: {
                            query: '',
                        },
                    }
                })
                // results.push(axios.get(self.$store.state.apiUrl + '/userSettings/get'));
                axios.get(self.$store.state.apiUrl + '/userSettings/get').then(resp => {
                    console.log('proFile: ', resp.data);
                    let _load = resp.data || {},
                        _filters = ((_load.defaultProfile || {}).restrictions || {}).filters || [],
                        profile = {};
                    // --- load profile ---
                    if (_load.defaultProfile) {
                        _.forEach(_filters, (it, i) => {
                            self.facetFilters[i].facets = it.facets;
                            self.facetFilters[i].query = it.query;
                        });
                        self.profileActive = _load.profileActive || false;
                        self.profileVisible = _load.profileVisible || false;
                        self.profileName = _load.defaultProfile.name;
                        self.tabToShow.selected = ((_filters[self.docType] || {}).facets || []).map(fas => {
                            fas.id = fas.type + fas.facetId;
                            return fas;
                        });
                        setTimeout(() => {
                            if (self.$refs.searchFacets) {
                                self.$refs.searchFacets.getFacets();
                            }
                        }, 500)
                        self.loading = false;
                        self.setFilterQuery(_filters[self.docType].query)
                    } else{
                        setTimeout(() => {
                            if (self.$refs.searchFacets) {
                                self.$refs.searchFacets.getFacets();
                            }
                        }, 500)
                        self.loading = false;
                    }

                })
            },
            saveProfile() {
                let self = this;
                self.$refs.predicate.getPredicate();
                let _filters = {}
                _.forEach(_.cloneDeep(self.facetFilters), (it, docType) => {
                    it.query = it.query && Object.keys(it.query).length ? it.query : {query: ''};
                    _filters[docType] = it;
                });
                let _query =
                    {
                        "defaultProfile": {
                            "name": self.profileName,
                            "restrictions": {
                                "filters": _filters,
                            }
                        },
                        "profileActive": self.profileActive,
                        "profileVisible": self.profileVisible
                    };
                console.log('_save query', _query);
                return self.$services.userSettings.save(_query).then(resp => {
                    // console.log(resp.data);
                    self.$message({
                        type: 'success',
                        message: 'Profile saved'
                    });
                })

            },
            setFilterQuery(query) {
                let self = this;
                if (query) {
                    self.filterQuery = (query.nativeQuery ? (query.nativeQuery.value || '') : query.query) || '';
                    self.isNativeQuery = !!(query && query.nativeQuery)
                }
            },
            handleSelect(selection) {
                // console.log('sel...', selection);
                this.facetFilters[this.docType].facets = selection.requests || [];
                // console.log('facetFilters', this.facetFilters);
                // this.saveProfile()
            },
            handleSwitchNativeQuery(state) {
                let self = this;
                if (state) {
                    self.facetFilters[self.docType].query = {
                        nativeQuery: {
                            value: self.filterQuery
                        }
                    }
                    delete self.facetFilters[self.docType].query.query
                } else {
                    self.facetFilters[self.docType].query = {
                        query: self.filterQuery
                    }
                    delete self.facetFilters[self.docType].query.nativeQuery
                }
            },
            changePredicate(p) {
                let self = this;
                if (self.isNativeQuery) {
                    self.facetFilters[self.docType].query = {nativeQuery: {value: p}};
                } else {
                    self.facetFilters[self.docType].query = {query: p};
                }
            },
            fireEvent(data) {
                this.firedData = {incEvent: data};
            },
            handleAdminPredicate() {
                this.firedData = {incEvent: 'handleSearchSource', data: this.adminPredicate}
            },
            handleChangeDocType(item) {
                let self = this;
                self.tabToShow.filter.types = [item];

                self.$nextTick(() => {
                    // self.setFilterQuery(self.facetFilters[item].query)
                    self.refresh();
                });
            },

        },
    }
</script>

<style lang="scss">
    .fst-SearchProfile {
        &.wrapper .content {
            display: block;
            padding: 5px 30px;
            overflow: auto;
        }

        .control-block {
            display: flex;
            align-items: center;

            .el-input {
                max-width: 300px;
            }

            + .el-menu {
                .el-tag {
                    height: 19px;
                    padding: 0 7px;
                    line-height: 19px;
                    margin-left: 5px;
                }
            }
        }

        .facets-block {
            width: 100%;
            min-height: 50vh;
            display: flex;
            align-items: flex-start;
            justify-content: space-around;

            > div {
                width: 100%;
            }

            .facet {
                flex: 1;
            }

        }

        .fst-SearchFacets {
            .el-collapse {
                display: flex;
                flex-wrap: wrap;

                > div {
                    flex-basis: 400px;
                    margin: 0 30px 20px 0;
                }
            }
        }

        .fst-PredicateEditor {
            padding: 15px 0;
            max-width: 650px;

            .queryFieldsLabel {
                display: none !important;
            }

            .autocomplete-dropdown {
                z-index: 300;
            }

            .editor-content-wrap .editor-suffix {
                padding: 0 15px;
            }

            .control-group .nativeSearch {
                position: absolute;
                right: 45px;
                top: 15px;
                z-index: 350;
                font-size: 15px;
                font-weight: 400;
                cursor: pointer;
                border-radius: 4px;
                color: $iq-color-grey-light;
                transition: all .25s ease;
                white-space: nowrap;
            }

        }
    }
</style>
