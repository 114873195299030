<template>
    <div class="fst-Home">
        <Search></Search>
    </div>
</template>

<script>
    import Search from "@/components/search/Search.vue";

    export default {
        name: "Home",
        components: {Search}
    }
</script>

<style lang="scss">
    .fst-Home {
        height: 100%;
    }
</style>