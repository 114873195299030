<template>
    <div class="iqp-context-menu" @mousedown="handleBodyClick">
        <IqpContextSubmenu :items="items" @clickItem="handleItemClick" :style="`left:${left}px; top:${top}px; display:block;`"/>
    </div>
</template>

<script>
    import IqpContextSubmenu from './Submenu';
    export default {
        name: 'IqpContextMenu',
        components: {IqpContextSubmenu},
        props: {
            items: {
                type: Array,
                default() {return [];}
            },
            left: Number,
            top: Number
        },
        methods: {
            handleItemClick(item) {
                this.$emit('clickItem', item);
            },
            handleBodyClick() {
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss" src="./styles.scss"></style>
