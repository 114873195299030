<template>
    <div class="widget-block">
        <div class="header">
            <div class="title">
                {{ $t('dashboard.Markets_evaluation') }}
                <div class="viewAll" @click="(e) => viewAll(e)">{{ $t('dashboard.viewAll') }}</div>
            </div>
        </div>
        <div class="chart-container"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <apexchart
                v-show="chartSeries.length"
                ref="chart"
                :options="chartOptions"
                :series="chartSeries"
                @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "Markets",
    props: {
        request: Object,
        chartOptions: Object,
    },
    data() {
        return {
            chartSeries: [],
            reQ: {
                chart: {},
                documents: {}
            },
            mode: 'sum',
            loading: true,
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        request(newVal) {
            if (newVal) {
                this.refresh();
            }
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let query = _.cloneDeep(self.request),
                ii = 0;

            delete query.widgets;
            delete query.filter.subfilters;
            query.widgets = [
                'markets'
            ];

            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                } catch (e) {
                    console.error(e);
                }
            }


            self.loading = true;

            self.reQ.chart = self.$services.analytics.fullView(query);

            self.reQ.chart.then(resp => {
                let _data = resp.data.market.filter(it => Object.keys(it.volume).length);


                //---------- Markets ----------
                self.chartSeries.forEach(it => {
                    it.data = [];
                })
                let _colors = [
                    // 'hsl(36,65%,78%)', // Orange2
                    '#C69245', // Orange2
                    '#61B768', // Green2
                    '#8599D0', // BlueBright
                    '#C46370', // Red2
                    '#BABABA'  // White2
                ];
                self.chartSeries = _data.map(it => {
                    let out = {}
                    out.name = _.upperFirst(it.name) + (it.cagr ? ' (CAGR: ' + self.$utils.roundX(it.cagr, 1) + '%)' : '');
                    out.data = [];
                    return out;
                })
                let _years = _.flatten(_.map(_data, 'volume').map((it, i) => {
                    return Object.keys(it);
                }));
                _data.forEach((line, ii) => {
                    for (let year = _.min(_years); year <= _.max(_years); year++) {
                        self.chartSeries[ii].data.push({
                            x: year,
                            y: line.volume[year] ? line.volume[year] : null,
                            parentUrl: line.parentUrl
                        });
                    }
                });
                let options = {
                    colors: _colors,
                    chart: {
                        type: 'line',
                        height: '420px',
                    },
                    markers: {
                        size: 5,
                        hover: {
                            size: 7
                        }
                    },
                    tooltip: {
                        enabled: true,
                        intersect: true,
                        shared: false,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                        horizontalAlign: 'left',
                    },
                    stroke: {
                        width: 4,
                        curve: 'straight'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            align: 'right',
                            style: {
                                fontSize: '14px'
                            },
                            formatter: function (val, index) {
                                // console.log('ind', index);
                                return '$' + self.$utils.toFinHuman(val, 0).number;
                            }
                        },
                    },
                }
                if (self.$refs && self.$refs.chart) {
                    self.$refs.chart.updateOptions(options)
                }


                self.loading = false;
                // console.log('-- series Markets: ', self.chartSeries);
            }).catch(e => {
                console.log(e);
            });
        },
        dataPointSelectionHandler(e, chartContext, config) {
            let self = this;
            let _data = self.chartSeries[config.seriesIndex].data[config.dataPointIndex];
            // keyword = self.columnsSeries.data[keyIndex].x;
            console.log('conf', _data);
            if(e.button === 0 && _data.parentUrl) {
                window.open(_data.parentUrl, '_blank');
            }

            },
        viewAll(e){
            let self = this;
            let years = [],
                docType = "MarketSize";
            let facets = {}
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
            }
            if(e.button === 0) {
                self.$utils.openSearch(params);
            }

        },
        createYaxis(series) {
            let self = this;
            return series.map((it, i) => {
                let ser = {};
                ser.seriesName = it.name;
                if (it.yaxis === 'opposite') {
                    ser.opposite = true;
                }
                ser.axisTicks = {show: true};
                ser.axisBorder = {show: true, color: it.color};
                ser.labels = {
                    style: {
                        colors: it.color
                    },
                    formatter: function (val, index) {
                        return it.suffix ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val, 0);
                    }
                };
                ser.title = {
                    text: it.title,
                    style: {
                        color: it.color,
                        fontSize: 15,
                        fontWeight: 500
                    }
                };
                return ser;
            });
        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">

</style>