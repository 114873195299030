<template>
    <el-tooltip effect="light" content="Export to Image" :open-delay="404" placement="top">
        <i class="fas exportBtn" :class="loading? 'fa-spin fa-spinner-third' : 'fa-save'"
           @click="e => export2Img(e)"></i>
    </el-tooltip>
</template>

<script>

    export default {
        name: "ExportToImage",
        props: {
            selector: String,
            parent: {
                type: String,
                default: '.widget-block'
            },
            filename: String,
        },
        data() {
            return {
                loading: false,
            }
        },
        computed: {},
        methods: {
            export2Img(e) {
                let self = this,
                    _target = e.target,
                    _selector = _target.closest(self.parent).querySelector(self.selector);
                self.loading = true;
                self.$emit('startExport');
                self.$utils.exportToPng(_selector, self.filename).then(() => {
                    setTimeout(() => {
                        self.loading = false;
                        self.$emit('finishExport');
                    }, 1001);
                })
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>