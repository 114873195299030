import Vue from 'vue';
import MathJax from '@/commons/components/MathJax.vue'

export default Vue.component('DocumentText', {
    props: {
        text: String,
        docType: String,
        hostingName: String
    },
    render(h, context) {
        if(this.docType !== 'Science' && this.docType !== 'ScienceOpen') {
            return h('span', {domProps: {innerHTML: this.text}});
        }
        if(this.hostingName === 'Springer') {
            return h(MathJax, {props: {text: this.text, inlineMath: [['\\(', '\\)'],['$', '$']], displayMath: [['$$', '$$']]}});
        }
        return h(MathJax, {props: {text: this.text}});
    }
});
