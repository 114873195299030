<template>
    <div class="fst-CopyToClipboard" :title="$t('copyToClipboard.title')">
        <div class="mb-3" v-if="beforeText" v-html="beforeText"></div>
        <div class="text-copy" v-html="text" @click="handleCopy"></div>
    </div>
</template>

<script>
    export default {
        name: "CopyToClipboard",
        props: ['text', 'beforeText', 'successMessage'],
        methods: {
            handleCopy() {
                this.$copyText(this.text).then(e => {
                    this.$message({message: this.successMessage || this.$t('copyToClipboard.success'), showClose: true});
                });
            }
        }
    }
</script>

<style lang="scss">
    @import "assets/imports";

    .fst-CopyToClipboard {
        .text-copy {
            position: relative;
            border: 1px solid $iq-border-color-lighter;
            padding: 15px 35px 15px 15px;
            border-radius: 4px;
            background-color: $iq-background-color-extra-light;
            cursor: pointer;
            word-wrap: break-word;
            transition: all 0.2s linear;
            &:after {
                position: absolute;
                top: 10px;
                right: 10px;
                font-family: 'Font Awesome 5 Pro';
                content: "\f0c5";
                color: $iq-color-primary;
            }
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.04) 0px 0px 6px;
            }
        }
    }
</style>
