<template>
    <div class="fst-EditUserNotifications" v-if="userIsAdmin">
        <el-card class="box-card" :class="status">
            <div slot="header" class="clearfix">
                <div class="h3">
                    <template v-if="status"><i class="far fa-clock mr-1"></i> Edit notification ({{ timeLeftFormatted }})</template>
                    <template v-else><i class="far fa-plus mr-1"></i> Add new notification</template>
                </div>
            </div>
            <el-row :gutter="10">
                <el-col :span="24" class="mb-3">
                    <label>Expiration date</label>
                    <el-date-picker
                        v-model="notification.expirationDate"
                        type="datetime"
                        format="dd.MM.yyyy HH:mm:ss"
                        clearable/>
                    <div class="timeLeft" v-if="status==='edit'"></div>
                </el-col>
                <el-col :span="6">
                    <label>Title</label>
                    <el-input v-model="notification.title" type="textarea"
                              @focus="$event.target.select()"></el-input>
                </el-col>
                <el-col :span="6">
                    <label>Description</label>
                    <el-input v-model="notification.description" type="textarea"
                              @focus="$event.target.select()"></el-input>
                </el-col>
                <el-col :span="6">
                    <label>Expiration text (optional)</label>
                    <el-input v-model="notification.expirationText" type="textarea"
                              @focus="$event.target.select()"></el-input>
                </el-col>
                <el-col :span="4" style="padding-top: 18px;">
                    <el-button class="justify-content-center w-100" type="primary"
                               v-if="!status"
                               @click="saveNotification">+ Add
                    </el-button>
                    <el-button type="success"
                               v-if="status"
                               @click="saveNotification"><i class="far fa-edit"></i>
                    </el-button>
                    <el-button type="danger"
                               v-if="status"
                               @click="saveNotification('clear')"><i class="far fa-trash-alt"></i>
                    </el-button>
                </el-col>
            </el-row>

        </el-card>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import axios from 'axios';

export default {
    name: "UserNotifications",
    props: {},
    data() {
        return {
            dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZZ',
            title: '+ Add new notification',
            status: '',
            timerId: 0,
            timeLeft: 0,
            timeLeftFormatted:'',
            userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
            notification: {},
            notificationDefault: {
                expirationDate: moment().add(5, 'm').format(),
                title: "Teqviser will start updating in \n" +
                    '<span class="big">{duration}</span> minutes',
                description: "<p>Please, save all your work.</p>\n" +
                    '<p>System will not be available for 3-5 minutes.</p>',
                expirationText: 'System update in progress...',
            }
        }
    },
    computed: {},
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            axios.get( this.$store.state.apiUrl + '/systemAlert/getAlert').then(resp => {
                // console.log('Get Resp:', resp.data);
                if (resp.data) {
                    _.merge(this.notification,resp.data);
                    this.notification.expirationDate = moment(this.notification.expirationDate, this.dateFormat).format();
                    this.status = 'edit';
                    this.timeLeft =  moment(this.notification.expirationDate).format('X') - moment().format("X");
                    this.countdown();
                } else {
                    this.notification = _.clone(this.notificationDefault);
                    this.status = '';
                }
            });
        },
        countdown(){
            if(this.timeLeft === 0 || this.timeLeft < 600){
                this.timeLeft =  moment(this.notification.expirationDate).format('X') - moment().format("X");
                this.timeLeftFormatted = moment(this.timeLeft, 'X').format('mm:ss')
                this.timerId = setTimeout(()=> this.countdown(), 1000);
            } else {
                this.notification = _.clone(this.notificationDefault);
                this.status = '';
                clearTimeout(this.timerId);
            }
        },
        saveNotification(action) {
            if(this.notification.expirationDate < moment().format()) this.notification.expirationDate = moment().add(10, 'm').format(this.dateFormat);
            let _query = {
                title: (action === "clear") ? '' : this.notification.title,
                description: (action === "clear") ? '' : this.notification.description,
                expirationDate: (action === "clear") ? moment().add(-1, 'days').format(this.dateFormat) : moment(this.notification.expirationDate).format(this.dateFormat),
                expirationText: (action === "clear") ? '' : this.notification.expirationText
            }
            // console.log('query:', _query);
            this.$services.systemAlert.setAlert(_query).prepare().then((resp) => {
                this.refresh();
            })
        },
        handleDateFocus(){
            if(status !== 'edit'){
                this.notification.expirationDate = moment().add(10, 'm').format();
            }
        }
    }
}
</script>

<style lang="scss">
.fst-EditUserNotifications {
    .edit .el-card__header {
        background: #67c23a;
        color: #fff;
    }
    .el-card__header {
        font-size: 18px;
        font-weight: 500;
    }
    label {
        display: block;
        margin-bottom: 3px;
        font-weight: 500;
        font-size: 13px;
    }

    textarea {
        height: 70px;
    }

    .expiresAt {
        font-size: 13px;
    }
}
</style>