<template>
    <el-dialog
        title="Add/Edit Pattern"
        custom-class="dialog-custom"
        :visible="true"
        @close="close"
        width="40%">
        <div class="fst-EditPattern">

            <el-radio-group v-model="pattern.docType" class="iq-radio">
                <el-radio-button v-for="type in docTypes" :label="type.type" :key="type.type">{{ type.title }}
                </el-radio-button>
            </el-radio-group>

            <div class="group-control">
                <div class="label">Pattern name</div>
                <el-input v-model="pattern.name" placeholder="Some kind of pattern name"></el-input>
            </div>
            <div class="group-control mb-4">
                <div class="label">
                    <div>Semantic rules</div>
                    <div>
                        <button class="btn success-light" @click="insertUsersQuery">
                            <i class="far fa-plus mr-1"></i>
                            user's search query
                        </button>
                    </div>
                </div>
                <PredicateEditor v-model="pattern.template"
                                 @apply="handlePredicate"
                                 from="adminPattern"
                                 :notValidate="true"
                                 :showApplyButton="false"
                                 :savedQueriesList="[]"
                                 ref="predicate"
                />
            </div>
            <div class="group-control mb-200">
                <div class="label">
                    <div>On empty user's query</div>
                </div>
                <PredicateEditor v-model="pattern.defaultQuery"
                                 @apply="handlePredicate"
                                 from="adminPattern"
                                 :showApplyButton="false"
                                 :savedQueriesList="[]"
                                 ref="predicateDefault"
                />

            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="close">Cancel</el-button>
            <el-button type="primary" @click="savePattern">Save</el-button>
        </div>
    </el-dialog>
</template>

<script>
import PredicateEditor from '../../search/PredicateEditor.vue';
import {mapState} from 'vuex';

export default {
    name: 'EditPattern',
    components: {PredicateEditor},
    props: {
        pattern: {
            type: Object,
            default: () => ({})
        },
        close: {
            type: Function
        }
    },
    data() {
        return {
            docTypes: [
                {type: 'News', title: 'News'},
                {type: 'Science', title: 'Science'},
                {type: 'ScienceOpen', title: 'Science Open'},
                {type: 'Patents', title: 'Patents'},
                {type: 'Companies', title: 'Companies'}
            ]
        };
    },
    created() {
        // this.pattern.docType = this.pattern.docType ? this.pattern.docType : "News";
        console.log('pattt::', this.pattern);
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            notionsDict: state => state.notions,
        })
    },
    watch: {},
    methods: {
        savePattern() {
            this.$refs.predicate.apply();
            this.$refs.predicateDefault.apply();
            let _query = {
                name: this.pattern.name,
                docType: this.pattern.docType,
                template: this.pattern.template,
                defaultQuery: this.pattern.defaultQuery,
                id: this.pattern.id || null
            };
            console.log('Query:::', _query);
            this.$services.patterns.save(_query).prepare().then(resp => {
                console.log('Resp:', resp);
                this.$emit('updateList');

            });

        },
        insertUsersQuery() {
            // this.pattern.template += ' <span class="userQuery" data-id="query">user\'s query </span> ';
            this.$refs.predicate.insertNotion({name: 'User\'s query', id: 'query'});
        },
        handlePredicate() {
        }

    },
    destroyed() {

    }
}
</script>

<style lang="scss">
.fst-EditPattern {
    padding: 20px;

    .group-control {
        margin: 15px 0;

        .label {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 3px;
        }

        .el-input input {
            padding: 12px 20px;
            line-height: 25px;
            height: 50px;
            font-size: 15px;
        }

        .btn {
            @include iq-button-sm;
            font-variant: all-small-caps;
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;

        }
    }

    .query {
        border: 1px solid $iq-color-blue-light;
        border-radius: 5px;
        min-height: 50px;
        max-height: 150px;
        overflow-y: auto;
        line-height: 30px;
        padding: 9px 15px;
        box-sizing: border-box;

        &:focus {
            border: 1px solid $iq-color-blue;
            outline: none;
        }

        .notion {
            @include iq-button-sm;
            margin-right: 3px;
        }

        .usersQuery {
            @include iq-button-sm;
        }

    }

    .editor-suffix .clear {
        margin-right: 15px;
    }

    .fst-PredicateEditor .autocomplete-wrap{
        z-index: 200;
    }
}
</style>
<style lang="scss" scoped>
</style>
