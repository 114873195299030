import { render, staticRenderFns } from "./Wordstat.vue?vue&type=template&id=6217e69a&scoped=true&"
import script from "./Wordstat.vue?vue&type=script&lang=js&"
export * from "./Wordstat.vue?vue&type=script&lang=js&"
import style0 from "./Wordstat.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Wordstat.vue?vue&type=style&index=1&id=6217e69a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6217e69a",
  null
  
)

export default component.exports