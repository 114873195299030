<template>
    <div class="widget-block" :class="params.widgetClass"
         v-loading="loading">
        <div class="header">
            <div class="title">
                {{ title }}
                <div class="viewAll"
                     v-if="this.$store.getters.swList.mode === 'all'"
                     @click="viewAll()">{{ $t('dashboard.viewAll') }}
                </div>
            </div>
            <div class="widgetMode">
                <span
                        @click="handleWidgetModeChange('chart')"
                        :class="{active: widgetMode === 'chart'}">
                    <el-tooltip effect="light" placement="top" content="Graph" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                    fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
                <el-tooltip effect="light" placement="top" content="Companies list" :open-delay="600">
                    <span @click="handleWidgetModeChange('companies')"
                          :class="[{active: widgetMode === 'companies'}]">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                  fill="currentColor"/>
                        </svg>
                    </span>
                </el-tooltip>
                <export-to-image :filename="title" selector=".chart-container"></export-to-image>
            </div>
        </div>
        <div class="chart-container" v-show="chartSeries.length && widgetMode === 'chart'">
            <apexchart
                    v-if="chartOptionsLoc.chart"
                    ref="chart"
                    :options="chartOptionsLoc"
                    :series="chartSeries"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
        </div>
        <div class="cards" v-show="companies.length && widgetMode === 'companies'"
             v-loading="loading">
            <el-popover
                    v-for="(item, key) in companies"
                    placement="top-start"
                    width="400"
                    popper-class="investDesc"
                    :key="key"
                    :open-delay="1250"
                    :close-delay="300"
                    trigger="hover">
                <div>
                    <div class="desc" v-html="item.description"></div>
                    <div class="tags"
                         v-show="item.systemKeywords && item.systemKeywords.length">
                        <div v-for="(tag, tk) in item.systemKeywords" :key="tk">{{ tag }}</div>
                    </div>
                </div>
                <div slot="reference">
                    <div class="card" @click="handleOpenCompanyCard(item)">
                        <div class="logo">
                            <img :src="item.urlLogo" alt="Company Logo" onerror="this.src='noLogo3.png'">
                        </div>
                        <div class="text">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="investmentTotal" v-if="item.investmentTotal">Investment total:
                                ${{ item.investmentTotal }}
                            </div>
                            <div class="link" v-else><a :href="item.url" target="_blank">{{ item.urlFormatted }}</a>
                            </div>
                            <div class="attr">{{ item.attr }}</div>
                        </div>
                    </div>
                </div>
            </el-popover>
        </div>

        <div class="noResult" v-show="!chartSeries || !chartSeries.length">
            No Result
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWCompanyCountries",
        components: {ExportToImage},
        props: {
            filter: Object,
            chartOptions: Object,
            title: String,
            years: Object,
            params: {
                type: Object,
                default: () => {
                }
            }

        },
        data() {
            return {
                loading: true,
                widgetMode: 'chart',
                curYear: new Date().getFullYear(),
                chartSeries: [],
                companies: [],
                showOther: false,
                reQ: {
                    chart: {},
                    documents: {}
                },
                chartOptionsLoc: {}
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            document.removeEventListener('click', (e) => {
            });
            document.addEventListener('click', (e) => {
                if (e.target && e.target.parentElement.matches('.apexcharts-legend-series:first-child')) {
                    // console.log('!!!!', e.target)
                    this.showOther = !this.showOther;
                }
            });
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let promises = [];
                let widgetFilter = _.cloneDeep(self.filter);
                if (self.params.docType) {
                    widgetFilter.types = [self.params.docType]
                }

                let query = {
                    "filter": widgetFilter,
                    "widgets": {
                        "chart": {
                            "type": self.params.type,
                            "field": self.params.field,
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        }
                    }
                };
                let textQuery = {
                    count: 10,
                    offset: 0,
                    duplicate: false,
                    explain: false,
                    facets: {
                        calculate: false,
                        "requests": [{
                            type: "YEAR",
                            values: self.years.years
                        }],
                    },
                    order: [{
                        "field": "year",
                        "type": "DESC"
                    }, {
                        "field": "investmentTotal",
                        "type": "DESC"
                    }],
                    profile: false,
                    query: self.filter.query,
                    nativeQuery: self.filter.nativeQuery,
                    semantic: false,
                    spam: false,
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    suggestQuery: false,
                    types: ["Companies"],
                }
                _.forEach(self.reQ, req => {
                    if (req.then) {
                        try {
                            req.cancel();
                        } catch (e) {
                            console.error(e);
                        }
                    }
                })

                self.loading = true;

                if (self.widgetMode === 'chart') {
                    self.reQ.chart = self.$services.analytics.widgets(query);
                    self.reQ.chart.then(resp => {
                        let _data = {},
                            _total = resp.data.widgets.chart.counts;
                        _.forEach(resp.data.widgets.chart.termsByYear, (it, yr) => {
                            if (yr <= self.curYear) {
                                _data[yr] = it.slice(0, self.params.count);
                            }
                            let _sum = _.reduce(_data[yr], (sum, count) => {
                                return sum + parseInt(count.docCount);
                            }, 0);
                            _data[yr].unshift({value: 'Other', docCount: (_total[yr] - _sum)});
                        })
                        let _countries = _.uniq(_.flatten(_.map(_data, (it) => {
                                return _.map(it, 'value');
                            }))),
                            ii = 0;

                        // console.log('-- Data 4 Chart: ', resp.data.widgets.chart);
                        // console.log('-- Countries: ', _countries);


                        // -------------- Foundation YEARS ---------------
                        self.chartSeries = [];
                        _countries.forEach(country => {
                            self.chartSeries[ii] = {
                                type: 'bar',
                                name: _.upperFirst((country || '').toString()),
                                data: [],
                            }
                            // for (let year = _dateFrom; year <= self.curYear; year++) {
                            self.years.years.forEach(year => {

                                self.chartSeries[ii].data.push(
                                    _data[year] ? parseInt((_data[year].find(it => it.value === country) || {}).docCount || 0) || 0 : 0
                                );
                                // if (_data[year] && _data[year][country]) {
                                // self.chartSeries[ii].data.push({
                                //     x: year,
                                //     y: _data[year] && _data[year] ? parseInt(_data[year][country]) || 0 : 0
                                // });
                                // }
                            });
                            ii++;

                        })

                        self.chartOptionsLoc = _.cloneDeep(self.chartOptions);
                        self.chartOptionsLoc.chart.type = 'bar';
                        self.chartOptionsLoc.chart.stacked = true;
                        self.chartOptionsLoc.legend = {
                            show: true,
                            showForSingleSeries: true,
                            position: 'bottom',
                            horizontalAlign: 'left',
                            fontSize: '14px',
                            labels: {
                                colors: '#B2B2B2',
                                useSeriesColors: true
                            },
                            itemMargin: {
                                horizontal: 15,
                                vertical: 5
                            },

                        };
                        self.chartOptionsLoc.colors = [
                            'hsl(231,5%,62%)',
                            '#003f5c',
                            '#2f4b7c',
                            '#665191',
                            '#a05195',
                            '#d45087',
                            '#f95d6a',
                            '#ff7c43',
                            '#ffa600',
                            'hsl(231,37%,52%)',
                            'hsl(180,99%,28%)',
                            'hsl(155,96%,33%)',
                            'hsl(99,49%,41%)',
                            'hsl(38,95%,54%)',
                            'hsl(29,77%,43%)',
                            'hsl(358,82%,41%)',
                            'hsl(310,65%,40%)',
                            '#5d6d63',
                            'hsl(258,30%,56%)',
                            'hsl(205,97%,36%)',
                        ];
                        self.chartOptionsLoc.dataLabels = {
                            enabled: false,
                        };
                        self.chartOptionsLoc.yaxis = {
                            axisBorder: {
                                show: false,
                                color: '#b2b2b2'
                            },
                            labels: {
                                show: true,
                                align: 'right',
                                style: {
                                    fontSize: '14px',
                                    // colors: '#b2b2b2'
                                },
                                formatter: function (val, index) {
                                    return self.$utils.toFin(val, 0);
                                }
                            },
                        };

                        self.chartOptionsLoc.xaxis.range = undefined;
                        self.chartOptionsLoc.xaxis.type = null;
                        self.chartOptionsLoc.xaxis.categories = _.cloneDeep(self.years.years);
                        self.chartOptionsLoc.tooltip = {
                            enabled: true,
                            shared: false,
                            y: {
                                formatter: function (val, opts) {
                                    return self.$utils.toFin(val);
                                },
                            },
                        };
                        self.chartOptionsLoc.stroke = {
                            width: 4,
                            curve: 'straight'
                        };

                        self.chartOptionsLoc.markers = {
                            size: 5,
                            hover: {
                                size: 7
                            }
                        }

                        // self.chartSeries = _.cloneDeep(self.chartSeries);

                        self.$nextTick(() => {
                            if (self.$refs.chart) {
                                self.$refs.chart.updateOptions({
                                    chart: {
                                        height: '380px',
                                    },
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '20%',
                                            distributed: false,
                                        }
                                    },
                                    stroke: {
                                        width: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
                                        colors: ['#FFF'],
                                        curve: 'stepline'
                                    }
                                });
                            }

                            self.loading = false;
                            // console.log('-- series CompCountries: ', self.chartSeries);
                        });

                        // ---- Hide first column ---
                        setTimeout(() => {
                            if (!this.showOther) {
                                const legendItem = document.querySelector("[name='Company_foundation'] .apexcharts-legend-series:nth-child(1) .apexcharts-legend-text")
                                legendItem.dispatchEvent(new Event('click'))
                            }
                        }, 10);

                    }).catch(e => {
                        console.log(e);
                    });


                } else {
                    self.reQ.documents = self.$services.documents.search(textQuery);
                    self.reQ.documents.then(resp => {
                        let _companies = resp.data.list;
                        self.companies = self.createCompanyCards(_companies || []);
                        self.loading = false;
                    })


                }
            },
            createYaxis(series) {
                let self = this;
                return series.map((it, i) => {
                    let ser = {};
                    ser.seriesName = it.name;
                    if (it.yaxis === 'opposite') {
                        ser.opposite = true;
                    }
                    ser.axisTicks = {show: true};
                    ser.axisBorder = {show: true, color: it.color};
                    ser.labels = {
                        style: {
                            colors: it.color
                        },
                        formatter: function (val, index) {
                            return it.suffix ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val, 0);
                        }
                    };
                    ser.title = {
                        text: it.title,
                        style: {
                            color: it.color,
                            fontSize: 15,
                            fontWeight: 500
                        }
                    };
                    return ser;
                });
            },
            createCompanyCards(items) {
                let self = this;
                return items.slice(0, 8).map(company => {
                    let it = company.investor ? company.investor
                        : company.investee ? company.investee
                            : company;
                    let countriesFormat = (it.countries || []).join('<i class="divider"></i>'),
                        founded = _.compact([countriesFormat, it.year]).join(', ');
                    it.urlLogo = (it.icon && it.icon.url && !it.icon.url.includes('placeholder_')) ? it.icon.url.replace('f_jpg', 'f_auto,b_white,q_auto:eco') : 'noLogo3.png';
                    it.urlFormatted = it.url ? it.url.split('/')[2].replace('www.', '') : '';
                    it.investmentTotal = company.investor ? self.$utils.toFinHuman(company.investmentTotal, 1).number : null;
                    it.attr = founded;
                    return it;
                });

            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this,
                    country = ((config.w.config.series[config.seriesIndex] || {}).name || '').toLowerCase(),
                    year = self.years.years[config.dataPointIndex].toString();
                // console.log('conf', keyword, ' ---->', config.w.config.series[config.seriesIndex].name);
                if (e.button === 0) {
                    self.viewAll(country, year)
                }
            },

            viewAll(country = null, year = null) {
                // console.log('keyword:', keyword);
                let self = this;
                let docType = 'Companies';
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

                let facets = {
                    requests: [{
                        type: yearType,
                        values: year ? [year] : self.years.years
                    }]
                };
                if (country && country !== 'other') {
                    facets.requests.push({
                        type: 'COUNTRIES',
                        values: [country]
                    })
                }
                // console.log('Facets', facets, self.filter);
                let params = {
                    query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: docType,
                    orderBy: 'date',
                    facets: facets,
                    spam: false,
                    duplicate: 'false'
                }
                self.$utils.openSearch(params);

            },
            handleOpenCompanyCard(item) {
                if (item.id) {
                    window.sessionStorage.removeItem('companyCard');
                    window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
                } else {
                    console.log('--- No company Id. Investor - person? --- ');
                }
            },

            handleWidgetModeChange(mode) {
                this.widgetMode = mode;
                this.refresh();

            },
            reload() {
                this.$nextTick(() => {
                    this.$refs.chart.updateOptions({})
                });
            },

        }
    }
</script>

<style lang="scss">

</style>

<style scoped lang="scss">

</style>