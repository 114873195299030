<template>
    <div class="widget-block">
        <div class="header">
            <div class="title">
                {{ $t('dashboard.Venture_stages') }}
                <div class="viewAll" @click="viewAll()">{{ $t('dashboard.viewAll') }}</div>
            </div>
            <el-radio-group v-model="mode" @change="refresh">
                <el-radio-button label="count">{{ $t('dashboard.count') }}</el-radio-button>
                <el-radio-button label="sum">{{ $t('dashboard.sum') }}</el-radio-button>
            </el-radio-group>

        </div>
        <div class="chart-container"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <apexchart
                v-show="chartSeries.length"
                ref="chart"
                :options="chartOptions"
                :series="chartSeries"
                @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>

        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "VentureStages",
    props: {
        chartOptions: Object,
        ventureSubtypeFacets: Array,
        request: Object,
    },
    data() {
        return {
            loading: false,
            mode: 'count',
            subtypes: [],
            reQ: {
                chart: {},
                documents: {}
            },
            chartSeries: [{
                type: 'column',
                name: 'Seed/Pre-seed/Angel',
                title: 'Seed/Pre-seed/Angel',
                data: [],
                color: '#61B768',
            }, {
                type: 'column',
                name: 'A+ rounds',
                title: 'A+ rounds',
                data: [],
                color: 'hsl(224, 44%, 67%)',
            }],
            seedStages: [
                'seed',
                'angel',
                'pre_seed',
                'convertible_note',
                'product_crowdfunding',
            ],
            excludeStages: [
                'undisclosed',
                'grant',
                'initial_coin_offering',
                'non_equity_assistance',
                'equity_crowdfunding',
                'debt_financing',
                'post_ipo_equity',
                'post_ipo_debt',
                'post_ipo_secondary',
                'secondary_market',
            ]

        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        request() {
            this.refresh();
        },
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        refresh() {
            let self = this;
            let query = _.cloneDeep(self.request)

            delete query.widgets;
            query.widgets = [
                'ventureStages',
            ];
            if (self.reQ.chart.then) {
                try {
                    self.reQ.chart.cancel();
                    // self.reQ.documents.cancel();
                } catch (e) {
                    console.error(e);
                }
            }
            self.loading = true;
            self.reQ.chart = self.$services.analytics.fullView(query);

            self.reQ.chart.then(resp => {
                let _data = resp.data;

                //---------- Venture Stages ----------
                self.chartSeries.forEach(it => {
                    it.data = [];
                })

                _.orderBy(_data.ventureSeed, 'value').forEach(it => {
                    self.chartSeries[0].data.push({
                        x: it.value,
                        // y: self.$utils.roundX(it.sum / Math.pow(10, (_max.toString().length - 4)), 2)
                        y: self.mode === 'sum' ? it.sum : it.count
                    });
                });

                _.orderBy(_data.ventureOther, 'value').forEach(it => {
                    self.chartSeries[1].data.push({
                        x: it.value,
                        // y: self.$utils.roundX(it.sum / Math.pow(10, (_max.toString().length - 4)), 2),
                        y: self.mode === 'sum' ? it.sum : it.count
                    });
                });
                let options = {
                    chart: {
                        height: '380px',
                    },
                    stroke: {
                        width: [3, 3],
                        colors: ['#191919'],
                    },
                    legend: {
                        show: true
                    },
                    tooltip: {
                        enabled: true,
                        intersect: true,
                        shared: false,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '45%',
                        },
                    },
                    yaxis: [{
                        labels: {
                            style: {
                                fontSize: '14px'
                            },
                            formatter: function (val, index) {
                                return self.mode === 'sum' ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val);
                            }
                        },
                    }]
                }
                if (self.$refs && self.$refs.chart) {
                    self.$refs.chart.updateOptions(options)
                }

                self.loading = false;
                // console.log('-- series VStages: ', self.chartSeries);
            }).catch(e => {
                console.log(e);
            });
        },
        dataPointSelectionHandler(e, chartContext, config) {
            let self = this;
            let year = (self.chartSeries[config.seriesIndex].data[config.dataPointIndex].x || '').toString(),
                docType = "Investment";
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
            let subtypes = config.seriesIndex ? self.ventureSubtypeFacets.filter(it => (!self.excludeStages.includes(it)
                && !self.seedStages.includes(it)))
                : self.seedStages;
            let facets = {
                requests: [
                    {
                        type: yearType,
                        values: [year]
                    }, {
                        type: "INVT_TYPE",
                        values: ['venture']
                    }, {
                        type: "INVT_SUBTYPE",
                        values: subtypes
                    }
                ]
            }
            // console.log('Facets', facets, self.request.filter);
            if (e.button === 0) {
                self.viewAll(facets);
            }
        },
        viewAll(facets = null) {
            let self = this;
            let years = [],
                docType = "Investment";
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                years.push(year.toString());
            }
            let subtypes = self.ventureSubtypeFacets.filter(it => !self.excludeStages.includes(it));

            facets = facets ? facets : {
                requests: [{
                    type: yearType,
                    values: years
                }, {
                    type: 'INVT_TYPE',
                    values: ['venture']
                }, {
                    type: "INVT_SUBTYPE",
                    values: subtypes
                }]
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
            }
            self.$utils.openSearch(params);

        },
    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">

</style>