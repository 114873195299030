<template>
    <div class="widget-block">
        <div class="header">
            <div class="title">
                {{ title }}
                <div class="viewAll" @click="viewAll()">{{ $t('dashboard.viewAll') }}</div>
            </div>
            <div class="widgetMode">
                <span
                    @click="handleWidgetModeChange('chart')"
                    :class="{active: widgetMode === 'chart'}">
                    <el-tooltip effect="dark" content="Graph" :open-delay="600" placement="top">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.33333 18.3333H2.83333C2.61232 18.3333 2.40036 18.2455 2.24408 18.0893C2.0878 17.933 2 17.721 2 17.5V10.8333C2 10.6123 2.0878 10.4004 2.24408 10.2441C2.40036 10.0878 2.61232 10 2.83333 10H5.33333C5.55435 10 5.76631 10.0878 5.92259 10.2441C6.07887 10.4004 6.16667 10.6123 6.16667 10.8333V17.5C6.16667 17.721 6.07887 17.933 5.92259 18.0893C5.76631 18.2455 5.55435 18.3333 5.33333 18.3333ZM11.1667 18.3333H8.66667C8.44565 18.3333 8.23369 18.2455 8.07741 18.0893C7.92113 17.933 7.83333 17.721 7.83333 17.5V3.33333C7.83333 3.11232 7.92113 2.90036 8.07741 2.74408C8.23369 2.5878 8.44565 2.5 8.66667 2.5H11.1667C11.3877 2.5 11.5996 2.5878 11.7559 2.74408C11.9122 2.90036 12 3.11232 12 3.33333V17.5C12 17.721 11.9122 17.933 11.7559 18.0893C11.5996 18.2455 11.3877 18.3333 11.1667 18.3333ZM17 18.3333H14.5C14.279 18.3333 14.067 18.2455 13.9107 18.0893C13.7545 17.933 13.6667 17.721 13.6667 17.5V8.33333C13.6667 8.11232 13.7545 7.90036 13.9107 7.74408C14.067 7.5878 14.279 7.5 14.5 7.5H17C17.221 7.5 17.433 7.5878 17.5893 7.74408C17.7455 7.90036 17.8333 8.11232 17.8333 8.33333V17.5C17.8333 17.721 17.7455 17.933 17.5893 18.0893C17.433 18.2455 17.221 18.3333 17 18.3333Z"
                                fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
                <span
                    @click="handleWidgetModeChange('documents')"
                    :class="[{active: widgetMode === 'documents'}]">
                    <el-tooltip effect="dark" placement="top" content="Deals" :open-delay="600">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M3 4.5C2.44772 4.5 2 4.94772 2 5.5V6.5C2 7.05228 2.44772 7.5 3 7.5H4C4.55228 7.5 5 7.05228 5 6.5V5.5C5 4.94772 4.55228 4.5 4 4.5H3ZM8 4.5C7.44772 4.5 7 4.94772 7 5.5V6.5C7 7.05228 7.44771 7.5 8 7.5H17C17.5523 7.5 18 7.05228 18 6.5V5.5C18 4.94772 17.5523 4.5 17 4.5H8ZM2 10.5C2 9.94772 2.44772 9.5 3 9.5H4C4.55228 9.5 5 9.94772 5 10.5V11.5C5 12.0523 4.55228 12.5 4 12.5H3C2.44772 12.5 2 12.0523 2 11.5V10.5ZM8 9.5C7.44772 9.5 7 9.94772 7 10.5V11.5C7 12.0523 7.44771 12.5 8 12.5H17C17.5523 12.5 18 12.0523 18 11.5V10.5C18 9.94772 17.5523 9.5 17 9.5H8ZM2 15.5C2 14.9477 2.44772 14.5 3 14.5H4C4.55228 14.5 5 14.9477 5 15.5V16.5C5 17.0523 4.55228 17.5 4 17.5H3C2.44772 17.5 2 17.0523 2 16.5V15.5ZM8 14.5C7.44772 14.5 7 14.9477 7 15.5V16.5C7 17.0523 7.44771 17.5 8 17.5H17C17.5523 17.5 18 17.0523 18 16.5V15.5C18 14.9477 17.5523 14.5 17 14.5H8Z"
                                  fill="currentColor"/>
                        </svg>
                    </el-tooltip>
                </span>
            </div>
        </div>
        <div class="axisLabels" v-show="gotData && widgetMode === 'chart'">
            <div :style="`color:${chartSeries[0].color}`">{{ $t('dashboard.totalDollar') }} <span v-if="cagr"> (CAGR: {{ cagr }})</span></div>
            <div :style="`color:${chartSeries[1].color}`">{{ $t('dashboard.dealCount') }}</div>
        </div>
        <div class="chart-container"
             v-show="gotData && widgetMode === 'chart'"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">
            <apexchart
                ref="chart"
                :options="chartOptions"
                :series="chartSeries"
                @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
        </div>
        <div class="topDeals" v-show="gotData && widgetMode === 'documents'">
            <div v-for="(item, key) in documents" :key="key">
                <div>&ndash;</div>
                <div class="item">
                    <el-popover
                        placement="top-start"
                        width="400"
                        popper-class="investDesc dark"
                        :open-delay="1050"
                        :close-delay="300"
                        trigger="hover">
                        <div>
                            <div class="desc" v-html="item.investee.description"></div>
                            <div class="tags"
                                 v-show="item.investee.systemKeywords && item.investee.systemKeywords.length">
                                <div v-for="(tag, tk) in item.investee.systemKeywords" :key="tk">{{ tag }}</div>
                            </div>
                        </div>
                        <div slot="reference" class="company"
                             @click="handleOpenCompanyCard(item.investee)"
                             v-html="item.investee.title">
                        </div>
                    </el-popover>
                    <template v-if="dataType === 'venture'">
                        <span>raised </span>
                        <template v-if="item.sum">
                            <span> a total of </span>
                            <span v-html="item.sum" class="sum"></span>
                            <span>in</span>
                        </template>
                        <span>{{ item.subtype }}</span>
                        <span
                            v-if="!['series_unknown', 'equity_crowdfunding', 'undisclosed', 'product_crowdfunding'].includes(item.subtype)">funding</span>

                        <span v-if="item.investor">from</span>

                    </template>
                    <template v-else>
                        <span>acquired by</span>
                    </template>
                    <template v-if="item.investor">
                        <el-popover
                            placement="top-start"
                            width="400"
                            popper-class="investDesc dark"
                            :open-delay="1050"
                            :close-delay="300"
                            trigger="hover">
                            <div>
                                <div class="desc" v-html="item.investor.description"></div>
                                <div class="tags"
                                     v-show="item.investor.systemKeywords && item.investor.systemKeywords.length">
                                    <div v-for="(tag, tk) in item.investor.systemKeywords" :key="tk">{{ tag }}</div>
                                </div>
                            </div>
                            <div slot="reference" class="company"
                                 @click="handleOpenCompanyCard(item.investor)"
                                 v-html="item.investor.title">
                            </div>
                        </el-popover>
                        <el-tooltip effect="dark" placement="top"
                                    v-if="item.otherInvestors && item.otherInvestors.length"
                                    popper-class="otherInvestors"
                                    :open-delay="600">
                            <div slot="content">
                                <div v-for="(other, key) in item.otherInvestors" :key="key">
                                    &ndash; {{ other.title }}
                                </div>
                                <div v-if="item.otherInvestorsMore">... (total: {{ item.otherInvestorsMore }})</div>
                            </div>
                            <span>and <span class="company">others</span></span>
                        </el-tooltip>

                    </template>
                    <template v-if="dataType === 'ma' && item.sum ">
                        <span>for</span>
                        <span v-html="item.sum" class="sum"></span>
                    </template>
                    <div class="date-news">
                        <span class="date">in {{ item.date }}</span>

                        <span class="news" v-if="item.news">
                            <el-popover
                                v-if="item.news && item.news.length"
                                placement="top-start"
                                width="400"
                                popper-class="investNews dark"
                                :open-delay="500"
                                :close-delay="500"
                                trigger="hover">
                                    <div>
                                        <a :href="news.url" target="_blank" class="newsItem"
                                           v-for="(news, nk) in item.news"
                                           :key="nk">
                                            <div class="date-hosting">
                                                <span class="date">{{ news.dateFormatted }}</span>
                                                <span class="hosting" v-if="news.hostName"> - {{ news.hostName }}</span>
                                            </div>
                                            <div class="title">{{ news.title }}</div>
                                        </a>
                                    </div>
                                    <div slot="reference">
                                        <i class="fas fa-share"></i>
                                    </div>
                            </el-popover>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="noResult" v-show="!gotData">
            No Result
        </div>
    </div>
</template>

<script>
import {USER_ROLES} from '@/models/Users';
import _ from "lodash";


export default {
    name: "InvestmentVentures",
    props: {
        widgetData: Object,
        dataType: String,
        title: String,
        request: Object,
        ventureSubtypeFacets: Array,
        chartSeries: Array,
        chartOptions: Object,
    },
    data() {
        return {
            cagr: '',
            loading: true,
            widgetMode: 'chart',
            documents: [],
            subtypes: [],
            facets: [],
            years: [],
            excludeStages: [
                'undisclosed',
                'grant',
                'initial_coin_offering',
                'non_equity_assistance',
                'debt_financing',
                'post_ipo_equity',
                'post_ipo_debt',
                'post_ipo_secondary',
                'secondary_market',
                'm&a'
            ],

        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        gotData() {
            return this.widgetData[this.dataType] && this.widgetData[this.dataType].length;
        }
    },
    watch: {
        incEvent(newVal) {
            if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
        },
        widgetData(newVal) {
            if (newVal) {
                this.refresh();
            }
        },
    },
    mounted() {

    },
    methods: {
        refresh() {
            let self = this;
            let _data = _.cloneDeep(self.widgetData || {}),
                _cagr = _data[(self.dataType + 'Cagr')];
            self.cagr = _cagr ? self.$utils.roundX(_cagr * 100, 1) + "%" : null;

            for (let year = self.request.years.min; year <= self.request.years.max; year++) {
                self.years.push(year.toString());
            }

            //---------- VC - M&A----------

            let docQuery = {
                "offset": 0,
                "count": 15,
                "query": self.request.filter.query ? self.request.filter.query : null,
                "nativeQuery": self.request.filter.nativeQuery ? self.request.filter.nativeQuery : null,
                "facets": {
                    "requests": [
                        {
                            "type": "INVT_YEAR",
                            "values": self.years
                        },
                        {
                            "type": "INVT_TYPE",
                            "values": [self.dataType]
                        },

                    ]
                },
                "types": [
                    "Investment"
                ],
                "semantic": false,
                "duplicate": false,
                "spam": false,
                "order": [
                    {
                        "field": "investment.date",
                        "type": "DESC"
                    }
                ]
            }
            this.$services.documents.investment(docQuery).then(resp => {
                let _docs = resp.data.list;

                self.documents = _docs.map(it => {
                    let out = {},
                        _investors = _.orderBy(it.investors, ['id', 'companyRank'], ['asc', 'asc']);
                    out.date = moment(it.date).format('MMMM YYYY');
                    out.investor = _investors.shift();
                    out.otherInvestorsMore = _investors && _investors.length > 10 ? _investors.length : null;
                    out.otherInvestors = _investors.slice(0, 10);
                    out.investee = it.investee;
                    out.subtype = _.startCase(it.subtype);
                    if (it.news && it.news.length) {
                        out.news = _.orderBy(it.news.map(n => {
                            n.dateFormatted = moment(n.date).format('ll');
                            return n;
                        }), 'date', 'desc');
                    }
                    out.sum = it.sum ? '$ ' + self.$utils.toFinHuman(it.sum).number : null;
                    //Nuance Communications acquired by Microsoft for $19.7B in april 2021
                    return out;
                });


                self.chartSeries.forEach(it => {
                    it.data = [];
                });

                _.orderBy(_data[self.dataType], 'value').forEach(it => {
                    self.chartSeries[1].data.push({
                        x: it.value,
                        y: it.count
                    });
                    self.chartSeries[0].data.push({
                        x: it.value,
                        y: it.sum,
                    });
                });
                let options = {
                    chart: {
                        height: '380px',
                    },
                    tooltip: {
                        enabled: true,
                        intersect: true,
                        shared: false,
                    },
                    markers: {
                        size: 4,
                        hover: {
                            size: 6
                        }
                    },
                    yaxis: self.createYaxis(self.chartSeries)
                };
                if (self.$refs && self.$refs.chart) {
                    self.$refs.chart.updateOptions(options)
                }

                self.loading = false;
                // console.log('-- series VC-M&A: ', self.chartSeries);
            });
        },
        createYaxis(series) {
            let self = this;
            return series.map((it, i) => {
                let ser = {};
                ser.seriesName = it.name;
                if (it.yaxis === 'opposite') {
                    ser.opposite = true;
                }
                ser.axisTicks = {show: true};
                ser.axisBorder = {show: true, color: it.color};
                ser.labels = {
                    style: {
                        colors: it.color,
                        fontSize: '14px'
                    },
                    formatter: function (val) {
                        return it.format == 'financial' ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val, 0);
                    }
                };
                ser.title = {
                    // text: it.title,
                    style: {
                        color: it.color,
                        fontSize: 15,
                        fontWeight: 500
                    }
                };
                return ser;
            });
        },
        reload() {
            this.$nextTick(() => {
                this.$refs.chart.updateOptions({})
            });
        },
        dataPointSelectionHandler(e, chartContext, config) {
            let self = this,
                year = (self.chartSeries[config.seriesIndex].data[config.dataPointIndex].x || '').toString();
            console.log(e);
            if (e.button === 0) {
                self.viewAll(year)
            }
        },
        viewAll(year = null) {
            let self = this;
            let docType = "Investment";
            let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'

            let facets = {
                requests: [{
                    type: yearType,
                    values: year ? [year] : self.years
                }, {
                    type: 'INVT_TYPE',
                    values: [self.dataType]
                },]
            };
            if (self.dataType === 'venture') {
                let subtypes = self.ventureSubtypeFacets.filter(it => !self.excludeStages.includes(it));
                facets.requests.push({
                    type: "INVT_SUBTYPE",
                    values: subtypes
                })
            }
            // console.log('Facets', facets, self.request.filter);
            let params = {
                query: self.request.filter.nativeQuery ? self.request.filter.nativeQuery.value : self.request.filter.query,
                isNativeQuery: !!self.request.filter.nativeQuery,
                docType: docType,
                facets: facets,
                spam: false,
                duplicate: 'false',
            }
            self.$utils.openSearch(params);
        },
        handleWidgetModeChange(mode) {
            this.widgetMode = mode;
            if (mode === 'chart') {
                this.reload();
            }
        },
        handleOpenCompanyCard(item) {
            if (item.id) {
                window.sessionStorage.removeItem('companyCard');
                window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
            } else {
                console.log('--- No company Id. Investor - person? --- ');
            }
        },

    }
}
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
.otherInvestors {
    > div div {
        & + div {
            margin-top: 5px;
        }
    }
}
</style>