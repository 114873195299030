var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.showWidgets),expression:"!showWidgets"}],staticClass:"fst-360view view360",attrs:{"element-loading-background":"rgba(19, 19, 19, .7)"}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"filter-block"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"periodSelector"},[_vm._v(" "+_vm._s(_vm.$t('label.period'))+" "+_vm._s(_vm.$t('label.from'))+" "),_c('el-select',{staticStyle:{"width":"63px"},attrs:{"popper-class":"darkSelect"},on:{"change":_vm.apply},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},_vm._l((_vm.years),function(year){return _c('el-option',{key:("from-" + (year.value)),attrs:{"label":year.value,"value":year.value}},[_vm._v(_vm._s(year.value)+" ")])}),1)],1)]),_c('span',{staticClass:"dashboardExport",on:{"click":_vm.handleExport360}},[(_vm.exportLoading)?_c('i',{staticClass:"fas fa-pulse fa-spinner"}):_c('svg',{attrs:{"width":"16","height":"19","viewBox":"0 0 16 19","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z","fill":"currentColor"}})]),_vm._v(" "+_vm._s(_vm.$t('label.exportToExcel'))+" ")])]),_c('div',{staticClass:"widgets"},[_c('Keywords',{attrs:{"request":_vm.request,"title":_vm.$t('dashboard.Keywords'),"widget":"keywords","field":"scienceKeywords","params":{
                    rotation: {
                        rule: 'stringLength',
                        value: 10,
                        rng: 0.65
                    }
                }}}),_c('TopCompanies',{attrs:{"request":_vm.request,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"title":_vm.$t('dashboard.Most_active_companies'),"widget":"companies","tab":"Companies","field":"companies"}}),_c('News',{attrs:{"chart-options":_vm.chartOptions,"request":_vm.request}}),_c('SciencePatentArticles',{attrs:{"chart-options":_vm.chartOptions,"loading":_vm.loading,"widgetData":_vm.chartData,"request":_vm.request}}),_c('InvestmentVentures',{attrs:{"chart-options":_vm.chartOptions,"title":_vm.$t('dashboard.Venture_investments_dynamics'),"loading":_vm.loading,"request":_vm.request,"widget-data":_vm.chartData,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"data-type":"venture","chart-series":_vm.chartSeries.VC}}),_c('InvestmentVentures',{attrs:{"chart-options":_vm.chartOptions,"title":_vm.$t('dashboard.MA_dynamics'),"loading":_vm.loading,"request":_vm.request,"widgetData":_vm.chartData,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"data-type":"ma","chart-series":_vm.chartSeries.MA}}),_c('TopCompanies',{attrs:{"request":_vm.request,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"title":_vm.$t('dashboard.Top_venture_investors'),"widget":"topVentureInvestors","field":"topVentureInvestors","tab":"Investment"}}),_c('TopDeals',{attrs:{"request":_vm.request,"title":_vm.$t('dashboard.Top_MA_deals'),"widget":"topMADeals","field":"topMADeals","params":{}}}),_c('VentureStages',{attrs:{"chart-options":_vm.chartOptions,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"request":_vm.request}}),_c('Markets',{attrs:{"chart-options":_vm.chartOptions,"request":_vm.request}}),_c('SciencePatentCountries',{attrs:{"chart-options":_vm.chartOptions,"request":_vm.request,"loading":_vm.loading,"widgetData":_vm.chartData.topCountries,"params":{
                    rotation: {}
                }}}),_c('TopCompanies',{attrs:{"request":_vm.request,"ventureSubtypeFacets":_vm.ventureSubtypeFacets,"title":_vm.$t('dashboard.Top_funded_companies'),"widget":"topFundedCompanies","field":"topFundedCompanies","tab":"Investment"}}),_c('RatedList',{attrs:{"request":_vm.request,"title":_vm.$t('dashboard.Top_research_institutions'),"widget":"researchInstitutions","tab":"Science","field":"scienceCompanies","params":{
                    bgColor: 'hsl(352, 55%, 55%)', // Red2
                    color: 'hsl(352, 55%, 75%)', // Red2
                    units: 'dashboard.Total_publications'
                }}}),_c('RatedList',{attrs:{"request":_vm.request,"title":_vm.$t('dashboard.Top_patent_applicants'),"widget":"patentApplicants","tab":"Patents","field":"patentCompanies","params":{
                    bgColor: 'hsl(36, 53%, 52%)', // Orange2
                    color: 'hsl(36, 50%, 72%)', // Orange2
                    units: 'dashboard.Total_patents'
                }}}),_c('CompanyCountries',{attrs:{"base-chart-options":_vm.chartOptions,"request":_vm.request}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }