export default Object.freeze({
    apiUrl: '/api',
    locale: 'en',
    // locale: 'ru',
    format: Object.freeze({
        moment: Object.freeze({
            date: 'YYYY-MM-DD',
            datetime: 'YYYY-MM-DDTHH:mm:ss.SSSZZ',
        })
    })
});
