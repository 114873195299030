<template>
    <div class="admin-content mb-3" ref="content">
        <div class="fst-Vectors">
            <h3 class="mt-0 mb-3">Vectors</h3>
            <div class="top-menu">
                <div class="w-33 mr-3 d-flex">
                    <el-input @change="refresh()"
                              placeholder="Search vector"
                              clearable
                              v-model="filter.namePredicate.value">
                        <el-button slot="append" @click="refresh()" type="primary" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="flex-center">
                    <div class="mr-5">
                        <label class="strong">Order by: </label>
                        <el-select
                                v-model="filter.order"
                                value-key="field"
                                @change="refresh()">
                            <el-option v-for="item in orderBy" :key="item.field"
                                       :label="item.title"
                                       :value="item"></el-option>
                        </el-select>
                    </div>
                    <label class="mr-3 strong">Show only:</label>
                    <el-checkbox
                            v-model="filter.dirty"
                            active-text="Dirty"
                            @change="refresh()"
                            class="mr-3">
                        Dirty
                    </el-checkbox>
                    <el-checkbox
                            v-model="filter.hasValue"
                            active-text="Calculated"
                            @change="refresh()"
                            class="mr-2">
                        Calculated
                    </el-checkbox>
                </div>
            </div>

            <div class="vector-list" v-loading="loading">
                <div class="vector-item" v-for="vector in vectors" :key="vector.id">
                    <div class="vector-header">
                        <el-link class="name" @click="handleEditVector(vector)">
                            {{ vector.name }}
                            <i class="fas fa-edit"></i>
                        </el-link>
                        <div class="control">
                            <el-button type="default" @click="handleCalculate(vector.id)">
                                <i class="fas fa-calculator mr-1"></i>Calculate
                            </el-button>
                            <el-button type="default"
                                       @click="handleShowExport(vector)"
                                       :title="!vector.hasValue ? 'Vector is empty' : ''"
                                       :disabled="!vector.hasValue">
                                <i class="fas mr-1 fa-file-excel"></i>
                                Export
                            </el-button>
                            <el-button type="danger" plain @click="handleDelete(vector.id)">
                                <i class="far fa-trash-alt mr-1"></i>Delete
                            </el-button>
                        </div>
                    </div>
                    <div class="description">
                        <div class="text">
                            {{ vector.description }}
                        </div>
                        <div class="date"><label>Updated:</label> {{ vector.updated }}</div>
                        <div class="date"><label>Created:</label> {{ vector.created }}</div>
                    </div>
                    <div class="flags">
                        <div>
                            <i :class="[vector.hasValue ? 'fas fa-check-square' : 'far fa-square', 'mr-1']"></i>
                            calculated
                        </div>
                        <div>
                            <i :class="[vector.dirty ? 'fas fa-check-square' : 'far fa-square', 'mr-1']"></i>
                            dirty
                        </div>
                    </div>
                    <div class="thresholds">
                        <div v-for="item in vector.thresholdsArr" :key="item.name">
                            <span class="key">{{ item.name }}</span>:
                            <span class="value">{{ item.value }}</span>
                        </div>
                    </div>
                    <div class="queries">
                        <div class="query">
                            <div class="title">Sample</div>
                            <div>
                                <template v-if="vector.sampleQuery.query">
                                    <label>Query</label>
                                    <div class="value" v-html="vector.sampleQuery.query"></div>
                                </template>
                                <template v-if="vector.sampleQuery.nativeQuery">
                                    <label>Native query</label>
                                    <div class="value" v-html="vector.sampleQuery.nativeQuery.value"></div>
                                </template>
                            </div>
                        </div>

                        <div class="query"
                             v-if="vector.andMaskQuery && (vector.andMaskQuery.query || vector.andMaskQuery.nativeQuery)">
                            <div class="title">AND Mask</div>
                            <div v-if="vector.andMaskQuery.query">
                                <label>Query</label>
                                <div class="value"
                                     v-html="vector.andMaskQuery.query"></div>
                            </div>
                            <div v-if="vector.andMaskQuery.nativeQuery">
                                <label>Native query</label>
                                <div class="value"
                                     v-html="vector.andMaskQuery.nativeQuery.value"></div>
                            </div>
                        </div>

                        <div class="query"
                             v-if="vector.orMaskQuery && (vector.orMaskQuery.query || vector.orMaskQuery.nativeQuery)">
                            <div class="title">OR Mask</div>
                            <div v-if="vector.orMaskQuery.query">
                                <label>Query</label>
                                <div class="value" v-html="vector.orMaskQuery.query"></div>
                            </div>
                            <div v-if="vector.orMaskQuery.nativeQuery">
                                <label>Native query</label>
                                <div class="value"
                                     v-html="vector.orMaskQuery.nativeQuery.value"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <el-pagination
                    small
                    background
                    :hide-on-single-page="true"
                    layout="prev, pager, next"
                    :total="listTotal"
                    :page-size="pageCount"
                    :current-page="currentPage"
                    @current-change="onChangePage"/>

        </div>
        <el-dialog :title="modal.title" :visible.sync="modal.show"
                   :close-on-click-modal="false"
                   :custom-class="modal.mode">
            <template v-if="modal.show">
                <div class="itemEditor" v-if="modal.mode === 'editItem'">
                    <div>
                        <label>Name</label>
                        <el-input v-model="vectorToEdit.name" placeholder="Vector name"></el-input>
                    </div>
                    <div>
                        <label>Description</label>
                        <el-input v-model="vectorToEdit.description" placeholder="Description"
                                  type="textarea"
                                  :rows="2"></el-input>
                    </div>
                    <div>
                        <label>Thresholds</label>
                        <div class="iq-valTab-simple">
                            <div v-for="item in vectorToEdit.thresholdsArr" :key="item.name">
                                <div class="label">{{ item.name }}</div>
                                <el-input v-model="item.value" @input="validateThresholds" type="number" :min="0"></el-input>
                            </div>
                        </div>
                    </div>
                    <h3>Sample</h3>
                    <div>
                        <label>
                            Query
                            <div v-if="isNative.sampleQuery" class="warning">
                                <i class="far fa-exclamation-triangle mr-1"></i>
                                Adv. search
                            </div>
                        </label>
                        <div>
                            <ValidateQuery v-model="queryToValidate.sampleQuery"
                                           popoverWidth="800"
                                           @validationComplete="handleSaveDisabled"/>
                            <SearchQueryEditor v-model="vectorToEdit.sampleQuery.query"
                                               @input="handleInput('sampleQuery', vectorToEdit.sampleQuery.query)"/>
                            <el-switch
                                    :width="36"
                                    v-model="isNative.sampleQuery">
                            </el-switch>

                        </div>
                    </div>
                    <h3>AND Mask</h3>
                    <div>
                        <label>Query
                            <div v-if="isNative.andMaskQuery" class="warning">
                                <i class="far fa-exclamation-triangle mr-1"></i>
                                Adv. search
                            </div>
                        </label>
                        <div>
                            <ValidateQuery v-model="queryToValidate.andMaskQuery"
                                           popoverWidth="800"
                                           @validationComplete="handleSaveDisabled"/>
                            <SearchQueryEditor v-model="vectorToEdit.andMaskQuery.query"
                                               @input="handleInput('andMaskQuery', vectorToEdit.andMaskQuery.query)"/>
                            <el-switch
                                    :width="36"
                                    v-model="isNative.andMaskQuery">
                            </el-switch>
                        </div>
                    </div>

                    <h3>OR Mask</h3>
                    <div>
                        <label>Query
                            <div v-if="isNative.orMaskQuery" class="warning">
                                <i class="far fa-exclamation-triangle mr-1"></i>
                                Adv. search
                            </div>
                        </label>
                        <div>
                            <ValidateQuery v-model="queryToValidate.orMaskQuery"
                                           popoverWidth="800"
                                           @validationComplete="handleSaveDisabled"/>
                            <SearchQueryEditor v-model="vectorToEdit.orMaskQuery.query"
                                               @input="handleInput('orMaskQuery', vectorToEdit.orMaskQuery.query)"/>
                            <el-switch
                                    :width="36"
                                    v-model="isNative.orMaskQuery">
                            </el-switch>
                        </div>
                    </div>
                    <div>
                        <label> </label>
                        <div class="flex-center justify-content-end">
                            <el-button type="text" class="ml-3"
                                       @click="modal.show = false">
                                Cancel
                            </el-button>
                            <el-button type="primary"
                                       :disabled="saveDisabled"
                                       @click="saveVector(vectorToEdit)">
                                Save vector
                            </el-button>
                        </div>
                    </div>
                </div>
                <div v-else-if="modal.mode === 'exportToExcel'">
                    <h4 class="mt-0 alert alert-info">{{toExport.vector.name}}</h4>
                    <div class="exportBlock">
                        <div>
                            <label>Source</label>
                            <el-select v-model="toExport.source" placeholder="Choose source">
                                <el-option v-for="item in toExport.vector.thresholdsArr"
                                           :key="item.name"
                                           :label="item.name"
                                           :value="item.name"
                                ></el-option>
                            </el-select>
                        </div>
                        <div>
                            <label>Threshold</label>

                            <el-input type="number" v-model="toExport.threshold"></el-input>
                        </div>
                    </div>
                    <div class="exportBlock">
                        <div>
                            <label>Years</label>
                            <el-select v-model="toExport.years" multiple clearable>
                                <el-option v-for="(year, ind) in years" :key="ind"
                                           :label="year"
                                           :value="year"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="text-right">
                        <el-button type="primary"
                                   class="px-4"
                                   :disabled="!toExport.source || exportLoading"
                                   @click="handleExport()">
                            <i :class="['fas mr-2', (exportLoading ? 'fa-spinner fa-pulse' : 'fa-file-excel')]"></i>
                            Export
                        </el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import NotionsEditor from '../settings/NotionsEditor.vue';
    import {USER_ROLES} from '@/models/Users';
    import {services} from "@/commons/services";
    import ValidateQuery from "@/commons/components/ValidateQuery";
    import {mapState} from "vuex";
    import Help from "../search/Help";
    import _ from "lodash";
    import axios from "axios";


    export default {
        name: "Vectors",
        components: {
            ValidateQuery,
            SearchQueryEditor: () => import('@/libs/ace/SearchQueryEditor')
        },
        props: {
            incEvent: Object
        },
        data() {
            return {
                loading: false,
                saveDisabled: false,
                exportLoading: false,
                vectors: [],
                years: [],
                curYear: new Date().getFullYear(),
                pageCount: 20,
                listTotal: 0,
                filter: {
                    count: 200,
                    offset: 0,
                    dirty: null,
                    hasValue: null,
                    order: {title: 'Update date', field: 'dateUpdate', type: 'DESC'},
                    namePredicate: {
                        "type": "LIKE",
                        "caseInsensitive": true,
                        "value": ""
                    },
                },
                modal: {
                    show: false,
                    title: 'Edit Vector',
                    mode: 'editItem'
                },
                orderBy: [
                    {title: 'Update date', field: 'dateUpdate', type: 'DESC'},
                    {title: 'Creation date', field: 'dateCreate', type: 'DESC'},
                    {title: 'Name', field: 'name', type: 'ASC'},
                ],
                toExport: {
                    vector: {
                        thresholdsArr: []
                    },
                    source: 'Science',
                    threshold: 0.2,
                    years: []
                },
                isNative: {
                    sampleQuery: false,
                    andMaskQuery: false,
                    orMaskQuery: false,
                },
                queryToValidate: {
                    sampleQuery: '',
                    andMaskQuery: '',
                    orMaskQuery: '',
                },
                vectorToEdit: {},
                initVector: {
                    name: 'New vector',
                    thresholdsArr: [],
                    thresholds: {
                        Science: 0.2,
                        ScienceOpen: 0.2,
                        Patents: 0.2,
                        News: 0.2,
                        Companies: 0.2
                    },
                    sampleQuery: {
                        query: "",
                    },
                    andMaskQuery: {
                        query: "",
                    },
                    orMaskQuery: {
                        query: "",
                    },
                },
                indexControl: {
                    onlyDirty: true,
                    onlyActive: true
                },
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),

            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
                notionsDict: state => state.notions,
            }),
            currentPage() {
                return (this.filter.offset / this.pageCount) + 1;
            },

        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) {
                    this[newVal.incEvent]({});
                }
            }
        },
        mounted() {
            let self = this,
                lastYear = self.curYear - 6;
            this.handleInputDebounced = _.debounce(this.operateInput, 505);
            for (let i = self.curYear; i >= lastYear; i--) {
                self.years.push(i);
            }
            self.refresh();
        },
        methods: {
            refresh() {
                let self = this,
                    filter = _.cloneDeep(self.filter);
                filter.dirty = filter.dirty ? true : null;
                filter.count = self.pageCount;
                filter.hasValue = filter.hasValue ? true : null;
                filter.order = [filter.order];
                self.loading = true;
                services.vectors.list(filter).then(function (resp) {
                    // console.log('vectors:::', resp.data);
                    self.vectors = resp.data.list.map(item => {
                        item.thresholdsArr = _.map(item.thresholds, (it, key) => {
                            return {name: key, value: it};
                        });
                        item.exportLoading = false;
                        item.updated = moment(item.dateUpdate).format('DD.MM.YYYY HH:mm');
                        item.created = moment(item.dateCreate).format('DD.MM.YYYY HH:mm');
                        return item;
                    });
                    self.listTotal = resp.data.total;
                    self.loading = false;
                });
            },
            notionName(id) {
                return (this.notionsDict.map[id] || {}).name || 'Undefined'
            },
            saveVector(vector) {
                let self = this,
                    item = _.cloneDeep(vector);
                item.thresholdsArr.forEach(it => {
                    item.thresholds[it.name] = it.value;
                });
                delete item.thresholdsArr;

                if (self.isNative.sampleQuery) {
                    item.sampleQuery = {
                        nativeQuery: {
                            value: item.sampleQuery.query,
                            fields: []
                        }
                    }
                } else {
                    item.sampleQuery = {
                        query: item.sampleQuery.query,
                    }
                }

                if (self.isNative.andMaskQuery) {
                    item.andMaskQuery = {
                        nativeQuery: {
                            value: item.andMaskQuery.query,
                            fields: []
                        }
                    }
                } else {
                    item.andMaskQuery = {
                        query: item.andMaskQuery.query,
                    }
                }

                if (self.isNative.orMaskQuery) {
                    item.orMaskQuery = {
                        nativeQuery: {
                            value: item.orMaskQuery.query,
                            fields: []
                        }
                    }
                } else {
                    item.orMaskQuery = {
                        query: item.orMaskQuery.query,
                    }
                }

                this.loading = true;

                services.vectors.save(item).then(resp => {
                    self.loading = false;
                    self.modal.show = false;
                    self.refresh();
                }, err => {
                    if (err.response && err.response.data.message) {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message
                        });
                    }
                    self.loading = false;

                })

            },
            handleAddVector() {
                let self = this
                self.initVector.thresholdsArr = _.map(self.initVector.thresholds, (it, key) => {
                    return {name: key, value: it};
                });
                self.vectorToEdit = _.cloneDeep(self.initVector);
                self.modal.title = '+ Create Vector';
                self.modal.mode = 'editItem';
                self.modal.show = true;
            },
            handleEditVector(vector) {
                let self = this,
                    item = _.cloneDeep(vector);
                item.thresholdsArr = _.map(item.thresholds, (it, key) => {
                    return {name: key, value: it};
                });
                if (item.sampleQuery.nativeQuery) {
                    self.isNative.sampleQuery = true;
                    item.sampleQuery.query = item.sampleQuery.nativeQuery.value;
                }
                if (item.andMaskQuery.nativeQuery) {
                    self.isNative.andMaskQuery = true;
                    item.andMaskQuery.query = item.andMaskQuery.nativeQuery.value;
                }
                if (item.orMaskQuery.nativeQuery) {
                    self.isNative.orMaskQuery = true;
                    item.orMaskQuery.query = item.orMaskQuery.nativeQuery.value;
                }
                self.vectorToEdit = _.merge({}, self.initVector, item);
                self.modal.title = 'Edit Vector';
                self.modal.mode = 'editItem';
                self.modal.show = true;
            },

            handleCalculate(id) {
                let self = this;
                this.$confirm('Start calculation?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then(() => {
                    this.$message({message: 'Calculating started...', showClose: true});
                    this.$services.vectors.calculate({id: id}).then(resp => {
                        console.log('Process started........');
                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                        self.loading = false;
                    })
                });
            },
            handleDelete(id) {
                let self = this;
                this.$confirm('Delete this vector?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then(() => {
                    this.$services.vectors.delete([id]).then(() => {
                        self.$message({message: 'Vector deleted', showClose: true});
                        self.refresh();
                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                        self.loading = false;
                    })
                });
            },
            handleShowExport(vector) {
                let self = this
                self.toExport.vector = vector;
                self.modal.title = 'Export';
                self.modal.mode = 'exportToExcel';
                self.modal.show = true;
            },

            handleExport() {
                let self = this,
                    vector = this.toExport.vector,
                    source = self.toExport.source,
                    threshold = self.toExport.threshold;
                if (!self.exportLoading) {
                    let _query = {
                            vectorId: vector.id,
                            docType: source,
                            threshold: threshold,
                            years: self.toExport.years
                        },
                        fileName = 'Teqviser_Vector_' + vector.name.replace(/\s/g, '_') + '_' + source + '.xlsx';
                    self.exportLoading = true;

                    axios({
                        url: this.$store.state.apiUrl + '/vectors/exportDocuments',
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then(resp => {
                        // console.log('Checked query:', query, this.filter);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        self.exportLoading = false;
                    }, err => {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message || 'Export problem on server'
                        });
                        self.exportLoading = false;
                    })
                }

            },
            handleInput(type, query) {
                this.handleInputDebounced(type, query);
            },
            operateInput(type, query) {
                this.validateQuery(type, query)
            },
            validateThresholds(){
                let _data = this.vectorToEdit.thresholdsArr;
                if(_.map(_data, 'value').some(it => it < 0)){
                    this.saveDisabled = true;
                    this.$message({
                        type: 'error',
                        message: 'Threshold should be > 0'
                    });

                } else {
                    this.saveDisabled = false;
                }
            },
            validateQuery(type, query) {
                this.queryToValidate[type] = _.clone(query);
            },
            handleSaveDisabled(rez) {
                this.saveDisabled = !rez.status;
            },
            onChangePage(page) {
                this.filter.offset = this.pageCount * (page - 1);
                this.refresh();
            },
            refresh_vectors(){
                this.refresh();
            }
        }
    }
</script>

<style lang="scss">
    .fst-Vectors {
        .top-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid $iq-border-color-base;
        }

        .facet-list {
            min-height: 100px;

            .el-tag.el-tag--success {
                color: $iq-color-text-primary;
                font-variant: all-small-caps;
                font-weight: 500;
            }

            div.iqTab {
                .tbody {
                    padding-right: 0;

                    &:first-child {
                        padding-right: 0;
                    }

                    > div > div {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            flex: 0;
                            flex-basis: 90px;
                            box-sizing: border-box;
                        }

                        &:nth-child(7) {
                            flex: 0;
                            flex-basis: 140px;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }

        .top-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            background: #edf4fc;
            padding: 15px;
            border-radius: 5px;

            > div {
                display: flex;
                align-items: center;

                > div {
                    & + div {
                        margin-left: 30px;
                    }
                }
            }
        }

        .vector-list {
            .vector-item {
                margin-bottom: 15px;
                border-radius: 5px;
                border: 1px solid $iq-border-color-base;
                padding: 10px 15px;
                overflow: hidden;

                .vector-header {
                    background: $iq-color-blue-lighter;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 15px;
                    margin: -10px -15px 15px;
                    border-bottom: 1px solid $iq-border-color-base;

                    .control {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }

                .description {
                    background: $iq-color-blue-lighter;
                    padding: 10px 15px;
                    border-radius: 5px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;

                    .text {
                        font-weight: 500;
                        flex-basis: 50%;
                    }

                    .date {
                        label {
                            display: inline-block;
                            font-weight: 500;
                        }
                    }
                }

                .name {
                    font-size: 16px;
                    font-weight: 600;
                    text-decoration: none;
                    /*color: #fff;*/
                    color: $iq-color-blue;

                    span {
                        display: inline-flex;
                        align-items: center;
                    }

                    i {
                        font-size: 14px;
                        margin-left: 7px;
                    }
                }

                .thresholds {
                    margin-bottom: 15px;
                    display: inline-flex;
                    border: 1px solid $iq-border-color-base;

                    > div {
                        padding: 5px 10px;

                        & + div {
                            border-left: 1px solid $iq-border-color-base;
                        }
                    }

                    .key {
                        font-weight: 500;
                    }
                }

                .query {
                    margin-bottom: 15px;
                    padding: 0 5px 15px 0;
                    border-bottom: 1px solid $iq-border-color-base;

                    &:last-child {
                        border-bottom: none;
                        margin-bottom: 0;
                        padding-bottom: 5px;
                    }

                    .title {
                        font-weight: 500;
                        margin-bottom: 3px;
                    }

                    > div {
                        display: flex;
                        gap: 10px;

                        label {
                            flex-shrink: 0;
                            white-space: nowrap;
                            margin-top: 4px;
                        }
                    }

                    .value {
                        border-radius: 5px;
                        border: 1px solid $iq-border-color-base;
                        padding: 3px 10px;
                        max-height: 100px;
                        overflow-y: auto;
                        line-height: 1.4;
                        box-shadow: 1px 1px 2px inset rgba(0, 0, 0, 0.11);
                    }
                }
            }

            .flags {
                display: flex;
                gap: 20px;
                margin: 0 0 15px 2px;

                i {
                    color: $iq-color-blue;
                }
            }
        }

        & + .el-dialog__wrapper {
            .el-dialog {
                &.exportToExcel {
                    width: 540px;
                }
            }
        }

    }

</style>

<style scoped lang="scss">
    .admin-content {
        max-height: calc(100vh - 160px);
    }

    .fst-ValidateQuery .gotErrorMarks i {
        left: -1px;
        top: -1px;
    }

    .itemEditor {
        > div {
            display: flex;
            align-items: flex-start;
            margin-bottom: 15px;
            width: 100%;

            label {
                margin-top: 8px;
                flex-basis: 200px;
                flex-shrink: 0;
                font-weight: 500;

                & + div {
                    position: relative;
                    width: 100%;
                }
            }

            &:last-child {
                margin-bottom: -10px;
            }

            .el-switch {
                position: absolute;
                z-index: 3000;
                right: 38px;
                top: 15px;
            }

            .warning {
                margin-top: 7px;
                font-size: 13px;
                color: $iq-color-danger;
            }
        }

        h3 {
            border-top: 1px solid $iq-border-color-base;
            margin-top: 25px;
            padding-top: 10px;
            color: #3a3a3a;

        }
    }

    .exportBlock {
        display: flex;
        gap: 15px;
        align-items: flex-end;
        margin-bottom: 15px;

        > div {
            flex: 1;
        }

        label {
            display: block;
            font-weight: 500;
        }

        .el-select, .el-input {
            display: block;
        }
    }
</style>