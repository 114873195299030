export default {
    yes: 'Да',
    no: 'Нет',
    error: 'Ошибка',
    warning: 'Внимание',
    apply: 'Применить',
    cancel: 'Отменить',
    delete: 'Удалить',
    close: 'Закрыть',
    clean: 'Очистить',
    clear: 'Очистить',
    search: 'Поиск',
    save: 'Сохранить',
    create: 'Создать',
    update: 'Обновить',
    edit: 'Редактировать',
    actions: 'Действия',
    from: 'с',
    to: 'по',
    in: 'в',
    inSource: 'в источнике',
    textSearch: 'текстовой поиск',
    total: 'Всего',
    active: 'Активность',
    visible: 'Отображение',
    name: 'Название',
    emptyResult: 'Ничего не найдено',
    dragFile: 'Перетащите сюда файл или <em>выберите на диске</em>',
    selectFile: 'Выбрать файл',
    upgradePlan: 'Для открытия вкладки свяжитесь с нами через сайт',
    Favorites: 'Избранное',
    operators: 'Операторы',
    saveSearch: 'Сохранить запрос',
    structuredQuery: 'Конструктор запроса',
    createCluster: 'Кластеризовать запрос',
    exportToExcel: 'Экспорт в Excel',
    moreLikeText: 'Найти похожие документы по тексту',
    searchIn: 'Искать в:',
    allFields: 'во всех полях',
    allCompanies: 'Всем компаниям',
    title: 'в заголовках',
    titleDescription: 'в заголовках и описаниях',
    inCompanies: 'в компаниях',
    textSearchOnlyIn: 'Текстовой поиск ',
    onlyBefore: 'только',
    onlyAfter: '',
    companies: 'Компании',
    mySearch: 'Мой поиск:',
    savedSearches: 'Сохраненные запросы',
    history: 'История запросов',
    showDocuments: 'Отображать:',
    allDocs: 'Все документы',
    notViewedDocs: 'Непросмотренные документы',
    viewedDocs: 'Просмотренные документы',
    sendMessage: 'Отправить сообщение',
    writeYourNotesHere: 'Ваша заметка',
    News: "Новости",
    Science: "Наука",
    ScienceCrossref: "Crossref",
    Patents: "Патенты",
    Companies: "Компании",
    MarketSize: "Рынки",
    Investment: "Инвестиции",
    Investments: "Инвестиции",
    ChooseSource: 'Выберите тип источника для поиска',
    daily: 'ежедневно',
    weekly: 'еженедельно',
    monthly: 'ежемесячно',
    reset: 'Сброс',
    copyToClipboard: {
        title: 'Скопировать в буфер обмена',
        success: 'Скопировано в буфер обмена'
    },
    label: {
        userMenu: {
            settings: "Понятия",
            searchProfile: 'Поисковый профиль',
            admin: "Администрирование",
            langEn: "Язык: En",
            langRu: "Язык: Ru",
            logout: "Выход"
        },
        all: 'Все',
        searchMode: 'Поиск',
        clusterMode: 'Кл.Демо',
        clusterModeSrv: 'Кластеры',
        analyticsMode: 'Аналитика',
        quickTour: 'С чего начать',
        help: 'Справка',
        leaveFeedback: 'Связь с разработчиками',
        filterBy: 'Фильтр',
        chartCategory: 'Графики',
        feedback: 'Связь с разработчикам',
        writeMessage: 'Отправьте нам сообщение',
        writeMessagePlaceholder: 'Чем мы можем вам помочь?',
        notes: 'Заметки',
        moreLikeThis: 'Похожие документы',
        citedBy: 'Цитировавшие',
        references: 'Ссылки на источники',
        allLinks: "Все web-ссылки на пубдикацию",
        publisher: "Издательство",
        translate: 'Перевести',
        translateToRu: 'Перевести на русский',
        sortBy: 'Сортировка по:',
        standardRelevance: 'Стандартной релевантности',
        semanticRelevance: 'Семантической релевантности',
        clusterRelevance: 'Кластерной релевантности',
        docDate: 'Дате документа',
        score: 'Базовой релевантности',
        pattern: 'Шаблон:',
        searchType: 'Тип поиска:',
        direct: 'Точный',
        context: 'Контекстный',
        years: 'Года',
        year: 'Год',
        byYear: ' году',
        totalInvestments: 'Всего инвестиций: $',
        showMore: 'Дальше...',
        showLess: 'Скрыть',
        showAll: 'Все',
        enterNameOfQuery: 'Имя сохраненного запроса',
        documentType: 'Тип документа',
        savedSearch: 'Сохраненные запросы',
        advSearch: 'Расширенный поиск',
        advSearchON: 'Расширенный поиск включен',
        advSearchOFF: 'Расширенный поиск выключен',
        investors: 'Инвесторы',
        searchHistory: 'История запросов',
        abstract: 'Абстракт',
        investmentTotal: 'Всего инвестиций',
        description: 'Описание',
        fullText: 'Полный текст',
        relatedNews: 'Похожие новости',
        noResult: 'Нет результатов',
        authorsCountries: 'Страны авторов',
        hqCountry: "Страна головного офиса",
        authorsOrgs: 'Организации авторов',
        authors: 'Авторы',
        fundingOrganization: 'Грантодатель',
        journalSubjectArea: 'Пердметная область (ASJC)',
        citeScore: 'Рейтинг журнала (Scopus CiteScore)',
        trends: "Тренды",
        industries: 'Индустрии',
        metrics: 'Метрики',
        articleViews: 'Просмотров статьи',
        accessesFrom: 'Доступ из',
        founded: 'Основана',
        news: "Новости",
        totalNews: "Всего новостей",
        date: "Дата",
        title: "Заголовок",
        source: "Источник",
        values: "Значения",
        rows: 'Строки',
        columns: 'Колонки',
        calculate: 'Расчитать',
        colsPlaceholder: 'Значения. Макс. колонок',
        rowsPlaceholder: 'Значения. Макс. строк',
        companyRank: 'Ранг компании',
        companyStatus: 'Статус компании',
        numberOfEmployees: 'Количество персонала',
        companyAddress: 'Адрес компании',
        totalSum: 'Всего инвестиций',
        notSelected: 'Не выбран',
        reportDate: 'Отчетный период',
        marketSegment: 'Сегмент рынка',
        period: 'Период',
        from: 'от',
        to: 'до',
        exportToExcel: 'Экспорт в excel',
        apply: 'Применить',
        showTourOnStart: 'Показать обучение по Teqviser при старте',
        tags: "Тэги",
        backToSearch: 'Назад к поиску',
        enterNewTag: 'Введите новый тэг...',
        morLikeThis: "Найти похожие документы",
        morLikeText: "Найти похожие документы по тексту",
        onlyMine: "Только мои",
        publicNotion: 'Общедоступные',
        amountRaised: "Объем инвестиций",
        type: "Тип",
        documents: "Документы",
        analytics: "Аналитика",
        openInNewTab: "Открыть в новой вкладке",
        keywords: "Ключевые слова",
        patentFamily: "Cемья патентов",
        patentFamilyHead: "Глава семьи патентов",
        patentCountry: "Страна патентования",
        headCountry: "Страна первой подачи",
        applicationDate: "дата рег. заявки",
        priorityDate: "Дата приоритета",
        applicant: "Компания-заявитель",
        publicationDate: "Дата публикации",
        publication: "Публикация",
        id: "ID",
        currentPatent: 'Текущий патент',
        customFacetsSeparator: '----- кастомные фасеты -----',
        patents: 'Патенты',
        applications: 'Заявки',
        families: 'Семейства петентов',
        inventions: "Изобретения",
        dataCoverage: "Покрытие данными",
        trendReport: 'Отчет по тренду',
        trendMonitoring: 'Отчет по покрытию данных',
        checkMaxRows: 'Выберите макс. кол-во строк',
        checkMaxCols: 'Выберите макс. кол-во колонок',
        searchFor: 'Поиск по полю',
        addAnotherField: 'Добавить еще поле',
        filterSign:'Фильтр по специфичности',
        hideSign:'Скрыть специфичность',
        showSign:'Показать специфичность',
        sortDirection: 'Направление сортировки',

    },
    investments: {
        funding: 'Получено инвестиций',
        investments: 'Инвестиции',
        acquisitions: 'Поглощения',
    },
    placeholder: {
        Find_news: "Поиск новостей",
        Find_articles: "Поиск статей",
        Find_patents: "Поиск патентов",
        Find_companies: "Поиск компаний",
        Find_Market_segments: "Поиск сегментов рынка",
        Find_investment: "Поиск инвестиций",
        Find_investor: "Поиск инвесторов",
        Type_query: 'Введите запрос',
        More_like_text: 'Вставьте текст в данное окно. Минимальное рекомендуемое кол-во слов: 30.',
        More_like_text_desc: 'Вставьте текст, чтобы найти документы с подобным содержанием. Текст должен содержать не менее 30 слов. Чем больше текст, тем лучше будет результат поиска',
        Enter_keyword: 'Введите ключевое слово',
        youCanUse: `Вы можете использовать символы <b>k</b>, <b>m</b> и <b>b</b>. Пример: 32k = 32 000`,
        joinByAND: "Объединить выбранные значения по логическому И<br>" +
            "(найти все документы, в которых есть и то, и другое значение атрибута)",
        joinByOR: "Объединить выбранные значения по логическому ИЛИ<br>" +
            "(найти все документы, в которых есть хотя бы одно из значений атрибута)",
        searchByName: 'Найти по имени',
    },
    export: {
        all: "Выгрузить все<br>(10К макс.)",
        checked: "Выгрузить выбранное",
    },
    facets: {
        year: "Год",
        invt_year: "Год",
        year_of_patent: "Год рег. патента",
        year_of_application: "Год рег. заявки на патент",
        patent_family_head: "Глава семьи патентов",
        patent_family_head_only: "Только главы семьи патентов",
        head_country: "Страна первой подачи",
        trend: "Тренд",
        industry: "Индустрия",
        company: "Организация",
        applicant: "Компания-заявитель",
        groups_of_web_sites: "Группа веб-сайтов",
        web_site: "Веб-сайт",
        system_keywords: "Системные ключевые слова",
        source_keywords: "Ключевые слова источника",
        query_keywords: "Ключевые слова запроса",
        country: "Страна",
        countries: "Страны",
        countries_alignment: 'Категория страны',
        patentCountry: "Страна патентования",
        keywords: "Ключевые слова",
        subject_area: "Предметная область",
        subject: "Предмет",
        significant_keywords: "Специфичные ключевые слова",
        companies_significant: "Специфичные компании",
        significant: "Специфичные",
        frequent: "Частотные",
        journal_quality: "Рейтинг журнала",
        funded: "Грантодатель",
        authors: "Автор",
        country_patent_office: "Страна патентования",
        country_alignment: "Категория страны",
        patent_class: "Патентный класс (CPC)",
        patent_class_ext: "Патентный класс (CPC) (расшир.)",
        cpc_ext: "Патентный класс (CPC)",
        founded: "Год основания",
        category: "Категория",
        companyCategory: "Категории компаний",
        total_investments: "Всего инвестиций",
        company_size: "Размер компании",
        company_rank: "Ранг компании",
        investors: 'Инвесторы',
        news: "Новости",
        status: "Статус",
        type: "Тип",
        subtype: "Подтип",
        publications: "Публикации",
        publicationtype: "Тип публикации",
        publicationsubtype: "Подтип публикации",
        investor: "Инвестор",
        funded_company: "Инвестируемая компания",
        sumDollar: 'Сумма, $',
        date: 'Дата',
        all: 'Все публикации',
        withAbstract: 'Только с абстрактами',
        citedByScienceCount: 'Индекс цитирования',
        citedbysciencecount: 'Индекс цитирования',
        tooltips: {
            year: 'Год публикации',
            trend: "Технологический тренд, присвоенный документу сервисом TeqViser",
            industry: "Индустрия, присвоенная документу сервисом TeqViser",
            company: "Организация",
            groups_of_web_sites: "Группа веб-сайтов",
            web_site: "Веб-сайт",
            system_keywords: "Системные ключевые слова",
            source_keywords: "Ключевые слова источника",
            query_keywords: "Ключевые слова запроса",
            country: "Страна",
            countries: "Страны",
            keywords: "Ключевые слова в порядке убывания<br>частоты встречания на документах.<br> " +
                "Ключевые слова приписываются статье<br> их авторами или искусственным интеллектом",
            subject_area: "Предметная область",
            subject: "Предмет",
            significant_keywords: "Ключевые слова в порядке убывания их специфичности заданному запросу. <br>" +
                "Специфичность - это способность ключевого слова отражать основной смысл заданного запроса.<br>" +
                "Ключевое слово с самой высокой специфичностью не обязательно является самым частотным словом. <br>" +
                "Вместо этого оно несет максимальное количество информации о том, что отличает документы,<br>" +
                "полученные по запросу, от всех других документов в базе.",
            companies_significant: "Специфичные компании",
            journal_quality: "Рейтинг журнала",
            funded: "Грантодатель",
            authors: "Автор",
            country_patent_office: "Страна патентования",
            patent_class: "Патентный класс (CPC)",
            patent_class_ext: "Патентный класс (CPC) EXT",
            cpc_ext: "Патентный класс (CPC)",
            founded: "Основано",
            category: "Категория",
            total_investments: "Всего инвестиций",
            company_size: "Размер компании",
            company_rank: "Ранг компании",
            investors: 'Инвесторы',
            news: "Новости",
            status: "Статус",
            type: "Тип",
        },
        values: {
            friendly: 'Дружественная',
            unfriendly: 'Недружественная',
        }
    },
    dashboard: {
        view360: 'Вид 360&deg;',
        techRating: 'Рейтинг технологий',
        countryRating: 'Рейтинг стран',
        onlyTrends: 'Искать только тренды',
        heatmap: 'Тепловая карта',
        patentFirstCountries: 'Страны патентования / первой подачи',
        weakSignals: 'Технологические сигналы',
        Keywords: 'Ключевые слова',
        Most_active_companies: "Наиболее активные компании",
        Venture_investments_dynamics: "Динамика венчурных инвестиций",
        MA_dynamics: 'Динамика M&A',
        Top_venture_investors: 'Топ венчурных инвесторов',
        Top_MA_deals: 'Топ M&A сделок',
        Top_funded_companies: 'Топ инвестируемых компаний',
        Top_research_institutions: 'Топ исследовательских организаций',
        Top_research_leaders: 'Лидеры в исследованиях',
        Total_publications: 'Всего публикаций',
        Total_patents: 'Всего патентов',
        In_the_last: 'За последние',
        Years: "лет",
        Frequent_companies: "Частотные компании",
        Specific_companies: "Специфичные компании",
        Frequent_keywords: "Частотные ключевые слова",
        Specific_keywords: "Специфичные ключевые слова",
        Top_patent_applicants: 'Топ изобретателей',
        News_dynamics: 'Динамика новостей',
        Venture_stages: 'Стадии венчурных инвестиций',
        Markets_evaluation: 'Оценка рынков',
        Research_leaders: 'Лидеры по науке',
        Patent_leaders: 'Страна публикации патента',
        First_country_leaders: 'Страна первой подачи патента',
        Top_inventors_by_family: "Топ изобретателей (по семье патентов)",
        Company_foundation: 'Основание компаний',
        Type: "Тип",
        Countries: "Страны",
        Subject_area: "Предметная область",
        viewAll: 'Смотреть все',
        funding: 'Инвестиции',
        or: 'или',
        Science: 'Наука',
        Patents: 'Патенты',
        s: 'Н',
        p: 'П',
        and: 'и',
        dynamics: '',
        exportToExcel: 'Экспорт в excel',
        totalDollar: 'Всего, $',
        dealCount: 'Кол-во сделок',
        acquiredBy: 'приобретена компанией',
        for: 'за',
        in: 'в',
        count: 'Кол-во',
        sum: 'Сумма',
        compareTo: 'Сравнение с',
        Absolute: 'Абсолютные',
        CAGR: 'CAGR',
        mean: 'Среднее',
        Arithmetic: 'Арифметическое',
        Geometric: 'Геометрическое',
        yearChangedTo: 'Год изменен на ',
        yearRestoredTo: 'Год восстановлен на ',
        hotInRow: "По строкам",
        hotInColumn: "По столбцам",
        hotAbsolute: "Абсолютные",
        articleDynamics: "Динамика публикаций",
        patentDynamics: "Динамика патентования",
        devDynamics: "Динамика разработок",
        inventionDynamics: "Динамика изобретений",


        weakSignalsTab: {
            keyword: "Ключевое слово",
            significance: 'Специфичность',
            growthLastYear: 'Рост за последний<br> полный год',
            growthLastYearNoBr: 'Рост за последний полный год',
            growth: 'Рост средний',
        }
    },
    errors: {
        clusteringNotEnoughDocs: "Минимальное кол-во документов для кластеризации - ",
    },
    hints: {
        Patent_leaders: "Таблица показывает патентные офисы, в которых были выданы патенты." +
            "<br> Не обязательно являются страной, где изобретение было создано или зарегистрировано впервые",
        Invention_dynamics: 'На графике показаны реальные года создания изобретений <br>(подачи заявок на первые патенты в семействах)',
        First_country_leaders: "Распределение патентов по стране их первой регистрации",
        Top_inventors_by_family: "Распределение патентов по стране их первой регистрации. <br>" +
            "Учитываются только первые патенты в семействах - собственно само изобретение",
        HorizontalScroll: 'Удерживайте кнопку CTRL для горизонтальной прокрутки карты колёсиком мыши',
    },
    quickTour: {
        next: 'Дальше',
        back: 'Назад',
        skip: 'Пропустить',
        start: 'Приступим!',
        finish: 'Завершить тур',
        again: 'Пройти заново',
    },
    notes: {
        abstractNotAvailableFor: 'Описание  отсутствует для патента ',
        abstractOfCorrespondingDocument: 'Описание взято из связанного патента '
    },
    auth: {
        errorTitle: 'Пользователь не авторизован',
        errorMessage: 'Перейти на <a href="login">страницу авторизации</a>.',
        login: 'Имя пользователя',
        pass: 'Пароль пользователя',
    },
    main: {
        title: 'Teqviser',
    },
    admin: {
        title: 'Административная панель',
        users: {
            title: 'Пользователи',
            search: 'найти по имени/логину',
            add: 'Добавить пользователя',
            statusTitle: 'Статус пользователя',
            status: {
                ENABLED: 'Активен',
                LOCKED: 'Блокирован'
            },
            table: {
                empty: 'Пользователи не найдены',
                name: 'Имя',
                login: 'Логин',
                dateAccountExpire: 'Дата истечения'
            },
            deleteUsers: {
                sure: 'Вы уверены что хотите удалить пользователя?',
                deleted: 'Пользователь удален',
            },
            editUser: {
                title: 'Добавление/редактирование пользователя',
                login: 'Логин',
                password1: 'Пароль',
                password2: 'Повтор пароля',
                fio: 'ФИО',
                email: 'e-mail',
                groups: 'Группы',
                success: 'Пользователь сохранен',
                dateAccountExpire: 'Дата окончания действия аккаунта'
            },
            generateAuthToken: {
                title: 'Генерация токена аутентификации',
                token: 'Токен',
                form: {
                    username: 'Пользователь'
                },
                msg: {
                    generate: 'Будет создан токен аутентификации, для доступа к API пользователя <b>{name}</b>.',
                    headerName: 'Токен нужно посылать в заголовке запроса {name}',
                    successMessage: 'Токен скопирован в буфер обмена'
                }
            },
            resetAuthTokens: {
                title: 'Сброс токенов аутентификации',
                msg: {
                    confirm: 'Будут сброшены токены аутентификации, для доступа к API пользователя <b>{name}</b>. Продолжить операцию?'
                }
            }
        },
        groups: {
            title: 'Группы пользователей',
            add: 'Добавить группу',
            table: {
                empty: 'Группы не найдены',
                name: 'Название',
            },
            deleteGroups: {
                sure: 'Вы уверены что хотите удалить группу?',
                deleted: 'Группа удалена',
            },
            editGroup: {
                title: 'Добавление/редактирование группы',
                name: 'Название',
                roles: 'Роли',
                users: 'Пользователи',
                success: 'Группа сохранена'
            },
        },
        notions: {
            title: 'Понятия',
            total: 'Всего:',
            addNotion: 'Создать понятие',
            editNotion: 'Изменить понятие',
            updateDeleteTags: "Обновить/удалить тэги",
            table: {
                id: 'ID',
                name: 'Название',
                querySciencePatents: 'Запрос (Наука и Патенты)',
                queryNewsCompanies: 'Запрос (Новости и Компании)',
                attributeQuery: "Атрибутивный запрос",
                description: 'Описание',
                notion: 'Понятие',
                dateCreate: 'Создано',
                dateUpdate: 'Обновлено',
                creator: 'Автор',
                icon: 'Иконка',
                selectIcon: 'Выбрать иконку',
                visibleByCompany: "Доступно компании",
            },
            filter: {
                name: 'Название',
                placeholder: 'Поиск по названию, описанию, запросу или автору'
            },
            editor: {
                title: 'Создание понятия | Редактировние понятия',
                form: {
                    name: 'Название',
                    querySciencePatents: 'Запрос (Science и Patents)',
                    queryNewsCompanies: 'Запрос (News и Companies)',
                    description: 'Описание',
                    icon: 'Иконка',
                    dateCreate: 'Дата создания',
                    dateUpdate: 'Дата обновления',
                    creator: 'Автор'
                },
                msg: {
                    success: 'Понятие успешно сохранено'
                }
            },
            msg: {
                delete: {
                    confirm: 'Вы действительно хотите удалить понятие?',
                    success: 'Понятие успешно удалено'
                }
            }
        },
        wordStat: {
            title: 'Статистика по словам',
            wordPhrase: 'Слово / фраза',
            score: 'Вес',
            morphologyFor: "Морфология для",
            semanticProximityFor: "Семантическая близость для",
            description: `<p>Модуль "Статистика по словам" позволяет обогатить ваши запросы семантически 
                            близкими* словами и 
                            словосочетаниями, которые рассчитываются на основе контекстуальной близости в документах 
                            базы TeqViser. Поскольку научный язык/словарь отличается от языка, на котором пишут новости 
                            СМИ, TeqViser предоставляет две опции контекстуальной близости:</p>
                    <ul class="mt-0 mb-2">
                        <li>Наука – на основе научных текстов. 
                        </li>
                        <li>Новости – на основе новостей.</li>
                    </ul>

                    <p class="small">* Семантическая близость - похожесть смыслов или контекста слов/словосочетаний.</p>`,
        },
        userEvents: {
            title: 'События пользователей'
        }
    },
    searchMode: {
        source: {
            All: "Вид 360&deg;",
            News: "Новости",
            JustScience: "Наука",
            Science: "Наука",
            ScienceLens: "Наука Lens",
            ScienceCrossref: "Crossref",
            ScienceOpen: "Наука (откр.)",
            Patents: "Патенты",
            Companies: "Компании",
            MarketSize: "Рынки",
            Investment: "Инвестиции",
            InvestmentAndCompanies: "Инвестиции и компании",
            RuNews: "Новости (рус)",

        }
    },
    notion: {
        note: {
            filled: 'Понятия, включающие в себя  ',
            empty: 'Понятия, состоящие из набора терминов'
        }
    },
    dataCoverage: {
        docCount: 'Общее количество документов',
        docPeriod: 'Период предоставленных документов',
        lastUpdate: 'Дата последнего обновления',
        updateFrequency: 'Частота обновления данных',
        countryDistribution: 'Распределение покрытия данных по странам в %',
        companyCount: 'Кол-во компаний',
        companyCountryDistribution: 'Распределение инвестиций по странам компаний, в которые проинвестированы средства',
        investmentCount: 'Общее количество сделок',
        investmentCountryDistribution: 'Распределение покрытия данных по стране компании',
        investmentSum: 'Общий объём инвестиций',
        docPeriodPatents: 'Период представления регистрации патентов',
        countryDistributionPatents: 'Распределение стран публикации патента в %',
        countryDistributionInvestment: 'Распределение инвестиций по странам компаний, в которые проинвестированы средства',
        description: {
            news: "Источники данных - CNET, TechCrunch, Eurekalert, Reuters, Engadjet.",
            science: "Источник данных - один из четырех крупнейших научных издательств в мире, ежегодно публикующий около четверти всех статей, опубликованных в научных журналах мира.",
            scienceOpen: "Источники данных - Springer, Onepetro.",
            scienceCrossref: "Источник данных - Crossref.",
            patents: "Сбор данных о патентах осуществляется в основном из базы данных EPO. Патентная информация - это коммерческий взгляд на технологию.",
            investment: "Источник данных является крупнейшим зарубежным ресурсом, который содержит информацию об инвестициях венчурного капитала с базой данных о стартапах и инвесторах, а также о результатах исследований рынка.",
        },
    },
    dataReport: {
        trends: {
            title: 'Отчет по тренду',
        }
    },
    structuredQueryLabel: {
        'title': 'Заголовок',
        'description': 'Абстракт',
        'cpcs_code': 'Код классификатора',
        'companies': 'Компания',
        'fullText': 'Полный текст',
    },
    chatBot:{
        title: 'Чатбот',
        prompt: 'Шаблон',
        topic: 'Тема [topic]',
        technology: 'Технология [technology]',
        role: 'Роль [role]',
        company: 'Компания [company]',
        industry: 'Индустрия [industry]',
        insertQuery: 'Вставить запрос',
        chatResponse: 'Ответ Чатбота',
        createQuery: 'Формирование запроса',
    },

}
