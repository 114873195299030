<template>
    <div class="fst-WikiText" v-loading="loading">
        <div class="fst-modal-container">
            <div class="wiki-item" v-for="(article, key) in articles" :key="key">
                <div class="header">
                    <div class="title">{{ article.title }}</div>
                    <div class="links">
                        <div>
                            <a :href="article.link" class="scr-link" target="_blank" v-if="article.link">
                                Link to Wiki page <i class="fa-share-square far ml-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="description" v-html="article.extract" v-if="article.extract">
                    <img :src="article.image" v-if="article.image" alt="article.title">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import utils from '@/commons/utils'


    export default {
        name: "WikiText",
        props: {
            value: {
                type: String
            }
        },
        data() {
            return {
                articles: [],
                loading: true,
                langCode: {
                    ja: {code: 'jp', label: 'JP'},
                    ka: {code: 'ge', label: ''},
                    da: {code: 'dk', label: ''},
                    zh: {code: 'cn', label: ''},
                    ko: {code: 'kr', label: ''},
                    et: {code: 'ee', label: ''},
                    cs: {code: 'cz', label: ''},
                    fa: {code: 'ir', label: ''},
                    ca: {code: 'cat', label: ''},
                    ar: {code: 'sa', label: 'AR'},
                    hi: {code: 'in', label: ''},
                    cy: {code: 'cyv', label: ''},
                    simple: {code: 'gb', label: ''},
                }
            };
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                const value = this.$utils.getSelectionText() ? this.$utils.getSelectionText() : this.value,
                    URL = 'https://en.wikipedia.org/w/api.php?' +
                        'format=json' +
                        '&action=query' +
                        '&generator=prefixsearch' +
                        '&prop=extracts|langlinks|redirects' +
                        '&redirects=true' +
                        '&exlimit=5' +
                        '&exintro' +
                        '&lllimit=300' +
                        '&llprop=url' +
                        // '&explaintext' +
                        '&gpslimit=5' +
                        '&gpssearch=' + value + '&origin=*';

                //Wiki API: https://en.wikipedia.org/w/api.php?action=help&modules=query

                fetch(URL, {
                    method: 'GET'
                }).then(response => response.json()).then(resp => {
                    // console.log(resp);
                    let data = resp.query ? resp.query.pages : [],
                        langs = [],
                        ruPromises = [],
                        ruArticles = [];
                    _.each(data, it => {
                        if (it.title) {
                            if (it.langlinks && it.langlinks.length) {
                                let ruPage = it.langlinks.find(ll => ll.lang === 'ru');
                                // console.log('ruPage::::::', ruPage);
                                // console.log('langLinks:', JSON.stringify(it.langlinks, null, 2));
                                if (ruPage) {
                                    let word = ruPage['*'],
                                        URL = 'https://ru.wikipedia.org/w/api.php?' +
                                            'format=json' +
                                            '&action=query' +
                                            '&generator=prefixsearch' +
                                            '&prop=extracts' +
                                            '&exlimit=10' +
                                            '&exintro' +
                                            // '&explaintext' +
                                            '&gpslimit=1' +
                                            '&gpssearch=' + word + '&origin=*';
                                    ruPromises.push(fetch(URL, {method: 'GET'}).then(response => response.json()));
                                }
                                // it.langlinks.forEach(it => {
                                //     let langCode = this.langCode[it.lang] ? this.langCode[it.lang].code : it.lang;
                                //     let langLabel = this.langCode[it.lang] && this.langCode[it.lang].label ? this.langCode[it.lang].label : it.lang.toUpperCase();
                                //     langs.push({
                                //         url: it.url,
                                //         icon: 'https://www.countryflags.io/' + langCode + '/shiny/16.png',
                                //         label: langLabel
                                //     })
                                // })
                            }
                            this.articles.push({
                                id: it.pageid,
                                title: it.title,
                                extract: it.extract,
                                link: it.pageid ? 'https://en.wikipedia.org/?curid=' + it.pageid : null,
                                image: it.thumbnail ? it.thumbnail.source : '',
                                // langlinks: langs && langs.length ? langs : null
                            })
                        }
                    });
                    Promise.all(ruPromises).then(proms => {
                        proms.forEach(resp => {
                            let data = resp.query ? resp.query.pages : []
                            _.each(data, it => {
                                if (it.title) {
                                    ruArticles.push({
                                        id: it.pageid,
                                        title: it.title,
                                        extract: it.extract,
                                        link: it.pageid ? 'https://ru.wikipedia.org/?curid=' + it.pageid : null,
                                        image: it.thumbnail ? it.thumbnail.source : '',
                                    })
                                }
                            });
                            this.articles = ruArticles.concat(this.articles);
                        })
                    })
                    this.articles = this.articles.length ? this.articles : [{
                        title: 'No result',
                        extract: 'Try to select another word or phrase',
                        noResult: true
                    }]
                    this.loading = false;
                })
            }
        }
    };
</script>

<style lang="scss">
    .fst-WikiText {
        min-height: 20vh;
        .fst-modal-container {
            padding: 0 10px 0 0;
            max-height: calc(70vh - 84px);
        }

        .wiki-item {
            background: #ffffff;
            padding: 15px 20px;
            border-radius: 5px;
            margin-bottom: 20px;
            border: 1px solid $iq-border-color-base;

            &:last-of-type {
                margin-bottom: 0;
            }

            .header {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-weight: 600;
                    font-size: 18px;
                }

                .scr-link {
                    font-size: 13px;
                    font-weight: 600;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .links {
                    text-align: right;

                    .flags {
                        display: flex;
                        justify-content: flex-end;

                        > a {
                            display: inline-block;
                            margin-left: 1px;
                        }
                    }
                }
            }

            .description {
                line-height: 1.5;

                p {
                    &:first-child {
                        margin-top: 0;
                    }
                }

                img {
                    float: right;
                    margin: 0 0 15px 7px;
                }

            }
        }
    }

    #app {
        .fst-WikiText {
            &.dark-theme {
                //background: $iq-dark-theme-bg-light-1;
                background: #000;

                .wiki-item {
                    background: $iq-dark-theme-bg-light-2;
                    border-color: transparent;
                    color: $iq-dark-theme-light;

                    .header {
                        .title {
                            color: #ddd;
                        }
                    }

                    b {
                        color: #ddd;
                    }
                }

            }
        }
    }

</style>