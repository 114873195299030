<template>
    <div class="fst-dataCoverage">
        <template v-if="Object.keys(tableData).length">
            <section v-for="(section, key) in sourceTabs" :key="key" v-if="section.dataCoverageFields">
                <div class="title">{{$t('searchMode.source.' + (section.titleAlt || section.title))}}</div>
                <div class="description" v-html="$t('dataCoverage.description.' + key)"></div>
                <table class="coverageTab">
                    <tr>
                        <th v-for="col in section.dataCoverageFields"
                            :key="col.alias">
                            {{$t('dataCoverage.' + (col.title || col.alias))}}
                        </th>
                    </tr>
                    <tr>
                        <td v-for="col in section.dataCoverageFields"
                            :key="col.alias">
                            <span v-if="col.format === 'toFin'">
                                {{ $utils.toFin(tableData[section.alias][col.alias])}}
                            </span>
                            <span v-else-if="col.format === 'toFinHuman'">
                                $ {{ $utils.toFinHuman(tableData[section.alias][col.alias]).number}}
                            </span>
                            <span v-else-if="col.type === 'yearPeriod' && tableData[section.alias][col.alias].min">
                                {{ tableData[section.alias][col.alias].min}}
                                &ndash;
                                {{ tableData[section.alias][col.alias].max}}
                            </span>
                            <table v-else-if="col.type === 'countryData'">
                                <tr v-for="country in tableData[section.alias][col.alias]" :key="country.alias">
                                    <td class="capitalize">
                                        <flag :iso="$utils.countryToISO((country.name || '').toLowerCase())" :squared="false" class="mr-1"></flag>
                                        {{country.name}}
                                    </td>
                                    <td>{{$utils.roundX((country.value * 100), 2)}}</td>
                                </tr>
                            </table>
                            <span v-else-if="col.dateFormat">
                                {{ $utils.momentIt(tableData[section.alias][col.alias]).format(col.dateFormat)}}
                            </span>
                            <span v-else-if="col.type === 'i18n'">
                                {{ $t(tableData[section.alias][col.alias]) }}
                            </span>
                            <span v-else >
                                {{ tableData[section.alias][col.alias]}}
                            </span>
                        </td>
                    </tr>
                </table>
            </section>
        </template>
    </div>
</template>

<script>
    import axios from "axios";
    import {mapState} from "vuex";
    import moment from "moment";

    export default {
        name: "DataCoverage",
        data() {
            return {
                tableData: {}
            }
        },
        computed: {
            ...mapState({
                sourceTabs: state => state.sourceTabs,
            }),
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                let self = this;
                // axios.get(this.$store.state.apiUrl + '/documents/coverage').then(resp => {
                self.$services.documents.coverage().then(resp => {
                    this.tableData = resp.data
                });

            }
        }
    }
</script>

<style scoped lang="scss">
    .fst-dataCoverage {
        max-height: calc(100vh - 210px);
        overflow-y: auto;

        section {
            .title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            .description {
                font-size: .85rem;
                margin: 0 0 10px 1px;
            }

            & + section {
                margin-top: 15px;
                border-top: $border;
                padding-top: 15px;
            }

            .coverageTab {
                width: 100%;

                td, th {
                    vertical-align: top;
                    font-size: 13px;
                    padding: 9px 7px;

                    table {
                        width: 100%;

                        td {
                            padding: 2px 4px;
                        }
                    }
                }

                th {
                    background: $iq-color-blue-light;
                    //color: $iq-color-primary;
                }
            }
        }
    }
</style>