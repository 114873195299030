<template>
    <div class="fst-Help">
        <el-tabs>
            <el-tab-pane label="Стандартный поиск" v-if="isRu">
                <h2>Синтаксис поисковых запросов</h2>

                <p>По умолчанию ключевые слова, введенные в поисковую строку, объединяются в запросе по логическому
                    "И".</p>

                <div class="alert alert-info"> Пример: запрос <span class="iq-query">Qualcomm 5G</span> вернет
                    документы, в которых
                    будут оба этих слова.
                </div>

                <h3>Операторы поиска</h3>
                <p>В запросе можно использовать следующие операторы поиска:</p>
                <table class="table w-100 fz-08">
                    <tr>
                        <th style="width: 140px;">Оператор</th>
                        <th style="width: 160px;">Описание</th>
                        <th>Примеры</th>
                    </tr>
                    <tr>
                        <td><b>AND</b></td>
                        <td>Логическое И</td>
                        <td>Запрос <span class="iq-query">Qualcomm AND 5G</span> вернет документы, в которых есть
                            оба этих слова (не
                            обязательно расположенных рядом).
                        </td>
                    </tr>
                    <tr>
                        <td><b>OR</b></td>
                        <td>Логическое ИЛИ</td>
                        <td>Запрос <span class="iq-query">Qualcomm OR 5G</span> вернет документы, в которых есть
                            хотя бы одно из этих слов
                            (не обязательно расположенных рядом).
                        </td>
                    </tr>
                    <tr>
                        <td><b>NOT</b></td>
                        <td>Логическое отрицание</td>
                        <td>Запрос <span class="iq-query">5G NOT Qualcomm</span> вернет документы, в которых
                            встречается <b>5G</b>, но
                            отсутствует <b>Qualcomm</b>
                        </td>
                    </tr>
                    <tr>
                        <td><b>@</b></td>
                        <td>Поиск без учета морфологии</td>
                        <td>Запрос <span class="iq-query">@question @answering</span> вернет документы, в которых
                            встречается словосочетание
                            <b>question answering</b> и не вернет документы, в которых встречаются такие
                            словосочетания,
                            как <b>questions answered</b>.
                        </td>
                    </tr>
                    <tr>
                        <td><b>(xxx xxx)</b><br>круглые скобки</td>
                        <td>Группировка логических выражений</td>
                        <td>
                            Запрос <span class="iq-query">Rostelecom AND (5G OR 4G)</span> вернет документы, в
                            которых встретились
                            одновременно
                            <b>Rostelecom</b> и <b>5G</b>, либо же одновременно <b>Rostelecom</b> и <b>4G</b>.
                        </td>
                    </tr>
                    <tr>
                        <!--td>Кавычки <br>двойные <b>"xxx"</b><br>или одинарные <b>'xxx'</b></td-->
                        <td><b>"xxx"</b><br> кавычки</td>
                        <td>Поиск словосочетаний</td>
                        <td>
                            По запросу <span class="iq-query">"artificial intelligence"</span> будут найдены
                            документы, в которых за словом
                            artificial
                            следует
                            слово intelligence, и эти слова находятся рядом
                            (для сравнения, по запросу <b>artificial intelligence</b> будут найдены документы,
                            в которых эти два слова находятся в произвольных частях документа).
                        </td>
                    </tr>
                    <tr>
                        <td><b>*</b><br>символ звездочка</td>
                        <td>Поиск по началу слова</td>
                        <td>
                            Запрос <span class="iq-query">pre*</span> вернет документы, в которых будут найдены
                            слова, начинающиеся
                            на «pre», например, <b>pre</b>dict, <b>pre</b>ss, <b>pre</b>requisites
                        </td>
                    </tr>
                    <tr>
                        <td><b>( ) DIST N</b></td>
                        <td>Поиск на расстоянии не более N слов</td>
                        <td>
                            Запрос <span class="iq-query">(Qualcomm AND 5G) DIST 3</span> вернет документы, в
                            которых слова <b>Qualcomm</b>
                            и <b>5G</b>
                            будут находиться на расстоянии не более чем 3 слова друг от друга.
                            Внутри DIST можно использовать и более сложные конструкции, например:
                            <b>((Rostelecom OR Qualcomm) AND (5G OR 4G)) DIST 3</b>
                        </td>
                    </tr>
                </table>

                <h3>Морфология</h3>
                <p>Поиск по ключевым словам, введенным в поисковую строку, производится с учетом морфологических
                    форм слов.</p>
                <div class="alert alert-info">
                    Пример: запрос <span class="iq-query">machine</span> вернет документы, в которых будет хотя бы
                    одно из слов <b>machine, machines, machining</b> и т.д.
                </div>

                <h3>Шаблоны (Patterns)</h3>
                <p>Использование Шаблона (Pattern) "Use Case"
                    в дополнение к введенному запросу позволяет находить документы, в которых описаны сценарии
                    применения искомого предмета интереса пользователя (технологий, технологий в отраслях,
                    технологий компаниями и т.д.).</p>

                <p class="bg-blue-lighter d-flex"><img src="usecase_pattern2.png" alt="fig.1"></p>

                <div class="alert alert-info">
                    <div class="mb-3 mt-1">Примеры результатов запросов с активированным шаблоном "Use Case":</div>
                    <table class="table w-100 fz-08">
                        <tr>
                            <th style="width: 230px;">Запрос *</th>
                            <th>Описание результата запроса *</th>
                        </tr>
                        <tr>
                            <td>robot "automotive industry"</td>
                            <td>Сценарии применения роботов в автомобильной индустрии</td>
                        </tr>
                        <tr>
                            <td>IBM "artificial intelligence"</td>
                            <td>Сценарии применения искусственного интеллекта компанией IBM (как для решения задач
                                клиентов, так и для внутренних нужд)
                            </td>
                        </tr>
                        <tr>
                            <td>Google "artificial intelligence" calls</td>
                            <td>Сценарии применения компанией Google искусственного интеллекта для обработки
                                телефонных звонков
                            </td>
                        </tr>
                    </table>
                    <small class="border-top p-2">* чем точнее и полнее запрос – тем лучше получаемые
                        результаты</small>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Search" v-if="isEn">
                <h2>Search query syntax</h2>

                <p>By default search terms entered in the search field are aggregated into the search query
                    by logical "AND" (conjunction).</p>

                <div class="alert alert-info">
                    Example: search query <span class="iq-query">Qualcomm 5G</span> will find documents with both
                    terms
                    <b>Qualcomm</b> and <b>5G</b> in them.
                </div>

                <h3>Search statements</h3>
                <p>In the search query it’s allowed to use the following statements:</p>
                <table class="table w-100 fz-08">
                    <tr>
                        <th style="width: 140px;">Statement</th>
                        <th style="width: 160px;">Description</th>
                        <th>Examples</th>
                    </tr>
                    <tr>
                        <td><b>AND</b></td>
                        <td>Logical AND</td>
                        <td>Query <span class="iq-query">Qualcomm AND 5G</span> will find documents with both term
                            <b>Qualcomm</b>
                            and term <b>5G</b> in them.
                            (Terms may or may not be near each other).
                        </td>
                    </tr>
                    <tr>
                        <td><b>OR</b></td>
                        <td>Logical OR</td>
                        <td>Query <span class="iq-query">Qualcomm OR 5G</span> will find documents with at least one
                            of the terms <b>Qualcomm</b>
                            or <b>5G</b> in them.
                            (Terms may or may not be near each other).
                        </td>
                    </tr>
                    <tr>
                        <td><b>NOT</b></td>
                        <td>Logical negation</td>
                        <td>Query <span class="iq-query">5G NOT Qualcomm</span> will find documents with the term
                            <b>5G</b> and
                            without the term
                            <b>Qualcomm</b> in them.
                        </td>
                    </tr>
                    <tr>
                        <td><b>@</b></td>
                        <td>Search without morphology transformations</td>
                        <td>Query <span class="iq-query">@question @answering</span> will find documents with
                            combination of words
                            <b>question answering</b> only and will not expand the result set with documents
                            containing word combinations like <b>questions answered</b>.
                        </td>
                    </tr>
                    <tr>
                        <td><b>(xxx xxx)</b><br>parentheses</td>
                        <td>Grouping</td>
                        <td>
                            Query <span class="iq-query">Rostelecom AND (5G OR 4G)</span> will find documents with
                            both
                            terms <b>Rostelecom</b> and <b>5G</b>, or with both terms <b>Rostelecom</b>
                            and <b>4G</b>
                        </td>
                    </tr>
                    <tr>
                        <!--td>Quotes <br>double <b>"xxx"</b><br>oe single <b>'xxx'</b></td-->
                        <td><b>"xxx"</b><br>quotes</td>
                        <td>Combination of words</td>
                        <td>
                            Query <span class="iq-query">"artificial intelligence"</span> will find documents, where
                            the
                            <b>word</b> artificial is followed by the word
                            <b>intelligence</b>, i.e. and these words are located next to each other
                            (for comparison: query <b>artificial intelligence</b> will find documents with both of
                            these terms, but the terms
                            will not necessarily be located next to each other).
                        </td>
                    </tr>
                    <tr>
                        <td><b>*</b><br>asterisk</td>
                        <td>Search by beginning of word</td>
                        <td>
                            Query <span class="iq-query">pre* </span>will find documents with the words starting
                            with «pre», for example
                            <b>pre</b>dict, <b>pre</b>ss, <b>pre</b>requisites
                        </td>
                    </tr>
                    <tr>
                        <td><b>( ) DIST N</b></td>
                        <td>Search at distance not more than N words</td>
                        <td>Query <span class="iq-query">(Qualcomm AND 5G) DIST 3</span> will find documents, where
                            the terms
                            <b>Qualcomm</b>
                            and
                            <b>5G</b> will be located at a distance not more than 3 words between them.
                            Inside DIST statements it’s possible to use more complex query constructions, for
                            example:
                            <b>((Rostelecom OR Qualcomm) AND (5G OR 4G)) DIST 3</b>
                        </td>
                    </tr>
                </table>

                <h3>Morphology</h3>
                <p>Search is performed with considering words morphological forms.</p>
                <div class="alert alert-info">
                    Example: search query <span class="iq-query">machine</span>
                    will return documents with at least one of the words <b>machine, machines,
                    machining</b> etc.
                </div>

                <h3>Patterns</h3>
                <p>Activating the Pattern "Use Case" in addition to
                    search query allows finding documents containing use cases of user’s subject of interest,
                    described in the search query. It may be use cases of technology in a specific industry,
                    company’s use cases etc. </p>
                <p class="bg-blue-lighter d-flex"><img src="usecase_pattern2.png" alt="fig.1"></p>
                <div class="alert alert-info">
                    <div class="mb-3 mt-1">Examples of search results with the "Use Case" pattern:</div>
                    <table class="table w-100 fz-08">
                        <tr>
                            <th style="width: 230px;">Search query *</th>
                            <th>Description of search results *</th>
                        </tr>
                        <tr>
                            <td>robot "automotive industry"</td>
                            <td>Use cases of robots in automotive industry</td>
                        </tr>
                        <tr>
                            <td>IBM "artificial intelligence"</td>
                            <td>Use cases of artificial intelligence by IBM (for client needs or for internal IBM’s
                                needs)
                            </td>
                        </tr>
                        <tr>
                            <td>Google "artificial intelligence" calls</td>
                            <td>Use cases of artificial intelligence by Google Google for handling calls</td>
                        </tr>
                    </table>
                    <small class="border-top p-2">* the more accurate and complete the query is - the better the
                        result
                        will be</small>
                </div>

            </el-tab-pane>
            <el-tab-pane label="Атрибутивный поиск" v-if="true || isRu">

                <h2>Атрибутивный поиск</h2>
                <p>Поиск по отдельным атрибутам документов возможен в режиме поиска Advanced search,
                    который включается тумблером в поисковой строке:</p>
                <div>
                    <img src="advSearch1.png" alt="Advanced search">
                </div>
                <h3>Атрибуты для поиска</h3>
                <p>Поиск можно осуществлять по следующим атрибутам:</p>

                <table class="table w-100 fz-08 first-td-500">
                    <tr>
                        <th class="nowrap">Название атрибута</th>
                        <th>Описание атрибута</th>
                        <th>Примеры простых запросов</th>
                    </tr>
                    <tr>
                        <td colspan="3"><h4 class="">Все типы источников</h4></td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>Название документа</td>
                        <td>title: ("price structure" OR "dynamic pricing")</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>Основной текст документа</td>
                        <td>description: ("price structure" AND retail*)</td>
                    </tr>
                    <tr>
                        <td colspan="3"><h4 class="">Новости</h4></td>
                    </tr>
                    <tr>
                        <td>companies</td>
                        <td>Компании, упоминаемые в новости</td>
                        <td>companies: (Google AND Amazon)</td>
                    </tr>
                    <tr>
                        <td>systemKeywords</td>
                        <td>Ключевые слова, которыми протэгированы новости.</td>
                        <td>systemKeywords: ("carbon steel")</td>
                    </tr>
                    <tr>
                        <td colspan="3"><h4 class="">Научные статьи</h4></td>
                    </tr>

                    <tr>
                        <td>companies</td>
                        <td>Институты, научные организации, компании, с которыми аффилированы авторы научных статей</td>
                        <td>companies: ("China institute")</td>
                    </tr>
                    <tr>
                        <td>systemKeywords</td>
                        <td>Ключевые слова, которыми протэгированы научные статьи</td>
                        <td>systemKeywords: ("artificial intelligence")</td>
                    </tr>
                    <tr>
                        <td>grantCompanies</td>
                        <td>Организации, предоставившие грант на исследования, в результате которых написана научная
                            статья
                        </td>
                        <td>grantCompanies: ("russian foundation")</td>
                    </tr>
                    <tr>
                        <td>asjc.code</td>
                        <td>
                            Детальный код тематики (Subject) научной статьи.
                            <br><br>
                            <a href="docs/Classification_asjc.xlsx">Скачать список тематик и их кодов</a>
                            <br><br>
                            Для поиска по верхнеуровневой предметной области (коды, оканчивающиеся на «00»)
                            рекомендуется использовать поиск по первым двум цифрам её кода.

                        </td>
                        <td>
                            Поиск по коду «2205» выглядит так: <br>
                            asjc.code: 2205
                            <br><br>
                            Поиск по всем кодам, начинающимся с «22» выглядит так:
                            <br>
                            asjc.code: 22*
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3"><h4 class="">Патенты</h4></td>
                    </tr>

                    <tr>
                        <td>companies</td>
                        <td>Заявители патента (applicants)</td>
                        <td>сompanies: (gazprom OR rosneft OR lukoil)</td>
                    </tr>
                    <tr>
                        <td>systemKeywords</td>
                        <td>Ключевые слова, которыми протэгированы патенты</td>
                        <td>systemKeywords: ("operating system")</td>
                    </tr>
                    <tr>
                        <td>cpcs.code</td>
                        <td>
                            Код CPC-классификатора патента.
                            <p>
                                Посмотреть классификатор можно здесь:
                                <a href="https://worldwide.espacenet.com/patent/cpc-browser" target="_blank">worldwide.espacenet.com/patent/cpc-browser</a>
                            </p>
                        </td>
                        <td>
                            Поиск всех патентов по производству железа и стали с кодом, начинающимся на C21B:
                            <p>cpcs.code: C21B*</p>
                        </td>
                    </tr>
                </table>

                <h3>Полнотекстовый поиск в атрибуте и поиск на точное соответствие</h3>

                <p>Возможны два варианта написания запроса к атрибутам
                    <b>systemKeywords</b>, <b>companies</b>, <b>grantCompanies</b>:</p>
                <ol>
                    <li>
                        <p>Для полнотекстового поиска по содержимому атрибута: поиск на вхождение подстроки, поиск с
                            помощью операторов <span class="iq-query">OR</span>,
                            <span class="iq-query">AND</span>,
                            <span class="iq-query">NOT</span> и т.д.</p>
                        <p>Вид такого запроса:</p>
                        <span class="iq-query">Название_атрибута: (полнотекстовый_запрос_к_атрибуту)</span>
                        <p class="mt-4">Пример:</p>
                        <span class="iq-query">systemKeywords: ("retail" OR "store")</span>
                        <p class="mt-3">В результате будут найдены все документы, у которых в ключевом слове
                            присутствует слово <b>retail</b>
                            или слово
                            <b>store</b>. Т.е. будут найдены документы с ключевыми словами <b>retail, store, retail
                                sales, online
                                retailing, in store experience, virtual store</b> и т.д.</p>
                    </li>
                    <li class="mt-4">
                        <p>Для поиска на точное соответствие атрибута введенной фразе.</p>
                        <p>Вид такого запроса: </p>
                        <span class="iq-query">Название_атрибута.keyword: (точный_запрос_словосочетание_к_атрибуту)</span>
                        <p class="mt-4">Пример:</p>
                        <span class="iq-query"> systemKeywords.keyword: ("retail")</span>

                        <p class="mt-3">В результате будут найдены все документы, ключевые слова которых в точности
                            равны слову <b>retail</b></p>
                    </li>
                    <li class="mt-4">
                        <p>Поиск наличия атрибута и использование булевых операторов</p>
                        <p class="mt-4">Пример:</p>
                        <span class="iq-query"> patentFamilyHead: false AND NOT _exists_: description</span>
                    </li>
                </ol>
            </el-tab-pane>

        </el-tabs>

        <div v-if="isDev || userInfo.username === 'test'" class="mt-4">
            <el-checkbox v-model="showQT" @change="handleQTOnStart">{{$t('label.showTourOnStart')}}</el-checkbox>
        </div>
    </div>
</template>

<script>
    import i18n from '@/commons/i18n';
    import {mapState} from "vuex";

    export default {
        name: "Help",
        data() {
            return {
                activeName: 'first',
                showQT: false
            };
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
            }),
            isRu() {
                return i18n.locale === 'ru';
            },
            isEn() {
                return i18n.locale === 'en';
            },
            isDev() {
                return this.userInfo.roles.indexOf('DEV') >= 0;
            },
        },
        methods: {
            handleQTOnStart(state) {
                this.$services.userSettings.save({
                    skipTour: {
                        search: !state,
                        searchView360: !state,
                        view360: !state,
                    }
                }).then(resp => {
                    console.log('userSettings: ', resp.data);
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .fst-Help {
        margin-top: -10px;

        h2 {
            margin-top: 7px;
        }

        h3 {
            margin-top: 35px;
        }

        .table {
            h4 {
                text-align: left;
                margin: -10px -15px;
                font-size: 14px;
                background: $iq-color-blue-light;
                padding: 10px 15px;
            }
        }

    }

</style>