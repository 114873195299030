<template>
    <div class="fst-ChatBot">
        <el-dialog
                :title="$t('chatBot.title')"
                :visible="showChatBot"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :before-close="beforeClose()"
                :show-close="false"
                custom-class="iq-msm-dialog"
                width="1200px">
            <div class="chatContent" @contextmenu="handleContextMenu">
                <div class="control">
                    <label>{{ $t('chatBot.prompt') }}</label>
                    <el-select v-model="prompt" class="w-100">
                        <el-option v-for="(opt, key) in promptOpt"
                                   :label="opt.user"
                                   :value="opt.chat"
                                   :key="key"></el-option>
                    </el-select>
                    <div class="inputs" v-show="prompt">
                        <div v-for="(fld, name) in fields" :key="name">
                            <label>{{ $t('chatBot.' + name) }}</label>
                            <el-input v-model="field[name]"
                                      clearable
                                      @keyup.native.enter="search"
                            ></el-input>
                        </div>
                        <el-button type="primary" @click="search">
                            <i class="fal fa-search mr-1"></i> {{ $t('search') }}
                        </el-button>
                        <el-button type="text" plain @click="reset()" class="ml-0">
                            <i class="far fa-trash-alt mr-1"></i>
                            {{$t('reset')}}
                        </el-button>

                    </div>
                </div>
                <template v-if="result.length">
                    <label class="mt-3">{{$t('chatBot.createQuery')}}</label>
                    <div class="links">
                        <div v-for="(word, ind) in links" :key="ind"
                             :class="{active: word.active}"
                             :title="word.text"
                             @click="handleAddToQuery(word)"
                             v-html="word.text"></div>
                    </div>
                    <div class="textQuery">
                        <div>{{textQuery}}</div>
                        <el-button type="primary" @click="apply">
                            {{ $t('chatBot.insertQuery')}}
                            <i class="fas fa-arrow-right ml-1"></i>
                        </el-button>
                    </div>
                    <div class="result">
                        <label>{{ $t('chatBot.chatResponse') }}</label>
                        <div v-html="result"></div>
                    </div>
                </template>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="px-4" @click="toggleChatBot(false)">{{$t('close')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "ChatBot",
        computed: {
            ...mapState({
                showChatBot: state => state.showChatBot
            }),
            fields() {
                return _.pickBy(this.field, (it, key) => this.prompt.includes('[' + key + ']'))
            },
            textQuery: {
                set(value) {
                    return value;
                },
                get() {
                    return this.queryWords.length ? '"' + _.uniq(this.queryWords).join('" OR "') + '"' : '';
                },
            }
        },
        watch: {
            showChatBot() {
                this.reset()
            }
        },
        data() {
            return {
                result: '',
                links: [],
                queryWords: [],
                field: {
                    topic: '',
                    technology: '',
                    role: '',
                    company: '',
                    industry: ''
                },
                prompt: '',
                promptOpt: [
                    {
                        user: 'Suggest 3 alternative queries for [topic].',
                        chat: 'Suggest 3 alternative queries for [topic]. The result should be in JSON-file with information "variant number" - number of varian (integer) and "sin" - search queries. In each variant shood be 5-7 search querises'
                    },
                    {
                        user: 'What is a more general way of saying [topic]?',
                        chat: 'What is a more general way of saying [topic]? The result should be in JSON-file with information "variant number" - number of varian (integer) and "sin" - search queries. In each variant shood be 5-7 search querises.'
                    },
                    {
                        user: 'What could be substitute technologies for [technology]?',
                        chat: 'What could be substitute technologies for [technology]? The result should be in JSON-file with information "technology" and "description of technology"'
                    },
                    {
                        user: 'What are different types or examples of [topic]?',
                        chat: 'What are different types or examples of [topic]?  The result should be in JSON-file with information "technology" and "description of technology"'
                    },
                    {
                        user: 'What technologies do I need for [topic]?',
                        chat: 'What technologies do I need for [topic]? The result should be in JSON-file with information "technology" and "description of technology"'
                    },
                    {
                        user: 'I am a [role] at [company]. What emerging technologies should I explore?',
                        chat: 'I am a [role] at [company]. What emerging technologies should I explore? The result should be in JSON-file with information "technology" and "description of technology"'
                    },
                    {
                        user: 'What could be technological approaches to solve the following problem? [topic]',
                        chat: 'What could be technological approaches to solve the following problem? [topic]. The result should be in JSON-file with information "technological approaches" and "way to solve problem"'
                    },
                    {
                        user: 'How do different industries approach the following topic? [topic]',
                        chat: 'How do different industries approach the following topic? [topic]. The result should be in JSON-file with information "industry" "approach" and "description of approach"'
                    },
                    {
                        user: 'What are applications in different industries for [technology]?',
                        chat: 'What are applications in different industries for [technology]? The result should be in JSON-file with information "industry"  and "applications"'
                    },
                    {
                        user: 'What applications exist for [technology] in [industry]?',
                        chat: 'What applications exist for [technology] in [industry]? The result should be in JSON-file with information "applications" and "description of application"'
                    },
                    {
                        user: 'Make a mindmap for [topic].',
                        chat: 'Make a mindmap for [topic].  Mindmap should contain attributes: Key technologies, System architecture components, Applications, Related technologies, Substituting technologies, Emerging technologies or other important attribute. The result should be in JSON-file with information "attribute" and "key components of attribute"'
                    },
                ]
            }
        },
        methods: {
            search() {
                console.log('Prompt: ', this.prompt);

                let self = this,
                    query = {
                        "uuid": null,
                        "chat": {
                            "messages": [
                                {
                                    "role": "user",
                                    "content": _.cloneDeep(self.prompt || '')
                                }
                            ]
                        }
                    };
                _.forEach(this.fields, (fld, name) => {
                    let _pat = new RegExp('\\[' + name.replace(/"/g, '') + '\\]', "g")
                    query.chat.messages[0].content = query.chat.messages[0].content.replace(_pat, '\"' + fld + '\"')
                });
                // console.log('query', query);
                this.$services.ai.chat(query).prepare().then(resp => {
                    let json = (((resp.data || [])[0] || {}).message || {}).content || '';
                    // console.log('json: ', json);
                    self.result = json;
                    self.links = _.filter(_.uniq(json.match(/"[^"]+"/g)).map(it => {
                        return {
                            active: false,
                            text: it.replace(/\"/g, '')
                        }
                    }), item => {
                        let t1 = new RegExp('variant.?[0-9]?|search.?queries', 'g'),
                            t2 = new RegExp('^sin$|^[0-9]*$');
                        return !(t1.test(item.text)
                            || t2.test(item.text)
                            || (item.text || '').length > 50)
                    });

                    // console.log('words: ', self.links);

                })

            },
            apply() {
                this.$emit('apply', this.textQuery);
                this.toggleChatBot(false);
            },
            handleAddToQuery(word) {
                if (word.active) {
                    this.queryWords = this.queryWords.filter(it => it !== word.text)
                    word.active = false;
                } else {
                    this.queryWords.push(word.text)
                    word.active = true;
                }
            },
            handleContextMenu(event) {
                this.$utils.wikiTranslateContext(this, event);
            },
            toggleChatBot(state) {
                this.$store.commit('updateState', [{
                    path: 'showChatBot',
                    data: state,
                }])

            },
            reset() {
                _.forEach(this.field, (it, key) => {
                    this.field[key] = ''
                });
                this.textQuery = '';
                this.result = '';
                this.prompt = '';
                this.links = [];
                this.queryWords = [];
            },

            beforeClose() {
                // console.log(this.showChatBot)
            },
        }
    }
</script>

<style scoped lang="scss">
    .fst-ChatBot {

    }
    .chatContent {
        max-height: 60vh;
        overflow: hidden auto;
        padding-right: 15px;
        margin-right: -15px;
        min-height: 250px;
    }


    .inputs {
        margin-top: 10px;
        display: flex;
        gap: 15px;
        align-items: flex-end;

        > div {
            min-width: 250px;
        }
    }

    .links {
        line-height: 1.4;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        margin-top: 5px;

        > div {
            color: $iq-color-link;
            cursor: pointer;
            padding: 5px 8px 4px;
            background: $iq-color-blue-light;
            border-radius: 4px;
            line-height: 1;
            max-width: 222px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all .15s ease;

            &:hover {
                color: #fff;
                background: #9fb7f9;
            }

            &.active {
                color: #fff;
                background: $iq-color-primary;
            }
        }
    }

    .textQuery {
        display: flex;
        gap: 10px;
        margin-top: 10px;

        > div {
            background: $iq-color-blue-light;
            font-size: 1rem;
            font-weight: 400;
            padding: 7px 12px;
            border-radius: 4px;
            flex: 1;
            color: #333;
        }
    }

    .result {
        margin-top: 15px;

        > div {
            border: $border;
            border-radius: 4px;
            padding: 7px 12px;
            line-height: 1.4;
            white-space: pre-wrap;
        }
    }

    label {
        font-weight: 500;
        display: block;
        margin: 5px 0 4px 1px;
    }
</style>