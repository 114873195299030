<template>
    <div class="fst-CollapseTags" ref="tags" :class="{visible: visible}">
        <div class="tags-inner" :class="{active: tagsInnerActive}">
            <el-tag v-for="(item, index) in list" :key="`tag-item-${index}`" :type="type">
                <DocumentText :text="item.label" :docType="docType" :hostingName="hostingName" />
            </el-tag>
        </div>
        <el-button v-show="moreButtonVisible" ref="moreButton"
                   :icon="`fas fa-chevron-${tagsInnerActive ? 'up' : 'down'}`"
                   @click="tagsInnerActive = !tagsInnerActive">
        </el-button>
    </div>
</template>

<script>
    import uuid from 'uuid';

    export default {
        name: "CollapseTags",
        props: {
            value: {
                type: Array,
                default: () => ([])
            },
            type: {
                type: String,
                default: 'primary'
            },
            hostingName: {
                type: String
            },
            docType: {
                type: String,
                default: 'News'
            }
        },
        data() {
            return {
                visible: false,
                isShow: false,
                tagsInnerActive: false,
                moreButtonVisible: true,
                list: [],
                hiddenItemsIndexes: []
            }
        },
        watch:{
          value(newVals, oldVals){
            this.refresh();
          }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            toggle(value) {
/*
                this.isShow = value !== undefined ? value : !this.isShow;
                if (this.isShow) {
                    this.list = _.cloneDeep(this.fullList);
                } else {
                    this.list = this.list.filter((item, index) => !this.hiddenItemsIndexes.includes(index));
                }
*/
            },
            refresh() {
                this.fullList = _.cloneDeep(this.value).map(item => {
                    if (_.isString(item)) {
                        return {label: item}
                    } else if (_.isObject(item)) {
                        return item;
                    }
                });
                this.list = _.cloneDeep(this.fullList);

                setTimeout(()=>this.getHiddenItemsIndexes(), 100);
                this.toggle(false);
                this.$nextTick(() => {
                    if (this.list.length) {
                        this.visible = true;
                    }
                });
            },
            getHiddenItemsIndexes() {
                let tags = this.$refs.tags;
                if (tags) {
                    let tagsItems = tags.querySelectorAll('.el-tag');
                    let containerWidth = tags.offsetWidth;
                    let tagsWidth = this.getElementWidth(this.$refs.moreButton.$el);
                    this.hiddenItemsIndexes = [];
                    this.moreButtonVisible = true;

                    tagsItems.forEach((item, index) => {
                        let width = this.getElementWidth(item);
                        tagsWidth += width;
                        if (tagsWidth > containerWidth) {
                            this.hiddenItemsIndexes.push(index);
                            // console.log('-',index, '.', item.innerText,' | ' , tagsWidth,'+=',width ,'>', containerWidth );
                        }
                    });
                    if (this.hiddenItemsIndexes.length < 1) {
                        this.moreButtonVisible = false;
                    }
                }
            },
            getElementStyleAsNumber(element, style) {
                let currentStyle = element.currentStyle || window.getComputedStyle(element);
                if (currentStyle && currentStyle[style]) {
                    return parseInt((currentStyle[style].match(/\d+/) || [])[0] || 0);
                } else {
                    return null;
                }
            },
            getElementWidth(element) {
                let marginLeft = this.getElementStyleAsNumber(element, 'marginLeft');
                let marginRight = this.getElementStyleAsNumber(element, 'marginRight');
                return element.offsetWidth + marginLeft + marginRight;
            }
        }
    }
</script>

<style lang="scss">
.fst-CollapseTags {

    .el-tag {
        font-size: 15px;
        font-variant: all-small-caps;
        font-weight: 500;
        color: $iq-color-text-primary;

        em {
            font-weight: 700;
            font-style: normal;
            color: #000;
        }
    }

    .el-button {
        width: 40px;
        height: 24px;
        padding: 0 8px;
        line-height: 22px;
    }
}

.fst-CollapseTags {
        overflow: hidden;
        display: flex;
        .tags-inner {
            height: 0;
            position: absolute;
            overflow: hidden;
            white-space: nowrap;
        }

        &.visible {
            .tags-inner {
                height: auto;
                position: static;
                white-space: normal;
                max-height: 30px;
                transition: all .35s ease;
                &.active{
                    max-height: 300px;
                }
            }
        }
        .el-tag {
            margin: 0 7px 7px 0;
            transition: none;
            font-variant: all-small-caps;
            font-weight: 500;
            color: $iq-color-text-primary;
            em {
                font-weight: 700;
                font-style: normal;
                color: #000;
            }
        }
        .el-button {
            margin: 0 7px 7px 0;
            background: #D5EDD7;
            border-color: #D5EDD7;
            color: $iq-color-text-primary;
            &:hover, &:active, &:focus{
                background: #c9e8cc;
                border-color: #a9daad;
                color: $iq-color-text-primary;
            }
        }
    }
</style>