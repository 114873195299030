<template>
    <section>
        <el-drawer
                :title="title"
                :append-to-body="true"
                :visible.sync="visible"
                custom-class="fst-DataReport"
                direction="rtl">
            <div class="control">
                <el-input clearable v-model="predicate"
                          :placeholder="$t('placeholder.searchByName')"
                          suffix-icon="far fa-search"></el-input>
            </div>
            <div class="results-block" v-loading="loading">
                <table class="resultTab">
                    <tr v-for="(item, key) in fList" :key="key" @click="handleExport(item)">
                        <td>
                            <div class="linkLike">{{item.name}}</div>
                        </td>
                        <td>
                            <el-button type="primary" plain size="mini"><i class="fad fa-download"></i></el-button>
                        </td>
                    </tr>
                </table>
            </div>
        </el-drawer>
    </section>
</template>

<script>
    import axios from "axios";

    export default {
        name: "DataReport",
        props: {
            title: {
                type: String,
                default: 'Export Data'
            },
            type: {
                type: String,
                default: 'trends'
            },
            params: {
                type: Object,
                default: () => ({})
            },
        },
        data() {
            return {
                visible: false,
                list: [],
                predicate: '',
                loading: true,
            }
        },
        watch: {
            visible(state) {
                if (state) {
                    this.refresh();
                }
            }
        },
        computed: {
            fList() {
                let _predicate = this.predicate.toLowerCase();
                return this.list.filter(it => {
                    return !_predicate || (it.name || '').toLowerCase().includes(_predicate)
                })
            },
        },
        methods: {
            refresh() {
                if (this.type === 'trends') {
                    this.showTrends();
                }
            },
            showTrends() {
                this.$services.analytics.availableTrends().then(resp => {
                    // console.log('/api/analytics/availableTrends: ', resp.data);
                    this.list = _.orderBy(resp.data.items, 'name');
                    this.loading = false;
                });

            },
            handleExport(item) {
                let fileName = 'ReportTrend_' + item.name + '.xlsx',
                    _query = {trend: item.id};
                this.loading = true;

                axios({
                    url: this.$store.state.apiUrl + '/analytics/trendOverviewExport',
                    method: 'POST',
                    data: JSON.stringify(_query),
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json;charset=UTF-8'}
                }).then((resp) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(resp.data);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setTimeout(() => {
                        this.loading = false;
                    }, 777)
                })
            },
            showReport(state) {
                this.visible = state;
            }
        }
    }
</script>

<style lang="scss">
    .fst-DataReport {
        .el-drawer__header {
            padding: 10px 5px 10px 20px;
            background-color: #374e66;
            color: #fff;
            font-weight: 500;
            font-size: 17px;
            margin-bottom: 0;
        }

        .el-drawer__body {
            padding: 15px;
        }

        .control {
            margin-bottom: 7px;
            padding-bottom: 7px;
        }

        .results-block{
            max-height: calc(100vh - 125px);
            overflow: hidden auto;
        }
        .resultTab {
            width: calc(100% - 15px);
            margin: 0 ;

            td {
                padding: 3px 0;

                &:last-child {
                    text-align: right;
                }
            }
        }

        .linkLike {
            @include linkLike;
        }

    }
</style>