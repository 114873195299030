<template>
    <div class="admin-content mb-3" ref="content">
        <div class="fst-CustomFacets">
            <h3 class="mt-0 mb-3">Custom facets</h3>
            <div class="top-menu">
                <div class="w-33 mr-3 d-flex">
                    <el-input @change="searchByName"
                              placeholder="Search facet by name or id(alias)"
                              clearable
                              v-model="predicate">
                        <span slot="prepend">{{totalFound}}</span>
                        <el-button slot="append" @click="refresh()" type="primary" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div>
                    <el-button @click="handleReindex">Index documents</el-button>
                </div>

            </div>

            <div class="facet-list" v-loading="loading">
                <div class="facet" v-for="(facet, key) in facets"
                     v-show="facet.show"
                     :key="key">
                    <div class="top-control">
                        <el-button type="default" plain
                                   class="px-2"
                                   @click="handleCollapseFacet(facet)">
                            <i :class="[{'el-icon-arrow-down': facet.collapsed}, {'el-icon-arrow-up': !facet.collapsed}]"></i>
                        </el-button>
                        <div class="flex-1 ml-3">
                            <div>
                                <el-input v-model="facet.id"
                                          :readonly="!facet.new"
                                          class="facetAlias facetName"
                                          placeholder="Facet alias"
                                >
                                    <div slot="prefix">id:</div>
                                </el-input>
                                <el-input v-model="facet.name"
                                          class="facetName"
                                          placeholder="Facet name"
                                >
                                </el-input>
                                <el-select v-model="facet.companyIds"
                                           class="facetName companies"
                                           multiple
                                           clearable
                                           placeholder="Choose company">
                                    <div slot="prefix"><i class="el-icon-office-building"></i></div>
                                    <el-option v-for="item in companies"
                                               :key="`comp-${item.id}`"
                                               :label="item.name"
                                               :value="item.id"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-checkbox class="ml-3" v-model="facet.visible" @change="saveFacet(facet)">Visible
                                </el-checkbox>
                            </div>

                            <div class="facet-btns">
                                <el-button type="primary" @click="saveFacet(facet)" class="px-2 mr-2">
                                <span v-if="facet.new" class="mr-1">
                                    <i class="far fa-plus mr-1"></i>
                                    <span>Create</span>
                                </span>
                                    <span v-else>
                                    <i class="fas fa-save mr-1"></i>
                                    <span>Update</span>
                                </span>
                                </el-button>
                                <el-button type="primary" plain v-if="facet.id && facet.name"
                                           class="mr-2"
                                           @click="handleAddItem(facet)"><i class="far fa-plus mr-1"></i>
                                    <span>Add Item</span>
                                </el-button>

                                <el-button type="danger" plain @click="deleteFacet(facet)"
                                           class="px-2">
                                    <i class="el-icon-delete mr-1"></i>
                                    <span>Delete</span>
                                </el-button>
                            </div>
                        </div>
                        <el-button type="default" plain
                                   class="px-2"
                                   @click="handleCollapseFacet(facet)">
                            <i :class="[{'el-icon-arrow-down': facet.collapsed}, {'el-icon-arrow-up': !facet.collapsed}]"></i>
                        </el-button>
                    </div>
                    <div :class="{collapsed: facet.collapsed}">
                        <div class="table-like iqTab align-middle" v-if="facet.items && facet.items.length">
                            <div class="tbody">
                                <div>
                                    <div>
                                        <div>
                                            Alias
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Name
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Notion / Vector
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Visible
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Active
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            Dirty
                                        </div>
                                    </div>
                                    <div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div class="tbody">
                                <div v-for="(item, key) in facet.items" :key="key">
                                    <div>
                                        <el-link @click="handleEditItem(item, facet)" class="iq-text-blue">
                                            {{ item.id }}
                                        </el-link>
                                    </div>
                                    <div>
                                        {{ item.name }}
                                    </div>

                                    <div>
                                        <el-tag type="success" title="Notion" v-if="item.notionName">{{ item.notionName }}</el-tag>
                                        <el-tag type="info" title="Vector" v-if="item.vectorName">{{ item.vectorName }}</el-tag>
                                    </div>

                                    <div>
                                        <i :class="['text-primary ml-2', {'el-icon-check': item.visible}, {'': !item.visible}]"></i>
                                    </div>
                                    <div>
                                        <i :class="['text-primary ml-2', {'el-icon-check': item.active}, {'': !item.active}]"></i>
                                    </div>
                                    <div>
                                        <i :class="['text-primary ml-2', {'el-icon-check': item.dirty}, {'': !item.dirty}]"></i>
                                    </div>
                                    <div>
                                        <el-button type="primary" plain @click="handleEditItem(item, facet)">
                                            <i class="far fa-pencil-alt"></i>
                                        </el-button>

                                        <el-button type="danger" plain @click="deleteItem(item, facet)" class="ml-2">
                                            <i class="el-icon-delete"></i>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-button type="primary" plain v-if="facet.id && facet.name"
                                   class="mt-2"
                                   @click="handleAddItem(facet)">+ Add Item
                        </el-button>
                    </div>
                </div>
            </div>
            <el-pagination
                    small
                    background
                    :hide-on-single-page="true"
                    layout="prev, pager, next"
                    :total="listTotal"
                    :page-size="pageCount"
                    :current-page="currentPage"
                    @current-change="onChangePage"/>

            <el-dialog :title="modal.title" :visible.sync="modal.show"
                       :close-on-click-modal="false"
                       custom-class="customFacetControl">
                <template v-if="modal.show">
                    <template v-if="modal.mode === 'reIndex'">
                        <div class="control">
                            <div>Show:</div>
                            <el-switch
                                    @change="refreshFacetsToIndex"
                                    v-model="indexControl.onlyActive"
                                    active-text="Only Active"
                                    inactive-text="All">
                            </el-switch>
                            <el-switch
                                    v-model="indexControl.onlyDirty"
                                    @change="refreshFacetsToIndex"
                                    active-text="Only Dirty"
                                    inactive-text="All">
                            </el-switch>
                        </div>
                        <div class="facets-to-index">
                            <div v-for="(facet, key) in facetsToIndex.list" :key="key">
                                <div class="title">{{ facet.name }}</div>
                                <div class="items">
                                    <div class="table-like iqTab align-middle" v-if="facet.items && facet.items.length">
                                        <div class="tbody">
                                            <div>
                                                <div>Name</div>
                                                <div>Action</div>
                                                <div>Active</div>
                                                <div>Dirty</div>
                                            </div>
                                        </div>
                                        <div class="tbody">
                                            <div v-for="item in facet.items" :key="item.id">
                                                <div class="itemName">{{ item.name }}</div>

                                                <el-radio-group v-model="item.action">
                                                    <el-radio-button label="">None</el-radio-button>
                                                    <el-radio-button label="refresh">Refresh</el-radio-button>
                                                    <el-radio-button label="remove">Delete</el-radio-button>
                                                </el-radio-group>
                                                <div>
                                                    <i :class="['text-primary ml-2', {'fas fa-check-square': item.active}, {'far fa-square': !item.active}]"></i>
                                                </div>
                                                <div>
                                                    <i :class="['text-primary ml-2', {'fas fa-check-square': item.dirty}, {'far fa-square': !item.dirty}]"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-center justify-content-end pt-2">
                            <el-button type="text" plain class="mr-2"
                                       @click="modal.show = false">
                                Cancel
                            </el-button>
                            <el-button type="primary" @click="handleStartReindex">
                                Start process
                            </el-button>
                        </div>

                    </template>
                    <template v-else>
                        <div class="facetItemEditor">
                            <div>
                                <label>Notion</label>

                                <el-select v-model="toEdit.item.notion"
                                           filterable
                                           remote
                                           reserve-keyword
                                           clearable
                                           placeholder="Select notion"
                                           :remote-method="searchNotion"
                                           @change="handleChangeNotion">
                                    <el-option v-for="notion in notionsToShow"
                                               :label="notion.name"
                                               :title="notion.description"
                                               :value="notion.id"
                                               :key="notion.id"
                                    ></el-option>
                                </el-select>

                                <span class="mx-5"> OR </span>
                                <label class="fb-auto mr-2">Vector</label>

                                <el-select v-model="toEdit.item.vector"
                                           placeholder="Select vector"
                                           clearable
                                           filterable
                                           @change="handleChangeVector">
                                    <el-option v-for="vector in vectors"
                                               :label="vector.name"
                                               :title="vector.description"
                                               :value="vector.id"
                                               :key="vector.id"
                                    ></el-option>
                                </el-select>
                            </div>

                            <div>
                                <label>Name</label>
                                <el-input v-model="toEdit.item.name" placeholder="Item name"></el-input>
                            </div>

                            <div>
                                <label>Alias (id)</label>
                                <el-input v-model="toEdit.item.id"
                                          :readonly="!toEdit.item.new"
                                          placeholder="Item alias"></el-input>
                            </div>

                            <div>
                                <label> </label>
                                <el-checkbox v-model="toEdit.item.visible"> Visible</el-checkbox>
                            </div>
                            <div>
                                <label> </label>
                                <el-checkbox v-model="toEdit.item.active"> Active</el-checkbox>
                            </div>
                            <div>
                                <label> </label>
                                <div class="flex-center-between">
                                    <el-button type="primary"
                                               @click="saveFacetItem(toEdit.item, toEdit.facet)">
                                        <span v-if="toEdit.item.new" class="mr-1">
                                            <i class="far fa-plus mr-1"></i> Create Item
                                        </span>
                                        <span v-else class="mr-1">
                                            <i class="fas fa-save mr-1"></i> Update Item
                                        </span>
                                    </el-button>
                                    <el-button type="text" class="ml-3"
                                               @click="modal.show = false">
                                        Cancel
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import NotionsEditor from '../settings/NotionsEditor.vue';

    import {USER_ROLES} from '@/models/Users';
    import {services} from "@/commons/services";
    import {mapState} from "vuex";


    export default {
        name: "CustomFacets",
        props: {
            incEvent: Object
        },
        data() {
            return {
                loading: false,
                facets: [],
                promises: [],
                notions: [],
                vectors: [],
                notionsToShow: [],
                toEdit: {},
                companies: [],
                companyName: {},
                pageCount: 300,
                listTotal: 0,
                predicate: '',
                totalFound: 0,
                filter: {
                    count: 300,
                    offset: 0,
                    order: [{
                        field: 'dateUpdate',
                        type: 'DESC'
                    }]
                },
                showIndexDocs: false,
                tagIdsToRetag: [],
                modal: {
                    show: false,
                    title: 'Edit Item',
                    mode: 'editItem'
                },
                initFacet: {
                    id: '',
                    collapsed: false,
                    name: '',
                    company: {},
                    visible: false,
                    new: true,
                    show: true,
                    items: []
                },
                facetsToIndex: {
                    list: [],
                    removeIds: [],
                    refreshIds: []
                },
                indexControl: {
                    onlyDirty: true,
                    onlyActive: true
                },
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),

            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
                notionsDict: state => state.notions,
            }),
            currentPage() {
                return (this.filter.offset / this.pageCount) + 1;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent]({});
            }
        },
        mounted() {
            let self = this,
                promises = [],
                vectorFilter = {
                    count: 1000,
                    offset: 0,
                    hasValue: true,
                    order: [{
                        field: "name",
                        type: "ASC"
                    }]
                };

            promises.push(self.$store.dispatch('notions').then(() => {
                self.notions = _.orderBy((self.notionsDict || []).list.filter(it => it.system), 'name').map(it => {
                    it.label = it.name + "<br>" + it.description;
                    return it;
                });
                self.notionsToShow = self.notions;
                // console.log('~ :notions : ~', self.notions);
            }));

            promises.push(services.vectors.list(vectorFilter).then(resp => {
                self.vectors = (resp.data || {}).list || [];
            }));

            Promise.all(promises).then(() => {
                self.getCompanies();
                console.log('vectr', self.vectors);
            })

        },
        methods: {
            refresh(facetId = null, collapsed = true) {
                let self = this, promises = [];
                self.loading = true;
                self.filter.count = self.pageCount;
                services.facets.listFacets(self.filter).then(function (resp) {
                    self.facets = _.orderBy(resp.data.list.map(facet => {
                        facet.companyIds = _.map(facet.companies, 'id');
                        // facet.collapsed = collapsed || !facetId;
                        facet.show = true;
                        facet.collapsed = !facetId || facetId !== facet.id || collapsed;
                        facet.orderBy = facet.id === 'trend' ? 1
                            : facet.id === 'industry' ? 2
                                : 20;
                        facet.items = _.orderBy((facet.items || []).map(it => {
                            if (it.vector) {
                                it.vectorName = it.vector.name;
                                it.vector = it.vector.id;
                            } else {
                                it.notion = it.notion.id;
                                it.notionName = self.notionName(it.notion);
                            }
                            return it;
                        }), 'id');
                        return facet;
                    }), ['orderBy'], ['asc']);
                    self.listTotal = self.totalFound = resp.data.total;
                    self.loading = false;
                    // console.log('facets:::', self.facets);
                });
            },
            notionName(id) {
                return (this.notionsDict.map[id] || {}).name || 'Undefined'
            },
            saveFacet(facet) {
                let self = this;
                let _query = {
                        id: facet.id,
                        name: facet.name,
                        visible: facet.visible,
                        companies: facet.companyIds ? facet.companyIds.map(it => {
                            return {id: it}
                        }) : []
                    },
                    _service = facet.new ? 'createFacet' : 'updateFacet';

                this.loading = true;

                services.facets[_service](_query).then(resp => {
                    self.loading = false;
                    facet.readonly = true;
                    self.refresh(facet.id, facet.collapsed);
                }, err => {
                    if (err.response && err.response.data.message) {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message
                        });
                    }
                    self.loading = false;

                })

            },
            saveFacetItem(item, facet) {
                let self = this;
                let _query = {
                        id: item.id,
                        name: item.name,
                        visible: item.visible,
                        active: item.active,
                        facet: {
                            id: facet.id
                        }
                    },
                    _service = item.new ? 'createItem' : 'updateItem';
                if (item.vector) {
                    _query.vector = {id: item.vector}
                } else if (item.notion) {
                    _query.notion = {id: item.notion}
                }
                this.loading = true;

                services.facets[_service](_query).then(resp => {
                    self.loading = false;
                    facet.readonly = true;
                    self.refresh(facet.id, false);
                    self.modal.show = false;


                }, err => {
                    if (err.response && err.response.data.message) {
                        this.$message({
                            type: 'error',
                            message: err.response.data.message
                        });
                    }
                    self.loading = false;
                    // self.modal.show = false;

                })

            },
            deleteFacet(facet) {
                let self = this;
                if (this.facets[0].new) {
                    this.facets.shift();
                    this.$refs.content.scrollTop = 0;
                } else if (facet.items.length) {
                    this.$message({
                        type: 'warning',
                        message: 'This facet got Items! Delete all items first.'
                    });
                    facet.collapsed = false;
                } else {
                    this.$confirm('Delete this facet?', 'Warning', {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        type: 'warning'
                    }).then(() => {
                        this.$services.facets.deleteFacet([facet.id]).then(resp => {
                            console.log('Facet deleted');
                            self.refresh(facet.id);
                        }, err => {
                            if (err.response && err.response.data.message) {
                                this.$message({
                                    type: 'error',
                                    message: err.response.data.message
                                });
                            }
                            self.loading = false;
                        })
                    });
                }
            },
            deleteItem(item, facet) {
                let self = this;
                if (item.active) {
                    this.$message({
                        type: 'warning',
                        message: 'Can\'t delete ACTIVE facet. Deactivate it first'
                    });
                    facet.collapsed = false;
                } else {
                    this.$confirm('Delete this Item?', 'Warning', {
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        type: 'warning'
                    }).then(() => {
                        this.$services.facets.deleteItem([item.id]).then(resp => {
                            console.log('Item deleted');
                            self.refresh(facet.id, false);
                        }, err => {
                            if (err.response && err.response.data.message) {
                                this.$message({
                                    type: 'error',
                                    message: err.response.data.message
                                });
                            }
                            self.loading = false;
                        })
                    });
                }
            },
            getCompanies() {
                let _query = {
                    "fullness": "FULL",
                    "deleted": false,
                    "order": [
                        {
                            "field": "name",
                            "type": "ASC"
                        }
                    ],
                    "offset": 0,
                    "count": 8888
                };
                return this.$services.companies.list(_query).then(resp => {
                    this.companies = resp.data.list;
                    this.companies.forEach(it => {
                        this.companyName[it.id] = it.name;
                    })
                    this.refresh();

                });
            },
            refreshFacetsToIndex() {
                let self = this;
                this.facetsToIndex.list = _.cloneDeep(this.facets).map(facet => {
                    facet.items = _.orderBy((facet.items.filter(it => {
                        return (!self.indexControl.onlyActive || it.active)
                            && (!self.indexControl.onlyDirty || it.dirty)
                    }) || []).map(it => {
                        it.action = '';
                        return it;
                    }), 'id');
                    return facet;
                });
            },
            searchNotion(query) {
                this.notionsToShow = this.notions.filter(it => it.name.toLowerCase().includes((query || '').toLowerCase())
                    || (it.description || '').toLowerCase().includes((query || '').toLowerCase()));
            },
            handleChangeNotion(id) {
                let self = this,
                    notionName = self.notionName(id);
                self.toEdit.item.name = self.toEdit.item.name ? self.toEdit.item.name : notionName
                self.toEdit.item.id = self.toEdit.item.id ? self.toEdit.item.id : (notionName || '').toLowerCase().replace(/[\W]/g, '_');
                self.toEdit.item.vector = null;
            },
            handleChangeVector(id) {
                let self = this,
                    _name = (self.vectors.find(it => it.id == id) || {}).name;
                self.toEdit.item.name = self.toEdit.item.name ? self.toEdit.item.name : _name;
                self.toEdit.item.id = self.toEdit.item.id ? self.toEdit.item.id : _name.toLowerCase().replace(/[\W]/g, '_');
                self.toEdit.item.notion = null;
            },
            handleAddFacet() {
                if (!this.facets.find(it => it.new)) {
                    this.facets.unshift(_.cloneDeep(this.initFacet));
                    this.$refs.content.scrollTop = 0;
                } else {
                    this.$message({
                        type: 'error',
                        message: 'Complete previous Facet first'
                    });
                }
            },
            handleCollapseFacet(facet) {
                facet.collapsed = !facet.collapsed;
            },
            handleEditItem(item, facet) {
                let self = this;
                self.toEdit = {
                    item: _.cloneDeep(item),
                    facet: _.cloneDeep(facet)
                };
                self.modal.title = 'Edit Item';
                self.modal.mode = 'editItem';
                self.modal.show = true;
                self.notionsToShow = self.notions;
            },
            handleAddItem(facet) {
                let self = this;
                self.toEdit = {
                    item: {
                        id: '',
                        name: '',
                        notion: '',
                        new: true,
                        visible: false,
                        active: false,
                    },
                    facet: facet
                };
                self.modal.title = '+ Create Item';
                self.modal.mode = 'editItem';
                self.modal.show = true;
                self.notionsToShow = self.notions;

                // self.refresh(facet.id);
            },
            handleReindex() {
                let self = this;
                self.refreshFacetsToIndex();
                self.modal.title = 'Reindex Facets';
                self.modal.mode = 'reIndex';
                self.modal.show = true;
            },
            handleStartReindex() {
                let self = this,
                    _removeIds = [],
                    _refreshIds = [];
                self.facetsToIndex.list.forEach(facet => {
                    _refreshIds = _refreshIds.concat(_.map(facet.items.filter(it => it.action === 'refresh'), 'id'))
                    _removeIds = _removeIds.concat(_.map(facet.items.filter(it => it.action === 'remove'), 'id'))
                });
                let query = {
                    "refreshIds": _refreshIds,
                    "removeIds": _removeIds
                };

                console.log('index Q:', query);
                this.$confirm('Reindexing may process for a long period of time. Are you sure you want to do it now?', 'Warning', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then(() => {
                    this.modal.show = false;
                    this.$message({message: 'Retagging process started...', showClose: true});
                    this.$services.facets.refreshItems(query).then(resp => {
                        console.log('Process started........');
                    }, err => {
                        if (err.response && err.response.data.message) {
                            this.$message({
                                type: 'error',
                                message: err.response.data.message
                            });
                        }
                        self.loading = false;
                    })
                });
            },
            searchByName(text) {
                let self = this;
                self.facets = self.facets.map(it => {
                    it.show = !text || it.id.includes(text) || it.name.includes(text);
                    return it;
                })
                self.totalFound = (self.facets.filter(it => it.show) || []).length;
            },
            onChangePage(page) {
                this.filter.offset = this.pageCount * (page - 1);
                this.refresh();
            },
        }
    }
</script>

<style lang="scss">
    .fst-CustomFacets {
        .top-menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid $iq-border-color-base;
        }

        .facet-list {
            min-height: 100px;

            .el-tag {
                font-variant: all-small-caps;
                font-weight: 500;
                color: $iq-color-text-primary;
                &.el-tag--info{
                    background: #d4dffc;
                }
            }

            div.iqTab {
                .tbody {
                    padding-right: 0;

                    &:first-child {
                        padding-right: 0;
                    }

                    > div > div {
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            flex: 0;
                            flex-basis: 90px;
                            box-sizing: border-box;
                        }

                        &:nth-child(7) {
                            flex: 0;
                            flex-basis: 140px;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }

        .top-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            background: #edf4fc;
            padding: 15px;
            border-radius: 5px;

            > div {
                display: flex;
                align-items: center;

                > div {
                    & + div {
                        margin-left: 30px;
                    }
                }
            }
        }

        .facet {
            margin-top: 10px;
            border-top: 1px solid $iq-border-color-base;
            padding-top: 30px;

            &:first-child {
                border-top: none;
            }

            > div {
                transition: all .3s ease;
                padding-bottom: 20px;

                &.collapsed {
                    max-height: 0;
                    overflow: hidden;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }

        .facetName {
            font-size: 15px;
            width: auto;

            .el-input--small {
                font-size: 15px;
            }

            input {
                //padding: 3px;
                font-weight: 500;
                border: none;
                //border-bottom: 1px solid $iq-border-color-base;
                //border-radius: 0;
            }

            &.facetAlias {
                width: 150px;
                margin-right: 10px;
            }

            &.companies {
                width: 300px;
                margin-left: 10px;
            }

            .el-input__prefix {
                display: inline-flex;
                align-items: center;
            }
        }

        .facet-btns {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

    }

    .customFacetControl {
        .control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 50%;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .el-radio-button--small .el-radio-button__inner {
            padding: 7px 12px !important;
        }

        .facets-to-index {
            max-height: 50vh;
            overflow-y: auto;

            > div {
                margin-bottom: 15px;
            }

            .title {
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 5px;
                padding: 7px 12px;
                background: $iq-color-blue;
                color: #fff;
                border-radius: 4px;
            }

            div.iqTab {
                .tbody {
                    padding-right: 0;

                    &:first-child {
                        padding-right: 0;
                    }

                    > div > div {
                        &:nth-child(3),
                        &:nth-child(4) {
                            flex: 0;
                            flex-basis: 90px;
                            box-sizing: border-box;
                        }
                    }
                }
            }

        }
    }

    @media only screen and (max-width: 1680px) {
        .fst-CustomFacets {
            .facetName {
                margin-bottom: 10px;

                &.companies {
                    width: auto;
                    margin-left: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 1570px) {
        .fst-CustomFacets {
            .el-button + .el-button {
                margin-left: 0;
            }

            .facet-btns {
                button {
                    margin-bottom: 10px;
                }
            }
        }
    }

</style>

<style scoped lang="scss">
    .admin-content {
        max-height: calc(100vh - 160px);
    }

    .facetItemEditor {
        > div {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: -10px;
            }

            label {
                flex-basis: 110px;
                flex-shrink: 0;
            }
        }
    }
</style>