<template>
    <div class="fst-TranspaledText">
        <div class="fst-modal-container">
            <!--div class="control">
                <label>Translate to: </label>
                <select v-model="lang" @change="refresh">
                    <option
                        v-for="lng in languages"
                        :key="lng.value"
                        :label="lng.label"
                        :value="lng.value">
                    </option>
                </select>
            </div-->
            <div class="result">
                <div>
                    <div class="header"> Source</div>
                    <div class="body" v-html="input"></div>
                </div>
                <div>
                    <div class="header"> Translation</div>
                    <div class="body" v-html="translation"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import utils from '@/commons/utils'


export default {
    name: "TranslatedText",
    props: {
        value: {
            type: String
        }
    },
    data() {
        return {
            translation: '',
            input: this.$utils.getSelectionText() ? this.$utils.getSelectionText() : this.value,
            languages: [
                {value: 'rus_Cyrl', label: 'Russian'},
                // {value: 'en', label: 'English'},
                // {value: 'de', label: 'Deutsch'},
                // {value: 'fr', label: 'France'},
            ],
            lang: 'rus_Cyrl',
            srcLang: '<i class="far fa-spinner fa-pulse"></i>'
        };
    },
    mounted() {
        this.refresh();
    },
    watch: {},
    methods: {
        refresh() {
            this.input = this.input.substring(0, 4999);
            if (this.input) {
                this.translation = '<div class="text-center"><i class="fas fa-spinner fa-pulse my-1 iq-text-primary"></i></div>';
                let self = this;
                this.$utils.translateText({
                    text: this.input,
                    tl: this.lang
                }).then(function (resp) {
                    if (resp.data) {
                        self.translation = resp.data.description;
                    // if (response && response.data && response.data.message) {
                    //     self.translation = response.data.message.content;
                        // self.srcLang =  response.data.original_text
                    }
                });
            } else {
                this.translation = '<h4 class="my-2">There is no text selected</h4>';

            }
        },
        getSelectionText() {
            let text = "";
            if (window.getSelection) {
                text = window.getSelection().toString();
            } else if (document.selection && document.selection.type !== "Control") {
                text = document.selection.createRange().text;
            }
            return text;
        },
    }
};
</script>

<style lang="scss">
.fst-TranspaledText {
    .fst-modal-container {
        padding: 0 10px 0 0;
    }

    .control {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        select{
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: $iq-color-text-primary;
            min-width: 200px;
            padding: 0 7px;
            border-radius: 5px;
            border-color: $iq-border-color-base;
        }

        label {
            margin-right: 5px;
            font-weight: 500;
            color: $iq-color-grey-blue;
        }
    }

    .result {
        display: flex;
        justify-content: space-between;

        > div {
            width: 48.5%;
            border: 1px solid $iq-border-color-base;
            border-radius: 5px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .header {
                padding: 10px 20px;
                border-radius: 5px 5px 0 0;
                background: $iq-color-blue-light;
                font-weight: 500;
                font-variant: all-small-caps;
            }
            .body {
                background: #ffffff;
                padding: 15px 20px;
                border-radius: 0 0 5px 5px;
                white-space: pre-line;
                line-height: 1.4;
                flex: 1;
            }
        }
    }
}
</style>