<template>
    <div class="fst-Help">
        <div class="fst-modal-container"
             v-loading="loading"
             element-loading-text="Sending..."
             element-loading-spinner="el-icon-loading">
            <h2 class="mt-0 mb-1" v-html="$t('label.writeMessage')"></h2>
            <el-input type="textarea" class="mt-2"
                      :rows="6" v-model="text"
                      :placeholder="$t('label.writeMessagePlaceholder')"></el-input>
            <div class="text-right">
                <el-button type="text" class="mt-2" @click="$emit('showUserFeedback')" v-html="$t('cancel')"></el-button>
                <el-button type="primary" class="mt-2" @click="sendIt()" v-html="$t('sendMessage')"></el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserFeedback",
    data() {
        return {
            text: '',
            loading: false
        };
    },
    mounted() {
        this.text = ''
    },
    methods: {
        sendIt() {
            if (this.text) {
                this.loading = true;
                this.$services.userFeedback.submit({text: this.text}).then(() => {
                    setTimeout(() => {
                        this.text = '';
                        this.loading = false;
                        this.$emit('showUserFeedback');
                    }, 555)
                })
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.fst-modal-container {
    max-height: 50vh;
}
</style>