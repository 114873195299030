<template>
    <div class="fst-Admin wrapper">
        <Header></Header>
        <!--div class="content">
            <div class="main__header">
                <div class="section-header section-header_padding section-header_bordered">
                    <strong class="section-header__content">Notions</strong>
                </div>
            </div>
            <el-tabs v-model="activeTab" tab-position="left">
                <el-tab-pane v-for="tab in tabs" :key="tab.id" :label="tab.title" :name="tab.id">
                    <component :is="tab.component" :incEvent="firedData"></component>
                    <h1>{{tab.title}}</h1>
                </el-tab-pane>
            </el-tabs>
        </div-->
        <div class="content">
            <el-tabs v-model="activeTab" tab-position="left" :class="{hiddenLeftMenu: hiddenLeftMenu}">
                <div class="showHideLeftMenu" @click="hiddenLeftMenu = !hiddenLeftMenu">
                    <i :class="['fas',{'fa-chevron-left': !hiddenLeftMenu},{'fa-chevron-right': hiddenLeftMenu}]"></i>
                </div>
                <el-tab-pane v-for="tab in tabs" :key="tab.id" :name="tab.id">
                    <div slot="label" class="side-control">
                        <div>{{ tab.title }}</div>
                        <div class="control-box">
                            <component v-if="tab.control" :is="tab.control"
                                       @fireEvent="data => fireEvent(data)"></component>
                            <el-button type="primary" v-if="tab.button && tab.button.visible" @click="fireEvent(tab.button.event)">
                                {{ tab.button.title }}
                            </el-button>
                        </div>
                    </div>
                    <component :is="tab.component" :incEvent="firedData"></component>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import {USER_ROLES} from '@/models/Users';
import Header from '../components/Header';
import Notions from '../components/settings/Notions';
import Wordstat from '../components/settings/Wordstat';
import UserNotification from "@/components/search/UserNotification";

export default {
    name: 'Settings',
    components: {UserNotification,Header},
    data() {
        const user = this.$store.state.userInfo;
        return {
            activeTab: 'notions',
            firedData: {},
            hiddenLeftMenu: false,
            tabs: []
        };
    },
    mounted() {
      this.createMenu();
    },
    computed: {
        user() {
            return this.$store.state.userInfo;
        },
        allowed() {
            return this.$store.state.allowedActions;
        },

    },
    methods: {
        createMenu(){
            this.tabs = _.filter([
                {
                    id: 'notions',
                    title: this.$t('admin.notions.title'),
                    component: Notions,
                    hiddenLeftMenu: false,
                    visible: true,
                    button: {
                        title: '+ ' + this.$t('admin.notions.addNotion'),
                        event: 'editNotion',
                        visible:  this.user.roles.includes('DEV') || this.allowed.contains('CREATE_NOTION')
                    }
                },
                {
                    id: 'wordStat',
                    title: this.$t('admin.wordStat.title'),
                    component: Wordstat,
                    visible: true
                }

            ], v => !v.hasOwnProperty('visible') || !!v.visible)
        },
        fireEvent(data) {
            this.firedData = {incEvent: data};
        },
        handleAdminPredicate() {
            this.firedData = {incEvent: 'handleSearchSource', data: this.adminPredicate}
        },

    },
}
</script>

<style lang="scss">
// --- .fst-Admin look common.scss
</style>
