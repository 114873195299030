<template>
    <div class="fst-Clusters dark-theme">
        <template v-if="isDev || allowed.contains('CLUSTERING_REPORTS')">
            <template v-if="mode==='clusterList'">
                <Header mode="clusters">
                    <template slot="predicate">
                        <div class="top-control">

                        </div>
                    </template>
                </Header>
                <div class="data-control">
                    <div class="container"
                         v-loading="loading"
                         element-loading-background="rgba(19, 19, 19, .7)">
                        <div class="table-like iqTab sortTab">
                            <div class="tbody pr-2">
                                <div>
                                    <div class="flex-center-between">
                                        <span>Report</span>
                                        <div>
                                            <el-input clearable
                                                      suffix-icon="far fa-search"
                                                      @input="handleFilterReports()"
                                                      v-model="filter.query"></el-input>
                                        </div>
                                    </div>
                                    <div>Date</div>
                                    <div>Author</div>
                                    <div>Filter</div>
                                    <div class="text-right">
                                        <span class="pointer" @click="refresh()"><i class="el-icon-refresh"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="tbody">
                                <div v-for="row in pagedDataSourceList" :key="row.id" v-show="row.show">
                                    <div class="flex-top">
                                        <i :class="row.stateIcon" class="mr-2" :title="row.state"></i>
                                        <div>
                                            <el-link v-if="row.state === 'ready'"
                                                     @click="handleSelectClusterSource(row)">{{
                                                row.name }}
                                            </el-link>
                                            <span v-else>{{ row.name }}</span>
                                        </div>
                                        <div class="ml-auto">
                                            <el-popover
                                                    placement="left"
                                                    width="280"
                                                    :visible-arrow="false"
                                                    popper-class="subFilter dark"
                                                    v-if="row.allowedActions.includes('EDIT') && row.state === 'ready'"
                                                    trigger="click"
                                                    v-model="row.editShow">
                                                <div class="flex-center">
                                                    <el-input v-model="row.name"
                                                              @keydown.native.enter.prevent="handleSaveName(row)"></el-input>
                                                    <el-button type="primary" size="small" class="ml-2"
                                                               @click="handleSaveName(row)">
                                                        save
                                                    </el-button>
                                                </div>

                                                <span class="pointer ml-2" slot="reference">
                                                <i class="el-icon-edit"></i>
                                            </span>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div class="date" v-html="row.dateFormatted"></div>
                                    <div v-if="row.creator">{{row.creator.fullName }}</div>

                                    <div class="filterValues">
                                        <table @click="handleShowFilter(row)">
                                            <tr>
                                                <td>Type:</td>
                                                <td class="val">
                                                    <div>
                                                        {{ row.filter.types[0] }}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="nowrap">
                                                    <template v-if="row.filter.isNativeQuery">Native Q:</template>
                                                    <template v-else>Query:</template>
                                                </td>
                                                <td class="val">
                                                    <div class="filter" v-html="row.filter.queryFormatted"></div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <el-button type="text" @click="handleDelete(row)"
                                                   v-if="row.allowedActions.includes('DELETE') && row.state === 'ready'">
                                            <i class="el-icon-delete iq-text-danger"></i>
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-pagination
                                small
                                background
                                class="mt-2"
                                :hide-on-single-page="true"
                                layout="prev, pager, next"
                                :total="listTotal"
                                :page-size="pageCount"
                                :current-page="currentPage"
                                @current-change="onChangePage"/>

                    </div>
                </div>
            </template>
            <template v-else>
                <ClusterChart
                        :dataSourceList="dataSourceListToShow"
                        :selectedSourceId="selectedSourceId"
                        @showList="mode='clusterList'"
                ></ClusterChart>
            </template>
        </template>
        <template v-else>
            <h3 class="py-5 text-center mt-5">You don't have permissions to access this page!</h3>
        </template>

    </div>
</template>

<script>
    import _ from 'lodash';
    import {USER_ROLES} from '@/models/Users';
    import Header from '../components/Header';
    import ClusterChart from '../components/clusters/ClusterChart';
    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'Clusters',
        components: {Header, ClusterChart},
        data() {
            return {
                selectedSourceId: '',
                dataSourceList: [],
                dataSourceListToShow: [],
                pageCount: 10,
                listTotal: 0,

                sources: [],
                mode: 'clusterList',
                filter: {
                    query: '',
                    offset: 0,
                },
                firedData: {},
                loading: true,
            };
        },
        mounted() {
            let self = this;
            self.$store.dispatch('notions').then(() => {
                services.system.info().then(function (resp) {
                    self.$store.commit('lastYears', resp.data.lastYear);
                    self.refresh();
                });
            });
        },
        computed: {
            ...mapState({
                user: state => state.userInfo,
                notionsDict: state => state.notions,
            }),
            isDev() {
                return this.user.roles.indexOf('DEV') >= 0;
            },
            isAdmin() {
                return this.isDev || this.user.roles.indexOf('ADMIN') >= 0;
            },
            currentPage() {
                return (this.filter.offset / this.pageCount) + 1;
            },
            pagedDataSourceList() {
                return this.dataSourceList
                    .filter(it => it.show)
                    .slice(this.filter.offset, this.pageCount + this.filter.offset);
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
        },
        methods: {
            refresh() {
                let self = this,
                    iconClass = {
                        ready: 'far fa-check iq-text-success',
                        failed: 'far fa-ban iq-text-danger',
                        calculating: 'far fa-pulse fa-spinner iq-color-blue-2',
                    };
                self.loading = true;
                self.$services.clusteringReport.list({
                    count: 5000,
                    offset: 0,
                    "order": [
                        {
                            "field": "dateCreate",
                            "type": "DESC"
                        }
                    ],
                }).then(resp => {
                    console.log('Source List: ', resp.data);
                    self.listTotal = resp.data.total;
                    self.dataSourceList = resp.data.list.map(it => {
                        let isNative = !!it.filter.nativeQuery;
                        it.dateFormatted = moment(it.dateCreate).format('DD MMM YYYY<br>HH:mm');
                        it.filter.queryFormatted = isNative ? it.filter.nativeQuery.value : self.formatQueryNotions(it.filter.query);
                        it.filter.isNativeQuery = isNative;
                        it.stateIcon = iconClass[it.state];
                        it.editShow = false;
                        it.creator = it.creator || {id: 0, fullName: 'Not available'}
                        it.show = self.isAdmin
                            || it.filter.types[0] !== 'Science'
                            || self.allowed.contains('PRIVATE_SCIENCE_SEARCH');
                        return it;
                    });
                    self.dataSourceListToShow = self.dataSourceList.filter(it => it.state === 'ready');
                    setTimeout(() => {
                        self.loading = false;
                    }, 1000)
                });
            },
            handleSelectClusterSource(item) {
                let self = this;
                self.selectedSourceId = item.id;
                self.mode = "clusterMap"

            },
            handleDelete(item) {
                let self = this;
                this.$confirm('Delete this Cluster map?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    self.$services.clusteringReport.delete({id: item.id}).then(resp => {
                        console.log('Deleted', resp.data);
                        self.refresh();
                    })
                }).catch(() => {
                });

            },
            handleSaveName(item) {
                let self = this,
                    query = {
                        id: item.id,
                        name: item.name
                    };
                self.$services.clusteringReport.save(query).then(resp => {
                    item.editShow = false;
                    self.refresh();
                })
            },
            handleShowFilter(item) {
                let self = this,
                    message = JSON.stringify((item.filter || ''), null, 2);
                self.handleOpenQuery(item.filter);
                /*
                self.$confirm(message, 'Filter', {
                    customClass: 'textareaLikeBox',
                    showCancelButton: true,
                    cancelButtonText: 'Search documents',
                    confirmButtonText: "Close"
                }).then(() => {

                }).catch(() => {
                    self.handleOpenQuery(item.filter)
                });
                */
            },
            handleFilterReports() {
                let self = this,
                    _total = 0,
                    query = (self.filter.query || '').toLowerCase();
                self.dataSourceList.forEach(it => {
                    it.show = !query
                        || it.name.toLowerCase().includes(query)
                        || ((it.creator || {}).fullName || '').toLowerCase().includes(query);
                    if (it.show) {
                        _total++;
                    }
                });
                self.listTotal = _total;
            },
            handleOpenQuery(filter) {
                this.$utils.openSearch(filter);
            },
            notionName(id) {
                return (this.notionsDict.map[id] || {}).name || 'Undefined'
            },
            onChangePage(page) {
                this.filter.offset = this.pageCount * (page - 1);
                // this.refresh();
            },
            formatQueryNotions(text, type = "html") {
                if (text) {
                    let regex = /<\d*>/g;
                    let _query = '';
                    let tags = text.match(regex);
                    let words = text.split(regex);
                    let notions = tags ? tags.map(it => {
                        // return '<span class="notion">' + it.replace(/[<>]/g, "") + '</span>';
                        return (type === 'html') ? '<span class="notion">' + this.notionName(it.replace(/[<>]/g, "")) + '</span> ' : '<' + this.notionName(it.replace(/[<>]/g, "")) + '>';
                    }) : '';
                    words.forEach((it, i) => {
                        _query += it + (notions[i] || '');
                    })
                    return _query;
                }
            },
            fireEvent(data) {
                this.firedData = {incEvent: data};
            },
        },
    }
</script>


<style lang="scss">
    .fst-Clusters {
        .fst-Header {
            .header-left {
                flex-basis: 270px;
            }

            .header-right {
                /*display: none;*/
                flex-basis: auto !important;

                .help-block {
                    border-left: none;
                    padding-left: 0;
                }
            }

            .select-block {
                display: none;
            }
        }

        .data-control {
            margin-top: 30px;

            .el-link.el-link--default {
                color: $iq-dark-theme-light;
                font-weight: 500;

                &:hover {
                    color: $iq-dark-theme-primary;

                    &:after {
                        content: none;
                    }
                }

            }

            div.iqTab {
                > .tbody:first-child > div {
                    align-items: center;
                }

                .tbody > div > div {
                    &:first-child {
                        flex: 0;
                        flex-basis: 425px;
                    }

                    &:nth-child(2) {
                        flex-grow: 0;
                        flex-basis: 130px;
                    }

                    &:nth-child(3) {
                        flex-grow: 0;
                        flex-basis: 175px;
                    }

                    &:last-child {
                        flex-grow: 0;
                    }
                }

                .notion {
                    border-radius: 4px;
                    background: $iq-dark-theme-light;
                    color: #222;
                    padding: 1px 5px;
                    font-weight: 600;
                }
            }

            .date {
                font-size: .8rem;
                line-height: 1.4;
            }

            .filterValues {
                td {
                    cursor: pointer;
                    padding: 1px 5px;
                    font-size: .8rem;
                    vertical-align: top;

                    &:last-child {
                        color: #ffffff;
                    }
                }
            }

            .val {
                > div {
                    max-height: 28px;
                    overflow-y: hidden;
                    word-break: break-word;

                    &.filter {
                        font-variant: all-small-caps;
                        font-size: 12px;
                    }
                }
            }

        }

        .container {
            background: $iq-dark-theme-bg-light-1;
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;
            padding: 10px;
            box-sizing: border-box;
        }

        .el-link.el-link--default {
            color: $iq-dark-theme-light;
        }

        .tbody {
            .pointer {
                color: #b2b2b2;
            }
        }

    }

    @media only screen and (max-width: 1600px) {
        .fst-Clusters {
            .top-control {
                .colorSchemeBlock, .source {
                    flex-wrap: wrap;

                    > label {
                        width: 100%;
                        min-width: 200px;
                    }
                }

                .source {
                    .el-input__inner {
                        //              padding-left: 0;
                    }
                }
            }
        }
    }

</style>
