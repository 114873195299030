<template>
    <div class="widget-block RatedList"
         v-loading="loading">
        <div class="header mb-4">
            <div class="title">
                {{ title }}
                <el-tooltip class="hint" v-if="params.hint"
                            effect="light"
                            :open-delay="500">
                    <div slot="content" v-html="$t(params.hint)"></div>
                    <i class="far fa-question-circle ml-2 cursor-help"></i>
                </el-tooltip>
                <div class="viewAll"
                     v-if="this.$store.getters.swList.mode === 'all'"
                     @click="(e) => handleWordClick(e)">{{ $t('dashboard.viewAll') }}
                </div>
            </div>
            <div class="flex-center">
                <el-select v-model="mode" v-if="params.type === 'TERM_GROWTH'" @change="plotChart">
                    <el-option value="total" :label="$t(params.units)"></el-option>
                    <el-option value="byYears"
                               :label="$t('dashboard.In_the_last') + ' ' + params.numYears + ' ' + $t('dashboard.Years')"></el-option>
                </el-select>
                <div class="units" v-else-if="params.units"
                     :style="{ color: params.color }">{{ $t( params.units) }}
                </div>
                <export-to-image :filename="title" selector=".ratedList"
                                 @startExport="hideShowMore = true"
                                 @finishExport="hideShowMore = false">
                </export-to-image>

            </div>
        </div>
        <div class="ratedList" v-show="words.length" :style="{ color: params.color }">
            <div v-for="(item, key) in words" :key="key" class="listItem" v-show="item.show">
                <div class="text" @click="(e) => handleWordClick(e,item.value)">
                    <div class="title">
                        <flag :iso="item.iso" :squared="false" v-if="item.iso" class="mr-2"></flag>
                        <el-tooltip effect="light"
                                    popper-class="rated-tooltip"
                                    :disabled="item.tooltip && item.tooltip.length < 50"
                                    :content="(key+1 + '.' + item.tooltip)"
                                    placement="top">
                            <span class="label" v-html="item.title"></span>
                        </el-tooltip>
                    </div>
                    <div class="value">{{ item.docCountFormatted }}</div>
                </div>
                <div class="bar multi" v-if="params.type === 'TERM_GROWTH' && mode === 'byYears'">
                    <div v-for="(b,bKey) in item.counts" :key="bKey"
                         @click="(e) => handleWordClick(e,item.value, b.year)"
                         :title="b.year + ': ' + b.value"
                         :style="{ background: b.color, width: b.width }"></div>
                </div>
                <div class="bar multi" v-else-if="params.type === 'TERM_GROWTH' && mode === 'total'">
                    <div :style="{ background: colors[2], width: item.width }"
                         @click="(e) => handleWordClick(e,item.value)"></div>
                </div>
                <div class="bar" :style="{background: params.bgColor, width: item.width }" v-else></div>
            </div>
            <span v-if="params.total && params.total > showRows" class="showMore"
                  v-show="!hideShowMore"
                  @click="handleShowMore">{{ $t('label.showMore') }}
            </span>
            <div class="legend" v-if="mode === 'byYears'">
                <div v-for="(y, yKey) in legend" :key="yKey">
                    <div class="color" :style="{ background: y.color}"></div>
                    <div class="title">{{y.value}}</div>
                </div>
            </div>
        </div>
        <div class="noResult" v-show="words.length < 1">
            No Result
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWRatedList",
        components: {ExportToImage},
        props: {
            filter: Object,
            title: String,
            years: Object,
            params: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                loading: false,
                widgetFilter: {},
                words: [],
                spacing: 1,
                showRows: 0,
                hideShowMore: false,
                chartData: {},
                reQ: {
                    chart: {},
                },
                legend: [],
                mode: 'total',
                colors: this.params.colors || [
                    '#004c6d',
                    '#296080',
                    '#437594',
                    '#5d8ba9',
                    '#75a1be',
                    '#8eb8d3',
                    '#a7cfe9',
                    '#c1e7ff',
                ]
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                self.widgetFilter = _.cloneDeep(self.filter);
                if (self.params.docType) {
                    self.widgetFilter.types = [self.params.docType]
                }

                let yearType = self.params.docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
                if (self.$store.getters.swList.mode === 'all') {
                    self.widgetFilter.facets.requests = [{
                        type: yearType,
                        values: self.years.years
                    }]
                }

                if (self.params.facetRequest) {
                    self.widgetFilter.facets.requests = self.widgetFilter.facets.requests || [];
                    self.widgetFilter.facets.requests.push(self.params.facetRequest)
                }

                let query = {
                    "filter": self.widgetFilter,
                    "widgets": {
                        "chart": {
                            "type": self.params.type || "TERM_COUNT",
                            "field": self.params.field,
                            "limit": self.params.total || self.params.limit,
                        },
                    }
                };

                if (self.params.type === "TERM_GROWTH") {
                    query.widgets.chart.years = {
                        "minBoundType": "CLOSED",
                        "maxBoundType": "CLOSED",
                        "min": self.years.min,
                        "max": self.years.max
                    }

                }

                if (typeof (self.params.significant) !== 'undefined') {
                    query.widgets.chart.significant = self.params.significant;
                }
                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }

                self.loading = true;
                self.reQ.chart = self.$services.analytics.widgets(query);

                self.reQ.chart.then(resp => {

                    self.chartData = resp.data.widgets.chart.terms;

                    // console.log(`-- aKw ${self.field} Data: `, _data);

                    self.plotChart();
                    self.loading = false;
                });
            },
            plotChart() {
                let self = this,
                    _data = _.cloneDeep(self.chartData),
                    numYears = self.params.numYears * -1;

                // -------------- Rated List ---------------
                let max = 0,
                    baseColor = self.params.color.split(',').slice(0, 2).join(','),
                    ii = 0;

                if (self.mode === 'byYears') {
                    _data = _.map(_data, it => {
                        it.totalByYears = _.reduceRight((it.counts || []), function (sum, n, i) {
                            return parseInt(sum) + (self.years.years.slice(numYears).includes(i) ? parseInt(n) : 0);
                        });
                        return it;
                    });
                    max = _.max(_.map(_data, 'totalByYears'));
                    // console.log(_data,_.map(_data, 'totalByYears'));
                    let yc = 0;
                    self.legend = self.years.years.slice(numYears).map(y => {
                        let out = {
                            value: y,
                            color: self.colors[yc]
                        }
                        yc++;
                        return out;
                    })
                } else {
                    max = _.max(_.map(_data, it => parseInt(it.docCount)));
                }
                self.showRows = self.params.limit;
                self.words = _.orderBy(_.map(_data, (it) => {
                    ii++;
                    let counts = {},
                        yc = 0;
                    if (self.params.type === "TERM_GROWTH") {
                        self.years.years.slice(numYears).forEach(y => {
                            counts[y] = {
                                // color: self.$utils.getColor(yc),
                                color: self.colors[yc],
                                value: self.$utils.toFin(it.counts[y]),
                                year: y,
                                yc: yc,
                                width: (parseInt(it.counts[y]) / max * 100) + '%'
                            }
                            yc++;
                        })
                        // console.log('counts', counts);
                    }
                    let _docCount = (self.mode === 'byYears') ? it.totalByYears : it.docCount;
                    let _title = (it.name || it.value);
                    return {
                        title: _title,
                        totalByYears: it.totalByYears,
                        value: it.value,
                        tooltip: (self.params.tooltipToUpper ? _title.toUpperCase() : (_title)).replace(/->/g, "\n- - - - - - - - \n"),
                        show: !self.params.total || self.params.limit > ii,
                        docCountFormatted: self.$utils.toFin(_docCount),
                        docCount: parseInt(_docCount),
                        iso: self.params.isCountry ? self.$utils.countryToISO((it.value || '').toLowerCase()) : '',
                        width: Math.ceil(parseInt(it.docCount) / max * 100) + '%',
                        counts: counts
                    };
                }), 'docCount', 'desc');

            },
            handleWordClick(e, keyword = null, year = '') {
                // console.log('keyword:', keyword);
                let self = this,
                    filter = self.widgetFilter

                let docType = self.params.docType || filter.types[0],
                    facets = {};

                facets.requests = _.cloneDeep(filter.facets.requests) || [];

                if (keyword) {
                    facets.requests.push({
                        type: self.params.facet,
                        values: [keyword]
                    })
                }
                if (self.mode === 'byYears') {
                    let _years = year ? [year] : self.years.years.slice((-1 * self.params.numYears));
                    facets.requests.push({
                        type: 'YEAR',
                        values: _years
                    })
                }
                // if (self.params.facetRequest) {
                //     facets.requests = facets.requests || [];
                //     facets.requests.push(self.params.facetRequest);
                // }

                // console.log('Facets', facets, self.request.filter);
                let params = {
                    query: filter.nativeQuery ? filter.nativeQuery.value : filter.query,
                    isNativeQuery: !!filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                // console.log('Facets', facets, self.request.filter);

                if (e.button === 0) {
                    self.$utils.openSearch(params);
                }

            },
            handleShowMore() {
                let self = this;
                self.showRows += self.params.limit;
                self.words = self.words.map((it, i) => {
                    it.show = i <= self.showRows;
                    return it;
                })
            },
            handleCloudUpdate(e) {
                // console.log('e',e);
                this.loading = false;

            },
        }
    }
</script>

<style lang="scss">
    .rated-tooltip {
        white-space: pre-line;
        max-width: 33vw;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        font-variant: all-small-caps;
    }

</style>

<style scoped lang="scss">
    .units {
        //color: $iq-dark-theme-light;
    }

    .ratedList {
        /*
        max-height: 375px;
        overflow-y: auto;
        padding: 5px 10px 5px 0;
        margin-right: -10px;
        */
        .listItem {
            position: relative;
            padding: 0 5px;

            .title {
                font-variant: all-small-caps;
                display: flex;
                align-items: center;
            }

            .label {
                white-space: nowrap;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }

            .text {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                position: relative;
                z-index: 200;
            }

            .bar {
                top: -3px;
                height: 25px;
                opacity: 0.3;
                position: absolute;
                margin: 0 -5px;

                &.multi {
                    display: flex;
                    width: 100%;
                    opacity: 1;
                    position: static;
                    margin: 2px 0 0 0;
                    height: 17px;

                    > div {
                        cursor: pointer;
                    }
                }
            }

            & + .listItem {
                margin-top: 15px;
            }
        }

        .showMore {
            margin-top: 15px;
            font-variant: all-small-caps;
            color: $iq-color-primary;
            cursor: pointer;
            transition: all .15s ease;
            display: inline-block;
            font-weight: 500;
            font-size: 14px;

            &:hover {
                color: fade_out($iq-color-primary, .2)
            }
        }

        .legend {
            display: flex;
            margin-top: 15px;
            width: 300px;

            > div {
                flex: 1;
                width: 50px;
                font-size: 13px;
                text-align: center;

                .color {
                    height: 17px;
                }

                .title {
                    margin-top: 2px;
                }
            }
        }
    }

</style>