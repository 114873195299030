import Vue from 'vue';
import dates from './dates';
import WikiText from '@/commons/components/WikiText';
import TranslatedText from '@/commons/components/TranslatedText';
import axios from "axios";
import store from '@/store';
import globalStates from '@/store/state'
import BitSet from 'mnemonist/bit-set';
import _ from "lodash";
import * as htmlToImage from 'html-to-image';
import moment from "moment";
import uuid from 'uuid';
import services from '@/commons/services'


function numberFormat(val) {
    return String(val).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
}

function toBitset(array) {
    if (!(array instanceof Uint32Array)) {
        if (!array) {
            throw new Error('Array is required')
        }
        if (array instanceof ArrayBuffer) {
            return toBitset(new Uint32Array(array));
        }
        if (array.buffer instanceof ArrayBuffer) {
            return toBitset(new Uint32Array(array.buffer));
        }
        throw new Error('Unsupported array type')
    }
    const bitSet = new BitSet(array.length * 32);
    bitSet.array = array;
    bitSet.forEach((bit, i) => {
        bit && bitSet.size++
    });
    return bitSet;
}

function windowParams(width, height, scrollbars) {
    if (arguments.length === 1) {
        var el = arguments[0];
        return windowParams(el.width, el.height, el.scrollbars);
    }

    function size(size, maxSize) {
        if (_.isString(size) && size.endsWith('%')) {
            size = parseInt(size.substring(0, size.length - 1));
            size = (size ? size / 100 : 1) * maxSize;
        }
        if (!size) {
            size = maxSize;
        }
        return Math.round(Math.min(maxSize, size));
    }

    width = size(width, window.innerWidth);
    height = size(height, window.innerHeight);
    return 'resizable=yes,scrollbars=' + (scrollbars || 'yes') + ',toolbar=no,status=yes,location=no,width=' + width + ',height=' + height;
}


const Base64 = (() => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    // Use a lookup table to find the index.
    const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
    for (let i = 0; i < chars.length; i++) {
        lookup[chars.charCodeAt(i)] = i;
    }

    function encode(arraybuffer) {
        const bytes = new Uint8Array(arraybuffer),
            len = bytes.length,
            base64 = [];

        let i, byte0, byte1, byte2;
        for (i = 0; i < len; i += 3) {
            byte0 = bytes[i];
            byte1 = bytes[i + 1];
            byte2 = bytes[i + 2];
            base64.push(chars[byte0 >> 2]);
            base64.push(chars[((byte0 & 3) << 4) | (byte1 >> 4)]);
            base64.push(chars[((byte1 & 15) << 2) | (byte2 >> 6)]);
            base64.push(chars[byte2 & 63]);
        }

        if (len % 3 === 2) {
            base64[base64.length - 1] = '=';
        } else if (len % 3 === 1) {
            base64[base64.length - 1] = '=';
            base64[base64.length - 2] = '=';
        }
        return base64.join('');
    }

    function decode(base64) {
        let bufferLength = base64.length * 0.75,
            len = base64.length,
            i,
            p = 0,
            encoded1,
            encoded2,
            encoded3,
            encoded4;

        if (base64[base64.length - 1] === '=') {
            bufferLength--;
            if (base64[base64.length - 2] === '=') {
                bufferLength--;
            }
        }

        const arraybuffer = new ArrayBuffer(bufferLength),
            bytes = new Uint8Array(arraybuffer);

        for (i = 0; i < len; i += 4) {
            encoded1 = lookup[base64.charCodeAt(i)];
            encoded2 = lookup[base64.charCodeAt(i + 1)];
            encoded3 = lookup[base64.charCodeAt(i + 2)];
            encoded4 = lookup[base64.charCodeAt(i + 3)];

            bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
            bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
            bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
        }

        return arraybuffer;
    }

    return {encode, decode};
})();


const Utils = Vue.prototype.$utils = Object.freeze({
    dates,
    numbers: {
        format: numberFormat
    },
    bytes: {
        humanizeBytes(bytes) {
            if (!bytes) {
                return '';
            }
            bytes = bytes && parseInt(bytes);
            const kb = 1024, mb = kb * kb, gb = mb * kb, tb = gb * kb;
            if (!bytes || bytes < kb) {
                return `${bytes} B`;
            } else if (bytes < mb) {
                return `${(bytes / kb).toFixed(2)} KB`;
            } else if (bytes < gb) {
                return `${(bytes / mb).toFixed(2)} MB`;
            } else if (bytes < tb) {
                return `${(bytes / gb).toFixed(2)} GB`;
            }
            return `${(bytes / tb).toFixed(2)} TB`;
        }
    },
    getSelectionText() {
        let text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;
        }
        return text;
    },
    roundX(x = 0, d = 0, r = 0) {
        x = parseFloat(x);
        r = parseInt(r); // минимальное количество значимых чисел после запятой. roundX(0.00000123456, 1, 3) = 0.00000123
        d = r && Math.abs(x) < 0.1 ? ((x.toString().match(/0+/g) || {})[1] || '000000').match(/0/g).length + r : d;
        let n = Math.pow(10, parseInt(d));
        return Math.round(x * n) / n;
    },
    median(values) {
        if (values.length === 0) throw new Error("No inputs");
        values.sort(function (a, b) {
            return a - b;
        });
        let half = Math.floor(values.length / 2);
        if (values.length % 2)
            return values[half];
        return (values[half - 1] + values[half]) / 2.0;
    },
    momentIt(params) {
        return moment(params);
    },
    pointDistance(x, y, x1, y1, x2, y2) {
        // x,y - точка,
        // x1,y1  x2,y2 - отрезок

        let A = x - x1;
        let B = y - y1;
        let C = x2 - x1;
        let D = y2 - y1;

        let dot = A * C + B * D;
        let len_sq = C * C + D * D;
        let param = -1;
        if (len_sq != 0) { //in case of 0 length line
            param = dot / len_sq;
        }

        let xx, yy;

        if (param < 0) {
            xx = x1;
            yy = y1;
        } else if (param > 1) {
            xx = x2;
            yy = y2;
        } else {
            xx = x1 + param * C;
            yy = y1 + param * D;
        }

        let dx = x - xx;
        let dy = y - yy;
        return Math.sqrt(dx * dx + dy * dy);
    },
    toFin(num, dec = 0) { // форматирование сумм в виде XXX XXX XXX
        if (num) {
            num = parseFloat(num);
            if (Math.abs(num) >= 100) {
                dec = 0;
            } else if (Math.abs(num) >= 10) {
                dec = 1;
            }
            return num ? this.roundX(num, dec).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
        } else {
            return 0;
        }
    },
    toFinHuman(num, dec = 0) { // форматирование сумм в human формате: 5K, 100M
        let suffix = '',
            out = {value: '0', suffix: ''};
        if (num) {
            num = parseFloat(num);
            if (Math.abs(num) >= 1000000000) {
                suffix = "B";
                num = num / 1000000000;
                dec = 1;
            } else if (Math.abs(num) >= 1000000) {
                suffix = "M";
                num = num / 1000000;
                dec = 1;
            } else if (Math.abs(num) >= 1000) {
                suffix = "k";
                num = num / 1000;
                dec = 1;
            } else if (Math.abs(num) >= 10) {
                dec = 1;
            }
            out.value = num ? this.roundX(num, dec).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ') : 0;
        } else {
            out.value = 0;
        }
        out.suffix = suffix;
        out.number = suffix ? out.value + " " + suffix : out.value;
        out.numberSpanned = suffix ? out.value + '<span class="numSuffix">' + suffix + '</span>' : out.value;
        return out;
    },
    fromFinHuman(num) {
        let out = parseFloat(num.replace(/\s/gi, '').replace(/[A-z]/gi, '').replace(',', '.')) || 1,
            letters = [{
                letter: 'k',
                value: 1000
            }, {
                letter: 'm',
                value: 1000000
            }, {
                letter: 'b',
                value: 1000000000
            }]
        letters.forEach((it) => {
            let re = new RegExp(it.letter, "gi"),
                count = num.match(re) ? num.match(re).length : 0;
            if (count) {
                out = out * Math.pow(it.value, count);
            }
        })
        return out;
    },
    tourNextStep(params) {
        let self = this,
            _tour = params.tour,
            _curStep = _tour.curStep;
        params.step === 'back' ? _curStep.index--
            : (params.step || params.step === 0) ? _curStep.index = params.step
            : _curStep.index++;
        let _step = _tour.steps.filter(stp => {
                return !stp.hide && (!stp.availableForRole || globalStates.allowedActions.contains(stp.availableForRole))
            })[_curStep.index],
            out,
            _actions = _step ? _step.actions || [] : [];
        if (_step) {
            _curStep.title = _step.title;
            _curStep.text = _step.text;
            _.forEach(_tour.highlight, (it, i) => {
                _tour.highlight[i] = false;
            });

            let noteSelector = _step.noteElement || '.tour-step-note';
            let Note = document.querySelector(noteSelector);

            if (_actions.length) {
                _actions.forEach(_action => {
                    if (_action.type === 'highlight') {
                        if (Array.isArray(_action.target)) {
                            _action.target.forEach(tar => {
                                _tour[_action.type][tar] = true;
                            })
                        } else {
                            _tour[_action.type][_action.target] = true;
                        }
                        if (_action.timeout) {
                            setTimeout(() => {
                                _tour[_action.type][_action.target] = false;
                            }, _action.timeout)
                        }
                    } else if (_action.type === 'click') {
                        if (_action.target) {
                            let $elem = document.querySelector(_action.target);
                            // console.log('$elem:', $elem);
                            $elem.click();
                            out = 'click';
                        }
                    } else if (_action.type === 'predicateText') {
                        out = {predicateText: _action.text}
                    } else {
                        out = _action.type;
                    }
                    if (_action.noteCoords) {
                        let _noteCoords = _action.noteCoords,
                            _anglePlace = _action.anglePlacement;
                        let _targetElement = _noteCoords && _noteCoords.targetElement || '.tourHighlight',
                            _el = document.querySelector(_targetElement);
                        if (_noteCoords) {
                            let _coord = _el ? _el.getBoundingClientRect()
                                : {
                                    left: 0,
                                    width: 0,
                                    top: 0,
                                    height: 0
                                }
                            _.each(_noteCoords, (val, key) => {
                                let num = parseInt(val);
                                if (num || num === 0) {
                                    let _shift = _coord[key];
                                    if (_noteCoords.targetCoords) {
                                        if (key === 'left') {
                                            _shift = _coord.left + _coord.width / (_anglePlace === 'top' ? 2 : 1);
                                        } else if (key === 'right') {
                                            _shift = _coord.left - 410;
                                        } else if (key === 'top') {
                                            _shift = _coord[key] + _coord.height / (_anglePlace === 'left' ? 2 : 1);
                                        }
                                    }
                                    if (key === 'width') {
                                        Note.style.width = num + 'px';
                                    } else {
                                        let _key = ['right', 'left'].includes(key) ? 'left' : 'top';
                                        Note.style[_key] = num + _shift + 'px';
                                        Note.style.removeProperty('width');
                                    }
                                } else {
                                    Note.style[key] = val;
                                }
                            });
                        }
                        if (_anglePlace) {
                            Note.classList.add('angle-' + _step.content.anglePlacement);
                        }
                        Note.classList.remove('hidden');
                    } else {
                        Note.style = '';
                    }
                });
            }

        } else {
            if (_curStep.index < 0) {
                out = 'previousTourBlock';
                _curStep.index++;
            } else {
                out = 'nextTourBlock';
                _curStep.index--;
            }
        }
        return out;
    },
    translateText2(params) {
        let maxChunk = 999,
            maxNumChunks = 3,
            textResult = '',
            numChunks = Math.ceil(params.text.length / maxChunk);
        for (let i = 0; i < numChunks && i < maxNumChunks; i++) {
            let chunkText = params.text.slice(i * maxChunk, (i + 1) * maxChunk);
            this.sendToTranslate({
                text: chunkText,
                tl: params.tl
            }).then(function (resp) {
                if (resp.data && resp.data.data.translation) {
                    textResult += resp.data.data.translation;
                }
            })
        }
        console.log('transParams:', params);

    },
    stripTags(text) {
        return text.replace(/(<([^>]+)>)/ig, "");
    },
    getRandom(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; //Максимум и минимум включаются
    },
    openSearch(param) {
        let self = this,
            _data = {};
        _.each(param, (it, ind) => {
            _data[ind] = it;
        })
        window.localStorage.setItem('wordStatQuery', JSON.stringify(_data));
        window.open(document.location.origin + store.state.contextUrl + '/', '_blank');
    },
    cpcNameFormat(str, mode = '') {
        // console.log('initial str:', _.clone(str));
        let code = str.split(' ')[0],
            name = _.last(str.split('->'));
        if (mode !== 'full') {
            str = code && name ? (code + ' ' + name) : str;
        }
        let endSlice = str.indexOf('(');
        if (endSlice !== -1) {
            str = str.slice(0, endSlice);
        }
        return str.replace(/[{}]/g, '');
    },
    toBitset(array) {
        if (!(array instanceof Uint32Array)) {
            if (!array) {
                throw new Error('Array is required')
            }
            if (array instanceof ArrayBuffer) {
                return toBitset(new Uint32Array(array));
            }
            if (array.buffer instanceof ArrayBuffer) {
                return toBitset(new Uint32Array(array.buffer));
            }
            throw new Error('Unsupported array type')
        }
        const bitSet = new BitSet(array.length * 32);
        bitSet.array = array;
        bitSet.forEach((bit, i) => {
            bit && bitSet.size++
        });
        return bitSet;
    },
    toBase64(bitSet) {
        return !bitSet ? null : Base64.encode(bitSet.array.buffer);
    },
    fromBase64(base64) {
        return !(base64 && base64.length) ? null : toBitset(new Uint32Array(Base64.decode(base64)));
    },
    loadBitSetAsJson(data) {
        // Документация к BitSet-у:
        // https://yomguithereal.github.io/mnemonist/bit-set.html
        const bitSet = this.fromBase64(data);

        // const result = [], indices = [];
        // bitSet.forEach((bit, i) => {
        //     result.push(bit);
        //     if (bit) indices.push(i);
        // });
        // console.info(result.join(''), indices);
        return bitSet;
    },
    bitSetIndices(bitSet) {
        if (!bitSet || !bitSet.forEach) return [];
        const indices = [];
        bitSet.forEach((bit, i) => {
            if (bit) indices.push(i);
        });
        return indices;
    },
    translateText(params) {
        params.text = this.stripTags(params.text.substring(0, 1499));
        // let token = 'eyJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOlsidHJhbnNsYXRlIl0sInN1YiI6ImlseWEuZ3Jpc2h1a292QHJ0LnJ1IiwiaWF0IjoxNzAwMDM5OTg4LCJleHAiOjI1NjM5NTM1ODh9.Hhom1dmcVWBCN5kW04UZrUCZPTYc5altZLskhexmeXU',
        //     options = {
        //         method: 'POST',
        //         url: 'https://ai.rt.ru/api/1.0/translate/local',
        //         data: {
        //             "uuid": null,
        //             "translationRequest": {
        //                 "text": encodeURIComponent(params.text),
        //                 "target_lang": "rus_Cyrl",
        //                 "source_lang": "eng_Latn"
        //             }
        //         }, // {text: this.input, tl: this.lang}
        //         headers: {
        //             Authorization: `Bearer ${token}`
        //             'x-rapidapi-host': 'google-translate20.p.rapidapi.com',
        //             'x-rapidapi-key': '5dcb3128a2msh6d766a7130c6a38p1cf3bajsnbf12a2acad5e'
        // }
        // };

        // let data = "text=" + encodeURIComponent(params.text.substring(0, 900)) + "&to=" + params.tl;
        // const options = {
        //     method: 'POST',
        //     url: 'https://nlp-translation.p.rapidapi.com/v1/translate',
        //     headers: {
        //         'content-type': 'application/x-www-form-urlencoded',
        //         'x-rapidapi-key': '5dcb3128a2msh6d766a7130c6a38p1cf3bajsnbf12a2acad5e',
        //         'x-rapidapi-host': 'nlp-translation.p.rapidapi.com'
        //     },
        //     data: data
        // };
        // return axios.request(options);
        return services.documents.translate({
            text: params.text,
        })
    },
    wikiTranslateContext(context, event, value) {
        value = value ? value : this.getSelectionText();
        if (value) {
            event.preventDefault();
            context.$contextMenu(event, [
                // {
                //     text: 'Copy',
                //     icon: 'far fa-copy fw',
                //     handler: () => {
                //         document.execCommand('copy');
                //     }
                // },
                {
                    text: 'Wiki',
                    ':value': value,
                    icon: 'far fa-globe fw',
                    handler: () => {
                        context.$modal.dialog(WikiText, {
                            title: "Wikipedia",
                            ':value': value,
                            params: {
                                style: 'background: #ecf5ff;'
                            },
                        }).catch(() => {
                        });
                    }
                }, {
                    text: 'Translate',
                    icon: 'far fa-language fw',

                    handler: () => {
                        context.$modal.dialog(TranslatedText, {
                            title: "Translation",
                            ':value': value,
                            size: 'md',
                            params: {
                                style: 'background: #ecf5ff;'
                            },
                            buttons: [{
                                name: 'Close',
                                type: 'text',
                                handler: data => {
                                    data.dialog.close();
                                }
                            }]
                        }).catch(() => {
                        });
                    }
                }]).then(console.info, console.error)
        }
    },
    exportToPng(element, filename = 'exportImg.png') {
        return htmlToImage.toPng(element)
            .then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = filename;
                link.href = dataUrl;
                link.click();
            });
    },
    countryToISO(str) {
        // console.log('str', str);
        str = str ? this.stripTags(str.toString().toLowerCase()) : '';
        let codes = {
            "afghanistan": "af",
            "åland islands": "ax",
            "albania": "al",
            "algeria": "dz",
            "american samoa": "as",
            "andorra": "ad",
            "angola": "ao",
            "anguilla": "ai",
            "antarctica": "aq",
            "antigua and barbuda": "ag",
            "argentina": "ar",
            "armenia": "am",
            "aruba": "aw",
            "australia": "au",
            "austria": "at",
            "azerbaijan": "az",
            "bahamas": "bs",
            "bahrain": "bh",
            "bangladesh": "bd",
            "barbados": "bb",
            "belarus": "by",
            "belgium": "be",
            "belize": "bz",
            "benin": "bj",
            "bermuda": "bm",
            "bhutan": "bt",
            "bolivia": "bo",
            "bosnia and herzegovina": "ba",
            "botswana": "bw",
            "bouvet island": "bv",
            "brazil": "br",
            "british indian ocean territory": "io",
            "brunei darussalam": "bn",
            "bulgaria": "bg",
            "burkina faso": "bf",
            "burundi": "bi",
            "burma": "mm",
            "cambodia": "kh",
            "cameroon": "cm",
            "canada": "ca",
            "cape verde": "cv",
            "cayman islands": "ky",
            "central african republic": "cf",
            "chad": "td",
            "chile": "cl",
            "china": "cn",
            "christmas island": "cx",
            "cocos (keeling) islands": "cc",
            "colombia": "co",
            "comoros": "km",
            "congo (brazzaville)": "cg",
            "congo": "cg",
            "democratic republic of congo": "cd",
            "democratic republic congo": "cd",
            "cook islands": "ck",
            "costa rica": "cr",
            "côte d'ivoire": "ci",
            "cote divoire": "ci",
            "cote d'ivoire": "ci",
            "croatia": "hr",
            "cuba": "cu",
            "curaçao": "cw",
            "curacao": "cw",
            "cyprus": "cy",
            "czech republic": "cz",
            "czechoslovakia": "cz",
            "denmark": "dk",
            "djibouti": "dj",
            "dominica": "dm",
            "dominican republic": "do",
            "ecuador": "ec",
            "egypt": "eg",
            "el salvador": "sv",
            "equatorial guinea": "gq",
            "eritrea": "er",
            "estonia": "ee",
            "ethiopia": "et",
            "falkland islands (malvinas)": "fk",
            "faroe islands": "fo",
            "fiji": "fj",
            "finland": "fi",
            "france": "fr",
            "french guiana": "gf",
            "french polynesia": "pf",
            "french southern territories": "tf",
            "gabon": "ga",
            "gambia": "gm",
            "georgia": "ge",
            "germany": "de",
            "germany (democratic republic, ddr)": "de",
            "ghana": "gh",
            "gibraltar": "gi",
            "greece": "gr",
            "greenland": "gl",
            "grenada": "gd",
            "guadeloupe": "gp",
            "guam": "gu",
            "guatemala": "gt",
            "guernsey": "gg",
            "guinea": "gn",
            "guinea-bissau": "gw",
            "guyana": "gy",
            "haiti": "ht",
            "heard island and mcdonald islands": "hm",
            "vatican city state": "va",
            "honduras": "hn",
            "hong kong": "hk",
            "hungary": "hu",
            "iceland": "is",
            "india": "in",
            "indonesia": "id",
            "iran": "ir",
            "iraq": "iq",
            "ireland": "ie",
            "isle of man": "im",
            "israel": "il",
            "italy": "it",
            "jamaica": "jm",
            "japan": "jp",
            "jersey": "je",
            "jordan": "jo",
            "kazakhstan": "kz",
            "kenya": "ke",
            "kiribati": "ki",
            "north korea": "kp",
            "democratic people's republic of korea": "kp",
            "south korea": "kr",
            "kuwait": "kw",
            "kyrgyzstan": "kg",
            "laos": "la",
            "latvia": "lv",
            "lebanon": "lb",
            "lesotho": "ls",
            "liberia": "lr",
            "libyan arab jamahiriya": "ly",
            "liechtenstein": "li",
            "lithuania": "lt",
            "luxembourg": "lu",
            "macao": "mo",
            "north macedonia": "mk",
            "macedonia": "mk",
            "madagascar": "mg",
            "malawi": "mw",
            "malaysia": "my",
            "maldives": "mv",
            "mali": "ml",
            "malta": "mt",
            "marshall islands": "mh",
            "martinique": "mq",
            "mauritania": "mr",
            "mauritius": "mu",
            "mayotte": "yt",
            "mexico": "mx",
            "micronesia": "fm",
            "federated states of micronesia": "fm",
            "moldova": "md",
            "monaco": "mc",
            "mongolia": "mn",
            "montenegro": "me",
            "montserrat": "ms",
            "morocco": "ma",
            "mozambique": "mz",
            "myanmar": "mm",
            "namibia": "na",
            "nauru": "nr",
            "nepal": "np",
            "netherlands": "nl",
            "netherlands antilles": "an",
            "new caledonia": "nc",
            "new zealand": "nz",
            "nicaragua": "ni",
            "niger": "ne",
            "nigeria": "ng",
            "niue": "nu",
            "norfolk island": "nf",
            "northern mariana islands": "mp",
            "norway": "no",
            "oman": "om",
            "pakistan": "pk",
            "palau": "pw",
            "palestina": "ps",
            "palestine": "ps",
            "panama": "pa",
            "papua new guinea": "pg",
            "paraguay": "py",
            "peru": "pe",
            "philippines": "ph",
            "pitcairn": "pn",
            "poland": "pl",
            "portugal": "pt",
            "puerto rico": "pr",
            "qatar": "qa",
            "réunion": "re",
            "reunion": "re",
            "romania": "ro",
            "russia": "ru",
            "russian federation": "ru",
            "rwanda": "rw",
            "saint barthélemy": "bl",
            "saint barthelemy": "bl",
            "saint helena": "sh",
            "saint kitts and nevis": "kn",
            "saint lucia": "lc",
            "saint martin dutch part": "mf",
            "saint martin (dutch part)": "mf",
            "saint martin (french part)": "mf",
            "saint pierre and miquelon": "pm",
            "saint vincent and the grenadines": "vc",
            "samoa": "ws",
            "san marino": "sm",
            "sao tome and principe": "st",
            "saudi arabia": "sa",
            "senegal": "sn",
            "serbia": "rs",
            "seychelles": "sc",
            "sierra leone": "sl",
            "singapore": "sg",
            "slovakia": "sk",
            "slovenia": "si",
            "solomon islands": "sb",
            "somalia": "so",
            "south africa": "za",
            "south georgia and the south sandwich islands": "gs",
            "spain": "es",
            "sri lanka": "lk",
            "sudan": "sd",
            "south sudan": "sd",
            "suriname": "sr",
            "svalbard and jan mayen": "sj",
            "swaziland": "sz",
            "sweden": "se",
            "switzerland": "ch",
            "syrian arab republic": "sy",
            "taiwan": "tw",
            "tajikistan": "tj",
            "tanzania": "tz",
            "thailand": "th",
            "timor-leste": "tl",
            "togo": "tg",
            "tokelau": "tk",
            "tonga": "to",
            "trinidad and tobago": "tt",
            "tunisia": "tn",
            "turkey": "tr",
            "turkmenistan": "tm",
            "turks and caicos islands": "tc",
            "tuvalu": "tv",
            "uganda": "ug",
            "ukraine": "ua",
            "united arab emirates": "ae",
            "united kingdom": "gb",
            "united states": "us",
            "united states minor outlying islands": "um",
            "uruguay": "uy",
            "uzbekistan": "uz",
            "vanuatu": "vu",
            "venezuela": "ve",
            "viet nam": "vn",
            "virgin islands, british": "vg",
            "virgin islands british": "vg",
            "virgin islands (british)": "vg",
            "virgin islands (u.s.)": "vi",
            "virgin islands, u.s.": "vi",
            "virgin islands u s": "vi",
            "wallis and futuna": "wf",
            "western sahara": "eh",
            "yugoslavia": "yu",
            "yemen": "ye",
            "yemen, democratic people's republic": "ye",
            "zambia": "zm",
            "zaire": "cd",
            "zimbabwe": "zw",
            "european union": "eu",
            "europe": "eu",
            "bonaire, saint eustatius and saba": 'bq',
            "world": "un",
        }
        // if(!codes[str]) console.log(str, codes[str]);
        return codes[str] || '';
    },
    requestFullScreen(element = null) {
        element = element || document.querySelector('body');
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
        if (requestMethod) { // Native full screen.
            requestMethod.call(element);
        } else if (typeof window.ActiveXObject !== "undefined") {
            let wscript = new ActiveXObject("WScript.Shell");
            if (wscript !== null) {
                wscript.SendKeys("{F11}");
            }
        }
    },

    openInNewWindow(params) {
        return new Promise((resolve, reject) => {
            params = params || {};
            params.data = params.data || {};
            params.target = params.target || uuid();
            params.url = params.url || '';
            params.windowParams = params.windowParams || {width: '80%', height: '90%'};

            const wnd = window.open(params.url, params.target, windowParams(params.windowParams));

            function success() {
                try {
                    resolve(wnd);
                } catch (e) {
                    console.error('Resolve opened window with params ', params, ' error ', e);
                }
            }

            const dateKeys = Object.keys(params.data);
            if (dateKeys.length === 0) {
                setTimeout(success, 300);
                return;
            }

            // form
            try {
                const form = document.createElement('form');
                for (let i = 0; i < dateKeys.length; i++) {
                    const name = dateKeys[i];
                    let val = params.data[name];
                    if (!_.isString(val)) {
                        val = JSON.stringify(Service.jsonApiPreProcessing(val));
                    }
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = name;
                    input.value = val;
                    form.appendChild(input);
                }
                document.getElementsByTagName('body')[0].appendChild(form);
                form.action = params.url;
                form.method = 'post';
                form.target = params.target;
                form.submit();
                document.getElementsByTagName('body')[0].removeChild(form);
            } catch (e) {
                console.error('Open window with params ', params, ' error ', e);
                wnd.close();
                reject(e);
                return;
            }
            setTimeout(success, 300);
        });
    },

    getColor(id) {
        let colors =
            [
                "#A4CCE3",
                "#e08050",
                "#A57283",
                "#5784BA",
                "#F7CE76",
                "#81B1CC",
                "#8DA47E",
                "#A9C8C0",
                "#AE8A8C",
                "#d020d0",
                "#DCDBD9",
                "#E9BBB5",
                "#EEB8C5",
                "#4382BB",
                "#B2B2B2",
                "#7C98AB",
                "#D29F8C",
                "#D0BCAC",
                "#C1D5DE",
                "#9AC8EB",
                "#DBD0c0",
                "#F27348",
                "#BEB4C5",
                "#8C7386",
                "#77ee44",
                "#C47482",
                "#CA9C95",
                "#E8D6CF",
                "#2CCED2",
                "#37667E",
                "#AAD9CD",
                "#CCD4BF",
                "#C54B6C",
                "#DDF2F4",
                "#E7CBA9",
                "#D5E4C3",
                "#84A6D6",
                "#9C9359",
                "#874741",
                "#698396",
                "#E4CEE0",
                "#B8E0F6",
                "#A15D98",
                "#80396E",
                "#775ad0",
                "#897C87",
                "#DEC4D6",
                "#76CDCD",
                "#E6A57E",
                "#DBBC8E",
                "#F5F3E7",
                "#E5B3BB",
                "#FEC7BC",
                "#F9CAD7",
                "#DC828F",
                "#C2D9E1",
                "#D29F8C",
                "#D9D3D2",
                "#81B1CC",
                "#FFD9CF",
                "#46d02B",
                "#76504E",
                "#FBECDB",
                "#F3CBBD",
                "#90CDC3",
                "#AF8C72",
                "#938F43",
                "#D3CCCA",
                "#A37E7E",
                "#86736C",
            ]
        let _id = id - (colors.length * Math.floor(id / colors.length));
        // console.log('color::', _id, colors[_id]);
        return colors[_id];
    },

});

export default Utils;
