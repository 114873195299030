import _ from 'lodash'
import uuid from 'uuid'
import IqpDialog from './IqpDialog'
import IqpDialogPrompt from './IqpDialogPrompt'
import IqpDialogMessage from './IqpDialogMessage'
import ErrorMessageView from './../ErrorMessageView.vue'
import Modal from './Modal'
import ModalsContainer from './ModalsContainer'
import i18n from '@/commons/i18n';

const SIZES = {
    DEFAULT: {
        width: '900px',
        height: 'auto'
    },
    sm: {
        width: '40%',
        height: 'auto',
        scrollable: true
    },
    md: {
        width: '60%',
        height: 'auto',
        scrollable: true
    },
    lg: {
        width: '75%',
        height: 'auto',
        scrollable: true
    },
    wide: {
        width: '90%',
        height: 'auto',
        scrollable: true
    },
    'center-75': {
        width: '75%',
        height: '90%'
    },
    'center-wide': {
        width: '90%',
        height: '90%'
    }
};

const Plugin = {
    install(Vue, ops = {}) {
        /**
         * Makes sure that plugin can be installed only once
         */
        if (this.installed) {
            return;
        }

        this.installed = true;
        this.event = new Vue();
        this.dynamicContainer = null;
        this.componentName = ops.componentName || 'modal';

        const _getModalsContainer = Symbol('_getModalsContainer');
        class ModalObj {
            constructor(component) {
                this.component = component;
            }
            [_getModalsContainer](options) {
                const root = this.component && this.component.$root ? this.component.$root : (options.root ? options.root : Plugin.rootInstance);
                if (!root._dynamicContainer) {
                    const modalsContainer = document.createElement('div');
                    document.body.appendChild(modalsContainer);

                    new Vue({
                        parent: root,
                        render: h => h(ModalsContainer)
                    }).$mount(modalsContainer)
                }
                return root._dynamicContainer
            }
            show(Dialog, component, options){
                if(_.isString(Dialog)) {
                    Plugin.event.$emit('toggle', Dialog);
                    return;
                }
                options || (options = {});

                const container = this[_getModalsContainer](options);

                if (container === null) {
                    console.warn('[vue-js-modal] In order to render dynamic modals, a <modals-container> component must be present on the page');
                    return;
                } else if(!Dialog || !component) {
                    console.warn('dialog or component is undefined');
                    return;
                }
                const conf = {name: uuid(), minWidth: 300, adaptive: true};
                _.defaults(options, {size: 'DEFAULT'});
                _.defaults(conf, SIZES[options.size] || SIZES.DEFAULT);

                return new Promise(function (resolve, reject) {
                    const extendEvent = event => {
                        const data = _.find(container.modals, m => m.modalAttrs.name === event.name);
                        const modal = _.find(container.$children, c => c.name === event.name);
                        const dialog = modal ? _.find(modal.$children, d => d.$options.name === data.component.name) : null;
                        const component = dialog ? dialog.$refs.component : null;
                        return _.extend(event, {data, modal, dialog, component});
                    };
                    const events = {
                        opened(event) {
                            resolve(extendEvent(event));
                            if(_.isFunction(options.opened)){
                                options.opened.call(this, event);
                            }
                        }
                    };
                    _.each(['before-open', 'before-close', 'closed'], key => {
                        if(_.isFunction(options[key])){
                            events[key] = function (e) {
                                options[key].call(this, extendEvent(e));
                            };
                        }
                    });
                    container.add(Dialog, {
                        component,
                        ... options
                    }, conf, events);
                });
            }
            alert(message, options = {}) {
                const ops = _.defaults(options, {size: 'md', buttons:['close']});
                if(_.isObject(message)) {
                    return this.dialog(message, ops);
                }
                const params = options.params = options.params || {};
                params.message = message;
                return this.dialog(IqpDialogMessage, ops);
            }
            confirm(message, options = {}) {
                const okName = options.okName || i18n.t('apply');
                const closeName = options.closeName || i18n.t('cancel');
                return new Promise((resolve, reject) => {
                    this.alert(message, _.defaults(options, {
                        buttons: [_.defaults(options.ok || {}, {
                            name: okName,
                            type: 'primary',
                            handler: (data) => {
                                try {
                                resolve(data);
                                } finally {
                                    data.dialog.close();
                            }
                        }
                        }),_.defaults(options.close || {}, {
                            name: closeName,
                            type: 'text',
                            handler: 'close'
                        })],
                        closed: reject
                    }));
                });
            }
            prompt(message, options = {}) {
                const okName = options.okName || i18n.t('apply');
                const closeName = options.closeName || i18n.t('cancel');
                const params = options.params = options.params || {};
                params.message = message;
                return new Promise((resolve, reject) => {
                    this.dialog(IqpDialogPrompt, _.defaults(options, {
                        size: 'md',
                        buttons: [_.defaults(options.ok || {}, {
                            name: okName,
                            type: 'primary',
                            handler: (data) => {
                                try {
                                    resolve(data.component.currentValue);
                                } finally {
                                    data.dialog.close();
                                }
                            }
                        }), _.defaults(options.close || {}, {
                            name: closeName,
                            type: 'text',
                            handler: 'close'
                        })],
                        closed: reject
                    }));
                });
            }
            iqError(error, options) {
                const ops = _.defaults(options || {}, {title: i18n.t('error'), size: 'lg', buttons:['close'], params: {error}});
                return this.dialog(ErrorMessageView, ops);
            }
            dialog(component, options = {}) {
                const params = options.params = options.params || {};
                const events = options.events || (options.events = {});
                _.each(options, (val, key) => {
                    if(key.startsWith('@') && _.isFunction(val)) {
                        const event = key.substring(1);
                        if(!events[event]) {
                            events[event] = val;
                            delete options[key];
                        }
                    } else if(key.startsWith(':')) {
                        const param = key.substring(1);
                        if(!params[param]) {
                            params[param] = val;
                            delete options[key];
                        }
                    }
                });
                return this.show(IqpDialog, component, options);
            }
            hide(name, params) {
                Plugin.event.$emit('toggle', name, false, params)
            }
            toggle(name, params) {
                Plugin.event.$emit('toggle', name, undefined, params)
            }
        }

        /**
         * Plugin API
         */
        Object.defineProperty(Vue.prototype, '$modal', {
            get: function () {
                return new ModalObj(this);
            }
        });

        Vue.component(this.componentName, Modal);
        Vue.component('modals-container', ModalsContainer);
    }
};


export default Plugin;
