<template>
    <section class="fst-AdminSettings">
        <el-collapse v-model="activeTab" accordion>
            <el-collapse-item v-for="(tab,key) in tabs" :key="key"
                              :title="tab.title" :name="key">
                <component :is="tab.component"></component>
            </el-collapse-item>
        </el-collapse>
    </section>
</template>

<script>
    import DataCoverageSettings from "./settings/DataCoverageSettings";

    export default {
        name: "Settings",
        data() {
            return {
                activeTab: 0,
                tabs: [
                    {
                        title: 'Data Coverage Settings - Last update',
                        component: DataCoverageSettings
                    },

                ]
            }
        }

    }
</script>

<style lang="scss">
    .fst-AdminSettings {
        background: #fff;
        padding: 15px 20px;

        .el-collapse-item__header {
            font-size: 15px;
        }

        .settingsTab {
            border-collapse: collapse;

            td {
                padding: 5px 15px;
                border-bottom: $border;
            }

            tr:last-of-type {
                td {
                    border: none;
                }
            }
        }
    }

</style>