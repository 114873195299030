var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"widget-block"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"chart-container m-0",attrs:{"element-loading-background":"rgba(19, 19, 19, .7)"}},[_c('div',[_c('div',{staticClass:"header mb-3"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.Research_leaders'))+" "),_c('div',{staticClass:"viewAll",on:{"click":function($event){return _vm.viewAll('Science')}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.viewAll'))+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noResult),expression:"!noResult"}],staticClass:"cloud py-3"},[_c('vue-word-cloud',{staticStyle:{"height":"130px","width":"100%"},attrs:{"words":_vm.words.Science,"spacing":2,"font-size-ratio":_vm.ratio,"color":"#C46370","font-family":"Roboto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
var weight = ref.weight;
var word = ref.word;
return [_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","content":text + ': ' +  _vm.$utils.toFinHuman(weight,1).number + ' articles',"open-delay":600}},[_c('div',{staticClass:"keyword",on:{"click":function (e) { return _vm.handleWordClick(e, word, 'Science'); }}},[_vm._v(" "+_vm._s(text)+" ")])])]}}])})],1)])])]),_c('div',{staticClass:"widget-block"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"chart-container m-0",attrs:{"element-loading-background":"rgba(19, 19, 19, .7)"}},[_c('div',[_c('div',{staticClass:"header mb-3"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.Patent_leaders'))+" "),_c('div',{staticClass:"viewAll",on:{"click":function($event){return _vm.viewAll('Patents')}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.viewAll'))+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noResult),expression:"!noResult"}],staticClass:"cloud py-3"},[_c('vue-word-cloud',{staticStyle:{"height":"130px","width":"100%"},attrs:{"words":_vm.words.Patents,"spacing":2,"font-size-ratio":_vm.ratio,"color":"#C69245","font-family":"Roboto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var text = ref.text;
var weight = ref.weight;
var word = ref.word;
return [_c('el-tooltip',{attrs:{"effect":"dark","placement":"top","content":text + ': ' +  _vm.$utils.toFinHuman(weight,1).number + ' patents',"open-delay":600}},[_c('div',{staticClass:"keyword",on:{"click":function (e) { return _vm.handleWordClick(e, word, 'Patents'); }}},[_vm._v(" "+_vm._s(text)+" ")])])]}}])})],1)])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.noResult),expression:"noResult"}],staticClass:"noResult"},[_vm._v(" No Result ")])])}
var staticRenderFns = []

export { render, staticRenderFns }