<template>
    <div class="iq-msm-dialog" :class="[{'with-footer': hasButtons, 'fixed-height': isFixedSize}, params.class]"
         :style="params.style">
        <div class="header">
            <h6 class="clearfix">
                {{ title || $t('warning') }}
                <el-button class="close" type="text" :title="$t('close')" @click="close"><i class="far fa-times"></i>
                </el-button>
            </h6>
        </div>
        <div class="body">
            <component ref="component" :is="component" v-bind="params || {}" v-on="events || {}" @close="close"/>
        </div>
        <div v-if="hasButtons" class="footer">
            <template v-for="(btn, index) in buttons">
                <el-button v-if="btn === 'close'" :key="index + '-close'" @click="close" type="text">{{ $t('close') }}
                </el-button>
                <el-button v-else-if="btn === 'cancel'" :key="index + '-cancel'" @click="close" type="text">{{
                    $t('cancel') }}
                </el-button>
                <el-button v-else :key="btn.id || btn.key || index"
                           v-bind="btn" @click="(e) => handleButtonClick(e, btn, index)">{{ btn.name || btn.text ||
                    btn.label }}
                </el-button>
            </template>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'IqpDialog',
        props: {
            title: {
                type: String
            },
            size: {
                type: String,
                default: 'md'
            },
            component: {
                type: Object,
                required: true
            },
            params: {
                type: Object
            },
            events: {
                type: Object
            },
            buttons: Array
        },
        computed: {
            isFixedSize() {
                const s = this.size;
                return !(s === 'sm' || s === 'md' || s === 'lg' || s === 'wide');
            },
            hasButtons() {
                return this.buttons && this.buttons.length > 0;
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            handleButtonClick(event, button, index) {
                const data = {event, button, index, dialog: this, component: this.$refs.component},
                    handler = button.handler || button.callback || button.onClick || button.click;
                _.each(['handler', 'callback', 'onClick', 'click'], name => {
                    if (button[name]) {
                        if (_.isFunction(button[name])) {
                            button[name](data);
                        } else if (button[name] === 'close') {
                            this.close();
                        }
                    }
                });
                this.$emit('clickButton', data);
            }
        }
    }
</script>

<style lang="scss">
    .iq-msm-dialog {
        background-color: $iq-color-white;
        overflow: hidden;
        color: $iq-color-text-primary;
        transition: 0.3s;
        display: flex;
        flex-direction: column;

        &.fixed-height {
            height: 100%;
            overflow: hidden;

            > .body {
                overflow: auto;
            }
        }

        &.no-padding {
            > .body {
                padding: 0;
            }
        }

        .clearfix:after {
            clear: both;
        }

        > .header {
            position: relative;
            padding: 7px 40px 7px 20px;
            background-color: $iq-background-color-header;
            border-bottom: 1px solid $iq-border-color-base;
            box-sizing: border-box;
            color: $iq-color-white;

            > h6 {
                margin: 4px 0;
                font-weight: 400;
                font-size: 17px;
            }

            .close {
                position: absolute;
                top: 10px;
                right: 15px;
                font-size: 16px;
                padding: 3px;
                color: $iq-color-white;
            }
        }

        > .body {
            padding: 20px;
            flex: 1;
        }

        > .footer {
            text-align: right;
            background-color: $iq-color-white;
            border-top: 1px solid $iq-border-color-base;
            box-sizing: border-box;

            .el-button {
                font-size: 14px;
                margin: 0;
                padding: 12px 20px;
                border-top: 0;
                border-bottom: 0;
                border-radius: 0;
            }
        }

        &.dark-theme {
            > .header {
                background-color: $iq-dark-theme-color-border;
                border-bottom-color:transparent;
                border-radius: 5px 5px 0 0;
            }

            > .body {
                //background: $iq-dark-theme-bg-light-1;
                background: #000;
                color: $iq-dark-theme-light;
                border: 1px solid $iq-dark-theme-color-border;

            }

            > .footer {
                background-color: $iq-dark-theme-color-border;
                border-top-color: transparent;
            }
            a, .el-button--text{
                color: #ddd;
            }
        }
    }
</style>
