<template>
    <div class="fst-UserTags" ref="userTags">
        <div class="iq-tags">
            <el-popover
                    placement="bottom"
                    popper-class="searchList-userTag"
                    :visible-arrow="false"
                    title=""
                    width="230"
                    @show="refreshEditor"
                    v-model="editor.show"
                    trigger="click">
                <div class="tagEditor">
                    <div class="editingItem">
                        <div class="tagName">
                            <div class="marker" :class="`color${editor.editingItem.color}`"
                                 @click="showPalette = !showPalette"></div>
                            <el-input v-model="editor.editingItem.name"
                                      :placeholder="$t('label.enterNewTag')"
                                      :maxlength="28"
                                      @keyup.enter.native="handleSaveItem"
                            ></el-input>
                            <div class="control">
                                <i class="fas fa-save save" @click="handleSaveItem"></i>
                                <!--i class="far fa-trash-alt clear" @click="handleClearEditor"></i-->
                            </div>
                        </div>
                        <div class="palette" v-if="showPalette">
                            <div v-for="(n,i) in 12" :key="i">
                                <div :class="`color${i+1}`"
                                     class="marker"
                                     @click="handleChangeColor(i+1)"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div class="fullTagList">
                        <div class="item" v-for="item in editor.tagList"
                             :class="{disabled: item.disabled}"
                             :key="item.id">
                            <div class="name" @click="handleClickTag(item)">
                                <div class="marker" :class="`color${item.color}`"></div>
                                <div>{{ item.name }}</div>
                            </div>
                            <div class="control">
                                <i class="fas fa-pencil mr-2 edit" @click="handleEditItem(item)"></i>
                                <i class="fas fa-trash-alt delete" @click="handleDeleteItem(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="iq-tag pointer" slot="reference">
                    <div class="icon">
                        <i class="fas fa-tag fa-flip-horizontal"></i>
                    </div>
                </div>
            </el-popover>

            <div class="iq-tag" v-for="(item, key) in tagList" :key="key">
                <div class="tag-color" :class="`color${item.color}`"></div>
                <div class="tag-name">{{ item.name }}</div>
                <div class="tag-close" @click="handleRemoveTag(item)"><i class="far fa-times"></i></div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "UserTags",
        props: {
            value: {
                type: Array,
                default: () => ([])
            },
            doc: {
                type: Object
            },
            fullUserTagList: {
                type: Array,
                default: () => ([])
            }
        },
        data() {
            return {
                tagList: [],
                showPalette: false,
                editor: {
                    visible: false,
                    tagList: [],
                    editingItem: {
                        id: 0,
                        color: 1,
                        name: ''
                    }
                }
            }
        },
        watch: {
            value(newVals, oldVals) {
                this.updateTagList();
            },
            fullUserTagList(newVal) {
                this.tagList = this.fullUserTagList.filter(it => {
                    return _.map(this.tagList, 'id').includes(it.id);
                });
                this.refreshEditor();
                // console.log('updateTagList--', newVal);
            }
        },
        mounted() {
            this.updateTagList();
            window.addEventListener('storage', () => {
                this.checkTagIds();
            });
        },
        methods: {
            refreshEditor() {
                let tagsSelected = _.map(this.tagList, 'id');
                this.editor.tagList = this.fullUserTagList.map(it => {
                    let out = it;
                    out.disabled = tagsSelected.includes(it.id);
                    return out;
                });
                this.editor.tagList = _.orderBy(this.editor.tagList, [it => it.name.toLowerCase()]);
                this.showPalette = false;
                // console.log('this.editor.tagList', this.editor.tagList);
            },
            updateTagList() {
                this.tagList = this.fullUserTagList.filter(it => {
                    return this.value.includes(it.id);
                });
                // console.log('value:',this.value);
                // console.log('fullTagList:',this.fullUserTagList);
                // this.refreshEditor();
            },
            assignTag(item) {
                if (item) {
                    let query = {
                        tags: [{
                            id: item.id
                        }],
                        docIds: [{
                            id: this.doc.id,
                            type: this.doc.type
                        }]
                    };
                    this.$services.tags.assign(query).then(resp => {
                        this.tagList.push(this.fullUserTagList.find(it => it.id === item.id));
                        this.refreshEditor();
                        this.$emit('userTagAction', {
                            action: 'assign',
                            tagIds: _.map(this.tagList, 'id'),
                            doc: this.doc
                        });
                    });
                }
            },
            unassignTag(item) {
                if (item) {
                    let query = {
                        tags: [{
                            id: item.id
                        }],
                        docIds: [{
                            id: this.doc.id,
                            type: this.doc.type
                        }]
                    };
                    this.$services.tags.unassign(query).then(resp => {
                        this.tagList = this.tagList.filter(it => {
                            return it.id !== item.id;
                        });
                        this.refreshEditor();
                        this.$emit('userTagAction', {
                            action: 'unassign',
                            tagIds: _.map(this.tagList, 'id'),
                            doc: this.doc
                        });
                    });
                }
            },
            checkTagIds() {
                let json = window.localStorage.getItem('userTagAction');
                if (json) {
                    let data = JSON.parse(json);
                    if (data && data.doc && data.doc.id === this.doc.id) {
                        this.tagList = this.fullUserTagList.filter(it => {
                            return data.tagIds.includes(it.id);
                        });
                        localStorage.removeItem('userTagAction');
                    }
                }
            },
            saveUserTag(query) {
                if (query) {
                    this.$services.tags.save(query).prepare().then(resp => {
                        let saved = resp.data;
                        this.$emit('updateUserTagList', saved);
                        parent.localStorage.setItem('changeUserTags', JSON.stringify(query));
                        this.handleClearEditor();
                        if (!query.id && saved && saved.id) {
                            this.assignTag(saved);
                            this.editor.show = false;
                        }
                    });
                }
            },
            deleteUserTag(tagId) {
                if (tagId) {
                    this.$services.tags.delete([tagId]).prepare().then(resp => {
                        // this.fullUserTagList = this.fullUserTagList.filter(it => it.id !== tagId);
                        this.$emit('updateUserTagList');
                        parent.localStorage.setItem('changeUserTags', 'deleted tag ' + tagId);
                    });
                }
            },

            handleRemoveTag(item) {
                this.unassignTag(item);
            },
            handleClickTag(item) {
                if (item.disabled) {
                    this.unassignTag(item);
                } else {
                    this.assignTag(item);
                }
            },
            handleChangeColor(index) {
                this.editor.editingItem.color = index + '';
                this.showPalette = false;
            },
            handleEditItem(item) {
                this.editor.editingItem.name = item.name;
                this.editor.editingItem.color = item.color;
                this.editor.editingItem.id = item.id;
            },
            handleDeleteItem(item) {
                this.$confirm('Delete this tag? This action will remove this tag from all documents', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.deleteUserTag(item.id);
                }).catch(() => {
                });
            },
            handleSaveItem() {
                if (this.editor.editingItem.name) {
                    let id = this.editor.editingItem.id,
                        item = this.fullUserTagList.find(it => it.id === id),
                        query = {
                            id: item && item.id ? item.id : null,
                            color: this.editor.editingItem.color,
                            name: this.editor.editingItem.name
                        }
                    this.saveUserTag(query);
                }
            },
            handleClearEditor() {
                this.editor.editingItem.name = '';
                this.editor.editingItem.id = null;
            }
        }
    }
</script>

<style lang="scss">
    .fst-UserTags {
        &.highlighted {
            .el-popover + .iq-tag {
                background: $iq-color-blue;
                color: #fff;
            }
        }
    }

    .searchList-userTag {
        z-index: 7008 !important;
        .tagEditor {
            box-sizing: border-box;

            .marker {
                width: 12px;
                height: 12px;
                margin: 0 8px 2px 0;
                border-radius: 50%;

                &.color1 {
                    background: $iq-tag-color-1;
                }

                &.color2 {
                    background: $iq-tag-color-2;
                }

                &.color3 {
                    background: $iq-tag-color-3;
                }

                &.color4 {
                    background: $iq-tag-color-4;
                }

                &.color5 {
                    background: $iq-tag-color-5;
                }

                &.color6 {
                    background: $iq-tag-color-6;
                }

                &.color7 {
                    background: $iq-tag-color-7;
                }

                &.color8 {
                    background: $iq-tag-color-8;
                }

                &.color9 {
                    background: $iq-tag-color-9;
                }

                &.color10 {
                    background: $iq-tag-color-10;
                }

                &.color11 {
                    background: $iq-tag-color-11;
                }

                &.color12 {
                    background: $iq-tag-color-12;
                }
            }

        }

        .tagName {
            position: relative;

            .el-input--small .el-input__inner {
                height: 27px;
                line-height: 1;
                padding: 0 30px 0 26px;
            }

            .marker {
                position: absolute;
                z-index: 20;
                top: 7px;
                left: 8px;
                width: 13px;
                height: 13px;
                cursor: pointer;
            }

            .control {
                position: absolute;
                top: 5px;
                right: 8px;

                > i {
                    padding: 2px;
                    cursor: pointer;

                    &.clear {
                        color: $iq-color-grey-light;
                    }

                    &.save {
                        color: $iq-color-blue;
                    }
                }
            }

            & + .palette {
                margin: 10px auto 0;
                width: 70%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                position: absolute;
                background: #fff;
                left: 12px;
                padding: 15px 25px;
                border: 1px solid #d7e1fc;
                border-top-color: transparent;
                top: 27px;
                border-radius: 0 0 5px 5px;
                box-shadow: $iq-shadow;
                z-index: 100;

                .marker {
                    width: 20px;
                    height: 20px;
                    margin: 3px;
                    cursor: pointer;
                }
            }
        }

        .fullTagList {
            padding-top: 5px;
            //border-top: 2px solid #EBF0FD;

            .item {
                font-size: 14px;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                //color: $iq-color-text-primary;
                padding: 4px 8px;
                margin: 5px 0;
                border-radius: 5px;
                transition: all .1s ease;

                > div {
                    display: flex;
                    align-items: center;

                }

                .name {
                    cursor: pointer;
                    flex: 1;
                    margin-right: 10px;

                    &:hover {
                        color: $iq-color-blue;
                        font-weight: 500;
                    }
                }

                .control {
                    i {
                        opacity: 0;
                        font-size: 12px;
                        transition: all .15s ease;
                        cursor: pointer;
                        padding: 1px 2px;

                        &:hover {
                            &.edit {
                                color: $iq-color-blue;
                            }

                            &.delete {
                                color: $iq-color-danger
                            }
                        }
                    }
                }

                &:hover {
                    background: $iq-color-blue-light;

                    .control {
                        i {
                            opacity: 1;
                        }
                    }
                }

                &.disabled {
                    opacity: .6;

                    .name {
                        &:hover {
                            color: inherit;
                            font-weight: 400;
                        }
                    }

                    &:hover {
                        background: rgba(0, 40, 80, 0.12);

                        .name {
                            color: #333;
                        }
                    }
                }
            }
        }
    }
</style>