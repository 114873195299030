<template>
    <div class="fst-MarketSize">
        <div class="table-like iqTab sortTab" v-if="!noResult">
            <div class="thead">
                <div>
                    <div class="sortBy" v-for="(item,key) in dataSchema" :key="key" :class="item.dir"
                         @click="handleSortBy(item)">
                        <div>
                            <div v-html="item.label"></div>
                            <span></span>
                        </div>
                    </div>
                    <div v-if="isDev" class="actions"><i class="far fa-function" title="Actions"></i></div>
                </div>
            </div>
            <div class="tbody" ref="tbody">
                <div v-for="(item, key) in result.list" :key="key" v-show="item.show">
                    <div>
                        <el-link type="primary" :underline="false" @click="handleClickTitle(item)" class="ms-title">
                            <text-highlight :queries="filterQuery" :highlightClass="'markIt'">
                                {{ item.name }}
                            </text-highlight>
                            <span v-if="item.doubles && item.doubles.length" class="nowrap ml-1">
                                ({{ item.doubles.length }})
                                <i class="fas ml-1" :class="(item.showDoubles ? 'fa-angle-up' : 'fa-angle-down')"></i>
                            </span>

                        </el-link>
                        <ul v-if="item.doubles && item.doubles.length" class="doubles mb-0"
                            :class="{active: item.showDoubles}">
                            <li v-for="(dbl, j) in item.doubles" :key="j">
                                <el-link type="primary"
                                         :underline="false"
                                         @click="handleClickTitle(dbl)">
                                    {{ dbl.name }}
                                </el-link>
                            </li>
                        </ul>
                    </div>
                    <div class="num">
                        <span :class="{b: !item.cagrGuessed}">{{ item.formattedCagr }}</span>
                    </div>
                    <div v-for="(it, k) in item.formattedVolume" :key="`${key}-${k}`" class="num">
                        <span :class="{b: !it.guessed}">{{ it.value }}</span>
                    </div>
                    <div class="num">
                        <span>{{ item.formattedDate }}</span>
                    </div>
                    <div v-if="isDev" class="actions">
                        <el-link type="primary"
                                 :underline="false" title="Show source data"
                                 @click="showJson(item.original || item, item.name)" icon="far fa-code"/>
                    </div>
                </div>

                <infinite-loading ref="infiniteLoading" @infinite="handleInfinite" :distance="20">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>
        <div class="noResult" v-if="noResult">
            <div class="title">No result</div>
            <div class="description" v-if="msResult && msResult.contextTotal">
                <div class="origin">No Direct mentions in Market Segment found</div>
                <div class="suggested">
                    But there are <span class="iq-link"  @click="$emit('handleSwithchToContextMS')">
                            {{msResult.contextTotal}} documents found by Сontext</span>.
                </div>

            </div>
            <div class="description" v-else>Try to modify your search query</div>
            <img src="teq-search-clp.svg"/>
        </div>

    </div>
</template>

<script>
import _ from "lodash";
import JsonViewer from "@/commons/components/JsonViewer";

export default {
    name: "MarketSize",
    props: {
        marketDataType: {
            type: String
        },
        value: {
            type: Object,
            default: () => ({})
        },
        noResult: {
            type: Boolean
        },
        msResult: {
            type: Object
        },
        filter: {
            type: Object,
            default: () => ({})
        },
        filterQuery: {
            type: String
        },
        shiftYear: {
            type: Number,
            default: 0

        }

    },
    data() {
        return {
            curYear: new Date().getFullYear(),
            maxYears: 6,
            divider: 1000000000, // 1B
            dataSchema: [],
            dataSchemaInit: [
                {label: 'Market segment', title: 'marketSegment', dir: '', field: 'name'},
                {label: 'CAGR (%)', dir: '', field: 'cagr'}
            ],
            result: {
                total: 0,
                offset: 0,
                list: []
            },
            sortBy: {
                field: 'month',
                dir: 'DESC'
            }

        };
    },
    computed: {
        isDev() {
            return this.$store.state.userInfo.roles.indexOf('DEV') >= 0;
        }
    },
    watch: {
        value(newVal) {
            this.result = _.cloneDeep(newVal);
            // this.resetInfinite();
            this.refresh();
        },
        shiftYear(newVal) {
            this.refreshMarketSchema();
        }
    },
    mounted() {
        setTimeout(() => {
            this.refreshMarketSchema();
        },300);
    },
    created() {
        if (this.value) {
            this.result = _.cloneDeep(this.value);
            this.refresh();
        }
    },
    methods: {
        refresh() {
            this.result.list = this.result.list.map(item => {
                let _formattedVolume = [],
                    _item = {};
                for (let y = this.shiftYear; y < (this.shiftYear + this.maxYears); y++) {
                    if (item.volume && item.volume[y] && item.volume[y].value) {
                        _formattedVolume.push({
                            value: this.$utils.toFin((item.volume[y].value / this.divider), 1) || '',
                            guessed: item.volume[y].guessed || false
                        })
                    } else {
                        _formattedVolume.push({
                            guessed: true,
                            value: ''
                        })
                    }
                }
                _item.show = true;
                _item.showDoubles = false;
                _item.doubles = [];
                _item.formattedCagr = item.cagr ? item.cagr.toFixed(1) : '';
                _item.formattedVolume = _formattedVolume;
                _item.formattedDate = moment(item.date).format('YYYY - MM');
                return Object.assign({}, item, _item);
            });
            let prevItem = this.result.list[0],
                eqNum = 1;
            this.result.list.forEach((item, i) => {
                if (i > 0) {
                    let _isEqual = item.formattedCagr === prevItem.formattedCagr && item.formattedDate === prevItem.formattedDate,
                    _strPrevVol = _.map(prevItem.formattedVolume, 'value'),
                    _strVol = _.map(item.formattedVolume, 'value');
                    // _strPrevVol = JSON.stringify(_.map(prevItem.formattedVolume, 'value')),
                    // _strVol = JSON.stringify(_.map(item.formattedVolume, 'value'));
                    // console.log('CAGR: ', item.formattedCagr, '=== prev:' , prevItem.formattedCagr, ' | Date:' , item.formattedDate, '=== prev:', prevItem.formattedDate);
                    // console.log('prevVol:', _strPrevVol);
                    // console.log('curVol:', _strVol);
                    // console.log(i,'. ',eqNum, ' --------', item.name , '--------- isEq:', _strVol == _strPrevVol);
                    if (_isEqual && _.isEqual(_strPrevVol, _strVol)) {
                        item.show = false;
                        if (eqNum === 1) {
                            prevItem.doubles.push({
                                parentId: prevItem.parentId,
                                type: prevItem.type,
                                name: prevItem.parentUrl.split('/')[2].replace(/^www\./, '')
                            })
                        }
                        eqNum++;
                        // prevItem.name = prevItem.name + ' (' + eqNum + ')';
                        prevItem.doubles.push({
                            parentId: item.parentId,
                            type: item.type,
                            name: item.parentUrl.split('/')[2].replace(/^www\./, '')
                            // name: item.parentUrl.split('/').slice(0,3).join('/')
                        })
                    } else {
                        prevItem = this.result.list[i];
                        eqNum = 1;
                    }
                }
            })
             // console.log('|> Market result.list', this.result.list, this.filter);

        },
        refreshMarketSchema() {
            let self = this;
            this.dataSchema = self.dataSchemaInit.map(it => {
                it.label = it.title ? self.$t('label.' + it.title) : it.label;
                return it;
            });
            for (let y = this.shiftYear; y < (this.shiftYear + this.maxYears); y++) {
                this.dataSchema.push({
                    label: `${y} ($B)`,
                    field: `volume.${y}.value`,
                    dir: this.sortBy.field === `volume.${y}.value` ? this.sortBy.dir : ''
                });
            }
            this.dataSchema.push({
                label: self.$t('label.reportDate'),
                field: 'month',
                dir: this.sortBy.field === 'month' ? this.sortBy.dir : ''
            });
        },
        resetInfinite() {
            // console.log('---------- reset ----------');
            if (this.$refs.tbody) this.$refs.tbody.scrollTop = 0;
            if (this.$refs.infiniteLoading) {
                this.$refs.infiniteLoading.stateChanger.reset();
            }
        },
        showJson(json, title) {
            this.$modal.dialog(JsonViewer, {title, size: 'center-wide', params:{value: typeof json === 'function' ? json : () => json, open: 1}});
        },
        handleClickTitle(item) {
            let _type = item.type ? item.type : 'News';
            if (item.doubles && item.doubles.length > 0) {
                item.showDoubles = !item.showDoubles;
            } else {
                window.open(document.location.origin + this.$store.state.contextUrl + '/redirectToOriginal?type=' + _type + '&id=' + encodeURIComponent(item.parentId), '_blank');
            }
        },
        handleInfinite(state) {
            // console.log('>>>>> Infinity!', state, this.type);
            let total = _.get(this.result, 'total') || 0;
            if (total > this.filter.count && total > this.result.list.length) {
                this.$emit('infinite', state, this.sortBy);
            } else {
                state.complete();
            }
        },
        handleSortBy(item) {
            let _dir = this.dataSchema.find(it => it.field === item.field).dir,
                _curSortDir = _.cloneDeep(this.dataSchema.find(it => it.field === item.field).dir);
            _.each(this.dataSchema, it => {
                it.dir = '';
            });
            if (_curSortDir) {
                this.dataSchema.find(it => it.field === item.field).dir = (_curSortDir === 'DESC') ? 'ASC' : 'DESC';
            } else {
                this.dataSchema.find(it => it.field === item.field).dir = 'DESC';
            }
            this.sortBy = {
                field: item.field,
                dir: this.dataSchema.find(it => it.field === item.field).dir
            }
            this.$emit('sortBy', this.sortBy);
            if (this.$refs.tbody) this.$refs.tbody.scrollTop = 0;

        },

    }
};
</script>

<style lang="scss">
.fst-MarketSize {
    background: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid $iq-color-blue-light;
    height: calc(100vh - 225px);

    .iqTab {
        .thead > div > div,
        th > div {
            white-space: nowrap;
        }
        .thead .actions, .tbody .actions {
            width: 20px;
            flex: none;
            text-align: right;
        }
        .tbody .actions {
            padding: 10px 10px 10px 0;
        }

        thead th,
        tbody td,
        .thead > div > div,
        .tbody > div > div {
            &:first-child {
                text-transform: capitalize;
                min-width: 160px;
                flex-basis: 160px;
            }

            .markIt {
                font-weight: 800;
                color: #222;
                background: transparent;
                padding: 0;
            }
        }

        .tbody {
            max-height: calc(100vh - 265px);
        }

        tbody,
        .tbody {
            > div > div,
            td {
                font-size: 14px;

                &.num {
                    font-size: 13px;
                    color: $iq-color-grey-medium;
                    padding-left: 0;

                    .b {
                        color: $iq-color-text-primary;
                        font-weight: 700;
                    }
                }

                .el-link {
                    color: $iq-color-grey-medium;

                    &.el-link--primary:hover {
                        color: $iq-color-blue;
                    }
                }

            }
        }
        .ms-title{
            .el-link--inner {
                display: flex;
                align-items: flex-start;
            }
        }

    }

    .doubles {
        overflow: hidden;
        max-height: 0;
        transition: all .3s ease;
        text-transform: lowercase;
        margin-top: 1px;
        padding-left: 15px;
        li{
            list-style: square inside url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
            padding: 3px 0 2px;
        }
        > .el-link {
            display: block;
            font-size: 13px;
            font-weight: 400;
        }

        &.active {
            max-height: 350px;
        }
    }
    .noResult{
        .origin,.suggestion{
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
}
</style>
