<template>
    <div>
        <div v-html="message"></div>
        <el-input-number v-if="type === 'number'" :value="currentValue" @input="handleValueChange" controls-position="right" style="width: 100%;"/>
        <el-input v-else :value="currentValue" @input="handleValueChange" style="width: 100%;" />
    </div>
</template>

<script>
    export default {
        name: 'IqpDialogMessage',
        props: {
            message: String,
            value: {
                type: Object
            },
            type: {
                type: String,
                default: 'string' // number
            }
        },
        data() {
            return {
                currentValue: this.value
            };
        },
        watch: {
            value(newVal) {
                this.currentValue = newVal;
            }
        },
        methods: {
            handleValueChange(val) {
                this.currentValue = val;
                this.$emit('input', val);
            }
        }
    }
</script>

<style scoped>

</style>
