<template>
    <div class="widget-block" :class="params.widgetClass">
        <div class="header">
            <div class="title">
                {{ title }}
                <div class="viewAll" @click="(e) => viewAll(e)"
                     v-if="this.$store.getters.swList.mode === 'all'"
                >{{ $t('dashboard.viewAll') }}
                </div>
            </div>
            <export-to-image :filename="title" selector=".chart-container"></export-to-image>
        </div>
        <div class="chart-container"
             v-loading="loading">
            <apexchart
                    v-show="chartSeries.length"
                    ref="chart"
                    :options="chartOptions"
                    :series="chartSeries"
                    @dataPointSelection="dataPointSelectionHandler">
            </apexchart>
            <div class="noResult" v-show="chartSeries.length < 1">
                No Result
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWMarkets",
        components: {ExportToImage},
        props: {
            title: String,
            filter: Object,
            years: Object,
            chartOptions: Object,
            params: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                chartSeries: [],
                reQ: {
                    chart: {},
                    documents: {}
                },
                mode: 'sum',
                loading: true,
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.refresh();
            },
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                let self = this;
                let widgetFilter = _.cloneDeep(self.filter),
                    ii = 0;
                delete widgetFilter.subfilters
                let query = {
                    "filter": widgetFilter,
                    "widgets": {
                        "market": {
                            "type": "MARKET",
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        }
                    }
                };

                if (self.reQ.chart.then) {
                    try {
                        self.reQ.chart.cancel();
                    } catch (e) {
                        console.error(e);
                    }
                }


                self.loading = true;

                self.reQ.chart = self.$services.analytics.widgets(query);

                self.reQ.chart.then(resp => {
                    // console.log('marRsp', resp.data);
                    let _data = resp.data.widgets.market.markets.filter(it => Object.keys(it.volume).length);


                    //---------- Markets ----------
                    self.chartSeries.forEach(it => {
                        it.data = [];
                    })
                    let _colors = [
                        // 'hsl(36,65%,78%)', // Orange2
                        '#C69245', // Orange2
                        '#61B768', // Green2
                        '#8599D0', // BlueBright
                        '#C46370', // Red2
                        '#BABABA'  // White2
                    ];
                    self.chartSeries = _data.map(it => {
                        let out = {}
                        out.name = _.upperFirst(it.name) + (it.cagr ? ' (CAGR: ' + self.$utils.roundX(it.cagr, 1) + '%)' : '');
                        out.data = [];
                        return out;
                    })
                    let _years = _.flatten(_.map(_data, 'volume').map((it, i) => {
                        return Object.keys(it);
                    }));
                    _data.forEach((line, ii) => {
                        for (let year = _.min(_years); year <= _.max(_years); year++) {
                            self.chartSeries[ii].data.push({
                                x: year,
                                y: line.volume[year] ? line.volume[year] : null,
                                parentUrl: line.parentUrl
                            });
                        }
                    });
                    let options = {
                        colors: _colors,
                        chart: {
                            type: 'line',
                            height: '420px',
                        },
                        markers: {
                            size: 5,
                            hover: {
                                size: 7
                            }
                        },
                        tooltip: {
                            enabled: true,
                            intersect: true,
                            shared: false,
                        },
                        legend: {
                            show: true,
                            position: 'bottom',
                            horizontalAlign: 'left',
                        },
                        stroke: {
                            width: 4,
                            curve: 'straight'
                        },
                        dataLabels: {
                            enabled: false
                        },
                        yaxis: {
                            labels: {
                                show: true,
                                align: 'right',
                                style: {
                                    fontSize: '14px'
                                },
                                formatter: function (val, index) {
                                    // console.log('ind', index);
                                    return '$' + self.$utils.toFinHuman(val, 0).number;
                                }
                            },
                        },
                    }
                    if (self.$refs && self.$refs.chart) {
                        self.$refs.chart.updateOptions(options)
                    }


                    self.loading = false;
                    // console.log('-- series Markets: ', self.chartSeries);
                }).catch(e => {
                    console.log(e);
                });
            },
            dataPointSelectionHandler(e, chartContext, config) {
                let self = this;
                let _data = self.chartSeries[config.seriesIndex].data[config.dataPointIndex];
                // keyword = self.columnsSeries.data[keyIndex].x;
                console.log('conf', _data);
                if (e.button === 0 && _data.parentUrl) {
                    window.open(_data.parentUrl, '_blank');
                }

            },
            viewAll(e) {
                let self = this;
                let years = [],
                    docType = "MarketSize";
                let facets = {}
                let params = {
                    query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                if (e.button === 0) {
                    self.$utils.openSearch(params);
                }

            },
            createYaxis(series) {
                let self = this;
                return series.map((it, i) => {
                    let ser = {};
                    ser.seriesName = it.name;
                    if (it.yaxis === 'opposite') {
                        ser.opposite = true;
                    }
                    ser.axisTicks = {show: true};
                    ser.axisBorder = {show: true, color: it.color};
                    ser.labels = {
                        style: {
                            colors: it.color
                        },
                        formatter: function (val, index) {
                            return it.suffix ? '$' + self.$utils.toFinHuman(val, 0).number : self.$utils.toFin(val, 0);
                        }
                    };
                    ser.title = {
                        text: it.title,
                        style: {
                            color: it.color,
                            fontSize: 15,
                            fontWeight: 500
                        }
                    };
                    return ser;
                });
            },
        }
    }
</script>

<style lang="scss">

</style>

<style scoped lang="scss">
    .chart-container{
        min-height: 400px;
    }
</style>