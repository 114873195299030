import _ from 'lodash'
import Vue from 'vue'
import Menu from './Menu'

const defaultContainer = () => {
    let _defaultContainer = document.createElement('div');
    document.body.appendChild(_defaultContainer);
    return _defaultContainer;
};


export default {
    _vm: null,
    destroy() {
        if(this._vm) {
            this._vm.$destroy();
            this._vm.$root.$el.remove();
            this._vm = null;
        }
    },
    /**
     * // Контекстное меню, пример:
     * this.$contextMenu(event, [{
     *      // Название (text || name || label || title)
     *      text: 'text',
     *      // Иконка (iconClass || icon)
     *      icon: 'far fa-check',
     *      // Подменю (children || submenu || submenus)
     *      children: [{
     *          text: 'submenu item',
     *          icon: 'far fa-check',
     *      }],
     *      // Callback (handle || handler || callback || click)
     *      handler: (item) => {}
     *  }]).then(console.info, console.error)
     * @param position - позиция меню ({left:20, top:10} || {x:20, y:10} || Event || Element)
     * @param items - пункты меню
     * @returns {Promise<item>}
     */
    show(position, items) {
        const p = position;
        let left = p.left || p.x || p.clientX || p.pageX,
            top = p.top || p.y || p.clientY || p.pageY;
        if(p instanceof Event) {
            left = p.clientX;
            top = p.clientY;
        } else if(p instanceof Element) {
            left = p.clientLeft + p.clientWidth / 2;
            top = p.clientTop + p.clientHeight / 2;
        }
        return new Promise((resolve, reject) => {
            this._vm = new Vue({
                render: h => h(Menu, {
                    props: {items: items, left, top},
                    on: {
                        clickItem: (item) => {
                            this.destroy();
                            ['handle', 'handler', 'callback', 'click', 'action'].forEach(h => {
                                if(_.isFunction(item[h])) {
                                    item[h](item);
                                }
                            });
                            resolve(item);
                        },
                        close: () => {
                            this.destroy();
                            reject('cencelled');
                        }
                    }
                })
            });
            this._vm.$mount(defaultContainer());
        });
    },
    install(Vue, ops = {}) {
        if(Vue.prototype.$contextMenu) {
            return;
        }
        Vue.prototype.$contextMenu = (position, items) => this.show(position, items);
    }
};
