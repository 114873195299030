<template>
    <div class="fst-Investment">
        <span class="exportMenu" v-if="exportMenuVisible" v-loading="exportLoading">
            <span class="closeMenu" @click="handleCloseExportMenu"><i class="far fa-times"></i></span>
            <button class="btn" @click="$emit('handleExportDocs', {all: true})"><span v-html="$t('export.all')"></span></button>
            <button class="btn" :disabled="exportCheckedDisabled"
                    @click="$emit('handleExportDocs', {all: false})"><span v-html="$t('export.checked')"></span> ({{ exportCheckedCount }})
            </button>
        </span>

        <div class="table-like iqTab sortTab" v-loading="loading">
            <div class="tbody">
                <div>
                    <div :class="[{sortBy: item.sortable}, item.dir]" v-for="(item,key) in dataSchema"
                         :key="key" @click="handleSortBy(item)">
                        <div class="flex-center">
                            <div v-html="item.label"></div>
                            <span></span>
                            <el-popover
                                    placement="bottom"
                                    v-if="item.subFilter && showSubtypeFilter"
                                    :visible-arrow="true"
                                    popper-class="subTypeFilter"
                                    trigger="click"
                                    v-model="item.subFilter.visible">
                                <div>
                                    <el-radio-group v-model="item.subFilter.value" @change="handleSubFilter(item)">
                                        <el-radio-button v-for="sel in item.subFilter.opts" :label="sel.value" :key="sel.value">
                                            {{ sel.label }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </div>
                                <div slot="reference">
                                    <div
                                            :class="['subFilterBtn',{active: item.subFilter.value}]">
                                        <i :class="['fas',
                                                {'fa-ellipsis-v':!item.subFilter.value},
                                                {'fa-filter':item.subFilter.value}]"></i>
                                    </div>
                                </div>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tbody" ref="tbody">
                <div v-for="(item, key) in result.list"
                     v-show="!item.hide"
                     :key="key"
                     :class="{opened: item.opened}">
                    <div class="investee">
                        <el-checkbox v-model="item.checked"
                                     v-if="exportMenuVisible"
                                     @change="handleSelectDoc(item)"></el-checkbox>
                        <div>

                            <el-popover
                                    placement="top-start"
                                    width="400"
                                    popper-class="investDesc"
                                    :open-delay="450"
                                    :close-delay="150"
                                    trigger="hover">
                                <div>
                                    <div class="header">
                                        <div class="logo">
                                            <img v-if="item.investee.icon" :src="item.investee.icon.url" alt="Logo">
                                            <img v-else src="noLogo2.png" alt="Logo">
                                        </div>
                                        <div>
                                            <div class="title">{{ item.investee.title }}</div>
                                            <div class="estd">
                                                <template v-if="item.investee.countries">
                                                <span class="mr-1"
                                                      v-for="(country, key) in item.investee.countries" :key="key">
                                                    {{country}},
                                                </span>
                                                </template>
                                                <template v-if="item.investee.estd">
                                                    Estd. {{item.investee.estd}}
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="desc">{{ item.investee.description }}</div>
                                </div>
                                <span slot="reference">
                                <span @click="handleOpenCompanyCard(item.investee)">{{ item.investee.title }}</span>

                            </span>
                            </el-popover>
                            <div class="tags"
                                 v-show="item.investee.systemKeywords && item.investee.systemKeywords.length">
                                <div v-for="(tag, tk) in item.investee.systemKeywords" :key="tk">{{
                                    tag
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-popover
                                v-if="item.news && item.news.length"
                                placement="top-start"
                                width="400"
                                popper-class="investNews"
                                :open-delay="500"
                                :close-delay="500"
                                trigger="hover">
                            <div>
                                <a :href="news.url" target="_blank" class="newsItem" v-for="(news, nk) in item.news"
                                   :key="nk">
                                    <div class="date-hosting">
                                        <span class="date">{{ news.dateFormatted }}</span>
                                        <span class="hosting" v-if="news.hostName"> - {{ news.hostName }}</span>
                                    </div>
                                    <div class="title">{{ news.title }}</div>
                                </a>
                            </div>
                            <div slot="reference">
                                <div class="investNewsMarker">
                                    {{ item.news.length }}
                                </div>
                            </div>
                        </el-popover>
                    </div>

                    <div>
                        <div v-for="(investor, key) in item.investorsSorted"
                             class="investor"
                             :key="key">
                            <div @click="handleOpenCompanyCard(investor)"
                                 :class="['investor-name',{company: investor.id}, {'is-owner': item.isOwner}]">
                                <span v-if="investor.id && item.isOwner" class="icon">
                                    <el-tooltip effect="light" content="Acquired by" :open-delay="606">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M8 0L9.88854 6.11146H16L11.0557 9.88854L12.9443 16L8 12.2229L3.05573 16L4.94427 9.88854L0 6.11146H6.11146L8 0Z"
                                                fill="#3064F2"/>
                                    </svg>
                                    </el-tooltip>
                                </span>
                                <span v-else-if="investor.id" class="icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M2 19V10H7.05263V3.7L14.6316 1V6.4H18V19H2ZM9.57895 4.6H11.2632V17.5H9.57895V4.6ZM6.63158 12H4.94737V14H6.63158V12ZM4.94737 15.5H6.63158V17.5H4.94737V15.5ZM14.2105 12.3062H15.8947V14.3125H14.2105V12.3062ZM15.8947 9.1H14.2105V11.2188H15.8947V9.1ZM14.2105 15.5H15.8947V17.5H14.2105V15.5Z"/>
                                    </svg>
                                </span>
                                <span v-else class="icon">
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1.5496 16.2658L1.54895 14.1323C0.681422 14.0542 0 13.3103 0 12.4047V9.74646C0 8.42181 1.16654 7.16895 2.54798 7.16895C4.18266 7.16895 5.81734 7.16895 7.45202 7.16895C8.83258 7.16895 10 8.42234 10 9.74646V12.4047C10 13.3103 9.31793 14.0542 8.4504 14.1323V16.2658C8.4504 17.2229 7.68853 18 6.75024 18H3.24911C2.31082 18 1.5496 17.2229 1.5496 16.2658ZM1.95509 3.10645C1.95509 1.39207 3.31923 0 5 0C6.68045 0 8.04523 1.39207 8.04523 3.10645C8.04523 4.82017 6.68045 6.21224 5 6.21224C3.31923 6.21224 1.95509 4.82017 1.95509 3.10645Z"/>
                                </svg>
                                </span>

                                <el-popover
                                        v-if="investor.id"
                                        placement="top-start"
                                        width="400"
                                        popper-class="investDesc"
                                        :open-delay="450"
                                        :close-delay="200"
                                        trigger="hover">
                                    <div>
                                        <div class="header">
                                            <div class="logo">
                                                <img v-if="investor.icon" :src="investor.icon.url" alt="Logo">
                                                <img v-else src="noLogo2.png" alt="Logo">
                                            </div>
                                            <div>
                                                <div class="title">{{ investor.title }}</div>
                                                <div class="estd" v-if="investor.estd">Estd. {{ investor.estd }}</div>
                                            </div>
                                        </div>

                                        <div class="desc" v-html="investor.description"></div>
                                        <div class="tags"
                                             v-show="investor.systemKeywords && investor.systemKeywords.length">
                                            <div v-for="(tag, tk) in investor.systemKeywords" :key="tk">{{ tag }}</div>
                                        </div>
                                    </div>
                                    <div slot="reference">
                                        {{ investor.title }}
                                    </div>
                                </el-popover>
                                <template v-else>{{ investor.title }}</template>
                            </div>
                        </div>
                        <div v-if="!item.investors || !item.investors.length"></div>
                        <div v-if="item.investors.length > 3" class="show-more"
                             @click="item.opened = !item.opened"><i
                                :class="['far', {'fa-angle-up': item.opened},  {'fa-ellipsis-h': !item.opened}]"></i>
                        </div>
                    </div>
                    <div v-html="item.typeFormatted" class="type"></div>
                    <div v-html="item.subtypeFormatted" class="subtype"></div>
                    <div><span :title="item.sumFormatted" v-html="item.sumHumanFormatted"
                               :class="['sum',item.sumClass]"></span></div>
                    <div v-html="item.dateFormatted"></div>
                </div>
                <infinite-loading ref="infiniteLoading" @infinite="handleInfinite">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import axios from "axios";
    import JsonViewer from "@/commons/components/JsonViewer";

    export default {
        name: "Investment",
        props: {
            value: {
                type: Object,
                default: () => ({})
            },
            filter: {
                type: Object,
                default: () => ({})
            },
            noResult: {
                type: Boolean
            },
            showSubtypeFilter: {
                type: Boolean
            },
            showExportMenu: {
                type: Boolean
            },
        },
        data() {
            return {
                curYear: new Date().getFullYear(),
                divider: 1000000, // 1M
                loading: false,
                exportMenuVisible: false,
                exportLoading: false,
                dataSchema: [
                    {label: 'funded_company', dir: '', field: 'company', sortable: false,},
                    {label: 'news', dir: '', field: 'news', sortable: false,},
                    {label: 'investors', dir: '', field: 'investors', sortable: false},
                    {label: 'type', dir: '', field: 'type', sortable: false},
                    {
                        label: 'subtype', dir: '', field: 'subtype', sortable: false,
                        subFilter: {
                            value: '',
                            visible: false,
                            filled: false,
                            opts: [{
                                label: 'all',
                                value: ''
                            }]
                        }
                    },
                    {label: 'sumDollar', dir: '', field: 'sum', sortable: true},
                    {label: 'date', dir: 'DESC', field: 'investment.date', sortable: true},
                ],
                result: {
                    total: 0,
                    offset: 0,
                    list: []
                },
                sortBy: {
                    field: 'investment.date',
                    dir: 'DESC'
                },

            };
        },
        computed: {
            isDev() {
                return this.$store.state.userInfo.roles.indexOf('DEV') >= 0;
            },
            exportCheckedDisabled(){
                return (this.result.list.filter(it => it.checked) || []).length < 1
            },
            exportCheckedCount(){
                return (this.result.list.filter(it => it.checked) || []).length
            },

        },
        watch: {
            value(newVal) {
                // this.resetInfinite();
                this.refresh();
            },
            showExportMenu(newVal) {
                this.exportMenuVisible = newVal;
            }
        },
        mounted() {
            let self = this;
            self.exportMenuVisible = self.showExportMenu;
            if (self.filter && self.filter.order && self.filter.order.length) {
                let _order = self.filter.order[0],
                    field = _order.field,
                    dir = _order.type;
                if (field) {
                    let _ord = self.dataSchema.find(it => it.field === field);
                    if (_ord) {
                        self.dataSchema = self.dataSchema.map(it => {
                            it.dir = '';
                            return it;
                        })
                        _ord.dir = dir;
                    }
                }

            }
            self.dataSchema = self.dataSchema.map(it => {
                it.label = self.$t('facets.' + it.label);
                return it;
            });
        },
        created() {

        },
        methods: {
            refresh() {
                let self = this,
                    alias = {
                        ma: 'M&A',
                        'm&a': 'M&A',
                        'M&A': 'M&A',
                        ipo: 'IPO',
                        venture: 'Venture',
                        'post_ipo_debt': 'Post IPO Debt'
                    };
                // self.loading = true;
                self.result.list = _.cloneDeep(this.value).list.map(it => {
                    let _sum = self.$utils.toFinHuman(it.sum),
                        _subtype = (it.subtype || '').toLowerCase();

                    it.sumFormatted = self.$utils.toFin(it.sum);
                    it.sumHumanFormatted = !it.sum ? '&ndash;' : _sum.numberSpanned;
                    it.sumClass = _sum.suffix ? "grade_" + _sum.suffix : null;
                    it.dateFormatted = it.date ? moment(it.date).format('DD.MM.YYYY') : '&ndash;';
                    it.typeFormatted = alias[it.type] ? alias[it.type] : _.startCase(it.type);
                    it.subtypeFormatted = alias[_subtype] ? alias[_subtype] : _.startCase(it.subtype);
                    it.investorsSorted = it.investors.map(inv => {
                        inv.isCompany = !!inv.id;
                        inv.estd = inv.date ? moment(inv.date).format('YYYY') : ''
                        return inv
                    });
                    it.isOwner = it.typeFormatted.toLowerCase() === 'm&a'
                        && it.subtypeFormatted.toLowerCase() === 'm&a'
                    it.hide = false;
                    it.opened = false;
                    it.ckecked = false;
                    it.investorsSorted = _.orderBy(it.investorsSorted, ['isCompany', 'title'], ['desc', 'asc'])
                    if (it.news && it.news.length) {
                        it.news = _.orderBy(it.news.map(n => {
                            n.dateFormatted = moment(n.date).format('ll');
                            return n;
                        }), 'date', 'desc');
                    }
                    if (it.investee) {
                        it.investee.estd = it.investee.date ? moment(it.investee.date).format('YYYY') : ''
                    }
                    return it;
                });
                self.result.total = self.value.total;
                let subOpts = _.uniqBy(_.orderBy(self.result.list.map(it => {
                        return {value: it.subtype, label: it.subtypeFormatted}
                    }), 'label'), 'value'),
                    _subFilter = self.dataSchema.find(it => it.field === 'subtype').subFilter
                // console.log('subTypes', subOpts);
                _subFilter.opts = subOpts;
                _subFilter.opts.splice(0, 0, {value: '', label: this.$t('label.all')});
                self.dataSchema = _.cloneDeep(self.dataSchema);
                // self.loading = false;
            },
            resetInfinite() {
                // console.log('---------- reset ----------');
                if (this.$refs.tbody) this.$refs.tbody.scrollTop = 0;
                if (this.$refs.infiniteLoading) {
                    this.$refs.infiniteLoading.stateChanger.reset();
                }
            },
            showJson(json, title) {
                this.$modal.dialog(JsonViewer, {
                    title,
                    size: 'center-wide',
                    params: {value: typeof json === 'function' ? json : () => json, open: 1}
                });
            },
            handleOpenCompanyCard(item) {
                if (item.id) {
                    window.sessionStorage.removeItem('companyCard');
                    window.open(this.$router.resolve({name: 'companyCard'}).href + '?id=' + item.id, '_blank');
                } else {
                    console.log('--- No company Id. Investor - person? --- ');
                }
            },
            handleSubFilter(item) {
                let self = this;
                console.log('Item: ', item);
                item.subFilter.visible = false;
                self.result.list = self.result.list.map(it => {
                    it.hide = item.subFilter.value && it.subtype !== item.subFilter.value;
                    return it;
                });
                self.results = _.cloneDeep(self.result);
            },
            handleInfinite(state) {
                // console.log('>>>>> Infinity!', state, this.type);
                let total = _.get(this.result, 'total') || 0;
                if (total > this.filter.count && total > this.result.list.length) {
                    this.$emit('infinite', state, this.sortBy);
                } else {
                    state.complete();
                }
            },
            handleSortBy(item) {
                if (item.sortable) {
                    let _curSortDir = _.cloneDeep(this.dataSchema.find(it => it.field === item.field).dir);
                    _.each(this.dataSchema, it => {
                        it.dir = '';
                    });
                    if (_curSortDir) {
                        this.dataSchema.find(it => it.field === item.field).dir = (_curSortDir === 'DESC') ? 'ASC' : 'DESC';
                    } else {
                        this.dataSchema.find(it => it.field === item.field).dir = 'DESC';
                    }
                    this.sortBy = {
                        field: item.field,
                        dir: this.dataSchema.find(it => it.field === item.field).dir
                    }
                    this.$emit('sortBy', this.sortBy);
                    if (this.$refs.tbody) this.$refs.tbody.scrollTop = 0;
                }
            },
            handleCloseExportMenu() {
                this.result.list.forEach(it => {
                    it.checked = false
                })
                this.$emit('hideExportMenu');
                this.exportMenuVisible = false;
                this.exportCheckedCount = 0;
            },
            handleExportDocs(params) {
                let query = {},
                    fileName = 'Teqviser_Documents.xlsx',
                    docType = 'Investment',
                    serviceType = 'documentExport';
                this.exportLoading = true;
                if (params && params.all) {
                    let filter = _.cloneDeep(this.filter);
                    query = {docIds: null, filter: filter};
                    fileName = 'Teqviser_' + docType + '_all(2k).xlsx';
                } else {
                    let _data = this.result.list.filter(item => item.checked),
                        ids = _.map(_data, (it) => {
                            return {id: it.id, type: docType}
                        })
                    query = {docIds: ids, filter: null};
                    fileName = 'Teqviser_' + docType + '_selected(' + ids.length + ').xlsx';
                }
                axios({
                    url: this.$store.state.apiUrl + '/documents/' + serviceType,
                    method: 'POST',
                    data: JSON.stringify(query),
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json;charset=UTF-8'}
                }).then((resp) => {
                    // console.log('Checked query:', query, this.filter);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(resp.data);
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.exportLoading = false;
                })
            },
            handleSelectDoc(item) {
                this.$emit('handleSelectDoc', item);
                // let _count = this.result.list.filter(it => it.checked).length;
                // this.exportCheckedCount = _count;
                // this.exportCheckedDisabled = _count < 1;
            },

        }
    };
</script>

<style lang="scss">
    .fst-Investment {
        background: #fff;
        border-radius: 5px;
        padding: 10px 20px;
        border: 1px solid $iq-border-color-base;

        div.iqTab {
            .investee {
                display: flex;
                align-items: flex-start;

                span {
                    font-weight: 500;
                    cursor: pointer;
                    transition: all .2s ease;

                    &:hover {
                        color: $iq-color-blue;
                    }
                }

                .tags {
                    display: flex;
                    margin: 7px -5px 3px;
                    flex-wrap: wrap;
                    max-height: 24px;
                    overflow: hidden;
                    transition: all .35s ease-in .15s;
                    cursor: pointer;

                    &:hover {
                        max-height: 180px;
                        transition: all .66s ease-in .55s;
                    }

                    > div {
                        margin: 0 3px 5px;
                        background: #fff;
                        border: 1px solid #dfe1ec;
                        color: #9da2c1;
                        font-variant: all-small-caps;
                        font-size: .7rem;
                        font-weight: 400;
                        border-radius: 4px;
                        padding: 2px 6px;
                        white-space: nowrap;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:hover {
                            max-width: 300px;
                        }
                    }
                }

                > .el-checkbox {
                    margin: 0 10px 0 -10px;

                    .el-checkbox__inner {
                        width: 20px;
                        height: 20px;

                        &:after {
                            border-width: 2px;
                            height: 9px;
                            left: 6px;
                            top: 2px;
                            width: 5px;
                        }
                    }
                }
            }

            .type,
            .subtype {
                font-size: .8333rem;
            }

            .sum {
                //font-size: .8333rem;
                letter-spacing: -.5px;

                &.grade_B {
                    font-weight: 700;
                    color: #303030;
                    //font-size: .922rem;
                }

                &.grade_M {
                    font-weight: 500;
                    color: #444;
                    //font-size: .8777rem;
                }
            }

            .investor {
                transition: all .15s ease;

                > div {
                    font-size: .8333rem;
                    line-height: 1;
                    display: inline-flex;
                    align-items: flex-start;

                    > div {
                        padding-top: 2px;
                    }

                    &.company {
                        cursor: pointer;
                        font-weight: 500;

                        &:hover {
                            color: $iq-color-blue;

                            svg {
                                fill: $iq-color-blue;
                            }
                        }
                    }
                }

                & + .investor {
                    margin-top: 7px;
                }

                .investor-name {
                    display: inline-flex;
                    align-items: center;

                    svg {
                        transition: all .2s ease;
                        fill: #B8C4E6;
                        max-height: 17px;
                    }

                    .icon {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 20px;
                        margin-right: 7px;
                    }

                    &.is-owner {
                        color: $iq-color-text-primary;
                        font-weight: 700;
                    }
                }
            }

            .tbody {
                > div {
                    //max-height: 95px;
                    > div {
                        box-sizing: border-box;

                        &:nth-child(1) {
                            flex-basis: 20%;
                        }

                        &:nth-child(3) {
                            flex-basis: 15%;
                        }

                        &:nth-child(2) {
                            flex-basis: 90px;
                            flex-grow: 0;
                        }
                    }

                    .investor {
                        transition: all .3s ease;
                        overflow: hidden;
                        max-height: 0;
                        margin-top: 0;

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            max-height: 100px;
                        }

                        &:nth-child(2),
                        &:nth-child(3) {
                            margin-top: 7px;
                        }

                        i {
                            position: relative;
                            //top: 1px;
                        }
                    }

                    .show-more {
                        font-weight: 500;
                        cursor: pointer;
                        padding: 5px 0 0 4px;
                        color: #B8C4E6;


                        &:hover {
                            color: $iq-color-blue;
                        }
                    }

                    &.opened {
                        .investor {
                            //display: block;
                            margin-top: 7px;
                            max-height: 100px;

                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        //max-height: 2000px;
                        .show-more {
                            padding-left: 6px;
                        }
                    }
                }
            }

            .tbody {
                max-height: calc(100vh - 265px);
                overflow: auto;
            }
        }

        .investNewsMarker {
            font-size: .8333rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 23px;
            border: 1px solid $iq-border-color-base;
            border-radius: 5px;
            box-sizing: border-box;
            cursor: default;
            line-height: 1;
        }

        .exportMenu {
            position: absolute;
            top: 60px;
            right: 40px;
            z-index: 300;
            background: #fff;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            box-shadow: $iq-shadow;
            box-sizing: border-box;
            padding: 12px 10px;
            font-size: 14px;
            align-items: stretch;
            width: 190px;
            border: 1px solid $iq-color-blue-light;

            .btn {
                background: $iq-color-blue-light;
                color: $iq-color-blue;
                font-variant: all-small-caps;
                font-size: 14px;
                font-weight: 500;
                padding: 7px 10px;
                line-height: 1;
                border-radius: 5px;
                border: none;
                display: block;
                margin-bottom: 5px;
                cursor: pointer;
                transition: all .25s ease;
                text-align: left;

                &[disabled] {
                    background: $iq-color-grey-ultra-light;
                    color: $iq-color-blue-medium;
                    cursor: not-allowed;

                    &:hover {
                        background: $iq-color-grey-ultra-light;
                        color: $iq-color-blue-medium;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background: $iq-color-blue-medium;
                }
            }

            .closeMenu {
                position: absolute;
                font-size: 18px;
                padding: 5px;
                top: -8px;
                right: -25px;
                color: $iq-color-blue-medium;
                cursor: pointer;
            }
        }

    }
</style>

<style lang="scss" scoped>

</style>
