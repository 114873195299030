<template>
    <div class="fst-Analytics fst-SearchListItem"
         :class="[blockClass,`locale_${locale}`, {'view360': is360view}]"
         v-loading="loading"
         ref="main">
        <div class="filter360" v-if="is360view">

            <div class="periodSelector iq-select-like">
                {{$t('label.period')}} {{$t('label.from')}}:
                <el-select v-model="dateFrom"mainj
                           v-if="!customYearInput"
                           style="width: 70px; margin-left: 3px;"
                           @change="handleChangePeriod()">
                    <el-option v-for="year in yearsFilter"
                               :key="`from-${year.value}`"
                               :label="year.value"
                               :value="year.value"
                    >{{ year.value }}
                    </el-option>
                </el-select>
                <template v-else >
                    <el-input type="number"
                          @change="handleChangePeriod"
                          class="customYearInput" v-model="dateFrom">
                    </el-input>
                    <i class="far fa-angle-double-right ml-1 mr-2 pointer"></i>
                </template>
                <i class="fa fa-keyboard customYearInputIcon" :class="customYearInput ? 'fas' : 'far'"
                @click="customYearInput = !customYearInput"></i>
            </div>
            <span class="dashboardExport" @click="handleExport360">
                    <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                    <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                              fill="currentColor"/>
                    </svg>
                    {{ $t('label.exportToExcel') }}
                </span>

        </div>

        <div class="widgets" v-if="!loading">
            <div v-if="curWidget.group != firstGroup" class="widget-block groupTab"
                 :class="groupNav.prev.class"
                 @click="handleNavWidget('up')">
                <div class="header mb-0">
                    <div class="title">
                        {{$t(groupNav.prev.title)}}
                    </div>
                    <i class="fas fa-chevron-up ml-2"></i>
                </div>
            </div>

            <component v-for="(widget,ind) in curWidgetGroupList" :key="ind"
                       :class="[widget.class]"
                       data-role="widgetBlock"
                       :data-ind="ind"
                       :is="widget.component"
                       :filter="widgetFilter"
                       :params="widget.params"
                       :name="widget.name"
                       :title="$t(widget.title)"
                       :ventureSubtypeFacets="ventureSubtypeFacets"
                       :years="years"
                       :chart-series="chartSeries"
                       :chartOptions="chartOptions">
            </component>
            <div v-if="curWidget.group != lastGroup" class="widget-block groupTab"
                 :class="groupNav.next.class"
                 @click="handleNavWidget('down')">
                <div class="header mb-0">
                    <div class="title">
                        {{$t(groupNav.next.title)}}
                    </div>
                    <i class="fas fa-chevron-down ml-2"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import SWDiagram from "@/components/search/widgets/swDiagram";
    import SWKeywords from "@/components/search/widgets/swKeywords";
    import SWRatedList from "@/components/search/widgets/swRatedList";
    import SWGrowth from "@/components/search/widgets/swGrowth";
    import SWTopCompanies from "@/components/search/widgets/swTopCompanies";
    import SWTopDeals from "@/components/search/widgets/swTopDeals";
    import SWNews from "@/components/search/widgets/swNews";
    import SWInvestmentVentures from "@/components/search/widgets/swInvestmentVentures";
    import SWVentureStages from "@/components/search/widgets/swVentureStages";
    import SWWeakSignals from "@/components/search/widgets/swWeakSignals";
    import SWHeatmap from "@/components/search/widgets/swHeatmap";
    import SWCompanyCountries from "@/components/search/widgets/swCompanyCountries";
    import SWMarkets from "@/components/search/widgets/swMarkets";
    import axios from 'axios';
    import _ from "lodash";

    export default {
        name: "Analytics",
        components: {
            SWMarkets,
            SWKeywords, SWRatedList, SWGrowth, SWVentureStages, SWNews, SWDiagram, SWHeatmap,
            SWTopCompanies, SWTopDeals, SWInvestmentVentures, SWCompanyCountries, SWWeakSignals,
        },
        props: {
            filter: {
                type: Object
            },
            docType: String,
            blockClass: String,
        },
        data() {
            return {
                reQ: {
                    chart: {}
                },
                dateFrom: new Date().getFullYear() - 6,
                curYear: new Date().getFullYear(),
                widgetFilter: {},
                loading: true,
                stopToggleMenu: false,
                exportLoading: false,
                customYearInput: false,
                userSettings: {},
                widgetData: {
                    keywords: [],
                },
                curWidget: {
                    group: 'A',
                },
                ventureSubtypeFacets: [],
                chartSeries: {
                    venture: [{
                        type: 'column',
                        name: 'Sum of investments',
                        title: 'Sum of investments',
                        data: [],
                        color: '#8599D0',
                        format: 'financial'
                    }, {
                        type: 'line',
                        name: 'Deal count',
                        title: 'Deal count',
                        data: [],
                        color: '#607d7d',
                        yaxis: 'opposite'
                    }],
                    ma: [{
                        type: 'column',
                        name: 'Sum of investments',
                        title: 'Sum of investments',
                        data: [],
                        color: '#C69245',
                        format: 'financial'
                    }, {
                        type: 'line',
                        name: 'Deal count',
                        title: 'Deal count',
                        data: [],
                        color: '#607d7d',
                        yaxis: 'opposite'
                    }],
                    markets: [],
                    companySize: [{
                        name: 'Number of companies',
                        data: [],
                        color: '#61B768',
                    }],
                    SciencePatentArticles: []
                },
                chartOptions: {
                    chart: {
                        foreColor: '#6A6C73',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false,
                            offsetX: 7,
                            offsetY: -56,
                        },
                        zoom: {
                            enabled: false,
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                    stroke: {
                        width: [0, 4],
                        curve: 'smooth'
                    },
                    fill: {
                        opacity: 0.95,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: '25%',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                        formatter: function (val, opts) {
                            return val + '$'
                        },
                    },
                    legend: {
                        show: false,
                        position: 'bottom',
                        horizontalAlign: 'left',
                        fontSize: '14px',
                        labels: {
                            colors: '#46484C',
                            useSeriesColors: true
                        },
                        itemMargin: {
                            horizontal: 15,
                            vertical: 5
                        },
                    },
                    grid: {
                        borderColor: '#46484C',
                        // strokeDashArray: 2,
                        yaxis: {
                            lines: {
                                show: true
                            }
                        },
                    },
                    states: {
                        hover: {
                            filter: {
                                type: 'none',
                            }
                        },
                    },
                    yaxis: [{
                        labels: {
                            style: {
                                colors: '#46484C',
                                fontSize: '13px'
                            },
                        },
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                            color: '#46484C',
                        },
                        crosshairs: {
                            show: false,
                        }
                    }],
                    xaxis: {
                        color: '#46484C',
                        type: 'category',
                        labels: {
                            style: {
                                fontSize: '13px'
                            },
                        },
                        lines: {
                            show: false,
                        },
                        axisBorder: {
                            offsetY: 1
                        },

                    },
                },
                years: {
                    min: new Date().getFullYear() - 5,
                    max: new Date().getFullYear(),
                    years: [],
                },
                yearsFilter: [],
                widgetBlocks: [],
                groups: [],
                firstGroup: 'A',
                lastGroup: 'B'

            }
        },
        computed: {
            ...mapState({
                userInfo: state => state.userInfo,
                notionsDict: state => state.notions,
            }),
            allowed() {
                return this.$store.state.allowedActions;
            },
            locale() {
                return this.$store.state.locale;
            },
            isDev() {
                return this.userInfo.roles.indexOf('DEV') >= 0;
            },
            widgets() {
                return this.$store.getters.swList;
            },
            is360view() {
                return this.widgets.mode === 'all';
            },
            curWidgetList() {
                return this.widgets.list[this.docType];
            },
            curWidgetIndex() {
                return this.widgets.current;
            },
            curWidgetGroupList() {
                let self = this;
                return self.curWidgetList.filter(wid => {
                    return wid.group === self.curWidget.group
                    // && (!wid.availableForRole || self.allowed.contains(wid.availableForRole))
                })
            },
            groupNav() {
                let self = this,
                    curGroupInd = self.groups.findIndex(it => it === self.curWidget.group),
                    prevGroup = self.curWidgetList.filter(it => it.group === self.groups[curGroupInd - 1]),
                    nextGroup = self.curWidgetList.filter(it => it.group === self.groups[curGroupInd + 1]);
                return {
                    prev: _.last(prevGroup) || {},
                    next: nextGroup[0] || {}
                };
            }
        },
        watch: {
            filter() {
                this.refresh();
            },
            curWidgetIndex(index) {
                let self = this,
                    isNewGroup = self.curWidget.group !== self.curWidgetList[self.curWidgetIndex].group;

                self.curWidget = self.curWidgetList[self.curWidgetIndex];
                self.stopToggleMenu = true;
                setTimeout(() => {
                    let curPos = document.querySelectorAll('[data-role="widgetBlock"]')[index || 0];
                    if (curPos) {
                        self.$refs.main.scrollTo({
                            top: (curPos || {}).offsetTop - 72,
                            behavior: isNewGroup ? 'instant' : "smooth"
                        });
                        setTimeout(() => {
                            self.stopToggleMenu = false;
                        }, 850)
                    } else {
                        setTimeout(() => {
                            curPos = document.querySelectorAll('[data-role="widgetBlock"]')[index || 0];
                            if (!curPos) curPos = document.querySelectorAll('[data-role="widgetBlock"]')[0];
                            self.$refs.main.scrollTo({
                                top: (curPos || {}).offsetTop - 72,
                            });
                            setTimeout(() => {
                                self.stopToggleMenu = false;
                            }, 850)
                        }, 850)
                    }
                    setTimeout(() => {
                        self.widgetBlocks = []
                        let _blocks = Array.from(document.querySelectorAll('[data-role="widgetBlock"]'));
                        _blocks.forEach(block => {
                            self.widgetBlocks.push({
                                top: block.offsetTop,
                                ind: block.getAttribute('data-ind')
                            })
                        })
                    }, 1)

                }, 275)
            }
        },
        mounted() {
            let self = this,
                $main = document.querySelector('.fst-Analytics');
            services.system.info().then(function (resp) {
                self.userSettings = resp.data.userSettings || {};
                self.widgetFilter = _.cloneDeep(self.filter);
                self.refresh();
                self.$store.commit('updateWidgetList', self.allowed);
                $main.removeEventListener('scroll', e => {
                }, false)
                $main.addEventListener('scroll', e => {
                    let pos = $main.scrollTop,
                        block = self.widgetBlocks.find(it => it.top > (pos - 150));
                    if (block && self.widgetBlocks.length > 1 && !self.stopToggleMenu) {
                        self.$store.commit('activeMenuIndex', parseInt(block.ind))
                    }
                }, false);
                self.$refs.main.scrollTo({
                    top: 0,
                });
                self.$store.commit('activeMenuIndex', 0);
                self.loading = false;
                self.yearsFilter = [];
                for (let year = self.dateFrom; year <= self.curYear; year++) {
                    self.yearsFilter.push({value: year, disabled: false});
                }
                if (self.userSettings.skipTour && !self.userSettings.skipTour.searchView360) {
                    self.$emit('quickTour');
                }
            })
        },
        methods: {
            refresh() {
                let self = this,
                    _filter = {};
                _filter.facets = {
                    calculate: false,
                    requests: [{
                        calculate: true,
                        type: "INVT_SUBTYPE"
                    }]
                };
                self.widgetFilter = _.cloneDeep(self.filter);
                if (self.widgets.mode === 'all') {
                    self.widgetFilter.facets.requests = [];
                }
                self.groups = _.orderBy(_.uniq(_.map(self.curWidgetList, 'group')), 'asc');
                self.firstGroup = self.groups[0];
                self.lastGroup = _.last(self.groups);
                if (self.widgets.mode === 'all') {
                    self.years.max = self.curYear;
                    self.years.min = self.dateFrom;
                } else {
                    let _years = ((self.widgetFilter.facets.requests || []).find(it => ['YEAR', 'INVT_YEAR'].includes(it.type)) || {}).values;
                    self.years.max = _.max((_years || []).map(it => parseInt(it))) || (new Date().getFullYear());
                    self.years.min = _.min((_years || []).map(it => parseInt(it))) || (new Date().getFullYear() - 5);
                }
                self.years.years = [];
                for (let year = self.years.min; year <= self.years.max; year++) {
                    self.years.years.push(year.toString());
                }

                if (self.allowed.contains('COMPANIES_SEARCH')) {
                    self.$services.documents.investment(_filter).then(resp => {
                        self.ventureSubtypeFacets = _.map((resp.data.facets[0] || {}).items, 'value');
                    });
                }
                setTimeout(() => {
                    self.widgetBlocks = [];
                    let _blocks = Array.from(document.querySelectorAll('[data-role="widgetBlock"]'));
                    _blocks.forEach(block => {
                        self.widgetBlocks.push({
                            top: block.offsetTop,
                            ind: block.getAttribute('data-ind')
                        })
                    })
                }, 250)
            },
            handleNavWidget(dir) {
                let self = this,
                    curGroupInd = self.groups.findIndex(it => it === self.curWidget.group),
                    newGroupInd = dir === 'down' ? self.groups[curGroupInd + 1] : self.groups[curGroupInd - 1],
                    newGroup = self.curWidgetList.filter(it => it.group === newGroupInd),
                    ind = dir === 'down' ? newGroup[0].ind : _.last(newGroup).ind;
                // console.log('group', newGroup);
                self.$store.commit('toggleSwMenu', ind);
            },
            handleExport360() {
                if (!this.exportLoading) {
                    let self = this,
                        _query = {
                            "filter": self.widgetFilter,
                            "years": {
                                "minBoundType": "CLOSED",
                                "maxBoundType": "CLOSED",
                                "min": self.years.min,
                                "max": self.years.max
                            }
                        },
                        fileName = 'Teqviser_View360.xlsx';

                    self.exportLoading = true;

                    axios({
                        url: this.$store.state.apiUrl + '/analytics/fullViewExport',
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then((resp) => {
                        // console.log('Checked query:', query, this.filter);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.exportLoading = false;
                    })
                }
            },

            handleChangePeriod() {
                let self = this;
                self.years.max = self.curYear;
                self.years.min = self.dateFrom;
                self.years.years = [];
                for (let year = self.years.min; year <= self.years.max; year++) {
                    self.years.years.push(year.toString());
                }
                self.widgetFilter = _.cloneDeep(self.filter);
            },
            resetScroll() {
                this.$refs.main.scrollTo({top: 0});
            },
        },
    }
</script>

<style lang="scss">
    .fst-Analytics {
        overflow: auto;
        padding: 15px 0 0;
        min-height: 60vh;

        .noResult {
            width: auto !important;
        }

        .filter360 {
            margin: -10px 15px 7px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .periodSelector {
            color: $iq-color-grey-light;
            font-weight: 500;
            line-height: 1;
            position: relative;
            white-space: nowrap;
            top: 1px;
            .customYearInput{
                &.el-input--small .el-input__inner{
                    background: #fff;
                    width: 75px;
                    border-radius: 4px;
                    margin: 0 5px;
                    padding:  0 10px;
                }
            }
            .customYearInputIcon{
                font-size: 16px;
                color: $iq-color-primary;
                cursor: pointer;
            }

        }

        &.fst-SearchListItem {
            max-height: calc(100vh - 180px);

            &.view360 {
                /*max-height: none;*/

                .widgets {
                    flex-wrap: wrap;
                    display: flex;
                    align-items: stretch;
                    padding-right: 20px;
                    /*max-height: calc(100vh - 205px);*/
                    /*overflow-y: auto;*/

                    & > div {
                        flex-basis: calc(50% - 10px);
                        min-width: 570px;
                        max-width: none;

                        &.full {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }

        div {
            box-sizing: border-box;
        }

        .cloud {
            .keyword {
                text-transform: capitalize;
                cursor: pointer;
            }
        }

        .widgets {
            /*display: flex;*/
            /*flex-wrap: wrap;*/
            justify-content: space-between;
        }

        .widget-block {
            //flex-basis: calc(50% - 8px);
            max-width: 850px;
            min-height: 425px;
            flex-basis: 850px;
            background: #fff;
            border: $border;
            margin-bottom: 15px;
            padding: 15px 20px;

            &.groupTab {
                min-height: 0;

                .header {
                    cursor: pointer;
                }
            }

            &:last-of-type {
                /*margin-bottom: 0;*/
            }

            &.full {
                max-width: calc(100% - 20px);
            }

            .header {
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 25px;

                .title {
                    font-size: 17px;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    position: relative;
                }

                .viewAll {
                    font-variant: all-small-caps;
                    font-size: 14px;
                    color: $iq-color-primary;
                    margin-left: 20px;
                    cursor: pointer;
                    font-weight: 500;
                    transition: all .15s ease;

                    &.noClick {
                        cursor: default;
                    }

                    span {
                        color: $iq-color-primary;
                        cursor: pointer;
                        transition: all .15s ease;

                        &:hover {
                            color: $iq-color-primary;
                        }
                    }

                    &:hover:not(.noClick) {
                        color: $iq-color-primary;
                    }
                }
                .exportBtn{
                    font-weight: 900;
                    font-size: 19px;
                    color: $iq-color-grey-blue;
                    margin-left: 12px;
                    cursor: pointer;
                }
            }
        }

        .cards {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 -15px -15px;

            > * {
                flex-basis: calc(50% - 20px);
                margin: 0 0 15px 15px;
            }

            .card {
                border-radius: 5px;
                cursor: pointer;
                //background: $iq-color-blue-light;
                border: $border;
                padding: 15px;
                display: flex;
                color: $iq-color-text-primary;
                font-size: 14px;

                .logo {
                    flex-basis: 70px;
                    flex-shrink: 0;
                    height: 60px;
                    border-radius: 5px;
                    background: $iq-dark-theme-primary;
                    //border: $border;
                    margin-right: 15px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                        border-radius: 5px;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .title {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 1.11;
                    margin-bottom: 4px;
                }

                .link,
                .investmentTotal {
                    white-space: nowrap;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 4px;
                    font-size: 13px;
                    font-variant: all-small-caps;
                }

                .investmentTotal {
                    font-weight: 500;
                    color: darken($iq-color-dashboard-green, .25);
                    font-size: 13px;
                }

                a {
                    color: $iq-color-primary;
                    text-decoration: none;
                    transition: all .15s ease;

                    &:hover {
                        color: fade_out($iq-color-primary, 0.10);
                    }
                }
            }
        }

        .topDeals {
            color: $iq-color-text-primary;
            max-height: 385px;
            overflow-y: auto;
            padding-right: 10px;

            > div {
                display: flex;
                gap: 10px;

                & + div {
                    margin-top: 18px;
                }

            }

            .item {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .date-news {
                    display: flex;
                    gap: 5px;
                    white-space: nowrap;
                }

                .date {
                    //text-transform: lowercase;
                }

                .sum {
                    font-weight: 500;
                    color: darken($iq-color-dashboard-green, .25);
                }

                .company {
                    font-weight: 500;
                    color: $iq-color-primary;
                    cursor: pointer;
                    transition: all .15s ease;

                    &:hover {
                        color: fade_out($iq-color-primary, .2);
                    }
                }

                .news {
                    i {
                        padding: 0 3px;
                        font-size: 12px;
                        position: relative;
                        top: -2px;
                        color: $iq-color-grey-blue;
                        //color: $iq-dark-theme-link;
                        cursor: default;

                        &:hover {
                            color: fade_out($iq-color-primary, .2);
                        }
                    }
                }

            }

        }

        .newsCards {
            padding: 0 20px;
            max-height: 390px;
            overflow-y: auto;
            min-height: 320px;

            .cardsSubtitle {
                font-size: 16px;
                margin: -23px 0 10px;
                font-weight: 600;
            }

            .cardItem {
                cursor: pointer;
                display: block;
                border-radius: 5px;
                background: none;
                border: $border;
                padding: 15px 20px;
                color: $iq-color-text-primary;
                font-size: 14px;
                text-decoration: none;

                em {
                    font-style: normal;
                    //color: #fff;
                }

                & + .cardItem {
                    margin-top: 10px;
                }

                .title {
                    color: $iq-color-text-primary;
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 12px;
                    line-height: 1.15;
                    max-height: 40px;
                    overflow: hidden;
                    transition: all .2s ease;
                }

                &:hover {
                    .title {
                        color: $iq-color-primary;
                    }
                }

                .tags {
                    display: flex;
                    margin: 0 -3px 12px -3px;
                    flex-wrap: wrap;
                    max-height: 24px;
                    overflow: hidden;
                    //transition: all .35s ease-in .15s;
                    //cursor: pointer;

                    &:hover {
                        //max-height: 180px;
                        //transition: all .66s ease-in .55s;
                    }

                    > div {
                        margin: 0 3px 5px;
                        background: #D5EDD7;
                        border: none;
                        color: $iq-color-text-primary;
                        font-variant: all-small-caps;
                        font-size: 14px;
                        font-weight: 500;
                        border-radius: 4px;
                        padding: 4px 9px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }


                .attrs {

                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-variant: all-small-caps;

                    a {
                        color: $iq-color-primary;
                        text-decoration: none;
                        transition: all .15s ease;

                        &:hover {
                            color: fade_out($iq-color-primary, .2);
                        }
                    }

                    > * {
                        display: inline-block;

                        & + * {
                            display: inline-flex;
                            align-items: center;

                            &::before {
                                content: ' ';
                                display: inline-block;
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background: #7D8BB2;
                                margin: 0 9px 0 7px;
                            }
                        }
                    }
                }
            }

            &.news {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-primary;
                        }
                    }
                }
            }

            &.science {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-dashboard-red;
                        }
                    }
                }
            }

            &.patents {
                .cardItem {
                    &:hover {
                        .title {
                            color: $iq-color-dashboard-orange;
                        }
                    }
                }
            }

        }

        .widgetMode {
            display: flex;
            align-items: center;

            > span {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                color: $iq-color-grey-blue;

                &.patents {
                    margin-left: 8px;

                    &.active {
                        color: $iq-color-dashboard-orange;
                    }

                    svg {
                        margin-right: 3px;
                    }
                }

                &.science {
                    &.active {
                        color: $iq-color-dashboard-red;
                    }

                    svg {
                        margin-right: 3px;
                    }
                }

                & + span {
                    margin-left: 8px;
                }

                &.active {
                    color: $iq-color-primary;
                }
            }
        }

        .axisLabels {
            display: flex;
            justify-content: space-between;
        }

        .apexcharts-legend.position-bottom {
            margin-top: 15px;
        }

        .apexcharts-legend-series {
            display: flex;
            align-items: center;

            .apexcharts-legend-marker {
                margin-right: 7px;
            }
        }

        .techWeakSignals {
            background: #fff;
            //border: $border;
        }

        .export-calculate {
            display: flex;
            align-items: center;
            margin-right: -7px;
        }

        div.iqTab {
            > .tbody:first-child {
                > div {
                    align-items: center;
                }
            }
        }

        .widgetNav {
            padding: 2px 20px;
            line-height: 1;
            font-size: 16px;
            text-align: center;
            cursor: pointer;
            border: $border;
            background: #fff;
            width: 100px;

            &:hover {
                color: $iq-color-primary;
            }
        }

        .apexcharts-series-markers circle,
        .apexcharts-bar-series > g > path {
            cursor: pointer;
        }

        .significantSelect {
            width: 118px;
        }

        &.locale_ru {
            .significantSelect {
                width: 140px;
            }

        }

        .hint {
            opacity: .5;
            transition: all .25s ease;

            &:hover {
                opacity: .75;
            }
        }

        .apexcharts-menu-icon {
            svg {
                display: none;
            }

            &:before {
                content: "\f0c7";
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                font-size: 1.5em;
                color: $iq-color-grey-blue;
            }
        }

        .apexcharts-menu-item {
            &.exportCSV {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .fst-Analytics {
            &.fst-SearchListItem {
                &.view360 {
                    .widgets {
                        & > div {
                            flex-basis: calc(100% - 10px);
                            min-width: 570px;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }
</style>