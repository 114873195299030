<template>
    <el-dialog
        title="Edit company"
        :visible="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="dialog-custom text-left"
        width="780px"
        @close="close"
    >
        <div class="edit-company-form">
            <el-form label-width="130px" :model="form">
                <el-form-item label="Company name">
                    <el-input v-model="form.name" autocomplete="group-name"/>
                </el-form-item>
                <el-form-item label="Roles">
                    <el-select v-model="form.roles" multiple autocomplete="group-roles"
                               class="edit-company-form__roles">
                        <el-option
                            v-for="item in $store.state.roles"
                            :key="item.role"
                            :label="item.name"
                            :value="item.role"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="Users" v-if="users.length > 0">
                    <ol class="userList">
                        <li v-for="(item,key) in users" :key="key" v-if="item.show">
                            <div>
                                <i class="far fa-lock mr-2" v-if="item.state === 'LOCKED'"></i>
                                <span v-if="item.fullName">{{ item.fullName }}</span>
                                <span v-else>{{ item.username }}</span>
                                <i class="fas fa-times ml-auto pointer p-2" @click="removeUser(item)"></i>
                            </div>
                        </li>
                    </ol>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <div class="d-flex justify-content-between">
                <div>
                    <el-button size="default" type="danger" @click="delCompany" v-if="userIsAdmin">Delete company
                    </el-button>
                </div>
                <div>
                    <el-button size="default" type="text" @click="close">{{ $t('cancel') }}</el-button>
                    <el-button size="default" type="primary" @click="save">{{ $t('save') }}</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>

import {USER_ROLES} from "@/models/Users";

export default {
    name: 'EditGroup',
    props: {
        item: {
            type: Object
        },
        close: {
            type: Function
        }
    },
    data() {
        return {
            form: {
                id: this.item ? this.item.id : null,
                name: '',
                roles: []
            },
            users: [],
            userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN)
        };
    },
    created() {
        this.getUsers();
    },
    computed: {},
    methods: {
        save() {
            let _query = {
                id: this.item ? this.item.id : null,
                name: this.form.name,
                roles: this.form.roles
            };
            console.log('save:', _query)
            this.$services.companies.save(_query).then(resp => {
                this.$message({
                    message: 'Company saved',
                    type: 'success'
                });
                this.close();
            });
        },
        removeUser(item) {
            if (item && item.id) {
                let _query = {
                    user: {
                        id: item.id,
                        company: null
                    },
                    unsetCompany: true
                }
                this.$confirm('This will remove user from Company. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$services.security.saveUser(_query).then(() => {
                        item.show = false;
                    })
                }).catch(() => {});

            }
        },
        getUsers(){
            console.log('thisItem',this.item);
            if (this.item) {
                this.$services.security.users({
                    offset: 0,
                    count: 100000,
                    states: ['ENABLED', 'LOCKED'],
                    companyIds: [this.item.id],
                }).then(resp => {
                    this.form = this.item;
                    this.users = resp.data ? resp.data.list.map(it=>{
                        let out = it;
                        out.show = true;
                        return out;
                    }) : null;
                });
            }

        },
        delCompany() {
            if (this.item.id) {
                this.$confirm('This will delete this Company and lock users. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    console.log('DEL this.form.users:', this.users);
                    this.users.forEach(it => {
                        this.$services.security.saveUser({
                            user: {
                                state: 'LOCKED',
                                id: it.id
                            }
                        }).then(resp => {
                            console.log('LOCK resp', resp.data);
                        });
                    });
                    this.$services.companies.delete(this.item.id).prepare().then((resp) => {
                        this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                        this.close();
                    });
                }).catch(() => {
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.edit-company-form {
    padding: 20px 82px 20px 20px;

    &__roles {
        width: 100%;
    }

    .userList {
        margin: 0;
        padding: 3px 10px 3px 25px;
        border-radius: 5px;
        border: 1px solid $iq-border-color-base;

        li {
            font-size: .8rem;
            line-height: 1.2;
            padding: 2px 6px;
            border-radius: 3px;

            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &:nth-child(even) {
                background: $iq-color-blue-light-ultra;
            }
        }
    }
}

/deep/
.el-transfer {
    display: flex;
    align-items: center;

    .el-transfer-panel {
        flex: 1;
        width: auto;

        .el-checkbox__label {
            font-size: 14px !important;
        }

        &__item {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 15px);
        }
    }

    .el-transfer__buttons {
        display: flex;
        align-items: flex-start;
        padding: 0 20px;
        flex-direction: column;
    }

    .el-button + .el-button {
        margin-left: 0
    }

}

/deep/
.el-transfer__buttons {
    padding: 0 10px;
}
</style>
