<template>
    <div class="techWeakSignals">
        <div class="wrapper"
             v-loading="loading"
             element-loading-background="rgba(19, 19, 19, .7)">

            <div class="filter-block">
                <div class="flex-center">
                    <div>
                        {{$t('label.period')}} {{$t('label.from')}}
                        <el-select v-model="filter.dateFrom"
                                   style="width: 63px;"
                                   popper-class="darkSelect"
                                   @change="updateDataSchema = true">
                            <el-option v-for="year in years"
                                       :key="`from-${year.value}`"
                                       :label="year.value"
                                       :value="year.value"
                            >{{ year.value }}
                            </el-option>
                        </el-select>

                    </div>
                    <div>
                        {{$t('label.to')}}
                        <el-select v-model="filter.dateTo"
                                   style="width: 63px;"
                                   popper-class="darkSelect"
                                   @change="updateDataSchema = true">
                            <el-option v-for="year in years"
                                       :key="`to-${year.value}`"
                                       :value="year.value"
                                       :label="year.value"
                                       :disabled="year.disabled"
                            >{{ year.value }}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <el-button type="primary" @click="apply()">{{$t('label.apply')}}</el-button>

                <div class="selector"></div>

                <el-radio-group v-model="filter.type" @change="apply()">
                    <el-radio-button label="Science">{{ $t('Science') }}</el-radio-button>
                    <el-radio-button label="News">{{ $t('News') }}</el-radio-button>
                </el-radio-group>
                <el-radio-group v-model="mode" v-if="userIsAdmin"
                                :disabled="!(result.list || []).length">
                    <el-radio-button label="table"><i class="fal fa-table"></i></el-radio-button>
                    <el-radio-button label="chart"><i
                            class="fas fa-chart-network fa-code-branch fa-rotate-90"></i></el-radio-button>
                </el-radio-group>


                <template v-if="mode === 'chart'">

                    <div class="selector"></div>

                    <div class="scale">
                        <i class="fas fa-search-minus"></i>
                        <el-slider :show-tooltip="false" v-model="scale"></el-slider>
                        <i class="fas fa-search-plus"></i>
                        <!--el-input :value="maxChartRadius" size="mini"></el-input-->
                    </div>

                    <div class="selector"></div>

                    <div class="sortBlock">
                        <div class="sortLabel">{{$t('label.sortBy')}}</div>
                        <el-select v-model="sortBy.field" @change="handleSortBy" size="mini">
                            <el-option v-for="item in dataSchema"
                                       :label="(item.labelNoBr || item.label) + (isNumber(item.label) ? $t('label.byYear') : '')"
                                       :value="item.field"
                                       :key="item.field"></el-option>
                        </el-select>
                        <el-tooltip :open-delay="350" :content="$t('label.sortDirection')">
                            <i class="fas mr-1 px-2 pointer" @click="toggleSortDir()"
                               :class="sortBy.dir === 'DESC' ? 'fa-long-arrow-down' : 'fa-long-arrow-up'"></i>
                        </el-tooltip>
                    </div>

                    <div class="selector"></div>

                    <el-tooltip :open-delay="350" :content="showSign ? $t('label.hideSign') : $t('label.showSign')">
                        <div @click="showSign = !showSign" class="pointer">
                            <i class="fas pl-1" :class="showSign ? 'fa-eye-slash' : 'fa-eye'"></i>
                        </div>
                    </el-tooltip>

                    <el-tooltip :open-delay="350" :content="$t('label.filterSign')">
                        <el-popover
                                placement="bottom"
                                width="250"
                                :visible-arrow="false"
                                popper-class="subFilter dark"
                                trigger="click"
                                v-model="signItem.subFilter.visible">
                            <div class="input-group">
                                <el-input v-model="signItem.subFilter.from" :placeholder="$t('from')"
                                          clearable
                                          @keydown.native.enter.prevent="handleSubFilter(signItem)"
                                ></el-input>
                                <el-input v-model="signItem.subFilter.to" :placeholder="$t('to')"
                                          clearable
                                          @keydown.native.enter.prevent="handleSubFilter(signItem)"
                                ></el-input>
                                <el-button type="primary" @click="handleSubFilter(signItem)">
                                    {{$t('label.apply')}}
                                </el-button>
                            </div>
                            <div slot="reference">
                                <div :class="['pointer','pl-1',{active: signItem.subFilter.from || signItem.subFilter.to || signItem.subFilter.predicate}]">
                                    <i :class="['fas',
                                                (signItem.subFilter.from || signItem.subFilter.to || signItem.subFilter.predicate ? 'fa-filter' : 'fa-ellipsis-v')]"></i>
                                </div>
                            </div>
                        </el-popover>
                    </el-tooltip>
                </template>
            </div>
            <div class="dashboard-content pt-0">
                <div class="chart-block">
                    <weak-signals-chart
                            :wsData="result"
                            :maxRadius="maxChartRadius"
                            :showSign="showSign"
                            @mouseOver="mouseOver"
                            @mouseOut="mouseOut"
                            v-if="mode === 'chart'"></weak-signals-chart>
                    <div class="table-like iqTab sortTab" v-else>
                        <div class="tbody">
                            <div>
                                <div v-for="(item,key) in dataSchema" :key="key" :class="item.dir">
                                    <div class="sortBy" @click="handleSortBy(item)">
                                        <div class="nowrap" v-html="item.label"></div>
                                        <span></span>
                                    </div>
                                    <el-popover
                                            placement="bottom"
                                            width="250"
                                            :visible-arrow="false"
                                            popper-class="subFilter dark"
                                            trigger="click"
                                            v-model="item.subFilter.visible">
                                        <div class="input-group">
                                            <template v-if="typeof item.subFilter.predicate !== 'undefined'">
                                                <el-input v-model="item.subFilter.predicate"
                                                          :placeholder="$t('textSearch')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                            </template>
                                            <template v-else>
                                                <el-input v-model="item.subFilter.from" :placeholder="$t('from')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                                <el-input v-model="item.subFilter.to" :placeholder="$t('to')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                            </template>
                                            <el-button type="primary" @click="handleSubFilter(item)">
                                                {{$t('label.apply')}}
                                            </el-button>
                                        </div>
                                        <div slot="reference">
                                            <div :class="['subFilterBtn',{active: item.subFilter.from || item.subFilter.to || item.subFilter.predicate}]">
                                                <i :class="['fas',
                                                (item.subFilter.from || item.subFilter.to || item.subFilter.predicate ? 'fa-filter' : 'fa-ellipsis-v')]"></i>
                                            </div>
                                        </div>
                                    </el-popover>
                                    <div class="clearSubFilter"
                                         @click="handleClearSubFilter(item)"
                                         v-show="item.subFilter.from || item.subFilter.to || item.subFilter.predicate">
                                        <i class="fas fa-times"></i></div>

                                </div>
                            </div>
                        </div>
                        <div class="tbody" ref="tbody" v-if="result.list.length > 0">
                            <div v-for="(item, key) in result.list" :key="key">
                                <div>
                                    <div class="d-inline-block"
                                         @mouseover="mouseOver(item)"
                                         @mouseout="mouseOut()">
                                        <text-highlight
                                                class="wiki"
                                                :queries="[dataSchema.find(it => it.field === 'keyword').subFilter.predicate]"
                                                :highlightClass="'markIt'">
                                            {{ item.keyword }}
                                        </text-highlight>
                                    </div>
                                </div>
                                <div v-for="(it, k) in item.counts" :key="`${key}-${k}`" class="num">
                                    <span class="showDoc" @click="handleShowDocuments(item.keyword, k)">{{ it }}</span>
                                </div>
                                <div>
                                    {{ item.significance }}
                                </div>
                                <div>
                                    <i :class="['far up', item.iconLastYear]" v-if="item.iconLastYear"></i>
                                    {{ item.growthLastYear }}
                                </div>
                                <div>
                                    <i :class="['far up', item.icon]" v-if="item.icon"></i>
                                    {{ item.growth }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="noResult"><h3>No result</h3></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import WikiText from '@/commons/components/WikiText';
    import PredicateEditor from '@/components/search/PredicateEditor';
    import WeakSignalsChart from './widgets/WeakSignalsChart'
    import _ from "lodash";


    export default {
        name: "TechnologyWeakSignals",
        components: {WeakSignalsChart},
        props: {
            filterQuery: String,
            incEvent: Object,
            queryFields: Object,
            isNativeQuery: Boolean,
        },
        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                predicateValue: '',
                loading: false,
                showSign: true,
                mode: 'table',
                scale: 50,
                maxScaleWidth: 100,
                filter: {
                    dateFrom: new Date().getFullYear() - 2,
                    dateTo: new Date().getFullYear(),
                    count: 1000,
                    type: 'Science'
                },
                showWikiTimeout: 0,
                years: [],
                updateDataSchema: true,
                minYear: 2015,
                maxYears: 3,
                curYear: new Date().getFullYear(),
                dataSchema: [],
                dataSchemaInit: [
                    {
                        label: this.$t('dashboard.weakSignalsTab.keyword'),
                        dir: '',
                        field: 'keyword',
                        subFilter: {predicate: '', visible: false, filled: false}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.significance'),
                        dir: '',
                        field: 'significance',
                        subFilter: {from: 0.05, to: null, visible: false, filled: true}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.growthLastYear'),
                        labelNoBr: this.$t('dashboard.weakSignalsTab.growthLastYearNoBr'),
                        dir: '',
                        field: 'growthLastYear',
                        subFilter: {from: null, to: null, visible: false, filled: false}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.growth'),
                        dir: 'DESC',
                        field: 'growth',
                        subFilter: {from: null, to: null, visible: false, filled: false}
                    },
                ],
                sortBy: {
                    // field: 'growthLastYear',
                    field: new Date().getFullYear(),
                    dir: "DESC"
                },
                result: {
                    list: [],
                    max: 0,
                    total: 0,
                    offset: 0
                },
                statuses: [],
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            lastYear() {
                return this.$store.state.lastYears[this.filter.type]
            },
            maxChartRadius() {
                return (this.scale * this.maxScaleWidth / 100) + 100
            },
            signItem() {
                return this.dataSchema.find(it => it.field === 'significance')
            },
            curOrderFieldDir() {
                return this.dataSchema.find(it => it.field === this.sortBy.field).dir
            }
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            }
        },
        mounted() {
            this.initData();

        },
        methods: {
            search() {
                let self = this;
                let sortYear = parseInt(self.sortBy.field);
                if (sortYear && (sortYear > self.filter.dateTo || sortYear < self.filter.dateFrom)) {
                    self.sortBy.field = self.filter.dateTo > self.lastYear ? self.lastYear : self.filter.dateTo;
                }
                let _dynamicPeriod = self.filter.dateTo - self.filter.dateFrom;
                let query = {
                    "filter": {
                        "query": self.filterQuery,
                        "queryFields": self.queryFields.value,
                        "types": [self.filter.type],
                        "duplicate": false,
                        "spam": false,
                        "subfilters": [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    },
                    "year": self.filter.dateTo,
                    "dynamicPeriod": _dynamicPeriod > 0 ? _dynamicPeriod : 1,
                    "count": self.filter.count,
                    "order": {
                        "field": self.sortBy.field || self.filter.dateTo, // keyword/significance/growth/YYYY
                        "asc": self.sortBy.dir === 'ASC'
                    }
                };
                // console.log('sChema::', this.dataSchema.filter(it => it.subFilter.filled));
                (self.dataSchema.filter(it => it.subFilter.filled) || []).forEach(it => {
                    let _sf = it.subFilter,
                        isPrc = ['growthLastYear', 'growth'].includes(it.field),
                        _stop = false,
                        _query = query;

                    if (parseInt(it.field)) {
                        if (it.field > self.filter.dateTo || it.field < self.filter.dateFrom) {
                            _stop = true;
                        } else {
                            query.counts = query.counts || {};
                            _query = query.counts;
                        }
                    }
                    if (!_stop) {
                        if (typeof _sf.predicate !== 'undefined') {
                            query[it.field] = _sf.predicate
                        } else {
                            _query[it.field] = {}
                            if (!isNaN(parseInt(_sf.from))) {
                                _.merge(_query[it.field], {
                                    "minBoundType": "CLOSED",
                                    "min": isPrc ? parseFloat(_sf.from) / 100 : _sf.from,
                                });
                            }
                            if (!isNaN(parseInt(_sf.to))) {
                                _.merge(_query[it.field], {
                                    "maxBoundType": "CLOSED",
                                    "max": isPrc ? parseFloat(_sf.to) / 100 : _sf.to,
                                });
                            }
                        }
                    }
                })
                if (this.isNativeQuery) { // --- if native Query is ON ---
                    query.filter.nativeQuery = {
                        value: self.filterQuery
                    };
                    delete query.filter.query;
                }

                console.log('WS Filter: ', query);
                self.loading = true;
                this.$services.analytics.keywordGrowth(query).then(resp => {
                    let _from = this.filter.dateFrom >= this.filter.dateTo ? this.filter.dateTo - 1 : this.filter.dateFrom;
                    // this.filter.dateFrom = _from = _to > _from ? _from : _from - 1;

                    self.result.list = resp.data.keywords.map(it => {
                        it.countsRaw = [];
                        for (let year = _from; year <= self.filter.dateTo; year++) {
                            let _val = parseInt(it.counts[year]);
                            self.result.max = _val > self.result.max ? _val : self.result.max;
                            it.counts[year] = self.$utils.toFin(it.counts[year], 2) || 0;
                            it.countsRaw.push({
                                year: year,
                                value: _val || 0,
                                formattedValue: it.counts[year]
                            });
                        }
                        it.icon = it.growth > 0 && (typeof it.growth !== 'undefined') ? 'fa-long-arrow-alt-up' : '';
                        it.growth = (typeof it.growth !== 'undefined') ? self.$utils.toFin(it.growth * 100, 2) + '%' : 'new'; // &infin;
                        it.iconLastYear = it.growthLastYear > 0 && it.growth !== 'new' ? 'fa-long-arrow-alt-up' : '';
                        // it.iconLastYear = it.growthLastYear > 0 || (typeof it.growth === 'undefined') ? 'fa-long-arrow-alt-up' : '';
                        it.growthLastYear = (typeof it.growthLastYear !== 'undefined') ? self.$utils.toFin(it.growthLastYear * 100, 2) + '%' : 'new';
                        it.significance = self.$utils.roundX(it.significance, 2);

                        return it;
                    });
                    console.log('WS Data: ', self.result);

                    if (this.updateDataSchema) {
                        this.refreshDataSchema();
                    }

                    // console.log('----- Schema: ', self.dataSchema);
                    self.loading = false;
                });

            },
            refresh(params = {}) {
                this.search();
                // this.$refs.predicate.apply();
                if (!params.noScroll && this.$refs.tbody) {
                    this.$refs.tbody.scrollTop = 0;
                }

            },
            apply() {
                this.$emit('apply');
            },
            initData() {
                for (let year = this.minYear; year <= this.curYear; year++) {
                    this.years.push({value: year, disabled: false});
                }
                this.sortBy.field = this.lastYear;
                this.refreshDataSchema();
                this.search();
            },
            refreshDataSchema() {
                let self = this;
                let oldSchema = _.cloneDeep(self.dataSchema);
                self.dataSchema = _.cloneDeep(self.dataSchemaInit.map(it => {
                    it.dir = '';
                    return it;
                }));

                let _from = this.filter.dateFrom,
                    _to = this.filter.dateTo;

                this.filter.dateFrom = _from = _to > _from ? _from : _from - 1;

                for (let y = _from; y <= (_to); y++) {
                    let _sf = (oldSchema.find(it => it.field === y) || {}).subFilter;
                    self.dataSchema.splice(-3, 0, {
                        label: y,
                        field: y,
                        subFilter: {
                            from: _sf && _sf.from ? _sf.from : null,
                            to: _sf && _sf.to ? _sf.to : null,
                            visible: false,
                            filled: _sf ? !!(_sf.filled) : false
                        }
                    });
                }

                self.dataSchema.find(it => it.field === self.sortBy.field).dir = self.sortBy.dir;
                self.updateDataSchema = false;
            },
            handleAdjustSortFilter() {
                console.log(this.filter);
                console.log(this.dataSchema);

            },
            mouseOver(item) {
                this.showWikiTimeout = setTimeout(() => {
                    this.showWiki(item);
                }, 909)
            },
            mouseOut() {
                clearTimeout(this.showWikiTimeout);
            },
            toggleSortDir() {
                let _sortBy = this.dataSchema.find(it => it.field === this.sortBy.field),
                    _dir = (_sortBy.dir === 'DESC' ? 'ASC' : 'DESC');
                _sortBy.dir = _dir;
                this.sortBy.dir = _dir;
                this.$nextTick(() => {
                    this.handleSortBy(this.sortBy.field, _dir);
                })
            },
            showWiki(item) {
                this.$modal.dialog(WikiText, {
                    title: "Wikipedia",
                    ':value': item.keyword,
                    params: {
                        class: 'dark-theme'
                    },
                }).catch(() => {
                });
            },
            handleShowDocuments(keyword, year) {
                let self = this,
                    facets = {
                        requests: [
                            {
                                type: "YEAR",
                                values: [year]
                            },
                            {
                                type: "SYSTEM_KEYWORDS",
                                values: [keyword]
                            }
                        ]
                    }
                let params = {
                    query: this.filterQuery,
                    isNativeQuery: !!self.isNativeQuery,
                    docType: self.filter.type,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                }
                self.$utils.openSearch(params);


            },
            handleSortBy(item, dir) {
                item = typeof item === 'object' ? item : {field: item}

                let _curSortDir = _.cloneDeep(this.dataSchema.find(it => it.field === item.field).dir),
                    _sortBy = this.dataSchema.find(it => it.field === item.field);
                _.each(this.dataSchema, it => {
                    it.dir = '';
                });
                if (dir) {
                    _sortBy.dir = dir;
                } else if (_curSortDir) {
                    _sortBy.dir = (_curSortDir === 'DESC') ? 'ASC' : 'DESC';
                } else {
                    _sortBy.dir = 'DESC';
                }
                this.sortBy = {
                    field: item.field,
                    dir: _sortBy.dir
                }
                this.refresh();

            },
            handleSubFilter(item) {
                let self = this;
                console.log('subFilter', this.dataSchema);
                // this.result.list = this.fullList.filter(it => {
                //     let out = true;
                //     self.dataSchema.forEach(f => {
                //         let isYear = parseInt(f.field),
                //             _out = true;
                //         let _val = parseFloat(isYear ? (it.counts[f.field].toString()).replace(/[\s%]/g, '')
                //             : (it[f.field].toString()).replace(/[\s%]/g, ''));
                //         if (typeof f.subFilter.predicate !== 'undefined') {
                //             _out = !f.subFilter.predicate || (it.keyword || '').includes(f.subFilter.predicate);
                //         } else {
                //             _out = !f.subFilter.from || _val >= parseFloat(f.subFilter.from);
                //             if (_out) {
                //                 _out = !f.subFilter.to || _val <= parseFloat(f.subFilter.to);
                //             }
                //         }
                //         if (!_out) out = false;
                //     })
                //     return out;
                // })
                if (item) {
                    item.subFilter.visible = false;
                    item.subFilter.filled = !!(item.subFilter.from || item.subFilter.to || item.subFilter.predicate);
                }
                this.search();
                // console.log('list', this.result.list);
            },
            handleClearSubFilter(item) {
                if (typeof item.subFilter.predicate !== 'undefined') {
                    item.subFilter.predicate = '';
                } else {
                    item.subFilter.from = '';
                    item.subFilter.to = '';
                }
                this.handleSubFilter();
            },
            isNumber(num) {
                return _.isNumber(num)
            }
        }
    }
</script>

<style lang="scss">
    .techWeakSignals {
        .chart-block {
            max-width: calc(100% - 15px);
        }

        div.iqTab .tbody {
            max-height: calc(100vh - 240px);

            > div > div {
                font-size: 15px;
                padding: 15px 20px;
                line-height: 1;


                &:first-child {
                    flex-basis: 140px;
                    font-weight: 500;
                }
            }

            &:first-child {
                > div > div {
                    display: flex;
                    align-items: center;
                }

                span {
                    padding-left: 5px;
                }
            }

            i {
                &.up {
                    color: $iq-color-green-bright;
                }

                &.down {
                    color: #FF2C48;
                }
            }

            .showDoc {
                margin-left: -6px;
                padding: 1px 8px;
                border-radius: 5px;
                transition: all .1s ease;
                cursor: pointer;
                border: 1px solid rgba(80, 82, 83, .3);

                &:hover {
                    background: #515354;
                    color: #c6c6c6;
                    //font-weight: 500;
                }
            }

            .subFilterBtn {
                &:hover, &:focus, &:active, &.active {
                    color: #fff;
                }
            }
        }

        .sortTab {
            .sortBy {
                > span {
                    margin-right: 0;
                }

                & + span:hover {
                    & + .clearSubFilter {
                        //display: inline-block;
                        opacity: 1;
                        transition: all .2s ease .1s;
                    }
                }
            }
        }

        .noResult {
            min-height: calc(100vh - 202px);
        }

        .markIt {
            background: none;
            color: #fff;
        }

        .filter-block {

            .scale {
                display: flex;
                align-items: center;
                gap: 10px;

                .el-slider {
                    width: 125px;
                }

                .el-input {
                    width: 55px;
                }
            }

            .sortBlock {
                display: flex;
                align-items: center;
                gap: 5px;

                .el-select {
                    padding: 3px 6px 3px 10px;
                    background: $iq-text-color-highlight;
                    border-radius: 5px;
                }
            }
        }
    }

    @media (max-width: 1700px) {
        .techWeakSignals {
            .filter-block {
                .scale {
                    .el-slider {
                        width: 100px;
                    }
                }

                .sortLabel {
                    display: none;
                }

            }
        }
    }
</style>

<style scoped lang="scss">
    #app .fst-Dashboard {
        .wiki {
            cursor: help;
        }

        .filter-block {
            justify-content: flex-start;
            gap: 15px;

            .pointer {
                color: #fff;
            }
        }

        .selector {
            width: 1px;
            height: 32px;
            border-right: 1px solid #B2B2B2;
        }
    }
</style>