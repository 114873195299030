export default {
    step: [
        {
            type: 'modal',
            buttons: {
                first: {
                    name: 'quickTour.skip',
                    action: 'skip'
                },
                second: {
                    name: 'quickTour.start',
                    action: 'next'
                }
            },
            title: `Добро пожаловать в 360 view!`,
            text: `<div class="text-center">
                        <img src="teq-logo-icon1.svg" style="width: 36px;">
                    </div>
                    <div class="text-center">
                        <p>Пройдите небольшой тур, это облегчит начало работы.</p>
                    </div>`
        }, {
            type: 'step',
            title: 'Задайте запросом область своего интереса',
            text: '',
            noteCoords: {
                top: 60,
                left: 0,
                right: 'auto',
                bottom: 'auto'
            },
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.fst-PredicateEditor .predicate-block',
                    class: 'tourHighlight'
                }, {
                    selector: '.nativeSearch',
                    class: 'd-none'
                }]
            }],
        }, {
            type: 'step',
            title: 'Выберите период для анализа',
            text: '',
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.fst-Analytics.view360 .periodSelector',
                    class: 'tourHighlight'
                }]
            }],
            noteCoords: {
                top: 54,
                left: -137,
                right: 'auto',
                bottom: 'auto'
            },
        }, {
            type: 'step',
            title: 'Изучите аналитику',
            text: 'в интерактивных виджетах',
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.view360 .widgets',
                    class: 'tourHighlight'
                }, {
                    selector: '.view360 .widgets',
                    class: 'tourSubStep0'
                }]
            }, {
                type: 'scrollTop',
                targets: [{
                    selector: '.view360 .widgets',
                    value: 0
                }]
            }],
            noteCoords: {
                top: "-100",
                left: '20',
                right: 'auto',
                bottom: 'auto',
                width: '300',
                targetElement: '.widget-block:nth-child(1)',
                targetCoords: true,

            },
            anglePlacement: 'left',
        }, {
            type: 'step',
            availableForRole: 'PRIVATE_SCIENCE_SEARCH',
            title: 'Взаимодействуйте с дашбордом',
            text: 'нажмите на ключевое слово, чтобы посмотреть документы с ним',
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.view360 .widgets',
                            class: 'tourHighlight'
                        }, {
                            selector: '.view360 .widgets',
                            class: 'tourSubStep1'
                        }
                    ]
                }, {
                    type: 'scrollTop',
                    targets: [
                        {
                            selector: '.view360.fst-Analytics',
                            value: 0
                        }
                    ]
                }
            ],
            noteCoords: {
                top: -70,
                left: 20,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.tourHighlight .cloud > div > div > div:first-child > div',
                targetCoords: true,
            },
            anglePlacement: 'left',
        }, {
            type: 'step',
            title: 'Взаимодействуйте с дашбордом',
            availableForRole: 'COMPANIES_SEARCH',
            text: 'нажмите на компанию, чтобы открыть её карточку ',
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.view360 .widgets',
                    class: 'tourHighlight'
                }, {
                    selector: '.view360 .widgets',
                    class: 'tourSubStep2'
                }]
            }],
            noteCoords: {
                top: -110,
                left: 'auto',
                right : -82,
                bottom: 'auto',
                targetElement: '.tourSubStep2 .cards > span:nth-child(3) .card',
                targetCoords: true,
            },
            anglePlacement: 'right',
            timeOut: 1000,
        }, {
            type: 'step',
            title: 'Взаимодействуйте с дашбордом',
            text: 'нажмите на точку на графике, чтобы увидеть научные статьи/патенты по выбранному году',
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.view360 .widgets',
                    class: 'tourHighlight'
                }, {
                    selector: '.view360 .widgets',
                    class: 'tourSubStep3'
                }]
            }],
            noteCoords: {
                top: -74,
                left: 20,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.SW_patentDynamics .apexcharts-series:nth-child(1) .apexcharts-series-markers:nth-child(3) circle ',
                targetCoords: true,
            },
            anglePlacement: 'left',
            timeOut: 303,

        }, {
            type: 'step',
            title: 'Просматривайте документы',
            text: ' по которым была составлена аналитика',
            actions: [{
                type: 'addClass',
                targets: [{
                    selector: '.view360 .widget-block .header .viewAll',
                    class: 'tourHighlight'
                }]
            }, {
                type: 'scrollTop',
                targets: [{
                    selector: '.view360.fst-Analytics',
                    value: 0
                }]
            }],
            noteCoords: {
                top: -50,
                left: 76,
                right: 'auto',
                bottom: 'auto'
            },
            anglePlacement: 'left',
            timeOut: 676,

        }, {
            type: 'step',
            title: 'Переключайтесь',
            text: 'между графиком и последними документами',
            actions: [
                {
                    type: 'addClass',
                    targets: [
                        {
                            selector: '.view360 .widgets',
                            class: 'tourStepGraphDoc'
                        }
                    ]
                }, {
                    type: 'scrollTop',
                    targets: [
                        {
                            selector: '.view360.fst-Analytics',
                            value: -207,
                            targetElement: '.widget-block .widgetMode'
                        }
                    ]
                }
            ],
            noteCoords: {
                top: -60,
                left: 15,
                right: 'auto',
                bottom: 'auto',
                targetElement: '.tourStepGraphDoc .widget-block .widgetMode',
                targetCoords: true,
            },
            anglePlacement: 'left',
            timeOut: 686,
        }, {
            type: 'modal',
            buttons: {
                first: {
                    name: 'quickTour.again',
                    action: 'restart'
                },
                second: {
                    name: 'quickTour.finish',
                    action: 'skip'
                }
            },
            title: 'Спасибо, что прошли этот тур с нами!',
            text: `<div class="text-center">
                        <img src="teq-logo-icon1.svg" style="width: 36px;">
                    </div>
                    <div class="text-center">
                        <p>Хорошего дня!
                            <img src="smile3.png" style="width: 23px;position: relative;top: 6px;left: 3px;"><!--&#128526;--></p>
                    </div>`
        }
    ]
}