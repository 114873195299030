const USER_LIST_COUNT = 100;

const USER_ROLES = {
    ADMIN: 'ADMIN',
};

class Users {
    constructor (list = []) {
        this.userList = list;
        this.listCount = USER_LIST_COUNT;
        this.listOffset = 0;
    }

    get count () {
        return this.listCount;
    }

    get offset () {
        return this.listOffset;
    }

    set offset (value) {
        if (value >= this.total) {
            value = this.count * (Math.floor(this.total / this.count) - 1);
        }
        this.listOffset = value;
    }

    get list () {
        return this.userList.slice(this.listOffset, this.listOffset + this.listCount);
    }

    get fullList () {
        let result = [...this.userList];
        result.sort((a, b) => {
            if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                return -1;
            }
            if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        return result;
    }

    get total () {
        return this.userList.length;
    }

    fill (list) {
        this.userList = list;
        this.offset = this.offset;
    }

    clear () {
        this.userList = [];
        this.listOffset = 0;
    }
}

export {
    USER_LIST_COUNT,
    USER_ROLES,
    Users,
};
