<template>
    <el-tabs v-model="activeTabName" @tab-click="handleTabClick">
        <el-tab-pane label="Document" name="doc" lazy>
            <JsonViewer v-if="doc" :value="doc" :open="1" :fragments="[{path: 'highlights', open: 3}, {path: 'explanation', open: 3}]" />
        </el-tab-pane>
        <el-tab-pane label="Term Vectors" name="termVectors" lazy>
            <JsonViewer v-if="termVectors" :value="termVectors" :open="2" />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import _ from 'lodash';
import JsonViewer from "@/commons/components/JsonViewer";
export default {
    name: 'DocumentDevCard',
    components: {JsonViewer},
    props: {
        documentId: {
            type: Object,
            required: false
        },
        document: {
            type: [Object, Function],
            required: false
        },
    },
    data() {
        const doc = this.document;
        return {
            activeTabName: 'doc',
            doc: !doc ? null : (_.isFunction(doc) ? doc : () => doc),
            termVectors: null,
        };
    },
    computed: {
        docId() {
            const docId = this.documentId;
            if(docId && docId.id && docId.type) {
                return docId;
            }
            let doc = this.document;
            if(_.isFunction(doc)) {
                doc = doc();
            }
            if(_.isObject(doc) && doc.id && doc.type) {
                return {id:doc.id, type:doc.type};
            }
            return null;
        }
    },
    mounted() {
        const docId = this.docId;
        if(docId && !this.doc) {
            this.$services.documents.search({count:1, ids:[docId.id], types: [docId.type]}).prepare().then(resp => {
                const doc = _.get(resp.data, 'list[0]');
                this.doc = () => doc;
            });
        }
    },
    methods: {
        handleTabClick() {
            if(this.activeTabName === 'termVectors') {
                if(this.termVectors) {
                    return;
                }
                this.$services.documents.termVectors({docIds:[this.docId], offsets: false, positions: false}).prepare().then(resp => {
                    const termVectors = (resp.data || [])[0];
                    this.termVectors = () => termVectors;
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
