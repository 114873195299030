<template>
    <div class="fst-MoreLikeThis">
        <el-drawer
            title="More like text"
            :close-on-press-escape="false"
            :visible.sync="showMoreLikeText"
            :with-header="false"
            :wrapperClosable="false"
            size="45%"
            direction="ltr">
            <div class="drawer-container">
                <div class="header">
                    <div class="title">
                        <i class="far fa-file-search mr-1 iq-text-blue"></i> {{$t('label.morLikeText')}}
                    </div>
                    <div>
                        <i class="far fa-times close" @click="closeMe"></i>
                    </div>

                </div>
                <div class="body">
                    <div class="predicate-block">
                        <el-input
                            :placeholder="$t('placeholder.More_like_text')"
                            class="predicate"
                            clearable
                            type="textarea"
                            v-model="predicate"
                            @focus="$event.target.select()"
                            @change="search()"
                        ></el-input>
                        <el-button @click="search"><i class="far fa-search"></i></el-button>
                    </div>
                    <div class="tab-nav">
                        <el-menu :default-active="defaultActiveTab"
                                 :collapse-transition="false"
                                 mode="horizontal"
                                 text-color="#46494D"
                                 active-text-color="#3064F2"
                                 @select="id => handleSelectTab(id)"
                        >
                            <el-menu-item v-for="tab in tabs" :key="tab.id" :index="tab.id"
                                          :class="[tab.id]">
                                <span class="icon" :class="tab.icon" v-if="tab.icon"></span>
                                <span class="name">{{ tab.title || tab.id }}</span>
                            </el-menu-item>
                        </el-menu>
                    </div>
                    <div class="tab-content">
                        <SearchList v-for="tab in tabs" :key="tab.id"
                                    v-show="tab === activeTab"
                                    v-model="tab.result"
                                    :ref="`searchList-${tab.id}`"
                                    :filter="tab.filter"
                                    :noLikeThis="true"
                                    :type="tab.id"
                                    :fullUserTagList="fullUserTagList"
                                    @updateUserTagList="getUserTagList"
                                    @infinite="state => search(false, state)">
                        </SearchList>
                        <template v-if="activeTab.result.total === 0">
                            <div class="noResult">
                                <img src="noResult.svg">
                                <div class="text">{{$t('placeholder.More_like_text_desc')}}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import SearchList from './SearchList.vue';
import _ from "lodash";
import uuid from "uuid";



export default {
    name: "MoreLikeText",
    components: {SearchList},
    props: {
        showMoreLikeText: {
            type: Boolean,
            default: false
        },
        userInfo:{
            type: Object
        },
        favoritesTab: {
            type: Object,
            default: {
                id: 'Favorites',
                uuid: uuid(),
                icon: 'fas fa-star',
                filter: {
                    offset: 0,
                    count: 40,
                    favorites: true,
                    query: '',
                    types: ['News', 'Science','ScienceOpen', 'Patents', 'Companies']
                },
                filterData: {
                    types: []
                },
                result: {
                    total: 0,
                    facets: [],
                    list: []
                }
            }
        }
    },
    data() {
        return {
            predicate: '',
            defaultActiveTab: 'News',
            prevActiveTab: 'News',
            searchFilter: {},
            docTypes: [],
            orderBy: 'relevance',
            orderByItems: [
                {label: 'Standard Relevance', value: 'relevance'},
                {label: 'Semantic Relevance', value: 'semantic'},
                {label: 'Document date', value: 'date'}
            ],
            tabs: [{
                id: 'News',
            }, {
                id: 'Science',
                availableForRole:'PRIVATE_SCIENCE_SEARCH'
            }, {
                id: 'ScienceOpen',
                availableForRole:'OPEN_SCIENCE_SEARCH'
            }, {
                id: 'Patents',
            }, {
                id: 'Companies',
                availableForRole:"COMPANIES_SEARCH",
            }].map(tab => {
                return Object.assign(tab, {
                    title: this.$t('searchMode.source.' + tab.id),
                    uuid: uuid(),
                    result: {
                        total: 0,
                        facets: [],
                        list: []
                    },
                    filter: {
                        offset: 0,
                        count: 40,
                        query: '',
                        types: [tab.id]
                    }
                });
            }).filter(it => {
                return (!it.availableForRole || this.userInfo.roles.includes('ADMIN') || this.userInfo.roles.includes(it.availableForRole))
            }),
            fullUserTagList: [
                {id: 1, color: 1, name: 'Favorites'},
                {id: 2, color: 2, name: 'Veni, vidi, vici'},
            ],
            assignedTagsList: [],
            favCounter: {
                userTags: {},
                inFavorites: {}
            },

        };
    },
    computed: {
        activeTab() {
            return this.tabs.find(tab => tab.id === this.defaultActiveTab);
        },
    },
    mounted() {
        this.getUserTagList();
        // console.log('uI:', this.userInfo);
    },
    methods: {
        search(refresh, state) {
            if (this.predicate) {
                if (refresh) {
                    // this.refreshTabs();
                    this._countRequest = null;
                }
                let activeTab = this.activeTab;
                let filter = {
                    fullness: 'FULL',
                    offset: 0,
                    count: 40,
                    docType: activeTab.id,
                    text: this.predicate.replace(/“|”/g, '"')
                }

                console.log('search Filter:', filter);

                this.$services.documents.similar(filter).prepare().then(resp => {
                    if (state) {
                        if (resp.data.list.length) {
                            activeTab.result.list = activeTab.result.list.concat(resp.data.list);
                            activeTab.result = Object.assign({}, activeTab.result);
                            state.loaded();
                        } else {
                            state.complete();
                        }
                    } else {
                        activeTab.result = resp.data;
                        if(resp.data && resp.data.total === 0){
                            // this.defaultActiveTab = "News";
                        }
                    }
                }, error => {
                    if (state) state.complete();
                });
            }
        },
        getUserTagList() {
            this.$services.tags.list({
                count: 500,
                offset: 0,
                userIds: [this.userInfo.id]
            }).then(resp => {
                if (resp && resp.data) {
                    this.fullUserTagList = resp.data.list;
                    // this.refreshAssignTagsList();
                }
            });
        },
        closeMe(){
            this.activeTab.result = {
                total: 0,
                offset:0,
                list: []
            };
            this.predicate = '';
            this.$emit('closeMoreLikeText');
        },
        handleSelectTab(key) {
            this.defaultActiveTab = key;
            this.prevActiveTab = this.defaultActiveTab;
            let activeTab = this.activeTab;
            this.search(null, null, null);
        },
        handleToggleFavorites(doc) {
            let docTab = this.tabs.find(tab => tab.id === doc.type);
            let incrementToggle = (val) => {
                this.favoritesTab.filterData.types.forEach(item => {
                    if (item.type === doc.type) {
                        if (val) {
                            item.inFavorites = parseInt(item.inFavorites) + 1;
                        } else {
                            item.inFavorites = parseInt(item.inFavorites) - 1;
                        }
                    }
                });
            };
            docTab.result.list.forEach(item => {
                if (item.id === doc.id) {
                    item.isInFavorite = doc.isInFavorite;
                }
            });

            if (doc.isInFavorite) {
                incrementToggle(true);
                this.favoritesTab.result.total++;
            } else {
                incrementToggle(false);
                this.favoritesTab.result.total--;
            }

            this.favoritesTab.filterData = _.cloneDeep(this.favoritesTab.filterData);
            docTab.result = _.cloneDeep(docTab.result);
        },

    }
};
</script>

<style lang="scss">
@import "../../assets/variables.scss";

.fst-MoreLikeThis {
    .drawer-container{
        .body{
            max-height: none;
            overflow-y: hidden;
            > .tab-content{
                max-height: calc(100vh - 245px);
            }
        }
    }
    .tab-nav {
        .el-menu {
            background: transparent;
            //min-width: 500px;
        }

        .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
        .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
            background: transparent;
        }
    }

    .tab-content {
        width: 100%;
    }

    .predicate-block {
        display: flex;
        margin-bottom: 0;


        .predicate {
            textarea {
                height: 70px;
                border-radius: 5px 0 0 5px;
            }
        }

        .el-button {
            border-radius: 0 5px 5px 0;
            border: none;
            background: $iq-color-blue;
            color: #ffffff;
            min-height: 50px;
            width: 70px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            line-height: 1;
        }
    }

    .fst-SearchList {
        .list-item-container {
            width: auto;
            padding-top: 20px;
        }
    }

    .fst-SearchList {
        .noResult {
            display: none;
        }
    }

}
</style>

<style lang="scss" scoped>
</style>
