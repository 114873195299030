<template>
    <section class="fst-DataCoverageSettings" v-loading="loading">
        <table class="settingsTab">
            <tr v-for="(item, key) in list" :key="key">
                <td>{{sourceTabs[key].title}}</td>
                <td>
                    <el-date-picker
                            v-model="item.lastUpdate"
                            type="date"
                            format="dd.MM.yyyy"
                            value-format="yyyy-MM-dd"
                            @change="handleChangeDate(item)"
                            placeholder="Pick a day">
                    </el-date-picker>
                </td>
            </tr>
        </table>
        <div class="btns">
            <el-button type="primary" plain @click="getSettings()">
                <i class="far fa-sync-alt"></i>
            </el-button>
        </div>

    </section>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "DataCoverageSettings",
        computed: {
            ...mapState({
                sourceTabs: state => state.sourceTabs,
            }),
        },
        data() {
            return {
                loading: true,
                list: {
                    "news": {
                        "lastUpdate": ""
                    },
                    "science": {
                        "lastUpdate": ""
                    },
                    "scienceOpen": {
                        "lastUpdate": ""
                    },
                    "scienceCrossref": {
                        "lastUpdate": ""
                    },
                    "patents": {
                        "lastUpdate": ""
                    },
                    "investment": {
                        "lastUpdate": ""
                    }
                },
            }
        },
        mounted() {
            this.getSettings();
        },
        methods: {
            getSettings() {
                this.loading = true;
                this.$services.coverage.settings().then(resp => {
                    this.list = resp.data || this.list;
                    setTimeout(() =>{
                        this.loading = false;
                    },500)
                });
            },
            handleChangeDate(){
                this.loading = true;
                this.$services.coverage.saveSettings(this.list).then(resp => {
                    setTimeout(() =>{
                        this.loading = false;
                        console.log('Saved: ', resp.data);
                    },500)
                });

            }
        }
    }
</script>

<style lang="scss">
    .fst-DataCoverageSettings {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        .settingsTab {
            width: 480px;

            td:last-child {
                text-align: right;
            }
        }
    }
</style>