<template>
    <div class="fst-ClusterMap__ dark-theme"
         v-loading="loading"
         element-loading-background="rgba(19, 19, 19, .7)"
         :class="[{fullScreen: isFullScreen},{noLabels: !showLabels}]">
        <Header v-show="!isFullScreen"
                mode="clusterMap">
            <template slot="predicate">
                <div class="top-control">
                    <div class="colorSchemeBlock">
                        <label class="mr-2">Coloring by: </label>
                        <el-radio-group v-model="colorScheme" @change="toggleColorScheme()">
                            <el-radio-button label="byCluster">Cluster ID
                            </el-radio-button>
                            <el-radio-button label="byGrowthLastYear"
                                             :disabled="noYearCount">Growth last year
                            </el-radio-button>
                            <el-radio-button label="byGrowthAvg"
                                             :disabled="noYearCount">Growth Avg</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="source">
                        <label>Source: </label>
                        <el-select v-model="dataSource"
                                   popper-class="darkSelect"
                                   @change="getData()">
                            <el-option v-for="(item,key) in sources"
                                       :key="key"
                                       :value="key"
                                       :label="item.title"></el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-switch v-model="showNoCluster"
                                   :width="25"
                                   inactive-color="#46484D"
                                   active-color="#fff"
                                   class="mr-3"
                                   @change="handleShowNoClusters"
                                   active-text='"No clusters"'></el-switch>

                        <el-switch v-model="showBlackList"
                                   v-show="blackList.length"
                                   :width="25"
                                   inactive-color="#46484D"
                                   active-color="#fff"
                                   class="mr-3"
                                   @change="handleShowBlackList"
                                   active-text='BlackListed'></el-switch>

                        <el-switch v-model="showLabels"
                                   @change="toggleShowLabels"
                                   :width="25"
                                   inactive-color="#46484D"
                                   active-color="#fff"
                                   active-text="Labels"></el-switch>
                    </div>
                </div>
            </template>
        </Header>
        <div class="chart-container">
            <div class="control" v-show="clusterData.length > 0">
                <el-checkbox v-model="selectAll" @change="handleToggleAll">All</el-checkbox>
                <div class="cluster-checks">
                    <label :class="{checked: item.selected}"
                           v-for="item in clusters" :key="`cluster-${item.id}`"
                           @click="handleCheckCluster(item)">
                        <span class="marker" :style="`background:${item.color}`"></span>
                        <span class="form-check-label">{{item.name}} ({{item.id}})</span>
                    </label>
                </div>
            </div>
            <div id="clusterMapChart"></div>
            <div class="control right" data-role="cluster-props" v-show="selectedCluster.show">
                <span class="close" @click="selectedCluster.show=false"><i class="far fa-times"></i></span>
                <div class="title">{{selectedCluster.name}}</div>
                <div class="subtitle">Documents: {{selectedCluster.docs}}</div>
                <div v-show="!isByCluster" class="subtitle">
                    Growth
                    <template v-if="colorScheme === 'byGrowthLastYear'">last year:</template>
                    <template v-else>avg:</template>

                    {{selectedCluster.growthLastYear}}%
                </div>
                <div class="keywords">
                    <div v-for="(item, key) in selectedCluster.keywords"
                         class="kw"
                         :key="`tooltip-${key}`">
                        {{ item }}
                    </div>
                </div>
                <div class="yearCounts" v-if="selectedCluster.yearCounts.length">
                    <div class="subtitle">Number of documents by year</div>
                    <div class="mini-chart mt-10">
                        <div class="vals">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                {{item.value}}
                            </div>
                        </div>
                        <div class="histogram">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                <div :style="`height: ${item.chartHeight}px`"></div>
                            </div>

                        </div>
                        <div class="xaxis">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                {{item.year}}
                            </div>
                        </div>

                    </div>

                </div>
                <div class="settings"></div>
                <!--div>
                    <button class="btn btn-outline-primary" data-role="showModal">Import Data</button>
                </div-->
            </div>
            <div class="colorLegend" v-show="!isByCluster" :class="{'trans-0': loading}">
                <div class="gradient">
                    <div class="item" v-for="(item, key) in legend.values" :key="key">
                        <!--                        <div class="value">{{item}}%</div>-->
                        <div class="marker" :style="`background: ${legend.palette[key]}`"></div>
                    </div>
                </div>
                <div class="growth-range">
                    <el-slider v-model="growthRange.range"
                               @change="plotChart()"
                               :marks="growthRange.marks"
                               :min="growthRange.min"
                               :max="growthRange.max"
                               range></el-slider>
                </div>

            </div>
        </div>
        <div class="mapTooltip" ref="mapTooltip" v-show="tooltip.show">
            <div class="title">{{tooltip.title}}</div>
            <div class="docs params">{{tooltip.docs}}</div>
            <div class="params" v-show="!isByCluster">
                <template v-if="colorScheme === 'byGrowthAvg'">Growth avg.:</template>
                <template v-else>Growth last year:</template>
                {{tooltip.growthLastYear}}%
            </div>
            <div class="keywords params">
                <ul>
                    <li v-for="(item, key) in tooltip.keywords" :key="`tooltip-${key}`">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <!--            <div class="params">{{tooltip.color}}</div>-->
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {USER_ROLES} from '@/models/Users';
    import Header from '../components/Header';

    export default {
        name: 'ClusterMap',
        components: {Header},
        data() {
            return {
                srcPath: "https://watsmark.ru/projects/data/",
                srcVersion: "6",
                dataSource: 0,
                noYearCount: false,
                sources: [],
                firedData: {},
                ctrlDown: false,
                tooltip: {
                    show: false,
                    title: '',
                    docs: "0",
                    keywords: [],
                },
                selectedCluster: {
                    show: false,
                    name: '',
                    docs: "0",
                    yearCounts: [],
                    keywords: []
                },
                colorScheme: 'byCluster',
                legend: {
                    min: 0,
                    max: 3,
                    values: [],
                    palette: [
                        // "hsl(150, 100%, 40%)",
                        // "hsl(137, 38%, 42%)",
                        // "hsl(103, 10%, 35%)",
                        // "hsl(50, 0%, 27%)",
                        // "hsl(1, 55%, 33%)",
                        // "hsl(1, 80%, 40%)",
                        // "hsl(1, 100%, 52%)",
                        "#008A45",
                        "#3AAE5B",
                        "#8EDF6F",
                        "#FBEEaF",
                        "#FAB347",
                        "#FC4E2B",
                        "#AF0300",

                    ]
                },
                growthRange: {
                    min: -50,
                    max: 150,
                    marks: {
                        0: '0'
                    },
                    range: [-50, 150]
                },
                quadtree: {},
                curYear: new Date().getFullYear(),
                loading: true,
                moTimer: 0,
                chartProps: {},
                isFullScreen: false,
                selectAll: true,
                showLabels: true,
                showNoCluster: false,
                showBlackList: false,
                blackList: [],
                data: [],
                initialData: [],
                noNoClustersData: [],
                blackListedData: [],
                clusterData: [],
                labelsData: [],
                clusters: {},
                noClusterColor: "#555",
                fadeClusterColor: "#000",
                curPointCoords: {},
                contextMenu: []
            };
        },
        mounted() {
            let promises = [], self = this,
                dataListUrl = self.srcPath + "clusterDataList.json?v=" + Date.now();
            this.$loadScript("d3/d3.min.js").then(() => {
                promises.push(self.$loadScript("d3/d3fc.js"));
                promises.push(self.$loadScript("d3/d3-context-menu.js"));
                Promise.all(promises).then(() => {
                    console.log('Loaded!');
                    fetch(dataListUrl).then(response => response.json()).then(json => {
                        self.sources = json.sources;
                        self.getData();
                        self.createMap();
                    });
                })
            });
        },
        computed: {
            user() {
                return this.$store.state.userInfo;
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
            isByCluster() {
                return this.colorScheme === 'byCluster';
            }
        },
        methods: {
            createMap() {
                let self = this;
                self.chartProps.xScale = d3.scaleLinear().domain([-10, 30]);
                self.chartProps.yScale = d3.scaleLinear().domain([0, 50]);
                self.chartProps.xScaleOriginal = self.chartProps.xScale.copy();
                self.chartProps.yScaleOriginal = self.chartProps.yScale.copy();

                const textLabel = fc
                    .layoutTextLabel()
                    .padding(1)
                    .value(d => d.label);

                const strategy = fc.layoutRemoveOverlaps(fc.layoutGreedy());
                const labels = fc
                    .layoutLabel(strategy)
                    .size((d, i, g) => {
                        const textSize = g[i].getElementsByTagName('text')[0].getBBox();
                        return [textSize.width, textSize.height];
                    })
                    .position(d => {
                        return [d.x, d.y];
                    })
                    .component(textLabel);

                self.chartProps.pointSeries = fc
                    .seriesWebglPoint()
                    .equals((a, b) => a === b)
                    .size(1)
                    .crossValue(d => d.x)
                    .mainValue(d => d.y);

                let zoom = d3
                    .zoom()
                    .scaleExtent([0.8, 40])
                    .on("zoom", () => {
                        self.chartProps.xScale.domain(d3.event.transform.rescaleX(self.chartProps.xScaleOriginal).domain());
                        self.chartProps.yScale.domain(d3.event.transform.rescaleY(self.chartProps.yScaleOriginal).domain());
                        self.redraw();
                    });
                self.contextMenu = [
                    {
                        title: 'Deselect All',
                        disabled: false,
                        action: function (elm, d, i) {
                            self.selectAll = !self.selectAll;
                            self.handleToggleAll()
                        }
                    },
                    {
                        title: 'Hide labels',
                        disabled: false,
                        action: function (elm, d, i) {
                            self.showLabels = !self.showLabels;
                            self.toggleShowLabels()
                        }
                    },

                    {
                        title: 'Full screen ON',
                        disabled: false,
                        action: function () {
                            self.toggleFullScreen();
                        }
                    },
                    /*
                                        {
                                            divider: true
                                        },
                                        {
                                            title: 'Cluster settings',
                                            action: function (elm, d, i) {
                                                // clusterSettings();
                                            }
                                        }
                      */
                ];

                // ------------- EVENTS -----------------
                let labelClick = false;
                let $chart = document.querySelector('#clusterMapChart');
                $chart.addEventListener('mouseover', function (e) {
                    let isText = e.target && e.target.tagName === 'text',
                        isCircle = e.target.tagName === 'circle' && !self.showLabels;
                    if (isText || isCircle) {
                        let _id = isCircle ? self.getIdFromLabel(e.target.parentNode.querySelector('text').innerHTML)
                            : self.getIdFromLabel(e.target.innerHTML);
                        self.moTimeout = setTimeout(() => {
                            self.showTooltip({
                                id: _id,
                                coords: {
                                    x: e.clientX,
                                    y: e.clientY
                                }
                            })
                        }, 777)
                    }
                })
                $chart.addEventListener('mouseout', function (e) {
                    let isText = e.target && e.target.tagName === 'text',
                        isCircle = e.target.tagName === 'circle' && !self.showLabels;
                    if (isText || isCircle) {
                        if (self.moTimeout) {
                            clearTimeout(self.moTimeout);
                        }
                        setTimeout(() => {
                            self.tooltip.show = false;
                        }, 300)

                    }
                });
                $chart.addEventListener('click', function (e) {
                    if (e.target && e.target.tagName === 'text') {
                        let _id = self.getIdFromLabel(e.target.innerHTML);
                        self.selectCluster(_id);
                        labelClick = true;
                    }
                });

                d3.select("#clusterMapChart").on("click", function () {
                    if (self.curPointCoords && !labelClick) {
                        // $checks.removeClass('checked');
                        // $main.find('g.label').addClass('faded');
                        self.selectCluster(self.curPointCoords.cluster);
                    }
                    labelClick = false;
                })

                document.addEventListener('keydown', function (e) {
                    if (!self.ctrlDown && e.key === 'Control') {
                        self.ctrlDown = e.ctrlKey;
                    }
                })
                document.addEventListener('keyup', function (e) {
                    if (self.ctrlDown && e.key === 'Control') {
                        self.ctrlDown = e.ctrlKey;
                    }
                })

                const pointer = fc.pointer().on("point", ([coord]) => {

                    if (!coord || !self.quadtree) {
                        return;
                    }

                    const x = self.chartProps.xScale.invert(coord.x);
                    const y = self.chartProps.yScale.invert(coord.y);
                    const radius = Math.abs(self.chartProps.xScale.invert(coord.x) - self.chartProps.xScale.invert(coord.x - 20));
                    const closestDatum = self.quadtree.find(x, y, radius);

                    // if the closest point is within 20 pixels, show the annotation
                    if (closestDatum) {
                        self.curPointCoords = _.clone(closestDatum);
                        // annotations[0] = createAnnotationData(closestDatum);
                    } else {
                        self.curPointCoords = {};
                    }

                    self.redraw();
                });

                self.chartProps.chart = fc
                    .chartCartesian(self.chartProps.xScale, self.chartProps.yScale)
                    .webglPlotArea(
                        fc
                            .seriesWebglMulti()
                            .series([self.chartProps.pointSeries])
                            .mapping(d => d.data)
                    )
                    .svgPlotArea(
                        fc
                            .seriesSvgMulti()
                            .series([labels])
                            .mapping((data, index, series) => {
                                switch (series[index]) {
                                    case labels:
                                        return self.labelsData;
                                    default:
                                        return self.labelsData;
                                }
                            })
                    )

                    .decorate(sel =>
                        sel
                            .enter()
                            .select("d3fc-svg.plot-area")
                            .on("measure.range", () => {
                                self.chartProps.xScaleOriginal.range([0, d3.event.detail.width]);
                                self.chartProps.yScaleOriginal.range([d3.event.detail.height, 0]);
                            })
                            .on('contextmenu', d3.contextMenu(self.contextMenu))
                            .call(zoom)
                            .call(pointer)
                    );

            },
            getData() {
                let self = this,
                    url = self.srcPath + self.sources[self.dataSource].name + "?v=" + self.srcVersion;
                self.loading = true;
                self.selectedCluster.show = false;
                fetch(url).then(response => response.json()).then(json => {
                    self.data = json.points.map((it, i) => {
                        return {
                            cluster: it[2],
                            id: i,
                            x: it[0],
                            y: it[1]
                        }
                    });
                    self.clusterData = json.clusters;
                    self.blackList = json.blackList || [];
                    self.initialData = _.cloneDeep(self.data);
                    self.data = self.noNoClustersData = self.data.filter(it => it.cluster >= 0);
                    self.data = self.blackListedData = self.data.filter(it => !self.blackList.includes(it.cluster));

                    if (!self.clusterData.some(it => it.yearCounts)) {
                        self.noYearCount = true;
                        self.colorScheme = 'byCluster';
                    } else {
                        self.noYearCount = false;
                    }

                    self.prepareData(self.data);
                });
            },
            getClusters(data) {
                let self = this,
                    _cls = _.sortBy(_.uniq(_.map(data, 'cluster'))),
                    numDocs = {},
                    growLY = [],
                    clusterCenter = {},
                    cStep = [],
                    _out = {};
                let isByCluster = self.colorScheme === 'byCluster',
                    isAvg = self.colorScheme === 'byGrowthAvg',
                    min, max;
                self.labelsData = []
                if (!isByCluster) {
                    _.forEach(_cls, it => {
                        let cl = self.clusterData.find(cl => cl.id === it);
                        if (cl) {
                            cl.yearCounts = cl.yearCounts || [];
                            for (let ii = self.curYear - 4; ii <= self.curYear; ii++) {
                                if (!cl.yearCounts[ii]) {
                                    cl.yearCounts[ii] = 0
                                }
                            }
                            let denominator = cl && cl.yearCounts ?
                                (isAvg ? (cl.yearCounts[2020] || 1)
                                    : (((cl.yearCounts[2020] || 0) + (cl.yearCounts[2019] || 0) + (cl.yearCounts[2018] || 0)) / 3))
                                : 1;

                            growLY[it] = (cl && cl.yearCounts ? (1.5 * (cl.yearCounts[self.curYear] || 0) / denominator - 1) * 100 : 0);
                        }
                    });
                    max = self.growthRange.max = self.growthRange.range[1] = Math.ceil(_.max(growLY));
                    min = self.growthRange.min = self.growthRange.range[0] = Math.floor(_.min(growLY));
                    self.growthRange.marks = {};
                    self.growthRange.marks[max] = max + '%';
                    self.growthRange.marks[0] = "0";
                    self.growthRange.marks[min] = min + '%';
                    cStep = self.createGradArr(min, max);
                    // console.log('gradArr:', cStep);

                }
                _.forEach(_cls, it => {
                    let thisClusterData = data.filter(cl => cl.cluster === it) || [],
                        gradient = '',
                        clusterInfo = self.clusterData.find(cl => cl.id === it) || {};
                    if (!isByCluster) {
                        let _value = growLY[it],
                            _lightness = 1 - (Math.abs(1 - _value));
                        if (_value > cStep[1]) {
                            gradient = self.legend.palette[0]
                        } else if (_value > cStep[2]) {
                            gradient = self.legend.palette[1]
                        } else if (_value > cStep[3]) {
                            gradient = self.legend.palette[2]
                        } else if (_value > cStep[5]) {
                            gradient = self.legend.palette[3]
                        } else if (_value > cStep[6]) {
                            gradient = self.legend.palette[4]
                        } else if (_value > cStep[7]) {
                            gradient = self.legend.palette[5]
                        } else {
                            gradient = self.legend.palette[6]
                        }
                        // gradient = clusterInfo.yearCounts ? 'hsla(' + (((_value - min) / (max - min)) * 130 + 7) + ', 68%, '
                        //     + ((_lightness < 0.38 ? 0.38 : _lightness) * 85) + '%, 1)' : self.noClusterColor;
                        // gradient = 'hsla(' + ((_value- min) / (max - min)) * 120 + ', 70%, 45%, 1)';
                        // gradient = 'hsla(120, 70%, ' + (Math.pow(_value/ max, 2)  + .1) * 75 + '%, 1)';
                        // gradient = 'hsla(70,' + (Math.pow(_value/ max, 2)  + .1) * 90 + '%, 40%, 1)';
                        // gradient = _value>= 1 ? 'hsla(70,' + (Math.pow(_value/ max, 2)  + .1) * 90 + '%, 40%, 1)'
                        //     : 'hsla(1,' + (Math.pow(min / _value + .05,3)) * 90 + '%, 50%, 1)';
                        // gradient = _value>= 1 ? 'hsla(100,' + (_value/ max + .05) * 90 + '%, ' + (_value/ max  + .01) * 60 + '%, 1)'
                        //     : 'hsla(1,' + (_value / max + .05) * 90 + '%, ' + (_value/ max + .01) * 60 + '%, 1)';
                    }
                    let xs = _.map(thisClusterData, 'x'),
                        ys = _.map(thisClusterData, 'y');
                    let _color = isByCluster ? self.getColor(it) : gradient,
                        // _color = self.getColor(it),
                        _keywords = clusterInfo && clusterInfo.keywords ? clusterInfo.keywords.map(it => it.value) : [],
                        _name = clusterInfo && clusterInfo.keywords && clusterInfo.keywords.length ? clusterInfo.keywords[0].value : 'No cluster';
                    numDocs[it] = thisClusterData.length;
                    // clusterCenter[it] = [_.mean(xs), _.mean(ys)];
                    clusterCenter[it] = [self.$utils.median(xs), self.$utils.median(ys)];
                    if (clusterCenter[it] && it >= 0) {
                        self.labelsData.push({
                            "x": clusterCenter[it][0],
                            "y": clusterCenter[it][1],
                            // "label": it + '.' + _.upperFirst(_name)
                            "label": _.upperFirst(_name) + ' (' + it + ')'
                            // "label": 'Cluster #' + it + ' ' || 'No cluster '
                        });
                    }

                    _out[it] = {
                        color: it >= 0 ? _color : self.noClusterColor,
                        name: _.upperFirst(_name),
                        title: 'Cluster ' + it,
                        numDocs: self.$utils.toFin(numDocs[it]),
                        clusterCenter: clusterCenter[it],
                        growthLastYear: growLY[it],
                        yearCounts: clusterInfo.yearCounts ? clusterInfo.yearCounts : [],
                        selected: true,
                        keywords: _keywords,
                        desc: '',
                        id: it,
                    }
                });
                // console.log('clusters: ', _out);
                if (!self.isByCluster) {
                    _out = _.orderBy(_out, 'growthLastYear', 'desc');
                }
                return _out;
            },
            getCheckedClusters() {
                let self = this,
                    $unchecked = _.filter(self.clusters, it => !it.selected) || [],
                    $checked = _.filter(self.clusters, it => it.selected) || [];


                let isCheckedAll = self.selectAll = $unchecked.length === 0,
                    isUnCheckedAll = $checked.length === 0;

                // self.contextMenu[0].disabled = isCheckedAll;
                // contextMenu[3].disabled = isUnCheckedAll;
                _.forEach(self.clusters, it => {
                    it.selected = isCheckedAll ? true
                        : isUnCheckedAll ? false
                            : it.selected
                });
                let range = self.growthRange;
                $checked = $checked.filter(it => {
                    return self.isByCluster
                        || (!_.isUndefined(it.growthLastYear) && it.growthLastYear >= range.range[0]
                            && it.growthLastYear <= range.range[1]);
                });

                let selectedClusters = _.map($checked, 'id');

                return selectedClusters;
            },
            selectCluster(id = null) {
                let self = this,
                    isId = !!(id || id == 0),
                    _cluster = _.find(self.clusters, it => it.id == (isId ? id : self.curPointCoords.cluster)),
                    _id = _cluster ? _cluster.id : null;
                // clusterInfo = self.clusterData.find(it => it.id === _id);
                self.tooltip.show = false;
                if (_cluster) {
                    if (!self.ctrlDown) {
                        _.forEach(self.clusters, it => {
                            it.selected = false;
                        });
                        _cluster.selected = true;
                    } else {
                        _cluster.selected = !_cluster.selected;
                    }
                } else {
                    _.forEach(self.clusters, it => {
                        it.selected = true;
                    });
                }

                // if ((clusterInfo && from !== 'control') || (clusterInfo && $($thisCheck[0]).hasClass('checked'))) {
                self.selectedCluster.name = '';
                self.selectedCluster.growthLastYear = '';
                self.selectedCluster.docs = '';
                self.selectedCluster.keywords = [];
                self.selectedCluster.yearCounts = [];

                if (_cluster && _cluster.selected) {
                    self.selectedCluster.show = true;
                    let _keywords = _cluster.keywords;
                    let ycData = _.map((_cluster.yearCounts || []), (it, i) => {
                        return {
                            year: i,
                            value: it
                        };
                    }).slice(-4);
                    let _max = _.max(_.map(ycData, 'value'));
                    if (ycData && ycData.length) {
                        ycData[ycData.length - 1].value = Math.floor(1.5 * ycData[ycData.length - 1].value);
                    }

                    self.selectedCluster.name = _cluster.title;
                    self.selectedCluster.docs = _cluster.numDocs || 0;
                    self.selectedCluster.growthLastYear = self.$utils.roundX(_cluster.growthLastYear, 1);
                    self.selectedCluster.keywords = _keywords;
                    self.selectedCluster.yearCounts = ycData.map(it => {
                        it.chartHeight = it.value / _max * 60 + 2;
                        it.value = self.$utils.toFin(it.value);
                        return it;
                    });
                } else if (_id < 0) {
                    self.selectedCluster.show = true;
                    self.selectedCluster.name = 'No cluster';
                    self.selectedCluster.docs = (_cluster || {}).numDocs || 0;
                    self.selectedCluster.growthLastYear = _cluster ? self.$utils.roundX(_cluster.growthLastYear, 1) : 0;
                } else {
                    self.selectedCluster.show = false;
                }
                setTimeout(() => {
                    self.contextMenu[0].title = self.selectAll ? 'Deselect All' : 'Select All';
                }, 150);
                self.plotChart()
            },
            handleCheckCluster(item) {
                // item.selected = !item.selected;
                this.selectCluster(item.id);
            },
            handleToggleAll() {
                let self = this;
                _.forEach(self.clusters, it => {
                    it.selected = self.selectAll;
                });
                this.contextMenu[0].title = self.selectAll ? 'Deselect All' : 'Select All';
                self.plotChart();
            },
            handleShowNoClusters() {
                this.loading = true;
                setTimeout(() => {
                    this.data = _.cloneDeep(this.showNoCluster ? this.initialData : this.noNoClustersData);
                    this.prepareData(this.data);
                }, 250)
            },
            handleShowBlackList() {
                let self = this;
                self.loading = true;
                setTimeout(() => {
                    self.data = _.cloneDeep(self.showBlackList ? self.noNoClustersData : self.blackListedData);
                    self.prepareData(self.data);
                }, 250)
            },
            toggleFullScreen() {
                this.isFullScreen = !this.isFullScreen;
                this.contextMenu[2].title = this.isFullScreen ? 'Full screen OFF' : 'Full screen ON';
                if (this.isFullScreen) {
                    // this.$utils.requestFullScreen();
                }
            },
            toggleShowLabels() {
                this.contextMenu[1].title = (this.showLabels ? 'Hide' : 'Show') + ' labels';
            },
            toggleColorScheme() {
                let self = this;
                self.loading = true;
                self.selectedCluster.show = false;
                setTimeout(() => {
                    self.prepareData(self.data)
                }, 250)
            },
            createGradArr(min, max) {
                let self = this,
                    out = {},
                    zeroFrom = -Math.abs(min / 10),
                    zeroTo = Math.abs(max / 10),
                    delta = zeroTo + Math.abs(zeroFrom),
                    stepMinus = (zeroFrom - min) / 3,
                    stepPlus = (max - zeroTo) / 3,
                    minusArr = [],
                    plusArr = [];
                setTimeout(() => {
                    let marks = Array.from(document.querySelectorAll('.colorLegend .marker')),
                        // zeroMark = marks.slice(3,4)[0],
                        plusMarks = marks.slice(0, 3),
                        minusMarks = marks.slice(-3);
                    // zeroMark.style.width = 30 * (zeroTo - zeroFrom) / (delta * 2) + 'px';
                    minusMarks.forEach(it => {
                        it.style.width = 35 * stepMinus / (delta * 2) + 1 + 'px';
                    });
                    plusMarks.forEach(it => {
                        it.style.width = 35 * stepPlus / (delta * 2) + 1 + 'px';
                    });
                }, 100)
                for (let ii = 1; ii < 4; ii++) {
                    let jj = ii - 4;
                    plusArr.push(self.$utils.roundX(zeroTo + stepPlus * ii, 0));
                    minusArr.push(self.$utils.roundX(zeroFrom + stepMinus * jj, 0));
                }
                out = [...minusArr, zeroFrom, zeroTo, ...plusArr].reverse();
                self.legend.values = [...minusArr, 0, ...plusArr].reverse();
                return out;
            },
            showTooltip(params) {
                let self = this,
                    cluster = _.find(self.clusters, it => it.id == params.id),
                    $mapTooltip = document.querySelector('.mapTooltip'),
                    chartHeight = document.querySelector('#clusterMapChart').offsetHeight,
                    chartWidth = document.querySelector('#clusterMapChart').offsetWidth,
                    offsetX = (chartWidth - params.coords.x < 250) ? -180 : 0,
                    offsetY = (chartHeight - params.coords.y < 150) ? -190 : 15;
                $mapTooltip.style.left = (params.coords.x + offsetX + 20) + 'px';
                $mapTooltip.style.top = (params.coords.y + offsetY) + 'px';
                // console.log(params.coords.y, chartWidth, chartWidth - params.coords.x);

                self.tooltip.title = 'Cluster ' + cluster.id;
                self.tooltip.docs = 'Documents: ' + cluster.numDocs;
                self.tooltip.keywords = cluster.keywords.slice(0, 5);
                self.tooltip.color = cluster.color;
                self.tooltip.growthLastYear = self.$utils.roundX(cluster.growthLastYear, 1);
                self.tooltip.show = true;
            },
            prepareData(data) {
                let self = this,
                    xs = _.map(data, 'x'),
                    ys = _.map(data, 'y'),
                    maxX = Math.ceil(_.max(xs) + 4),
                    minX = Math.ceil(_.min(xs) - 4),
                    maxY = Math.ceil(_.max(ys) + 1),
                    minY = Math.ceil(_.min(ys) - 1);
                self.loading = true;
                self.clusters = self.getClusters(data);
                self.chartProps.xScale.domain([minX, maxX]);
                self.chartProps.yScale.domain([minY, maxY]);
                self.chartProps.xScaleOriginal.domain([minX, maxX]);
                self.chartProps.yScaleOriginal.domain([minY, maxY]);
                self.plotChart();
            },
            plotChart() {
                let self = this,
                    $labels = document.querySelectorAll('g.label'),
                    selectedClusters = self.getCheckedClusters();
                const clusterFill = point =>
                    self.webglColor(selectedClusters.includes(point.cluster) ?
                        ((_.find(self.clusters, it => it.id == point.cluster) || {}).color || self.noClusterColor)
                        : self.fadeClusterColor);

                const fillColor = fc.webglFillColor().value(clusterFill).data(self.data);
                self.chartProps.pointSeries.decorate(program => fillColor(program));

                _.forEach($labels, label => {
                    let _id = self.getIdFromLabel(label.querySelector('text').innerHTML);
                    if (selectedClusters.includes(parseInt(_id))) {
                        label.classList.remove('faded');
                    } else {
                        label.classList.add('faded');
                    }
                })

                // create a spatial index for rapidly finding the closest datapoint
                self.quadtree = d3
                    .quadtree()
                    .x(d => d.x)
                    .y(d => d.y)
                    .addAll(self.data);
                self.redraw();
            },

            redraw() {
                let self = this,
                    data = self.data,
                    labelsData = self.labelsData;
                d3.select("#clusterMapChart").datum({data, labelsData}).call(self.chartProps.chart);
                setTimeout(() => {
                    self.loading = false;
                }, 125)

            },
            webglColor(color) {
                const {r, g, b, opacity} = d3.color(color).rgb();
                return [r / 255, g / 255, b / 255, opacity];
            },
            getIdFromLabel(context) {
                return ((context.split(' (') || [])[1] || '').replace(')', '');
            },
            fireEvent(data) {
                this.firedData = {incEvent: data};
            },
            getColor(id) {
                let colors =
                    [
                        "#A4CCE3",
                        "#5784BA",
                        "#81B1CC",
                        "#8DA47E",
                        "#A9C8C0",
                        "#AE8A8C",
                        "#e08050",
                        "#d020d0",
                        "#DCDBD9",
                        "#E9BBB5",
                        "#EEB8C5",
                        "#4382BB",
                        "#B2B2B2",
                        "#7C98AB",
                        "#D29F8C",
                        "#D0BCAC",
                        "#C1D5DE",
                        "#F7CE76",
                        "#9AC8EB",
                        "#DBD0c0",
                        "#F27348",
                        "#A57283",
                        "#BEB4C5",
                        "#8C7386",
                        "#77ee44",
                        "#C47482",
                        "#CA9C95",
                        "#E8D6CF",
                        "#2CCED2",
                        "#37667E",
                        "#AAD9CD",
                        "#CCD4BF",
                        "#C54B6C",
                        "#DDF2F4",
                        "#E7CBA9",
                        "#D5E4C3",
                        "#84A6D6",
                        "#9C9359",
                        "#874741",
                        "#698396",
                        "#E4CEE0",
                        "#B8E0F6",
                        "#A15D98",
                        "#80396E",
                        "#775ad0",
                        "#897C87",
                        "#DEC4D6",
                        "#76CDCD",
                        "#E6A57E",
                        "#DBBC8E",
                        "#F5F3E7",
                        "#E5B3BB",
                        "#FEC7BC",
                        "#F9CAD7",
                        "#DC828F",
                        "#C2D9E1",
                        "#D29F8C",
                        "#D9D3D2",
                        "#81B1CC",
                        "#FFD9CF",
                        "#46d02B",
                        "#76504E",
                        "#FBECDB",
                        "#F3CBBD",
                        "#90CDC3",
                        "#AF8C72",
                        "#938F43",
                        "#D3CCCA",
                        "#A37E7E",
                        "#86736C",
                    ]
                let _id = (id >= colors.length) ? id - colors.length : id;
                return colors[_id];
            },

        },
    }
</script>


<style src="@/assets/d3-context-menu.css"></style>

<style lang="scss">

    .fst-ClusterMap__ {
        .header-center {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .header-right {
            flex: 0;
        }

        .top-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            label {
                color: $iq-dark-theme-light;
                &.is-disabled{
                    opacity: .5;
                }
            }

            .source {
                display: flex;
                align-items: center;

                .el-input__inner {
                    width: 180px;
                }
            }
        }

        .chart-container {
            flex: 1;
            height: calc(100vh - 100px);
            position: relative;
        }

        #clusterMapChart {
            padding: 15px 10px 10px;
            background: $iq-dark-theme-bg-light-2;
            height: calc(100vh - 86px);

            d3fc-group {
                grid-template-columns: 1em auto 1fr 1em 0;
                grid-template-rows: 0 auto 1fr 1em 0;
            }

            &.grey {
                //background: #bbb;
            }
        }

        .d3-context-menu-theme {
            ul hr {
                margin: 3px 0;
            }
        }

        .control {
            background: rgba(0, 0, 0, 0.65);
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;
            color: $iq-dark-theme-light;
            padding: 10px 15px;
            left: 20px;
            top: 20px;
            max-height: calc(100vh - 120px);
            overflow-y: auto;
            position: absolute;
            width: 295px;
            z-index: 2000;

            .el-checkbox {
                color: #676767;
                font-size: 14px;

                .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: $iq-dark-theme-primary;
                }

                .el-checkbox__label {
                    padding-left: 6px;
                }
            }

            &.right {
                left: auto;
                right: 20px;
            }

            .close {
                float: right;
                font-size: 20px;
                line-height: 1;
                margin: -4px -4px 0 0px;
                padding: 3px;
                cursor: pointer;

                &:hover {
                    color: $iq-dark-theme-primary;
                }
            }

            .title {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .subtitle {
                font-weight: 600;
                margin-bottom: 7px;
            }

            .keywords {
                margin-top: 3px;

                .kw {
                    margin: 5px 8px 4px 0;
                    border: 1px solid #D5EDFA;
                    padding: 5px 10px;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 4px;
                    background: #D5EDFA;
                    font-weight: 500;
                    color: #111;
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }

            .yearCounts {
                margin-top: 13px;
                border-top: 1px solid $iq-dark-theme-color-border;
                padding-top: 6px;

                .subtitle {
                    margin-bottom: 7px;
                }

                > div {
                    margin: 5px 0;

                    span {
                        color: #ffffff;
                    }
                }
            }

        }

        .cluster-checks {
            > label {
                cursor: pointer;
                color: $iq-dark-theme-primary;
                display: flex;
                align-items: flex-start;
                margin: 7px 0;
                padding: 0;
                font-size: 14px;

                .form-check-label {
                    opacity: .45;
                }

                &.checked {
                    .form-check-label {
                        opacity: 1;
                    }
                }

                .form-check-label {
                    //white-space: nowrap;
                }

                .marker {
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    margin: 2px 7px 0 0;
                    flex-shrink: 0;
                }
            }
        }


        g.label {
            fill: rgba(38, 38, 38, .25);
            cursor: pointer;
            //stroke: rgba(0,0,0,0.003);
            //stroke-width: 1px;

            circle {
                r: 5;
                cursor: pointer;
                fill: rgba(255, 255, 255, .85);
                stroke: rgba(0, 0, 0, .95);
            }

            text {
                fill: #fff;
                font-size: 14px;
                font-weight: 500;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                stroke: none;
            }

            &.faded {
                fill: transparent;

                text {
                    opacity: 0.38;
                    font-weight: 300;
                }
            }
        }

        &.noLabels {
            g.label {
                fill: none;

                circle {
                    r: 5;
                    fill: #fff;
                }

                text {
                    display: none;
                }

                &.faded {
                    circle {
                        fill: #fff;
                        opacity: .35;
                    }

                    & + g.label:not(.faded) {
                        text {
                            display: block;
                        }
                    }
                }
            }
        }

        .x-axis, .y-axis {
            opacity: 0;
        }

        .mapTooltip {
            position: absolute;
            width: 275px;
            background: $iq-dark-theme-bg-light-1;
            color: $iq-dark-theme-primary;
            padding: 7px 10px;
            border: 1px solid $iq-dark-theme-color-border;
            border-radius: 5px;
            z-index: 2100;
            line-height: 1.4;
            top: 95px;
            right: 15px;

            .title {
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 3px;
            }

            .params {
                font-size: 13px;
                line-height: 1.5;
                margin-bottom: 4px;
            }

            &.visible {
                display: block;
            }

            ul {
                padding-left: 5px;
                margin: 7px 0;

                li {
                    list-style: none;
                    line-height: 1.2;
                    margin: 7px 0 7px 7px;
                    display: flex;
                    text-transform: capitalize;

                    &:before {
                        content: "\2013";
                        text-indent: -5px;
                        margin-right: 5px;
                    }
                }
            }
        }

        &.fullScreen {
            .control {
                display: none;
            }

            #clusterMapChart {
                height: 100vh;
            }

            &.noLabels {
                g.label {
                    circle {
                        display: none;
                    }
                }
            }
        }

        .mini-chart {
            > div {
                display: flex;
                font-size: 12px;

                > div {
                    flex: 1;
                    text-align: center;
                }
            }

            .vals {
                margin-bottom: 7px;
                color: #fff;
            }

            .xaxis {
                border-top: 1px solid $iq-dark-theme-color-border;
                padding-top: 3px;
                margin-top: 1px;
            }

            .histogram {
                align-items: flex-end;

                > div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    > div {
                        width: 15px;
                        background: #d5edfa;
                    }
                }
            }
        }

        .colorLegend {
            position: absolute;
            top: 20px;
            left: 329px;
            background: rgba(0, 0, 0, 0.8);
            padding: 10px 17px 0;
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;

            .gradient {
                display: flex;
                align-items: flex-end;
                flex-direction: row-reverse;
            }

            .item {
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                line-height: 1;
                align-items: center;
                flex-direction: column;
            }

            .value {
                margin-bottom: 3px;
            }

            .marker {
                width: 35px;
                height: 14px;
            }
        }

        .growth-range {
            width: calc(100% - 0);
            margin: -5px auto 5px;

            label {
                position: relative;
                top: 10px;
            }
        }
    }

    @media only screen and (max-width: 1650px) {
        .fst-ClusterMap {
            .colorSchemeBlock {
            }
        }
    }

</style>
