<template>
    <div v-html="message"></div>
</template>

<script>
    export default {
        name: 'IqpDialogMessage',
        props: {
            message: String
        }
    }
</script>

<style scoped>

</style>
