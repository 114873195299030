import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import store from './../store';
import Const from './const';
import i18n from './i18n';
import { Loading, MessageBox } from 'element-ui';

function authDialog() {
    MessageBox.alert(i18n.t('auth.errorMessage'), i18n.t('auth.errorTitle'), {
        type: 'error',
        dangerouslyUseHTMLString: true
    }).finally(() => {
        window.open('logout', '_self');
    });
}

function ajax(params) {
    const cancel = axios.CancelToken.source();

    const req = axios.request(Object.assign(params, {
        cancelToken: cancel.token
    }));

    req.iqpRequestInfo = params._requestInfo || {};

    req.cancel = function (message) {
        cancel.cancel(message);
    };

    req.prepare = function(args) {
        args || (args = {});
        let target = args.target || args.element;
        let error = args.error;
        if(_.isString(target)) {
            target = document.querySelector(target);
        } else if(target && !_.isElement(target) && target.jquery) {
            target = target[0];
        }
        if(!_.isElement(target)) {
            target = null;
        }
        if(error !== false) {
            if(!_.isFunction(error)) {
                error = err => {
                    const resp = err.response || {}, data = resp.data || {};
                    console.error('Service error', err, 'Response:', resp, 'Response data:', data);
                    MessageBox.alert((data.message || err.message || '').replace('\n', '<br/>'), i18n.t('error'), {type: 'error', dangerouslyUseHTMLString: true, customClass:'lg'});
                    return err;
                };
            }
        } else {
            error = _.identity;
        }
        const loader = Loading.service(_.extend({target: target}, args.loader));
        const close = () => {
            try {
                loader.close();
            } catch (e) {
                console.info(e);
            }
        };
        const promise = new Promise(function (resolve, reject) {
            req.then(resp => {
                try {
                    resp.iqpRequestInfo = req.iqpRequestInfo;
                    resolve(resp);
                } finally {
                    close();
                }
            }, err => {
                try {
                    if(err.response && err.response.status === 403) {
                        reject(err);
                        authDialog();
                    } else {
                        reject(error(err));
                    }
                } finally {
                    close();
                }
            });
        });
        promise.iqpRequestInfo = req.iqpRequestInfo;
        promise.cancel = req.cancel;
        return promise;
    };

    return req;
}

const REF = '$ref-', REF_KEY = '@id',
    FORMAT_DATETIME = Const.format.moment.datetime, FORMAT_DATE = Const.format.moment.date;
export const jsonApiPostProcessing = (function () {
    function walk(jObj, idsToObjs) {
        let i, keys = jObj ? Object.keys(jObj) : [];
        for (i = 0; i < keys.length; i++) {
            let key = keys[i], value = jObj[key];
            if (key === REF_KEY) {
                idsToObjs[value] = jObj;
                delete jObj[REF_KEY];
            } else if (typeof(value) === 'object') {
                walk(value, idsToObjs);
            }
        }
    }

    function substitute(jObj, idsToObjs) {
        let i, keys = jObj ? Object.keys(jObj) : [];
        for (i = 0; i < keys.length; i++) {
            let key = keys[i], value = jObj[key], type = typeof(value);
            if (type === 'string' && value.indexOf(REF) === 0) {
                jObj[key] = idsToObjs[value];
            } else if (type === "object") {
                substitute(value, idsToObjs);
            }
        }
    }

    return function jsonApiPostProcessing(data) {
        if (typeof(data) === 'object') {
            let idsToObjs = {};
            walk(data, idsToObjs);
            substitute(data, idsToObjs);
        }
        return data;
    }
})();

export const jsonApiPreProcessing = (function () {
    return function jsonApiPreProcessing(data) {
        if(!data) {
            return data;
        }
        const refMap = new Map();
        let idNum = 0;
        function copy(jObj) {
            if(!jObj) {
                return jObj;
            }
            if(_.isArray(jObj)) {
                const r = [];
                for (let value of jObj) {
                    r.push(copy(value));
                }
                return r;
            }
            if(_.isObjectLike(jObj)) {
                if (jObj instanceof Date) {
                    return moment(jObj).format(FORMAT_DATETIME);
                } else if (moment.isMoment(jObj)) {
                    return jObj.format(FORMAT_DATETIME);
                }
                let r = refMap.get(jObj);
                if(r){
                    if(!r[REF_KEY]) {
                        r[REF_KEY] = REF + (idNum++);
                    }
                    return r[REF_KEY];
                }
                r = {};
                let i, keys = jObj ? Object.keys(jObj) : null;
                if(!(keys && keys.length)) {
                    return r;
                }
                refMap.set(jObj, r);
                for (i = 0; i < keys.length; i++) {
                    let key = keys[i], value = jObj[key];
                    r[key] = copy(value);
                }
                return r;
            }
            return jObj;
        }
        return copy(data);
    }
})();


function parseResponse(input) {
    return input && _.isString(input) && input.length > 0 ? JSON.parse(input) : null;
}

function requestConfig(params, methodName, headers){
    params = jsonApiPreProcessing(params);

    const url = store.state.apiUrl + '/' + this.serviceName + '/' + methodName;

    headers || (headers = {});
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    return {
        _requestInfo : {
            headers: headers,
            service: this.serviceName,
            method: methodName,
            arguments: params
        },
        url : url,
        method: 'post',
        data : JSON.stringify(params),
        transformResponse: [parseResponse, jsonApiPostProcessing],
        headers: headers
    };
}
function create_requestConfigMethod(methodName) {
    return function(){
        return requestConfig.call(this, Array.prototype.slice.call(arguments, 0), methodName);
    };
}

function create_handlerMethod(methodName) {
    return function(params, headers){
        return ajax(requestConfig.call(this, params, methodName, headers));
    };
}

const ADD_METHOD = Symbol('Service.addMethod');
const ADD_METHODS = Symbol('Service.addMethods');
export class Service {
    constructor(serviceName, methods) {
        this.serviceName = serviceName;
        // methods
        this[ADD_METHODS](methods);
        // default
        this[ADD_METHOD]('_schema');
    }
    [ADD_METHODS](methods) {
        const self = this;
        if(_.isArray(methods)){
            _.each(methods, methodName => self[ADD_METHOD](methodName));
        } else if(methods){
            _.each(methods, (handler, methodName) =>  self[ADD_METHOD](methodName, handler));
        }
    }
    [ADD_METHOD](methodName, handler) {
        const self = this;
        if(_.isString(methodName) && methodName.length && !self[methodName]){
            self[methodName + '_ajaxArgs'] = create_requestConfigMethod(methodName);
            self[methodName] = (_.isFunction(handler) ? handler : create_handlerMethod(methodName)).bind(self);
            return true;
        } else if(!handler && _.isFunction(methodName) && methodName.name.length) {
            self[methodName.name] = methodName.bind(self);
        }
        return false;
    }
}

export class VueService extends Service {
    [ADD_METHOD](methodName, handler) {
        if(!super[ADD_METHOD](methodName, handler)) {
            return false;
        }

        return true;
    }
}

const ADD_SERVICE = Symbol('Services.addService');
const ADD_SERVICE_WITH_URL = Symbol('Services.addServiceWithUrl');
export class Services {
    static newService(serviceName, methods) {
        return new Service(serviceName, methods);
    }
    [ADD_SERVICE](serviceName, methods) {
        return this[ADD_SERVICE_WITH_URL](serviceName, serviceName, methods);
    }
    [ADD_SERVICE_WITH_URL](serviceName, url, methods) {
        url || (url = serviceName);
        const self = this;
        let service = self[serviceName];
        if(service && service.serviceName === url) {
            service[ADD_METHODS](methods);
        } else {
            self[serviceName] = service = Services.newService(url, methods);
        }
        if(self._vueService) {
            self._vueService[ADD_SERVICE_WITH_URL](serviceName, url, methods);
        }
        return service;
    }
    // Vue plugin
    install(Vue) {
        if (this._vueService) {
            return;
        }
        const services = this, vs = this._vueService = new Services();
        vs.addService = function addService(serviceName, methods) {
            return this[serviceName] = new VueService(serviceName, methods);
        };
        if(typeof Proxy !== 'function') {
            Vue.prototype.$services = services;
            return;
        }
        function vueName(cmp) {
            return cmp == null ? null :
                _.get(cmp, '$options._componentTag')
                || _.get(cmp, '$vnode.componentOptions.tag');
        }
        function elPointer(el, child) {
            if(!el) return '';
            let name = vueName(el.__vue__) || el.tagName.toLowerCase(),
                _className = el.className && typeof el.className === "object" ? Object.keys(el.className)[0] : el.className;
            if(el.id) {
                name += '#' + el.id;
            }
            if(_className) {
                name += '.' + _className.replace(' ', '.');
            }
            if(child) {
                let children = el.children;
                for (let i = 0; i < children.length; i++) {
                    if(children.item(i) === child) {
                        if(i > 0) {
                            name += '[' + i + ']';
                        }
                        break
                    }
                }
            }
            return elPointer(el.parentElement, el) + '/' + name;
        }
        Object.defineProperties(Vue.prototype, {
            $services: {
                get: function () {
                    const self = this,
                        eventTarget = _.get(window, 'event.target'),
                        pointer = elPointer(eventTarget instanceof Element ? eventTarget : self.$el);
                    return new Proxy(services, {
                        get: function (services, serviceName) {
                            if(!services[serviceName]) {
                                throw new Error(`service '${serviceName}' not found`)
                            }
                            return new Proxy(services[serviceName], {
                                get: function (service, methodName) {
                                    if(!service[methodName]) {
                                        throw new Error(`service method '${serviceName}.${methodName}' not found`)
                                    }
                                    return new Proxy(service[methodName], {
                                        apply: function (method, serviceProxy, args) {
                                            args = [args[0], args[1] || {}];
                                            args[1]['X-FST-ElPtr'] || (args[1]['X-FST-ElPtr'] = pointer);
                                            console.debug('remote call %c%s.%s(%O)%c from %s: %O', 'font-weight:bold;', serviceName, methodName, args, 'font-weight:normal;', pointer, self);
                                            const req = method.apply(service, args),
                                                prepareOld = req.prepare;
                                            req.prepare = function (arg) {
                                                return prepareOld.apply(req, [arg]);
                                            };
                                            return req;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        });
    }
}

export const services = new Services();
export const addService = services[ADD_SERVICE].bind(services);
export const addServiceWithUrl = services[ADD_SERVICE_WITH_URL].bind(services);

export function addMethods(service, methods) {
    return service[ADD_METHODS](methods);
}

export function addMethod(service, methodName, handler) {
    return service[ADD_METHOD](methodName, handler);
}

export function addMappings(mappings) {
    if(!_.isArray(mappings)) {
        return services;
    }
    const srvMethods = {};
    for (let i = 0; i < mappings.length; i++) {
        const mapping = mappings[i];
        if(!(_.isString(mapping) && mapping.length && mapping.startsWith(Const.apiUrl) && mapping.indexOf('*') < 0)) {
            continue;
        }
        const split = mapping.substring(Const.apiUrl.length).split('/').filter(m => m.length);
        if(split.length !== 2) {
            continue;
        }
        (srvMethods[split[0]] || (srvMethods[split[0]] = [])).push(split[1]);
    }
    for (const serviceName in srvMethods) {
        addService(serviceName, srvMethods[serviceName]);
    }
    return services;
}

if(typeof window !== 'undefined') {
    window.services = services;
}

addService('system', [
    'manifest',
    'info'
]);

export default services;
