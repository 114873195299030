<template>
    <div class="fst-SearchFavoritesFilter">
        <div class="filter-header">
            <div v-html="$t('label.filterBy')"></div>
        </div>
        <div class="filter-content" v-loading="loading">
            <fst-collapse v-model="active">
                <fst-collapse-item name="types" :title="$t('label.documentType')"  :select-all-check-box="true"
                                   :select-all-status="selectAllTypes" key="filters"
                                   @checkItAll="handleSelectAll" ref="selectAllTypes">
                    <el-checkbox-group v-model="model.types">
                        <el-popover v-for="item in filters.types"
                                    :key="`filterPopover-types-${item.type}`"
                                    ref="filterPopover"
                                    v-model="item.popover"
                                    popper-class="button-popover"
                                    trigger="manual"
                                    placement="right"
                        >
                            <el-button @click="apply" type="primary">Apply</el-button>
                            <el-checkbox slot="reference" :label="item.type"
                                         @change="handleChangeFilterItem('types', item)">
                                <span class="type">{{ $t('searchMode.source.' + item.type) }}</span>
                                <span class="dots-div"></span>
                                <el-tag type="primary" effect="plain" size="mini">{{ item.inFavorites }}</el-tag>
                            </el-checkbox>
                        </el-popover>
                    </el-checkbox-group>
                </fst-collapse-item>

                <fst-collapse-item name="tags" :title="$t('label.tags')" :select-all-check-box="true"
                                   :select-all-status="selectAllTags" key="tags"
                                   @checkItAll="handleSelectAllTags" ref="selectAllTags">
                    <el-checkbox-group v-model="tagsModel" class="iq-tag-color">
                        <el-popover v-for="item in assignedTagsList"
                                    :key="`filterPopover-tags-${item.id}`"
                                    ref="filterPopover"
                                    v-model="item.popover"
                                    popper-class="button-popover"
                                    trigger="manual"
                                    placement="right"
                        >
                            <el-button @click="handleApplyTagFilter" type="primary">Apply</el-button>
                            <el-checkbox slot="reference" :label="item.id"
                                         @change="handleChangeTag(item)">
                                <span class="type" :class="`color-${item.color}`">{{ item.name }}</span>
                                <span class="dots-div"></span>
                                <el-tag type="primary" effect="plain" size="mini">{{ item.count }}</el-tag>
                            </el-checkbox>
                        </el-popover>
                    </el-checkbox-group>
                </fst-collapse-item>
            </fst-collapse>
        </div>
    </div>
</template>

<script>
import FstCollapse from '@/commons/components/FstCollapse.vue';
import FstCollapseItem from '@/commons/components/FstCollapseItem.vue';

export default {
    name: "SearchFavoritesFilter",
    components: {FstCollapse, FstCollapseItem},
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        favCounter:{
            type: Object,
            default: () => ({})
        },
        assignedTagsList: {
            type: Array
        },
        filterData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            model: this.value,
            tagsModel: _.map(this.assignedTagsList, 'id'),
            active: ['types', 'tags'],
            filters: this.filtersDataFormat(this.filterData),
            selectAllTypes: true,
            selectAllTags: true,
            loading: true
        }
    },
    watch: {
        filterData(newVal) {
            this.filters = this.filtersDataFormat(newVal);
        },
        assignedTagsList(newVal) {
            let newTag = newVal.find(it => it.newTag);
            if(newTag){
                 this.tagsModel.push(newTag.id);
            }
        },
        favCounter(newVal) {
            this.filters = this.filtersDataFormat(this.filterData);
        }
    },
    mounted() {
        console.log('Model', this.model);
        console.log('Tags Model', this.tagsModel);
        console.log('Tags List', this.assignedTagsList);
    },
    methods: {
        apply() {
            this.$emit('input', this.model);
            this.$emit('apply');
            this.$refs.filterPopover.forEach(popover => {
                popover.doClose();
            });
        },
        filtersDataFormat(data) {
            this.loading = false;
            return Object.assign(data, {
                types: data.types.map(item => {
                    return Object.assign(item, {
                        popover: false,
                        inFavorites: this.favCounter.inFavorites[item.type] || 0
                    });
                })
            });
        },
        handleLoading(status = false) {
            this.loading = status;
        },

        handleChangeFilterItem(filterName, item) {
            (this.filters[filterName] || []).forEach(it => {
                it.popover = item === it;
            });
            this.selectAllTypes = _.map(this.filters.types, "type").length === this.model.types.length
            clearTimeout(this.applyPopoverTimer);
            this.applyPopoverTimer = setTimeout(() => {
                item.popover = false;
            }, 10000);
        },
        handleChangeTag(item) {
            (this.assignedTagsList || []).forEach(it => {
                it.popover = item.id === it.id;
            });
            this.selectAllTags = this.assignedTagsList.length === this.tagsModel.length
        },
        handleApplyTagFilter() {
            this.$emit('filterByTag', this.tagsModel);
            this.$refs.filterPopover.forEach(popover => {
                popover.doClose();
            });

        },
        handleSelectAll(val) {
            this.model.types = val ? _.map(this.filters.types, "type") : [];
            this.selectAllTypes = val;
            if (val) this.apply();
        },
        handleSelectAllTags(val) {
            this.tagsModel = val ? _.map(this.assignedTagsList, 'id') : [];
            this.selectAllTags = val;
            if (val) this.handleApplyTagFilter();
        }
    }
}
</script>

<style lang="scss">
.fst-SearchFavoritesFilter {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: $iq-color-white;
    border-right: 1px solid $iq-color-blue-light;

    .filter-header {
        padding: 16px 20px;
        font-weight: bold;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $iq-color-blue-light;
        white-space: nowrap;

        .el-link {
            i {
                margin-right: 5px;
            }
        }
    }

    .filter-content {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .el-collapse-item__content {
        width: 100%;

        .el-checkbox-group,
        .el-radio-group {
            width: 100%;
        }

        .el-radio {
            display: flex;
            margin-bottom: 5px;
        }

        .el-checkbox, .el-radio {
            width: 100%;
            //width: auto;

            .el-checkbox__label,
            .el-radio__label {
                display: flex;
                width: 100%;

                .name {
                    margin-right: 5px;
                }

                .dots-div {
                    flex: 1;
                    border-bottom: 2px dotted rgba(213, 228, 246, .75);
                    position: relative;
                    bottom: 5px;
                }

                .el-tag {
                    height: 21px;
                    border-radius: 5px;
                    padding: 3px 10px;
                    border: none;
                    background: $iq-color-grey-ultra-light;
                    color: $iq-color-grey-light;
                    margin: 0 20px 0 5px;
                }
            }
        }
    }

    .el-collapse {
        border: none;
        padding: 5px 20px;

        &-item {
            position: relative;

            &__header {
                height: 40px;
                line-height: 40px;
                position: relative;
                padding-left: 25px;
                font-size: 15px;
                font-weight: bold;
                color: $iq-color-grey-light;
                border: none;
                display: flex;
                justify-content: space-between;

                .el-input {
                    margin-left: 10px;
                    flex: 1;
                }

                .clear {
                    margin-left: 10px;
                }
            }

            &__arrow {
                position: absolute;
                left: 0;
            }

            &__wrap {
                border: none;
                overflow: visible;
            }

            &__content {
                padding: 0 0 0 25px;
                display: inline-block;

                .el-checkbox {
                    margin: 0 0 5px 0;
                    display: flex;
                    align-items: start;
                    white-space: normal;
                    outline: none !important;

                    &__input {
                        padding-top: 2px;
                    }

                    .type {
                        margin-right: 5px;
                    }

                    .el-tag {
                        height: 17px;
                        line-height: 17px;
                        border-radius: 10px;
                    }

                    em {
                        font-style: normal;
                        font-weight: bold;
                    }
                }

                > div, .el-link, .el-checkbox {
                    float: left;
                    clear: both;
                }
            }
        }
    }
}
</style>