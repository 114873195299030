<template>
    <div class="admin-content mw-950">
        <el-row type="flex" class="action-panel" :gutter="20">
            <el-col :span="12">
                <el-button type="primary" @click="editGroup()"><i class="far fa-plus mr-1"></i> {{ $t('admin.groups.add') }}</el-button>
                <edit-group
                        v-if="show.editGroup"
                        :close="() => {this.show.editGroup = false}"
                        :loadGroups="loadGroups"
                        :id="editGroupId"
                />
            </el-col>
        </el-row>
        <el-table
            :data="GroupsModel.list"
            stripe
            border
            class="groups-table"
            :empty-text="$t('admin.groups.table.empty')"
        >
            <el-table-column type="index" label="№" width="50"/>
            <el-table-column
                    prop="id"
                    label="ID"
                    width="100"
            />
            <el-table-column
                    prop="name"
                    :label="$t('admin.groups.table.name')"
            />
            <el-table-column width="130" align="right">
                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="primary"
                            icon="el-icon-edit"
                            @click="editGroup(scope.row.id)"
                    />
                    <el-button
                            size="mini"
                            type="danger"
                            icon="el-icon-delete"
                            @click="deleteGroup(scope.row.id)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <el-row class="group-pagination text-right">
            <el-pagination
                    small
                    background
                    :hide-on-single-page="true"
                    layout="prev, pager, next"
                    :total="GroupsModel.total"
                    :page-size="GroupsModel.count"
                    :current-page="currentPage"
                    @current-change="onChangePage"
            />
        </el-row>
    </div>
</template>

<script>
    import {Groups} from '../../models/Groups';
    import EditGroup from './GroupEditor';

    export default {
        name: 'Groups',
        components: {
            EditGroup,
        },
        data () {
            return {
                GroupsModel: new Groups(),
                show: {
                    editGroup: false,
                },
                editGroupId: null,
            };
        },
        created() {
            this.loadGroups();
        },
        computed: {
            currentPage () {
                return (this.GroupsModel.offset / this.GroupsModel.count) + 1;
            },
        },
        methods: {
            loadGroups (offset = 0, count = 1000000) {
                this.$services.security.groups({
                    offset: offset,
                    count: count,
                    order: [{field: 'name', type: 'ASC'}],
                }).prepare().then(resp => {
                    this.GroupsModel.fill(resp.data);
                });
            },
            editGroup (id = null) {
                this.editGroupId = id;
                this.show.editGroup = true;
            },
            deleteGroup (id) {
                this.$confirm(this.$t('admin.groups.deleteGroups.sure'))
                    .then(() => {
                        this.$services.security.deleteGroups([id]).prepare().then(() => {
                            this.loadGroups();
                            this.$message({
                                message: this.$t('admin.groups.deleteGroups.deleted'),
                                type: 'success',
                            });
                        });
                    })
                    .catch(() => {})
                ;
            },
            onChangePage (page) {
                this.GroupsModel.offset = this.GroupsModel.count * (page - 1);
            },
        },
        destroyed () {
            this.GroupsModel.clear();
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/variables.scss";
    /deep/
    .groups-table {
        width: 100%;
        font-size: 14px;

        thead {
            color: $iq-color-text-primary;
            font-weight: bold;
        }
    }
    .action-panel {
        margin-bottom: 10px;
    }
    .group-pagination {
        margin: 10px -9px 0 0;
    }
</style>
