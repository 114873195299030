import Vue from 'vue';
import VueI18n from 'vue-i18n'
import Const from './../const'
import ru from './ru'
import en from './en'
// import store from "../store";

Vue.use(VueI18n);

export default new VueI18n({
    locale: Const.locale,
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: { ru, en }
});
