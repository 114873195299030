<template>
    <div class="widget-block">
        <div class="header mb-3">
            <div class="title">
                {{ $t(title) }}
            </div>
            <div class="flex-center">
                <div class="export-calculate">
                <span class="dashboardExport" @click="handleExport" v-if="filterLoc.type !== 'cagr'">
                    <i class="fas fa-pulse fa-spinner" v-if="exportLoading"></i>
                    <svg v-else width="16" height="19" viewBox="0 0 16 19" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.2 18.5L0 15.5V3.5L11.2 0.5V18.5ZM4.58001 5.5H2.8L4.62 8.74L2.68 12.42H4.42L5.58001 9.78L6.76 12.42H8.54L6.54 8.68L8.36 5.5H6.62L5.58001 7.72L4.58001 5.5ZM12 3.5H16V15.5H12V3.5Z"
                              fill="currentColor"/>
                    </svg>
                    {{$t('label.exportToExcel')}}
                </span>
                    <!--el-button type="primary" class="ml-2" @click="apply()">
                        {{$t('label.calculate')}}
                    </el-button-->
                </div>
                <export-to-image :filename="title" selector=".swHeatmap"></export-to-image>

            </div>
        </div>
        <div class="swHeatmap">
            <div class="wrapper">
                <div class="filter-block align-items-start" v-show="!params.noControl">
                    <div class="rows-cols-block"
                         v-loading="loading">
                        <div class="d-flex">
                            <template v-show="filterLoc.rows.length">
                                <div class="row-col-type">
                                    <span class="label ml-3">{{$t('label.values')}}: </span>
                                    <el-select v-model="filterLoc.valType"
                                               @change="apply()"
                                               placeholder="Absolute, Normalized...">
                                        <el-option key="normRow" :label="$t('dashboard.hotInRow')"
                                                   value="normRow"></el-option>
                                        <el-option key="normCol" :label="$t('dashboard.hotInColumn')"
                                                   value="normCol"></el-option>
                                        <el-option key="normAll" :label="$t('dashboard.hotAbsolute')"
                                                   value="normAll"></el-option>
                                    </el-select>
                                </div>
                            </template>
                        </div>
                        <div class="d-flex mt-3" v-show="filterLoc.docType">
                            <div v-show="filterLoc.docType" class="row-col-type">
                                <i class="far fa-thumbtack fixedValues"
                                   @click="fixed.rows = !fixed.rows"
                                   :class="{active: fixed.rows}"></i>
                                <span class="label">{{$t('label.rows')}}: </span>
                                <el-select v-model="filterLoc.rowsFacetType"
                                           @change="type => handleSelectType(type, 'rows')"
                                           placeholder="Row categories">
                                    <el-option v-for="(item,key) in facetTypes"
                                               :key="key"
                                               :label="item.label"
                                               :value="item.type"
                                               :disabled="item.disabled"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="row-col-values" v-show="filterLoc.rowsFacetType">
                                <el-select v-model="filterLoc.rows"
                                           class="w-100"
                                           multiple
                                           filterable
                                           @change="handleChangeFacetItem('rows')"
                                           :multiple-limit="maxRows"
                                           :placeholder="`${$t('label.colsPlaceholder')}: ${maxRows}`">
                                    <el-option v-for="(item,key) in rowsOpts"
                                               :key="key"
                                               :label="item.name"
                                               :value="item.value"
                                    ></el-option>
                                </el-select>
                                <div class="clearFacetItems">
                                    <i :class="['far fa-times', {active:filterLoc.rows.length}]"
                                       @click="handleClearFacetItems('rows')"></i>
                                </div>

                            </div>
                        </div>
                        <div class="d-flex mt-3" v-show="filterLoc.docType">
                            <div v-show="filterLoc.docType" class="row-col-type">
                                <i class="far fa-thumbtack fixedValues"
                                   @click="fixed.cols = !fixed.cols"
                                   :class="{active: fixed.cols}"></i>
                                <span class="label">{{$t('label.columns')}}: </span>
                                <el-select v-model="filterLoc.colsFacetType"
                                           @change="type => handleSelectType(type, 'cols')"
                                           placeholder="Column categories">
                                    <el-option v-for="(item,key) in facetTypes"
                                               :key="key"
                                               :label="item.label"
                                               :value="item.type"
                                               :disabled="item.disabled"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="row-col-values" v-show="filterLoc.colsFacetType">
                                <el-select v-model="filterLoc.cols"
                                           class="w-100"
                                           multiple
                                           @change="handleChangeFacetItem('cols')"
                                           filterable
                                           :multiple-limit="maxCols"
                                           :placeholder="`${$t('label.colsPlaceholder')}: ${maxCols}`">
                                    <el-option v-for="(item,key) in colsOpts"
                                               :key="key"
                                               :label="item.name"
                                               :value="item.value"
                                    ></el-option>
                                </el-select>
                                <div class="clearFacetItems">
                                    <i :class="['far fa-times', {active:filterLoc.cols.length}]"
                                       @click="handleClearFacetItems('cols')"></i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="dashboard-content">
                    <div class="chart-block" v-if="validQuery">
                        <div class="colsTitle">{{axes.cols.name}}</div>
                        <div class="chart-axes">
                            <div class="rowsTitle">
                                <span>{{axes.rows.name}}</span>
                            </div>
                            <apexchart
                                    ref="heatmapChart"
                                    height="350"
                                    class="chart"
                                    :options="columnsChartOptions"
                                    :series="chartSeries"
                                    @dataPointSelection="dataPointSelectionHandler">
                            </apexchart>
                        </div>
                    </div>
                    <div v-else class="noResult"><h3>No result</h3></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import _ from "lodash";
    import axios from "axios";
    import ExportToImage from "../../elements/ExportToImage";


    export default {
        name: "SWHeatmap",
        components: {ExportToImage},
        props: {
            filter: Object,
            title: String,
            years: Object,
            params: {
                type: Object,
                default: () => {
                }
            }
        },

        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                validQuery: false,
                loading: false,
                exportLoading: false,
                axes: {
                    cols: {
                        name: ''
                    },
                    rows: {
                        name: ''
                    },
                },
                result: {
                    list: [],
                    total: 0,
                    offset: 0
                },
                facets: [],
                docTypes: [{
                    name: 'News',
                    disabledFacets: ['COUNTRIES', 'CPC_EXT', 'customFacetsSeparator']
                }, {
                    name: 'Science',
                    disabledFacets: ['CPC_EXT', 'customFacetsSeparator']
                }, {
                    name: 'ScienceCrossref',
                    disabledFacets: ['customFacetsSeparator']
                }, {
                    name: 'Patents',
                    disabledFacets: ['customFacetsSeparator']
                }],
                facetName: {},
                cpcName: {},
                minYear: 2015,
                curYear: new Date().getFullYear(),
                facetTypes: [],
                rowsOpts: [],
                colsOpts: [],
                query: {},
                request: {
                    years: [],
                    filter: {},
                },
                maxRows: 30,
                maxCols: 15,
                fixed: {
                    rows: false,
                    cols: false
                },
                filterLoc: {
                    query: '',
                    lastQuery: '',
                    docType: 'Science',
                    rowsFacetType: 'trend',
                    colsFacetType: 'industry',
                    valType: 'normRow',
                    rows: [],
                    cols: [],
                    dateFrom: 2018,
                    dateTo: new Date().getFullYear(),
                },
                sortBy: {
                    field: 'score',
                    dir: 'desc'
                },
                statuses: [],
                chartSeries: [{
                    name: 'Technology Heatmap',
                    data: []
                }],

                columnsChartOptions: {
                    chart: {
                        type: 'heatmap',
                        foreColor: '#6A6E73',
                        fontFamily: 'Roboto, Montserrat, sans-serif',
                        toolbar: {
                            show: false
                        },
                        events: {
                            click: function (event, chartContext, config) {
                                // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex].x)
                            }
                        }
                    },
                    colors: ["#bd7100"],
                    // colors: ["#aa4400"],
                    // colors: ["#a16308"],
                    // colors: ["#F1B050"],
                    // colors: ["#219653"], // green
                    plotOptions: {
                        heatmap: {
                            enableShades: true,
                            radius: 0,
                        }
                    },
                    stroke: {
                        show: true,
                        colors: ['#dcc'],
                        width: 1,
                    },
                    legend: {
                        show: true,
                    },
                    dataLabels: {
                        enabled: false,
                        style: {
                            colors: ['#555'],
                        }
                    },
                    grid: {
                        show: false,
                    },
                    yaxis: {
                        labels: {
                            show: true,
                            align: 'right',
                            minWidth: 40,
                            maxWidth: 200,
                            style: {
                                fontSize: '12px'
                            },
                        },
                    },
                    xaxis: {
                        position: 'top',
                        color: '#C0C5CB',
                        type: 'category',
                        labels: {
                            show: true,
                        },
                        lines: {
                            show: false,
                        }
                    },
                },
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            customFacets() {
                return this.$store.state.customFacets;
            },
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) {
                    this[newVal.incEvent](newVal.data);
                }
            },
            filter(newVal) {
                let self = this;
                if (self.filterLoc.lastQuery !== self.filter.query) {

                    self.getFacets().then(() => {
                        if (self.filterLoc.rowsFacetType) {
                            self.changeType(self.filterLoc.rowsFacetType, 'rows')
                        }
                        if (self.filterLoc.colsFacetType) {
                            self.changeType(self.filterLoc.colsFacetType, 'cols')
                        }
                        self.refresh();
                    });
                    self.filterLoc.lastQuery = self.filter.query;
                } else {
                    self.refresh();
                }
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
            refresh(params = {}) {
                let self = this,
                    query = {};
                self.request.years = self.years.years;
                self.loading = true;

                query.filter = _.merge(_.cloneDeep(self.filter), {"years": self.years.years,})

                self.request.filterLoc = _.cloneDeep(query.filterLoc);
                query.columns = {
                    "type": self.filterLoc.colsFacetType,
                    "values": self.filterLoc.cols
                }
                if (self.customFacets[self.filterLoc.colsFacetType]) {
                    query.columns.type = 'CUSTOM';
                    query.columns.facetId = self.filterLoc.colsFacetType;
                }

                query.rows = {
                    "type": this.filterLoc.rowsFacetType,
                    "values": this.filterLoc.rows
                }
                if (self.customFacets[self.filterLoc.rowsFacetType]) {
                    query.rows.type = 'CUSTOM';
                    query.rows.facetId = self.filterLoc.rowsFacetType;
                }

                self.validQuery = self.filterLoc.rows.length && self.filterLoc.rowsFacetType && self.filterLoc.cols.length && self.filterLoc.colsFacetType;

                if (self.validQuery) {
                    self.query = query;
                    this.$services.analytics.heatMap(query).then(resp => {
                        const result = resp.data,
                            totalRows = result.rows.length;
                        let isRowCPC = query.rows.type.includes('CPC_');
                        let isColCPC = query.columns.type.includes('CPC_');
                        // console.log('REZZZ: ', result);
                        self.chartSeries = result.rows.reverse().map(it => {
                            // let _name = (it.rowValue.name && it.rowValue.name.includes('->')
                            //     ? it.rowValue.value + ' ' + self.$utils.cpcNameFormat(it.rowValue.name) : it.rowValue.name)
                            return {
                                name: it.rowValue.name && !isRowCPC ? it.rowValue.name : it.rowValue.value,
                                data: it.cells.map((cell, ind) => {
                                    // cell = cell ? cell : {absolute: 0, normAll: 0, normRow: 0, normCol: 0};
                                    // cell = cell ? cell : {};
                                    // let _name = (result.columnValues[ind].name && result.columnValues[ind].name.includes('->')
                                    //     ? result.columnValues[ind].value + ' ' + self.$utils.cpcNameFormat(result.columnValues[ind].name) : result.columnValues[ind].name);
                                    return {
                                        x: isColCPC ? result.columnValues[ind].value : _.startCase(result.columnValues[ind].name || result.columnValues[ind].value),
                                        y: cell ? self.$utils.roundX(cell[self.filterLoc.valType], 3) : 0,
                                        absolute: cell ? self.$utils.toFinHuman(cell['absolute']).number : 0,
                                        raw: cell ? cell['absolute'] : 0,
                                        colValue: result.columnValues[ind].value,
                                        rowValue: it.rowValue.value,
                                        colType: !!(result.columnValues[ind].name && !isColCPC) ? 'CUSTOM' : self.filterLoc.colsFacetType,
                                        colFacetId: !!(result.columnValues[ind].name && !isColCPC) ? self.filterLoc.colsFacetType : null,
                                        rowType: !!(it.rowValue.name && !isRowCPC) ? 'CUSTOM' : self.filterLoc.rowsFacetType,
                                        rowFacetId: !!(it.rowValue.name && !isRowCPC) ? self.filterLoc.rowsFacetType : null,
                                    }
                                })
                            }
                        });
                        self.$refs.heatmapChart.updateOptions({
                                chart: {
                                    height: totalRows > 1 ? totalRows * 45 : 80
                                },
                                xaxis: {
                                    labels: {
                                        rotate: 0,
                                        trim: true,
                                        hideOverlappingLabels: false,
                                        maxHeight: 250,

                                        // rotate: self.filterLoc.cols.length > 5 ? 45 : 0,
                                        // rotateAlways: self.filterLoc.cols.length > 5 ,
                                    },
                                    tooltip: {
                                        enabled: false
                                    }
                                },
                                tooltip: {
                                    enabled: true,
                                    y: {
                                        formatter: function (val, conf) {
                                            return self.chartSeries[conf.seriesIndex].data[conf.dataPointIndex].absolute;
                                        },
                                        title: {
                                            formatter: (seriesName) => self.cpcName[seriesName] ? self.cpcName[seriesName] : _.upperFirst(seriesName),
                                        },
                                    },
                                    x: {
                                        show: true,
                                        formatter: (x) => self.cpcName[x] || _.upperFirst(x),
                                    },
                                    // theme: 'dark'
                                },

                            }
                        );
                        // console.log('~HM~ Series: ', self.chartSeries);
                        setTimeout(() => {
                            self.loading = false;
                            let _hm = document.querySelectorAll('.apexcharts-heatmap > g rect'),
                                _h = _hm[0] ? _hm[0].height.baseVal.value : null,
                                _w = _hm[0] ? _hm[0].width.baseVal.value : null;
                            if (_h && self.filterLoc.valType === 'normRow') {
                                // _hm.forEach(it => {
                                //     it.height.baseVal.value = _h - 5;
                                // });
                            } else if (_h && self.filterLoc.valType === 'normCol') {
                                _hm.forEach(it => {
                                    it.width.baseVal.value = _w - 5;
                                });
                            }
                        }, 950);

                    });
                } else {
                    self.loading = false;
                }
            },
            apply() {
                this.refresh();
            },
            dataPointSelectionHandler(e, chartContext, conf) {
                let self = this;
                let _data = self.chartSeries[conf.seriesIndex].data[conf.dataPointIndex];

                console.log('KW--', _data);
                let docType = self.filterLoc.docType;
                let yearType = docType === 'Investment' ? 'INVT_YEAR' : 'YEAR'
                let facets = {
                    requests: [
                        {
                            type: yearType,
                            values: self.request.years
                        }
                    ]
                }
                // _.merge(facets.requests, ((self.filter.facets || {}).requests || []));
                facets.requests = ((self.filter.facets || {}).requests || []).concat(facets.requests);
                if (_data.colType === _data.rowType && _data.rowFacetId === _data.colFacetId) {
                    facets.requests.push({
                        type: _data.colType,
                        values: [_data.colValue, _data.rowValue],
                        facetId: _data.colFacetId,
                        operator: "AND",
                    });
                } else {
                    facets.requests.push({
                        type: _data.colType,
                        values: [_data.colValue],
                        facetId: _data.colFacetId
                    });
                    facets.requests.push({
                        type: _data.rowType,
                        values: [_data.rowValue],
                        facetId: _data.rowFacetId
                    });

                }
                // console.log('Facets', facets, self.request.filterLoc);
                let params = {
                    query: self.filter.nativeQuery ? self.filter.nativeQuery.value : self.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: docType,
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                }
                if (e.button === 0) {
                    self.$utils.openSearch(params);
                }
            },
            initData() {
                let self = this,
                    _filter = _.cloneDeep(self.filter);

                self.maxRows = self.params.maxRows || 20;
                self.maxCols = self.params.maxCols || 15;
                self.filterLoc.rowsFacetType = self.params.rows || 'trend';
                self.filterLoc.colsFacetType = self.params.cols || 'industry';
                self.filterLoc.valType = self.params.valType || 'normRow';
                self.columnsChartOptions.dataLabels = self.params.dataLabels || {
                    enabled: false,
                    style: {
                        colors: ['#555'],
                    }
                }
                if (self.params.namesToIso) {
                    self.columnsChartOptions.yaxis.labels.formatter = function (value, timestamp, opts) {
                        return self.params.namesToIso && !value.toString().match(/world/gi) ? self.$utils.countryToISO(value) : value
                    }
                    self.columnsChartOptions.xaxis.labels.formatter = function (value, timestamp, opts) {
                        return self.params.namesToIso && !value.toString().match(/world/gi) ? self.$utils.countryToISO(value) : value
                    }
                }


                self.filterLoc.docType = _filter.types[0];
                self.handleSelectSource(self.filterLoc.docType, true);
                self.filterLoc.lastQuery = self.filterLoc.query;
                self.docTypes = self.docTypes.map(it => {
                    it.label = self.$t('searchMode.source.' + it.name);
                    return it;
                });


                self.filterLoc.dateFrom = self.years.min;
                self.filterLoc.dateTo = self.years.max;
                self.facetName = {
                    'cpc_ext': 'PATENT CLASS (CPC)',
                    'trend': self.$t('facets.trend'),
                    'industry': self.$t('facets.industry'),
                    'APPLICATION_YEAR': 'year_of_application',
                    'customFacetsSeparator': self.$t('label.customFacetsSeparator'),
                    'COMPANIES': self.filterLoc.docType === 'Patents' ? 'applicant' : 'countries',
                    'COUNTRIES': self.filterLoc.docType === 'Patents' ? 'country_patent_office' : 'countries',
                    'YEAR': self.filterLoc.docType === 'Patents' ? 'year_of_patent' : 'year',
                };
            },
            getFacets(selectedFacet = null) {
                let self = this;
                self.request.years = self.years.years;

                let filter = {
                    query: self.filter.query,
                    types: self.filter.types,
                    queryFields: self.filter.queryFields,
                    semantic: false,
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    "years": self.request.years,
                    "spam": false,
                    "duplicate": false,
                    count: 0,
                    offset: 0,
                    facets: {
                        requests: [{
                            type: "YEAR",
                            calculate: true,
                            values: selectedFacet && selectedFacet.type === 'YEARS' ? selectedFacet.values : null,
                        },
                            {
                                type: "APPLICATION_YEAR",
                                calculate: true,
                                title: 'year_of_application',
                                availableFor: ['Patents'],
                                values: selectedFacet && selectedFacet.type === 'APPLICATION_YEAR' ? selectedFacet.values : null,
                            },
                            {
                                type: "COUNTRIES",
                                calculate: true,
                                values: selectedFacet && selectedFacet.type === 'COUNTRIES' ? selectedFacet.values : null,
                            },
                            {
                                type: "HEAD_COUNTRY",
                                calculate: true,
                                availableFor: ['Patents'],
                                values: selectedFacet && selectedFacet.type === 'HEAD_COUNTRY' ? selectedFacet.values : null,
                            },
                            {
                                type: "COMPANIES",
                                calculate: true,
                                availableFor: ['Patents'],
                                values: selectedFacet && selectedFacet.type === 'COMPANIES' ? selectedFacet.values : null,
                            },
                            {
                                type: "CPC_EXT",
                                calculate: true,
                                availableFor: ['Patents'],
                                values: selectedFacet && selectedFacet.type === 'CPC_EXT' ? selectedFacet.values : null,
                            },
                        ]
                    },
                };
                self.filter.facets.requests = (self.filter.facets.requests || []).filter(req => {
                    return !req.availableFor || req.availableFor.includes(self.filterLoc.docType);
                })
                if (self.isNativeQuery) { // --- if native Query is ON ---
                    filter.nativeQuery = {
                        value: self.filterLoc.query
                    };
                    delete filter.query;
                }

                self.loading = true;

                if (self.customFacets && Object.keys(self.customFacets).length) {
                    _.each(self.customFacets, it => {
                        filter.facets.requests.push({
                            type: "CUSTOM",
                            facetId: it.id,
                            calculate: true,
                            values: selectedFacet && selectedFacet.facetId === it.id ? selectedFacet.values : null,
                        })
                    });
                }
                return this.$services.documents.search(filter).then(resp => {
                    const result = resp.data;
                    self.facets = result.facets.map(it => {
                        it.orderBy = it.orderBy ? it.orderBy
                            : it.facetId && it.facetId === 'trend' ? 1
                                : it.facetId && it.facetId === 'industry' ? 2
                                    : it.type === "CUSTOM" ? 20
                                        : 10;
                        it.disabled = false;
                        it.items = _.orderBy(it.items, 'name', 'asc')
                        return it
                    });
                    self.facets.push({
                        orderBy: 15,
                        disabled: true,
                        type: '',
                        facetId: 'customFacetsSeparator',
                        items: [],
                    })
                    self.facets = _.orderBy(self.facets, ['orderBy', 'facetId'], ['asc', 'desc']);
                    // console.log('Industry facets items with "steel": ', ((self.facets.find(it => it.facetId === 'industry')
                    //     || {}).items || []).filter(it => (it.value && it.value.includes('steel'))));
                    self.loading = false;

                    let cpcs = _.find(self.facets, it => it.type.includes('CPC_'));
                    if (cpcs && cpcs.items) {
                        cpcs.items.forEach(it => {
                            self.cpcName[it.value] = self.$utils.cpcNameFormat(it.name);
                        })
                    }
                });
            },
            changeType(item, opts, refresh = false) {
                let self = this;
                item = self.params.orderBy || item;
                self.axes[opts].name = _.capitalize(self.facetTypes.find(it => it.type === self.filterLoc[`${opts}FacetType`]).label);
                self.filterLoc[opts] = self.fixed[opts] && !refresh ? self.filterLoc[opts] : [];
                if (item !== 'YEAR') {
                    self[opts + 'Opts'] = (self.facets.find(it => (it.facetId ? it.facetId === item : it.type === item)) || {})
                        .items.filter(it => it.value).map(it => {
                            let _name = it.name || it.value;
                            if (item.includes('CPC_')) {
                                _name = self.$utils.cpcNameFormat(_name);
                                it.name = _name.substr(0, 135) + (_name.length > 135 ? ' ...' : '');
                            } else {
                                if (self.params.namesToIso) {
                                    it.name = self.$utils.countryToISO(_name) || _name;
                                } else {
                                    it.name = _name;
                                }
                            }
                            return it;
                        });
                }
                self.fixed[opts] = refresh ? false : self.fixed[opts];

                if (!self.fixed[opts] || refresh) {
                    if (item === 'YEAR') {
                        self.filterLoc[opts] = [];
                        self[opts + 'Opts'] = [];
                        for (let year = self.filterLoc.dateFrom; year <= self.filterLoc.dateTo; year++) {
                            let _year = year.toString();
                            self.filterLoc[opts].push(_year);
                            self[opts + 'Opts'].push({
                                name: _year,
                                value: _year
                            })
                        }
                        if (_.last(self.filterLoc[opts]) === self.curYear.toString()) {
                            self.filterLoc[opts].pop();
                        }
                    } else {
                        let _items = _.orderBy(self[opts + 'Opts'], 'docCount', 'desc').filter(it => it.name !== 'Undefined');
                        self.filterLoc[opts] = _.map(_items.slice(0, (opts === 'cols' ? self.maxCols : self.maxRows)), 'value');
                    }
                }
                self.$nextTick(() => {
                    self.refresh()
                });
                // console.log('self[opts + Opts]', self[opts + 'Opts']);
            },
            handleSelectSource(item, apply = false) {
                let self = this,
                    _docType = self.docTypes.find(it => it.name === item);
                self.getFacets().then(() => {
                    self.facetTypes = self.facets.map(it => {
                        let out = _.cloneDeep(it);
                        out.type = out.facetId || out.type;
                        out.label = self.facetLabel(out);
                        out.disabled = (_docType.disabledFacets || []).includes(out.type);
                        return out;
                    });
                    if (_docType.disabledFacets.includes(self.filterLoc.colsFacetType)) {
                        self.filterLoc.colsFacetType = '';
                        self.filterLoc.cols = [];
                    }
                    if (_docType.disabledFacets.includes(self.filterLoc.rowsFacetType)) {
                        self.filterLoc.rowsFacetType = '';
                        self.filterLoc.rows = [];
                    }
                    if (self.filterLoc.rowsFacetType) {
                        self.changeType(self.filterLoc.rowsFacetType, 'rows')
                    }
                    if (self.filterLoc.colsFacetType) {
                        self.changeType(self.filterLoc.colsFacetType, 'cols')
                    }
                    if (apply) {
                        self.$nextTick(() => {
                            self.refresh()
                        });
                    }
                    // console.log('item', item, self.filterLoc);
                });
            },
            facetLabel(item) {
                let self = this;
                item = typeof item === 'object' ? item : {
                    facetId: item,
                    type: item,
                };
                return item.facetId ? self.facetName[item.facetId] || _.upperCase(self.customFacets[item.facetId].name)
                    : self.$t('facets.' + (self.facetName[item.type] || item.type).toLowerCase())
            },
            handleSelectType(item, opts) {
                let self = this;
                self.changeType(item, opts, true);
            },
            handleChangeFacetItem(opts) {
                let self = this;
                // self.fixed[opts] = true;
                if (opts === 'cols') {
                    let isCustom = self.customFacets[self.filterLoc.colsFacetType];
                    self.getFacets({
                        "type": isCustom ? 'CUSTOM' : self.filterLoc.colsFacetType,
                        "values": self.filterLoc.cols,
                        "facetId": isCustom ? self.filterLoc.colsFacetType : null
                    }).then(() => {
                        self.changeType(self.filterLoc['rowsFacetType'], 'rows');
                    });
                } else {
                    let isCustom = self.customFacets[self.filterLoc.rowsFacetType];
                    self.getFacets({
                        "type": isCustom ? 'CUSTOM' : self.filterLoc.rowsFacetType,
                        "values": self.filterLoc.rows,
                        "facetId": isCustom ? self.filterLoc.rowsFacetType : null
                    }).then(() => {
                        self.changeType(self.filterLoc['colsFacetType'], 'cols');
                    });
                }
            },
            handleChangePeriod() {
                let self = this;
                if (self.filterLoc.rowsFacetType === 'YEAR') {
                    self.changeType(self.filterLoc.rowsFacetType, 'rows')
                }
                if (self.filterLoc.colsFacetType === 'YEAR') {
                    self.changeType(self.filterLoc.colsFacetType, 'cols')
                }
                self.apply();
            },
            handleClearFacetItems(opts) {
                this.filterLoc[opts] = [];
            },
            handleExport() {
                if (!this.exportLoading) {
                    let self = this,
                        _query = _.cloneDeep(self.query),
                        fileName = 'Teqviser_Heatmap.xlsx';
                    _query.normalization = self.filterLoc.valType.replace('norm', '').toLowerCase();
                    self.exportLoading = true;

                    axios({
                        url: this.$store.state.apiUrl + '/analytics/heatMapExport',
                        method: 'POST',
                        data: JSON.stringify(_query),
                        responseType: 'blob',
                        headers: {'Content-Type': 'application/json;charset=UTF-8'}
                    }).then((resp) => {
                        // console.log('Checked query:', query, this.filterLoc);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(resp.data);
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.exportLoading = false;
                    })
                }
            },

        }
    }
</script>

<style lang="scss">
    .swHeatmap {
        /*max-height: calc(100vh - 95px);*/

        .rows-cols-block {
            flex: 1;
            margin-left: 10px;

            .label {
                width: 80px;
                display: inline-block;
            }

            input[type="text"] {
                font-variant: all-small-caps;
                font-size: .85rem;
            }
        }

        .row-col-type {
            .el-input {
                min-width: 220px;
            }

            &.source {
                padding-left: 17px;
            }
        }

        .row-col-values {
            flex: 1;
            margin-left: 5px;
            display: flex;
            align-items: center;
            color: $iq-color-text-primary;
        }

        .clearFacetItems {
            margin: 0 -15px 0 5px;
            z-index: 300;

            > i {
                opacity: 0;
                padding: 2px 5px;
                cursor: pointer;
                transition: all .2s ease;

                &.active {
                    opacity: 1;
                }
            }
        }

        .fixedValues {
            opacity: .75;
            padding: 0 5px 0 1px;
            position: relative;
            top: 2px;
            cursor: pointer;

            &.active {
                color: $iq-color-text-primary;
                font-weight: 900;
            }
        }

        .apexcharts-xaxis text,
        .apexcharts-yaxis text {
            font-variant: all-small-caps;
        }

        .dashboardExport {
            margin-left: auto;
            margin-right: 25px !important;
        }

        .apexcharts-tooltip {
            max-width: 300px;
            white-space: normal;

            .apexcharts-tooltip-y-group {
                display: flex;
                align-items: flex-start;
            }

            .apexcharts-tooltip-text-label {
                //text-align: justify;
            }

            .apexcharts-tooltip-text-value {
                white-space: nowrap;
                margin-left: 3px;

                &::before {
                    content: ": ";
                }
            }
        }

        .apexcharts-data-labels text {
            text-shadow: 1px 1px 0 rgba(255, 255, 255, .75),
            -1px -1px 0 rgba(255, 255, 255, .75),
            1px -1px 0 rgba(255, 255, 255, .75),
            -1px 1px 0 rgba(255, 255, 255, .75);
        }

        .el-tag.el-tag--info {
            background-color: $iq-blue-light;
            border-color: #d7e1fc;
            color: $iq-dark-theme-text;
            font-variant: all-small-caps;
            font-weight: 500;
        }

        .dashboard-content {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 1200px) {
        #app .fst-Dashboard .techHeatmap {
            .filter-block {
                flex-direction: column;
            }

            .rows-cols-block {
                margin-left: 0;
                width: calc(100% - 15px);

                & > div {
                    display: flex;
                    justify-content: space-between;
                }

                .dashboardExport {
                    margin-right: 0 !important;
                }
            }

            .calculate-block {
                width: 100%;
                margin-top: 15px;
                text-align: right;

                .el-button {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
</style>

<style scoped lang="scss">
    .chart {
        width: 100%;
        flex-basis: 100%;
    }

    .chart-axes {
        display: flex;
    }

    .rowsTitle {
        display: flex;
        align-items: center;
        width: 15px;
        min-height: 250px;

        span {
            transform: rotate(-90deg);
            transform-origin: 0 0;
            display: inline-block;
        }
    }

    .colsTitle, .rowsTitle {
        text-align: center;
        font-weight: 500;
        white-space: nowrap;
    }
</style>