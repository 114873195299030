<template>
    <div class="widget-block">
        <div class="header mb-2">
            <div class="title">
                {{ $t('dashboard.weakSignals') }}
            </div>
        </div>

        <div class="swTechWeakSignals">
            <div class="wrapper"
                 v-loading="loading">

                <div class="chart-block">
                    <div class="table-like iqTab sortTab">
                        <div class="tbody">
                            <div>
                                <div v-for="(item,key) in dataSchema" :key="key" :class="item.dir">
                                    <div class="sortBy" @click="handleSortBy(item)">
                                        <div class="nowrap" v-html="item.label"></div>
                                        <span></span>
                                    </div>
                                    <el-popover
                                            placement="bottom"
                                            width="250"
                                            :visible-arrow="false"
                                            popper-class="subFilter"
                                            trigger="click"
                                            v-model="item.subFilter.visible">
                                        <div class="input-group">
                                            <template v-if="typeof item.subFilter.predicate !== 'undefined'">
                                                <el-input v-model="item.subFilter.predicate"
                                                          :placeholder="$t('textSearch')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                            </template>
                                            <template v-else>
                                                <el-input v-model="item.subFilter.from" :placeholder="$t('from')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                                <el-input v-model="item.subFilter.to" :placeholder="$t('to')"
                                                          clearable
                                                          @keydown.native.enter.prevent="handleSubFilter(item)"
                                                ></el-input>
                                            </template>
                                            <el-button type="primary" @click="handleSubFilter(item)">
                                                {{$t('label.apply')}}
                                            </el-button>
                                        </div>
                                        <div slot="reference">
                                            <div :class="['subFilterBtn',{active: item.subFilter.from || item.subFilter.to || item.subFilter.predicate}]">
                                                <i :class="['fas',(item.subFilter.from || item.subFilter.to || item.subFilter.predicate ? 'fa-filter' : 'fa-ellipsis-v')]"></i>
                                            </div>
                                        </div>
                                    </el-popover>
                                    <div class="clearSubFilter"
                                         @click="handleClearSubFilter(item)"
                                         v-show="item.subFilter.from || item.subFilter.to || item.subFilter.predicate">
                                        <i class="fas fa-times"></i></div>

                                </div>
                            </div>
                        </div>
                        <div class="tbody" ref="tbody" v-if="result.list.length > 0">
                            <div v-for="(item, key) in result.list" :key="key">
                                <div>
                                    <div class="d-inline-block"
                                         @mouseover="mouseOver(item)"
                                         @mouseout="mouseOut()">
                                        <text-highlight

                                                class="wiki"
                                                :queries="[dataSchema.find(it => it.field === 'keyword').subFilter.predicate]"
                                                :highlightClass="'markIt'">
                                            {{ item.keyword }}
                                        </text-highlight>
                                    </div>
                                </div>
                                <div v-for="(it, k) in item.counts" :key="`${key}-${k}`" class="num">
                                    <span class="showDoc" @click="handleShowDocuments(item.keyword, k)">{{ it }}</span>
                                </div>
                                <div>
                                    {{ item.significance }}
                                </div>
                                <div>
                                    <i :class="['far up', item.iconLastYear]" v-if="item.iconLastYear"></i>
                                    {{ item.growthLastYear }}
                                </div>
                                <div>
                                    <i :class="['far up', item.icon]" v-if="item.icon"></i>
                                    {{ item.growth }}
                                </div>
                            </div>
                        </div>
                        <div v-else class="noResult"><h3>No result</h3></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_ROLES} from '@/models/Users';
    import WikiText from '@/commons/components/WikiText';
    import PredicateEditor from '@/components/search/PredicateEditor';
    import _ from "lodash";


    export default {
        name: "SWWeakSignals",
        props: {
            filter: Object,
            title: String,
            years: Object,
            params: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                userIsAdmin: this.$store.state.userInfo.roles.includes(USER_ROLES.ADMIN),
                predicateValue: '',
                loading: false,
                dateFrom: new Date().getFullYear() - 2,
                dateTo: new Date().getFullYear(),
                showWikiTimeout: 0,
                updateDataSchema: true,
                minYear: 2015,
                maxYears: 3,
                curYear: new Date().getFullYear(),
                dataSchema: [],
                dataSchemaInit: [
                    {
                        label: this.$t('dashboard.weakSignalsTab.keyword'),
                        dir: '',
                        field: 'keyword',
                        subFilter: {predicate: '', visible: false, filled: false}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.significance'),
                        dir: '',
                        field: 'significance',
                        subFilter: {from: 0.05, to: null, visible: false, filled: true}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.growthLastYear'),
                        dir: '',
                        field: 'growthLastYear',
                        subFilter: {from: null, to: null, visible: false, filled: false}
                    },
                    {
                        label: this.$t('dashboard.weakSignalsTab.growth'),
                        dir: 'DESC',
                        field: 'growth',
                        subFilter: {from: null, to: null, visible: false, filled: false}
                    },
                ],
                sortBy: {
                    // field: 'growthLastYear',
                    field: new Date().getFullYear(),
                    dir: "DESC"
                },
                result: {
                    list: [],
                    total: 0,
                    offset: 0
                },
                statuses: [],
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.userInfo;
            },
            lastYear() {
                return this.$store.state.lastYears[this.filter.types[0]]
            },
            maxYear() {
                return this.years.max;
            }
        },
        watch: {
            incEvent(newVal) {
                if (this[newVal.incEvent]) this[newVal.incEvent](newVal.data);
            },
            filter() {
                this.$nextTick(() => {
                    this.search();
                })
            },
            maxYear() {
                this.updateDataSchema = true;
            }
        },
        mounted() {
            this.initData();
        },
        methods: {
            search() {
                let self = this;
                self.dateFrom = (self.years.max - 2) || self.dateFrom;
                self.dateTo = self.years.max || self.dateTo;
                let sortYear = parseInt(self.sortBy.field);
                if (sortYear && (sortYear > self.dateTo || sortYear < self.dateFrom)) {
                    self.sortBy.field = self.dateTo > self.lastYear ? self.lastYear : self.dateTo;
                }
                let _dynamicPeriod = self.dateTo - self.dateFrom;
                let query = {
                    "filter": self.filter,
                    "year": self.dateTo,
                    "dynamicPeriod": _dynamicPeriod > 0 ? _dynamicPeriod : 1,
                    "count": 1000,
                    "order": {
                        "field": self.sortBy.field, // keyword/significance/growth/YYYY
                        "asc": self.sortBy.dir === 'ASC'
                    }
                };
                // console.log('sChema::', this.dataSchema.filter(it => it.subFilter.filled));
                (self.dataSchema.filter(it => it.subFilter.filled) || []).forEach(it => {
                    let _sf = it.subFilter,
                        isPrc = ['growthLastYear', 'growth'].includes(it.field),
                        _stop = false,
                        _query = query;

                    if (parseInt(it.field)) {
                        if (it.field > self.dateTo || it.field < self.dateFrom) {
                            _stop = true;
                        } else {
                            query.counts = query.counts || {};
                            _query = query.counts;
                        }
                    }
                    if (!_stop) {
                        if (typeof _sf.predicate !== 'undefined') {
                            query[it.field] = _sf.predicate
                        } else {
                            _query[it.field] = {}
                            if (!isNaN(parseInt(_sf.from))) {
                                _.merge(_query[it.field], {
                                    "minBoundType": "CLOSED",
                                    "min": isPrc ? parseFloat(_sf.from) / 100 : _sf.from,
                                });
                            }
                            if (!isNaN(parseInt(_sf.to))) {
                                _.merge(_query[it.field], {
                                    "maxBoundType": "CLOSED",
                                    "max": isPrc ? parseFloat(_sf.to) / 100 : _sf.to,
                                });
                            }
                        }
                    }
                })
                if (!!self.filter.nativeQuery) { // --- if native Query is ON ---
                    query.filter.nativeQuery = {
                        value: self.filter.query
                    };
                    delete query.filter.query;
                }

                console.log('WS Filter: ', query);
                self.loading = true;
                this.$services.analytics.keywordGrowth(query).then(resp => {
                    let _from = this.dateFrom >= this.dateTo ? this.dateTo - 1 : this.dateFrom;
                    // this.dateFrom = _from = _to > _from ? _from : _from - 1;


                    self.result.list = resp.data.keywords.map(it => {
                        for (let year = _from; year <= self.dateTo; year++) {
                            it.counts[year] = self.$utils.toFin(it.counts[year], 2) || 0;
                        }
                        it.icon = it.growth > 0 && (typeof it.growth !== 'undefined') ? 'fa-long-arrow-alt-up' : '';
                        it.growth = (typeof it.growth !== 'undefined') ? self.$utils.toFin(it.growth * 100, 2) + '%' : 'new'; // &infin;
                        it.iconLastYear = it.growthLastYear > 0 && it.growth !== 'new' ? 'fa-long-arrow-alt-up' : '';
                        // it.iconLastYear = it.growthLastYear > 0 || (typeof it.growth === 'undefined') ? 'fa-long-arrow-alt-up' : '';
                        it.growthLastYear = (typeof it.growthLastYear !== 'undefined') ? self.$utils.toFin(it.growthLastYear * 100, 2) + '%' : 'new';
                        it.significance = self.$utils.roundX(it.significance, 2);
                        return it;
                    });
                    // console.log('WS Data: ', self.result.list);
                    if (this.updateDataSchema) {
                        this.refreshDataSchema();
                    }

                    // console.log('----- Schema: ', self.dataSchema);
                    self.loading = false;
                });

            },
            refresh(params = {}) {
                this.search();
                // this.$refs.predicate.apply();
                if (!params.noScroll && this.$refs.tbody) {
                    this.$refs.tbody.scrollTop = 0;
                }

            },
            apply() {
                this.$emit('apply');
            },
            initData() {
                this.sortBy.field = this.lastYear;
                this.refreshDataSchema();
                this.search();
            },
            refreshDataSchema() {
                let self = this;
                let oldSchema = _.cloneDeep(self.dataSchema);
                self.dateFrom = (self.years.max - 2) || self.dateFrom;
                self.dateTo = self.years.max || self.dateTo;
                // self.dataSchema = _.cloneDeep(self.dataSchemaInit.map(it => {
                //     it.dir = '';
                //     return it;
                // }));
                self.dataSchema = self.dataSchema.length ? self.dataSchema.filter(it => !_.isNumber(it.field))
                    : _.cloneDeep(self.dataSchemaInit.map(it => {
                        it.dir = '';
                        return it;
                    }));

                let _from = this.dateFrom,
                    _to = this.dateTo;
                console.log('-- dataSch', self.dataSchema);
                this.dateFrom = _from = _to > _from ? _from : _from - 1;

                for (let y = _from; y <= (_to); y++) {
                    let _sf = (oldSchema.find(it => it.field === y) || {}).subFilter;
                    self.dataSchema.splice(-3, 0, {
                        label: y,
                        field: y,
                        subFilter: {
                            from: _sf && _sf.from ? _sf.from : null,
                            to: _sf && _sf.to ? _sf.to : null,
                            visible: false,
                            filled: _sf ? !!(_sf.filled) : false
                        }
                    });
                }
                (self.dataSchema.find(it => it.field === self.sortBy.field) || {}).dir = self.sortBy.dir;
                self.updateDataSchema = false;
            },
            handleAdjustSortFilter() {
                console.log(this.filter);
                console.log(this.dataSchema);

            },
            mouseOver(item) {
                this.showWikiTimeout = setTimeout(() => {
                    this.showWiki(item);
                }, 909)
            },
            mouseOut() {
                clearTimeout(this.showWikiTimeout);
            },
            showWiki(item) {
                // console.log('iiii', item.keyword);
                this.$modal.dialog(WikiText, {
                    title: "Wikipedia",
                    ':value': item.keyword,
                    params: {},
                }).catch(() => {
                });
            },
            handleShowDocuments(keyword, year) {
                let self = this,
                    facets = {
                        requests: [
                            {
                                type: "YEAR",
                                values: [year]
                            },
                            {
                                type: "SYSTEM_KEYWORDS",
                                values: [keyword]
                            }
                        ]
                    }
                let params = {
                    query: this.filter.query,
                    isNativeQuery: !!self.filter.nativeQuery,
                    docType: self.filter.types[0],
                    facets: facets,
                    spam: false,
                    duplicate: 'false',
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                }
                self.$utils.openSearch(params);


            },
            handleSortBy(item) {
                let _curSortDir = _.cloneDeep(this.dataSchema.find(it => it.field === item.field).dir);
                _.each(this.dataSchema, it => {
                    it.dir = '';
                });
                if (_curSortDir) {
                    this.dataSchema.find(it => it.field === item.field).dir = (_curSortDir === 'DESC') ? 'ASC' : 'DESC';
                } else {
                    this.dataSchema.find(it => it.field === item.field).dir = 'DESC';
                }
                this.sortBy = {
                    field: item.field,
                    dir: this.dataSchema.find(it => it.field === item.field).dir
                }
                this.refresh();

            },
            handleSubFilter(item) {
                let self = this;
                console.log('subFilter', this.dataSchema);
                // this.result.list = this.fullList.filter(it => {
                //     let out = true;
                //     self.dataSchema.forEach(f => {
                //         let isYear = parseInt(f.field),
                //             _out = true;
                //         let _val = parseFloat(isYear ? (it.counts[f.field].toString()).replace(/[\s%]/g, '')
                //             : (it[f.field].toString()).replace(/[\s%]/g, ''));
                //         if (typeof f.subFilter.predicate !== 'undefined') {
                //             _out = !f.subFilter.predicate || (it.keyword || '').includes(f.subFilter.predicate);
                //         } else {
                //             _out = !f.subFilter.from || _val >= parseFloat(f.subFilter.from);
                //             if (_out) {
                //                 _out = !f.subFilter.to || _val <= parseFloat(f.subFilter.to);
                //             }
                //         }
                //         if (!_out) out = false;
                //     })
                //     return out;
                // })
                if (item) {
                    item.subFilter.visible = false;
                    item.subFilter.filled = !!(item.subFilter.from || item.subFilter.to || item.subFilter.predicate);
                }
                this.search();
                // console.log('list', this.result.list);
            },
            handleClearSubFilter(item) {
                if (typeof item.subFilter.predicate !== 'undefined') {
                    item.subFilter.predicate = '';
                } else {
                    item.subFilter.from = '';
                    item.subFilter.to = '';
                }
                this.handleSubFilter();
            },
        }
    }
</script>

<style lang="scss">
    .swTechWeakSignals {
        .chart-block {
            max-width: calc(100% - 15px);
        }

        div.iqTab .tbody {
            max-height: calc(100vh - 310px);

            > div > div {
                font-size: 15px;
                padding: 15px 20px;
                line-height: 1;


                &:first-child {
                    flex-basis: 140px;
                    font-weight: 500;
                }
            }

            &:first-child {
                > div > div {
                    display: flex;
                    align-items: center;
                }

                span {
                    padding-left: 5px;
                }
            }

            i {
                &.up {
                    color: #19b326;
                }

                &.down {
                    color: #a33;
                }
            }

            .showDoc {
                margin-left: -6px;
                padding: 1px 8px;
                border-radius: 5px;
                transition: all .1s ease;
                cursor: pointer;
                border: $border;

                &:hover {
                    background: $iq-border-color-base;
                    color: #333;
                    /*font-weight: 500;*/
                }
            }

            .subFilterBtn {
                &:hover, &:focus, &:active, &.active {
                    color: $iq-color-text-primary;
                }
            }
        }

        .sortTab {
            .DESC, .ASC {
                .sortBy > div {
                    color: $iq-color-text-primary;
                }
            }

            .DESC {
                .sortBy {
                    > span {
                        &:after {
                            color: $iq-color-text-primary;
                            content: "\f175";
                            position: relative;
                            top: -1px;

                        }

                        &:before {
                            content: '';
                        }
                    }
                }
            }

            .ASC {
                .sortBy {
                    > span {
                        &:after {
                            color: $iq-color-text-primary;
                            content: "\f176";
                            position: relative;
                            top: -1px;

                        }

                        &:before {
                            content: '';
                        }
                    }
                }
            }

            .sortBy {
                > span {
                    margin-right: 0;
                }

                & + span:hover {
                    & + .clearSubFilter {
                        //display: inline-block;
                        opacity: 1;
                        transition: all .2s ease .1s;
                    }
                }
            }
        }

        .noResult {
            min-height: calc(100vh - 202px);
        }

        .markIt {
            background: none;
            color: #fff;
        }
    }
</style>

<style scoped lang="scss">
    .wiki {
        cursor: help;
    }
</style>