<template>
    <div class="fst-ClusterMap dark-theme"
         v-loading="loading"
         element-loading-background="rgba(19, 19, 19, .7)"
         :class="[
             {fullScreen: isFullScreen},
             {noLabels: !showLabels},
             {quickTourStarted: step.show && step.section === 'clusterMap'}
             ]">
        <Header v-show="!isFullScreen"
                @quickTour="startQuickTour"
                from="Clusters"
                mode="clusters">
            <template slot="predicate">
                <div class="top-control">
                    <div class="source">
                        <label>Report: </label>
                        <div class="d-flex">
                            <el-select v-model="dataSourceId"
                                       ref="reportSelect"
                                       popper-class="darkSelect"
                                       filterable
                                       @change="handleChangeSource()">
                                <el-option value="">--- View all reports ---</el-option>
                                <el-option v-for="(item,key) in sources"
                                           :key="key"
                                           :value="item.id"
                                           :label="`${item.filter.types[0]}: ${item.name}`"></el-option>
                            </el-select>
                            <!--el-button plain type="primary" @click="$emit('showList')">
                                <i class="far fa-list-ul"></i>
                            </el-button-->
                        </div>
                    </div>
                    <div class="predicate">
                        <el-input clearable v-model="searchFilter.query"
                                  @change="handleChangeSearch()"
                                  @keyup.native="handleSearchKeypress()"
                                  @keyup.native.enter="searchInClusters()">
                            <template slot="prepend">
                                <div>
                                    <template v-if="totalFound">
                                        Found:
                                        <span class="pointer"
                                              @click="showDocs({mode:'foundDocs'})">{{totalFound}}</span>
                                    </template>
                                    <template v-else><i class="far fa-search"></i></template>
                                </div>
                            </template>
                        </el-input>
                        <el-button type="primary" plain
                                   @click="handleChangeSearchResultType('points')">
                            Search
                        </el-button>
                        <!--el-button type="primary" plain
                                   :class="{'is-pressed': searchFilter.searchResultType === 'clusters'}"
                                   @click="handleChangeSearchResultType('clusters')">
                            Clusters
                        </el-button-->
                    </div>
                </div>
            </template>
        </Header>
        <div class="chart-container">
            <div class="control" v-show="clusterData.length > 0">
                <div class="colorSchemeBlock">
                    <label class="mr-2">Coloring by: </label>
                    <el-select v-model="colorScheme" @change="toggleColorScheme()"
                               class="flex-1"
                               popper-class="darkSelect">
                        <el-option label="Cluster ID" value="byCluster"></el-option>
                        <el-option label="Growth last year" value="byGrowthLastYear"
                                   :disabled="noYearCount || isSearch"></el-option>
                        <el-option label="Growth Avg" value="byGrowthAvg"
                                   :disabled="noYearCount || isSearch"></el-option>
                    </el-select>
                </div>

                <div class="flex-center-between">
                    <span @click="handleToggleAll()" :class="['selectAll', {active: selectAll}]">
                        <!--el-checkbox v-model="selectAll">All</el-checkbox-->
                        <i :class="['fas mr-1', selectAll ? 'fa-check-square' : 'fa-square']"></i>
                        All
                    </span>
                    <div class="total" @click="handleToggleAll()">Total: {{totalDocs}}</div>
                </div>
                <div class="cluster-checks">
                    <label v-for="item in clusters" :key="`cluster-${item.id}`">
                        <div :class="[{checked: item.selected && item.show}, {strike: !item.show}]"
                             @click="handleCheckCluster(item)">
                            <span class="marker" :style="`background:${item.color}`"></span>
                            <span class="form-check-label">{{item.id}}. {{item.name}}</span>
                            <span class="numDocsPrc">
                                <template v-if="!isByCluster">{{item.growthFormatted}}%</template>
                                <template v-else>{{item.numDocsPrc}}%</template>
                            </span>
                        </div>
                        <i class="far showHide fa-fw"
                           :class="[{checked: item.selected && item.show}, (item.show ? 'fa-eye' : 'fa-eye-slash')]"
                           @click="toggleShowCluster(item)"></i>
                    </label>
                </div>
                <div class="switches">
                    <el-switch v-model="showNoCluster"
                               :width="25"
                               inactive-color="#46484D"
                               active-color="#fff"
                               class="mr-3"
                               @change="handleShowNoClusters"
                               active-text="No clusters"></el-switch>

                    <el-switch v-model="showBlackList"
                               v-show="blackList.length"
                               :width="25"
                               inactive-color="#46484D"
                               active-color="#fff"
                               class="mr-3"
                               @change="handleShowBlackList"
                               active-text="BlackListed"></el-switch>

                    <el-switch v-model="showLabels"
                               @change="toggleShowLabels"
                               :width="25"
                               inactive-color="#46484D"
                               active-color="#fff"
                               active-text="Labels"></el-switch>
                </div>

            </div>
            <div id="polySelection" :class="{active:polySelection.show}"></div>
            <div id="clusterMapChart"></div>
            <div class="control right" data-role="cluster-props" v-show="selectedCluster.show">
                <span class="close" @click="selectedCluster.show=false"><i class="far fa-times"></i></span>
                <div class="title" v-loading="selectedCluster.loading"
                     element-loading-spinner="el-icon-loading"
                     element-loading-background="rgba(0, 0, 0, 0.8)">
                    <template v-if="selectedCluster.combinedName">
                        {{selectedCluster.combinedName}}
                    </template>
                    <template v-else>
                        <span class="nowrap" v-if="selectedCluster.id">{{selectedCluster.id}}. </span>
                        <el-input v-if="selectedCluster.isEditing" v-model="selectedCluster.name"
                                  @blur="handleEditing(false)"
                                  @change="handleEditClusterName()"></el-input>
                        <span :class="['clusterName', {pointer:isAllowedToEdit}]" v-else @click="handleEditing(true)">{{selectedCluster.name}}</span>
                    </template>
                </div>
                <div class="subtitle numDocs">
                    <div>
                        Documents: <span v-html="selectedCluster.docs"></span>
                    </div>
                    <el-link class="small-caps"
                             v-show="selectedCluster.docs"
                             @click="showDocs()">Show documents
                    </el-link>
                </div>
                <div v-show="!isByCluster" class="subtitle">
                    Growth
                    <template v-if="colorScheme === 'byGrowthLastYear'">last year:</template>
                    <template v-else>avg:</template>
                    {{selectedCluster.growthLastYear}}%
                </div>
                <div class="keywords"
                     v-show="selectedCluster.keywords && selectedCluster.keywords.length"
                     v-loading="loadingKw"
                     element-loading-background="rgba(0, 0, 0, .9)">
                    <div v-for="(item, key) in selectedCluster.keywords"
                         class="kw"
                         :key="`tooltip-${key}`">
                        {{ item }}
                    </div>
                </div>
                <div class="yearCounts"
                     v-if="sourceParams.gotYearCounts && selectedCluster.yearCounts.length
                     && selectedCluster.docs">
                    <div class="subtitle">Documents by year</div>
                    <div class="mini-chart mt-10">
                        <div class="vals">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                {{item.value}}
                            </div>
                        </div>
                        <div class="histogram">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                <div :style="`height: ${item.chartHeight}px`"></div>
                            </div>

                        </div>
                        <div class="xaxis">
                            <div v-for="(item, key) in selectedCluster.yearCounts" :key="key">
                                {{item.year}}
                            </div>
                        </div>

                    </div>

                </div>
                <div class="settings"></div>
                <!--div>
                    <button class="btn btn-outline-primary" data-role="showModal">Import Data</button>
                </div-->
            </div>
            <div class="colorLegend" v-show="!isByCluster || isSearch__Clusters && totalFound"
                 :class="{'trans-0': loading}">
                <div class="gradient">
                    <div class="item" v-for="(item, key) in legend.values" :key="key">
                        <!--                        <div class="value">{{item}}%</div>-->
                        <div class="marker"
                             :style="`background: ${isSearch ? searchFilter.palette[key] : legend.palette[key]}`"></div>
                    </div>
                </div>
                <div class="growth-range">
                    <el-slider v-model="growthRange.range"
                               v-if="!isByCluster || isSearch__Clusters && totalFound"
                               @change="plotChart()"
                               :marks="growthRange.marks"
                               :min="growthRange.min"
                               :max="growthRange.max"
                               range></el-slider>
                </div>

            </div>
        </div>
        <div class="exportBlock" v-show="exportSVG">
            <svg id="exportSVG" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink"></svg>
            <div class="exportMenu">
                <el-button type="primary" size="medium" plain class="toggleFullScreen"
                           @click="exportSVG = false">
                    <i class="fas fa-redo fa-flip-horizontal"></i>
                </el-button>
                <el-button type="primary" size="medium" class="toggleFullScreen" @click="saveSVGFile">
                    <i class="fas fa-save"></i>
                </el-button>
            </div>
        </div>
        <div class="mapTooltip" ref="mapTooltip" v-show="tooltip.show">
            <div class="title">{{tooltip.title}}</div>
            <div class="docs params">{{tooltip.docs}}</div>
            <div class="params" v-show="!isByCluster">
                <template v-if="colorScheme === 'byGrowthAvg'">Growth avg.:</template>
                <template v-else>Growth last year:</template>
                {{tooltip.growthLastYear}}%
            </div>
            <div class="keywords params">
                <ul>
                    <li v-for="(item, key) in tooltip.keywords" :key="`tooltip-${key}`">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <!--            <div class="params">{{tooltip.color}}</div>-->
        </div>
        <div class="bottomRightMenu" v-show="!isFullScreen">
            <div class="areaSelectionControl">
                <!--el-switch v-model="polySelection.show"
                           @change="togglePolySelection"
                           :width="25"
                           inactive-color="#46484D"
                           active-color="#fff"
                           class="mr-3"
                           active-text="Select area"></el-switch-->
                <div class="btn-group mr-3">
                    <el-button type="primary" size="small"
                               plain
                               :class="{'is-pressed': !polySelection.show}"
                               @click="togglePolySelection(false)">
                        <i class="fas fa-mouse-pointer"></i>
                    </el-button>
                    <el-button type="primary" size="small"
                               plain
                               :class="{'is-pressed': polySelection.show}"
                               @click="togglePolySelection(true)">
                        <i class="fas fa-lasso"></i>
                    </el-button>
                </div>
                <el-tooltip effect="dark" content="Reset selection"
                            :open-delay="404" placement="top">
                    <el-button type="primary" size="small"
                               :disabled="!polySelection.points.length"
                               @click="resetPolySelection({redraw: true})"
                               plain>RESET
                    </el-button>
                </el-tooltip>
            </div>

            <el-tooltip v-show="!isFullScreen" class="item" effect="dark" content="Fullscreen"
                        :open-delay="404" placement="top">
                <el-button type="primary" plain class="toggleFullScreen" @click="toggleFullScreen()">
                    <i class="far" :class="isFullScreen ? 'fa-compress-wide' : 'fa-expand-wide'"></i>
                </el-button>
            </el-tooltip>

            <el-dropdown trigger="click" @command="handleExportSVG">
                <el-button type="primary" plain class="ml-2">
                    Export to SVG<i class="el-icon-arrow-up el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" class="darkSelect">
                    <el-dropdown-item command="dark">Dark theme</el-dropdown-item>
                    <el-dropdown-item command="light">Light theme</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-button type="primary"
                   class="mr-3 showSelectedDocs"
                   size="medium"
                   plain
                   v-show="false && polySelection.docs.showBtn && polySelection.selected.length && !isSearch"
                   ref="showSelectedDocs"
                   @click="showDocs({mode: 'selectedDocs'})">
            <i :class="['fas mr-1', polySelection.docs.loading ? 'fa-pulse fa-spinner' : 'fa-copy']"></i>
            <span class="small">{{polySelection.numSelected}}</span>

        </el-button>
        <quick-tour></quick-tour>
    </div>
</template>

<script>
    import _ from 'lodash';
    import {USER_ROLES} from '@/models/Users';
    import Header from '@/components/Header';
    import axios from "axios";
    import SearchList from '../search/SearchList';
    import QuickTour from "../settings/QuickTour";
    import {mapGetters} from "vuex";

    export default {
        name: 'ClusterChart',
        components: {QuickTour, Header},
        props: {
            dataSourceList: {
                type: Array
            },
            selectedSourceId: {
                type: String
            },
        },
        data() {
            return {
                dataSourceId: _.clone(this.selectedSourceId),
                sources: [],
                firedData: {},
                ctrlDown: false,
                grabbing: false,
                timeout: {
                    poly: 0,
                    mouseOver: 0,
                    showDocBtn: 0,
                },
                tooltip: {
                    show: false,
                    title: '',
                    docs: "0",
                    keywords: [],
                },
                sourceParams: {},
                selectedCluster: {
                    show: false,
                    name: '',
                    altName: '',
                    editName: '',
                    isEditing: false,
                    loading: false,
                    docs: 0,
                    yearCounts: [],
                    keywords: [],
                    clusterIds: [],
                    type: 'clusters',
                    foundPoints: []
                },
                colorScheme: 'byCluster',
                legend: {
                    min: 0,
                    max: 3,
                    values: [],
                    palette: [
                        // "hsl(150, 100%, 40%)",
                        // "hsl(137, 38%, 42%)",
                        // "hsl(103, 10%, 35%)",
                        // "hsl(50, 5%, 28%)",
                        // "hsl(1, 55%, 33%)",
                        // "hsl(1, 80%, 40%)",
                        // "hsl(1, 100%, 52%)",
                        "#008A45",
                        "#3AAE5B",
                        "#8EDF6F",
                        "#FBEEaF",
                        "#FAB347",
                        "#FC4E2B",
                        "#AF0300",
                    ]
                },
                growthRange: {
                    min: -50,
                    max: 150,
                    marks: {
                        0: '0'
                    },
                    range: [-50, 150]
                },
                quadtree: {},
                curYear: new Date().getFullYear(),
                loading: true,
                loadingKw: true,
                totalDocs: 0,
                totalFound: 0,
                noYearCount: false,
                chartProps: {
                    zoomScale: 1,
                    transform: {}
                },
                isFullScreen: false,
                selectAll: true,
                selectAll2: true,
                showLabels: true,
                showNoCluster: false,
                showBlackList: false,
                isAllowedToEdit: false,
                svgNS: "http://www.w3.org/2000/svg",
                exportSVG: false,
                extrapolation: 1.5,
                blackList: [],
                data: [],
                showData: [],
                showClusterIds: [],
                initialData: [],
                noNoClustersData: [],
                blackListedData: [],
                clusterData: [],
                clusterUserName: {},
                labelsData: [],
                clusters: {},
                userSettings: {},
                noClusterColor: "#555",
                fadeClusterColor: "#000",
                selectedPointColor: '#fff',
                gotDocPointColor: "hsl(200, 80%, 75%)", //"#3AAE5B",
                fadeDocPointColor: "hsl(200, 80%, 35%)", //"#3AAE5B",
                curPointCoords: {},
                contextMenu: [],
                polySelection: {
                    show: false,
                    svg: {},
                    points: [],
                    pointsScaled: [],
                    selected: [],
                    numSelected: '',
                    selectedBase64: '',
                    curPos: {
                        x: 0,
                        y: 0,
                    },
                    curPosNative: {
                        x: 0,
                        y: 0
                    },
                    movingPointId: null,
                    startPoint: {
                        x: 0,
                        y: 0
                    },
                    inProgress: false,
                    tick: 0,
                    docs: {
                        loading: false,
                        showBtn: false
                    }
                },
                searchFilter: {
                    loading: false,
                    query: '',
                    searchResultType: 'points',
                    result: {
                        clusters: {},
                        clusterIds: [],
                        bitset: () => 1,
                        docs: []
                    },
                    palette: [
                        "hsl(200, 80%, 82%)",
                        "hsl(200, 60%, 60%)",
                        "hsl(200, 70%, 40%)",
                        "hsl(200, 80%, 25%)",
                        // "hsl(150, 100%, 40%)",
                        // "hsl(137, 38%, 42%)",
                        // "hsl(103, 10%, 35%)",
                        // "hsl(50, 5%, 28%)",
                    ]

                }
            };
        },
        mounted() {
            let promises = [], self = this;
            this.$loadScript("d3/d3.min.js").then(() => {
                promises.push(self.$loadScript("d3/d3fc.js"));
                promises.push(self.$loadScript("d3/d3-context-menu.js"));
                Promise.all(promises).then(() => {
                    console.log('== D3 loaded ==');
                    self.sources = self.dataSourceList;
                    self.getData();
                    self.createMap();
                    // console.log('dataSourceList', self.dataSourceList)
                })
            });
            services.system.info().then(function (resp) {
                self.userSettings = resp.data.userSettings || {};
            });
        },
        watch: {
            step(newStep) {
                this.handleChangeStep()
            }
        },
        computed: {
            user() {
                return this.$store.state.userInfo;
            },
            allowed() {
                return this.$store.state.allowedActions;
            },
            isDev() {
                return this.user.roles.indexOf('DEV') >= 0;
            },
            isByCluster() {
                return this.colorScheme === 'byCluster';
            },
            isSearch() {
                return !!this.searchFilter.query;
            },
            isSearch__Clusters() {
                return this.searchFilter.query && this.searchFilter.searchResultType === 'clusters';
            },
            isSearch__Points() {
                return this.searchFilter.query && this.searchFilter.searchResultType === 'points';
            },
            lastYear() {
                return this.$store.state.lastYears[this.sourceParams.filter.types[0]]
            },
            ...mapGetters({
                step: 'QTStatus'
            }),
            xSc() {
                return this.chartProps.xScale
            },
            ySc() {
                return this.chartProps.yScale
            },
        },
        methods: {
            createMap() {
                let self = this;
                self.chartProps.xScale = d3.scaleLinear().domain([-10, 30]);
                self.chartProps.yScale = d3.scaleLinear().domain([0, 50]);
                self.chartProps.xScaleOriginal = self.chartProps.xScale.copy();
                self.chartProps.yScaleOriginal = self.chartProps.yScale.copy();

                const textLabel = fc
                    .layoutTextLabel()
                    .padding(1)
                    .value(d => d.label);

                const strategy = fc.layoutRemoveOverlaps(fc.layoutGreedy());
                const labels = fc
                    .layoutLabel(strategy)
                    .size((d, i, g) => {
                        const textSize = g[i].getElementsByTagName('text')[0].getBBox();
                        return [textSize.width, textSize.height];
                    })
                    .position(d => {
                        return [d.x, d.y];
                    })
                    .component(textLabel);

                self.chartProps.pointSeries = fc
                    .seriesWebglPoint()
                    .equals((a, b) => a === b)
                    .size(1)
                    .crossValue(d => d.x)
                    .mainValue(d => d.y);

                self.polySelection.svg = d3.select('#polySelection')
                    .append("svg")
                    .call(d3.zoom()
                        .scaleExtent([0.8, 7])
                        .on("zoom", () => {
                            // console.log('zzzoom');
                            self.zoomIt()
                        })
                    );


                self.chartProps.zoom = d3
                    .zoom()
                    .scaleExtent([0.8, 7])
                    .on("zoom", () => {
                        self.zoomIt()
                    });
                self.contextMenu = [
                    {
                        title: 'Deselect All',
                        disabled: false,
                        action: function (elm, d, i) {
                            self.selectAll = !self.selectAll;
                            self.handleToggleAll()
                        }
                    },
                    {
                        title: 'Hide labels',
                        disabled: false,
                        action: function (elm, d, i) {
                            self.showLabels = !self.showLabels;
                            self.toggleShowLabels()
                        }
                    },

                    {
                        title: 'Full screen ON',
                        disabled: false,
                        action: function () {
                            self.toggleFullScreen();
                        }
                    },
                    /*
                                        {
                                            divider: true
                                        },
                                        {
                                            title: 'Show cluster documents',
                                            action: function (e) {
                                                // clusterSettings();
                                                self.showDocs();
                                            }
                                        }
                    */
                ];

                // ----------------- EVENTS -----------------
                let labelClick = false;
                let $chart = document.querySelector('#clusterMapChart'),
                    $poly = document.querySelector("#polySelection");

                $chart.addEventListener('mouseover', function (e) {
                    let isText = e.target && e.target.tagName === 'text',
                        isCircle = e.target.tagName === 'circle' && !self.showLabels;
                    if (isText || isCircle) {
                        let _id = isCircle ? self.getIdFromLabel(e.target.parentNode)
                            : self.getIdFromLabel(e.target.parentNode);
                        self.timeout.mouseOver = setTimeout(() => {
                            self.showTooltip({
                                id: _id,
                                coords: {
                                    x: e.clientX,
                                    y: e.clientY
                                }
                            })
                        }, 777)
                    }
                })
                $chart.addEventListener('mouseout', function (e) {
                    let isText = e.target && e.target.tagName === 'text',
                        isCircle = e.target.tagName === 'circle' && !self.showLabels;
                    if (isText || isCircle) {
                        if (self.timeout.mouseOver) {
                            clearTimeout(self.timeout.mouseOver);
                        }
                        setTimeout(() => {
                            self.tooltip.show = false;
                        }, 300)

                    }
                });
                $chart.addEventListener('click', function (e) {
                    if (e.target && e.target.tagName === 'text') {
                        let _id = self.getIdFromLabel(e.target.parentNode);
                        self.selectCluster(_id);
                        labelClick = true;
                    }
                    self.$refs.reportSelect.blur();
                });

                d3.select("#clusterMapChart").on("click", function () {
                    if (self.curPointCoords && !labelClick) {
                        self.selectCluster(self.curPointCoords.cluster);
                    }
                    labelClick = false;
                })
                d3.select("#polySelection").on('mousemove', event => {
                    let pos = d3.mouse(self.polySelection.svg.node()),
                        pS = self.polySelection,
                        cP = self.chartProps,
                        zoom = cP.zoomScale,
                        pointId = pS.movingPointId,
                        x = self.chartProps.xScale.invert(pos[0]),
                        y = self.chartProps.yScale.invert(pos[1]);


                    self.polySelection.curPos = {
                        x: x,
                        y: y
                    }
                    if (pS.inProgress) {
                        pS.tick++;
                        let dx = Math.abs(x - pS.startPoint.x),
                            dy = Math.abs(y - pS.startPoint.y);
                        // if (pS.tick > 30
                        // && dx < (.3 / (zoom / 1.1))
                        // && dy < (.3 / (zoom / 1.1))) {
                        // pS.tick = 0;
                        // self.completeSelection();
                        // } else if (pS.tick % 3 === 0) {
                        if (pS.tick % 3 === 0) {
                            console.log(`{x: ${pos[0]}, y: ${pos[1]}}`);
                            pS.points.push(pS.curPos);
                            self.plotPoly();
                        }
                    }

                    // console.log(pos);

                });
                document.addEventListener('keydown', function (e) {
                    let pS = self.polySelection;
                    if (e.key === 'Control') {
                        self.ctrlDown = true;
                    } else if (e.key === 'Escape') {
                        if (pS.points.length && !self.isFullScreen) {
                            self.resetPolySelection({redraw: true});
                        }
                        self.isFullScreen = false;
                        self.exportSVG = false;
                        self.plotDocsTooltip();
                        self.plotChart();
                    }
                })
                document.addEventListener('keyup', function (e) {
                    if (e.key === 'Control') {
                        self.ctrlDown = false;
                    }
                })
                $poly.addEventListener("mousemove", e => {
                    let self = this,
                        pS = self.polySelection;
                    pS.curPosNative = {
                        x: e.pageX,
                        y: e.pageY,
                    }
                });
                $chart.addEventListener("contextmenu", e => {
                    e.preventDefault();
                })
                $poly.addEventListener("click", e => {
                    e.preventDefault();
                    let pS = self.polySelection;
                    if (pS.show) {
                        if (Array.from(e.target.parentNode.classList).includes('showDocsTooltip')) {
                            self.showDocs({mode: 'selectedDocs'})
                        } else {
                            if (pS.inProgress) {
                                pS.tick = 0;
                                self.completeSelection();
                            } else {
                                self.resetPolySelection();
                                pS.inProgress = true;
                                pS.startPoint = {
                                    x: pS.curPos.x,
                                    y: pS.curPos.y,
                                }
                            }
                        }
                    }
                    /*
                    if (pS.show && !pS.movingPointId) {
                        if (e.target.tagName !== 'circle') {
                            let _near = []
                            pS.points.forEach((it, ind) => {
                                let A = it,
                                    B = pS.points[ind < (pS.points.length - 1) ? (ind + 1) : 0];
                                _near.push({
                                    key: ind,
                                    A: A,
                                    B: B,
                                    val: self.$utils.pointDistance(pS.curPos.x, pS.curPos.y, A.x, A.y, B.x, B.y)
                                })
                            })
                            let _newInd = (_.orderBy(_near, 'val', 'asc')[0] || {}).key + 1;
                            console.info('near', _near, _newInd)

                            if (pS.points.length > 3) {
                                pS.points.splice(_newInd, 0, pS.curPos);
                            } else {
                                pS.points.push(pS.curPos);
                            }
                            // pS.points.push(pS.curPos);
                            self.plotPoly();
                            // console.log('polyPoints:', pS.points);
                            if (pS.points.length > 2) {
                                self.fillPolySelection();
                            }
                        }
                    }
                    */

                });
                /*
                                document.querySelector(".chart-container")
                                    .addEventListener("click", e => {
                                        e.preventDefault();
                                        // console.log('e', e.target);
                                        let pS = self.polySelection,
                                            _points = Array.from(document.querySelectorAll('#polySelection circle'));
                                        if (e.target.tagName === 'circle') {
                                            if (pS.movingPointId) {
                                                pS.movingPointId = null;
                                                 _points.forEach(it => {
                                                     it.classList.remove('grabbing');
                                                 })
                                                self.fillPolySelection();
                                            } else {
                                                let id = (e.target.dataset || {}).id;
                                                if (id) {
                                                    pS.movingPointId = id;
                                                     _points[id].classList.add('grabbing');
                                                    // console.log('id: ', id);
                                                }
                                            }
                                        }
                                    })
                                    */
                $poly.addEventListener("contextmenu", e => {
                    e.preventDefault();
                    self.resetPolySelection({redraw: true});
                    // let pS = self.polySelection;
                    // if (e.target.tagName === 'circle') {
                    //     let id = (e.target.dataset || {}).id;
                    //     if (id) {
                    //         pS.points.splice(id, 1);
                    //         self.plotPoly();
                    //         self.fillPolySelection();
                    //
                    //     }
                    // }
                })

                const pointer = fc.pointer().on("point", ([coord]) => {

                    if (!coord || !self.quadtree) {
                        return;
                    }

                    const x = self.chartProps.xScale.invert(coord.x);
                    const y = self.chartProps.yScale.invert(coord.y);
                    const radius = Math.abs(self.chartProps.xScale.invert(coord.x) - self.chartProps.xScale.invert(coord.x - 20));
                    const closestDatum = self.quadtree.find(x, y, radius);

                    // if the closest point is within 20 pixels, show the annotation
                    if (closestDatum) {
                        self.curPointCoords = _.clone(closestDatum);
                        // annotations[0] = createAnnotationData(closestDatum);
                    } else {
                        self.curPointCoords = {};
                    }

                    // self.redraw();
                });

                self.chartProps.chart = fc
                    .chartCartesian(self.chartProps.xScale, self.chartProps.yScale)
                    .webglPlotArea(
                        fc
                            .seriesWebglMulti()
                            .series([self.chartProps.pointSeries])
                            .mapping(d => d.data)
                    )
                    .svgPlotArea(
                        fc
                            .seriesSvgMulti()
                            .series([labels])
                            .mapping((data, index, series) => {
                                let labelsData = self.showClusterIds.length ? self.labelsData.filter((val, key) => self.showClusterIds.includes(val.id))
                                    : self.labelsData;

                                switch (series[index]) {
                                    case labels:
                                        return labelsData;
                                    default:
                                        return labelsData;
                                }
                            })
                    )

                    .decorate(sel =>
                        sel
                            .enter()
                            .select("d3fc-svg.plot-area")
                            .on("measure.range", () => {
                                self.chartProps.xScaleOriginal.range([0, d3.event.detail.width]);
                                self.chartProps.yScaleOriginal.range([d3.event.detail.height, 0]);
                            })
                            // .on('contextmenu', d3.contextMenu(self.contextMenu))
                            .call(self.chartProps.zoom)
                            .call(pointer)
                    );
            },
            getData() {
                let self = this;
                if (self.dataSourceId) {
                    self.loading = true;
                    self.selectedCluster.show = false;
                    self.searchFilter.query = '';
                    self.totalFound = 0;
                    self.sourceParams = self.dataSourceList.find(it => it.id === self.dataSourceId);
                    self.isAllowedToEdit = self.sourceParams.allowedActions.includes('CLUSTER_EDIT');
                    self.$services.clusteringReport.open({id: self.dataSourceId}).then(resp => {
                        let json = resp.data;
                        self.clusterUserName = {};
                        self.data = json.points.map((it, i) => {
                            return {
                                cluster: it[2],
                                id: i,
                                x: it[0],
                                y: it[1]
                            }
                        });
                        self.curYear = parseInt(moment((self.sourceParams.dateCreate || '2021')).format('YYYY'));
                        self.clusterData = json.clusters;
                        (json.clusterUserData || []).forEach(it => {
                            self.clusterUserName[it.id] = it.name;
                        });

                        if (json.extrapolation && json.extrapolation.year == self.curYear) {
                            self.extrapolation = json.extrapolation.factor;
                        } else {
                            self.extrapolation = 1.5;
                        }

                        if (self.curYear > self.lastYear) { // ----- Временный параметр, пока год не наберет документов
                            self.extrapolation = 1;
                        }

                        self.blackList = json.blackList || [];
                        self.initialData = _.cloneDeep(self.data);
                        if (self.clusterData.length < 1) {
                            /// --- No Clusters ---
                            self.showNoCluster = true;
                            self.noNoClustersData = self.data;
                        } else if (!self.showNoCluster) {
                            // } else {
                            self.data = self.noNoClustersData = self.data.filter(it => it.cluster >= 0);
                        }

                        if (!self.clusterData.some(it => it.yearCounts)) {
                            self.noYearCount = true;
                            self.colorScheme = 'byCluster';
                        } else {
                            self.noYearCount = false;
                        }
                        // console.log('current source', self.sourceParams);

                        self.data = self.showData = self.blackListedData = self.data.filter(it => !self.blackList.includes(it.cluster));
                        self.prepareData(self.data);
                        setTimeout(() => {
                            let $labels = document.querySelectorAll('g.label');
                            _.forEach($labels, label => {
                                label.classList.remove('faded');
                            })
                        }, 500)
                    });
                } else {
                    self.$emit('showList');
                }
            },
            getClusters(data) {
                let self = this,
                    _cls = _.sortBy(_.uniq(_.map(data, 'cluster'))),
                    numDocs = {},
                    growLY = [],
                    clusterCenter = {},
                    cStep = [],
                    _out = {};
                let isByCluster = self.colorScheme === 'byCluster',
                    isAvg = self.colorScheme === 'byGrowthAvg',
                    min, max;
                self.labelsData = [];
                self.totalDocs = self.data.length;
                if (self.clusterData.length) {
                    // console.log('Extrapolation:', self.extrapolation);
                    _.forEach(_cls, it => {
                        let cl = self.clusterData.find(cl => cl.id === it);
                        if (cl) {
                            self.sourceParams.gotYearCounts = !!cl.yearCounts;
                            cl.yearCounts = cl.yearCounts || [];
                            if (self.isSearch__Clusters) {
                                let _val = self.searchFilter.result.clusters[it];
                                growLY[it] = _.isUndefined(_val) ? _val : _val;
                            } else {
                                for (let ii = self.curYear - 4; ii <= self.curYear; ii++) {
                                    if (!cl.yearCounts[ii]) {
                                        cl.yearCounts[ii] = 0
                                    }
                                }
                                let _curYear = self.curYear > self.lastYear ? self.lastYear : self.curYear, // --- Временный параметр, пока год не наберет документов
                                    denominator = cl && cl.yearCounts ?
                                        (isAvg ? (((cl.yearCounts[_curYear - 1] || 0) + (cl.yearCounts[_curYear - 2] || 0) + (cl.yearCounts[_curYear - 3] || 0)) / 3)
                                            : (cl.yearCounts[_curYear - 1] || 1))
                                        : 1;

                                growLY[it] = (cl && cl.yearCounts ? (self.extrapolation * (cl.yearCounts[_curYear] || 0) / (denominator || 1) - 1) * 100 : 0);
                            }
                        }
                    });
                    max = self.growthRange.max = self.growthRange.range[1] = Math.ceil(_.max(growLY));
                    min = self.growthRange.min = self.growthRange.range[0] = Math.floor(_.min(growLY));
                    self.growthRange.marks = {};
                    self.growthRange.marks[max] = max + (!isByCluster ? '%' : '');
                    self.growthRange.marks[0] = "0";
                    self.growthRange.marks[min] = min + (!isByCluster ? '%' : '');
                    cStep = self.createGradArr(min, max);
                    // console.log('gradArr:', cStep);

                    _.forEach(_cls, it => {
                        let thisClusterData = data.filter(cl => cl.cluster === it) || [],
                            gradient = '',
                            clusterInfo = self.clusterData.find(cl => cl.id === it) || {};
                        if (!isByCluster || self.isSearch__Clusters) {
                            let _value = growLY[it],
                                _palette = self.isSearch ? self.searchFilter.palette : self.legend.palette,
                                _lightness = 1 - (Math.abs(1 - _value));
                            if (_.isUndefined(_value)) {
                                gradient = '#000';
                            } else if (_value > cStep[1]) {
                                gradient = _palette[0]
                            } else if (_value > cStep[2]) {
                                gradient = _palette[1]
                            } else if (_value > cStep[3]) {
                                gradient = _palette[2]
                            } else if (_value > cStep[5]) {
                                gradient = _palette[3]
                            } else if (_value > cStep[6]) {
                                gradient = _palette[4]
                            } else if (_value > cStep[7]) {
                                gradient = _palette[5]
                            } else {
                                gradient = _palette[6]
                            }
                        }
                        let xs = _.map(thisClusterData, 'x'),
                            ys = _.map(thisClusterData, 'y');
                        let _color = gradient ? gradient : self.$utils.getColor(it),
                            // _color = self.$utils.getColor(it),
                            _keywords = clusterInfo && clusterInfo.keywords ? clusterInfo.keywords : [],
                            _name = self.clusterUserName[it] ? self.clusterUserName[it]
                                : clusterInfo && clusterInfo.keywords && clusterInfo.keywords.length ? clusterInfo.keywords[0].value
                                    : 'No cluster';
                        numDocs[it] = self.isSearch__Clusters ? growLY[it] : thisClusterData.length;
                        // clusterCenter[it] = [_.mean(xs), _.mean(ys)];
                        clusterCenter[it] = [self.$utils.median(xs), self.$utils.median(ys)];
                        if (clusterCenter[it] && it >= 0) {
                            self.labelsData.push({
                                "x": clusterCenter[it][0],
                                "y": clusterCenter[it][1],
                                "label": _.upperFirst(_name),
                                'id': it
                                // "label": it + '.' + _.upperFirst(_name)
                                // "label": _.upperFirst(_name) + ' (' + it + ')'
                                // "label": 'Cluster #' + it + ' ' || 'No cluster '
                            });
                        }

                        _out[it] = {
                            color: it >= 0 ? _color : self.noClusterColor,
                            name: _.upperFirst(_name),
                            title: 'Cluster ' + it,
                            numDocsFormatted: self.$utils.toFin(numDocs[it]),
                            numDocs: parseInt(numDocs[it]) || 0,
                            numDocsPrc: self.$utils.roundX(parseInt(numDocs[it]) / self.totalDocs * 100, 2) || 0,
                            clusterCenter: clusterCenter[it],
                            growthLastYear: growLY[it],
                            growthFormatted: self.$utils.roundX(growLY[it], 0, 2),
                            yearCounts: clusterInfo.yearCounts ? clusterInfo.yearCounts : [],
                            selected: !self.isSearch__Points || self.searchFilter.result.clusterIds.includes(it),
                            blocked: self.isSearch__Points && !self.searchFilter.result.clusterIds.includes(it),
                            show: true,
                            keywords: _keywords,
                            desc: '',
                            id: it,
                        }
                    });
                } else {
                    /// --- No Clusters ---
                    let xs = _.map(self.data, 'x'),
                        ys = _.map(self.data, 'y');
                    let clusterCenter = [self.$utils.median(xs), self.$utils.median(ys)];
                    self.labelsData.push({
                        "x": clusterCenter[0],
                        "y": clusterCenter[1],
                        "label": 'No cluster (-1)'
                    });

                    _out = [{
                        id: -1,
                        name: 'No cluster',
                        color: self.noClusterColor,
                        selected: true,
                        numDocs: (self.data.points || []).length
                    }]
                }
                // console.log('clusters: ', _out);
                if (!self.isByCluster) {
                    _out = _.orderBy(_out, 'growthLastYear', 'desc');
                } else if (self.isSearch__Clusters) {
                    _out = _.orderBy(_out, 'numDocs', 'desc');
                }
                return _out;
            },
            getCheckedClusters() {
                let self = this,
                    $unchecked = _.filter(self.clusters, it => !it.selected) || [],
                    $checked = _.filter(self.clusters, it => it.selected) || [];


                let isCheckedAll = $unchecked.length === 0,
                    isUnCheckedAll = $checked.length === 0;

                // self.contextMenu[0].disabled = isCheckedAll;
                // contextMenu[3].disabled = isUnCheckedAll;
                // _.forEach(self.clusters, it => {
                //     it.selected = isCheckedAll ? true
                //         : isUnCheckedAll ? false
                //             : it.selected
                // });
                let range = self.growthRange;

                $checked = $checked.filter(it => {
                    let _val = self.isSearch__Clusters ? it.numDocs : it.growthLastYear,
                        rez = (self.isByCluster && !self.isSearch__Clusters)
                            || (!_.isUndefined(_val) && !_.isNaN(_val) && _val >= range.range[0]
                                && _val <= range.range[1]);

                    return rez;
                });

                let selectedClusterList = _.map($checked, 'id');

                return selectedClusterList;
            },
            selectCluster(id = null) {
                let self = this,
                    isId = !!(id || id == 0),
                    _cluster = {},
                    isSearch = self.isSearch__Points && !!self.totalFound,
                    clickedCluster = _.find(self.clusters, it => it.id == (isId ? id : self.curPointCoords.cluster)),
                    _id = clickedCluster ? clickedCluster.id : null;
                // clusterInfo = self.clusterData.find(it => it.id === _id);
                self.tooltip.show = false;
                self.selectedCluster.isEditing = false;
                self.selectedCluster.type = 'clusters';
                self.loadingKw = true;
                if (clickedCluster) {
                    if (!self.ctrlDown) {
                        _.forEach(self.clusters, it => {
                            it.selected = false;
                        });
                    }
                    clickedCluster.selected = !clickedCluster.selected;
                } else if (!self.ctrlDown) {
                    _.forEach(self.clusters, it => {
                        it.selected = !it.blocked;
                    });
                }
                let $unchecked = _.filter(self.clusters, it => !it.selected) || [];
                let isAll = self.selectAll = $unchecked.length === 0;

                // if ((clusterInfo && from !== 'control') || (clusterInfo && $($thisCheck[0]).hasClass('checked'))) {
                self.resetSelectedClusterCard();

                let $checked = _.filter(self.clusters, it => it.selected) || [];

                if ($checked.length > 0 && !isAll) {
                    self.selectedCluster.show = true;
                    let isSearch = self.isSearch,
                        sC = self.searchFilter.result.clusters,
                        _kw = [],
                        _ids = [],
                        _grow = 0,
                        _num = 0,
                        _yc = {};
                    // console.log('results:', self.searchFilter.result);
                    _.forEach($checked, it => {
                        _kw = _kw.concat(it.keywords.slice(0, Math.ceil(10 / $checked.length)));
                        _num += isSearch ? (sC[it.id] || {}).size || 0 : parseInt(it.numDocs);
                        _grow += parseInt(it.growthLastYear);
                        _ids.push(it.id);
                        for (let ii = self.curYear - 3; ii <= self.curYear; ii++) {
                            if (isSearch) {
                                _yc[ii] = parseInt(_yc[ii] || 0) + (((sC[it.id] || {}).yearCounts || {})[ii] || 0)
                            } else {
                                _yc[ii] = parseInt(_yc[ii] || 0) + (it.yearCounts[ii] || 0)
                            }
                        }
                    });
                    _cluster.numDocs = _num;
                    let _prc = _num / self.totalDocs * 100;
                    _cluster.numDocsPrc = self.$utils.roundX(_prc, 2, 2);
                    _cluster.numDocsFormatted = self.$utils.toFin(_num);
                    let ycData = _.map((_yc || []), (it, i) => {
                        return {
                            year: i,
                            value: it
                        };
                    }).slice(-4);
                    let _max = _.max(_.map(ycData, 'value'));
                    if (ycData && ycData.length) {
                        ycData[ycData.length - 1].value = self.$utils.roundX(self.extrapolation * ycData[ycData.length - 1].value, 0);
                    }

                    _cluster.keywords = _.orderBy(_kw, 'score', 'desc');
                    self.selectedCluster.combinedName = _ids.length > 1 ? "Clusters: " + _ids.join(', ') : '';
                    self.selectedCluster.name = self.clusterUserName[_ids[0]] || _.upperFirst((_cluster.keywords[0] || {}).value) || 'No cluster';
                    self.selectedCluster.id = _ids[0];
                    self.selectedCluster.ids = _ids;
                    self.selectedCluster.docs = _num && _cluster.numDocsFormatted ? _cluster.numDocsFormatted + ' <span class="small">' + _cluster.numDocsPrc + '%</span>' : 0;
                    self.selectedCluster.growthLastYear = self.$utils.roundX(_grow / $checked.length, 1);
                    self.selectedCluster.keywords = isSearch ? [] : _.map(_cluster.keywords, 'value');
                    self.selectedCluster.yearCounts = ycData.map(it => {
                        it.chartHeight = it.value / _max * 60 + 2;
                        it.value = self.$utils.toFin(it.value);
                        return it;
                    });

                    if (self.searchFilter.query) {
                        // self.showDocs({mode:'list'});
                    }

                } else if (_id < 0) {
                    self.selectedCluster.show = true;
                    self.selectedCluster.name = 'No cluster';
                    self.selectedCluster.docs = (_cluster || {}).numDocsFormatted || 0;
                    self.selectedCluster.growthLastYear = _cluster ? self.$utils.roundX(_cluster.growthLastYear, 1) : 0;
                } else {
                    self.selectedCluster.show = false;
                }

                setTimeout(() => {
                    self.contextMenu[0].title = self.selectAll ? 'Deselect All' : 'Select All';
                }, 150);
                self.loadingKw = isSearch;

                self.plotChart();
            },
            plotPoly() {
                let self = this,
                    pS = self.polySelection,
                    _points = pS.pointsScaled = pS.points.map((d) => [self.chartProps.xScale(d.x), self.chartProps.yScale(d.y)]),
                    _hull = d3.polygonHull(_points),
                    // _line = d3.line().curve(d3.curveLinearClosed);
                    _line = d3.line().curve(pS.inProgress ? d3.curveNatural : d3.curveLinearClosed);
                pS.svg
                    .selectAll("g.polySelect").remove();

                let poly = pS.svg.append('g')
                    .classed('polySelect', true);

                if (pS.points.length > 2) {
                    poly
                        .append("path")
                        // .attr("d", _line(pS.inProgress  ? _points : _hull))
                        .attr("d", _line(_points))
                        .attr('stroke', 'rgba(255,255,255,.95)')
                        .style("stroke-dasharray", ("3, 3"))
                        .style("stroke-width", 1)
                        .attr('fill', pS.inProgress ? 'rgba(255,255,255,.05)' : 'rgba(255,255,255,.25)');
                }

                if (pS.inProgress) {
                    poly
                        .append('circle')
                        .attr('cx', _points[0][0])
                        .attr('cy', _points[0][1])
                        .attr('r', 3)
                        .attr('fill', '#000')
                        .attr('stroke', '#fff')
                        .style("stroke-width", 2);
                    // _points.forEach((it, ind) => {
                    //     pS.svg
                    //         .append('circle')
                    //         .attr('cx', it[0])
                    //         .attr('cy', it[1])
                    //         .attr('r', (ind === 0 ? '2' : '1'))
                    //         // .attr('data-id', ind)
                    //         .attr('fill', (ind === 0 ? '#000' : 'rgba(255,255,255,.5)'))
                    //         // .classed('grabbing', pS.movingPointId == ind)
                    //         .attr('stroke', (ind === 0 ? '#fff' : 'transparent'))
                    //         .style("stroke-width", (ind === 0 ? 3 : 1));
                    // });
                }
                // console.info('==  plotPoly ==');
            },
            plotDocsTooltip() {
                let self = this,
                    pS = self.polySelection,
                    zoom = self.chartProps.zoomScale,
                    $copy = "M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z",
                    $clock = "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z",
                    $hourglass = "M360 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24 0 90.965 51.016 167.734 120.842 192C75.016 280.266 24 357.035 24 448c-13.255 0-24 10.745-24 24v16c0 13.255 10.745 24 24 24h336c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24 0-90.965-51.016-167.734-120.842-192C308.984 231.734 360 154.965 360 64c13.255 0 24-10.745 24-24V24c0-13.255-10.745-24-24-24zm-75.078 384H99.08c17.059-46.797 52.096-80 92.92-80 40.821 0 75.862 33.196 92.922 80zm.019-256H99.078C91.988 108.548 88 86.748 88 64h208c0 22.805-3.987 44.587-11.059 64z",
                    icon = pS.docs.loading ? $hourglass : $copy;

                if (pS.numSelected && pS.docs.showBtn && !self.isFullScreen) {
                    pS.svg.selectAll(".showDocsTooltip").remove();

                    let avgX = _.mean(pS.pointsScaled.map(it => it[0])),
                        maxX = _.max(pS.pointsScaled.map(it => it[0])),
                        avgY = _.mean(pS.pointsScaled.map(it => it[1])),
                        x = maxX,
                        y = avgY / 1.2,
                        width = pS.numSelected.toString().length * 6.5 + 32,
                        btn = pS.svg.append('g')
                            .classed('showDocsTooltip', true)
                            .attr("transform", "translate(" + x + ", " + y + ")")

                    btn.append('rect')
                        .attr('width', width)
                        .attr('height', 29)
                        .attr('fill', '#454748')
                        .attr('stroke', ' #b2b2b2')
                        .style("stroke-width", 1);

                    btn.append("text")
                        .attr("x", 25)
                        .attr("y", 19)
                        .text(pS.numSelected)
                        .style('fill', '#b2b2b2')
                        .style('font-size', '11px');

                    btn.append('path')
                        .attr('d', icon)
                        .attr('transform', 'scale(.03) translate(200, 220)')
                        .style('fill', '#b2b2b2')

                }

            },
            polySelected(p, point) {
                let self = this,
                    result = false,
                    size = p.length,
                    dx = 0,
                    dy = 0,
                    j = size - 1;
                for (let i = 0; i < size; i++) {
                    if (((p[i].y) < point.y && (p[j].y) >= point.y || (p[j].y) < point.y && (p[i].y) >= point.y) &&
                        ((p[i].x) + (point.y - (p[i].y)) / (p[j].y - p[i].y) * (p[j].x - p[i].x) < point.x)) {
                        result = !result;
                    }
                    j = i;
                }
                return result;
            },
            completeSelection() {
                let self = this,
                    pS = self.polySelection;
                pS.inProgress = false;
                self.polySelection.tick = 0;

                pS.docs.showBtn = true;
                self.plotPoly();

                pS.startPoint = {
                    x: 0,
                    y: 0,
                }

                setTimeout(() => {
                    self.fillPolySelection();
                    if (pS.selected) {
                        let foundBitSet = self.searchFilter.result.bitset;
                        if (foundBitSet.get && self.totalFound) {
                            pS.selected = pS.selected.filter(it => foundBitSet.get(it))
                            pS.numSelected = self.$utils.toFin(pS.selected.length);
                        }
                        self.plotDocsTooltip();
                        self.annotateRegion(pS.selected)
                    }
                }, 85)
            },
            annotateRegion(points, field = '') {
                let self = this,
                    pS = self.polySelection;
                // console.log('points', points);
                self.createBitSet(points).then(bitSet => {
                    let _num = pS.selected.length,
                        _prc = _num / self.totalDocs * 100,
                        _numDocsPrc = self.$utils.roundX(_prc, 2, 2),
                        isByCluster = self.colorScheme === 'byCluster',
                        isAvg = self.colorScheme === 'byGrowthAvg';

                    pS.selectedBase64 = self.$utils.toBase64(bitSet);

                    if (field !== 'keywordsOnly') {
                        self.resetSelectedClusterCard();
                        self.selectedCluster.combinedName = 'Selected area';
                        self.selectedCluster.docs = _num ? self.$utils.toFin(_num) + ' <span class="small">' + _numDocsPrc + '%</span>' : 0;
                        self.selectedCluster.type = 'area';
                        self.selectedCluster.show = true;
                    }
                    if (pS.selectedBase64) {
                        self.$services.clusteringReport.annotateRegion({
                            reportId: self.sourceParams.id,
                            points: pS.selectedBase64
                        }).then(resp => {
                            if (field !== 'keywordOnly') {
                                let _yc = {};
                                for (let ii = self.curYear - 3; ii <= self.curYear; ii++) {
                                    _yc[ii] = parseInt(_yc[ii] || 0) + (resp.data.yearCounts[ii] || 0)
                                }
                                let ycData = _.map((_yc || []), (it, i) => {
                                        return {
                                            year: i,
                                            value: it
                                        };
                                    }).slice(-4),
                                    _curYear = self.curYear > self.lastYear ? self.lastYear : self.curYear, // --- Временный параметр, пока год не наберет документов
                                    denominator = _.size(_yc) ?
                                        (isAvg ? (((_yc[_curYear - 1] || 0) + (_yc[_curYear - 2] || 0) + (_yc[_curYear - 3] || 0)) / 3)
                                            : (_yc[_curYear - 1] || 1))
                                        : 1,
                                    growLY = (_.size(_yc) ? (self.extrapolation * (_yc[_curYear] || 0) / (denominator || 1) - 1) * 100 : 0);

                                let _max = _.max(_.map(ycData, 'value'));
                                if (ycData && ycData.length) {
                                    ycData[ycData.length - 1].value = self.$utils.roundX(self.extrapolation * ycData[ycData.length - 1].value, 0);
                                }
                                self.selectedCluster.growthLastYear = growLY ? self.$utils.roundX(growLY, 1) : 0;
                                self.selectedCluster.yearCounts = ycData.map(it => {
                                    it.chartHeight = it.value / _max * 60 + 2;
                                    it.value = self.$utils.toFin(it.value);
                                    return it;
                                });
                            }
                            let keywords = _.orderBy(resp.data.keywords, 'score', 'desc');
                            self.selectedCluster.keywords = _.map(keywords, 'value');
                            self.loadingKw = false;

                        });
                    }
                });

            },
            createBitSet(array) {
                let self = this;
                return axios.post(`${self.$store.state.apiUrl}/test/bitSetAsBinary`, array, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/octet-stream'
                    }
                }).then(resp => {
                    const bitSet = self.$utils.toBitset(new Uint32Array(resp.data));
                    return bitSet;
                })
            },
            handleCheckCluster(item) {
                // item.selected = !item.selected;
                this.selectCluster(item.id);
            },
            toggleShowCluster(item) {
                item.show = !item.show;
                let self = this,
                    clusterIds = self.showClusterIds = _.map(_.filter(self.clusters, cl => cl.show), 'id');
                self.showData = self.data.filter(it => {
                    return clusterIds.includes(it.cluster);
                });
                self.plotChart();
            },
            handleToggleAll(state) {
                let self = this;
                self.selectAll = typeof state !== 'undefined' ? state : !self.selectAll;
                _.forEach(self.clusters, it => {
                    it.selected = self.selectAll;
                });
                this.contextMenu[0].title = self.selectAll ? 'Deselect All' : 'Select All';
                self.selectedCluster.show = false;
                self.plotChart();
            },
            handleShowNoClusters() {
                this.loading = true;
                this.resetPolySelection();
                setTimeout(() => {
                    this.data = this.showData = _.cloneDeep(this.showNoCluster ? this.initialData : this.noNoClustersData);
                    this.prepareData(this.data);
                }, 250)
            },
            handleShowBlackList() {
                let self = this;
                self.loading = true;
                setTimeout(() => {
                    self.data = _.cloneDeep(self.showBlackList ? self.noNoClustersData : self.blackListedData);
                    self.prepareData(self.data);
                }, 250)
            },
            toggleFullScreen() {
                let self = this,
                    pS = self.polySelection;
                self.isFullScreen = !self.isFullScreen;
                self.contextMenu[2].title = self.isFullScreen ? 'Full screen OFF' : 'Full screen ON';
                if (self.isFullScreen) {
                    // self.$utils.requestFullScreen();
                    self.$message('Press ESC to exit full screen mode.');
                    // pS.docs.showBtn = false;
                    pS.svg.selectAll(".showDocsTooltip").remove();
                } else {
                    self.plotDocsTooltip();
                }
                self.plotChart();
            },
            resetSVG(theme = 'dark') {
                let self = this, cP = self.chartProps;
                const gs = document.querySelectorAll("#exportSVG *");
                _.forEach(gs, g => {
                    g.parentElement.removeChild(g);
                })
                let fontColor = theme === 'dark' ? '#fff' : '#262626',
                    shadowColor = theme === 'dark' ? '#262626' : '#fff',
                    bgColor = theme === 'dark' ? '#262626' : '#fff';
                let style = document.createElementNS(self.svgNS, "style");
                let css = `
                    #exportSVG {
                        background: ${bgColor};
                        width: 100vw;
                        height: 100vh;
                    }
                    #exportSVG g.labels > text {
                        fill: ${fontColor};
                        font-size: 16px;
                        font-family: sans-serif;
                        font-weight: 600;
                        cursor: default;
                        text-shadow: -1px -1px 0 ${shadowColor}, 1px -1px 0 ${shadowColor}, -1px 1px 0 ${shadowColor}, 1px 1px 0 ${shadowColor};
                    }
                    #exportSVG g.labels > text.faded {
                        opacity: 0.38;
                        font-weight: 300;
                    }`
                document.getElementById("exportSVG").appendChild(style);
                style.type = 'text/css';
                if (style.styleSheet) {
                    style.styleSheet.cssText = css;
                } else {
                    style.appendChild(document.createTextNode(css));
                }

                cP.pointGroup = document.createElementNS(self.svgNS, "g");
                cP.pointGroup.setAttributeNS(null, "id", 'points');
                document.getElementById("exportSVG").appendChild(self.chartProps.pointGroup);

                cP.labelGroup = document.createElementNS(self.svgNS, "g");
                cP.labelGroup.setAttributeNS(null, "class", 'labels');
                document.getElementById("exportSVG").appendChild(self.chartProps.labelGroup);

            },
            handleExportSVG(theme) {
                let self = this,
                    _clusters = _.filter(self.clusters, cl => cl.show),
                    clusterCenter = {},
                    clusterIds = _.map(_clusters, 'id'),
                    data = self.data.filter(it => {
                        return clusterIds.includes(it.cluster);
                    }),
                    xs = _.map(data, 'x'),
                    ys = _.map(data, 'y'),
                    maxX = Math.ceil(_.max(xs) + 1),
                    minX = Math.ceil(_.min(xs) - 1),
                    maxY = Math.ceil(_.max(ys) + 1),
                    minY = Math.ceil(_.min(ys) - 2),
                    cP = self.chartProps;

                _.forEach(clusterIds, it => {
                    let thisClusterData = data.filter(cl => cl.cluster === it) || [],
                        xs = _.map(thisClusterData, 'x'),
                        ys = _.map(thisClusterData, 'y');
                    clusterCenter[it] = [self.$utils.median(xs), self.$utils.median(ys)];
                })

                self.exportSVG = true;
                // console.log('clusters', self.clusters);
                self.chartProps.scrWidth = window.innerWidth;
                self.chartProps.scrHeight = window.innerHeight;
                self.resetSVG(theme);
                // myCircle.setAttributeNS(null, "viewBox", `0 0 ${} ${}`);
                if (theme === 'dark') {
                    self.createSVGRect('bg', 0, 0, '100%', '100%', '#262626');
                }
                data.forEach(point => {
                    let cluster = _.find(self.clusters, it => it.id === point.cluster),
                        cx = (point.x - minX) * cP.scrWidth / (maxX - minX),
                        cy = cP.scrHeight - (point.y - minY) * cP.scrHeight / (maxY - minY),
                        fill = cluster.selected ? cluster.color : self.fadeClusterColor,
                        id = 'point' + point.id,
                        r = 2;
                    this.createSVGCircle(id, cx, cy, r, fill);
                });
                _.forEach(_clusters, cluster => {
                    let x = (clusterCenter[cluster.id][0] - minX) * cP.scrWidth / (maxX - minX),
                        y = cP.scrHeight - (clusterCenter[cluster.id][1] - minY) * cP.scrHeight / (maxY - minY),
                        // fill = cluster.selected ? "#fff" : '#888',
                        cssClass = cluster.selected ? "" : 'faded',
                        fill = null,
                        id = 'point' + cluster.id;
                    this.createSVGText(id, x, y, fill, cluster.name, cssClass);

                });
                setTimeout(() => {
                    self.saveSVGFile(theme);
                    self.exportSVG = self.user.username === 'watson';
                }, 333)
            },
            saveSVGFile(theme) {
                let self = this;
                let svgData = document.querySelector('#exportSVG').outerHTML;
                let svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});
                let svgUrl = URL.createObjectURL(svgBlob);
                let downloadLink = document.createElement("a");
                downloadLink.href = svgUrl;
                downloadLink.download = "Export_Cluster_"
                    + self.sourceParams.name.replace(/\s/g, '_')
                    // + "_" + moment().format('YYYY-MM-DD-HH-mm')
                    + " (" + theme + ")"
                    + ".svg";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },
            createSVGCircle(id, cx, cy, r, fill) {
                let self = this,
                    myCircle = document.createElementNS(self.svgNS, "circle");
                myCircle.setAttributeNS(null, "id", id);
                myCircle.setAttributeNS(null, "cx", cx);
                myCircle.setAttributeNS(null, "cy", cy);
                myCircle.setAttributeNS(null, "r", r);
                myCircle.setAttributeNS(null, "fill", fill);
                myCircle.setAttributeNS(null, "stroke", "none");

                // document.getElementById("exportSVG").appendChild(myCircle);
                self.chartProps.pointGroup.appendChild(myCircle);
            },
            createSVGRect(id, x, y, w, h, fill) {
                let self = this,
                    $elem = document.createElementNS(self.svgNS, "rect");
                $elem.setAttributeNS(null, "id", id);
                $elem.setAttributeNS(null, "x", x);
                $elem.setAttributeNS(null, "y", y);
                $elem.setAttributeNS(null, "width", w);
                $elem.setAttributeNS(null, "height", h);
                $elem.setAttributeNS(null, "fill", fill);

                // document.getElementById("exportSVG").appendChild($elem);
                self.chartProps.pointGroup.appendChild($elem);
            },
            createSVGText(id, x, y, fill = null, text, cssClass) {
                let self = this,
                    newText = document.createElementNS(self.svgNS, "text");
                newText.setAttributeNS(null, "id", id);
                newText.setAttributeNS(null, "x", x);
                newText.setAttributeNS(null, "y", y);
                newText.setAttributeNS(null, "class", cssClass);
                // newText.setAttributeNS(null, "fill", fill);
                if (fill) {
                    newText.setAttributeNS(null, "style", `fill:${fill};`);
                }

                let textNode = document.createTextNode(text);
                newText.appendChild(textNode);
                self.chartProps.labelGroup.appendChild(newText);
            },
            toggleShowLabels() {
                this.contextMenu[1].title = (this.showLabels ? 'Hide' : 'Show') + ' labels';
            },
            togglePolySelection(state) {
                let self = this,
                    pS = self.polySelection;
                pS.show = state;

                if (state) {
                    self.plotPoly();
                } else {
                    self.resetPolySelection({redraw: true});
                    // pS.svg.selectAll(".showDocsTooltip").remove();
                    // pS.docs.showBtn = false;
                    // self.polySelection.points = [];
                    // self.polySelection.selected = [];
                    // self.plotChart();
                }
            },
            toggleColorScheme() {
                let self = this;
                self.loading = true;
                self.selectedCluster.show = false;
                self.resetPolySelection();
                setTimeout(() => {
                    self.prepareData(self.data)
                }, 250)
            },
            resetPolySelection(params = {}) {
                let self = this,
                    pS = self.polySelection;
                pS.inProgress = false;
                pS.tick = 0;
                pS.points = [];
                pS.selected = [];
                pS.docs.showBtn = false;
                pS.svg.selectAll("*").remove();
                self.selectedCluster.show = false;

                if (params.redraw) {
                    setTimeout(() => {
                        self.plotChart();
                    }, 100)
                }
            },
            resetSelectedClusterCard() {
                let self = this;
                self.selectedCluster.name = '';
                self.selectedCluster.growthLastYear = '';
                self.selectedCluster.docs = '';
                self.selectedCluster.keywords = [];
                self.selectedCluster.yearCounts = [];
            },
            fillPolySelection() {
                let self = this,
                    pS = self.polySelection,
                    xs = _.map(pS.points, 'x'),
                    ys = _.map(pS.points, 'y'),
                    maxX = _.max(xs),
                    maxY = _.max(ys),
                    minX = _.min(xs),
                    minY = _.min(ys),
                    _data = self.data.filter(it => {
                        return it.x >= minX && it.x <= maxX && it.y >= minY && it.y <= maxY;
                    });
                pS.selected = [];
                _data.forEach(it => {
                    if (self.polySelected(pS.points, it)) {
                        pS.selected.push(it.id);
                    }
                });
                pS.numSelected = self.$utils.toFin(pS.selected.length) || '';
                self.plotChart();
                // console.log('polySelected:', pS.selected);
            },
            createGradArr(min, max) {
                let self = this,
                    out = {},
                    zeroFrom = -Math.abs(min / 10),
                    zeroTo = Math.abs(max / 10),
                    delta = zeroTo + Math.abs(zeroFrom),
                    stepMinus = (zeroFrom - min) / 3,
                    stepPlus = (max - zeroTo) / 3,
                    minusArr = [],
                    plusArr = [];
                setTimeout(() => {
                    let marks = Array.from(document.querySelectorAll('.colorLegend .marker')),
                        // zeroMark = marks.slice(3,4)[0],
                        plusMarks = marks.slice(0, 3),
                        minusMarks = marks.slice(-3);
                    // zeroMark.style.width = 30 * (zeroTo - zeroFrom) / (delta * 2) + 'px';
                    minusMarks.forEach(it => {
                        it.style.width = min < 0 ? 35 * stepMinus / (delta * 2) + 1 + 'px' : '0px';
                    });
                    plusMarks.forEach(it => {
                        it.style.width = max > 0 ? 35 * stepPlus / (delta * 2) + 1 + 'px' : '0px';
                    });
                }, 100)
                for (let ii = 1; ii < 4; ii++) {
                    let jj = ii - 4;
                    plusArr.push(self.$utils.roundX(zeroTo + stepPlus * ii, 0));
                    minusArr.push(self.$utils.roundX(zeroFrom + stepMinus * jj, 0));
                }
                out = [...minusArr, zeroFrom, zeroTo, ...plusArr].reverse();
                self.legend.values = [...minusArr, 0, ...plusArr].reverse();
                return out;
            },
            showTooltip(params) {
                let self = this,
                    cluster = _.find(self.clusters, it => it.id == params.id),
                    $mapTooltip = document.querySelector('.mapTooltip'),
                    chartHeight = document.querySelector('#clusterMapChart').offsetHeight,
                    chartWidth = document.querySelector('#clusterMapChart').offsetWidth,
                    offsetX = (chartWidth - params.coords.x < 250) ? -180 : 0,
                    offsetY = (chartHeight - params.coords.y < 150) ? -190 : 15;
                $mapTooltip.style.left = (params.coords.x + offsetX + 20) + 'px';
                $mapTooltip.style.top = (params.coords.y + offsetY) + 'px';
                // console.log(params.coords.y, chartWidth, chartWidth - params.coords.x);

                self.tooltip.title = cluster.id + '. ' + (self.clusterUserName[cluster.id] || _.upperFirst(cluster.keywords[0].value));
                self.tooltip.docs = 'Documents: ' + cluster.numDocsFormatted;
                self.tooltip.keywords = _.map(cluster.keywords.slice(0, 6), 'value');
                self.tooltip.color = cluster.color;
                self.tooltip.growthLastYear = self.$utils.roundX(cluster.growthLastYear, 1);
                self.tooltip.show = true;
            },
            prepareData(data) {
                let self = this,
                    xs = _.map(data, 'x'),
                    ys = _.map(data, 'y'),
                    maxX = Math.ceil(_.max(xs) + 4),
                    minX = Math.ceil(_.min(xs) - 4),
                    maxY = Math.ceil(_.max(ys) + 1),
                    minY = Math.ceil(_.min(ys) - 1);
                self.loading = true;
                self.clusters = self.getClusters(data);
                self.chartProps.maxX = maxX;
                self.chartProps.maxY = maxY;
                self.chartProps.minX = minX;
                self.chartProps.minY = minY;
                self.chartProps.xScale.domain([minX, maxX]);
                self.chartProps.yScale.domain([minY, maxY]);
                self.chartProps.xScaleOriginal.domain([minX, maxX]);
                self.chartProps.yScaleOriginal.domain([minY, maxY]);
                self.plotChart();
            },
            plotChart() {
                let self = this,
                    $labels = document.querySelectorAll('g.label'),
                    sRez = self.searchFilter.result,
                    selectedClusterList = self.getCheckedClusters(),
                    _polyPoints = self.polySelection.selected,
                    _bitset = self.searchFilter.result.bitset,
                    foundPoints = [];
                const clusterFill = point => {
                    let _color = self.fadeClusterColor;
                    let isBitSet = _bitset.get && _bitset.get(point.id),
                        isInSelectedCluster = selectedClusterList.includes(point.cluster);
                    if (self.isSearch__Points) {
                        if (isBitSet && isInSelectedCluster && !self.selectAll) {
                            foundPoints.push(point.id)
                        }
                        _color = isBitSet ? isInSelectedCluster ? self.gotDocPointColor
                            : self.fadeDocPointColor
                            : self.fadeClusterColor;
                        //     _color = _bitset.get && _bitset.get(point.id) ? self.gotDocPointColor : self.fadeClusterColor;
                    } else if (selectedClusterList.includes(point.cluster)) {
                        _color = ((_.find(self.clusters, it => it.id == point.cluster) || {}).color || self.noClusterColor)
                    }
                    if (_polyPoints.length && _polyPoints.includes(point.id)) {
                        if (self.isSearch__Points) {
                            _color = isBitSet ? self.selectedPointColor : self.fadeClusterColor;
                        } else {
                            _color = self.selectedPointColor;
                        }
                    }

                    return self.webglColor(_color);
                }

                const fillColor = fc.webglFillColor().value(clusterFill).data(self.showData);
                self.chartProps.pointSeries.decorate(program => fillColor(program));

                self.quadtree = d3
                    .quadtree()
                    .x(d => d.x)
                    .y(d => d.y)
                    .addAll(self.showData);
                self.loading = false;
                self.redraw();

                _.forEach($labels, (label, ind) => {
                    // let _id = self.getIdFromLabel(label.querySelector('text').innerHTML);
                    // if (selectedClusterList.includes(parseInt(_id))) {
                    // let inSearch = (self.searchFilter.result.clusters || {})[ind];
                    // if ((!self.isSearch && selectedClusterList.includes(ind))
                    //     || !_.isUndefined(inSearch)) {
                    if (selectedClusterList.includes(ind)) {
                        label.classList.remove('faded');
                    } else {
                        label.classList.add('faded');
                    }
                })
                setTimeout(() => {
                    if (self.isSearch__Points && selectedClusterList.length && !_polyPoints.length) {
                        self.annotateRegion(foundPoints || [], 'keywordsOnly');
                    }
                }, 250)

            },
            zoomIt() {
                let self = this;
                if (!self.polySelection.inProgress) {
                    self.chartProps.xScale.domain(d3.event.transform.rescaleX(self.chartProps.xScaleOriginal).domain());
                    self.chartProps.yScale.domain(d3.event.transform.rescaleY(self.chartProps.yScaleOriginal).domain());

                    // console.log('.select("d3fc-svg.plot-area")', d3.select("d3fc-svg.plot-area").node().__zoom);
                    // console.log('self.polySelection.svg.node().__zoom', self.polySelection.svg.node().__zoom);
                    self.chartProps.transform = d3.event.transform;
                    if (self.chartProps.zoomScale != d3.event.transform.k) {
                        self.chartProps.zoomScale = d3.event.transform.k;
                        // self.polySelection.docs.showBtn = false;
                    }


                    /*-- Zoom Sync --*/
                    self.polySelection.svg.node().__zoom = d3.select("d3fc-svg.plot-area").node().__zoom = self.chartProps.transform;

                    self.redraw();
                    self.plotPoly();
                    self.plotDocsTooltip();
                }
            },
            redraw() {
                let self = this;
                let data = self.showData,
                    labelsData = self.labelsData;
                labelsData = [];
                // console.log('data:', data.map(it => {
                //     it.x = self.chartProps.xScale.invert(it.x);
                //     it.y = self.chartProps.yScale.invert(it.y);
                //     return it;
                // }));
                // console.log('labels:', labelsData);
                d3.select("#clusterMapChart").datum({data, labelsData}).call(self.chartProps.chart);


                setTimeout(() => {
                    self.loading = false;
                }, 10);

                // if (self.polySelection.show) {
                //     self.plotPoly();
                // }


            },
            showDocs(params = {}) {
                let self = this,
                    pS = self.polySelection,
                    reportId = self.sourceParams.id;
                params.mode = params.mode || '';
                let filter = {
                    query: self.searchFilter.query,
                    duplicate: false,
                    spam: false,
                    queryFields: self.sourceParams.filter.queryFields,
                    subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                    docType: self.sourceParams.filter.types[0],
                    types: self.sourceParams.filter.types,
                    clusterFilter: {
                        reportId: reportId,
                        clusterIds: self.selectedCluster.ids || [],
                        cluster: self.selectedCluster,
                        source: self.sourceParams,
                        addScore: true,
                    }
                }
                if (params.mode === 'list' && self.searchFilter.query) {
                    filter.count = 10;
                    self.searchFilter.result.docs = [];
                    self.searchFilter.loading = true;
                    delete filter.clusterFilter.cluster;
                    delete filter.clusterFilter.source;
                    self.$services.documents.search(filter).then(resp => {
                        self.searchFilter.result.docs = resp.data.list;
                        // console.log('docs: ', self.searchFilter.result.docs);
                        self.searchFilter.loading = false;

                    });
                } else if (params.mode === 'selectedDocs') {
                    // console.log('bitset', bitSet);
                    // console.log('test:::', self.$utils.bitSetIndices(self.$utils.fromBase64(_base64)));
                    delete filter.clusterFilter.cluster;
                    delete filter.clusterFilter.source;
                    delete filter.clusterFilter.clusterIds;
                    filter.count = 40;
                    filter.clusterFilter.points = pS.selectedBase64;
                    pS.docFilter = _.cloneDeep(filter);
                    pS.docs.loading = true;
                    self.plotDocsTooltip();

                    self.$services.documents.search(filter).then(resp => {
                        // console.log('docs: ', resp.data.list);
                        setTimeout(() => {
                            pS.docs.loading = false;
                            // pS.docs.showBtn = false;
                            self.plotDocsTooltip();
                        }, 2000);

                        self.$modal.dialog(SearchList, {
                            title: "Selected documents (" + self.polySelection.numSelected + ")",
                            params: {
                                class: 'dark-theme short-card noMaxHeight'
                            },
                            ':value': resp.data,
                            ':filter': filter,
                            ':type': self.sourceParams.filter.types[0],
                            buttons: [{
                                name: 'Close',
                                type: 'text',
                                handler: data => {
                                    data.dialog.close();
                                }
                            }]
                        }).catch(() => {
                        });

                    });

                } else if (params.mode === 'foundDocs') {
                    let bitSet = self.searchFilter.result.bitset;
                    delete filter.clusterFilter.clusterIds;
                    filter.clusterFilter.points = self.$utils.toBase64(bitSet);
                    filter.clusterFilter.cluster = {
                        name: 'Found documents',
                        keywords: []
                    }
                    self.$utils.openSearch(filter);
                } else if (self.selectedCluster.type === 'area') {
                    self.createBitSet(pS.selected).then(bitSet => {
                        delete filter.clusterFilter.clusterIds;
                        filter.clusterFilter.points = self.$utils.toBase64(bitSet);
                        filter.cluster = {
                            name: 'Selected area',
                            keywords: []
                        }
                        self.$utils.openSearch(filter);
                    })
                } else {
                    self.$utils.openSearch(filter);

                }
            },
            searchInClusters() {
                let self = this,
                    reportId = self.sourceParams.id;
                self.selectedCluster.show = false;
                self.loading = true;
                self.colorScheme = 'byCluster';

                let filter = {
                        query: self.searchFilter.query,
                        duplicate: false,
                        spam: false,
                        queryFields: self.sourceParams.filter.queryFields,
                        subfilters: [{operator: "AND_NOT", hostingGroups: {excludeFromSearch: true}}],
                        types: self.sourceParams.filter.types,

                    },
                    query = {
                        filter: filter,
                        reportId: reportId,
                    };
                if (self.searchFilter.query) {
                    self.colorScheme = 'byCluster';
                    self.loading = true;
                    self.$services.clusteringReport.search(query).then(resp => {
                        let bitset = self.$utils.fromBase64(resp.data.points);
                        let _data = {};
                        resp.data.clusters.forEach(it => {
                            _data[it.id] = it;
                        })
                        self.totalFound = bitset ? bitset.size : 0;
                        self.searchFilter.result.clusters = _data;
                        self.searchFilter.result.clusterIds = _.map(resp.data.clusters, 'id');
                        self.searchFilter.result.bitset = bitset || {};
                        self.resetPolySelection();
                        self.clusters = self.getClusters(self.data);
                        self.plotChart();
                    })
                } else {
                    self.searchFilter.result.clusters = {};
                    self.searchFilter.result.clusterIds = [];
                    self.loading = true;
                    self.clusters = self.getClusters(self.data);
                    self.plotChart();
                    self.totalFound = 0;
                }

            },
            clearSearch() {
                this.searchFilter.query = '';
                this.handleChangeSearch();
            },
            handleChangeSearch() {
                let self = this;
                if (!self.searchFilter.query) {
                    self.totalFound = 0;
                    self.loading = true;
                    self.resetPolySelection();
                    self.clusters = self.getClusters(self.data);
                    self.plotChart();
                }
            },
            handleChangeSource() {
                let self = this;
                self.showNoCluster = false;
                self.polySelection.points = [];
                self.polySelection.selected = [];
                self.resetPolySelection();
                self.getData();
            },
            handleSearchKeypress() {
                this.totalFound = 0;
            },
            handleChangeSearchResultType(type) {
                let self = this;
                self.searchFilter.searchResultType = type;
                self.searchInClusters();
            },
            handleEditClusterName() {
                let self = this,
                    sC = self.selectedCluster,
                    query = {
                        reportId: self.sourceParams.id,
                        id: sC.id,
                        name: sC.name
                    };
                sC.loading = true;
                self.$services.clusteringReport.updateCluster(query).then(resp => {
                    sC.isEditing = false;
                    setTimeout(() => {
                        sC.loading = false;
                        self.getData();
                    }, 1000);
                });
            },
            handleEditing(state) {
                state = state && this.isAllowedToEdit;
                this.selectedCluster.isEditing = state;
            },
            webglColor(color) {
                const {r, g, b, opacity} = d3.color(color).rgb();
                return [r / 255, g / 255, b / 255, opacity];
            },
            getIdFromLabel(parent) {
                let els = document.querySelectorAll('g.label');
                parent.classList.add('clicked');
                let _id = Array.prototype.indexOf.call(els, document.querySelector('g.label.clicked'))
                parent.classList.remove('clicked');
                return _id;
                // return ((context.split(' (') || [])[1] || '').replace(')', '');
            },
            startQuickTour() {
                let self = this;
                self.$store.commit('QTChangeSection', {
                    section: 'clusterMap',
                    widget: 'map'
                });
                self.$store.commit('QTStart');
                let _skipTour = self.userSettings.skipTour || {};
                _skipTour.clusterMap = true;
                // console.log('uS', self.userSettings);
                this.$services.userSettings.save({
                    skipTour: _skipTour
                }).then(resp => {
                    console.log('userSettings: ', resp.data);
                });

            },
            handleChangeStep() {
                let self = this,
                    _step = self.step.content;
                console.log('-->> step', self.step);
                if (self.step.number == 0) {
                    // --- Reset / End QT ---
                    self.searchFilter.query = '';
                    self.$nextTick(() => {
                        self.handleChangeSearch();
                        self.searchInClusters();
                        self.polySelection.points = [];
                        self.togglePolySelection(false);
                        self.handleToggleAll(true);

                    })

                } else {
                    (_step.actions || []).forEach(action => {
                        if (action.type === 'function') {
                            if (action.arg === 'clustersItem') {
                                setTimeout(() => {
                                    self.ctrlDown = !!action.isCtrl;
                                    let _item = self.clusters[action.argVal];
                                    self[action.function](_item);
                                    self.ctrlDown = false;
                                }, (action.timeOut || 0))
                            } else {
                                setTimeout(() => {
                                    self[action.function](action.argVal);
                                }, (action.timeOut || 0))
                            }
                        } else if (action.type === 'predicateSearch') {
                            let _item = self.clusters[0] || {},
                                _kw = typeof action.query !== 'undefined' ? action.query
                                    : _item.keywords[0].value.split(' ')[0];
                            self.searchFilter.query = _kw;
                            self.$nextTick(() => {
                                self.handleChangeSearch();
                                self.searchInClusters();
                            })
                        } else if (action.type === 'changeColoring') {
                            setTimeout(() => {
                                self.colorScheme = action.value;
                                self.$nextTick(() => {
                                    self.toggleColorScheme();
                                })
                            }, (action.timeOut || 0))
                        } else if (action.type === 'lasso') {
                            let pS = self.polySelection,
                                timeout = 0;

                            self.resetPolySelection();
                            self.togglePolySelection(true);
                            let devX = 2.75,
                                devY = 1.6,
                                mxX = self.chartProps.maxX / devX,
                                mxY = self.chartProps.maxY / devY,
                                mnX = self.chartProps.minX / devX,
                                mnY = self.chartProps.minY / devY,
                                midX = _.mean([mxX, mnX]),
                                midY = _.mean([mxY, mnY]),
                                points = [
                                    {x: midX, y: mnY},
                                    {x: _.mean([midX, mxX]) * 1.3, y: _.mean([mnY, midY]) * 1.3},
                                    {x: mxX, y: midY},
                                    {x: _.mean([midX, mxX]) * 1.2, y: _.mean([mxY, midY]) * 1.25},
                                    {x: midX, y: mxY},
                                    {x: _.mean([midX, mnX]) * 1.25, y: _.mean([mxY, midY]) * 1.3},
                                    {x: mnX, y: midY},
                                    {x: _.mean([midX, mnX]) * 1.3, y: _.mean([mnY, midY]) * 1.4},
                                ];
                            console.log('points', points);
                            points.forEach(it => {
                                timeout += action.timeout;
                                setTimeout(() => {
                                    pS.points.push(it);
                                    self.plotPoly();
                                }, timeout)
                            });
                            console.log('avgXY', (self.chartProps.maxX + self.chartProps.minX) / 2, (self.chartProps.maxY + self.chartProps.minY) / 2);
                            setTimeout(() => {
                                self.completeSelection()
                                // self.togglePolySelection(false);
                            }, (action.values.length * action.timeout + 150))

                        }
                    })
                }
            },
            fireEvent(data) {
                this.firedData = {incEvent: data};
            },

        },
    }
</script>


<style src="@/assets/d3-context-menu.css"></style>

<style lang="scss">

    .fst-ClusterMap {
        .header-center {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .header-right {
            flex: 0;
        }

        .colorSchemeBlock {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            padding-bottom: 5px;

            label {
                font-size: 13px;
                margin-right: 7px;
                white-space: nowrap;
            }
        }

        .top-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 15px;

            label {
                color: $iq-dark-theme-light;

                &.is-disabled {
                    opacity: .5;
                }
            }

            .source,
            .predicate {
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
                //padding: 5px 10px;
                //border: 1px solid $iq-dark-theme-color-border;
                //border-radius: 5px;
                label {
                    display: inline-flex;
                    align-items: center;
                    margin-right: 5px;
                }

                .el-input__inner {
                    width: 300px;
                    background: $iq-dark-theme-color-border !important;
                    height: 35px;
                }

                .pointer {
                    font-weight: 500;
                    color: $iq-dark-theme-link;

                    &:hover {
                        color: $iq-dark-theme-link-hover !important;
                        border-bottom: 1px solid;
                    }
                }
            }


            .predicate {
                flex-wrap: nowrap;
                margin-top: auto;

                .el-input__inner {
                    width: 400px;
                    font-size: 15px;
                    border-radius: 0;
                }

                .el-input__suffix {
                    top: 2px;
                }


                > *:last-child {
                    margin-left: 0;
                    border-left: none;
                    border-radius: 0 5px 5px 0;
                }
            }
        }

        .chart-container {
            flex: 1;
            height: calc(100vh - 100px);
            position: relative;
        }

        #clusterMapChart {
            padding: 0;
            background: $iq-dark-theme-bg-light-2;
            height: calc(100vh - 86px);
            /*cursor: grab;*/

            d3fc-group {
                grid-template-columns: 1em auto 1fr 1em 0;
                grid-template-rows: 0 auto 1fr 1em 0;
            }

            &.grey {
                //background: #bbb;
            }
        }

        #polySelection {
            position: absolute;
            top: 0;
            left: 15px;
            cursor: url('/lasso_tool_18.png') 6 20, auto;


            &.active {
                z-index: 400;
            }

            > svg {
                width: calc(100vw - 40px);
                height: calc(100vh - 100px);

                circle {
                    position: relative;
                    z-index: 300;
                    /*cursor: grab;*/
                }
            }
        }

        .d3-context-menu-theme {
            ul hr {
                margin: 3px 0;
            }
        }

        .chart-container > .control {
            background: rgba(0, 0, 0, 0.8);
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;
            color: $iq-dark-theme-light;
            padding: 10px 15px;
            left: 20px;
            top: 20px;
            max-height: calc(100vh - 120px);
            overflow-y: auto;
            position: absolute;
            width: 350px;
            z-index: 2000;

            .el-link.el-link--default {
                color: $iq-dark-theme-link;
                font-size: .76rem;
            }

            .selectAll {
                cursor: pointer;

                &.active {
                    color: #fff;
                }
            }

            .el-checkbox {
                color: #676767;
                font-size: 14px;

                .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: $iq-dark-theme-primary;
                }

                .el-checkbox__label {
                    padding-left: 6px;
                }
            }

            &.right {
                left: auto;
                right: 20px;

                .el-loading-spinner {
                    margin-top: -10px;
                }
            }

            .total {
                font-size: 14px;
                color: $iq-dark-theme-light;
            }

            .close {
                float: right;
                font-size: 20px;
                line-height: 1;
                margin: -4px -4px 0 0px;
                padding: 3px;
                cursor: pointer;

                &:hover {
                    color: $iq-dark-theme-primary;
                }
            }

            .title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 5px;
                word-break: break-word;
                display: flex;
                align-items: flex-start;
                max-height: 46px;
                overflow: auto;
                margin-right: 20px;

                .clusterName {
                    margin-left: 4px;
                }

                .el-input__inner {
                    padding: 3px 5px;
                    border-radius: 4px;
                    font-size: 15px;
                    margin: -2 px 0 0 3px;
                    font-weight: 500;
                    border: none;
                    background: $iq-dark-theme-color-border !important;
                    height: auto !important;
                    color: $iq-dark-theme-primary !important;
                    line-height: 1.25;
                }
            }

            .subtitle {
                font-weight: 600;
                margin-bottom: 7px;
                transition: all .2s ease;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                &.pointer:hover {
                    color: #ffffff;
                }

            }

            .numDocs {
                .small {
                    font-size: 0.75rem;
                    position: relative;
                    top: -1px;
                    color: $iq-dark-theme-light;
                    border-radius: 50px;
                    padding: 1px 5px;
                    border: 1px solid $iq-dark-theme-color-border
                }
            }

            .clusterDocs {
                .doc-item {
                    margin-bottom: 7px;
                }

                .title {
                    font-size: .9rem;
                    font-weight: 500;
                }
            }

            .keywords {
                margin-top: 3px;
                min-height: 150px;

                .kw {
                    margin: 5px 8px 4px 0;
                    border: 1px solid #D5EDFA;
                    padding: 5px 10px;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 4px;
                    background: #D5EDFA;
                    font-weight: 500;
                    color: #111;
                    font-size: 14px;
                    text-transform: capitalize;
                }
            }

            .yearCounts {
                margin-top: 13px;
                border-top: 1px solid $iq-dark-theme-color-border;
                padding-top: 6px;

                .subtitle {
                    margin-bottom: 7px;
                }

                > div {
                    margin: 5px 0;

                    span {
                        color: #ffffff;
                    }
                }
            }

            .switches {
                padding: 7px 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 10px;
            }

            .areaSelectionControl {
                border-top: 1px solid $iq-dark-theme-color-border;
                margin-top: 5px;
                padding-top: 14px;
            }

            .clusterDocs {
                height: 200px;
                overflow-y: auto;
            }
        }

        .cluster-checks {
            max-height: calc(100vh - 380px);
            overflow-y: auto;
            margin: 10px 0;

            > label {
                cursor: pointer;
                color: $iq-dark-theme-primary;
                margin: 7px 0;
                padding: 0;
                font-size: 14px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                > div {
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                }

                .showHide {
                    margin: 0 7px 0 4px;
                    opacity: .45;
                }

                .strike {
                    text-decoration: line-through;
                }

                .form-check-label,
                .numDocsPrc {
                    opacity: .45;
                }

                .checked {
                    .form-check-label {
                        opacity: 1;
                    }

                    &.showHide {
                        opacity: 1;
                    }

                    .numDocsPrc {
                        opacity: 1;
                    }
                }

                .form-check-label {
                    //white-space: nowrap;
                }

                .marker {
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    margin: 2px 7px 0 0;
                    flex-shrink: 0;
                }

                .numDocsPrc {
                    margin-left: auto;
                    padding: 0 7px 0 10px;
                    font-size: .8rem;
                    color: #eee;
                }
            }
        }


        g.label {
            fill: rgba(38, 38, 38, .25);
            cursor: pointer;
            //stroke: rgba(0,0,0,0.003);
            //stroke-width: 1px;

            circle {
                r: 5;
                cursor: pointer;
                fill: rgba(255, 255, 255, .85);
                stroke: rgba(0, 0, 0, .95);
            }

            text {
                fill: #fff;
                font-size: 14px;
                font-weight: 500;
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                stroke: none;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            &.faded {
                fill: transparent;

                text {
                    opacity: 0.38;
                    font-weight: 300;
                }
            }
        }

        &.noLabels {
            g.label {
                fill: none;

                circle {
                    r: 5;
                    fill: #fff;
                }

                text {
                    display: none;
                    fill: none;
                }

                &.faded {
                    circle {
                        fill: #fff;
                        opacity: .35;
                    }

                    & + g.label:not(.faded) {
                        text {
                            display: block;
                        }
                    }
                }
            }
        }

        .x-axis, .y-axis {
            opacity: 0;
        }

        .mapTooltip {
            position: absolute;
            width: 275px;
            background: rgba(0, 0, 0, 0.75);
            color: $iq-dark-theme-primary;
            padding: 7px 10px;
            border: 1px solid $iq-dark-theme-color-border;
            border-radius: 5px;
            z-index: 2100;
            line-height: 1.4;
            top: 95px;
            right: 15px;

            .title {
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 3px;
            }

            .params {
                font-size: 13px;
                line-height: 1.5;
                margin-bottom: 4px;
            }

            &.visible {
                display: block;
            }

            ul {
                padding-left: 5px;
                margin: 7px 0;

                li {
                    list-style: none;
                    line-height: 1.2;
                    margin: 7px 0 7px 7px;
                    display: flex;
                    text-transform: capitalize;

                    &:before {
                        content: "\2013";
                        text-indent: -5px;
                        margin-right: 5px;
                    }
                }
            }
        }

        &.fullScreen {
            .control {
                display: none;
            }

            #clusterMapChart {
                height: 100vh;
            }

            &.noLabels {
                g.label {
                    circle {
                        display: none;
                    }
                }
            }
        }

        .mini-chart {
            > div {
                display: flex;
                font-size: 12px;

                > div {
                    flex: 1;
                    text-align: center;
                }
            }

            .vals {
                margin-bottom: 7px;
                color: #fff;
            }

            .xaxis {
                border-top: 1px solid $iq-dark-theme-color-border;
                padding-top: 3px;
                margin-top: 1px;
            }

            .histogram {
                align-items: flex-end;

                > div {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    > div {
                        width: 15px;
                        background: #d5edfa;
                    }
                }
            }
        }

        .colorLegend {
            position: absolute;
            top: 20px;
            left: 383px;
            background: rgba(0, 0, 0, 0.8);
            padding: 10px 17px 0;
            border-radius: 5px;
            border: 1px solid $iq-dark-theme-color-border;

            .gradient {
                display: flex;
                align-items: flex-end;
                flex-direction: row-reverse;
            }

            .item {
                color: #fff;
                font-size: 12px;
                font-weight: 500;
                display: flex;
                line-height: 1;
                align-items: center;
                flex-direction: column;
            }

            .value {
                margin-bottom: 3px;
            }

            .marker {
                width: 35px;
                height: 14px;
            }
        }

        .growth-range {
            width: calc(100% - 0);
            margin: -5px auto 5px;

            label {
                position: relative;
                top: 10px;
            }
        }

        .el-button--mini {
            padding: 3px 6px;
        }

        .bottomRightMenu {
            position: absolute;
            right: 20px;
            bottom: 20px;
            display: flex;
            z-index: 5001;
        }

        .areaSelectionControl {
            margin-right: 40px;
            display: flex;
            align-items: center;
        }

        .showSelectedDocs {
            position: absolute;
            top: 40%;
            left: 50%;
            z-index: 2000;
            padding: 6px 9px;

            .small {
                font-size: 0.7rem;
                position: relative;
                top: -1px;
                //background: $iq-dark-theme-light;
                //border: 1px solid $iq-dark-theme-color-border;
                border-radius: 50px;
                //padding: 1px 5px;
                margin-left: 4px;
            }

            &:hover {
                .small {
                    //border-color: #61646b;
                }
            }
        }

        g.showDocsTooltip {
            z-index: 4000;
            cursor: pointer;
        }

        .exportBlock {
            width: 100vw;
            height: 100vh;
            position: absolute;
            z-index: -6000;
            top: 0;
            left: 0;
            background: $iq-dark-theme-bg-light-1;
            color: #fff;
            overflow: hidden;

            .exportMenu {
                position: absolute;
                bottom: 15px;
                right: 15px;
                display: flex;

                i {
                    font-size: 20px;
                }
            }
        }

        #exportSVG {
            width: 100vw;
            height: 100vh;
        }
    }

    #app {
        .dark-theme {
            .fst-ClusterMap {
                .colorSchemeBlock {
                    input.el-input__inner {
                        height: 24px;
                        line-height: 1;
                        background: $iq-dark-theme-bg-light-2;
                        color: $iq-dark-theme-light;
                        border: none;
                        padding-left: 7px;
                    }

                    .el-input--small .el-input__icon {
                        line-height: 1;
                        font-size: 14px;
                    }
                }

                .el-input-group__prepend {
                    background: $iq-dark-theme-bg-light-2;
                    color: $iq-dark-theme-light;
                    border-color: $iq-dark-theme-color-border;
                }
            }
        }
    }

</style>
